/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../constants';
import DataFormatter from '../utils/DataFormatter';
import CommonUtils from '../utils/CommonUtils';

export default class DlAppConfigService extends BaseService {
  constructor() {
    super(constants.DL_APP_CONFIG_REQUESTED, 'DlAppConfigServiceStatus');
  }

  handleEvent(eventName, event) {
    const deviceId = CommonUtils.getdeviceId();
    const gabProductId = CommonUtils.getGabProductId();
    const headerParams = {
      headers: {
        deviceid: deviceId,
        appversion: 'app'
      }
    };
    const schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
    let Schoolid; let geoId; let saId; let appConfigURL;
    if (typeof schoolList[0] !== 'undefined') {
      Schoolid = schoolList[0].id;
      geoId = schoolList[0].geoId;
      saId = schoolList[0].saId;
      appConfigURL = schoolList[0].links.appConfigAPI;
      if (typeof appConfigURL !== 'undefined') {
        if (appConfigURL.toString().indexOf('&version') < 0) {
          if (appConfigURL.toString().indexOf('?') > 0) {
            appConfigURL += '&version=v7';
          } else {
            appConfigURL += '?version=v7';
          }
        }
      }
    }
    if (gabProductId) {
      headerParams.headers.productid = gabProductId;
    }
    if (Schoolid) {
      headerParams.headers.schoolid = Schoolid;
    }
    if (saId) {
      headerParams.headers.said = saId;
    }
    if (geoId) {
      headerParams.headers.geoid = geoId;
    }
    if (appConfigURL) {
      headerParams.headers.appconfigurl = appConfigURL;
    }
    // console.log("fetch-appconfig handleEvent: ", new Date(), new Date().getTime());
    return this.getClient('hapi').get('/fetch-appconfig', headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    // console.log("fetch-appconfig handleResponse: ", new Date(), new Date().getTime());
    if (response.data && response.data !== '') {
      Framework.getStoreRegistry().getStore('dlAppConfig').set(response.data);
      Framework.getEventManager().publish(
        constants.DL_APP_CONFIG_FETCHED,
        response.data
      );
    } else {
      Framework.getEventManager().publish(constants.DL_APP_CONFIG_EMPTY, {
        message: 'Cannot Find App Configurations'
      });
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    // Framework.getStoreRegistry().getStore('dlUserSchool').set({});
    Framework.getEventManager().publish(constants.DL_APP_CONFIG_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
