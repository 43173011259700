/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import StudentContainer from './StudentContainer';
import CourseSectionFetchService from './services/CourseSectionFetchService';
import CourseRosterFetchService from './services/CourseRosterFetchService';

export default {
  path: '/student/:pageName',
  exact: true,
  services: [
    new CourseSectionFetchService(),
    new CourseRosterFetchService()
  ],
  stores: [],
  render: props => <StudentContainer {...props} />
};
