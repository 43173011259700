/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const SchoolsForCourse = types
  .model('SchoolsForCourse', {
    id: types.maybeNull(types.string),
    saId: types.maybeNull(types.string),
    geoId: types.maybeNull(types.string)
  });
const ProductsForCourse = types
  .model('ProductsForCourse', {
    id: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string)
  });
const externalRefForCourse = types
  .model('externalRefForCourse', {
    originId: types.maybeNull(types.string),
    source: types.maybeNull(types.string)
  });
const CourseItem = types
  .model('CourseItem', {
    cartridgeIds: types.array(types.maybeNull(types.string)),
    tags: types.array(types.maybeNull(types.string)),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    subject: types.maybeNull(types.string),
    grade: types.maybeNull(types.string),
    schools: types.array(SchoolsForCourse),
    products: types.array(ProductsForCourse),
    externalRef: types.array(externalRefForCourse),
    createdBy: types.maybeNull(types.string),
    expires: types.maybeNull(types.boolean),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    roleValue: types.maybeNull(types.string),
    id: types.maybeNull(types.string)
  });

const DlCourseForNavbar = types
  .model('DlCourseForNavbar', {
    items: types.optional(types.array(CourseItem), [])
  })
  .actions(self => ({
    fetch(schoolId, userId) {
      Framework.getEventManager().publish(constants.COURSELIST_REQUESTED, { schoolId, userId });
    },
    set(state,fetchNextClass,schoolId,userId) {
      applySnapshot(self, state);
      if(fetchNextClass) {
        Framework.getEventManager().publish(constants.COURSELIST_REQUESTED, { schoolId, userId });
      }
    }
  }));

export default DlCourseForNavbar;
