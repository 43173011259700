/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Base environment settings. This base environment config can be overriden in environment-specific configs.
 *
 * @author Hari Gangadharan
 */

const base = {
  ENVIRONMENT: 'stg',
  PARTNER_ENV: 'stg',
  CSG_API_BASE_URL: 'https://content-service.stg-prsn.com/csg/api/v3',
  MARIN_API_BASE_URL: 'https://plus-stg.pearson.com/marin/api/1.0',
  PRISM_API_BASE_URL: 'https://prism-stg.pearsoned.com/api',
  PAPER_API_BASE_URL: 'https://paperapi.stg-openclass.com/nextext-api/api/platforms/bookshelf',
  STPAPER_API_BASE_URL: 'https://stpaperapi.stg-prsn.com/etext/v2',
  SPECTRUM_API_BASE_URL: 'https://spectrum-stg.pearsoned.com/api/v1',
  SPECTRUM_API_V4_BASE_URL: 'https://spectrum-stg.pearsoned.com/api/v4', // TODO: Need to check with Spectrum team for notescopy support
  IDC_API_BASE_URL: 'https://etext-instructor-stg.pearson.com/idc-api/',
  ETEXT_API_BASE_URL: 'https://etext-stg.pearson.com/api/nextext-api/v1/api',
  HAPI_API_BASE_URL: `//digital-library-stg.pearson.com${process.env.NODE_ENV !== 'production' ? ':3000' : ''}`,
  CSG_API_TIMEOUT: 10000,
  MARIN_API_TIMEOUT: 20000,
  PRISM_API_TIMEOUT: 10000,
  STPAPER_API_TIMEOUT: 20000,
  SPECTRUM_API_TIMEOUT: 10000,
  IDC_API_TIMEOUT: 60000,
  ETEXT_API_TIMEOUT: 10000,
  HAPI_API_TIMEOUT: 20000,
  PRODUCT: 'Digital Library',
  BROWSER_REQUIREMENTS_URL: 'https://www.pearson.com/us/higher-education/products-services-teaching/'
    + 'course-content/textbooks-and-etexts/pearson-etext/system-requirements.html',
  CLIENT_ID: 'Wf2aVSJuuP88GZChhzEk29WH1ifGIPQe',
  PSEUDO_LOCALE_CODE: 'pt',
  PRIVACY_URL: 'https://login-stg.pearson.com/v1/piapi-int/policies/static/html/PearsonPrivacyPolicy.html',
  PROMO_CODE_API_BASE_URL: 'https://readerplus-resources.s3.amazonaws.com/etext/dev/promo.json',
  PROMO_CODE_API_TIMEOUT: 10000,
  CONSOLE_URL: 'https://console-stg.pearson.com',
  ZEPPELIN_URL: 'https://zeppelin.stg-openclass.com/products',
  TENANT_ID_ETEXT: '30258aed-46bb-4ec5-909e-ecea2431772b',
  TENANT_KEY_ETEXT: '6551b60c-b521-4949-be3a-1be09d8ede6f',
  TENANT_KEY_ETEXT_PDF: 'a7a95b0e-6294-4588-9ee2-87767bbecb05',
  TERMS_OF_USE_URL: 'https://login-stg.pearson.com/v1/piapi-int/policies/static/html/PearsonEULA.html',
  CONTENT_HOST: `https://${window.location.host}`,
  PXE_BASE_PATH: 'eps/pearson-reader/api/item',
  CITE_BASE_PATH: 'eps/sanvan/api/item',
  COLLECTIONS_BASE_PATH: 'eps/bluesky/api/item',
  EPUB_BASE_PATH: 'epub/bronte',
  EPUB_STD_BASE_PATH: 'epub/standard',
  PXE_X_CALLER: 'f50674e2-3416-11e8-b467-0ed5f89f718b',
  CITE_X_CALLER: 'b2ceee88-d75e-11e8-9f8b-f2801f1b9fd1',
  NEWRELIC_CONFIG_NAME: '/newrelic/newrelic.js',
  NEWRELIC_ENABLED: false,
  NEWRELIC_CONFIG: {
    accountId: 1322480,
    agentId: 30030167,
    applicationId: 30030167,
    licenseKey: 'd527718953',
    trustKey: 691807
  },
  SMARTTHINKING_CONFIG: {
    baseUrl: 'https://staging.services.smarthinking.com/api/payment-mojo',
    secret: '20BBEBB8-DCC1-4544-AD32-7F3973CCED7A'
  },
  LD_CLIENT_SIDE_ID: '601af2742c6a070a54423c29',
  LD_ANONYMOUS_USER_ID: 'ise_anonymous_stg_user',
  LD_ANONYMOUS_HASH: '0e36d47a6e87fff7d7bfded6d7e5488de59a7249f806e5a6c3c7bf78f06580d1',
  BOOKSHELF_CONFIG: {
    checkout: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/pricing.html',
    pmcHeader: 'https://pearson-stg3.pearson.com/en-us/pearsonplus.nav-content.standalone.html',
    purchase: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/search.html',
    addon: 'https://pearson-stg3.pearson.com/store/_ui/addons/pmciamaddon/responsive/common/js/pmciamaddon.js',
    chatbot: {
      url: 'https://pearson--testbau.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseUrl: 'https://pearson--testbau.my.salesforce.com',
      supportUrl: 'https://testbau-pearsoncommunity.cs160.force.com/getsupport',
      id: '00D5r0000004faA',
      name: 'NAUS_HETS_DTC_CS_Bot',
      baseLiveAgentContentURL: 'https://c.la3-c1cs-fra.salesforceliveagent.com/content',
      deploymentId: '572b0000000ClKC',
      buttonId: '5735r000000007F',
      baseLiveAgentURL: 'https://d.la3-c1cs-fra.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5r000000006dEAA_1797baff2a5',
      isOfflineSupportEnabled: false
    },
    more: {
      MOJO_TERMS: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/terms-of-use.html',
      MOJO_PRIVACY: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/user-privacy-policy.html',
      MOJO_COOKIES: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_INFO: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_ACCESSIBILITY: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/accessibility.html',
      MOJO_NOTICE: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/patent-notice.html',
      MOJO_PEARSON_ACCESSIBILITY: 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
      MOJO_SUPPORT: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/support.html'
    }
  },
  PRODUCT_TOKEN: '1',
  PLUS_URL: 'https://plus-stg.pearson.com',
  HIDE_APPINFO: false,
  MARKETING_OPT: false,
  IES_BASE_URL: 'https://login.pearson.com/v1/piapi',
  CAAS_ID: '65cb47d5-5581-4b6e-9f8d-cff3681387cc', // f15b6dd4-2aef-436c-9d42-dc4f85ceca34

  // Google Tag Manager Configs - Local/Dev
  GTM_CONTAINER_ID: 'GTM-N8JS47K',
  GTM_AUTH: '34vutGcP7_nco7Xgx1OspQ',
  GTM_PREVIEW: 'env-3',

  ACCESS_CODE_SECRET_KEY: 'JWnVTLftOlmI4B59HCVj4F1E60Z2Nytz',
  ACCESS_CODE_BASE_URL: 'https://admin-dev.cac.pulse.pearson.com/#/ext-access-code'
};

export default base;
