import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import fav from '../../../assets/images/books/fav.png';
import add from '../../../assets/images/books/add.png';
import flipIcon from '../../../assets/images/books/flip-icon-secondary.png';
import flipPrimary from '../../../assets/images/books/flip-primary.png';
import teachingNotesImage from '../../../assets/images/teaching.png';
import resourcesImage from '../../../assets/images/resources.png';
import lessonPlanImage from '../../../assets/images/lessonplan.png';

const styles = {
  baacFavImageHolder: {
    backgroundImage: fav
  },
  baacAddImageHolder: {
    backgroundImage: add
  },
  baacFlipImageHolder: {
    backgroundImage: flipIcon
  },
  baacFlipImageHolderForPrimary: {
    display: 'block',
    backgroundImage: flipPrimary
  }
};

const theme = {
  THEME_PRIMARY: 'primary',
  THEME_SECONDARY: 'secondary'
};

const booktheme = createTheme({
  palette: {
  }
});


class Book extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      book: this.props.book,
      showActivityPopover: false
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      book: newProps.book
    });
  }

  handleClick = (book, index, event) => {
    (event !== undefined) ? event.stopPropagation() : '';
    this.props.bookClickCallback(book, index);
  }

  bookAddCallback = (book, index, event) => {
    event.stopPropagation();
    this.props.bookAddCallback(book, index);
  }

  bookCheckedCallback = (book, index, event) => {
    event.stopPropagation();
  }

  favouriteOptionCallback = (book, index, event) => {
    event.stopPropagation();
    if (book.bookInfo.isLiked) {
      this.props.book.bookInfo.isLiked = false;
      event.currentTarget.setAttribute('style', `background-image: url("${this.props.favInactiveImageURL}"); display: block;`);
    } else {
      this.props.book.bookInfo.isLiked = true;
      event.currentTarget.setAttribute('style', `background-image: url("${this.props.favActiveImageURL}"); display: block;`);
    }
    this.props.favouriteOptionCallback(book, index);
  }

  buyBookCallback = (book, index) => {
    this.props.buyBookCallback(book, index);
  }

  teachingNotesClickCallback = (book, index) => {
    this.props.teachingNotesClickCallback(book, index);
  }

  resourcesClickCallback = (book, index) => {
    this.props.resourcesClickCallback(book, index);
  }

  lessonPlansClickCallback = (book, index) => {
    this.props.lessonPlansClickCallback(book, index);
  }

  returnBookCallback = (book, index) => {
    this.props.returnBookCallback(book, index);
  }

  sendBookCallback = (book, index) => {
    this.props.sendBookCallback(book, index);
  }

  moreBooksCallback = (book, index) => {
    this.props.moreBooksCallback(book, index);
  }

  flipBookCallback = (book, index, event) => {
    event.stopPropagation();
    event.preventDefault();
    book.isFlipped = true;
    this.setState({
      book
    }, () => {
      const el = document.getElementsByClassName('baacFlipBookDesc');
      for (let i = 0; i < el.length; i++) {
        const wordArray = el[i].innerHTML.split(' ');
        while (el[i].scrollHeight > el[i].offsetHeight) {
          wordArray.pop();
          el[i].innerHTML = `${wordArray.join(' ')}...`;
        }
      }
    });
    this.props.flipBookCallback(book, index);
  }

  handleFlipClose = (book, index, event) => {
    event.stopPropagation();
    event.preventDefault();
    book.isFlipped = false;
    this.setState({
      book,
      showActivityPopover: false
    });
    this.props.flipBookCallback(book, index);
  }

  handleActivityClick = (book, event) => {
    if (!book.metadata.activities) {
      return;
    } if (book.metadata.activities.length == 0) {
      return;
    }
    const flag = !this.state.showActivityPopover;
    const element = event.currentTarget.parentElement;
    this.setState({
      showActivityPopover: flag
    }, () => {
      if (flag) {
        try {
          const elementCount = element.children.length;
          for (let i = 0; i < elementCount; i++) {
            if (element.children[i].classList.contains('baacActivity')) {
              const elementCount1 = element.children[i].children.length;
              for (let j = 0; j < elementCount1; j++) {
                if (element.children[i].children[j].classList.contains('baacActivityPopover')) {
                  const dd = element.children[i].children[j].children[1];
                  const temp = dd.getBoundingClientRect().height - 64;
                  dd.style.top = `-${temp / 2}px`;
                }
              }
            }
          }
        } catch (err) {}
      }
    });
  }

  closeActivityPopover = () => {
    this.setState({
      showActivityPopover: false
    });
  }

  handleCheckboxAction = (index, event, value) => {
    const book = this.state.book;
    if (value) {
      book.isChecked = true;
      this.setState({
        book
      }, this.props.bookCheckedCallback(book, index));
    } else {
      book.isChecked = false;
      this.setState({
        book
      }, this.props.bookCheckedCallback(book, index));
    }
    this.props.handleCheckboxAction(event, value);
    const elements = document.getElementsByClassName('baacTextBookSelector');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.border = 'none';
    }
  }

  handleCheckboxHoverStyle = (event) => {
    try {
      const childElements = event.currentTarget.children;
      for (let j = 0; j < childElements.length; j++) {
        if (childElements[j].classList.contains('baacTextBookSelector')) {
          const childElements1 = childElements[j].children;
          for (let i = 0; i < childElements1.length; i++) {
            if (childElements1[i].classList.contains('baacTriangleShape')) {
              childElements1[i].style.backgroundColor = '#ed8000';
              if (this.state.book.isChecked) {
                childElements1[i].classList.remove('baacColouredTriangle');
              }
            }
          }
        }
      }
      event.currentTarget.style.border = '3px solid #ed8000';
    } catch (e) {}
  }

  handleCheckboxMouseOutStyle = (event) => {
    try {
      const childElements = event.currentTarget.children;
      for (let j = 0; j < childElements.length; j++) {
        if (childElements[j].classList.contains('baacTextBookSelector')) {
          const childElements1 = childElements[j].children;
          for (let i = 0; i < childElements1.length; i++) {
            if (childElements1[i].classList.contains('baacTriangleShape')) {
              childElements1[i].style.backgroundColor = '#fff';
              if (this.state.book.isChecked) {
                childElements1[i].classList.add('baacColouredTriangle');
              }
            }
          }
        }
      }

      if (!this.state.book.isChecked) {
        event.currentTarget.style.border = 'none';
      } else {
        event.currentTarget.style.border = '3px solid #22bcb4';
      }
    } catch (e) {}
  }

  handleStylesType1 = (event) => {
    try {
      event.currentTarget.style.backgroundColor = '#ed8000';
      event.currentTarget.children[0].style.backgroundColor = 'white';
      event.currentTarget.children[0].style.color = '#ed8000';
      event.currentTarget.children[1].style.color = 'white';
    } catch (e) {

    }
  }

  handleStylesType2 = (event) => {
    try {
      event.currentTarget.style.backgroundColor = '#ed8000';
      event.currentTarget.children[0].style.backgroundColor = '#ed8000';
      event.currentTarget.children[0].style.color = 'white';
      event.currentTarget.children[1].style.color = 'white';
    } catch (e) {

    }
  }

  removeStyles = (event) => {
    try {
      event.currentTarget.removeAttribute('style');
      event.currentTarget.children[0].removeAttribute('style');
      event.currentTarget.children[1].removeAttribute('style');
    } catch (e) {}
  }

  baacFavImageHolder;

  baacAddImageHolder;

  baacFlipImageHolder;

  baacFlipImageHolderForPrimary;

  updateUiWithProps = () => {
    if (this.props.enableFavourite) {
      if (this.props.book.hasOwnProperty('bookInfo')) {
        this.baacFavImageHolder = Object.assign({}, styles.baacFavImageHolder, { display: 'block', backgroundImage: `url('${this.props.book.bookInfo.isLiked ? this.props.favActiveImageURL : this.props.favInactiveImageURL}')` });
      } else {
        this.baacFavImageHolder = Object.assign({}, styles.baacFavImageHolder, { display: 'block', backgroundImage: `url('${this.props.favInactiveImageURL}')` });
      }
      // this.baacFavImageHolder = Object.assign({},styles.baacFavImageHolder,{});
    } else {
      this.baacFavImageHolder = Object.assign({}, styles.baacFavImageHolder, { display: 'none' });
    }
    if (this.props.enableAdd) {
      this.baacAddImageHolder = Object.assign({}, styles.baacAddImageHolder, { display: 'block', backgroundImage: `url('${this.props.addImageURL}')` });
    } else {
      this.baacAddImageHolder = Object.assign({}, styles.baacAddImageHolder, { display: 'none' });
    }
    if (this.props.enableFlip) {
      this.baacFlipImageHolder = Object.assign({}, styles.baacFlipImageHolder, { display: 'block', backgroundImage: `url('${this.props.flipImageURL}')` });
      this.baacFlipImageHolderForPrimary = Object.assign({}, styles.baacFlipImageHolderForPrimary, { backgroundImage: `url('${this.props.flipImageURL1}')` });
    } else {
      this.baacFlipImageHolder = Object.assign({}, styles.baacFlipImageHolder, { display: 'none' });
    }
  }

  handleTeachingNotes = () => {
    if (this.state.book.metadata.hasOwnProperty('teachingNote')) {
      const that = this;
      return (
        <div
          tabIndex={0}
          className="baacFlipBookActions"
          onClick={this.teachingNotesClickCallback.bind(this, this.state.book, this.props.index)}
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              that.teachingNotesClickCallback.call(that, that.state.book, that.props.index);
            }
          }}
        >
          <div className="baacFlipBookActionIcon baacteachingNotes"><img alt="teaching_active" src={teachingNotesImage} /></div>
          <div className="baacFlipBookActionText baacTeachingNotesText">{this.props.teachingNotesText}</div>
        </div>
      );
    }
  }

  handleResources = () => {
    if (this.state.book.metadata.hasOwnProperty('resources')) {
      const that = this;
      return (
        <div
          tabIndex={0}
          className="baacFlipBookActions"
          onClick={this.resourcesClickCallback.bind(this, this.state.book, this.props.index)}
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              that.resourcesClickCallback.call(that, that.state.book, that.props.index);
            }
          }}
        >
          <div className="baacFlipBookActionIcon baacResources"><img alt="resources_active" src={resourcesImage} /></div>
          <div className="baacFlipBookActionText baacResourcesText">{this.props.resourcesText}</div>
        </div>
      );
    }
  }

  handleLessonPlans = () => {
    if (this.state.book.metadata.hasOwnProperty('lessonPlans')) {
      const that = this;
      return (
        <div
          tabIndex={0}
          className="baacFlipBookActions"
          onClick={this.lessonPlansClickCallback.bind(this, this.state.book, this.props.index)}
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              that.lessonPlansClickCallback.call(that, that.state.book, that.props.index);
            }
          }}
        >
          <div className="baacFlipBookActionIcon baacLessonPlans"><img alt="lessonplans_active" src={lessonPlanImage} /></div>
          <div className="baacFlipBookActionText baacLessonPlansText">{this.props.lessonPlansText}</div>
        </div>
      );
    }
  }

  handleAccessibility = (book, index, event) => {
    if (event.key == 'Enter') {
      const action = event.target.className.split(' ')[0];
      switch (action) {
        case 'baacFavImageHolder':
          this.favouriteOptionCallback(book, index, event);
          break;
        case 'baacAddImageHolder':
        case 'baacAddToMyBookBox':
          this.bookAddCallback(book, index, event);
          break;
        case 'baacFlipImageHolder':
          this.flipBookCallback(book, index, event);
          window.setTimeout(() => {
            const elementIndex = index + 1;
            const querySelectorString = `#bookshelfWrapper > div > div:nth-child(${elementIndex}) > div > div:nth-child(6)`;
            document.querySelector(querySelectorString).focus();
          }, 0);
          break;
        case 'baacFlipImageHolderPrimary':
          this.flipBookCallback(book, index, event);
          event.target.parentElement.parentElement.focus();
          break;
        case 'baacReturnBookContainer':
          this.returnBookCallback(book, index, event);
          break;
        case 'baacFlipClose':
          this.handleFlipClose(book, index, event);
          break;
        default:
          break;
      }
    }
  }

  handleBuyPhysicalCopy = () => {
    let isEllUser = false;
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
    const filterConfigs = appConfig.bookshelf_filters;
    if (filterConfigs && filterConfigs.isEnabled && filterConfigs.roleValues.includes(role)) {
      const filterdataURL = role === constants.roleValue ? filterConfigs.primaryurl : filterConfigs.url;
      isEllUser = filterdataURL.includes('pedl') || filterdataURL.includes('pegr') || filterdataURL.includes('elt');
    }
    if (this.state.book.metadata.hasOwnProperty('eCommLink') && isEllUser) {
      return (
        <div tabIndex={0} className="baacFlipBookActions" onClick={this.buyBookCallback.bind(this, this.state.book, this.props.index)}>
          <div className="baacFlipBookActionIcon"><i className="material-icons">shopping_cart</i></div>
          <div className="baacFlipBookActionText">{this.props.physicalCopyText}</div>
        </div>
      );
    }
  }

  handleBookProgress = () => {
    if (this.props.theme == theme.THEME_PRIMARY) {
      if (this.props.book.hasOwnProperty('bookInfo')) {
        if (this.props.book.bookInfo.hasOwnProperty('readingCompletion')) {
          return (
            <div className="baacProgressBarContainer">
              <div className="baacProgressBar" style={{ width: this.props.book.bookInfo.readingCompletion }} />
            </div>
          );
        }
        return (
          <div className="baacProgressBarContainer" />
        );
      }
      return (
        <div className="baacProgressBarContainer" />
      );
    }
    return null;
  }

  renderActivityItem = data => (
    <div className="baacActivityPopoverItem">{data}</div>
  )

  renderActivityPopover = () => {
    if (this.state.showActivityPopover) {
      return (
        <div className="baacActivity" onClick={this.closeActivityPopover.bind(this)}>
          <div className="baacActivityLayer" />
          <div className="baacActivityPopover">
            <div className="baacActivityArrow" />
            <div className="baacActvityPopoverContainer">
              {this.state.book.metadata.activities.map(this.renderActivityItem, this)}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  handleNewBookIndicator = () => {
    if (this.state.book.isNew) {
      return (
        <div className="baacNewIndicator">
          <img src={this.props.newImageURL} />
        </div>
      );
    }
    return null;
  }

  checkBookState = function () {
    const that = this;
    this.updateUiWithProps();
    let thumbnailImage = {};
    if (this.state.book.coverImageURL != '' && this.state.book.coverImageURL != undefined) {
      thumbnailImage = {
        backgroundImage: `url('${this.state.book.coverImageURL}')`
      };
    }
    // if(this.props && this.props.enableBookSelect){
    // thumbnailImage.height = '305px';
    //  }
    if (this.state.book.flippedSrc) {
      return (
        <div tabIndex={0} className="baacTextBookItem baacRelatedBookContainer">
          <div tabIndex={0} className="baacTextBookThumnailImage" style={thumbnailImage} />
          <div tabIndex={0} className="baacTextBookDetails">
            <div className={`baacTextBookName ${this.props.theme == theme.THEME_PRIMARY ? 'baacBookAuthorAndNameForBookBox' : ''}`}>{this.state.book.bookName}</div>
            <div className={`baacTextBookAuthor ${this.props.theme == theme.THEME_PRIMARY ? 'baacBookAuthorAndNameForBookBox' : ''}`}>{this.state.book.author}</div>
          </div>
          <div tabIndex={0} className="baacFlipBookDesc baacDescForRelatedBook">{this.state.book.metadata.information}</div>
        </div>
      );
    }
    if (this.state.book.isFlipped) {
      if (this.props.theme == theme.THEME_PRIMARY) {
        return (
          <div tabIndex={0} style={{ height: '428px' }} className="baacTextBookItem baacTextBookFlipContent primary" onMouseOver={this.handleCheckboxHoverStyle.bind(this)} onMouseOut={this.handleCheckboxMouseOutStyle.bind(this)}>
            <div className="baacFlipClose" onClick={this.handleFlipClose.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)}>
              <i tabIndex={0} alt="Close eBook Info" className="baacFlipClose material-icons">clear</i>
            </div>
            <div tabIndex={0} className="baacFlipBookTitle">{this.state.book.bookName}</div>
            <div tabIndex={0} className="baacFlipBookAuthor">{this.state.book.author}</div>
            <div tabIndex={0} className="baacFlipBookDesc">{this.state.book.metadata.information}</div>
            <div tabIndex={0} onMouseOver={this.handleStylesType1.bind(this)} onMouseOut={this.removeStyles.bind(this)} className={`baacAddToMyBookBox baacReturnBookContainer ${this.props.enableAddBookOption ? '' : 'hide'}`} onClick={this.bookAddCallback.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)}>
              <div className="baacReturnBookOption"><i className="material-icons">add</i></div>
              <div className="baacAddBookOptionText">{this.props.addtoMyBookboxText}</div>
            </div>
            <div tabIndex={0} onMouseOver={this.handleStylesType1.bind(this)} onMouseOut={this.removeStyles.bind(this)} className={`baacReturnBookContainer ${this.state.book.hasOwnProperty('bookInfo') ? ((this.state.book.bookInfo.assignmentType == 'self' && !this.state.book.bookInfo.isReturned) ? '' : 'hide') : 'hide'}`} onClick={this.returnBookCallback.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)}>
              <div className="baacReturnBookOption"><i className="material-icons">clear</i></div>
              <div className="baacReturnBookOptionText">{this.props.returnBookText}</div>
            </div>
            <div tabIndex={0} onMouseOver={this.handleStylesType2.bind(this)} onMouseOut={this.removeStyles.bind(this)} className={`baacReturnBookContainer ${this.props.enableMoreBooksOption ? '' : 'hide'}${this.state.book.hasOwnProperty('bookInfo') ? (this.state.book.bookInfo.assignmentType == 'self' ? '' : ' baacReturnDisabled') : ''}`} onClick={this.moreBooksCallback.bind(this, this.state.book, this.props.index)}>
              <div className="baacMoreBookOption"><i className="material-icons">library_books</i></div>
              <div className="baacMoreBookOptionText">More Books like this</div>
            </div>
          </div>
        );
      }
      return (
        <div tabIndex={0} className="baacTextBookItem baacTextBookFlipContent" onMouseOver={this.handleCheckboxHoverStyle.bind(this)} onMouseOut={this.handleCheckboxMouseOutStyle.bind(this)}>
          <div className="baacFlipClose" onClick={this.handleFlipClose.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)}>
            <i tabIndex={0} alt="Close eBook Info" className="baacFlipClose material-icons">clear</i>
          </div>
          <div tabIndex={0} className="baacFlipBookTitle">{this.state.book.bookName}</div>
          <div tabIndex={0} className="baacFlipBookAuthor">{this.state.book.author}</div>
          <div tabIndex={0} className={`baacFlipBookDesc${this.state.book.metadata.activities ? this.state.book.metadata.activities.length > 0 ? ' hasActivity' : ' noActivity' : ' noActivity'}`}>
            {this.state.book.metadata.information}
          </div>
          <div tabIndex={0} className={`baacFlipBookActivityContent${this.state.book.metadata.activities ? this.state.book.metadata.activities.length > 0 ? ' showActivity' : ' hideActivity' : ' hideActivity'}`}>
            <div className={`baacFlipBookActivityOption ${this.state.book.metadata.activities ? (this.state.book.metadata.activities.length > 0 ? '' : 'baacdisabledClass') : 'baacdisabledClass'}`} onClick={this.handleActivityClick.bind(this, this.state.book)}>
              {this.props.activityText}
              {' '}
(
              {this.state.book.metadata.activities ? this.state.book.metadata.activities.length : 0}
)
            </div>
            {this.renderActivityPopover()}
          </div>
          <div
            tabIndex={0}
            className="baacFlipBookActions"
            onClick={this.handleClick.bind(this, this.state.book, this.props.index)}
            onKeyDown={(event) => {
              if (event.key == 'Enter') {
                that.handleClick.call(that, that.state.book, that.props.index);
              }
            }}
          >
            <div className="baacFlipBookActionIcon"><i className="material-icons">launch</i></div>
            <div className="baacFlipBookActionText">{this.props.openBookText}</div>
          </div>
          <div
            tabIndex={0}
            className={`baacFlipBookActions ${this.state.book.isChecked ? 'baacdisabledClass' : ''}`}
            onClick={this.sendBookCallback.bind(this, this.state.book, this.props.index)}
            onKeyDown={(event) => {
              if (event.key == 'Enter') {
                that.sendBookCallback.call(that, that.state.book, that.props.index);
              }
            }}
          >
            <div className="baacFlipBookActionIcon"><i className="material-icons baacSendBookIconRotate">reply</i></div>
            <div className="baacFlipBookActionText">{this.props.sendBookText}</div>
          </div>
          {this.handleTeachingNotes()}
          {this.handleBuyPhysicalCopy()}
          {this.handleResources()}
          {this.handleLessonPlans()}
        </div>
      );
    }
    return (
      <div
        tabIndex={0}
        onMouseOver={this.handleCheckboxHoverStyle.bind(this)}
        onMouseOut={this.handleCheckboxMouseOutStyle.bind(this)}
        onClick={this.handleClick.bind(this, this.state.book, this.props.index)}
        onKeyDown={(event) => {
          if (event.key == 'Enter') {
            if (event.target.type !== 'checkbox') {
              that.handleClick.call(that, that.state.book, that.props.index);
            }
          }
        }}
        className="baacTextBookItem"
        style={{ border: (this.state.book.isChecked ? '3px solid #22bcb4' : 'none'), height: (this.props.theme == theme.THEME_PRIMARY ? '428px' : '460px') }}
      >
        {this.handleNewBookIndicator()}
        <div className={`baacTextBookSelector ${this.props.enableBookSelect ? 'show' : 'hide'}`}>
          <div className={`baacTriangleShape ${this.state.book.isChecked ? 'baacColouredTriangle' : ''}`} />
          <div className="baacReadingLevelStyle">
            {this.props.book.metadata.readingLevelText}
            {' '}
            <span style={{ fontWeight: 'bold' }}>{(this.state.book.metadata && this.state.book.metadata.readingLevels) ? this.state.book.metadata.readingLevels : ''}</span>
          </div>
          <Checkbox
            name="book_checkbox"
            tabIndex={0}
            className="baacCheckBoxStyle"
            onClick={this.bookCheckedCallback.bind(this, this.state.book, this.props.index)}
            checkedIcon={<i className="material-icons" style={{ color: '#22bcb4' }}>check_box</i>}
            icon={<i className="material-icons" style={{ color: '#74797b' }}>check_box_outline_blank</i>}
                    // onCheck={this.handleCheckboxAction.bind(this, this.props.index)}
            onChange={(event) => {
              if (event.target.checked === true) {
                this.handleCheckboxAction(this.props.index, event, true);
              } else {
                this.handleCheckboxAction(this.props.index, event, false);
              }
            }}
            onKeyDown={(event) => {
              if (event.key == 'Enter') {
                document.activeElement.click();
              }
            }}
            checked={!!this.state.book.isChecked}
          />
        </div>
        <div className="baacTextBookThumnailImage" tabIndex={-1} style={thumbnailImage} />
        <div className="baacTextBookDetails">
          <div tabIndex={-1} className={`baacTextBookName ${this.props.theme == theme.THEME_PRIMARY ? 'baacBookAuthorAndNameForBookBox' : ''}`}>{this.state.book.bookName}</div>
          <div tabIndex={-1} className={`baacTextBookAuthor ${this.props.theme == theme.THEME_PRIMARY ? 'baacBookAuthorAndNameForBookBox' : ''}`}>{this.state.book.author}</div>
        </div>
        <div className="baacOptionalElement" style={this.props.theme == theme.THEME_PRIMARY ? { display: 'block' } : { display: 'none' }}>
          <div className="baacFlipImageHolderPrimary baacFavImageHolder" tabIndex={0} onClick={this.flipBookCallback.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)} style={this.props.theme == theme.THEME_PRIMARY ? this.baacFlipImageHolderForPrimary : {}} />
        </div>
        <div className="baacOptionalElement">
          <div className="baacFavImageHolder" tabIndex={0} onClick={this.favouriteOptionCallback.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)} style={this.baacFavImageHolder} />
          <div className="baacAddImageHolder" tabIndex={0} onClick={this.bookAddCallback.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)} style={this.baacAddImageHolder} />
          <div className="baacFlipImageHolder" tabIndex={0} onClick={this.flipBookCallback.bind(this, this.state.book, this.props.index)} onKeyDown={this.handleAccessibility.bind(this, this.state.book, this.props.index)} style={this.props.theme == theme.THEME_PRIMARY ? {} : this.baacFlipImageHolder} />
        </div>
        {this.handleBookProgress()}
      </div>
    );
  }

  render() {
    return (
      <MuiThemeProvider theme={booktheme}>
        <div className="baacBookItemContainer">
          {this.checkBookState()}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Book;
