import React from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import * as constants from '../../../common/constants';
import DataFormatter from '../../../common/utils/DataFormatter';
import CustomSelectBox from '../../../common/components/customSelectBox';
import ChipComponent from './chips';
import PrepareSearchObjectUtil from '../../../common/utils/prepareSearchObjectUtil';
import ResetIcon from '../../../assets/images/reset.png';
import CommonUtils from '../../../common/utils/CommonUtils';


const styles = {
  flatButtonStyles: {
    marginTop: '14px',
    marginLeft: '1px',
    width: '250px'
  },
  resetFilterLabelHighlightStyles: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '1.25',
    textAlign: 'center',
    color: '#22bcb4'
  },
  resetFilterLabelStyles: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '1.25',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.26)'
  },
  primaryResetFilterLabelStyles: {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff'
  },
  filterHeadingStyle: {
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12)'
  },
  redoStyle: {
    cursor: 'pointer',
    height: '24px',
    paddingRight: '5px',
    marginTop: '-2px'
  }
};
let filterConfig;

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  },
  overrides: {
    MuiPopover: {
      paper: {
        position: 'fixed'
      }
    },
    MuiInputLabel: {
      root: {
        anchorOrigin: 'center left'
      }
    }
  }
});
class FilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilterData = this.updateFilterData.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderFilterElement = this.renderFilterElement.bind(this);
    this.handleFilterElementValueUpdate = this.handleFilterElementValueUpdate.bind(this);
    this.checkFilterSelected = this.checkFilterSelected.bind(this);
    this.handleResetFilterClick = this.handleResetFilterClick.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  state = {
    configFilter: false,
    filterQueue: this.props.filterQueue,
    chipData: this.props.chipData,
    sectionData: this.props.sectionData
  }


  componentDidMount = function () {
    // AppStore.on(AppConstants.EventTypes.FILTER_FETCHED_SUCCESS, this.updateFilterData.bind(this));
    Framework.getEventManager().on(constants.FILTER_FETCHED_SUCCESS, (setFilterData) => {
      // console.log("setFilterData",setFilterData)
      this.updateFilterData(setFilterData);
    });
    window.addEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillMount = function () {
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
    const filterConfigs = appConfig.bookshelf_filters;
    if (filterConfigs && filterConfigs.isEnabled && filterConfigs.roleValues.includes(role)) {
      // let filterdataURL = role === AppConstants.roleValue ? filterConfigs.primaryurl : filterConfigs.url;
      const filterdataURL = role === constants.roleValue ? filterConfigs.primaryurl : filterConfigs.url;
      // AppActions.getFilterData(filterdataURL);
      // for Filter Service Change
      Framework.getEventManager().publish(constants.FILTER_FETCHED_REQUESTED, { furl: filterdataURL });
      this.setState({
        configFilter: true
      });
    }
  }

  handleResize() {
    this.onWindowResize();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      filterQueue: this.props.filterQueue,
      chipData: this.props.chipData,
      sectionData: this.props.sectionData
    });
  }

  componentWillUnmount() {
    // AppStore.removeListener(constants.FILTER_FETCHED_SUCCESS, this.updateFilterData);
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate = function () {
    this.onWindowResize();
  }

  onWindowResize = function () {
    const filterElements = document.getElementsByClassName('divider')[0];
    const searchConsoleFilter = document.getElementsByClassName('searchConsoleFilter')[0];
    const mainContainer = document.getElementById('main');

    if (this.props.showFilter && !this.props.hideFilter) {
      const filterHeader = document.getElementsByClassName('headingMiddleSectionContainer')[0];
      const filterResetButton = document.getElementById('filterResetButton');

      if (filterHeader && searchConsoleFilter) {
        searchConsoleFilter.style.marginRight = `${mainContainer.offsetWidth - mainContainer.clientWidth}px`;
        // filterResetButton.style.marginRight = (mainContainer.offsetWidth - mainContainer.clientWidth) + 'px';
        const chipContainer = document.getElementById('chipContainer');
        if (chipContainer) {
          chipContainer.style.maxHeight = 'none';
          chipContainer.style.width = '100%';
          chipContainer.style.overflowY = 'hidden';
        }

        if ((searchConsoleFilter.offsetHeight - filterHeader.offsetHeight) < 180) {
          filterElements.style.display = 'none';
          if (chipContainer) {
            if (chipContainer.offsetHeight > 380) {
              chipContainer.style.maxHeight = '400px';
              chipContainer.style.width = '85%';
              chipContainer.style.overflowY = 'auto';
            }
          }
        } else {
          filterElements.style.display = 'block';
          const maxHeight = searchConsoleFilter.offsetHeight - filterResetButton.offsetHeight - filterHeader.offsetHeight - 105;
          filterElements.style.maxHeight = `${maxHeight}px`;
          if (maxHeight > (filterElements.offsetHeight + 15)) {
            filterElements.style.overflow = 'hidden';
          } else {
            filterElements.style.overflow = 'auto';
          }
        }
      }
    }
  }

  updateFilterData = function (filterData) {
    // filterConfig = AppStore.filterData;
    filterConfig = filterData;
  }

  handleFilterElementValueUpdate(filterName, filterStateValueArray, selectedSections) {
    DataFormatter.setObjectInStorage('restrictedProductCall', true);
    const filterData = JSON.parse(JSON.stringify(this.state.filterQueue));
    let _sectionData = {};
    const chips = this.state.chipData;
    let len = chips.length;
    let chip;
    filterData[filterName] = [...filterStateValueArray];
    if (selectedSections && selectedSections.length > 0) {
      _sectionData = JSON.parse(JSON.stringify(this.state.sectionData));
      if (Object.keys(this.state.sectionData).length === 0) {
        _sectionData[filterName] = [...selectedSections];
        for (const [key, value] of Object.entries(filterData)) {
          if (key !== filterName) {
            _sectionData[key] = value;
          }
        }
      } else {
        _sectionData[filterName] = filterName === 'readingLevels' ? [...selectedSections] : [...filterStateValueArray];
      }
    }
    while (len--) {
      if (chips[len].filterName == filterName) {
        chips.splice(len, 1);
      }
    }
    for (const filterStateValue of filterStateValueArray) {
      if (filterStateValue.hasOwnProperty('subValue')) {
        for (const filterStateSubvalue of filterStateValue.subValue) {
          chip = { filterName, filterValue: filterStateSubvalue.value, filterLabel: filterStateSubvalue.labelValue };
          chips.push(chip);
        }
      } else {
        chip = { filterName, filterValue: filterStateValue.value, filterLabel: filterStateValue.labelValue };
        chips.push(chip);
      }
    }
    this.setState({
      filterQueue: filterData,
      chipData: chips,
      sectionData: _sectionData
    }, function () {
      this.props.doSearchCall(filterData, chips, _sectionData, true);
    });
  }

  renderFilterElement() {
    let filterComponent = [];
    if (filterConfig && filterConfig.filters instanceof Array && filterConfig.filters.length > 0) {
      for (let i = 0, iLen = filterConfig.filters.length; i < iLen; i++) {
        const ifilterConfig = filterConfig.filters[i];
        if (ifilterConfig && ifilterConfig.type && ifilterConfig.name) {
          filterComponent = filterComponent.concat(<CustomSelectBox key={i.toString()} mode={this.props.mode} index={i} infoButtonUrl={ifilterConfig.infoButtonUrl} filterConfig={ifilterConfig} filterValue={this.state.filterQueue[ifilterConfig.name]} sectionData={this.state.sectionData[ifilterConfig.name]} onFilterValueUpdate={this.handleFilterElementValueUpdate.bind(this, ifilterConfig.name)} showFilter={this.props.showFilter} />);
        }
      }
    }
    return filterComponent;
  }

  renderChips = () => {
    if (this.state.chipData.length > 0) {
      return (
        <ChipComponent
          chipData={this.state.chipData}
          UpdatehiddenCountOfChip={count => this.props.UpdatehiddenCountOfChip(count)}
          reload={this.props.reload}
          handleChipDelete={this.handleChipDelete}
          renderFilterElements={this.renderFilterElements}
          hiddenCountOfChip={this.props.hiddenCountOfChip}
        />
      );
    }
    return null;
  }

  renderFilterElements = () => {
    this.setState({ updateFilter: true });
  }

  handleChipDelete = (data) => {
    const filters = this.state.filterQueue;
    let sections = {};
    if (this.state.sectionData) {
      sections = this.state.sectionData;
    }
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    for (let valueIndex = 0; valueIndex < filters[data.filterName].length; valueIndex++) {
      // if (data.filterName !== 'readingLevels' || role === AppConstants.roleValue) {
      if (data.filterName !== 'readingLevels' || role === constants.roleValue) {
        if (filters[data.filterName][valueIndex].value == data.filterValue) {
          filters[data.filterName].splice(valueIndex, 1);
        }
        if (sections[data.filterName] && sections[data.filterName].length > 0 && sections[data.filterName][valueIndex].value == data.filterValue) {
          sections[data.filterName].splice(valueIndex, 1);
        }
      } else {
        for (let subValueIndex = 0; subValueIndex < filters[data.filterName][valueIndex].subValue.length; subValueIndex++) {
          if (filters[data.filterName][valueIndex].subValue[subValueIndex].value == data.filterValue) {
            const currentVal = filters[data.filterName][valueIndex].subValue[subValueIndex];
            if (sections && sections[data.filterName] && sections[data.filterName].length > 0 && currentVal.other && currentVal.other.aliasValue) {
              let subValueArray = sections[data.filterName][0].subValue;
              subValueArray = subValueArray.filter(subvalue => !currentVal.other.aliasValue.includes(subvalue.value));
              if (subValueArray.length === 0) {
                sections = {};
              } else {
                sections[data.filterName][0].subValue = subValueArray;
              }
            }
            filters[data.filterName][valueIndex].subValue.splice(subValueIndex, 1);
          }
        }
        if (filters[data.filterName][valueIndex].subValue.length == 0) {
          filters[data.filterName].splice(valueIndex, 1);
        }
      }
    }
    if (filters[data.filterName].length == 0) {
      delete (filters[data.filterName]);
    }
    if (Object.keys(sections).length > 0 && sections[data.filterName].length == 0) {
      delete (sections[data.filterName]);
    }
    const chips = this.state.chipData;
    for (let chipIndex = 0; chipIndex < chips.length; chipIndex++) {
      if (chips[chipIndex].filterValue == data.filterValue && chips[chipIndex].filterName == data.filterName) {
        chips.splice(chipIndex, 1);
      }
    }
    this.setState({
      filterQueue: filters,
      chipData: chips,
      sectionData: sections
    }, () => {
      try {
        const elements = $('.baacTextBookItem');
        for (let i = 0; i < elements.length; i++) {
          elements[i].children[0].style.border = 'none';
        }
      } catch (e) { }
      if (filters.length === 0) {
        DataFormatter.setObjectInStorage('restrictedProductCall', false);
      }
      this.props.doSearchCall(filters, chips, sections, true);
    });
  }

  checkFilterSelected() {
    const { filterQueue } = this.state;
    for (const filterName in filterQueue) {
      if (filterQueue[filterName] && filterQueue[filterName].length > 0) {
        return true;
      }
    }
    return false;
  }

  handleResetFilterClick() {
    const { language } = this.props;
    if (DataFormatter.getObjectInStorage('restrictedProductCall') === false) {
      return false;
    }
    DataFormatter.setObjectInStorage('restrictedProductCall', false);
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    DataFormatter.setObjectInSessionStorage('searchState', null);
    DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    let filterCancel = '';
    if (role === 'teacher') {
      filterCancel = 'filter_cancel_teacher';
    } else if (role === 'student') {
      filterCancel = 'search_cancel_student';
    }
    let eventCategory; let
      eventAction = '';
    const filterObj = PrepareSearchObjectUtil.prepareRefineSearchObject('', this.props.filterQueue);
    if (filterObj && filterObj.hasOwnProperty('metadata') && Object.keys(filterObj.metadata).length !== 0) {
      eventCategory = language.getMessage('filter_cancel');
      eventAction = language.getMessage('filter_cancel_executed');
    }
    if (eventCategory) {
      const gtmData = {
        dataLayer: {
          event: filterCancel,
          event_category: eventCategory,
          event_action: eventAction,
          event_label: null,
          user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
          filter_option: (filterObj && filterObj.hasOwnProperty('metadata')) ? `Number of filter options: ${Object.keys(filterObj.metadata).length}` : null,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }

    document.getElementById('hiddenCountOfChip').setAttribute('value', 0);
    this.setState({
      filterQueue: {},
      chipData: [],
      sectionData: {}
    }, () => {
      if ((typeof this.props.searchText === 'string' && this.props.searchText.trim() != '')) {
        DataFormatter.setObjectInStorage('restrictedProductCall', true);
        this.props.doSearchCall({}, [], {}, true);
      } else {
        this.props.doSearchCall({}, [], {}, null, true);
      }
      this.props.resetChipCount();
    });
  }

  renderFilter() {
    const { language } = this.props;
    const maxHeight = 500;
    const filterResetButtonStyle = {};
    let labelStyle = {};
    let labelHighLightStyle = {};
    const isFilterSelected = this.checkFilterSelected();
    let resetImage = '';
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    const filerSectionHeader = (role === 'teacher') ? 'refineByFilterHeader' : 'chooseFilterHeader';
    const resetText = (role === 'teacher') ? 'resetFilterButtonLabel' : 'resetButtonLabel';
    // const filerSectionHeader = (role === 'teacher') ? "refineByFilterHeader" : "chooseFilterHeader";
    // const resetText = (role === 'teacher') ? "resetFilterButtonLabel" : "resetButtonLabel"
    let filterHeadingStyle = { height: 'auto', display: 'table' };
    if (this.props.mode == 'secondary') {
      if (this.props.showingSendBookFooter) {
        filterResetButtonStyle.bottom = '8%';
      }
      labelStyle = styles.resetFilterLabelStyles;
      labelHighLightStyle = styles.resetFilterLabelHighlightStyles;
    } else {
      filterHeadingStyle = { ...filterHeadingStyle, ...styles.filterHeadingStyle };
      filterResetButtonStyle.backgroundColor = (isFilterSelected ? '#db0020' : '#636769');
      labelStyle = styles.primaryResetFilterLabelStyles;
      labelHighLightStyle = styles.primaryResetFilterLabelStyles;
      resetImage = <img src={ResetIcon} style={styles.redoStyle} onClick={this.handleResetFilterClick} />;
    }
    const isiPad = navigator.userAgent.match(/iPad/i) != null;
    if (isiPad || navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC' || navigator.platform === 'Mac68K') {
      filterResetButtonStyle.marginRight = 0;
    }
    return (
      <div className="middleSection">
        <div style={filterHeadingStyle} className="headingMiddleSectionContainer">
          <div style={{ display: 'table-row' }}>
            {' '}
            <p className="headingMiddleSection">
              {
                language.getMessage(filerSectionHeader)
              }
            </p>
          </div>
          {this.renderChips()}
        </div>
        <div style={{ maxHeight: `${maxHeight}px`, display: 'block', paddingBottom: '25px' }} className="divider">
          <div style={{ padding: '1px' }}>
            {this.renderFilterElement()}
          </div>
        </div>
        <div id="filterResetButton" style={filterResetButtonStyle} className="filterActionButtonWrapper">
          <Button
            className="resetButtonClass"
            style={styles.flatButtonStyles}
            onClick={this.handleResetFilterClick}
            disabled={this.state.chipData.length === 0}
            onBlur={this.setFocusToClose}
          >
            {/* {resetImage}<span style={(isFilterSelected ? labelHighLightStyle : labelStyle)}>{intl.formatMessage(language.getText(resetText))}</span> */}
            {resetImage}
            <span style={(isFilterSelected ? labelHighLightStyle : labelStyle)}>
              {
                language.getMessage(resetText)
              }
            </span>
          </Button>
        </div>
      </div>

    );
  }

  setFocusToClose = () => {
    const filterCloseIcon = document.getElementById('filterCloseIcon');
    if (filterCloseIcon) {
      filterCloseIcon.focus();
    }
  }

  render() {
    const searchFilterStyle = {};
    const isiPad = navigator.userAgent.match(/iPad/i) != null;
    if (isiPad) {
      searchFilterStyle.marginRight = 0;
    }
    if (this.props.mode == 'secondary') {
      if (this.props.showingSendBookFooter) {
        searchFilterStyle.height = '84%';
      }
    }
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div
          id="searchConsoleFilter"
          style={searchFilterStyle}
          className={`secondaryFilterContainer searchConsoleFilter${
            this.state.configFilter && this.props.showFilter ? '' : ' hidden'
          }${this.props.hideFilter ? ' hidden' : ''}`}
        >
          {this.renderFilter()}
        </div>
      </MuiThemeProvider>
    );
  }
}

FilterComponent.propTypes = {
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language'),
  observer
)(injectIntl(FilterComponent));
