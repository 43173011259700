/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { parse } from 'query-string';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {
  Link
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import pearsonTheme from '@greenville/mui-theme';
import Framework, { Container } from '@greenville/framework';

import LanguageContext from './contexts/LanguageContext';
import modules from './modules';
import languages from './common/languages';
import env from './common/env';
import * as constants from './common/constants';
import { STUDENT_CLIENT_ID } from './common/constants';
import ExpiryMessage from './common/components/ExpiryMessage';
import './App.css';
import './assets/styles/css/bootstrap.min.css';
import './assets/styles/css/material-font/material.css';
import './assets/styles/less/main.less';
import favIcon from './assets/images/favicon.ico';
import TagManager from 'react-gtm-module';

const intlBuilder = Framework.getIntlBuilder();

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    this.favIcon = favIcon;// `${env.CONTENT_HOST}/favicon.ico`;
    this.title = constants.PEARSON_DL_TITLE;
    this.metaData = constants.PEARSON_ETEXT_META;
    if (window.location.hostname.includes(constants.SPARK)) {
      // this.favIcon = `${env.CONTENT_HOST}/mojo_favicon_v1.png`;
      this.title = constants.PEARSON_DL_SPARK_TITLE;
      this.metaData = constants.PEARSON_ETEXT_META;
    }
    const queryString = parse(window.location.search);
    let locale = constants.DEFAULT_LOCALE;
    const isLocaleAvailable = queryString.locale
      && languages.find(language => language.locale === queryString.locale);

    if (queryString.locale && isLocaleAvailable) {
      locale = queryString.locale;
    }
    this.state = {
      locale,
      browserLocale: true
    };

    languages.forEach(language => intlBuilder.add(language.locale, language.messages));

    intlBuilder.setDefaultLang(locale).addPseudoLocale(env.PSEUDO_LOCALE_CODE).build();

    const moduleBuilder = Framework.getModuleBuilder();

    if (modules.length > 0) {
      modules.forEach(module => moduleBuilder.add(module));
      modules.length = 0;
      this.modules = moduleBuilder.build();
    }

    // Initialize GTM
    const initGTM = {
      gtmId: env.GTM_CONTAINER_ID,
      auth: env.GTM_AUTH,
      preview: env.GTM_PREVIEW
    };
    // console.log("gtm env: ", env.GTM_PREVIEW);
    TagManager.initialize(initGTM);
  }

  /**
   * Handles locale change
   *
   * @param {string} locale - locale to change
   */
  handleLocaleChange = (locale) => {
    // updating the default language in the IntlBuilder
    intlBuilder
      .setDefaultLang(locale)
      .addPseudoLocale(env.PSEUDO_LOCALE_CODE)
      .build();
    this.setState({
      locale,
      browserLocale: false
    });
  };

  render() {
    const currentPath = window.location.pathname;
    const isRouteMatched = constants.ROUTE_LIST.indexOf(currentPath) !== -1;
    const userClientId = localStorage.getItem('usertype') == 'student' ? STUDENT_CLIENT_ID : env.CLIENT_ID;
    const component = isRouteMatched ? (
      <MuiThemeProvider theme={createTheme(pearsonTheme)}>
        <CssBaseline />
        <LanguageContext.Provider
          value={{
            onLocaleChange: this.handleLocaleChange,
            locale: this.state.locale
          }}
        >
          <Helmet>
            <link rel="shortcut icon" id="favicon" href={this.favIcon} />
            <meta name="description" content={this.metaData} />
            <title>{this.title}</title>
          </Helmet>
          <Container
            authType="IES"
            authClientId={userClientId}
            browserLocale={this.state.browserLocale}
            browserRequirementsUrl={env.BROWSER_REQUIREMENTS_URL}
            correlationIdPrefix="GVET"
            gtmArgs={{
              gtmId: env.GTM_CONTAINER_ID,
              auth: env.GTM_AUTH,
              preview: env.GTM_PREVIEW
            }}
            delayedGTM
            mojoMVP
            browserCheck={false}
            modules={this.modules}
            product={env.PRODUCT}
            partnerEnv={env.PARTNER_ENV}
            partnerEnvConfig={
              {
                LOGOUT_REDIRECTION_URL: constants.LOGIN_URL,
                USER_PROFILE_BASE_URL: env.MARIN_API_BASE_URL
              }
            }
            version="1.0.7"
            sessionDuration={constants.SESSION_DURATION}
            noAuthRoutes={constants.NO_AUTH_ROUTES}
          />
        </LanguageContext.Provider>
      </MuiThemeProvider>
    ) : (
      <div className='noMatchWrapper'>
        <ExpiryMessage
          title="Looks like there's nothing here"
          description={(<>Try going to the <a href='/pages/selectrole'>home page</a>.</>)}
        />
      </div>
    );
    return component;
  }
}
