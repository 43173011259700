export const DRAWER_STATE_CLOSED = 'closed';
export const SPDF = 'spdf';
export const EPUB = 'epub';
export const PDF = 'pdf';
export const CLIENT_APP = 'READER_PLUS';
export const CLOUDFRONT_URL = 'https://d38l3k3yaet8r2.cloudfront.net/';
export const STUDENT = 'student';
export const YL = 'YL';
export const MY_BOOKBOX = 'my-bookbox';
export const SINGLE_PAGE = 'single_page';
export const DOUBLE_PAGE = 'double_page';
export const HOST_URL = `https://${window.location.host}`;
export const DEFAULT_LANG = 'en';
