/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Expiry Message Banner
 *
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

const Styles = () => ({
  expiryWrapper: {
    width: '90%',
    margin: '50px auto 0 auto',
    padding: 32,
    textAlign: 'center',
    borderRadius: 12,
    backgroundColor: '#FFFFFF'
  },
  expiryTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 15
  }
});

const ExpiryMessage = (props) => {
  const { classes, title, description } = props;
  return (
    <div className={classes.expiryWrapper}>
      {
        title && (
        <Typography variant="h2" className={classes.expiryTitle}>
            {title}
        </Typography>
        )
      }
      {
        description && (
        <Typography variant="paragraph">
            {description}
        </Typography>
        )
      }
    </div>
  );
};

ExpiryMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
};

ExpiryMessage.defaultProps = {
  title: '',
  description: ''
};

export default withStyles(Styles)(ExpiryMessage);
