/* eslint-disable linebreak-style */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import SelectRole from './components/SelectRole';

function PageContainer(props) {
  const { match } = props;
  const { pageName } = match.params;
  return renderSwitch(pageName, props);
}

const renderSwitch = (param, props) => {
  switch (param) {
    case 'selectrole':
      return <SelectRole pageName={param} props={props} />;
    default:
      return <SelectRole pageName={param} props={props} />;
  }
};

// PageContainer.propTypes = {
//   // match: shapes.match.isRequired,
//   // history: shapes.history.isRequired,
//   intl: intlShape.isRequired,
//   language: PropTypes.object.isRequired
// };

// export default injectIntl(PageContainer);
export default compose(
  inject('language'),
  observer
)(injectIntl(PageContainer));
