import env from '../env';

const fr = {
  continue: 'continuer',
  username: "Nom d'utilisateur",
  password: 'Entrer un mot de passe',
  back: 'Fin',
  sign_in: 'Se connecter',
  invalid_user: "Nom d'utilisateur non valide",
  invalid_password: 'Mot de passe non valide',
  username_empty: "Veuillez entre votre nom d'utilisateur",
  password_empty: 'Veuillez entrer votre mot de passe',
  brand: 'Pearson',
  skip_to_main_content: 'Aller au contenu principal',
  school_library: 'Bibliothèque',
  students: 'Élèves',
  my_bookbox: 'Ma boîte de livres',
  sign_out_label: 'Se déconnecter',
  course_selection_label: 'Cours',
  keywordSearch: 'Recherche par titre, auteur ou mot-clé',
  recentSearches: 'Recherches récentes',
  findBooks: 'Trouver les livres',
  filterButtonLabel: 'Filtres',
  cancelButtonLabel: 'Annuler',
  resetFilterButtonLabel: 'Réinitialiser le filtres',
  resetButtonLabel: 'RÉINITIALISER',
  doneButtonLabel: 'Trouver',
  refineByFilterHeader: 'Affiner par',
  chooseFilterHeader: 'Choisir',
  NoOfbooksSuffix: 'livres',
  roleValue: 'Élève',
  select_all_button_label: 'Sélectionner tout',
  cancel_button_label: 'Annuler',
  send_books_button_label: 'Envoyer les livres',
  student: 'élève',
  provision_success: 'Les livres ont été envoyés',
  provision_failed: "Erreur - l'envoi de livres a échoué",
  primary: 'élémentaire',
  secondary: 'secondaire',
  selfassigned: 'Pour soi',
  teacherassigned: 'Enseignant',
  interactiveActivity: 'Activités interactives',
  selectStudents: "Sélection d'élèves",
  NoBookFound: "Désolé, aucun livre n'a été trouvé",
  NoBookFoundSuffix1: 'Veuillez essayer de nouveau',
  terms: "Termes d'utilisation",
  cookies: 'Cookies',
  privacy: 'Politique de confidentialité',
  copyright: 'Pearson Education',
  copy_right: "Droit d'auteur",
  copy_right_clicked: "Droit d'auteur cliqué",
  help: 'Aide',
  notificationMessagePrefix: 'Votre enseignant vous a envoyé ',
  noNotificationMessagePrefix: 'Aucune notification ',
  Total: 'Total',
  AverageStats: 'Moyenne de livres lus par élève',
  Overall: "Dans l'ensemble",
  This_Month: 'Ce mois-çi',
  This_Year: 'Cette année',
  Books_Read: 'Livres lus',
  BookBox: 'Boîte de livres',
  Book_Title: 'Titre du livre',
  Date_Sent: 'Date_envoi',
  Date_text: 'Date',
  Complete: 'Complet',
  Pages: 'Pages',
  Read: 'Livre',
  Audio: 'Audio',
  Used: 'Utilisé',
  Favorite: 'Favori',
  Self_text: 'Pour soi',
  Allocated: 'Sélectionné',
  In_bookbox: 'dans la boîte de livres',
  Unread_books: 'Livres non lus',
  Books_Read_dashboard: 'Livres lus',
  Number_of: 'Nombre de',
  Student_name: "Nom de l'élève",
  unread_in: 'Non lu',
  remove_books: 'Retirer des livres',
  notifications: 'Notifications',
  set_config: "Configuration de l'app non définie",
  book_selected: 'livre sélectionné',
  books_selected: 'livres sélectionnés',
  books_removed_pural: 'livres retirés pour cet élève',
  book_removed_singular: '1 livre a été retiré pour cet élève',
  manage_bookbox: 'Gérer la boîte de livres',
  view_all: 'Tout voir',
  view_selected: 'Voir cliqué',
  login: 'Connexion',
  login_clicked: 'Connexion cliqué',
  logout_clicked: 'Se déconnecter cliqué',
  terms_clicked: 'Termes cliqué',
  cookie_clicked: 'Cookie cliqué',
  policy_clicked: 'Confidentialité cliqué',
  open_book: 'Ouvrir le livre',
  book_clicked: 'Livre cliqué',
  flip_book_teacher: 'Flip book - Enseignant',
  book_turnback: 'Livre (inverser)',
  send_book: 'Envoyer le livre',
  send_book_clicked: 'Envoyer le livre cliqué',
  teaching_notes: "Notes d'enseignement",
  teaching_notes_clicked: "Notes d'enseignement cliqué",
  keyword_search: 'Recherche par mot-clé',
  search_executed: 'Recherche effectuée',
  filter_option: 'Option de filtrage',
  filter_executed: 'Recherche par filtre effectuée',
  my_bookbox_tab: 'Onglet de Ma boîte de livres',
  my_bookbox_tab_clicked: 'Ma boîte de livres cliqué',
  favourite: 'Favori',
  favourite_clicked: 'Favori cliqué',
  flip_book_student: 'Flip book - Élève',
  return_book: 'Retourner le livre',
  return_book_clicked: 'Livre retourné cliqué',
  more_book: 'Plus de livres',
  more_book_clicked: 'Plus de livres cliqué',
  add_book: 'Ajout de livre',
  add_book_clicked: 'Ajout de livre cliqué',
  adding_student: 'Ajouter un étudiant',
  add_student_clicked: "Ajout d'étudiant cliqué",
  deleting_student: 'Supprimer un étudiant',
  delete_student_clicked: 'Supprimer un étudiant cliqué',
  editing_student: "Modifier l'étudiant",
  edit_student_clicked: "Modifier l'étudiant cliqué",
  editing_classname: 'Modifier le nom de classe',
  edit_classname_clicked: 'Modifier le nom de classe',
  adding_notes: 'Ajouter des notes',
  add_notes_clicked: 'Ajouter des notes cliqué',
  notification: 'Notification',
  notification_clicked: 'Notification cliqué',
  school_library_tab: "Onglet de la bibliothèque de l'école",
  school_library_tab_clicked: "Onglet de la bibliothèque de l'école cliqué",
  search_cancel: 'Recherche annulée',
  search_cancel_executed: 'Annulation de la recherche effectuée',
  filter_cancel: 'Annulation du filtre',
  filter_cancel_executed: 'Annulation du filtre effectuée',
  student_tab: "Onglet de l'élève",
  student_tab_clicked: "Onglet de l'élève cliqué",
  student_list: 'Liste des élèves',
  student_name_clicked: "Nom de l'élève cliqué",
  remove_book: 'Retirer le livre',
  remove_book_clicked: 'Retirer le livre cliqué',
  selection_reached_header: 'Atteinte de la limite de sélection',
  selection_reached_message: "L'ensemble des résultats contient plus de 100 livres.  Nous avons sélectionné les premiers 100 pour vous.",
  Guided: 'Guidé',
  Reading_Level: 'Niveau de lecture',
  books_i_choose: 'Livres / Choisir',
  book_suffix: 'livre',
  from_my_teacher: 'De mon enseignant',
  my_book_box: 'Ma boîte de livres',
  SessionExpired: 'Votre session a expiré.  Veuillez vous connecter de nouveau pour continuer.',
  network_not_available: 'Réseau non disponible',
  null_check_username_password: "Veuillez entrer votre nom d'utilisateur et votre mot de passe",
  check_network: 'Veuillez vérifier le réseau et essayer de nouveau.',
  NoSchoolsAssignedLoggedOut: 'Aucune école assignée',
  invalid_server_response: 'Réponse du serveur non valide.',
  book_already_provisioned: 'Le livre a déjà été transmis.',
  Off_label: 'Ne pas inclure',
  On_label: 'Inclure',
  okButtonLabel: "D'accord",
  take_a_look: 'Voir un aperçu',
  select_all: 'Tout sélectionner',
  Of: 'de',
  EmptyBookShelfHeader: 'Aucun contenu disponible',
  EmptyBookShelfHint: 'Cliquez ici pour réinitialiser votre biblio',
  Logout: 'Se déconnecter',
  Save: 'Sauvegarder',
  Cancel: 'Annuler',
  app_logo: 'R +',
  reader_plus: 'Reader +',
  search: 'Rechercher',
  search_bookshelf: 'Rechercher dans la biblio',
  unknown: 'Inconnu',
  version: 'Version',
  close: 'Fermer',
  Delete: 'Supprimer',
  logout: 'Déconnexion',
  my_bookshelf: 'Ma biblio',
  page: 'Page',
  resources_title: 'Ressources',
  ok: "D'accord",
  empty_bookshelf_message: 'Cliquez ici afin de réactualiser votre biblio.',
  bookmarks_toolbar_select_exit_btn: 'Terminé',
  bookmarks_toolbar_edit_bar_cancel: 'Annuler',
  all_resource_filter_panel_search_hint_txt: 'Taper un mot-clé ou une phrase',
  download_manager_modal_action_button_label: 'Appliquer',
  ReadBook: 'Lire le livre',
  Bookshelf: 'Ma biblio',
  add_to_mybookbox: 'Ajouter ce livre à ma boîte',
  book_added: 'Livre ajouté',
  book_returned: 'Livre retourné',
  return_to_school_library: "Retourner ce livre à la bibliothèque de l'école",
  items_selected: 'Sélection',
  welcome: 'Bienvenue',
  filter_all: 'Tout',
  filter_returned: 'Retourné',
  filter_new: 'Nouveau',
  filter_reading: 'Je lis',
  filter_finished: 'Terminé',
  filter_liked: 'Aimé',
  teaching_notes_baac: "Notes d'enseignement",
  teachers_notes: "Les notes de l'enseignant",
  resources_baac: 'Ressources',
  lesson_plans_baac: 'Plans de la leçon',
  activity: 'Activité',
  physical_copy: 'Acheter une copie physique',
  addto_my_bookbox: 'Ajouter à ma boîte',
  see_more: 'Voir plus',
  show_less: 'Voir moins',
  book: 'livre',
  filter_sections: 'Sections',
  filter_color_bands: 'Bandes de couleur',
  'filter_lessthan-A1-GSE:15-22': '<A1 (GSE:15-22)',
  'filter_equals-A1-GSE:19-28': 'A1 (GSE:19-28)',
  'filter_A1-A2-GSE:25-36': 'A1-A2 (GSE:25-36)',
  'filter_A2-A2+GSE:33-41': 'A2-A2+ (GSE:33-41)',
  'filter_A2+-B1GSE:38-46': 'A2+-B1 (GSE:38-46)',
  'filter_greaterthanB1-GSE:Above 47': '>B1 (GSE: Above 47)',
  'filter_elt_GSE_16-27': 'GSE [16-27]/CEFR [<A1 - A1]',
  'filter_elt_GSE_22-32': 'GSE [22-32]/CEFR [A1 - A2]',
  'filter_elt_GSE_25-35': 'GSE [25-35]/CEFR [A1 - A2]',
  'filter_elt_GSE_27-38': 'GSE [27-38]/CEFR [A1 - A2+]',
  'filter_elt_GSE_30-40': 'GSE [30-40]/CEFR [A2 - A2+]',
  'filter_elt_GSE_36-48': 'GSE [36-48]/CEFR [A2+ - B1]',
  filter_pink: 'Rose',
  filter_red: 'Rouge',
  filter_orange: 'Orange',
  filter_yellow: 'Jaune',
  filter_green: 'Vert',
  filter_blue: 'Bleu',
  filter_aqua: 'Turquoise',
  filter_purple: 'Violet',
  filter_brown: 'Brun',
  filter_white: 'White',
  filter_levels: 'Niveaux',
  filter_level1: 'Niveaux 1',
  filter_level2: 'Niveaux 2',
  filter_level3: 'Niveaux 3',
  filter_level4: 'Niveaux 4',
  filter_level5: 'Niveaux 5',
  filter_level6: 'Niveaux 6',
  'filter_(levels A–B): Initial': '(niveaux A et B) : Initial',
  'filter_(levels C–D): Early Emergent': '(niveaux C et D) : Émergent débutant',
  'filter_(levels D–E): Emergent': '(niveaux C et D) : Émergent',
  'filter_(levels F–H): Early': '(niveaux F à H) : Débutant',
  'filter_(levels I–K): Developing': '(niveaux I à K) : En développement',
  'filter_(levels L–N): Transitional': '(niveaux L à N) ; En transition',
  'filter_(levels O–Q): Fluent': '(niveaux O à Q) : Compétent',
  'filter_(levels R–U): Independent': '(niveaux R à U) : Indépendant',
  'filter_(levels V–Z): Proficient': '(niveaux V à Z) : Expert',
  filter_guided_reading: 'Lecture guidée',
  filter_a: 'A',
  filter_b: 'B',
  filter_c: 'C',
  filter_d: 'D',
  filter_e: 'E',
  filter_f: 'F',
  filter_g: 'G',
  filter_h: 'H',
  filter_i: 'I',
  filter_j: 'J',
  filter_k: 'K',
  filter_l: 'L',
  filter_m: 'M',
  filter_n: 'N',
  filter_O: 'O',
  filter_p: 'P',
  filter_q: 'Q',
  filter_r: 'R',
  filter_s: 'S',
  filter_t: 'T',
  filter_u: 'U',
  filter_v: 'V',
  filter_w: 'W',
  filter_x: 'X',
  filter_y: 'Y',
  filter_z: 'Z',
  filter_dra: 'DRA',
  filter_a12: 'A, 1, 2',
  filter_3: '3',
  filter_4: '4',
  'filter_6-8': '6-8',
  filter_10: '10',
  filter_12: '12',
  filter_14: '14',
  filter_16: '16',
  filter_18: '18',
  filter_20: '20',
  filter_24: '24',
  filter_28: '28',
  filter_30: '30',
  filter_34: '34',
  filter_38: '38',
  filter_40: '40',
  filter_50: '50',
  filter_60: '60',
  'filter_70-80': '70-80',
  'filter_topic of Interest/Inquiry': "Sujet d'intérêt/enquête",
  filter_adventure: 'Aventure',
  filter_animals: 'Animaux',
  'filter_animals/Insects': 'Animaux/insectes',
  'filter_animals/Bugs': 'Animaux/insectes',
  filter_bullying: 'Intimidation',
  filter_courage: 'Courage',
  filter_environment: 'Environnement',
  filter_families: 'Familles',
  filter_food: 'Nourriture',
  filter_friendship: 'Amitié',
  filter_indigenous_peoples: 'Peuples autochtones',
  filter_media: 'Média',
  filter_numbers: 'Nombres',
  filter_space: 'Espace',
  filter_sports: 'Sports',
  filter_technology: 'Technologie',
  filter_the_human_body: 'Le corps humain',
  filter_transportation: 'Transport',
  filter_communities: 'Communautés',
  filter_plants: 'Plantes',
  filter_subject_area: "Matière à l'étude",
  filter_science: 'Sciences',
  'filter_health_and_well-being': 'Santé et bien-être',
  filter_mathematics: 'Mathématiques',
  filter_social_studies: 'Études sociales',
  filter_the_arts: 'Les arts',
  'filter_genre/Text_type': 'Genre/type de texte',
  'filter_non-fiction': 'Informatif',
  filter_Fiction: 'Fiction',
  filter_biography: 'Biographie',
  filter_description: 'Description',
  filter_explanation: 'Explication',
  filter_procedure: 'Procédure',
  filter_report: 'Entrevue',
  filter_retell: 'Redire dans ses mots',
  filter_graphic_text: 'Texte graphique',
  filter_traditional_story: 'Récit traditionnel',
  'filter_fantasy/sci-fi': 'Fantaisie/science-fiction',
  filter_comprehension_strategies: 'Stratégies de compréhension',
  filter_comprehension_fluency: 'Compréhension/Maîtrise',
  filter_activating_prior_knowledge: 'Activer les connaissances antérieures',
  filter_analyzing_and_evaluating: 'Analyser et évaluer',
  filter_inferring: 'Faire des inférences',
  filter_predicting: 'Faire des prédictions',
  filter_summarizing: 'Faire une synthèse',
  filter_using_text_features: 'Utiliser des éléments du texte',
  'filter_listen-to-myself-reading': "M'écouter lire",
  'filter_language-of-books': 'La langue des livres',
  'filter_english-only': 'Anglais uniquement',
  'filter_french-only': 'Français uniquement',
  filter_phonics: 'Livres décodables',
  filter_all_decodable_books: 'Tous les livres décodables',
  filter_set00_wordless_books: 'Ensemble 00: Livres sans texte',
  filter_set1_2_satpinmd: 'Ensemble 1-2: s a t p i n m d',
  filter_set3_gock: 'Ensemble 3: g o c k',
  filter_set4_ckeur: 'Ensemble 4: ck e u r',
  filter_set5_hbffflllss: 'Ensemble 5: h b f ff l ll ss',
  filter_set6_jvwx: 'Ensemble 6: j v w x',
  filter_set7_yzzzqu: 'Ensemble 7: y z zz qu',
  filter_set8_chshthng: 'Ensemble 8: ch sh th ng',
  filter_set9_aieeighoaoo: 'Ensemble 9: ai ee igh oa oo',
  filter_set10_arorurowoi: 'Ensemble 10: ar or ur ow oi',
  filter_set11_earairureer: 'Ensemble 11: ear air ure er',
  filter_set12_Consolidation: 'Ensemble 12: Ensembles de consolidation 1-11',
  filter_set13_whph: 'Ensemble 13: wh ph',
  filter_set14_ayaeeigh: 'Ensemble 14: /ai/ ay a-e eigh ey ei',
  filter_set15_eaeeie: 'Ensemble 15: /ee/ ea e-e ie ey y',
  filter_set16_ieieyI: 'Ensemble 16: /igh/ ie i-e y i eigh',
  filter_set17_owoeo: 'Ensemble 17: /oa/ ow o-e o oe',
  filter_set18_ewueue: 'Ensemble 18: /oo/ ew ue u-e u; /(y)oo/ ew ue u-e; /oo/ u oul',
  filter_set19_awaual: 'Ensemble 19: /ŏ/ aw au al',
  filter_set20_irerear: 'Ensemble 20: /ur/ ir er ear',
  filter_set21_ouoy: 'Ensemble 21: /ow/ ou; /oi/ oy',
  filter_set22_ereeerareear: 'Ensemble 22: /ear/ ere eer; /air/ are ear',
  filter_set23_ckckch: 'Ensemble 23: k ck ch',
  filter_set24_cecicyscstlse: 'Ensemble 24: /s/ ce c sc st se',
  filter_set25_gegigydge: 'Ensemble 25: /j/ g(e) g(i) g(y) dge',
  filter_set26_lembkngnwr: 'Ensemble 26: /l/ le; /m/ mb; /r/ wr; /n/ kn gn',
  filter_set27_tchsheazh: 'Ensemble 27: tch t(ion) ss(ion) c(ial) c(ian); /e/ ea; /o/ (w)a; /u/ o',
  definition_of_text_types: 'Définition des types de textes',
  reading_level_correlation_chart: 'Tableau de corrélation des niveaux de lecture',
  moving_to_bookbox: 'Pour déplacer ce livre dans ma boîte',
  book_added_moving_to_bookbox: 'Livre ajouté, transférer dans ma boîte',
  not_favourite: "N'est pas favori",
  navigate_to_homepage: "Cliquez pour aller à la page d'accueil de Pearson",
  student_dashboard: 'Élève - analyse des données',
  Reading_Level_Short: 'Niveau_lec',
  Level_Short: 'Niveau',
  signing_declaration: 'En vous inscrivant, vous acceptez nos',
  terms_privacy_cookies: 'Termes, confidentialité et cookies',
  terms_privacy_copy_cookies: "Termes, confidentialité, droits d'auteur et cookies",
  continue_declaration: 'En continuant sur ce site, vous acceptez notre',
  accept_continue: 'ACCEPTER ET CONTINUER',
  and_use_of: 'utilisation de',
  assessed_strategies: 'Stratégies évaluées (activités interactives)',
  assessed_skills: 'Compétences évaluées (activités interactives)',
  prior_knowledge: 'Activer les connaissances précédentes',
  predicting: 'Prédire',
  inferring: 'Conclure',
  summarizing: 'Résumer',
  analyzing: 'Analyser et évaluer',
  text_features: 'Utiliser des fonctionnalités de texte',
  first_att: 'Bonne 1ère tentative',
  second_att: '2ème tentative correcte',
  third_att: '3ème tentative correcte',
  fourth_att: '3ème tentative inexacte',
  num_activities: "Nombre d'activités",
  completed: 'complétées',
  no_activity: "Cet étudiant n'a effectué aucune activité",

  welcome_text: 'Bienvenue à',
  select_your_role: 'Sélectionnez votre rôle :',
  teacher_text: 'Professeur',
  student_text: 'Étudiant',
  what_is_your_code: 'Quel est votre code?',
  your_code: 'VOTRE CODE',
  your_code_will_be_provided: 'Votre code sera fourni par votre professeur',
  error_msg_1: '<p>Réessayez<br/>ou<br/>renseignez-vous auprès de votre professeur.</p>',
  who_are_you: 'Qui êtes-vous?',
  your_account_locked: 'Votre compte est verrouillé.',
  your_account_locked_1: 'Veuillez réessayer dans 15 minutes.',
  close_msg: 'Fermer',
  you_are: 'Vous êtes :',
  what_is_your_password: 'Quel est votre mot de passe?',
  done_msg: 'Terminé',
  forgot_password: 'Vous avez oublié votre mot de passe?',
  validation_error_msg: '<p>Le code ne peut contenir que des numéros, des lettres<br/> et 6 caractères. </p>',
  license_expiry_msg_ca: "<p>Bienvenue!<br/>Votre essai est maintenant expiré. Pour réactiver, <a href='https://pearsoncanada.ca/spark' target='_blank'>cliquez ici</a> pour acheter.</p>",
  license_expiry_msg_student_ca: "<p>Bienvenue!<br/>Vous n'avez plus accès à Spark Reading. Veuillez demander plus d'informations à votre professeur.</p>",
  license_purchase_msg_ca: "Bienvenue!<br/>Il reste {0} jours à votre essai. Pour continuer à profiter de Spark Reading Canada <a href={1} target='_blank'>cliquez ici</a> pour acheter.",
  license_expiry_msg_tr: "<p>Bienvenue!<br/>Votre essai est maintenant expiré. Pour réactiver, <a href='https://pearsoncanada.ca/spark' target='_blank'>cliquez ici</a> pour acheter.</p>",
  license_expiry_msg_student_tr: "<p>Bienvenue!<br/>Vous n'avez plus accès à Spark Reading. Veuillez demander plus d'informations à votre professeur.</p>",
  license_purchase_msg_tr: "Bienvenue!<br/>Il reste {0} jours à votre essai. Pour continuer à profiter de Spark Reading Canada <a href={1} target='_blank'>cliquez ici</a> pour acheter.",
  teacher_license_expiry_msg_last_day: 'Your account expires today - please contact your administrator or Spark Reading Help {0} for assistance.',
  teacher_license_expiry_msg_ca: "Your account will expire in {0} day(s) - please contact your administrator or <a href={1} target='_blank'>Spark Reading Help</a> for assistance.",
  teacher_license_expiry_msg_tr: "Your account will expire in {0} day(s) - please contact your administrator or <a href={1} target='_blank'>Spark Reading Help</a> for assistance.",
  teacher_license_expiry_msg_gb: "Your account will expire in {0} day(s) - please contact your administrator or <a href={1} target='_blank'>Spark Reading Help</a> for assistance.",
  teacher_license_expired_msg_ca: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  teacher_license_expired_msg_tr: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  teacher_license_expired_msg_gb: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  class_text: 'Classe:',
  password_text: 'Mot de passe',
  show_password: 'Afficher le mot de passe',
  ask_teacher: '<p>Demandez à votre professeur de vous aider.</p>',
  ask_teacher_try_again: '<p>Un problème est survenu!, Veuillez réessayer! <br/>Ou Demandez à votre professeur de vous aider.</p>',
  download_template: "Télécharger le modèle d'importation",
  upload_template: "Importer le modèle d'importation",
  no_students_msg1: "Il n'y a aucun élève dans votre cours. ",
  no_students_msg2: "Cliquez sur AJOUTER UN ÉTUDIANT pour ajouter un étudiant ou cliquez sur Télécharger le modèle d'importation. ",
  no_students_msg3: "Ajoutez ensuite le modèle et cliquez sur Importer le modèle d'importation pour importer vos étudiants.",
  add_new_student: 'Ajouter un nouvel étudiant',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  student_id: "ID de l'étudiant",
  password_type: 'Type de mot de passe',
  password_tooltip: 'Les mots de passe peuvent contenir uniquement des numéros, des lettres et avoir un minimum de 5 caractères.',
  select_password: 'Sélectionner le type',
  password_text_option: 'Texte',
  password_image_option: 'Image',
  image_selected: 'Image sélectionnée',
  add_student: 'Ajouter un étudiant',
  add_student_button: 'Terminer/Imprimer',
  max_limit_add: 'Vous avez atteint votre limite maximale de ',
  remove_student: 'Supprimer',
  please_enter: 'Veuillez saisir ',
  please_select: 'Veuillez sélectionner ',
  password_validation: 'Les mots de passe peuvent contenir uniquement des numéros, des lettres et avoir un minimum de 5 caractères.',
  password_text_validation: 'Texte du mot de passe',
  password_image_validation: 'Image du mot de passe',
  student_creation_started: "La création de l'étudiant a commencé.",
  close_popup: "Vous pouvez alors fermer la fenêtre contextuelle et naviguer librement autour de l'application.",
  class_list_ready: 'Nous vous informerons dès que votre liste de cours sera prête.',
  student_creation_progress: "La création de l'étudiant est en cours.",
  do_not_close: 'Veuillez ne pas fermer la fenêtre ou appuyer sur le bouton arrière',
  delete_student: 'Êtes-vous sûr de vouloir supprimer définitivement cet étudiant?',
  yes: 'OUI',
  no: 'NON',
  upload_successful: 'Le téléchargement a réussi. Un courriel avec votre liste de cours a été envoyé.',
  upload_partial_successful: 'Le téléchargement a partiellement réussi.',
  upload_partial_successful_email: 'les enregistrements ont été téléchargés. Un courriel avec votre liste de cours a été envoyé.',
  upload_unsuccessful: "L'importation a échoué.",
  view_students: 'Cliquez pour afficher les étudiants.',
  edit_students: "Modifier l'étudiant",
  edit_student_button: 'Enregistrer/Imprimer',
  student_update_progress: "La mise à jour de l'élève est en cours.",
  student_update_started: "La mise à jour de l'étudiant a commencé.",
  let_you_know: "Nous vous informerons dès que les détails de l'étudiant seront mis à jour.",
  students_updated: 'Vos élèves sont en cours de mise à jour...',
  password_not_found: 'Mot de passe introuvable!',
  upload_failed_contents: "Échec du téléchargement de l'élève!<br> Veuillez vérifier le contenu du fichier.",
  failed_to_import: "Impossible d'importer {0} de nouveaux étudiants car vous dépasserez votre limite maximale {1} d'étudiants",
  csv_file_error: 'Impossible de lire le fichier csv',
  csv_format_error: "Le fichier téléchargé n'est pas au format CSV. Assurez-vous <br></br>d'avoir téléchargé le <br></br>modèle en premier et essayez de l'importer à nouveau.",
  upload_failed: "Échec du téléchargement de l'élève!<br> Veuillez vérifier après un moment.",
  add_student_successful_p1: 'IMPORTANT! Le(s) compte(s) a (ont) été créé(s) pour le(s) étudiant(s) que vous avez récemment ajouté(s).',
  add_student_successful_p2: ' {0} pour télécharger le fichier avec la(les) lettre(s) de l’étudiant(s).',
  add_student_click_here: 'cliquez ici',
  add_student_created_message: 'Vos comptes étudiants sont en cours de création. Cela peut prendre quelques instants.',
  class_code: 'Code de classe:',
  cookie_policy: 'Politique de cookies',
  edit_student_successful_p1: 'IMPORTANT! Les comptes ont été mis à jour pour vos élèves.',
  edit_student_successful_p2: ' {0} pour télécharger le fichier avec la(les) lettre(s) de l’étudiant(s).',
  edit_student_update_message: 'Vos comptes étudiants sont en cours de mise à jour. Cela peut prendre quelques instants.',
  select_library: 'Sélectionnez votre bibliothèque',
  make_selection: 'Faire une sélection',
  create_account: 'Créer un compte',
  select_library_error: "Une erreur s'est produite lors du traitement de votre demande. Veuillez contacter l'équipe d'assistance.",
  account_created: 'Compte créé',
  loading_data: 'Chargement des données...',
  import_students_help: 'Ajouter plusieurs étudiants : Aide',
  'welcome_!': 'Bienvenue!',
  logged_in_count: 'Nombre de<br>connexions',
  last_login_time: 'Dernière<br>connexion',
  leave_as_is: 'Laisser comme tel',
  clicked: ' cliqué',
  student_letter: "Lettre de l'étudiant",
  password_letter: 'Lettre du mot de passe',
  student_letters: "Lettres de l'étudiant",
  password_letter_unavailable: "La lettre de l'étudiant n'est pas disponible, veuillez modifier les informations de l'étudiant/réinitialiser le mot de passe de l'étudiant pour générer la lettre.",
  options: 'Option de',
  loading_activity_graph: 'Chargement en cours du graphie d’activité...',
  available_access: 'Accès disponible',
  reset_password: 'Réinitialisez le mot de passe',
  decodable_set: 'Décodable',
  country_not_supported_msg: 'Sorry, we do not yet support self registration in the country that you selected.',
  student_license_expiry_title: 'Votre classe a expiré ou n\'existe pas.',
  student_license_expiry_description: 'Veuillez contacter le service d\'aide à la lecture de Spark <sparkreadinghelp@pearson.com>',
  teacher_license_expiry_title:"Your account has expired.",
  teacher_license_expiry_contact:"Please contact your <a href={0} target='_blank'>sales representative </a>or your administrator to renew your license.",
  teacher_license_expiry_description:"For any other inquiries contact Spark Reading Help &lt;sparkreadinghelp@pearson.com&gt;",
  // Reports
  notes: 'Notes',
  time_spent: 'Temps passé',
  download_report: 'Télécharger le rapport comme',
  report_error_message: 'Something went wrong on our end. Please try again later.',
  csv: 'CSV',
  excel: 'Excel',
  pdf: 'PDF',
  report_generation_progress: 'Report generation is in progress.',
  activities_completed: 'Activities Completed',
  // Reportbanner message
  report_error_message: 'Something went wrong on our end when generating your',
  report_error_message_m1: 'file.Please try again later',
  report_inProgress_message: 'Generation of your report as a',
  report_inProgress_message_m1: 'file is in progress.',
  report_complete_message: 'Generation of your report as a',
  report_complete_message_m1: 'has been completed successfully.',
  Keep_reading: 'Keep Reading',
  'are_you_still_reading?': 'Are You Still Reading?',
  log_out_label: 'Log out now'
};
export default fr;
