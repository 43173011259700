import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import HardwareKeyboardArrowBack from '@material-ui/icons/ArrowBack';
import TagManager from 'react-gtm-module';
import StudentAnalytics from './StudentAnalytics';
import StudentAnalyticsOverView from './StudentAnalyticsOverView';
import ExpiryMessage from '../../../common/components/ExpiryMessage';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';
import DataFormatter from '../../../common/utils/DataFormatter';

const masterStyle = {
  fontFamily: 'Open Sans'
};
const styles = {
  backButton: {
    height: '24px',
    width: '24px',
    padding: '0px',
    marginRight: '20px',
    top: '5px'
  },
  backButtonIcon: {
    height: '24px',
    width: '24px'
  }
};
const muiSecondaryTheme = createTheme(masterStyle);


class StudentAnalyticsContainer extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    language: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleSwitchCourseSection = this.handleSwitchCourseSection.bind(this);
  }

  state = {
    activeTab: 0,
    studentOverviewActive: false,
    userId: ''
  }

  componentDidMount = function () {
    Framework.getEventManager().on(constants.COURSE_SELECTED, () => {
      this.handleSwitchCourseSection();
    });
  }

  UNSAFE_componentWillMount = function () {
    // AppStore.on(AppConstants.EventTypes.COURSE_SELECTED, this.handleSwitchCourseSection);
  }

  componentWillUnmount() {
    // AppStore.removeListener(AppConstants.EventTypes.COURSE_SELECTED, this.handleSwitchCourseSection);
  }

  handleSwitchCourseSection = function () {
    this.setState({ studentOverviewActive: false });
  }

  handleRedirect = function (userData) {
    const { language, intl } = this.props;
    const gtmData = {
      dataLayer: {
        event: 'student_name_clicked',
        event_category: intl.formatMessage(language.getText('student_list')),
        event_action: intl.formatMessage(language.getText('student_name_clicked')),
        event_label: null,
        student_id: userData.userId,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);

    this.setState({
      studentOverviewActive: true,
      userId: userData.userId,
      fullname: `${userData.fName} ${userData.lName}`
    });
  }

  handleSwitchStudentList = function () {
    this.setState({
      studentOverviewActive: false
    });
  }

  renderBackButton = function () {
    const { language, intl } = this.props;
    return (
      <div className="back-to-student-list">
        <IconButton onClick={this.handleSwitchStudentList.bind(this)} aria-label="To Go back to student list" style={styles.backButton}>
          <HardwareKeyboardArrowBack style={styles.backButtonIcon} />
        </IconButton>
        <p><FormattedMessage {...language.getText('student_list')} /></p>
      </div>
    );
  }

  render = function () {
    const { language } = this.props;
    const courseInfo = DataFormatter.getObjectInStorage('courseSelected');
    const currentDate = new Date().setHours(0,0,0,0);
    let courseEndDate = courseInfo && new Date(courseInfo.endDate).setHours(0,0,0,0);
    const studentTabContent = courseInfo && (courseEndDate >= currentDate) ? (
      <div className="">
        <div>{(this.state.studentOverviewActive === true ? this.renderBackButton() : '')}</div>
        {(this.state.studentOverviewActive === false ? <StudentAnalytics handleRedirect={this.handleRedirect.bind(this)} /> : <StudentAnalyticsOverView fullname={this.state.fullname} userId={this.state.userId} handleRedirect={this.handleRedirect.bind(this)} />)}

      </div>
    ) : (
      <div className="applicationWrapperStyle">
        <ExpiryMessage
          title={language.getMessage('student_license_expiry_title')}
          description={language.getMessage('student_license_expiry_description')}
        />
      </div>
    );
    return (
      <MuiThemeProvider theme={muiSecondaryTheme}>
        {studentTabContent}
      </MuiThemeProvider>
    );
  }
}

StudentAnalyticsContainer.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

// export default StudentAnalyticsContainer;
export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language'),
  observer
)(injectIntl(StudentAnalyticsContainer));
