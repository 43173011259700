/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Index file for all environment setting files.
 *
 * @author Hari Gangadharan
 */
import base from './base';
import local from './local';
import dev from './dev';
import qa from './qa';
import stg from './stg';
import sit from './sit';
import prod from './prod';
import saet from './flavors/saet';
import sparkProd from './sparkProd';

let env;
let productFlavor;

switch (window.location.hostname) {
  case 'localhost':
  case 'local-dev.pearson.com':
    env = local;
    productFlavor = saet;
    break;
  case 'digital-library-dev.pearson-intl.com':
    env = dev;
    productFlavor = saet;
    break;
  case 'digital-library-qa.pearson-intl.com':
  case 'digital-library-qa.pearson.com':
    env = qa;
    productFlavor = saet;
    break;
  case 'digital-library-stg.pearson-intl.com':
  case 'digital-library-stg.pearson.com':
    env = stg;
    productFlavor = saet;
    break;
  case 'digital-library-sit.pearson-intl.com':
  case 'digital-library-sit.pearson.com':
    env = sit;
    productFlavor = saet;
    break;
  // Production
  case 'digital-library.pearson-intl.com':
  case 'digital-library.pearson.com':
    env = prod;
    productFlavor = saet;
    break;
  // Spark Production
  case 'spark.pearson.com':
    env = sparkProd;
    productFlavor = saet;
    break;
  default:
    env = stg;
    productFlavor = saet;
}

/**
 * Override the base values with the environment specific values.
 */
export default { ...base, ...productFlavor, ...env };
