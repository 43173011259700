/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const UserDetails = types
  .model('UserDetails', {
    _id: types.array(types.maybeNull(types.string)),
    userId: types.array(types.maybeNull(types.string)),
    schoolId: types.maybeNull(types.string),
    coursesectionId: types.maybeNull(types.string),
    roleValue: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    externalRef: types.array(types.maybeNull(types.string))
  });

const IESUserDetailsByCourse = types
  .model('IESUserDetailsByCourse', {
    items: types.optional(types.array(UserDetails), [])
  })
  .actions(self => ({
    fetch(courseId, roleValue) {
      Framework.getEventManager().publish(constants.IES_USER_REQUESTED, { courseId, roleValue });
    },
    set(state) {
      applySnapshot(self, state);
    }
  }));

export default IESUserDetailsByCourse;
