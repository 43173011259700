import CryptoJS from 'crypto-js';
import env from '../env';

class HmacService {
  static encryptJS(dataToEncrypt) {
    return CryptoJS.AES.encrypt(
      dataToEncrypt,
      env.ACCESS_CODE_SECRET_KEY
    ).toString();
  }

  static decryptJS(encryptedData) {
    return CryptoJS.AES.decrypt(
      encryptedData,
      env.ACCESS_CODE_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
  }
}

export default HmacService;
