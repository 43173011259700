/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Student Code page
 *
 * @file StudentCode.js
 * @author Ratheesh
 * @since 1.0.0
 */

import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import ReactHtmlParser from 'react-html-parser';
import TagManager from 'react-gtm-module';
import * as constants from '../../../common/constants';
import WelcomeMessage from '../../../common/components/WelcomeMessage';
import CustomMessage from '../../../common/components/CustomMessage';
import pswdicon from '../../../assets/images/young_learners/password-icon.svg';
import logo from '../../../assets/images/pearson_logo.png';
import logo2 from '../../../assets/images/pearson_logo@2x.png';
import logo3 from '../../../assets/images/pearson_logo@3x.png';
import CommonUtils from '../../../common/utils/CommonUtils';

class StudentCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDomainSpark: false,
      isValidCode: 0,
      showValidateError: false,
      studentCode: '',
      csid: '',
      showErrorMessage: {
        show: false,
        message: '',
        color: '#fc5b43'
      }
    };
  }

  componentDidMount() {
    Framework.getEventManager().on(constants.FETCH_COURSE_SECTION_SUCCESS, (coursesectionData) => {
      this.callbackFetchCourseSection(coursesectionData);
    });
    this.setState({ isDomainSpark: window.location.href.includes('spark') });
    localStorage.setItem('isDomainSpark', window.location.href.includes('spark'));
    const classCode = localStorage.getItem('class_code');
    if (classCode !== null) {
      this.setState({ isValidCode: 1, studentCode: classCode });
    }

    // Page view for Student code
    const gtmPageData = CommonUtils.getDefaultGAPageView();
    gtmPageData.dataLayer.page_title = 'Class Code';
    TagManager.dataLayer(gtmPageData);
  }

  callbackFetchCourseSection = (setCoursesectionFetchData) => {
    const { language, intl } = this.props;
    if (setCoursesectionFetchData.data && setCoursesectionFetchData.data.length > 0 && setCoursesectionFetchData.data[0].id !== '') {
      localStorage.setItem('class_code', this.state.studentCode);
      localStorage.setItem('setCoursesectionFetchData', JSON.stringify(setCoursesectionFetchData));
      Framework.redirectTo('/student/selectuser/');
    } else {
      this.setState({
        showErrorMessage: {
          show: true,
          message: ReactHtmlParser(intl.formatMessage(language.getText('error_msg_1'))),
          color: '#fc5b43'
        }
      });
    }
  };

  redirectWithStudentCode = () => {
    if (this.state.studentCode.length === constants.student_code_max_length
      && constants.student_code_pattern.test(this.state.studentCode)) {
      Framework.getEventManager().publish(constants.FETCH_COURSE_SECTION_REQUESTED,
        {
          classCode: this.state.studentCode
        });
    } else if (this.state.studentCode.length < constants.student_code_max_length) {
      this.setState({ showValidateError: true });
    }
  };

  enableNextButton = (e) => {
    this.setState({ showValidateError: false });
    this.setState({ studentCode: e.target.value });
    // set initial state of button
    // let initNextButtonState = 0;
    // check if good value present
    if (
      e.target.value.length > 0 && (e.target.value.length > constants.student_code_max_length
      || !constants.student_code_pattern.test(e.target.value))
    ) {
      // If Error Show Error
      // initNextButtonState = 1;
      this.setState({ showValidateError: true });
    } else if (e.target.value.length === 0) {
      // If no chars. disable button
      this.setState({ isValidCode: 0 });
    } else if (e.target.value.length > 0 && e.target.value.length <= constants.student_code_max_length) {
      // If some chars but less than or = to max chars then show enable button
      this.setState({ isValidCode: 1 });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showErrorMessage: {
        show: false
      }
    });
  };

  onClickLogo = () => {
    const piSession = window.piSession;
    if (piSession) {
      piSession.setOptions({
        requireLogin: false
      });
    }

    // Getting the userName from localStorage before clearing to set it back - fix for RPLUS-4705
    const userName = localStorage.getItem('userName');
    const isStudentAdd = localStorage.getItem('isStudentAdd');
    localStorage.clear();
    localStorage.setItem('userName', userName);
    (isStudentAdd === 'true') ? localStorage.setItem('isStudentAdd', isStudentAdd) : '';
    Framework.redirectTo('/');
    if (piSession) {
      const redirectURL = `${window.location.protocol}//${window.location.host}`;
      piSession.logout(redirectURL);
    }
  }

  render() {
    const { language, intl } = this.props;
    return (
      <div>
        <div>
          <nav className="navbar navbar-expand-sm rectangle-1">
            <img
              onClick={this.onClickLogo}
              src={logo}
              alt="Pearson Logo"
              srcSet={`${logo2} 2x,${logo3} 3x`}
              className="pearson-logo"
            />
          </nav>
        </div>
        <div className="container-fluid selectrole-container">
          <div className="selectrole-welcome-container">
            <WelcomeMessage />
          </div>
          <div id="studentcode-div" className="student-code-container">
            <FormControl className="student-code-formcontrol">
              <label className="select-role-txt">
                {intl.formatMessage(language.getText('what_is_your_code'))}
              </label>
              <br />
              <div className="class-code-icon">
                <TextField
                  id="input-with-icon-textfield"
                  margin="none"
                  onChange={this.enableNextButton}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      this.redirectWithStudentCode();
                    }
                  }}
                  value={this.state.studentCode}
                  placeholder={intl.formatMessage(language.getText('your_code'))}
                  className="student-code-textbox"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          className="password-icon-image"
                          src={pswdicon}
                          alt="Password icon image"
                        />
                      </InputAdornment>
                    )
                  }}
                />
                <div
                  id="id_student_code_next"
                  className={
                this.state.isValidCode === 1
                  ? 'student-code-next-enabled'
                  : this.state.isValidCode === 2
                    ? 'student-code-next-clicked'
                    : 'student-code-next-disabled'
              }
                  onClick={this.redirectWithStudentCode}
                />
              </div>
            </FormControl>
            <br />
            <label className="student-code-info">
              {intl.formatMessage(language.getText('your_code_will_be_provided'))}
            </label>
            <br />
            <label
              className={
              this.state.showValidateError === true
                ? 'student-code-error-on'
                : 'student-code-error-off'
            }
            >
              {ReactHtmlParser(intl.formatMessage(language.getText('validation_error_msg')))}
            </label>
          </div>
          {this.state.showErrorMessage.show === true ? (
            <CustomMessage
              color={this.state.showErrorMessage.color}
              message={this.state.showErrorMessage.message}
              show={this.state.showErrorMessage.show}
              handleClose={this.handleClose}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

StudentCode.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(StudentCode));
