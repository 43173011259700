/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { useState, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  CircularProgress
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Framework from '@greenville/framework';

const UserTypeRedirect = inject('language')(
  observer((props) => {
    const auth = Framework.getAuthService();
    const user = Framework.getStoreRegistry().getStore('user');
    const userType = localStorage.getItem('usertype');
    props.setIsLoading(false);
    // console.log(auth, user, userType);
    if (auth && auth.isInitialized === true && user && user.id.length > 0) {
      if (userType === 'teacher') {
        Framework.redirectTo('/app');
        return <Fragment />;
      }
      Framework.redirectTo('/app');
      return <Fragment />;
    }
    Framework.redirectTo('pages/selectrole');
    return <Fragment />;
  })
);

function Home(props) {
  const [isLoading, setIsLoading] = useState(true);
  if (isLoading === false) {
    return (
      <UserTypeRedirect isLoading={isLoading} setIsLoading={setIsLoading} />

    );
  }
  return (
    <>
      <UserTypeRedirect isLoading={isLoading} setIsLoading={setIsLoading} />
      <CircularProgress className="circleStyle" />
    </>
  );
}

export default injectIntl(Home);
