import React from 'react';

import injectTapEventPlugin from 'react-tap-event-plugin';
import DataFormatter from '../../../common/utils/DataFormatter';
import TeacherLicenseExpiryMsg from './TeacherLicenseExpiryMsg';

const crypto = require('crypto');

try {
  injectTapEventPlugin();
} catch (e) {
}
// var lang = DataFormatter.getLanguage();
class ImageBar extends React.Component {
  constructor(props) {
    super(props);
  }

  //  componentDidMount() {
  //  }
  //  componentWillUnmount() {
  //  }
  render() {
    let schoolList;
    let teacherName;
    let classRoomName;
    let uid;
    let imageFileName;
    try {
      schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
      teacherName = DataFormatter.getKeyFromObject('userInformation', 'fName');
      uid = DataFormatter.getKeyFromObject('userInformation', 'id');
      const configInfo = DataFormatter.getObjectInStorage('configData');
      const secretKey = configInfo ? configInfo.caas.id : '';
      const timestamp = Math.floor(new Date().getTime() / 1000);
      const payload = { userId: uid, timestamp: timestamp.toString() };
      const hmac = crypto
        .createHmac('sha256', secretKey)
        .update(JSON.stringify(payload))
        .digest('hex');
      classRoomName = schoolList[0].name;
      imageFileName = `${schoolList[0].links.heroImage}&userId=${uid}&hmac-signature=${hmac}&timestamp=${timestamp}`;

      return (
        <div
          id="backGroundImage"
          className={`backGroundImage imageBar ${
            this.props.classNamePrefix === 'primary'
              ? this.props.classNamePrefix
              : ''
          }`}
        >
          {this.props.role === 'secondary' &&  this.props.classNamePrefix === 'secondary' ? <TeacherLicenseExpiryMsg show={this.props.show} color={this.props.color} message={this.props.message} handleClose={this.props.handleClose} animateStatus={this.props.animateStatus} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave} /> : '' }
          <img className="backGroundImageEle" src={imageFileName} />
        </div>
      );
    } catch (e) {
    // console.log(e);
      return <div />;
    }
  }
}
export default ImageBar;
