import React from 'react';
import { injectIntl } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import $ from 'jquery';
import injectTapEventPlugin from 'react-tap-event-plugin';
import TagManager from 'react-gtm-module';
import * as constants from '../../../common/constants';
import { SINGLE_PAGE, DOUBLE_PAGE } from '../../../common/constants';
import DataFormatter from '../../../common/utils/DataFormatter';
import TextbookShelf from './TextBooks';
import LoadingOverlay from './loadingOverlay';
import PrimaryRefineSearchConsole from './primaryRefineSearchConsole';
import ReturnBookComponent from './ReturnBookComponent';
import primFlipImageURL from '../../../assets/images/books/flip-icon-secondary.png';
import primFlipImageURL1 from '../../../assets/images/books/flip-primary.png';
import primNewImageURL from '../../../assets/images/new.png';
import primAddImageURL from '../../../assets/images/add.png';
import primFavInactiveImageURL from '../../../assets/images/fav.png';
import primFavActiveImageURL from '../../../assets/images/fav1.png';
import CommonUtils from '../../../common/utils/CommonUtils';

try {
  injectTapEventPlugin();
} catch (e) {

}
let primaryBookShelfProps = {

};

const styles = {
  primaryHeadingStyles: {
    marginTop: '12px',
    marginBottom: '12px',
    fontSize: '32px',
    fontWeight: '300',
    lineHeight: '1.13',
    textAlign: 'left',
    color: '#393c3d'
  }
};

// var lang = DataFormatter.getLanguage();
let previousScrollTop = 0;
const booksListData = [];
const BOOKS_LIMIT_PER_FETCH = 25;
class MyBookBox extends React.Component {
  constructor() {
    super();
    this.updateProductsFetched = this.updateProductsFetched.bind(this);
    this.updateSearchProductsFetched = this.updateSearchProductsFetched.bind(this);
    this.refineServiceProductData = this.refineServiceProductData.bind(this);
    this.handleBookClick = this.handleBookClick.bind(this);
    this.updateProductsFetchUpdate = this.updateProductsFetchUpdate.bind(this);
    this.searchBookshelf = this.searchBookshelf.bind(this);
    this.searchBookshelfAutoComplete = this.searchBookshelfAutoComplete.bind(this);
    this.updateAutoCompleteSearchProductsFetched = this.updateAutoCompleteSearchProductsFetched.bind(this);
    this.enableLoading = this.enableLoading.bind(this);
    this.fetchUserProducts = this.fetchUserProducts.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    searchText: null,
    books: null,
    bookshelfDataLoaded: false,
    showReturnbookPopup: false,
    searchKeyword: '',
    filterBy: '',
    showKeywordSearch: false,
    lazyLoadingApplicable: false,
    productFetchInProgress: false,
    isLoading: true,
    bookLimitPerFetch: BOOKS_LIMIT_PER_FETCH,
    getBookReadCountData: '',
    bookBoxProductListData: ''
  }

  handleBookClick = (book, index) => {
    DataFormatter.setObjectInStorage('bookClicked', book);
    // this.props.history.push('/book');
    Framework.redirectTo('/book');
  }

  handleScroll = function () {
    const mainAppContainerElement = document.getElementById('main');
    const scrollTop = mainAppContainerElement.scrollTop;
    const docHeight = mainAppContainerElement.scrollHeight;
    const winHeight = mainAppContainerElement.offsetHeight;
    const scrollPercent = (scrollTop) / (docHeight - winHeight);
    const scrollPercentRounded = Math.round(scrollPercent * 100);

    if ((previousScrollTop < scrollTop) && scrollPercentRounded > 75 && !this.state.productFetchInProgress) {
      if (this.state.lazyLoadingApplicable) {
        const path = window.location.search;
        if (path == '') {
          if (this.props.userId) {
            this.setState({
              productFetchInProgress: true,
              isLoading: true
            }, () => {
              if (this.state.searchOccured) {
                // AppActions.searchUserProducts(this.props.userId, (this.state.searchKeyword !== null ? this.state.searchKeyword : ''), this.state.filterBy, false, 25, (!isNaN(this.state.productsLength) ? this.state.productsLength : ''));
                Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_REQUESTED,
                  {
                    userId: this.props.userId,
                    searchKeyword: (this.state.searchKeyword !== null ? this.state.searchKeyword : ''),
                    filterByArg: this.state.filterBy,
                    limit: 25,
                    offset: (!isNaN(this.state.productsLength) ? this.state.productsLength : '')
                  });
              } else {
                // AppActions.fetchProductByUserId(this.props.userId, false, this.state.bookLimitPerFetch, (!isNaN(this.state.productsLength) ? this.state.productsLength : ''), '-assignedDate');
                Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
                  {
                    userId: this.props.userId,
                    includeReturned: false,
                    searchlimit: this.state.bookLimitPerFetch,
                    offset: (!isNaN(this.state.productsLength) ? this.state.productsLength : '')
                  });
              }
            });
          }
        }
      }
    }
    previousScrollTop = scrollTop;
  }

  calculateWindowWidth = function (sendRemainingWidth) {
    const windowWidth = window.innerWidth;
    const booksCanAccomodate = windowWidth / 285;
    const remainingWidth = windowWidth - (parseInt(booksCanAccomodate) * 285);
    if (sendRemainingWidth) {
      return remainingWidth;
    }
    return (windowWidth - remainingWidth - 24);
  }

  componentDidMount = function () {
    DataFormatter.setObjectInStorage('restrictedProductCall', false);
    Framework.getEventManager().on(constants.USER_PRODUCTS_FETCHED_SUCCESS, (respData) => {
      this.setState({
        bookBoxProductListData: respData
      }, () => this.updateProductsFetched());
      // this.updateProductsFetched();
    });
    // AppStore.on(AppConstants.EventTypes.SEARCH_USER_PRODUCTS_FETCHED_SUCCESS, this.updateSearchProductsFetched);
    Framework.getEventManager().on(constants.FETCH_USER_PRODUCTS_SUCCESS, (respData) => {
      this.setState({
        bookBoxProductListData: respData
      }, () => this.updateSearchProductsFetched());
      // this.updateSearchProductsFetched();
    });
    // AppStore.on(AppConstants.EventTypes.USER_PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, this.updateAutoCompleteSearchProductsFetched);
    Framework.getEventManager().on(constants.FETCH_USER_PRODUCTS_SUCCESS, (respData) => {
      this.setState({
        bookBoxProductListData: respData
      }, () => this.updateAutoCompleteSearchProductsFetched());
      // this.updateAutoCompleteSearchProductsFetched(respData);
    });

    // AppStore.on(AppConstants.EventTypes.RETURN_PRODUCT_COMPLETED, this.returnBookSuccess);
    Framework.getEventManager().on(constants.RETURN_BOOK_SUCCESS, (respData) => {
      this.returnBookSuccess();
    });

    // AppStore.on(AppConstants.EventTypes.UPDATE_USER_PRODUCT_SUCCESS, this.fetchUserProducts);
    Framework.getEventManager().on(constants.UPDATE_USER_PRODUCT_SUCCESS, (respData) => {
      this.fetchUserProducts(respData);
    });
    const mainAppContainerElement = document.getElementById('main');
    if (mainAppContainerElement) {
      mainAppContainerElement.addEventListener('scroll', this.handleScroll);
    }
    // AppActions.fetchBookReacdCountByUserId(this.props.userId,true);
    // Framework.getEventManager().publish(constants.FETCH_BOOK_READ_COUNT_REQUESTED,
    //   {
    //       userId: this.props.userId,
    //       includeReturned:true
    //   }
    // );
    Framework.getEventManager().on(constants.FETCH_BOOK_READ_COUNT_SUCCESS, (respData) => {
      this.setState({ getBookReadCountData: respData });
    });

    const gtmData = CommonUtils.getDefaultGAPageView();
    gtmData.dataLayer.page_title = 'My Bookbox';
    TagManager.dataLayer(gtmData);
  }

  returnBookSuccess = () => {
    this.searchBookshelf(this.state.searchKeyword, this.state.filterBy);
  }

  fetchUserProducts = (respData) => {
    if (respData == 'success') {
      DataFormatter.setObjectInStorage('MyBookboxData', '');
      if (this.state.favouriteClicked) {
        this.setState({
          isLoading: true,
          favouriteClicked: false
        }, () => {
          this.searchBookshelf(this.state.searchKeyword, this.state.filterBy);
        });
      }
    }
  }

  bookShelfProps = {};

  UNSAFE_componentWillMount = function () {
    // if (localStorage.getItem('ReadingStatus') !== undefined && localStorage.getItem('ReadingStatus') != null) {
    //   const arg = JSON.parse(localStorage.getItem('ReadingStatus'));
    //   Framework.getEventManager().publish(constants.UPDATE_USER_PRODUCT_REQUESTED, {
    //     userId: arg?.userId,
    //     productId: arg?.productId,
    //     userStatusObj: arg?.userStatusObj
    //   });
    // }
    DataFormatter.setObjectInStorage('currentTab', 'my-bookbox');
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    const dashboardConfig = DataFormatter.getKeyFromObject('appConfig', 'dashboard');

    const currentTab = DataFormatter.getObjectInStorage('currentTab');
    if (dashboardConfig && dashboardConfig.sections instanceof Array) {
      for (let i = 0, iLen = dashboardConfig.sections.length; i < iLen; i++) {
        const sectionItem = dashboardConfig.sections[i];
        for (let j = 0, iLgth = sectionItem.showKeywordSearch.length; j < iLgth; j++) {
          if (sectionItem.isEnabled !== false && sectionItem.tag === currentTab && sectionItem.showKeywordSearch[j] === role) {
            this.setState({
              showKeywordSearch: true
            });
          }
        }
      }
    }
    if (this.props.userId) {
      Framework.getEventManager().publish(constants.FETCH_BOOK_READ_COUNT_REQUESTED,
        {
          userId: this.props.userId,
          includeReturned: true,
          filterByArg: 'finished'
        });
      setTimeout(() => {
        if (this.state.books === null) {
          this.setState({
            books: [],
            bookshelfDataLoaded: true,
            isLoading: false
          });
        }
      }, 8 * 1000);
      const isBackFromPlayer = DataFormatter.getObjectInStorage('fromPlayerBackButtonClick');
      const searchState = DataFormatter.getObjectInSessionStorage('searchState');
      if (!isBackFromPlayer || (!searchState)) {
        this.setState({ productsLength: 0 }, () => {
          // AppActions.fetchProductByUserId(this.props.userId,false,25,0, '-assignedDate');
          setTimeout(() => {
            Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
              {
                userId: this.props.userId,
                includeReturned: false,
                searchlimit: 25,
                offset: 0
              });
          }, 2000);
        });
      } else {
        DataFormatter.setObjectInStorage('fromPlayerBackButtonClick', false);
      }
    } else {
      this.setState({
        books: [],
        isLoading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    localStorage.removeItem('ReadingStatus');
    const mainAppContainerElement = document.getElementById('main');
    if (mainAppContainerElement) {
      mainAppContainerElement.removeEventListener('scroll', this.handleScroll);
    }
  }

  getTitle = (book, productItem) => productItem.title

  refineServiceProductData = function (userProductItemArg, idx) {
    if (!userProductItemArg.productId) {
      return false;
    }
    const productItem = userProductItemArg.productId;
    let refinedData = {};
    const userRole = DataFormatter.getKeyFromObject('userInformation', 'role');
    if (productItem && productItem.books) {
      const bookArray = productItem.books;
      try {
        if (bookArray.length == 0) {
          return false;
        }
        if (bookArray.length == 1) {
          refinedData = {
            bookId: bookArray[0].id,
            assetid: bookArray[0].id,
            coverImageURL: bookArray[0].metadata.coverImageUrl,
            bookName: this.getTitle(bookArray[0].metadata, productItem), // bookArray[0].metadata.title?bookArray[0].metadata.title:productItem.title,
            assetUrl: bookArray[0].metadata.url,
            type: bookArray[0].metadata.type
          };
        } else if (bookArray.length > 1) {
          const book1Metadata = bookArray[0].metadata || '';
          const book2Metadata = bookArray[1].metadata || '';
          if (book1Metadata != '') {
            var book1Role = book1Metadata.role || '';
          } else {
            var book1Role = '';
          }
          if (book2Metadata != '') {
            var book2Role = book2Metadata.role || '';
          } else {
            var book2Role = '';
          }
          if (String(book1Role) === String(userRole)) {
            refinedData = {
              bookId: bookArray[0].id,
              assetid: bookArray[0].id,
              coverImageURL: book1Metadata.coverImageUrl,
              bookName: this.getTitle(book1Metadata, productItem), // book1Metadata.title?book1Metadata.title:productItem.title,
              assetUrl: book1Metadata.url,
              type: book1Metadata.type
            };
          } else if (String(book2Role) === String(userRole)) {
            refinedData = {
              bookId: bookArray[1].id,
              assetid: bookArray[0].id,
              coverImageURL: book2Metadata.coverImageUrl,
              bookName: this.getTitle(book2Metadata, productItem), // book2Metadata.title?book2Metadata.title:productItem.title,
              assetUrl: book2Metadata.url,
              type: book2Metadata.type
            };
          } else {
            refinedData = {
              bookId: bookArray[0].id,
              assetid: bookArray[0].id,
              coverImageURL: book1Metadata.coverImageUrl ? book1Metadata.title : productItem.title,
              bookName: this.getTitle(book1Metadata, productItem), // book1Metadata.title,
              assetUrl: book1Metadata.url,
              type: book1Metadata.type
            };
          }
        }
        refinedData.author = productItem.author;
        refinedData.productId = productItem.id;
        const assignedDate = userProductItemArg.assignedDate * 1000;
        const bookStatus = userProductItemArg.status;
        const dateToday = new Date();
        const daysLimit = 5; // go back 5 days!
        const verifyDate = dateToday.setDate(dateToday.getDate() - daysLimit);
        if (assignedDate > verifyDate && bookStatus < 3) {
          refinedData.isNew = true;
        }
        /* For notifications */
        if (DataFormatter.getObjectInStorage('getNewBooksCount')) {
          const notificationLastVisitedTime = DataFormatter.getObjectInStorage('notificationsLastVisitedTime');
          if (notificationLastVisitedTime) {
            var verifyDate1 = notificationLastVisitedTime;
          } else {
            var verifyDate1 = dateToday.setDate(dateToday.getDate() - daysLimit);
          }
          if (assignedDate > verifyDate1 && bookStatus < 3) {
            refinedData.isNewForNotifications = true;
          }
        }

        refinedData.metadata = {};
        if (productItem.metadata) {
          if (productItem.metadata.readingLevels) {
            if (productItem.metadata.readingLevels instanceof Array) {
              refinedData.metadata.readingLevels = productItem.metadata.readingLevels[0];
            } else {
              refinedData.metadata.readingLevels = productItem.metadata.readingLevels.toString();
            }
            refinedData.metadata.readingLevelsForFilter = productItem.metadata.readingLevels;
          }
          if (productItem.metadata.information) {
            refinedData.metadata.information = productItem.metadata.information.toString();
          }
          if (productItem.metadata.activities) {
            refinedData.metadata.activities = productItem.metadata.activities;
          }
          if (productItem.metadata.eCommLink) {
            refinedData.metadata.eCommLink = productItem.metadata.eCommLink[0];
          }
          if (productItem.metadata.teachingNotes) {
            refinedData.metadata.teachingNote = productItem.metadata.teachingNotes[0];
          }
          if (productItem.metadata.resources) {
            refinedData.metadata.resources = productItem.metadata.resources[0];
          }
          if (productItem.metadata.lessonPlans) {
            refinedData.metadata.lessonPlans = productItem.metadata.lessonPlans[0];
          }
          if (productItem.metadata.topicsOfInterest) {
            refinedData.metadata.topicsOfInterest = productItem.metadata.topicsOfInterest;
          }
        }
        refinedData.bookInfo = {};
        if ((userProductItemArg.pagesRead instanceof Array)) {
          let readingPercentage = parseInt(userProductItemArg.pagesRead.length) / parseInt(userProductItemArg.totalPages);
          if (isNaN(readingPercentage)) { readingPercentage = 0; }
          refinedData.bookInfo.readingCompletion = `${parseFloat(readingPercentage) * 100}%`;
          refinedData.bookInfo.pagesRead = userProductItemArg.pagesRead;
        }
        if (userProductItemArg.assignmentType) {
          refinedData.bookInfo.assignmentType = userProductItemArg.assignmentType;
        }
        if (userProductItemArg.assignedDate) {
          refinedData.bookInfo.assignedDate = userProductItemArg.assignedDate;
        }
        if (userProductItemArg.isReturned) {
          refinedData.bookInfo.isReturned = userProductItemArg.isReturned;
        }
        if (typeof userProductItemArg.isLiked !== 'undefined') {
          refinedData.bookInfo.isLiked = !!userProductItemArg.isLiked;
        }
        if (typeof userProductItemArg.interactiveIncluded !== 'undefined') {
          refinedData.bookInfo.interactiveIncluded = !!userProductItemArg.interactiveIncluded;
        }
        if (typeof userProductItemArg.lastVisitedPage !== 'undefined') {
          refinedData.bookInfo.lastVisitedPage = userProductItemArg.lastVisitedPage;
        }
        if (typeof userProductItemArg.status !== 'undefined') {
          refinedData.bookInfo.status = userProductItemArg.status;
        }
        if (userProductItemArg.isAudioUsed) {
          refinedData.bookInfo.isAudioUsed = userProductItemArg.isAudioUsed;
        }

        if (this.props.schoolId) {
          refinedData.schoolId = this.props.schoolId;
        }
        const userIdTemp = DataFormatter.getKeyFromObject('userInformation', 'id');
        if (userIdTemp) {
          refinedData.userId = userIdTemp;
        }
        const bookLayoutDetails = DataFormatter.getObjectInStorage('bookLayoutDetails') ? DataFormatter.getObjectInStorage('bookLayoutDetails') : {};
        const bookLevelSettingIcon = DataFormatter.getObjectInStorage('bookLevelSettingIcon') ? DataFormatter.getObjectInStorage('bookLevelSettingIcon') : {};
        if (productItem.layout.indexOf('portrait_single_page') > -1 && productItem.layout.indexOf('landscape_single_page') > -1) {
          bookLayoutDetails[productItem.id] = SINGLE_PAGE;
          bookLevelSettingIcon[productItem.id] = false;
          if (productItem.layout.indexOf('portrait_double_page') > -1 || productItem.layout.indexOf('landscape_double_page') > -1) {
            bookLayoutDetails[productItem.id] = DOUBLE_PAGE;
            bookLevelSettingIcon[productItem.id] = true;
          }
          DataFormatter.setObjectInStorage('bookLayoutDetails', bookLayoutDetails);
          DataFormatter.setObjectInStorage('bookLevelSettingIcon', bookLevelSettingIcon);
        } else {
          bookLayoutDetails[productItem.id] = DOUBLE_PAGE;
          bookLevelSettingIcon[productItem.id] = true;
          DataFormatter.setObjectInStorage('bookLayoutDetails', bookLayoutDetails);
          DataFormatter.setObjectInStorage('bookLevelSettingIcon', bookLevelSettingIcon);
        }
        return refinedData;
      } catch (e) {
        console.log(e);
      }
    }
  };

  bookAddCallback = (book, index) => {
    console.log('bookAddCallback', index);
  }

  bookCheckedCallback = (book, index) => {
    console.log('bookCheckedCallback', index);
  }

  favouriteOptionCallback = (book, index) => {
    const { language, intl } = this.props;
    this.setState({
      isLoading: true,
      favouriteClicked: true
    }, () => {
      const productId = book.productId;
      const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
      const userStatusObj = {
        isLiked: book.bookInfo.isLiked
      };
      // AppActions.updateUserProductStatus(userId,productId,userStatusObj);
      Framework.getEventManager().publish(constants.UPDATE_USER_PRODUCT_REQUESTED,
        {
          userId,
          productId,
          userStatusObj
        });
    });

    const gtmData = {
      dataLayer: {
        event: 'favourite',
        event_category: intl.formatMessage(language.getText('favourite')),
        event_action: intl.formatMessage(language.getText('favourite_clicked')),
        event_label: null,
        product_id: book.productId,
        is_liked: (book.bookInfo.isLiked).toString(),
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  }

  flipBookCallback = (book, index) => {
    const { language, intl } = this.props;
    const gtmData = {
      dataLayer: {
        event: 'flip_book_student',
        event_category: intl.formatMessage(language.getText('flip_book_student')),
        event_action: intl.formatMessage(language.getText('book_turnback')),
        event_label: null,
        book_title: book.bookName,
        book_id: book.bookId,
        activity: 'Turn Backside',
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  }

  buyBookCallback = (book, index) => {
    console.log('Buy clicked', index);
  }

  teachingNotesClickCallback = (book, index) => {
    console.log('Teaching clicked', index);
  }

  resourcesClickCallback = (book, index) => {
    console.log('Resources clicked', index);
  }

  lessonPlansClickCallback = (book, index) => {
    console.log('Lesson plans clicked', index);
  }

  sendBookCallback = (book, index) => {
    console.log('send clicked', index);
  }

  moreBooksCallback = (book, index) => {
    const { language, intl } = this.props;
    const selectedBook = {};
    selectedBook.metadata = {};
    if (book.metadata.topicsOfInterest) {
      selectedBook.metadata.topicsOfInterest = book.metadata.topicsOfInterest;
    }
    if (book.metadata.readingLevelsForFilter) {
      selectedBook.metadata.readingLevels = book.metadata.readingLevelsForFilter;
    }

    const gtmData = {
      dataLayer: {
        event: 'more_book_student',
        event_category: intl.formatMessage(language.getText('more_book')),
        event_action: intl.formatMessage(language.getText('more_book_clicked')),
        event_label: null,
        user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
        filter_option: (book && book.hasOwnProperty('metadata')) ? `Number of filter options: ${Object.keys(book.metadata).length}` : null,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);

    const encodedSearchObj = btoa(JSON.stringify(selectedBook));
    this.props.history.push(`/search?params=${encodedSearchObj}`);// Framework.redirectTo('/search?params=');
    // setTimeout(AppActions.switchToLibrary, 1000);
    // AppActions.switchToLibrary();
  }

  intializePrimaryProps = function (books) {
    const { intl, language } = this.props;
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
    const eltConfig = (appConfig.isElt === true);
    let teachingNotes = intl.formatMessage(language.getText('teaching_notes_baac'));
    if (eltConfig) {
      teachingNotes = intl.formatMessage(language.getText('teachers_notes'));
    }
    primaryBookShelfProps = {
      textBookObj: books,
      bookClickCallback: this.handleBookClick,
      bookAddCallback: this.bookAddCallback,
      bookCheckedCallback: this.bookCheckedCallback,
      favouriteOptionCallback: this.favouriteOptionCallback,
      flipBookCallback: this.flipBookCallback,
      returnBookCallback: this.returnBookCallback,
      sendBookCallback: this.sendBookCallback,
      moreBooksCallback: this.moreBooksCallback,
      buyBookCallback: this.buyBookCallback,
      teachingNotesClickCallback: this.teachingNotesClickCallback,
      resourcesClickCallback: this.resourcesClickCallback,
      lessonPlansClickCallback: this.lessonPlansClickCallback,
      favInactiveImageURL: primFavInactiveImageURL,
      favActiveImageURL: primFavActiveImageURL,
      addImageURL: primAddImageURL,
      flipImageURL: primFlipImageURL,
      flipImageURL1: primFlipImageURL1,
      newImageURL: primNewImageURL,
      enableBookSelect: false,
      enableFavourite: true,
      enableAdd: false,
      enableFlip: true,
      enableReturnBook: true,
      enableMoreBooksOption: false,
      enableAddBookOption: false,
      theme: 'primary',
      emptyTextbookText: intl.formatMessage(language.getText('NoBookFound')),
      readingLevelText: intl.formatMessage(language.getText('Reading_Level')),
      activityText: intl.formatMessage(language.getText('activity')),
      teachingNotesText: teachingNotes,
      resourcesText: intl.formatMessage(language.getText('resources_baac')),
      lessonPlansText: intl.formatMessage(language.getText('lesson_plans_baac')),
      openBookText: intl.formatMessage(language.getText('open_book')),
      sendBookText: intl.formatMessage(language.getText('send_book')),
      physicalCopyText: intl.formatMessage(language.getText('physical_copy')),
      addtoMyBookboxText: intl.formatMessage(language.getText('addto_my_bookbox')),
      returnBookText: intl.formatMessage(language.getText('return_book'))
    };
  }

  updateProductsFetched = function () {
    this.updateProductsFetchUpdate(() => {
      DataFormatter.setObjectInStorage('MyBookboxData', this.state.books);
    });
  }

  updateSearchProductsFetched = function () {
    this.updateProductsFetchUpdate();
  }

  isLazyLoadingApplicable = function (newLength) {
    let lazyLoadingApplicable = false;
    if (newLength >= this.state.bookLimitPerFetch && (newLength % this.state.bookLimitPerFetch == 0)) {
      lazyLoadingApplicable = true;
    }
    return lazyLoadingApplicable;
  }

  updateProductsFetchUpdate = function (callback, bookListDataArg, isAutoComplete) {
    let bookitem; let booksList; let productList;
    const dataStoreAutoComplete = [];
    const productListData = this.state.bookBoxProductListData;// argProductListData;
    if (!(productListData instanceof Array && productListData.length > 0)) {
      productList = null;
    } else {
      productList = productListData;
    }
    booksList = [];
    if (this.state.productsLength !== 0
      && this.state.books instanceof Array
      && this.state.books.length > 0
      && (!DataFormatter.getObjectInStorage('isTakeANoteClicked'))) {
      booksList = JSON.parse(JSON.stringify(this.state.books));
    }

    if (bookListDataArg) {
      booksList = bookListDataArg;
    } else if (productList instanceof Array) {
      let productListItem = null;
      for (let i = 0, iLen = productList.length; i < iLen; i++) {
        productListItem = productList[i];
        bookitem = this.refineServiceProductData(productListItem, i);
        if (bookitem) {
          booksList.push(bookitem);
          try {
            dataStoreAutoComplete.push(bookitem.bookName);
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
    const productsCount = productList ? productList.length : 0;

    let productsLength = (!isNaN(this.state.productsLength) ? this.state.productsLength + productsCount : productList.length);

    if (DataFormatter.getObjectInStorage('getNewBooksCount')) {
      DataFormatter.setObjectInStorage('getNewBooksCount', false);
    }
    if (DataFormatter.getObjectInStorage('isTakeANoteClicked')) {
      const count = 0;
      const newBooks = [];
      productsLength = productList.length;
      //  AppActions.sendNewBookCountToStore(count, newBooks)
      setTimeout(() => {
        Framework.getEventManager().publish(constants.STORE_NEW_BOOK_COUNT, { count, books: newBooks });
      }, 10);

      DataFormatter.setObjectInStorage('isTakeANoteClicked', false);
    }
    const updtState = {
      bookshelfDataLoaded: true
    };
    if (isAutoComplete) {
      updtState.dataStoreAutoComplete = dataStoreAutoComplete;
    } else {
      const lazyLoadingApplicable = this.isLazyLoadingApplicable(productsCount);
      updtState.books = booksList;
      updtState.isLoading = false;
      updtState.productsLength = productsLength;
      updtState.lazyLoadingApplicable = lazyLoadingApplicable;
      updtState.productFetchInProgress = false;
    }
    if (!this.state.showKeywordSearch) {
      document.querySelector('.plain_container').style.minHeight = '700px';
      document.querySelector('.plain_container').style.backgroundColor = '#f8f8f8';
    }

    this.setState(updtState, (function (callback) {
      this.forceUpdate();
      this.handleRemoveClass();
      if (typeof callback === 'function') {
        callback();
      }
    }).bind(this, callback));
  }

  updateAutoCompleteSearchProductsFetched = function () {
    this.updateProductsFetchUpdate(null, false, true);
  }


  searchBookshelf = function (searchKeyword, filterBy) {
    if ((!searchKeyword || searchKeyword === null) && (!filterBy || filterBy == 'all')) {
      this.setState({
        searchKeyword: '',
        filterBy: '',
        isLoading: true,
        productsLength: 0,
        productFetchInProgress: true,
        searchOccured: false

      }, function () {
        // AppActions.fetchProductByUserId(this.props.userId, false, 25, 0, '-assignedDate');
        Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
          {
            userId: this.props.userId,
            includeReturned: false,
            searchlimit: 25,
            offset: 0
          });
      });
    } else {
      this.setState({
        searchKeyword,
        filterBy,
        isLoading: true,
        productsLength: 0,
        productFetchInProgress: true,
        searchOccured: true
      }, (function (searchKeyword, filterBy) {
        const date = filterBy === 'new' ? '-assignedDate' : '-updatedAt';
        // AppActions.searchUserProducts(this.props.userId, (searchKeyword !== null ? searchKeyword : ''), filterBy, false, 25, 0, date);
        setTimeout(() => {
          Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_REQUESTED, {
            userId: this.props.userId,
            searchKeyword: (searchKeyword !== null ? searchKeyword : ''),
            filterByArg: filterBy,
            limit: 25,
            offset: 0,
            date
          });
        }, 2000);
      }).bind(this, searchKeyword, filterBy));
    }
  }

  searchBookshelfAutoComplete = function (searchKeyword, filterBy) {
    if (searchKeyword !== null) {
      // AppActions.searchUserProducts(this.props.userId, searchKeyword, filterBy, true);
      Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_REQUESTED, {
        userId: this.props.userId,
        searchKeyword: (searchKeyword !== null ? searchKeyword : ''),
        filterByArg: filterBy,
        limit: 25,
        offset: 0,
        date: ''
      });
    }
  }

  returnBookCallback = (book, index) => {
    try {
      $('.mainAppContainer')[0].scrollTop = 0;
    } catch (e) { }
    this.setState({
      showReturnbookPopup: true,
      bookSelectedToReturn: book
    }, () => {
      const returnBookModalPanel = document.getElementById('returnBookModalPanel');
      if (returnBookModalPanel) {
        returnBookModalPanel.focus();
      }
    });
  }

  closeReturnBookPopUp = () => {
    this.setState({
      showReturnbookPopup: false,
      bookSelectedToAdd: {}
    });
  }

  handleReturnbookPopup = () => {
    if (this.state.showReturnbookPopup) {
      return (
        <ReturnBookComponent book={this.state.bookSelectedToReturn} handleClose={this.closeReturnBookPopUp} />
      );
    }
    return null;
  }

  enableLoading = (callback) => {
    this.setState({
      isLoading: true
    }, (typeof callback === 'function' ? callback : () => { }));
  }

  returnBooksBasedOnAssignmentType = (assignmentType) => {
    const books = [];
    if (this.state.books) {
      for (let i = 0; i < this.state.books.length; i++) {
        if (this.state.books[i].bookInfo.assignmentType === assignmentType) {
          books.push(this.state.books[i]);
        }
      }
    }
    return books;
  }

  getBookReadCount = (type) => {
    const userListDataArray = this.state.getBookReadCountData;
    // var userListDataArray = userInitialBookList;
    const booksRead = []; const booksReadyear = []; const
      booksReadMonth = [];
    let bookReadCount = 0;
    if (userListDataArray && userListDataArray.length > 0) {
      userListDataArray.map((userdata, index) => {
        if (userdata.readCompletionDate) {
          booksRead.push(userdata.readCompletionDate);
          const date = new Date();
          const y = date.getFullYear();
          const m = date.getMonth();
          const firstDayofMonth = Math.floor(new Date(y, m, 1).getTime() / 1000);
          const lastDayofMonth = Math.floor(new Date(y, m + 1, 0).setHours(23, 59, 59, 999) / 1000);

          if (m < 8) {
            var firstDayofYear = Math.floor(new Date(y - 1, 8, 1).getTime() / 1000);
            var lastDayofYear = Math.floor(new Date(y, 7, 31).setHours(23, 59, 59, 999) / 1000);
          } else {
            var firstDayofYear = Math.floor(new Date(y, 8, 1).getTime() / 1000);
            var lastDayofYear = Math.floor(new Date(y + 1, 7, 31).setHours(23, 59, 59, 999) / 1000);
          }

          if (userdata.readCompletionDate >= firstDayofMonth && userdata.readCompletionDate <= lastDayofMonth) {
            booksReadMonth.push(userdata.readCompletionDate);
          }

          if (userdata.readCompletionDate >= firstDayofYear && userdata.readCompletionDate <= lastDayofYear) {
            booksReadyear.push(userdata.readCompletionDate);
          }
        }
      });
      if (type == 'overAll') {
        bookReadCount = booksReadyear.length;
      }
      if (type == 'month') {
        bookReadCount = booksReadMonth.length;
      }
    }
    return bookReadCount;
  }

  handleRemoveClass = () => {
    if (this.state.books != null) {
      const Teacherexistance = !!this.state.books.find(obj => obj.bookInfo.assignmentType === 'teacher');
      const Selfexistance = !!this.state.books.find(obj => obj.bookInfo.assignmentType === 'self');
      setTimeout(() => {
        let filterOpenStatus = document.getElementById('searchConsoleFilter');
        let textBookWrapComponent = document.getElementsByClassName('baacTextBookWrapComponent').length;
        textBookWrapComponent = textBookWrapComponent > 0 ? document.getElementsByClassName('baacTextBookWrapComponent')[0].getBoundingClientRect().height : 0;
        filterOpenStatus = filterOpenStatus !== null ? !!filterOpenStatus.classList.contains('hidden') : false;
        if (this.state.books.length === 0 && filterOpenStatus) {
          if (document.getElementsByClassName('baacTextBookWrapComponent')[0] && document.getElementsByClassName('baacTextBookWrapComponent')[0].classList != undefined) {
            document.getElementsByClassName('baacTextBookWrapComponent')[0].classList.remove('baacTextBookWrapComponentCount');
          }
        } else if (
          (this.state.books.length > 0 && !(Teacherexistance && Selfexistance))
          || (this.state.books.length === 0 && !filterOpenStatus)
        ) {
          if (document.getElementsByClassName('baacTextBookWrapComponent')[0] && document.getElementsByClassName('baacTextBookWrapComponent')[0].classList != undefined) {
            document.getElementsByClassName('baacTextBookWrapComponent')[0].classList.add('baacTextBookWrapComponentCount');
          }
        } else if (document.getElementsByClassName('baacTextBookWrapComponent')[0] && document.getElementsByClassName('baacTextBookWrapComponent')[0].classList != undefined) {
          document.getElementsByClassName('baacTextBookWrapComponent')[0].classList.remove('baacTextBookWrapComponentCount');
        }
      }, 1000);
    }
  };

  renderStudentDashboard = function () {
    const { language, intl } = this.props;
    return (
      <div className="student-dashboard-container clearFix">
        <div className="student-dashboard-box-overall clearFix">
          <div className="student-dashboard-content clearFix">
            <span>
              <p className="student-dashboard-text font-bold">
                {intl.formatMessage(language.getText('Books_Read'))}
                {' '}
              </p>
            </span>
            <span>
              <p className="student-dashboard-text-bottom">{intl.formatMessage(language.getText('Overall'))}</p>
            </span>
          </div>
          <div className="student-dashboard-content-number clearFix">
            <span>{this.getBookReadCount('overAll')}</span>
          </div>
        </div>
        <div className="student-dashboard-box-thismonth clearFix">
          <div className="student-dashboard-content clearFix">
            <span>
              <p className="student-dashboard-text font-bold">
                {intl.formatMessage(language.getText('Books_Read'))}
                {' '}
                {' '}
              </p>
            </span>
            <span>
              <p className="student-dashboard-text-bottom">{intl.formatMessage(language.getText('This_Month'))}</p>
            </span>
          </div>
          <div className="student-dashboard-content-number clearFix">
            <span>{this.getBookReadCount('month')}</span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    // console.log("MyBookBox render", new Date(), new Date().getTime());
    const { language, intl } = this.props;
    let shelfComponent; const headingElement = null; let
      keywordSearchSection;
    let emptyBookShelf = null;
    let teacherAssignedBookShelf = null;
    let selfAssignedBookShelf = null;
    const searchConsoleStyle = {};
    let bookWrapperStyle = {};
    let studentDashboardStyle = {};
    bookWrapperStyle = { paddingTop: '30px', paddingBottom: '120px' };

    if ((!this.state.books) || (this.state.books.length === 0)) {
      this.intializePrimaryProps(this.state.books);
      emptyBookShelf = (
        <div className="bookshelf_wrapper" style={bookWrapperStyle}>
          {this.state.bookshelfDataLoaded ? <TextbookShelf {...primaryBookShelfProps} /> : null}
        </div>
      );
    } else {
      studentDashboardStyle = { display: 'block' };
      const teacherAssignedBooks = this.returnBooksBasedOnAssignmentType('teacher');
      const selfAssignedBooks = this.returnBooksBasedOnAssignmentType('self');

      if (teacherAssignedBooks.length > 0) {
        var changedMargin = `${this.calculateWindowWidth(true) / 2}px`;
        this.intializePrimaryProps(teacherAssignedBooks);
        const booksCount = <div style={{ fontWeight: 'bold' }}>{teacherAssignedBooks.length}</div>;
        const booksCountSuffix = teacherAssignedBooks.length > 1 ? intl.formatMessage(language.getText('NoOfbooksSuffix')) : intl.formatMessage(language.getText('book_suffix'));
        teacherAssignedBookShelf = (
          <div style={{ clear: 'both' }}>
            <div id="teacherAssignedBookShelfComponent" className="assignmentInfo" style={{ marginLeft: changedMargin }}>
              <div className="fromMyTeacher">{intl.formatMessage(language.getText('from_my_teacher'))}</div>
            </div>
            <div className="bookshelf_wrapper" style={{ paddingTop: '30px' }}>
              {this.state.bookshelfDataLoaded ? <TextbookShelf {...primaryBookShelfProps} /> : null}
            </div>
          </div>
        );
      }
      if (selfAssignedBooks.length > 0) {
        this.intializePrimaryProps(selfAssignedBooks);
        const booksCount = <div style={{ fontWeight: 'bold' }}>{selfAssignedBooks.length}</div>;
        const booksCountSuffix = selfAssignedBooks.length > 1 ? intl.formatMessage(language.getText('NoOfbooksSuffix')) : intl.formatMessage(language.getText('book_suffix'));
        selfAssignedBookShelf = (
          <div>
            <div id="selfAssignedBookShelfComponent" className="assignmentInfo" style={{ marginLeft: changedMargin }}>
              <div className="booksIChoose">
                {intl.formatMessage(language.getText('books_i_choose'))}
              </div>
              <div className="books" />
            </div>
            <div className="bookshelf_wrapper last" style={bookWrapperStyle}>
              {this.state.bookshelfDataLoaded ? <TextbookShelf {...primaryBookShelfProps} /> : null}
            </div>
          </div>
        );
      }
    }

    keywordSearchSection = (
      <PrimaryRefineSearchConsole
      // handleScrollWithRecords={this.handleRemoveClass}
        headingElement={null}
        defaultPrimaryFilter="all"
        primaryFilterOptions={constants.primaryMyBookBoxFilter}
        mode="primary"
        handleSearch={this.searchBookshelf}
        dataStoreAutoComplete={this.state.dataStoreAutoComplete}
        enableLoading={this.enableLoading}
        parentComponent="myBookBox"
        showKeywordSearch={false}
      />
    );

    return (
      <div className="plain_container app_body_container school_library_container" style={(this.props.masterStyle ? this.props.masterStyle : {})}>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <div className="search_console_wrapper primary" style={searchConsoleStyle}>
          {keywordSearchSection}
        </div>
        <div className="student-dashboard" style={studentDashboardStyle}>
          {this.renderStudentDashboard()}
        </div>
        {this.handleReturnbookPopup()}
        {emptyBookShelf}
        {teacherAssignedBookShelf}
        {selfAssignedBookShelf}
      </div>
    );
  }
}

// MyBookBox.contextTypes= {
//   router: React.PropTypes.object.isRequired
// }

// export default withRouter(MyBookBox);
export default compose(
  inject('language'),
  observer
)(injectIntl(MyBookBox));
