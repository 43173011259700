import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ReactHtmlParser from 'react-html-parser';
import TagManager from 'react-gtm-module';
import WelcomeMessage from '../../../common/components/WelcomeMessage';
import CustomMessage from '../../../common/components/CustomMessage';
import LockMessage from '../../../common/components/LockMessage';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import logo from '../../../assets/images/pearson_logo.png';
import logo2 from '../../../assets/images/pearson_logo@2x.png';
import logo3 from '../../../assets/images/pearson_logo@3x.png';
import arrowLeftActive from '../../../assets/images/young_learners/arrow-left-active.png';
import CommonUtils from '../../../common/utils/CommonUtils';

const PasswordSelectionDoneButton = withStyles({
  root: {
    width: '501px',
    height: '72px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 24,
    padding: '6px 12px',
    marginLeft: '2%',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#222e5e',
    borderColor: '#222e5e',
    color: '#fff',
    borderRadius: '13px',
    fontFamily: [
      'HeinemannSpecial-Bold'
    ].join(','),
    '&:hover': {
      backgroundColor: '#222e5e',
      borderColor: '#222e5e',
      color: '#fff',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#222e5e',
      borderColor: '#222e5e',
      color: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#d1d1d1',
      borderColor: '#d1d1d1',
      color: '#fff'
    }
  }
})(Button);

class InputPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: {
        show: false,
        message: '',
        color: '#fc5b43'
      },
      showLockMessage: {
        show: false
      },
      passwordValidation: null,
      formValues: {
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false
      }
    };
  }

  componentDidMount() {
    // Page view for Select Password
    const gtmPageData = CommonUtils.getDefaultGAPageView();
    gtmPageData.dataLayer.page_title = 'Input Password';
    TagManager.dataLayer(gtmPageData);
  }

  handleChange = (event) => {
    if (typeof event.target === 'undefined') {
      return;
    }
    const enteredPassword = event.target.value;
    this.setState(prevState => ({
      formValues: {
        ...this.state.formValues,
        password: enteredPassword,
        showPassword: prevState.formValues.showPassword
      }
    }));
  };

  handleClickShowPassword = (event) => {
    this.setState(prevState => ({
      formValues: {
        ...this.state.formValues,
        showPassword: !prevState.formValues.showPassword
      }
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showErrorMessage: {
        show: false
      }
    });
  };

  handleBackArrowOnClick = () => {
    // this.props.history.push("/login/selectuser/");
    Framework.redirectTo('/student/selectuser/');
  }

  handleLockClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showLockMessage: {
        show: false
      }
    });
    Framework.redirectTo('/student/studentcode/');
  };


  onClickForgotPassword = () => {
    const { language, intl } = this.props;
    this.setState({
      showErrorMessage: {
        show: true,
        message: ReactHtmlParser(intl.formatMessage(language.getText('ask_teacher'))),
        color: '#48a744'
      }
    });
  }

  callbackLoginExtFunction = (status) => {
    console.log('ENV=', env.ENVIRONMENT);
    const { language, intl } = this.props;
    if (typeof status !== 'undefined') {
      this.setState({ passwordValidation: { status: status.status, resp: status.data } });
      if (status.status === 'success') {
        localStorage.setItem('initializeGA', true);
        localStorage.removeItem('class_code');
        window.location.assign('/app');
      }
      if (status.status === 'lock') {
        this.setState({
          showLockMessage: {
            show: true
          }
        });
      } else if (status.status === 'error') {
        this.setState({
          showErrorMessage: {
            show: true,
            message: ReactHtmlParser(intl.formatMessage(language.getText('error_msg_1'))),
            color: '#fc5b43'
          }
        });
      } else if (status.status === 'inactive') {
        this.setState({
          showLockMessage: {
            show: true
          }
        });
      }
    } else {
      this.setState({
        showErrorMessage: {
          show: true,
          message: <p>Username/Password failure, please try again !</p>,
          color: '#fc5b43'
        }
      });
    }
  }

  extLogin = (username, password, cid, endpoint, callback) => {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      try {
        if (typeof this.response !== 'undefined' && this.response !== '') {
          var response = JSON.parse(this.response.replace(/\\/g, ''));
        }
      } catch (e) {
        console.log('JSON Parsing Error');
        console.log(e);
      }
      if (this.readyState === 4) {
        if (this.status === 200 && response.status === 'success') {
          console.log('Logged in');
          callback({
            status: 'success',
            message: '',
            data: response
          });
        } else if (this.status === 401 && response.status === 'error') {
          if (response.fault.detail.errorcode === 'piui.accountIsLocked') {
            console.log('Account locked');
            callback({
              status: 'lock',
              message: 'Account locked, Please Try after 15 Min',
              data: response
            });
          } else if (response.fault.detail.errorcode === 'piui.authFailure') {
            console.log('Invalid username/password');
            callback({
              status: 'error',
              message: 'Invalid username/password',
              data: response
            });
          } else if (response.fault.detail.errorcode === 'piui.accountIsNotActive') {
            console.log('Account not active');
            callback({
              status: 'inactive',
              message: 'Account not active, Please Ask your teacher for help.',
              data: response
            });
          }
        } else {
          console.log('Unknown error');
          callback({ status: 'error', message: 'Try again!, Please Ask your teacher for help.', data: response });
        }
      }
    };
    // Calling IES /login/webcredentials API with username and password to set user's session cookie
    xhttp.open('POST', `${endpoint}/login/webcredentials`, true);
    xhttp.setRequestHeader(
      'Content-type',
      'application/x-www-form-urlencoded'
    );
    xhttp.withCredentials = true;
    xhttp.send(
      `username=${username}&password=${password}&client_id=${cid}`
    );
  }

  onClickPasswordDoneButton = () => {
    const loginUrl = constants.QA_BASE_URL.replace('/piui', '');
    const setIesUserSelectedFromRosterData = (localStorage.getItem('setIesUserSelectedFromRosterData') !== null ? JSON.parse(localStorage.getItem('setIesUserSelectedFromRosterData')) : '');
    if (this.state.formValues.password.length >= constants.password_min_length
        && constants.password_pattern.test(this.state.formValues.password)) {
      if (setIesUserSelectedFromRosterData !== null && setIesUserSelectedFromRosterData.userName !== '') {
        // window.extLogin(setIesUserSelectedFromRosterData.userName, this.state.formValues.password, QA_CLIENT_ID, loginUrl, this.callbackLoginExtFunction);
        this.extLogin(setIesUserSelectedFromRosterData.userName,
          this.state.formValues.password,
          constants.STUDENT_CLIENT_ID,
          loginUrl,
          this.callbackLoginExtFunction);
      } else {
        this.setState({
          showErrorMessage: {
            show: true,
            message: <p>Username/Password failure, please try again !</p>,
            color: '#fc5b43'
          }
        });
      }
    } else {
      this.setState({
        showErrorMessage: {
          show: true,
          message: <p>Invalid Password!</p>,
          color: '#fc5b43'
        }
      });
    }
  }

  showDoneButton = () => {
    const { language, intl } = this.props;
    if (typeof this.state.formValues.password !== 'undefined' && this.state.formValues.password !== ''
        && this.state.formValues.password.length >= constants.password_min_length) {
      return (
        <div className="col pad0 input-password-btn"><PasswordSelectionDoneButton onClick={this.onClickPasswordDoneButton}>{intl.formatMessage(language.getText('done_msg'))}</PasswordSelectionDoneButton></div>
      );
    }

    return (
      <div className="col pad0 input-password-btn"><PasswordSelectionDoneButton disabled>{intl.formatMessage(language.getText('done_msg'))}</PasswordSelectionDoneButton></div>
    );
  }

  render() {
    const { language, intl } = this.props;
    // const arrowLeftActive = "/assets/images/young_learners/arrow-left-active.png";
    const domainSpecificClass = localStorage.getItem('isDomainSpark') !== 'true' ? 'select-password-left-arrow-active select-password-left-arrow-active-global' : 'select-password-left-arrow-active';
    return (
      <div>
        <div>
          <nav className="navbar navbar-expand-sm rectangle-1">
            <img
              onClick={this.onClickLogo}
              src={logo}
              alt="Pearson Logo"
              srcSet={`${logo2} 2x,${logo3} 3x`}
              className="pearson-logo"
            />
          </nav>
        </div>
        {(this.state.showLockMessage.show === true) ? <div id="cover" /> : <div />}
        <div className={`container-fluid selectpassword-container ${(this.state.showLockMessage.show === true) ? 'blur-backdrop' : ''}`}>
          <div className="welcome-container">
            <WelcomeMessage />
            <div className="back-arrow-position-password col-md-12  col-lg-2 col-xl-2">
              <div className="row">
                <div className="col student-id-icon">
                  <div
                    tabIndex="0"
                    className="back-arrow-btn-outer"
                    onKeyDown={(e) => {
                      if (e.key == 'Enter') {
                        this.handleBackArrowOnClick();
                      }
                    }}
                  >
                    <img
                      onClick={() => this.handleBackArrowOnClick()}
                      src={arrowLeftActive}
                      alt="arrow Left Active"
                      className={domainSpecificClass}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-password">
            <div className="password-column">{this.renderSelectedUser()}</div>
            <div className="password-column">
              <p className="text-style-1">
                {intl.formatMessage(language.getText('what_is_your_password'))}
              </p>

              <div className="col password-type-text-container p-0">
                <TextField
                  id="password-type-text"
                  margin="none"
                  inputProps={{
                    minLength: constants.password_min_length,
                    pattern: constants.password_pattern
                  }}
                  value={this.state.formValues.password}
                  onChange={this.handleChange}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      this.onClickPasswordDoneButton();
                    }
                  }}
                  placeholder={intl.formatMessage(language.getText('password_text'))}
                  className="password-input"
                  type={
                    this.state.formValues.showPassword ? 'text' : 'password'
                  }
                />
              </div>

              <FormGroup className="show-password-checkbox">
                <FormControlLabel
                  control={(
                    <Checkbox
                      icon={
                        <CheckBoxOutlineBlankIcon style={{ fill: '#74797b' }} />
                      }
                      checkedIcon={<CheckBoxIcon style={{ fill: '#74797b' }} />}
                      onChange={this.handleClickShowPassword}
                      checked={this.state.formValues.showPassword}
                      className="show-password-label"
                    />
)}
                  label={intl.formatMessage(language.getText('show_password'))}
                />
              </FormGroup>

              {this.showDoneButton()}

              <div
                className="forgot-password"
                onClick={this.onClickForgotPassword}
              >
                {intl.formatMessage(language.getText('forgot_password'))}
              </div>
            </div>
          </div>
        </div>
        {(this.state.showErrorMessage.show === true) ? <CustomMessage color={this.state.showErrorMessage.color} message={this.state.showErrorMessage.message} show={this.state.showErrorMessage.show} handleClose={this.handleClose} /> : ''}
        {(this.state.showLockMessage.show === true) ? <LockMessage showLockMessage={this.state.showLockMessage} handleLockClose={this.handleLockClose} props={this.props} /> : ''}
      </div>
    );
  }

  renderSelectedUser = () => {
    const { language, intl } = this.props;
    const setIesUserSelectedFromRosterData = (typeof localStorage.getItem('setIesUserSelectedFromRosterData') !== 'undefined' ? JSON.parse(localStorage.getItem('setIesUserSelectedFromRosterData')) : '');
    if (localStorage.getItem('setIesUserSelectedFromRosterData') && typeof localStorage.getItem('setIesUserSelectedFromRosterData') !== 'undefined' && localStorage.getItem('setIesUserSelectedFromRosterData') !== null) {
      return (
        <div>
          <div className="row" style={{ marginLeft: '-15px !important', marginRight: '-15px !important' }}>
            <div className="col">
              <p className="you-are-text">
                {intl.formatMessage(language.getText('you_are'))}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col justify-content-center align-items-center">
              <img
                src={setIesUserSelectedFromRosterData.logoURL}
                alt={setIesUserSelectedFromRosterData.studentName}
                className="m-auto d-block selected-user-icon"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="user-name">{setIesUserSelectedFromRosterData.studentName}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}


// export default InputPassword;
InputPassword.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(InputPassword));
