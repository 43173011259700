/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';
import DataFormatter from '../../../common/utils/DataFormatter';

export default class CourseService extends BaseService {
  constructor() {
    super(constants.COURSELIST_REQUESTED, 'CourseServiceStatus');
  }

  handleEvent(eventName, event) {
    const gabProductId = CommonUtils.getGabProductId();
    let headerParams = null;
    headerParams = {
      headers: {
        productid: gabProductId,
        schoolid: event.schoolId,
        userid: event.userId,
        appversion: 'app'
      }
    };
    // console.log("fetch-enrolled-course-by-school-id handleEvent: ", new Date(), new Date().getTime());
    return this.getClient('hapi').get(
      '/fetch-enrolled-course-by-school-id',
      headerParams
    );
  }

  handleResponse(response,event) { // eslint-disable-line class-methods-use-this
    // console.log("fetch-enrolled-course-by-school-id handleResponse: ", new Date(), new Date().getTime());
    if (response.data && response.data.data && response.data.data.length > 0) {
      let schoolIdsArr = DataFormatter.getObjectInStorage('schoolIdsArr');
      if(schoolIdsArr && schoolIdsArr.length >= 1) {
        const currentSchoolId = (searchingId) => searchingId === event.schoolId;
        let nextSchoolIndex = schoolIdsArr.findIndex(currentSchoolId);
        if(nextSchoolIndex === 0) {
          Framework.getStoreRegistry().getStore('dlCourseForNavbar').set({ items: [] });
        }
        const courseList = toJS(
          Framework.getStoreRegistry().getStore('dlCourseForNavbar')
        );
        response.data.data.map(course => (
          courseList.items.push(course.id ? course : '')
        ));
        if(nextSchoolIndex+1 < schoolIdsArr.length) {
          Framework.getStoreRegistry().getStore('dlCourseForNavbar').set(courseList,true,schoolIdsArr[nextSchoolIndex+1],event.userId);
        } else {
          Framework.getStoreRegistry().getStore('dlCourseForNavbar').set(courseList,false,event.schoolId,event.userId);
          Framework.getEventManager().publish(constants.COURSELIST_FETCHED,courseList);
        }
      } else {
        Framework.getStoreRegistry().getStore('dlCourseForNavbar').set({ items: [] });
        const courseList = toJS(
          Framework.getStoreRegistry().getStore('dlCourseForNavbar')
        );
        response.data.data.map(course => (
          courseList.items.push(course.id ? course : '')
        ));
        Framework.getStoreRegistry().getStore('dlCourseForNavbar').set(courseList);
        Framework.getEventManager().publish(constants.COURSELIST_FETCHED,courseList);
      }
      // Framework.getEventManager().publish(constants.COURSELIST_FETCHED,courseList);
    } else {
      let schoolIdsArr = DataFormatter.getObjectInStorage('schoolIdsArr');
      if(schoolIdsArr && schoolIdsArr.length > 1 && event.schoolId) {
        const currentSchoolId = (searchingId) => searchingId === event.schoolId;
        let nextSchoolIndexElse = schoolIdsArr.findIndex(currentSchoolId);
        if(!nextSchoolIndexElse || nextSchoolIndexElse === 'undefined' || nextSchoolIndexElse === undefined || nextSchoolIndexElse === 0) {
          Framework.getStoreRegistry().getStore('dlCourseForNavbar').set({ items: [] });
        }
        const courseListElse = toJS(
          Framework.getStoreRegistry().getStore('dlCourseForNavbar')
        );
        if(nextSchoolIndexElse+1 < schoolIdsArr.length) {
          Framework.getStoreRegistry().getStore('dlCourseForNavbar').set(courseListElse,true,schoolIdsArr[nextSchoolIndexElse+1],event.userId);
        } else {
          if(courseListElse.items) {
            Framework.getEventManager().publish(constants.COURSELIST_FETCHED,courseListElse);
          } else {
            Framework.getStoreRegistry().getStore('dlCourseForNavbar').set(courseListElse,false,event.schoolId,event.userId);
          }
        }
      } else {
        const courseList = toJS(
          Framework.getStoreRegistry().getStore('dlCourseForNavbar')
        );
        if(courseList.items) {
          Framework.getEventManager().publish(constants.COURSELIST_FETCHED,courseList);
        } else {
          Framework.getEventManager().publish(constants.COURSELIST_EMPTY, {
            message: 'Unable to fetch user enrolled Course Data'
          });
        }
      }
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.COURSELIST_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
