import axios from 'axios';
import Hawk from 'hawk';
import DataFormatter from '../../../common/utils/DataFormatter';

export default class SpdfAndEpubMapper {
  /**
   *
   * @param {Object} param
   * {
   *   algorithm: 'sha256',
   *   baseUrl: 'https://api-bookshelf-prod.gls.pearson-intl.com/v1/',
   *   bookId: '5b85018597f41b2d68414f7e',
   *   cloudFrontUrl: 'https://d38l3k3yaet8r2.cloudfront.net/',
   *   hawkId: 'dh37fgj492je',
   *   hawkKey: 'werxhqb98rpaxn39848xrunpaw3489ruxnpa98w4rxn'
   * }
   */
  static getBookData = (param) => {
    const credentials = {
      id: param.hawkId,
      key: param.hawkKey,
      algorithm: param.algorithm
    };
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const header = Hawk.client.header(param.baseUrl, 'GET', { credentials, ext: '', timestamp });
    return new Promise((resolve) => {
      axios({
        method: 'get',
        url: param.baseUrl,
        headers: {
          Authorization: header.header
        }
      }).then((bookData) => {
        if (bookData && bookData.data && bookData.data.metadata && bookData.data.metadata.type) {
          const bookType = bookData.data.metadata.type;
          if (bookType === 'epub') {
            resolve(SpdfAndEpubMapper.getEpubData(bookData.data, param.cloudFrontUrl));
          } else {
            SpdfAndEpubMapper.getSpdfData(bookData.data, param.cloudFrontUrl).then((responseSpdf) => {
              resolve(responseSpdf);
            });
          }
        } else {
          resolve('');
        }
      });
    });
  }

  /**
   * Get EPUB data
   *
   * @param {*} manifestData - manifest data
   * @param {*} cloudFrontUrl - url
   */
  static getEpubData = (manifestData, cloudFrontUrl) => {
    const book = {
      pagePlaylist: [],
      tocData: {
        children: []
      }
    };

    /* Construction of page playlist - starts */
    if (manifestData && manifestData.pages) {
      const cdnUrl = `${cloudFrontUrl}${manifestData.metadata.contentPath}`;
      for (const page of manifestData.pages) {
        const title = `Page ${page.index + 1}`;
        let uri;

        if (page.url.indexOf('file://') !== -1) {
          uri = page.url.replace(/\\/g, '/');
        } else {
          uri = cdnUrl + page.url.split('content/')[1];
        }
        const pageInfo = {
          href: page.href,
          id: page.href.indexOf('/') > -1
            ? page.href.split('\\').pop().split('/').pop()
            : page.href, // Playlist id and toc id should be same

          index: page.index,
          pagenumber: (page.index + 1).toString() || '',
          title,
          type: 'page',
          pageType: page.pageType ? page.pageType : 'backmatter',
          uri // URL for navigation
        };

        book.pagePlaylist.push(pageInfo);
      }
    }

    // update the page labels to the page number and title
    if (manifestData.pageLabels && Object.entries(manifestData.pageLabels).length !== 0) {
      const pageLabels = Object.values(manifestData.pageLabels);
      const playListArr = book.pagePlaylist;

      for (let i = 0; i < playListArr.length; i += 1) {
        playListArr[i].title = pageLabels[i];
        playListArr[i].pagenumber = pageLabels[i] || '';
      }
    }
    /* Construction of page playlist - Ends */

    /* Construction of media overlay - starts */
    if (manifestData.mediaOverlays) {
      Object.values(manifestData.mediaOverlays).forEach((mediaOverlay) => {
        for (const key of Object.keys(mediaOverlay.body.pars)) {
          mediaOverlay.body.pars[key].text.src = mediaOverlay.body.pars[key].text.src.replace('http:', 'https:');
        }
      });
      book.tocData.mediaOverlays = manifestData.mediaOverlays;
    }
    /* Construction of media overlay - Ends */

    /* Construction of layout dimentions - starts */
    book.isFixedLayout = false; // indicated fixed or reflowable books
    try {
      if (manifestData.metadata
        && manifestData.metadata.custom
        && manifestData.metadata.custom.height > 0) {
        book.isFixedLayout = true;
        book.layoutDimensions = {};
        book.layoutDimensions.height = manifestData.metadata.custom.height;
        book.layoutDimensions.width = manifestData.metadata.custom.width;
        // Uncomment the below if block in case of enabling double page layout
        // if (manifestData.metadata.custom.versoStart === 0) {
        //   book.layoutDimensions.rightStart = true; // first page in the book starts form right
        // } else {
        //   book.layoutDimensions.rightStart = false;
        // }
        book.layoutDimensions.rightStart = false;
      }
    } catch (e) {
      // console.log(e);
    }
    /* Construction of layout dimentions - Ends */

    /* Construction of ToC - starts */
    if (manifestData.toc) {
      const tocList = manifestData.toc;
      const tocDataList = [];
      const drillToDeep = (toc) => {
        const list = {
          // _id: toc._id.str,
          children: [],
          href: toc.href,
          id: toc.filename,
          label: toc.label,
          url: toc.url,
          urn: toc.filename
        };
        const children = toc.subitems;

        if (children) {
          children.forEach((child) => {
            list.children.push(drillToDeep(child));
          });
        }

        return list;
      };
      tocList.forEach((node) => {
        const nodes = drillToDeep(node);

        tocDataList.push(nodes);
      });
      book.tocData.children = tocDataList;
    }

    /* Construction of ToC - Ends */

    /* Final book data
  book: {
    isFixedLayout, // pass this value as props 'bookInfo > isFixedLayout' in VR
    layoutDimensions: {
      height,
      rightStart,
      width
    }, // pass this value as props 'bookInfo > layoutDimensions' in VR , present only for fixed layout books
    pagePlaylist, // Page playlist, pass this as props 'pagePlaylist' in VR
    tocData: {
      children,
      mediaOverlay
    } // TOC list, pass this as props 'tocData' in VR
  }
  */

    return book;
  };

  /**
   * Get SPDF data
   *
   * @param {*} manifestData - manifest data
   * @param {*} cloudFrontUrl - url
   */
  static getSpdfData = (manifestData, cloudFrontUrl) => {
    // const $ = window.$;
    let bookmarkArray = [];
    let bookmarkFinalArray = [];
    let foxitAssetUrl = '';
    const book = {
      pagePlaylist: [],
      tocData: {
        children: []
      }
    };
    const foxitAssetsFolder = manifestData && manifestData.metadata && manifestData.metadata.foxitAssetsFolder
      ? manifestData.metadata.foxitAssetsFolder
      : 'foxit-assets';

    foxitAssetUrl = `${cloudFrontUrl}${manifestData.metadata.contentPath}${foxitAssetsFolder}`;

    /* Construction of page playlist - Starts */
    const pageLength = manifestData.pagesCount || 0;
    let pageInfo;

    for (let i = 0; i < pageLength; i += 1) {
      pageInfo = {
        _id: manifestData.id + i,
        href: `${foxitAssetUrl}/pages/page${i}`,
        id: `Page ${i}`,
        index: i,
        pagenumber: (i + 1).toString() || '',
        title: `Page ${i + 1}`,
        type: 'page',
        uri: `${foxitAssetUrl}/pages/page${i}`
      };
      book.pagePlaylist.push(pageInfo);
    }
    book.isDoublePageLayout = DataFormatter.getAppConfigKey('isDoublePage') || false;
    /* Construction of page playlist - Ends */

    /* Construction of TOC - Start */
    let tempBookmark = {};
    const listToTree = (tocList) => {
      const map = {};
      let node;
      const roots = [];
      const list = tocList;
      let i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id1] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== 0) {
          list[map[node.parentId]].children.push(node);
        } else {
          roots.push(node);
        }
      }

      return roots;
    };

    return new Promise((resolve) => {
      axios({
        method: 'get',
        url: `https://d2cntbma5621lq.cloudfront.net/${manifestData.metadata.contentPath}foxit-assets/bookmark`
      }).then((response) => {
        const data = response.data;

        bookmarkArray = JSON.parse(data.BookmarkInfo).bmks;
        if (bookmarkArray) {
          for (let i = 0; i < bookmarkArray.length; i += 1) {
            tempBookmark = {
              _id: bookmarkArray[i].parent,
              children: [],
              href: `Page ${bookmarkArray[i].cur}`,
              id: `Page ${bookmarkArray[i].dest.p}`,
              id1: bookmarkArray[i].cur,
              label: bookmarkArray[i].ttl,
              parentId: bookmarkArray[i].parent
            };
            bookmarkFinalArray.push(tempBookmark);
          }
          bookmarkFinalArray = listToTree(bookmarkFinalArray);
          book.tocData.children = bookmarkFinalArray;
        }
        resolve(book);
      });
    });
    /* Construction of TOC - Ends */

    /* Final book data
    book: {
      isDoublePageLayout, // pass this value as props 'bookInfo > isDoublePage' in VR
      pagePlaylist, // Page playlist, pass this as props 'pagePlaylist' in VR
      tocData: {
        children,
      } // TOC list, pass this as props 'tocData' in VR
    }
    */
  };
}
