/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import TeacherContainer from './TeacherContainer';
import Cartridges from './model/Cartridges';
import CartridgeService from './services/CartridgeService';
import CreateTeacherSchoolClassService from './services/CreateTeacherSchoolClassService';
import CreateTeacherPermissionsService from './services/CreateTeacherPermissionsService';
import ProvisionCartridgeSchoolService from './services/ProvisionCartridgeSchoolService';
import FetchClassReportService from './services/FetchClassReportService';
import PostNotesOnEachStudentService from './services/PostNotesOnEachStudentService';
import FetchStudentReportService from './services/FetchStudentReportService';
import UpdateTimeSpentOnEachBookService from './services/UpdateTimeSpentOnEachBookService';

export default {
  path: '/teacher/:pageName',
  exact: true,
  services: [
    new CartridgeService(),
    new CreateTeacherSchoolClassService(),
    new CreateTeacherPermissionsService(),
    new ProvisionCartridgeSchoolService(),
    new FetchClassReportService(),
    new PostNotesOnEachStudentService(),
    new FetchStudentReportService(),
    new UpdateTimeSpentOnEachBookService()
  ],
  stores: [
    {
      name: 'cartridgeList',
      spec: Cartridges,
      saveLocally: false,
      initialState: {
        items: []
      }
    }
  ],
  render: props => <TeacherContainer {...props} />
};
