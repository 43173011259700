import { chunk } from 'lodash';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ReactHtmlParser from 'react-html-parser';
import TagManager from 'react-gtm-module';
import WelcomeMessage from '../../../common/components/WelcomeMessage';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import { STUDENT_CLIENT_ID, QA_BASE_URL } from '../../../common/constants';
import CustomMessage from '../../../common/components/CustomMessage';
import LockMessage from '../../../common/components/LockMessage';
import logo from '../../../assets/images/pearson_logo.png';
import logo2 from '../../../assets/images/pearson_logo@2x.png';
import logo3 from '../../../assets/images/pearson_logo@3x.png';
import arrowLeftActive from '../../../assets/images/young_learners/arrow-left-active.png';
import correctCheckmarkIcon from '../../../assets/images/young_learners/correct-checkmark.png';
import wrongCrossIcon from '../../../assets/images/young_learners/wrong-xcross.png';
import CommonUtils from '../../../common/utils/CommonUtils';

const PasswordSelectionDoneButton = withStyles({
  root: {
    width: '100%',
    height: '72px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 24,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#222e5e',
    borderColor: '#222e5e',
    color: '#fff',
    borderRadius: '13px',
    fontFamily: [
      'HeinemannSpecial-Bold'
    ].join(','),
    '&:hover': {
      backgroundColor: '#222e5e',
      borderColor: '#222e5e',
      color: '#fff',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#222e5e',
      borderColor: '#222e5e',
      color: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#d1d1d1',
      borderColor: '#d1d1d1',
      color: '#fff'
    }
  }
})(Button);


class SelectPassword extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    passwordList: null,
    showErrorMessage: {
      show: false,
      message: '',
      color: '#fc5b43'
    },
    showLockMessage: {
      show: false
    },
    passwordListFetchStatus: null,
    passwordValidation: null,
    passwordSelected: null
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showErrorMessage: {
        show: false
      }
    });
  };

  handleLockClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showLockMessage: {
        show: false
      }
    });
    // this.props.history.push("/login/studentcode/");
    Framework.redirectTo('/student/studentcode');
  };

  callbackFetchIesPassword = (setIesPasswordList) => {
    const iesPasswordList = [];
    if (typeof setIesPasswordList.data.images !== 'undefined' && setIesPasswordList.data.images.length > 0) {
      setIesPasswordList.data.images.map((image) => {
        if (typeof image.imageUrl !== 'undefined' && image.imageUrl !== '' && typeof image.password !== 'undefined' && image.password !== '') {
          iesPasswordList.push({
            logoURL: image.imageUrl, password: image.password
          });
        }
      });
      this.setState({ passwordList: iesPasswordList, passwordListFetchStatus: { status: 'complete' } });
    } else {
      // TODO Password List is empty
    }
  }


  componentDidMount() {
    Framework.getEventManager().on(constants.IES_PASSWORD_LIST_FETCH_SUCCESS, (setIesPasswordData) => {
      this.callbackFetchIesPassword(setIesPasswordData);
    });

    // If we have a valid coursesectionId let us hit the roster API
    // if (typeof localStorage.getItem('setIesUserSelectedFromRosterData').passMethod !== "undefined" && localStorage.getItem('setIesUserSelectedFromRosterData').passMethod === "image") {
    this.setState({ passwordListFetchStatus: { status: 'started' } });
    // AppActions.iesPasswordFetch();
    Framework.getEventManager().publish(constants.IES_PASSWORD_LIST_FETCH_REQUESTED, {});
    // }

    // Page view for Select Password
    const gtmPageData = CommonUtils.getDefaultGAPageView();
    gtmPageData.dataLayer.page_title = 'Select Password';
    TagManager.dataLayer(gtmPageData);
  }

  passwordSelectionOnClickEnter = (passwordSelected) => {
    if (this.state.passwordSelected !== null && this.state.passwordValidation.status === 'success' && this.state.passwordSelected === passwordSelected) {
      this.onClickPasswordSelectionDoneButton();
    } else {
      this.passwordSelectionOnClick(passwordSelected);
    }
  }

  passwordSelectionOnClick = (passwordSelected) => {
    if (this.state.passwordValidation !== null && this.state.passwordValidation.status === 'success') {
      const piSession = window.piSession;
      if (piSession) {
        piSession.setOptions({
          requireLogin: false
        });
        const redirectURL = `${window.location.protocol}//${window.location.host}`;
        piSession.logout(redirectURL);
      }
    }
    const loginUrl = QA_BASE_URL.replace('/piui', '');
    if (passwordSelected !== '') {
      this.setState({ passwordSelected, passwordValidation: null });
      // Lets check with IES if the password is correct or not
      if (localStorage.getItem('setIesUserSelectedFromRosterData') !== null && localStorage.getItem('setIesUserSelectedFromRosterData').userName !== '') {
        const setIesUserSelectedFromRosterData = JSON.parse(localStorage.getItem('setIesUserSelectedFromRosterData'));
        // window.extLogin(setIesUserSelectedFromRosterData.userName, passwordSelected, QA_CLIENT_ID, loginUrl, this.callbackLoginExtFunction);
        this.extLogin(setIesUserSelectedFromRosterData.userName, passwordSelected, STUDENT_CLIENT_ID, loginUrl, this.callbackLoginExtFunction);
      }
    }
  }

  callbackLoginExtFunction = (status) => {
    const { language, intl } = this.props;
    // TODO Show Checkmark or Crosssymbol based on status
    if (typeof status !== 'undefined') {
      this.setState({ passwordValidation: { status: status.status, resp: status.data } });
      if (status.status === 'lock') {
        this.setState({
          showLockMessage: {
            show: true
          }
        });
      } else if (status.status === 'error') {
        this.setState({
          showErrorMessage: {
            show: true,
            message: ReactHtmlParser(intl.formatMessage(language.getText('error_msg_1'))),
            color: '#fc5b43'
          }
        });
      } else if (status.status === 'inactive') {
        this.setState({
          showLockMessage: {
            show: true
          }
        });
      }
    }
  }

  renderSelectionStatus = (password) => {
    if (this.state.passwordValidation !== null && this.state.passwordSelected !== null && this.state.passwordSelected === password) {
      if (this.state.passwordValidation.status === 'success') {
        return (
          <img src={correctCheckmarkIcon} alt="password-selected-icon" className="password-selected" />
        );
      }
      if (this.state.passwordValidation.status === 'error' || this.state.passwordValidation.status === 'lock' || this.state.passwordValidation.status === 'inactive') {
        return (
          <img src={wrongCrossIcon} alt="password-selected-icon" className="password-selected" />
        );
      }
    }
  }

  renderPasswordGrid = (passwordListFromAPI) => {
    if (this.state.passwordListFetchStatus !== null && this.state.passwordListFetchStatus.status === 'complete') {
      const passwordListArray = chunk(passwordListFromAPI, 4);
      return passwordListArray.map(passwordList => (
        <div className="row">
          {passwordList.map(({ password, logoURL }) => (
            <div className="col password-container">
              <div className="row justify-content-center align-items-center">
                <div className="col password-list">
                  <div className="overlay">
                    {this.renderSelectionStatus(password)}
                  </div>
                  <div tabIndex="0" className="password-btn-outer" onKeyDown={(e) => { if (e.key == 'Enter') { this.passwordSelectionOnClickEnter(password); } }}>
                    <img
                      src={logoURL}
                      className="password-option-icon show-cursor"
                      onClick={() => this.passwordSelectionOnClick(password)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ));
    }
  }

  handleBackArrowOnClick = () => {
    if (this.state.passwordValidation !== null && this.state.passwordValidation.status === 'success') {
      // window.logout();
      const piSession = window.piSession;
      if (piSession) {
        piSession.setOptions({
          requireLogin: false
        });
        const redirectURL = `${window.location.protocol}//${window.location.host}`;
        piSession.logout(redirectURL);
      }
    }
    // this.props.history.push("/login/selectuser/");
    Framework.redirectTo('/student/selectuser/');
  }

  extLogin = (username, password, cid, endpoint, callback) => {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      try {
        if (typeof this.response !== 'undefined' && this.response !== '') {
          var response = JSON.parse(this.response.replace(/\\/g, ''));
        }
      } catch (e) {
        console.log('JSON Parsing Error');
        console.log(e);
      }
      if (this.readyState === 4) {
        if (this.status === 200 && response.status === 'success') {
          console.log('Logged in');
          callback({
            status: 'success',
            message: '',
            data: response
          });
        } else if (this.status === 401 && response.status === 'error') {
          if (response.fault.detail.errorcode === 'piui.accountIsLocked') {
            console.log('Account locked');
            callback({
              status: 'lock',
              message: 'Account locked, Please Try after 15 Min',
              data: response
            });
          } else if (response.fault.detail.errorcode === 'piui.authFailure') {
            console.log('Invalid username/password');
            callback({
              status: 'error',
              message: 'Invalid username/password',
              data: response
            });
          } else if (response.fault.detail.errorcode === 'piui.accountIsNotActive') {
            console.log('Account not active');
            callback({
              status: 'inactive',
              message: 'Account not active, Please Ask your teacher for help.',
              data: response
            });
          }
        } else {
          console.log('Unknown error');
          callback({ status: 'error', message: 'Try again!, Please Ask your teacher for help.', data: response });
        }
      }
    };
    // Calling IES /login/webcredentials API with username and password to set user's session cookie
    xhttp.open('POST', `${endpoint}/login/webcredentials`, true);
    xhttp.setRequestHeader(
      'Content-type',
      'application/x-www-form-urlencoded'
    );
    xhttp.withCredentials = true;
    xhttp.send(
      `username=${username}&password=${password}&client_id=${cid}`
    );
  }

  onClickPasswordSelectionDoneButton = () => {
    console.log('ENV=', env.ENVIRONMENT);
    // const { language, intl } = this.props;
    if (this.state.passwordValidation !== null && this.state.passwordValidation.status === 'success') {
      localStorage.setItem('initializeGA', true);
      localStorage.removeItem('class_code');
      window.location.assign('/app');
    }
  }

  showDoneButton = () => {
    const { language, intl } = this.props;
    if (this.state.passwordValidation !== null && this.state.passwordValidation.status === 'success') {
      return (
        <PasswordSelectionDoneButton onClick={this.onClickPasswordSelectionDoneButton} onKeyDown={(e) => { if (e.key == 'Enter') { this.onClickPasswordSelectionDoneButton(); } }}>{intl.formatMessage(language.getText('done_msg'))}</PasswordSelectionDoneButton>
      );
    }

    return (
      <PasswordSelectionDoneButton disabled>{intl.formatMessage(language.getText('done_msg'))}</PasswordSelectionDoneButton>
    );
  }

  onClickForgotPassword = () => {
    const { language, intl } = this.props;
    this.setState({
      showErrorMessage: {
        show: true,
        message: ReactHtmlParser(intl.formatMessage(language.getText('ask_teacher'))),
        color: '#48a744'
      }
    });
  }

  render() {
    const { language, intl } = this.props;
    // const arrowLeftActive = "/assets/images/young_learners/arrow-left-active.png";
    const domainSpecificClass = localStorage.getItem('isDomainSpark') !== 'true' ? 'select-password-left-arrow-active select-password-left-arrow-active-global' : 'select-password-left-arrow-active';
    return (
      <div>
        <div>
          <nav className="navbar navbar-expand-sm rectangle-1">
            <img
              onClick={this.onClickLogo}
              src={logo}
              alt="Pearson Logo"
              srcSet={`${logo2} 2x,${logo3} 3x`}
              className="pearson-logo"
            />
          </nav>
        </div>
        {(this.state.showLockMessage.show === true) ? <div id="cover" /> : <div />}
        <div
          className={`container-fluid selectpassword-container ${(this.state.showLockMessage.show === true) ? 'blur-backdrop' : ''}`}
        >
          <div className="welcome-container">
            <WelcomeMessage />
            <div className="col-md-12  col-lg-2 col-xl-2 back-arrow-position-password-select">
              <div className="row">
                <div className="col student-id-icon">
                  <div tabIndex="0" className="back-arrow-btn-outer" onKeyDown={(e) => { if (e.key == 'Enter') { this.handleBackArrowOnClick(); } }}>
                    <img
                      src={arrowLeftActive}
                      alt="arrow Left Active"
                      className={domainSpecificClass}
                      onClick={() => { this.handleBackArrowOnClick(); }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-password">

            <div className="password-column">
              {this.renderSelectedUser()}
            </div>
            <div className="password-column">
              <div className="row">
                <div className="col-sm">
                  <p className="text-style-1">
                    {intl.formatMessage(language.getText('what_is_your_password'))}
                  </p>
                </div>
              </div>
              {this.renderPasswordGrid(this.state.passwordList)}
              <div className="row">
                <div className="col">{this.showDoneButton()}</div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="forgot-password show-cursor" onClick={this.onClickForgotPassword}>{intl.formatMessage(language.getText('forgot_password'))}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(this.state.showErrorMessage.show === true)
          ? (
            <CustomMessage
              color={this.state.showErrorMessage.color}
              message={this.state.showErrorMessage.message}
              show={this.state.showErrorMessage.show}
              handleClose={this.handleClose}
            />
          ) : ''}
        {(this.state.showLockMessage.show === true)
          ? <LockMessage showLockMessage={this.state.showLockMessage} handleLockClose={this.handleLockClose} props={this.props} /> : ''}
      </div>
    );
  }

  renderSelectedUser = () => {
    const { language, intl } = this.props;
    if (localStorage.getItem('setIesUserSelectedFromRosterData') && typeof localStorage.getItem('setIesUserSelectedFromRosterData') !== 'undefined' && localStorage.getItem('setIesUserSelectedFromRosterData') !== null) {
      const setIesUserSelectedFromRosterData = JSON.parse(localStorage.getItem('setIesUserSelectedFromRosterData'));
      return (
        <div>
          <div className="row" style={{ marginLeft: '-15px !important', marginRight: '-15px !important' }}>
            <div className="col">
              <p className="you-are-text">
                {intl.formatMessage(language.getText('you_are'))}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col justify-content-center align-items-center">

              <img
                src={setIesUserSelectedFromRosterData.logoURL}
                alt={setIesUserSelectedFromRosterData.studentName}
                className="m-auto d-block selected-user-icon"
              />

            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="user-name">{setIesUserSelectedFromRosterData.studentName}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}


// export default SelectPassword;
SelectPassword.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(SelectPassword));
