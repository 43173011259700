class PrepareSearchObjectUtil {
  static prepareSearchObject(searchText, filterQueue) {
    let searchObjectArray;
    if (searchText !== null) {
      searchObjectArray = [
        this.prepareRefineSearchObject({
          title: searchText
        }, filterQueue)];
    } else {
      const reSearch = this.prepareRefineSearchObject(null, filterQueue);
      if (reSearch !== null) {
        searchObjectArray = [reSearch];
      } else {
        searchObjectArray = null;
      }
    }
    return searchObjectArray;
  }

  static prepareRefineSearchObject = function (defaultObj, filterQueue) {
    let searchRefineObjectArray = null; let added = 0; let
      resultObj = {};
    if (defaultObj && typeof defaultObj.metadata === 'object') {
      searchRefineObjectArray = defaultObj.metadata;
    }
    if (typeof filterQueue !== 'undefined') {
      searchRefineObjectArray = {};
      for (const filterName in filterQueue) {
        if (filterQueue.hasOwnProperty(filterName)) {
          let valList = []; const
            valArray = filterQueue[filterName];
          if (valArray instanceof Array && valArray.length > 0) {
            for (let i = 0, iLen = valArray.length; i < iLen; i++) {
              if (valArray[i].value) {
                if (valArray[i].subValue && valArray[i].subValue instanceof Array && valArray[i].subValue.length > 0) {
                  for (let vi = 0, viLen = valArray[i].subValue.length; vi < viLen; vi++) {
                    if (valArray[i].subValue[vi].other && valArray[i].subValue[vi].other.aliasValue) {
                      valList = valList.concat(valArray[i].subValue[vi].other.aliasValue);
                    } else if (valArray[i].subValue[vi].value) {
                      valList = valList.concat(valArray[i].subValue[vi].value);
                    }
                  }
                } else if (valArray[i].other && valArray[i].other.aliasValue) {
                  valList = valList.concat(valArray[i].other.aliasValue);
                } else if (valArray[i].value) {
                  valList = valList.concat(valArray[i].value);
                }
              }
            }
            searchRefineObjectArray[filterName] = valList;
            added++;
          }
        }
      }
    }

    if (typeof defaultObj === 'object') {
      resultObj = defaultObj;
    }
    if (searchRefineObjectArray !== null) {
      if (resultObj === null) {
        resultObj = {};
      }
      resultObj.metadata = searchRefineObjectArray;
    }

    if (!defaultObj && !added) {
      resultObj = null;
    }
    if (added) {
      return resultObj;
    }
    return resultObj;
  }
}
export default PrepareSearchObjectUtil;
