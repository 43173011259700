import DataFormatter from './DataFormatter';

const defaultBrand = 'digital-library';
let currentBrand;

export default function getCurrentBrand() {
  let urlString;
  const validBrands = ['digital-library', 'spark'];
  if (window.location.href.split('#?brand=').length > 1) {
    urlString = window.location.href.split('#?brand=')[1];
  } else {
    urlString = window.location.hostname;
  }
  if (!urlString || urlString === '' || urlString === 'localhost') {
    currentBrand = defaultBrand;
  } else {
    for (let _index = 0; _index < validBrands.length; _index++) {
      if (urlString.indexOf(validBrands[_index]) != -1) {
        currentBrand = validBrands[_index];
        break;
      }
    }
  }
  DataFormatter.setObjectInStorage('brand_name', currentBrand);
  return currentBrand;
}
