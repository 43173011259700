/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../constants';

const DlUserSchoolProduct = types.model('DlUserSchoolProduct', {
  productId: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string)
});

const DlUserSchoolProducts = types.model('DlUserSchoolProducts', {
  items: types.maybeNull(types.array(DlUserSchoolProduct))
});

const DlUserSchoolTenantItem = types.model('DlUserSchoolTenantItem', {
  tag: types.maybeNull(types.string),
  hawkId: types.maybeNull(types.string),
  auth: types.maybeNull(types.string)
});

const DlUserSchoolDirectoryItem = types.model('DlUserSchoolDirectoryItem', {
  tenants: types.maybeNull(types.array(DlUserSchoolTenantItem)),
  url: types.maybeNull(types.string)
});

const DlUserSchoolLicenseItem = types.model('DlUserSchoolLicenseItem', {
  numberOfSeats: types.maybeNull(types.number),
  subscriptionLength: types.maybeNull(types.number)
});
const DlUserSchoolLinks = types.model('DlUserSchoolLinks', {
  heroImage: types.maybeNull(types.string),
  terms: types.maybeNull(types.string),
  privacy: types.maybeNull(types.string),
  cookie: types.maybeNull(types.string),
  appConfig: types.maybeNull(types.string),
  appConfigAPI: types.maybeNull(types.string),
  services: types.maybeNull(types.string),
  website: types.maybeNull(types.string),
  textbooksThumbnail: types.maybeNull(types.string),
  languagePackAPI: types.maybeNull(types.string)
});
const DlUserSchoolServiceDirectory = types.model('DlUserSchoolServiceDirectory', {
  coursesectionApi: types.maybeNull(DlUserSchoolDirectoryItem),
  aapi: types.maybeNull(DlUserSchoolDirectoryItem),
  bookshelfApiV0: types.maybeNull(DlUserSchoolDirectoryItem),
  bookshelfApiV2: types.maybeNull(DlUserSchoolDirectoryItem),
  annotationsApi: types.maybeNull(DlUserSchoolDirectoryItem),
  parentServiceApi: types.maybeNull(DlUserSchoolDirectoryItem),
  notificationsApi: types.maybeNull(DlUserSchoolDirectoryItem),
  preferencesApi: types.maybeNull(DlUserSchoolDirectoryItem),
  parkinglot: types.maybeNull(DlUserSchoolDirectoryItem),
  caas: types.maybeNull(DlUserSchoolDirectoryItem),
  discussionsApi: types.maybeNull(DlUserSchoolDirectoryItem)
});
const DlUserSchoolMetadata = types.model('DlUserSchoolMetadata', {
  licenses: types.maybeNull(types.array(DlUserSchoolLicenseItem)),
  SACode: types.maybeNull(types.string),
  graceBanner: types.maybeNull(types.string),
  gracePeriod: types.maybeNull(types.string),
  provision_in_GLS: types.maybeNull(types.boolean),
  send_email_notification: types.maybeNull(types.boolean),
  trialBanner: types.maybeNull(types.string),
  trialPeriod: types.maybeNull(types.string),
  graceBannerLink: types.maybeNull(types.string),
  trialBannerLink: types.maybeNull(types.string),
  isTrial: types.maybeNull(types.boolean)
});

const DlUserSchool = types.model('DlUserSchool', {
  id: types.string,
  name: types.maybeNull(types.string),
  saId: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  geoId: types.maybeNull(types.string),
  geoName: types.maybeNull(types.string),
  saName: types.maybeNull(types.string),
  roleValue: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  isSelfEnrolled: types.maybeNull(types.boolean),
  products: types.array(DlUserSchoolProducts),
  links: types.maybeNull(DlUserSchoolLinks),
  serviceDirectory: types.maybeNull(DlUserSchoolServiceDirectory),
  metadata: types.maybeNull(DlUserSchoolMetadata)
})
  .actions(self => ({
    fetch(userId) {
      Framework.getEventManager().publish(constants.DL_USER_SCHOOL_REQUESTED, {
        userId
      });
    },
    set(state) {
      applySnapshot(self, state);
    }
  }));

export default DlUserSchool;
