/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../constants';

const DlUser = types
  .model('DlUser', {
    id: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    accountStatus: types.maybeNull(types.string),
    lifecycleStatus: types.maybeNull(types.string),
    homeCountryCode: types.maybeNull(types.string),
    givenName: types.maybeNull(types.string),
    familyName: types.maybeNull(types.string),
    emailAddress: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
    resetPassword: types.maybeNull(types.boolean),
    lastLoginSuccess: types.maybeNull(types.string)
  })
  .actions(self => ({
    fetch(userId) {
      Framework.getEventManager().publish(constants.DL_USER_PROFILE_REQUESTED, {
        userId
      });
    },
    set(state) {
      applySnapshot(self, state);
    }
  }));

export default DlUser;
