import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  VictoryChart,
  VictoryBar,
  VictoryStack,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
  VictoryPortal
} from 'victory';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import AssessedStrategiesImg from '../../../assets/images/assessed_strategies.svg';
import UpArrowBlue from '../../../assets/images/up-arrow-blue.png';
import DownArrowBlue from '../../../assets/images/down-arrow-blue.png';

const minXAxisLimit = 24;

class StudentActivityAttemptsChart extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    xAxisLimit: 0,
    isChartActive: true,
    formattedChartData: []
  };

  getXAxisLimit = () => {
    if (this.props.data) {
      const xAxisLength = Object.keys(this.props.data).length < minXAxisLimit
        ? minXAxisLimit
        : Object.keys(this.props.data).length;
      return xAxisLength % 2 === 0 ? xAxisLength : xAxisLength + 1;
    }
  };

  componentDidMount() {
    const formattedData = [];
    if (this.props.data && Object.keys(this.props.data).length > 0) {
      Object.keys(this.props.data).map((chartData, index) => {
        const subArr = Object.keys(this.props.data[chartData]).map(
          formattedData => ({
            x: this.props.data[chartData][formattedData].skill,
            y0: index,
            y: 1,
            color: this.props.data[chartData][formattedData].color
              ? this.props.data[chartData][formattedData].color
              : 'transparent',
            count: this.props.data[chartData][formattedData].count
              ? this.props.data[chartData][formattedData].count
              : ''
          })
        );
        formattedData.push(subArr);
      });
    }

    this.setState({
      xAxisLimit: this.getXAxisLimit(),
      formattedChartData: formattedData
    });

    setTimeout(() => {
      const parentId = 'VictoryContainer';
      const parent = document.querySelectorAll(`.${parentId}`)[0];
      if (parent) {
        const appendTo = document.getElementById('fixed-axis');
        NodeList.prototype.forEach = Array.prototype.forEach;
        const children = parent.childNodes;
        children.forEach((item) => {
          const cln = item.cloneNode(true);
          appendTo.appendChild(cln);
        });

        const fixedSVG = document.querySelectorAll('.fixed-axis > svg')[0];
        fixedSVG.setAttribute('viewBox', '0 0 200 350');
        fixedSVG.setAttribute('width', '200');

        const isIOSDevice = /iPad|iPhone|Macintosh|Mac OS X|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIOSDevice) {
          const container = document.querySelectorAll('.chart-container')[0];
          container.style.overflowX = (container.scrollWidth > container.clientWidth) ? 'scroll' : 'auto';
        }
      }
    }, 0);

    this.props.toggleChartLoader();
  }

  toggleChart = () => {
    this.setState({
      isChartActive: !this.state.isChartActive
    });
  };

  getXPointers = () => {
    const xPointers = [];
    for (let i = 1; i <= this.getXAxisLimit(); i++) {
      xPointers.push(i);
    }
    return xPointers;
  };

  renderLegend = () => {
    const { language, intl } = this.props;
    return (
      <div className="graph-legend">
        <div className="legend-box box-1">
          <span className="attempt attempt-1">1</span>
          <span>
            { intl.formatMessage(language.getText('first_att')) }
          </span>
        </div>
        <div className="legend-box box-2">
          <span className="attempt attempt-2">2</span>
          <span>
            { intl.formatMessage(language.getText('second_att')) }
          </span>
        </div>
        <div className="legend-box box-3">
          <span className="attempt attempt-3">3</span>
          <span>
            { intl.formatMessage(language.getText('third_att')) }
          </span>
        </div>
        <div className="legend-box box-4">
          <span className="attempt attempt-4" />
          <span style={{ position: 'relative', bottom: '3px' }}>
            { intl.formatMessage(language.getText('fourth_att')) }
          </span>
        </div>
      </div>
    );
  };

  renderChartFrame = () => {
    const { language, intl } = this.props;
    let eltConfig = false;
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(
      appConfig.substring(
        appConfig.indexOf('{'),
        appConfig.lastIndexOf('}') + 1
      )
    );
    eltConfig = appConfig.isElt === true;
    return (
      <div id="chart-title">
        <div>
          {eltConfig === true ? (
            <span className="chart-heading">
              { intl.formatMessage(language.getText('assessed_skills')) }
            </span>
          ) : (
            <span className="chart-heading">
              { intl.formatMessage(language.getText('assessed_strategies')) }
            </span>
          )}
          <span className="chart-icon">
            <img src={AssessedStrategiesImg} />
          </span>
          <span className="arrow-toggle-chart" onClick={this.toggleChart}>
            <img
              src={this.state.isChartActive ? UpArrowBlue : DownArrowBlue}
            />
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { language, intl } = this.props;
    if (!this.props.noActivityAttempted) {
      return (
        <div className="student-activity-chart">
          {this.renderChartFrame()}

          <div
            className={`chart-container ${
              this.state.isChartActive ? 'active' : ''
            }`}
          >
            <VictoryChart
              domainPadding={50}
              padding={{
                bottom: 75, right: 50, left: 200, top: 30
              }}
              horizontal
              theme={VictoryTheme.material}
              width={
                this.getXAxisLimit()
                * (this.getXAxisLimit() <= minXAxisLimit ? 38 : 30.5)
              }
              // height={this.props.yLabels.skills.length * 55}
              style={{
                parent: {
                  width:
                    this.getXAxisLimit() <= minXAxisLimit ? '800px' : '100%',
                  // height: this.props.yLabels.skills.length * 60,
                  height: 'auto'
                }
              }}
            >
              <VictoryAxis
                dependentAxis
                offsetY={75}
                key="key-x-axis"
                tickValues={this.getXPointers()}
                style={{
                  tickLabels: {
                    padding: 5,
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontFamily: 'Open sans',
                    color: '#2F2F2F',
                    fill: 'inherit'
                  }
                }}
              />
              <VictoryAxis
                standalone={false}
                offsetY={50}
                key="key-y-axis"
                tickValues={this.props.yLabels.skills.map((skill) => {
                  { intl.formatMessage(language.getText(`${skill.label}`)); }
                })}
                style={{
                  tickLabels: {
                    padding: 5,
                    fontSize: '13px',
                    fontWeight: 600,
                    fontFamily: 'Open sans',
                    color: '#393c3d',
                    fill: 'inherit'
                  },
                  grid: { stroke: 'none' }
                }}
              />
              <VictoryStack horizontal>
                {this.state.formattedChartData.map(chartData => (
                  <VictoryBar
                    data={chartData}
                    cornerRadius={4}
                    labels={d => d.datum.count}
                    labelComponent={(
                      <VictoryPortal>
                        <VictoryLabel
                          dx={-16}
                          dy={0}
                          className="victoryLabel"
                        />
                      </VictoryPortal>
)}
                    style={{
                      data: {
                        width: 15,
                        fill: d => d.datum.color,
                        stroke: '#fff',
                        fillOpacity: 0.8,
                        strokeWidth: 2,
                        strokeLinejoin: 'round'
                      },
                      labels: {
                        fill: 'black',
                        fontSize: 10
                      }
                    }}
                  />
                ))}
              </VictoryStack>
            </VictoryChart>
            <div className="fixed-axis" id="fixed-axis" />

            <div className="legend-container">{this.renderLegend()}</div>
            <div className="x-axis-label">
              { `${intl.formatMessage(language.getText('num_activities'))
              } ${intl.formatMessage(language.getText('completed'))}` }
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="student-activity-chart no-activity-section">
        {this.renderChartFrame()}
        <div
          id="chart-graph"
          className={`chart-container ${
            this.state.isChartActive ? 'active' : ''
          }`}
        >
          <div className="chartWrapper">
            { intl.formatMessage(language.getText('no_activity')) }
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  inject('language'),
  observer
)(injectIntl(StudentActivityAttemptsChart));
