import env from '../env';

const en = {
  continue: 'continue',
  username: 'Username',
  password: 'Enter password',
  back: 'BACK',
  sign_in: 'Sign in',
  invalid_user: 'You have entered an invalid username.',
  invalid_password: 'You have entered an invalid password.',
  username_empty: 'Please enter your username',
  password_empty: 'Please enter your password',
  brand: 'Pearson',
  skip_to_main_content: 'Skip to Main Content',
  school_library: 'Library',
  students: 'Students',
  my_bookbox: 'My Bookbox',
  sign_out_label: 'SIGN OUT',
  course_selection_label: 'Courses',
  keywordSearch: 'Search a book by title, author or keyword',
  recentSearches: 'Recent Searches',
  findBooks: 'Find Books',
  filterButtonLabel: 'FILTERS',
  cancelButtonLabel: 'CANCEL',
  resetFilterButtonLabel: 'RESET FILTERS',
  resetButtonLabel: 'RESET',
  doneButtonLabel: 'Find',
  refineByFilterHeader: 'Refine by',
  chooseFilterHeader: 'Choose',
  NoOfbooksSuffix: 'books',
  roleValue: 'student',
  select_all_button_label: 'SELECT ALL',
  cancel_button_label: 'CANCEL',
  send_books_button_label: 'SEND BOOKS',
  student: 'student',
  provision_success: 'Books have been sent.',
  provision_failed: 'Error - Provisioning of books failed',
  primary: 'primary',
  secondary: 'secondary',
  selfassigned: 'self',
  teacherassigned: 'teacher',
  interactiveActivity: 'Interactive Activities',
  selectStudents: 'Select Students',
  NoBookFound: 'Sorry, no books found',
  NoBookFoundSuffix1: 'Please try again.',
  terms: 'Terms of Use',
  cookies: 'Cookies',
  privacy: 'Privacy Policy',
  copyright: 'Pearson Education',
  copy_right: 'Copyright',
  copy_right_clicked: 'Copyright clicked',
  help: 'Help',
  notificationMessagePrefix: 'Your Teacher has sent you ',
  noNotificationMessagePrefix: 'No notifications received.',
  Total: 'Total',
  AverageStats: 'Average number of books read per student',
  Overall: 'OVERALL',
  This_Month: 'THIS MONTH',
  This_Year: 'THIS YEAR',
  Books_Read: 'BOOKS READ',
  BookBox: 'BOOKBOX',
  Book_Title: 'Book Title',
  Date_Sent: 'Date Sent',
  Date_text: 'Date',
  Complete: 'Complete',
  Pages: 'Pages',
  Read: 'Read',
  Audio: 'Audio',
  Used: 'Used',
  Favorite: 'Favourite',
  Self_text: 'Self',
  Allocated: 'Selected',
  In_bookbox: 'in Bookbox',
  Unread_books: 'Unread Books',
  Books_Read_dashboard: 'Books Read',
  Number_of: 'Number of',
  Student_name: 'Student Name',
  unread_in: 'UNREAD IN',
  remove_books: 'REMOVE BOOKS',
  notifications: 'Notifications',
  set_config: 'App config not set',
  book_selected: 'book selected',
  books_selected: 'books selected',
  books_removed_pural: 'books were removed from this student',
  book_removed_singular: '1 book was removed from this student',
  manage_bookbox: 'MANAGE BOOKBOX',
  view_all: 'VIEW ALL',
  view_selected: 'VIEW SELECTED',
  login: 'Login',
  login_clicked: 'Login clicked',
  logout_clicked: 'Logout clicked',
  terms_clicked: 'Terms clicked',
  cookie_clicked: 'Cookie clicked',
  policy_clicked: 'Privacy clicked',
  open_book: 'Open Book',
  book_clicked: 'Book clicked',
  flip_book_teacher: 'Flip book-Teacher',
  book_turnback: 'Book (turn backside)',
  send_book: 'Send Book',
  send_book_clicked: 'Sendbook clicked',
  teaching_notes: 'TeachingNotes',
  teaching_notes_clicked: 'Teachingnotes clicked',
  keyword_search: 'Keyword search',
  search_executed: 'Search executed',
  filter_option: 'Filter Option',
  filter_executed: 'Searchfilter executed',
  my_bookbox_tab: 'My Bookbox Tab',
  my_bookbox_tab_clicked: 'Mybookbox tab clicked',
  favourite: 'Favourite',
  favourite_clicked: 'Favourite clicked',
  flip_book_student: 'Flip book-Student',
  return_book: 'Return Book',
  return_book_clicked: 'Returnbook clicked',
  more_book: 'More Books',
  more_book_clicked: 'Morebooks clicked',
  add_book: 'Add Book',
  add_book_clicked: 'Addbook clicked',
  adding_student: 'Add Student',
  add_student_clicked: 'Addstudent clicked',
  deleting_student: 'Delete Student',
  delete_student_clicked: 'Deletestudent clicked',
  editing_student: 'Edit Student',
  edit_student_clicked: 'Editstudent clicked',
  editing_classname: 'Edit Classname',
  edit_classname_clicked: 'Editclassname clicked',
  adding_notes: 'Add Notes',
  add_notes_clicked: 'Addnotes clicked',
  notification: 'Notification',
  notification_clicked: 'Notification clicked',
  school_library_tab: 'Library Tab',
  school_library_tab_clicked: 'Library tab clicked',
  search_cancel: 'Search cancel',
  search_cancel_executed: 'SearchCancel executed',
  filter_cancel: 'Filter Cancel',
  filter_cancel_executed: 'FilterCancel executed',
  student_tab: 'Student Tab',
  student_tab_clicked: 'Student tab clicked',
  student_list: 'Student List',
  student_name_clicked: 'Student Name clicked',
  remove_book: 'Remove Book',
  remove_book_clicked: 'Remove book clicked',
  selection_reached_header: 'Selection limit reached',
  selection_reached_message: 'This result set contains greater than 100 books. We have selected the first 100 for you.',
  Guided: 'Guided',
  Reading_Level: 'Reading Level',
  books_i_choose: 'Books I Chose',
  book_suffix: 'book',
  from_my_teacher: 'From My Teacher',
  my_book_box: 'My Bookbox',
  SessionExpired: 'Your session has expired. Please login again to continue.',
  network_not_available: 'Network not available.',
  null_check_username_password: 'Please enter your username and password',
  check_network: 'Please check network and try again',
  NoSchoolsAssignedLoggedOut: 'No school assigned.',
  invalid_server_response: 'Invalid server response:',
  book_already_provisioned: 'Book is already in your Bookbox.',
  Off_label: 'Off',
  On_label: 'On',
  okButtonLabel: 'OK',
  take_a_look: 'TAKE A LOOK',
  select_all: 'Select All',
  Of: 'of',
  EmptyBookShelfHeader: 'No Content Available',
  EmptyBookShelfHint: 'Click here to refresh your Bookshelf',
  Logout: 'Logout',
  Save: 'Save',
  Cancel: 'Cancel',
  app_logo: 'R+',
  reader_plus: 'Reader+',
  search: 'Search',
  search_bookshelf: 'Search in bookshelf',
  unknown: 'Unknown',
  version: 'Version',
  close: 'Close',
  Delete: 'Delete',
  logout: 'Sign out',
  my_bookshelf: 'My Bookshelf',
  page: 'Page',
  resources_title: 'Resources',
  ok: 'Ok',
  empty_bookshelf_message: 'Click here to refresh your bookshelf.',
  bookmarks_toolbar_select_exit_btn: "I'm Done",
  bookmarks_toolbar_edit_bar_cancel: 'CANCEL',
  all_resource_filter_panel_search_hint_txt: 'Type a keyword or a phrase',
  download_manager_modal_action_button_label: 'Apply',
  ReadBook: 'Read Book',
  Bookshelf: 'My Bookshelf',
  add_to_mybookbox: 'Add this book to My Bookbox?',
  book_added: 'Book added!',
  book_returned: 'Book returned!',
  return_to_school_library: 'Return this book to Library?',
  items_selected: 'Items Selected',
  welcome: 'Welcome',
  filter_all: 'All',
  filter_returned: 'Returned',
  filter_new: 'New',
  filter_reading: "I'm reading",
  filter_finished: 'Finished',
  filter_liked: 'Liked',
  teaching_notes_baac: 'Teaching Notes',
  teachers_notes: "Teacher's Notes",
  resources_baac: 'Resources',
  lesson_plans_baac: 'Lesson plans',
  activity: 'Activity',
  physical_copy: 'Buy a Physical Copy',
  addto_my_bookbox: 'Add to My Bookbox',
  see_more: 'See more',
  show_less: 'Show less',
  book: 'book',
  filter_sections: 'Sections',
  filter_color_bands: 'Color Bands',
  'filter_lessthan-A1-GSE:15-22': '<A1 (GSE:15-22)',
  'filter_equals-A1-GSE:19-28': 'A1 (GSE:19-28)',
  'filter_A1-A2-GSE:25-36': 'A1-A2 (GSE:25-36)',
  'filter_A2-A2+GSE:33-41': 'A2-A2+ (GSE:33-41)',
  'filter_A2+-B1GSE:38-46': 'A2+-B1 (GSE:38-46)',
  'filter_greaterthanB1-GSE:Above 47': '>B1 (GSE: Above 47)',
  'filter_elt_GSE_16-27': 'GSE [16-27]/CEFR [<A1 - A1]',
  'filter_elt_GSE_22-32': 'GSE [22-32]/CEFR [A1 - A2]',
  'filter_elt_GSE_25-35': 'GSE [25-35]/CEFR [A1 - A2]',
  'filter_elt_GSE_27-38': 'GSE [27-38]/CEFR [A1 - A2+]',
  'filter_elt_GSE_30-40': 'GSE [30-40]/CEFR [A2 - A2+]',
  'filter_elt_GSE_36-48': 'GSE [36-48]/CEFR [A2+ - B1]',
  filter_pink: 'Pink',
  filter_red: 'Red',
  filter_orange: 'Orange',
  filter_yellow: 'Yellow',
  filter_green: 'Green',
  filter_blue: 'Blue',
  filter_aqua: 'Aqua',
  filter_purple: 'Purple',
  filter_brown: 'Brown',
  filter_white: 'White',
  filter_levels: 'Levels',
  filter_level1: 'Level 1',
  filter_level2: 'Level 2',
  filter_level3: 'Level 3',
  filter_level4: 'Level 4',
  filter_level5: 'Level 5',
  filter_level6: 'Level 6',
  'filter_(levels A–B): Initial': '(levels A–B): Initial',
  'filter_(levels C–D): Early Emergent': '(levels C–D): Early Emergent',
  'filter_(levels D–E): Emergent': '(levels D–E): Emergent',
  'filter_(levels F–H): Early': '(levels F–H): Early',
  'filter_(levels I–K): Developing': '(levels I–K): Developing',
  'filter_(levels L–N): Transitional': '(levels L–N): Transitional',
  'filter_(levels O–Q): Fluent': '(levels O–Q): Fluent',
  'filter_(levels R–U): Independent': '(levels R–U): Independent',
  'filter_(levels V–Z): Proficient': '(levels V–Z): Proficient',
  filter_guided_reading: 'Guided Reading',
  filter_a: 'A',
  filter_b: 'B',
  filter_c: 'C',
  filter_d: 'D',
  filter_e: 'E',
  filter_f: 'F',
  filter_g: 'G',
  filter_h: 'H',
  filter_i: 'I',
  filter_j: 'J',
  filter_k: 'K',
  filter_l: 'L',
  filter_m: 'M',
  filter_n: 'N',
  filter_O: 'O',
  filter_p: 'P',
  filter_q: 'Q',
  filter_r: 'R',
  filter_s: 'S',
  filter_t: 'T',
  filter_u: 'U',
  filter_v: 'V',
  filter_w: 'W',
  filter_x: 'X',
  filter_y: 'Y',
  filter_z: 'Z',
  filter_lexile: 'Lexile®',
  'filter_100-150': '100-150',
  'filter_150-200': '150-200',
  'filter_200-250': '200-250',
  'filter_250-300': '250-300',
  'filter_300-350': '300-350',
  'filter_350-400': '350-400',
  'filter_400-450': '400-450',
  'filter_450-500': '450-500',
  'filter_500-550': '500-550',
  'filter_550-600': '550-600',
  'filter_600+': '600+',
  filter_dra: 'DRA',
  filter_a12: 'A, 1, 2',
  filter_3: '3',
  filter_4: '4',
  'filter_6-8': '6-8',
  filter_10: '10',
  filter_12: '12',
  filter_14: '14',
  filter_16: '16',
  filter_18: '18',
  filter_20: '20',
  filter_24: '24',
  filter_28: '28',
  filter_30: '30',
  filter_34: '34',
  filter_38: '38',
  filter_40: '40',
  filter_50: '50',
  filter_60: '60',
  'filter_70-80': '70-80',
  'filter_topic of Interest/Inquiry': 'Topic of Interest/Inquiry',
  filter_topics: 'Topics',
  filter_adventure: 'Adventure',
  filter_animals: 'Animals',
  'filter_animals/Insects': 'Animals/Insects',
  'filter_buildings-and-places': 'Buildings and Places',
  'filter_celebrations-and-festivals': 'Celebrations and Festivals',
  filter_emotions: 'Emotions',
  'filter_families-and-friends': 'Families and Friends',
  filter_bullying: 'Bullying',
  filter_courage: 'Courage',
  filter_environment: 'Environment',
  filter_families: 'Families',
  filter_food: 'Food',
  filter_friendship: 'Friendship',
  filter_culture: 'Culture',
  filter_indigenous_peoples: 'Indigenous Peoples',
  filter_media: 'Media',
  filter_numbers: 'Numbers',
  filter_space: 'Space',
  filter_sports: 'Sports',
  filter_technology: 'Technology',
  filter_the_human_body: 'The Human Body',
  filter_transportation: 'Transportation',
  filter_communities: 'Communities',
  filter_plants: 'Plants',
  filter_subject_area: 'Subject Area',
  'filter_design-and-technology': 'Design and Technology',
  filter_science: 'Science',
  'filter_health_and_well-being': 'Health and Well-being',
  'filter_health-and-wellbeing': 'Health and Wellbeing',
  filter_values: 'Values',
  filter_health: 'Health',
  'filter_human-body': 'Human Body',
  filter_jobs: 'Jobs',
  filter_magic: 'Magic',
  filter_music: 'Music',
  filter_nature: 'Nature',
  'filter_literature-and-language': 'Literature and Language',
  'filter_performance-art': 'Performance art',
  filter_transport: 'Transport',
  filter_travel: 'Travel',
  'filter_the-past': 'The Past',
  filter_weather: 'Weather',
  filter_compassion: 'Compassion',
  filter_confidence: 'Confidence',
  filter_creativity: 'Creativity',
  filter_curiosity: 'Curiosity',
  filter_determination: 'Determination',
  filter_empathy: 'Empathy',
  filter_equality: 'Equality',
  filter_fairness: 'Fairness',
  filter_friendship: 'Friendship',
  filter_generosity: 'Generosity',
  filter_kindness: 'Kindness',
  'filter_hard-working': 'Hard working',
  'filter_helping-others': 'Helping others',
  filter_honesty: 'Honesty',
  filter_humility: 'Humility',
  filter_independance: 'Independance',
  filter_integrity: 'Integrity',
  filter_justice: 'Justice',
  filter_loyalty: 'Loyalty',
  'filter_open-mindedness': 'Open-mindedness',
  filter_patience: 'Patience',
  filter_perseverance: 'Perseverance',
  filter_positivity: 'Positivity',
  'filter_problem-solving': 'Problem solving',
  filter_resilience: 'Resilience',
  filter_respect: 'Respect',
  filter_responsibility: 'Responsibility',
  'filter_self-belief': 'Self-belief',
  'filter_self-control': 'Self-control',
  filter_sincerity: 'Sincerity',
  filter_teamwork: 'Teamwork',
  filter_tolerance: 'Tolerance',
  filter_books: 'Books',
  filter_mathematics: 'Mathematics',
  filter_social_studies: 'Social Studies',
  filter_the_arts: 'The Arts',
  filter_art: 'Art',
  'filter_art-and-design': 'Art and Design',
  'filter_genre/Text_type': 'Genre/Text Type',
  filter_genre: 'Genre',
  filter_classic: 'Classic',
  filter_disney: 'Disney',
  'filter_fairy-tale': 'Fairy Tale',
  'filter_non-fiction': 'Non-Fiction',
  filter_Fiction: 'Fiction',
  filter_biography: 'Biography',
  filter_description: 'Description',
  filter_explanation: 'Explanation',
  filter_procedure: 'Procedure',
  filter_report: 'Report',
  filter_retell: 'Retell',
  filter_graphic_text: 'Graphic Text',
  filter_traditional_story: 'Traditional Story',
  'filter_fantasy/sci-fi': 'Fantasy/Sci-Fi',
  filter_comprehension_strategies: 'Comprehension Strategies',
  filter_comprehension_fluency: 'Comprehension/Fluency',
  filter_activating_prior_knowledge: 'Activating Prior Knowledge',
  filter_analyzing_and_evaluating: 'Analyzing and Evaluating',
  filter_inferring: 'Inferring',
  filter_predicting: 'Predicting',
  filter_summarizing: 'Summarizing',
  'filter_listen-to-myself-reading': 'Listen to Myself Reading',
  'filter_reading-skills': 'Reading Skills',
  filter_vocabulary: 'Vocabulary',
  'filter_extracting-information': 'Extracting Information',
  'filter_understanding-the-story': 'Understanding the Story',
  filter_using_text_features: 'Using Text Features',
  filter_grammar: 'Grammar',
  'filter_language-of-books': 'Language of Books',
  'filter_english-only': 'English Only',
  'filter_french-only': 'French Only',
  filter_phonics: 'Decodable Books',
  filter_all_decodable_books: 'All Decodable Books',
  filter_set00_wordless_books: 'Set 00: Wordless Books',
  filter_set1_2_satpinmd: 'Set 1-2: s a t p i n m d',
  filter_set3_gock: 'Set 3: g o c k',
  filter_set4_ckeur: 'Set 4: ck e u r',
  filter_set5_hbffflllss: 'Set 5: h b f ff l ll ss',
  filter_set6_jvwx: 'Set 6: j v w x',
  filter_set7_yzzzqu: 'Set 7: y z zz qu',
  filter_set8_chshthng: 'Set 8: ch sh th ng',
  filter_set9_aieeighoaoo: 'Set 9: ai ee igh oa oo',
  filter_set10_arorurowoi: 'Set 10: ar or ur ow oi',
  filter_set11_earairureer: 'Set 11: ear air ure er',
  filter_set12_Consolidation: 'Set 12: Consolidation Sets 1-11',
  filter_set13_whph: 'Set 13: wh ph',
  filter_set14_ayaeeigh: 'Set 14: /ai/ ay a-e eigh ey ei',
  filter_set15_eaeeie: 'Set 15: /ee/ ea e-e ie ey y',
  filter_set16_ieieyI: 'Set 16: /igh/ ie i-e y i eigh',
  filter_set17_owoeo: 'Set 17: /oa/ ow o-e o oe',
  filter_set18_ewueue: 'Set 18: /oo/ ew ue u-e u; /(y)oo/ ew ue u-e; /oo/ u oul',
  filter_set19_awaual: 'Set 19: /ŏ/ aw au al',
  filter_set20_irerear: 'Set 20: /ur/ ir er ear',
  filter_set21_ouoy: 'Set 21: /ow/ ou; /oi/ oy',
  filter_set22_ereeerareear: 'Set 22: /ear/ ere eer; /air/ are ear',
  filter_set23_ckckch: 'Set 23: k ck ch',
  filter_set24_cecicyscstlse: 'Set 24: /s/ ce c sc st se',
  filter_set25_gegigydge: 'Set 25: /j/ g(e) g(i) g(y) dge',
  filter_set26_lembkngnwr: 'Set 26: /l/ le; /m/ mb; /r/ wr; /n/ kn gn',
  filter_set27_tchsheazh: 'Set 27: tch t(ion) ss(ion) c(ial) c(ian); /e/ ea; /o/ (w)a; /u/ o',
  definition_of_text_types: 'Definition of Text types',
  reading_level_correlation_chart: 'Reading Level Correlation Chart',
  moving_to_bookbox: 'To move this book to my book box',
  book_added_moving_to_bookbox: 'Book added , Moving to My Book Box',
  not_favourite: 'Not Favourite',
  navigate_to_homepage: 'Click to go to the Pearson homepage',
  student_dashboard: 'Student Analytics Dashboard',
  Reading_Level_Short: 'Reading Level',
  signing_declaration: 'By signing in, you accept our',
  terms_privacy_cookies: 'Terms, privacy and cookies',
  terms_privacy_copy_cookies: 'Terms, privacy, copyright and cookies',
  continue_declaration: 'By continuing on this site, you agree to our',
  accept_continue: 'ACCEPT AND CONTINUE',
  and_use_of: 'and use of',
  assessed_strategies: 'Assessed Strategies (Interactive Activities)',
  prior_knowledge: 'Activating Prior knowledge',
  predicting: 'Predicting',
  inferring: 'Inferring',
  summarizing: 'Summarizing',
  analyzing: 'Analyzing and Evaluating',
  text_features: 'Using text features',
  first_att: 'Correct 1st attempt',
  second_att: 'Correct 2nd attempt',
  third_att: 'Correct 3rd attempt',
  fourth_att: 'Incorrect 3rd attempt',
  num_activities: 'Number of activities',
  completed: 'completed',
  no_activity: 'This student has not completed any activities',

  welcome_text: 'Welcome to',
  select_your_role: 'Select your role:',
  teacher_text: 'Teacher',
  student_text: 'Student',
  what_is_your_code: 'What is your code?',
  your_code: 'YOUR CODE',
  your_code_will_be_provided: 'Your code will be provided by your teacher',
  error_msg_1: '<p>Try again<br/>or<br/>ask your teacher.</p>',
  who_are_you: 'Who are you?',
  your_account_locked: 'Your account is locked.',
  your_account_locked_1: 'Try again in 15 minutes.',
  close_msg: 'Close',
  you_are: 'You are:',
  what_is_your_password: 'What’s Your Password?',
  done_msg: 'Done',
  forgot_password: 'Forgot Password?',
  validation_error_msg: '<p>Code may contain numbers, letters<br/>  and 6 characters only. </p>',
  license_expiry_msg_ca: "<p>Welcome !<br/>Your trial has now expired. To reactivate, <a href={0} target='_blank'>click here</a> to purchase.</p>",
  license_expiry_msg_student_ca: "<p>Welcome !<br/>You don't have access to Spark Reading anymore. Please ask your teacher for more information.</p>",
  license_purchase_msg_ca: "Welcome !<br/>Your trial has {0} days remaining. To continue to enjoy Spark Reading Canada <a href={1} target='_blank'>click here</a> to purchase.",
  license_expiry_msg_tr: "<p>Welcome !<br/>Your trial has now expired. To reactivate, <a href={0} target='_blank'>click here</a> to purchase.</p>",
  license_expiry_msg_student_tr: "<p>Welcome !<br/>You don't have access to this site anymore. Please ask your teacher for more information.</p>",
  license_purchase_msg_tr: "Welcome !<br/>Your trial has {0} days remaining. To continue <a href={1} target='_blank'>click here</a> to purchase.",
  license_expiry_msg_gb: "<p>Welcome !<br/>Your trial has now expired. To reactivate, <a href={0} target='_blank'>click here</a> to purchase.</p>",
  license_expiry_msg_student_gb: "<p>Welcome !<br/>You don't have access to this site anymore. Please ask your teacher for more information.</p>",
  license_purchase_msg_gb: "Welcome !<br/>Your trial has {0} days remaining. To continue <a href={1} target='_blank'>click here</a> to purchase.",
  teacher_license_expiry_msg_last_day: 'Your account expires today - please contact your administrator or Spark Reading Help {0} for assistance.',
  teacher_license_expiry_msg_ca: 'Your account will expire in {0} day(s) - please contact your administrator or Spark Reading Help {1} for assistance.',
  teacher_license_expiry_msg_tr: 'Your account will expire in {0} day(s) - please contact your administrator or Spark Reading Help {1} for assistance.',
  teacher_license_expiry_msg_gb: 'Your account will expire in {0} day(s) - please contact your administrator or Spark Reading Help {1} for assistance.',
  teacher_license_expired_msg_ca: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  teacher_license_expired_msg_tr: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  teacher_license_expired_msg_gb: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  class_text: 'Class:',
  password_text: 'Password',
  show_password: 'Show Password',
  ask_teacher: '<p>Ask your teacher for help.</p>',
  ask_teacher_try_again: '<p>Something went wrong!, Please try again! <br/>Or Please Ask your teacher for help.</p>',
  download_template: 'Download Import Template',
  upload_template: 'Upload Import Template',
  no_students_msg1: 'There are no students in your class. ',
  no_students_msg2: 'Click ADD STUDENT to add one student or click Download Import Template. ',
  no_students_msg3: 'Then, fill the template and click Upload Import Template to import your students.',
  add_new_student: 'Add a new student',
  first_name: 'First name',
  last_name: 'Last name',
  student_id: 'Student ID',
  password_type: 'Password type',
  password_tooltip: 'Passwords may contain only numbers, letters and have a minimum of 5 characters.',
  select_password: 'Select type',
  password_text_option: 'Text',
  password_image_option: 'Image',
  image_selected: 'Image Selected',
  add_student: 'Add a student',
  add_student_button: 'Done/Print',
  max_limit_add: 'You’ve reached your maximum limit of ',
  remove_student: 'Remove',
  please_enter: 'Please enter ',
  please_select: 'Please select ',
  password_validation: 'Passwords may contain only numbers, letters and have a minimum of 5 characters.',
  password_text_validation: 'Password Text',
  password_image_validation: 'Password Image',
  student_creation_started: 'Student creation has started.',
  close_popup: 'You can close the pop up and navigate freely around the app.',
  class_list_ready: 'We will let you know as soon as your class list is ready.',
  student_creation_progress: 'Student creation is in progress.',
  do_not_close: 'Please do not close the window or press the back button',
  delete_student: 'Are you sure you want to permanently delete this student?',
  yes: 'YES',
  no: 'NO',
  upload_successful: 'Upload was successful. An email with your class list has been sent.',
  upload_partial_successful: 'Upload was partially successful.',
  upload_partial_successful_email: 'records were uploaded. An email with your class list has been sent.',
  upload_unsuccessful: 'The upload was unsuccessful.',
  view_students: 'Click to view students.',
  edit_students: 'Edit Student',
  edit_student_button: 'Save/Print',
  student_update_progress: 'Student update is in progress.',
  student_update_started: 'Student update has started.',
  let_you_know: 'We will let you know as soon as the student details are updated.',
  students_updated: 'Your students are being updated ...',
  password_not_found: 'Password not found!',
  upload_failed_contents: 'Student Upload Failed!<br> Please check file contents.',
  failed_to_import: 'Failed to import {0} new students as you will exceed your maximum limit of {1} students',
  csv_file_error: 'Unable to read csv file',
  csv_format_error: 'The file uploaded is not in a CSV format. <br/>Please ensure you’ve downloaded the <br/>template first and try importing again.',
  upload_failed: 'Student Upload Failed!<br> Please check after sometime.',
  add_student_successful_p1: 'IMPORTANT! The account(s) have been created for the student(s) you recently added.',
  add_student_successful_p1_org: 'IMPORTANT! The account(s) have been created for the student(s) you recently added. An email has been sent to you with the new student letter(s).',
  add_student_successful_p2: ' {0} to download the file with the student letter(s).',
  add_student_successful_p2_org: ' {0} to download the file with the student letter(s) in case the email has been blocked.',
  add_student_click_here: 'Click here',
  add_student_created_message: 'Your student account(s) are being created. This may take a few moments.',
  class_code: 'Class Code:',
  cookie_policy: 'Cookie Policy',
  edit_student_successful_p1: 'IMPORTANT! The accounts have been updated for your student(s).',
  edit_student_successful_p2: ' {0} to download the file with the student letter(s).',
  edit_student_update_message: 'Your student account(s) are being updated. This may take a few moments.',
  select_library: 'Select your Library',
  make_selection: 'Make a selection',
  create_account: 'Create account',
  select_library_error: 'There is some error while processing your request, please contact support team.',
  account_created: 'Account created',
  loading_data: 'Loading Data...',
  import_students_help: 'Add Multiple Students: Help',
  'welcome_!': 'Welcome!',
  logged_in_count: 'Number of Times<br>Logged In',
  last_login_time: 'Last Time<br/>Logged In',
  leave_as_is: 'Leave as is',
  clicked: ' clicked',
  student_letter: 'Student Letter',
  password_letter: 'Password Letter',
  student_letters: 'Student Letter(s)',
  password_letter_unavailable: 'Student letter is not available, please edit student information/reset student password to generate the letter.',
  options: 'Options',
  loading_activity_graph: 'Loading activity graph...',
  available_access: 'Available Access',
  reset_password: 'Reset password',
  decodable_set: 'Decodable',
  country_not_supported_msg: 'Sorry, we do not yet support self registration in the country that you selected.',
  student_license_expiry_title: 'Your classroom has expired or does not exist.',
  student_license_expiry_description: 'Please contact Spark Reading Help <sparkreadinghelp@pearson.com>',
  teacher_license_expiry_title:"Your account has expired.",
  teacher_license_expiry_contact:"Please contact your <a href={0} target='_blank'>sales representative </a>or your administrator to renew your license.",
  teacher_license_expiry_description:"For any other inquiries contact Spark Reading Help &lt;sparkreadinghelp@pearson.com&gt;",

  // Reports
  notes: 'Notes',
  report_error_message: 'Something went wrong on our end. Please try again later.',
  time_spent: 'Time spent',
  download_report: 'Download Report As',
  csv: 'CSV',
  excel: 'Excel',
  pdf: 'PDF',
  report_generation_progress: 'Report generation is in progress.',
  activities_completed: 'Activities Completed',
  // Reportbanner message
  report_error_message: 'Something went wrong on our end when generating your',
  report_error_message_m1: 'file.Please try again later',
  report_inProgress_message: 'Generation of your report as a',
  report_inProgress_message_m1: 'file is in progress.',
  report_complete_message: 'Generation of your report as a',
  report_complete_message_m1: 'has been completed successfully.',
  Keep_reading: 'Keep Reading',
  'are_you_still_reading?': 'Are You Still Reading?',
  log_out_label: 'Log out now'
};

export default en;
