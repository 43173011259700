/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class EditClassNameService extends BaseService {
  constructor() {
    super(constants.EDIT_CLASS_NAME_REQUESTED, 'EditClassNameServiceStatus');
  }

  handleEvent(eventName, event) {
    // const deviceId = CommonUtils.getdeviceId();
    const headerParams = {
      headers: {
        csname: event.className,
        csid: event.classSecId
      }
    };

    return this.getClient('hapi').put(
      '/edit-classname',
      {}, headerParams
    );
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data) {
      Framework.getEventManager().publish(constants.EDIT_CLASS_NAME_SUCCESS, response.data);
    } else {
      Framework.getEventManager().publish(constants.EDIT_CLASS_NAME_ERROR, {
        message: 'Unable to Edit the class name details'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.EDIT_CLASS_NAME_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
