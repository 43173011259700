import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { chunk } from 'lodash';
import TagManager from 'react-gtm-module';
import { CircularProgress } from '@material-ui/core';
import * as constants from '../../../common/constants';
import WelcomeMessage from '../../../common/components/WelcomeMessage';
import CustomMessage from '../../../common/components/CustomMessage';
import LockMessage from '../../../common/components/LockMessage';
import logo from '../../../assets/images/pearson_logo.png';
import logo2 from '../../../assets/images/pearson_logo@2x.png';
import logo3 from '../../../assets/images/pearson_logo@3x.png';
import arrowLeftActive from '../../../assets/images/young_learners/arrow-left-active.png';

import circleDisabled from '../../../assets/images/young_learners/circle-disabled.png';
import squareDisabled from '../../../assets/images/young_learners/square-disabled.png';
import triangleDisabled from '../../../assets/images/young_learners/triangle-disabled.png';
import diamondDisabled from '../../../assets/images/young_learners/diamond-disabled.png';
import starDisabled from '../../../assets/images/young_learners/star-disabled.png';
import CommonUtils from '../../../common/utils/CommonUtils';

class SelectUser extends Component {
  state = {
    studentList: null,
    teacherList: null,
    userListFetchStatus: null,
    showErrorMessage: {
      show: false,
      message: ''
    },
    showLockMessage: {
      show: false
    },
    selectedStudent: null,
    courseRosterFetchData: [],
    IESFlag: 0,
    showLoader: true
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showErrorMessage: {
        show: false
      }
    });
  };

  handleLockClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      showLockMessage: {
        show: false
      }
    });
  };

  componentDidMount() {
    // AppStore.on(AppConstants.EventTypes.FETCH_COURSE_ROSTER,this.callbackFetchCourseRoster);
    Framework.getEventManager().on(constants.FETCH_COURSE_ROSTER_SUCCESS, (courseRosterData) => {
      this.setState({
        courseRosterFetchData: courseRosterData
      });
      this.callbackFetchCourseRoster(courseRosterData);
    });
    // AppStore.on(AppConstants.EventTypes.FETCH_IES_USER,this.callbackFetchIesUser);
    Framework.getEventManager().on(constants.FETCH_IES_USER_SUCCESS, (userData) => {
      this.callbackFetchIesUser(userData);
    });
    // AppStore.on(AppConstants.EventTypes.ROSTER_USER_CLICKED,this.callbackRosterUserSelected);

    // If we have a valid coursesectionId let us hit the roster API
    if (localStorage.getItem('setCoursesectionFetchData') && JSON.parse(localStorage.getItem('setCoursesectionFetchData')).data[0].id !== '') {
      this.setState({ userListFetchStatus: { status: 'started' } });
      const coursesectionId = JSON.parse(localStorage.getItem('setCoursesectionFetchData')).data[0].id;
      // AppActions.courseRosterFetch(AppStore.setCoursesectionFetchData.data[0].id);-- Need to implement this
      Framework.getEventManager().publish(constants.FETCH_COURSE_ROSTER_REQUESTED,
        {
          classSectionId: coursesectionId
        });
    }

    // Page view for Select User
    const clientID = localStorage.getItem('usertype');
    if (clientID && clientID != undefined && clientID != null) {
      const gtmPageData = CommonUtils.getDefaultGAPageView();
      gtmPageData.dataLayer.page_title = 'Select Student';
      TagManager.dataLayer(gtmPageData);
    }
  }

  onClickOfStudentImage = (rosterUserObject) => {
    // // Call Event to capture Click in Store and Push route to password
    if (rosterUserObject.studentName !== '') {
      // AppActions.rosterUserSelect(rosterUserObject);-- Need to implement this
      this.callbackRosterUserSelected(rosterUserObject); // Since the above AppActions call is just giving back the input param as output directly calling the method like this
    }
  }


  callbackRosterUserSelected = (setIesUserSelectedFromRosterData) => {
    // localStorage.removeItem('setCoursesectionFetchData');
    localStorage.setItem('setIesUserSelectedFromRosterData', JSON.stringify(setIesUserSelectedFromRosterData));

    if (setIesUserSelectedFromRosterData.isLocked) {
      localStorage.setItem('userId', setIesUserSelectedFromRosterData.userId);
      Framework.redirectTo('/student/accesscode/');
    } else {
      // Once click is captured, let us set state to forward student to next screen
      switch (setIesUserSelectedFromRosterData.passMethod) {
        case 'image':
          // this.props.history.push("/login/selectpassword/");
          Framework.redirectTo('/student/selectpassword/');
          break;
        case 'text':
          // this.props.history.push("/login/password/");
          Framework.redirectTo('/student/password/');
          break;
        default:
          // TODO handle unknown password type
          break;
      }
    }
  }

    studentList = [];

    teacherList = [];

  callbackFetchCourseRoster = (setCourseRosterFetchData) => {
    // If we have a valid list of users, let us call the user details fetching API
    const userList = [];
    if (setCourseRosterFetchData.length > 0) {
      setCourseRosterFetchData.map((user) => {
        if (user.userId !== '') {
          userList.push(user.userId);
          if (user.roleValue === 'student') {
            this.studentList.push(user.userId);
          }
          if (user.roleValue === 'teacher') {
            this.teacherList.push(user.userId);
          }
        }
      });
      // If user data is present, let us call the API to get user details i.e. name and Images
      if (userList.length > 0 && this.state.IESFlag < 1) {
        Framework.getEventManager().publish(constants.FETCH_IES_USER_REQUESTED,
          {
            userIdArray: userList
          });
        this.setState({ IESFlag: 1 });
      } else {
        // TODO:No Users enrolled to course, need to handle this error
      }
    } else {
      // TODO:No Course information present or data is lost, need to handle this.
    }
  };

  truncate = input => (input.length > 20 ? `${input.substring(0, 20)}...` : input);

  callbackFetchIesUser = (setIesUserFetchData) => {
    // 0. Check if any response is there, if there then start building array
    if (
      typeof setIesUserFetchData !== 'undefined'
      && typeof setIesUserFetchData.data.matches !== 'undefined'
      && setIesUserFetchData.data.matches.length > 0
    ) {
      // 1. Check for username & userimage & givenname should be populated
      const userWithImages = [];
      const teacherUsers = [];
      setIesUserFetchData.data.matches.map((user) => {
        if (typeof user !== 'undefined') {
          if (typeof user.credentials !== 'undefined' && user.credentials.length > 0) {
            if (typeof user.identityProfile !== 'undefined' && typeof user.identity !== 'undefined') {
              if (typeof user.credentials[0].userName !== 'undefined' && user.credentials[0].userName !== '' && typeof user.identityProfile.givenName !== 'undefined' && user.identityProfile.givenName !== '') {
                // Check if the user is Student
                if (this.studentList.indexOf(user.identity.id) > -1 && typeof user.credentials[0].userNameImageUrl !== 'undefined' && user.credentials[0].userNameImageUrl !== '') {
                  // 2. Construct name as Given.Middle.familyName
                  const userIndex = this.state.courseRosterFetchData.findIndex(roasterObj => roasterObj.userId === user.identity.id);
                  userWithImages.push({
                    userId: user.identity.id,
                    userName: user.credentials[0].userName,
                    logoURL: user.credentials[0].userNameImageUrl,
                    studentName: this.truncate(`${user.identityProfile.givenName} ${user.identityProfile.familyName.substr(0, 1)}`),
                    givenName: user.identityProfile.givenName,
                    middleName: '',
                    familyName: user.identityProfile.familyName,
                    passMethod: (user.credentials[0].passType === 'IMG') ? 'image' : 'text',
                    isLocked: (userIndex >= 0) ? this.state.courseRosterFetchData[userIndex].isLocked : false
                  });
                }
                // Check if the user is Teacher
                // TODO need to find a way to segregate Teacher and Co-Teacher
                if (this.teacherList.indexOf(user.identity.id) > -1) {
                  // 2. Construct name as Given.Middle.familyName
                  teacherUsers.push({
                    userId: user.identity.id,
                    userName: user.credentials[0].userName,
                    teacherName: this.truncate(`${user.identityProfile.givenName} ${user.identityProfile.familyName.substr(0, 1)}`),
                    givenName: user.identityProfile.givenName,
                    middleName: '',
                    familyName: user.identityProfile.familyName
                  });
                }
              } else {
                // TODO: required values are missing
              }
            } else {
              // TODO: identity || identityProfile object is empty
            }
          } else {
            // TODO:credentials object is empty
          }
        } else {
          // TODO:Object is empty, need to handle this
        }
      });
      // 3. Construct array and render grid
      this.setState({
        studentList: userWithImages,
        teacherList: teacherUsers,
        userListFetchStatus: { status: 'complete' }
      });
    } else {
      // TODO:There is not student data available, handle this
    }
    this.setState({showLoader:false});
  };

  MAX_STUDENTS_IN_ROW = 5; // Static

  MAX_STUDENT_ROWS = 7;

  templateStudentRow = [
    {
      icon: circleDisabled
    },
    {
      icon: squareDisabled
    },
    {
      icon: triangleDisabled
    },
    {
      icon: diamondDisabled
    },
    {
      icon: starDisabled
    }
  ];

  renderStudentGrid = (studentListFromAPI) => {
    if (this.state.userListFetchStatus !== null && this.state.userListFetchStatus.status === 'complete') {
      const studentListArray = chunk(studentListFromAPI, this.MAX_STUDENTS_IN_ROW);
      const studentListMassaged = [];
      let row = [];
      const max_rows = (studentListArray.length > 7) ? studentListArray.length : this.MAX_STUDENT_ROWS;
      for (let i = 0; i < max_rows; i++) {
        row = [];
        for (let j = 0; j < this.MAX_STUDENTS_IN_ROW; j++) {
          try {
            if (studentListArray[i][j] !== undefined) {
              row.push(studentListArray[i][j]);
            } else {
              row.push({
                studentName: '',
                logoURL: this.templateStudentRow[j].icon
              });
            }
          } catch (e) {
            row.push({
              studentName: '',
              logoURL: this.templateStudentRow[j].icon
            });
          }
        }
        studentListMassaged.push(row);
      }
      // console.log(studentListMassaged);
      return (
        <div className="row">
          {studentListMassaged.map(studentList => studentList.map(student => (
            <div className="col student-id-container">
              <div className="row justify-content-center align-items-center">
                <div className="col">
                  <div tabIndex="0" className="user-border-outer" onKeyDown={(e) => { if (e.key == 'Enter') { this.onClickOfStudentImage(student); } }}>
                    <img
                      onClick={() => this.onClickOfStudentImage(student)}
                      src={student.logoURL}
                      className={student.studentName !== '' ? 'mx-auto d-block student-id-icon show-cursor' : 'mx-auto d-block student-id-icon'}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="student-name">
                    {student.studentName === '' ? (
                      <div className="student-dummy-name-no-show" />
                    ) : (
                      student.studentName
                    )}
                  </div>
                </div>
              </div>
            </div>
          )))}
        </div>
      );
    }
    // TODO Fetching Started or NotStarted, need to handle this.
  };

  handleBackArrowOnClick = () => {
    Framework.redirectTo('/student/studentcode');
    // this.props.history.push("/student/studentcode");
  }

  renderTeacherName = (teacherUsers) => {
    const { language, intl } = this.props;
    if (
      this.state.userListFetchStatus !== null
        && this.state.userListFetchStatus.status === 'complete'
        && teacherUsers.length > 0
    ) {
      return (
        <p className="text-style-1">
          {intl.formatMessage(language.getText('who_are_you'))}
          <span className="who-are-you-class">
          (
            {intl.formatMessage(language.getText('class_code'))}
            {' '}
            {localStorage.getItem('class_code').toUpperCase()}
)
          </span>
        </p>
      );
    }
  }

onClickLogo = () => {
  // window.logout();
  const piSession = window.piSession;
  if (piSession) {
    piSession.setOptions({
      requireLogin: false
    });
  }
  // Getting the userName from localStorage before clearing to set it back - fix for RPLUS-4705
  const userName = localStorage.getItem('userName');
  const isStudentAdd = localStorage.getItem('isStudentAdd');
  localStorage.clear();
  localStorage.setItem('userName', userName);
  (isStudentAdd === 'true') ? localStorage.setItem('isStudentAdd', isStudentAdd) : '';
  Framework.redirectTo('/');
  if (piSession) {
    const redirectURL = `${window.location.protocol}//${window.location.host}`;
    piSession.logout(redirectURL);
    // window.location = window.location.protocol+"//"+window.location.host;
  }
}

render() {
  const domainSpecificClass = localStorage.getItem('isDomainSpark') !== 'true' ? 'select-user-left-arrow-active select-user-left-arrow-active-global' : 'select-user-left-arrow-active';
  return (
    <div>
      <div>
        <nav className="navbar navbar-expand-sm rectangle-1">
          <img
            onClick={this.onClickLogo}
            src={logo}
            alt="Pearson Logo"
            srcSet={`${logo2} 2x,${logo3} 3x`}
            className="pearson-logo"
          />
        </nav>
      </div>
      <div className={`container-fluid selectuser-container  ${this.state.showLockMessage.show === true ? 'blur-backdrop' : ''}`}>
        <div className="welcome-container">
          <WelcomeMessage />
          {this.state.showLoader ? <div className="progressWrapper"><CircularProgress /></div>:''}
          <div className="col-md-2 back-arrow-position">
            <div className="row">
              <div className="col student-id-icon">
                <div tabIndex="0" className="back-arrow-btn-outer" onKeyDown={(e) => { if (e.key == 'Enter') { this.handleBackArrowOnClick(); } }}>
                  <img
                    onClick={() => this.handleBackArrowOnClick()
                        }
                    src={arrowLeftActive}
                    alt="arrow Left Active"
                    className={domainSpecificClass}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="justify-content-center user-list-container">
            <div className="row">
              <div className="col-sm">
                {this.renderTeacherName(this.state.teacherList)}
              </div>
            </div>
            {this.renderStudentGrid(this.state.studentList)}
          </div>
        </div>
      </div>
      {this.state.showErrorMessage.show === true ? (
        <CustomMessage message={this.state.showErrorMessage.message} show={this.state.showErrorMessage.show} handleClose={this.handleClose} />
      ) : (
        ''
      )}
      {this.state.showLockMessage.show === true ? (
        <LockMessage showLockMessage={this.state.showLockMessage} handleLockClose={this.handleLockClose} props={this.props} />
      ) : (
        ''
      )}
    </div>
  );
}
}

SelectUser.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(SelectUser));
