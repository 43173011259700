import * as constants from '../constants';
// import MultiLineEllipsis from './MultiLineEllipsis';
import LocalizationHandler from './LocalizationHandler';


class DataFormatter {
  static LanguageAvailable;
  // static setAWSConfig = function(region, cognitoID) {
  //   // AWS.config.region = region;
  //   // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //   //       IdentityPoolId: cognitoID   //Required e.g. 'us-east-1:12345678-c1ab-4122-913b-22d16971337b'
  //   //   });
  // }

  // static getAWSSession = function(AppId) {
  //   // if(navigator.onLine) {
  //   //   var options = {
  //   //         appId : AppId,    //Required e.g. 'c5d69c75a92646b8953126437d92c007'
  //   //         appVersionName: "WebReader 7"
  //   //     };
  //   //   window.mobileAnalyticsClient = new AMA.Manager(options);
  //   // }
  // }

  // static getCurrentPageLabel = function(currentPageNumber){
  //   var pageMapping = DataFormatter.getObjectInStorage('pageMapping');
  //   if(pageMapping){
  //     if(pageMapping[currentPageNumber])
  //       return pageMapping[currentPageNumber]
  //     else return currentPageNumber

  //   }else{
  //     return currentPageNumber
  //   }
  // }

  // static pdfTOCFormatting = function(tocArray) {
  //   // tocArray = JSON.parse(tocArray);
  //   var parentElements = tocArray.filter(function(tocItem){
  //     return tocItem.ParentNodeID == 0;
  //   });
  //   var tocDiv = document.createElement('div');
  //   tocDiv.setAttribute('class', "tocPdfContainer");
  //   // tocDiv.setAttribute('nodeId', 0);
  //   for(i=0; i<parentElements.length; i++) {
  //     var element = document.createElement('div');
  //     element.setAttribute('class', "parentNode tocNode nodeId_" + parentElements[i].NodeID);
  //     element.setAttribute('nodeId', parentElements[i].NodeID);
  //     element.textContent = parentElements[i].NodeTitle;
  //     var element2 = document.createElement('div');
  //     element2.setAttribute("class", "pdf_toc_links rootParent tocNode nodeId_" + parentElements[i].NodeID);
  //     element2.setAttribute('nodeId', parentElements[i].NodeID);
  //     element2.textContent = parentElements[i].NodeTitle;
  //     element.appendChild(element2);
  //     tocDiv.appendChild(element);
  //   }
  //   // document.querySelector('body').appendChild(tocDiv);
  //   for(var i=0; i<tocArray.length; i++){
  //     var parentElements = tocDiv.querySelectorAll('.pdf_toc_links');
  //     var element = document.createElement('div');
  //     for(var j=0; j<parentElements.length; j++){
  //       if(tocArray[i].ParentNodeID == parentElements[j].getAttribute('nodeId')){
  //         element.setAttribute('class', "pdf_toc_links tocNode nodeId_" + tocArray[i].NodeID);
  //         element.setAttribute('nodeId', tocArray[i].NodeID);
  //         element.setAttribute('parentNodeId', tocArray[i].ParentNodeID);
  //         element.textContent = tocArray[i].NodeTitle;
  //         parentElements[j].appendChild(element);
  //       }
  //     }
  //   }
  //   parentElements = tocDiv.querySelectorAll('.parentNode');
  //   for(var i=0; i<parentElements.length; i++){
  //     if(parentElements[i].childNodes[1].childNodes.length == 1){
  //       parentElements[i].removeChild(parentElements[i].childNodes[1]);
  //     }
  //   }
  //   return tocDiv;
  // }

  // /*
  // This method is fired when the user changes the Font Style.
  // */
  // static changeFontStyle = function (fontStyle, value) {
  //     var body;
  //     var iFrames = document.querySelectorAll(".bookFrame");
  //     for(var j=0; j<iFrames.length; j++){
  //        try{
  //         body= iFrames[j].contentWindow.document.getElementsByTagName("body")[0];
  //         body.style.fontFamily = fontStyle;
  //        // body.setAttribute('style',body.getAttribute('style')+'font-family: "'+fontStyle+'";');
  //       var elements= body.getElementsByTagName("h1");
  //       for (var i = elements.length - 1; i >= 0; i--) {
  //         elements[i].style.fontFamily = fontStyle;
  //       }
  //        var elements= body.getElementsByTagName("span");
  //       for (var i = elements.length - 1; i >= 0; i--) {
  //         elements[i].style.fontFamily = fontStyle;
  //       }
  //       var elements= body.getElementsByTagName("p");
  //       for (var i = elements.length - 1; i >= 0; i--) {
  //         elements[i].style.fontFamily = fontStyle;
  //       }
  //        DataFormatter.setObjectInStorage("fontfamily", value);
  //      }catch(e){}
  //     }
  //   }

  // /*  This function is fired when the user changes the Font contrast dropdown.*/
  //  static changeFontContrast = function(fontTheme) {
  //   var iFrames = document.querySelectorAll(".bookFrame");
  //     for(var j=0; j<iFrames.length; j++){
  //   try{
  //      var color= "" , backgroundColor = "";
  //     var body = iFrames[j].contentWindow.document.getElementsByTagName("body")[0];
  //     DataFormatter.setObjectInStorage("fonttheme", fontTheme);
  //     // var defaultColor = DataFormatter.getObjectInStorage("defaultColor");
  //     // var defaultBackgroundColor = DataFormatter.getObjectInStorage("defaultBackgroundColor");
  //     DataFormatter.setObjectInStorage("fonttheme", fontTheme);
  //     if(fontTheme == 3){
  //       color = "#673403";
  //       backgroundColor = "#fbeecf";
  //     }else { if(fontTheme == 2) {
  //       color = "#666666";
  //       backgroundColor = "#222222";
  //       }
  //     }
  //      var highlightElement = iFrames[j].contentWindow.document.getElementsByClassName("highlight");
  //       for(var i=0; i<highlightElement.length; i++) {
  //         var highlightColor = highlightElement[i].style.backgroundColor;
  //         highlightElement[i].setAttribute("tempHColor", highlightColor);
  //         // highlightElement[i].setAttribute("style", "background-color:rgba(250, 199, 14, 0.4) !important;");
  //      }
  //      body.setAttribute("style", "color:" + color + "!important;background-color: " + backgroundColor + " !important");
  //      var labels = $(body).find('.label,.number,h1');
  //      labels.css("background-color", backgroundColor);
  //      labels.css("color", color);
  //      $(body).find('a').children().css( "background-color", "" );
  //      $(body).find('p').children().css( "background-color", "" );
  //      $(body).find('li','td','sup').children().css( "background-color", "" );
  //      $(body).find('p').css( "background-color", "" );
  //      $(body).find('p',).css( "color", color );
  //      $(body).find('li',).children().css( "color", color );
  //      $(body).find('li',).css( "color", color );
  //      $(body).find('.line','.upbold','.bold').css( "background-color", "" );
  //      $(body).find('.bold').css( "color", color);
  //      $(body).find('.strong').css( "background-color", "" );
  //      $(body).find('.emphasis').css( "background-color", "" );
  //      var highlightElement = iFrames[j].contentWindow.document.getElementsByClassName("highlight");
  //      for(var i=0; i<highlightElement.length; i++) {
  //        highlightElement[i].setAttribute("style", "background-color:" + highlightElement[i].getAttribute("tempHColor")  + " !important");
  //        highlightElement[i].removeAttribute("tempHColor");
  //      }
  //      var size = DataFormatter.getObjectInStorage('fontsize');
  //      if(size){
  //       body.style.fontSize = size + "%";
  //      }
  //   }catch(e){}
  //  }
  // }

  //  static setFontSize = function(fontSize) {
  //   var iFrames = document.querySelectorAll(".bookFrame");
  //     if(!DataFormatter.getObjectInStorage('isFixedLayout')){
  //       for(var i=0; i<iFrames.length; i++){
  //         try{
  //           var body = iFrames[i].contentWindow.document.body;
  //           body.style.fontSize = fontSize + "%";
  //         }catch(e){}
  //       }
  //     }
  //   }

  // static createAWSSession = function() {
  //   // if(navigator.onLine) {
  //   //   try{
  //   //     if(!window.mobileAnalyticsClient){
  //   //         setTimeout(function(){
  //   //           DataFormatter.setAWSConfig(config.Aws_Region, config.Aws_PoolId);
  //   //           DataFormatter.getAWSSession(config.Aws_AppID);
  //   //         }, 2000)
  //   //       }else {
  //   //         if(new Date().getTime() >= mobileAnalyticsClient.outputs.session.expirationDate) {
  //   //           mobileAnalyticsClient.renewSession();
  //   //         }
  //   //       }
  //   //     }catch(e){
  //   //       return false;
  //   //     }
  //   // }

  // }

  // static sendAWSEvent = function(eventName, attributeObject) {
  //   // if(navigator.onLine) {
  //   //   try{
  //   //     DataFormatter.createAWSSession();
  //   //     window.mobileAnalyticsClient.recordEvent(eventName , attributeObject);
  //   //   }catch(e){}

  //   //   // window.mobileAnalyticsClient.submitEvents();
  //   // }

  // }

  // static applyStyleOfParent = function(parent, child) {
  //   var elements= parent.getElementsByTagName(child);
  //     for (var i = elements.length - 1; i >= 0; i--) {
  //       elements[i].style.backgroundColor = parent.style.backgroundColor;
  //       elements[i].style.color = parent.style.color;
  //       }
  // }
  // static applyStyleOfParentClass = function(selector, color, backgroundColor) {
  //   $(selector).css('background-color', backgroundColor);
  //   $(selector).css('color',color);
  // }

  //  static changeContrast = function(element ,color, backgroundColor) {
  //   element.setAttribute("style", "color:" + color + "!important;background-color: " + backgroundColor + " !important");
  //   backgroundColor = backgroundColor;
  //   window.fontSettings.apply();
  //   DataFormatter.applyStyleOfParent(element, "h1");
  //   window.fontSettings.apply();
  // }

  static setObjectInStorage = function (key, value) {
    if (window.localStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

   static setObjectInSessionStorage = function (key, value) {
     if (window.sessionStorage) {
       sessionStorage.setItem(key, JSON.stringify(value));
     }
   }

  static removeObjectInStorage = function (key) {
    if (window.localStorage) {
      localStorage.removeItem(key);
    }
  }

   //   /* Get Key By Value from the JSON Object. Note that this is case insensitive
   //   */
   //   static getKeyByValue = function(object, value) {
   //     // return Object.keys(object).find(key => String(object[key]).toLowerCase() === String(value).toLowerCase());
   //     var objKeys = Object.keys(object);
   //     for(var i=0; i< objKeys.length; i++){
   //       if(String(object[objKeys[i]]).toLowerCase() == String(value).toLowerCase()){
   //         return objKeys[i];
   //       }
   //     }
   //     return null;
   //   }

    static getLanguage = function () {
      return LocalizationHandler.getLanguage();
    }

// static getLanguage = function () {
//   if(!DataFormatter.LanguageAvailable){
//     DataFormatter.LanguageAvailable = DataFormatter.fetchLanguageDefaultFile();
//   }
//   return DataFormatter.LanguageAvailable
// }
static fetchLanguageDefaultFile = function () {
  let obj = navigator.language || navigator.userLanguage;
  if (navigator.languages && navigator.languages.length > 0) {
    if (obj != navigator.languages[0]) {
      obj = navigator.languages[0];
    }
  }
  if (obj) {
    return obj;
  }
  return 'en-US';
}

static getObjectInStorage = function (key) {
  try {
    if (window.localStorage) {
      const localStorageItem = localStorage.getItem(key);
      if (localStorageItem !== null) {
        return JSON.parse(localStorageItem);
      }
    }
    return null;
  } catch (e) {
    return null;
  }
}

  static getObjectInSessionStorage = function (key) {
    try {
      if (window.sessionStorage) {
        const sessionStorageItem = sessionStorage.getItem(key);
        if (sessionStorageItem !== null) {
          return JSON.parse(sessionStorageItem);
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  static getKeyFromObject = function (objectKey, propKey) {
    const object = this.getObjectInStorage(objectKey);
    let value = null;
    if (object !== null && object !== undefined) {
      value = object[propKey];
    }
    return value;
  }

  //   static getCookie = function(cname) {
  //       var name = cname + "=";
  //       var ca = document.cookie.split(';');
  //       for(var i=0; i<ca.length; i++) {
  //           var c = ca[i];
  //           while (c.charAt(0)==' ') c = c.substring(1);
  //           if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  //       }
  //       return null;
  //   }

  //   static sortDates = function(list, order) {
  //     if(order == "ascending"){
  //       list.sort(function(a,b){
  //       // Turn your strings into dates, and then subtract them
  //       // to get a value that is either negative, positive, or zero.
  //       return new Date(a.creationTime) - new Date(b.creationTime);
  //      });
  //     }else{
  //       list.sort(function(a,b){
  //       // Turn your strings into dates, and then subtract them
  //       // to get a value that is either negative, positive, or zero.
  //       return new Date(b.creationTime) - new Date(a.creationTime);
  //      });
  //     }
  //     return list;
  //   }
  //   static sortNumbers = function (list,sortOrder,sortKey) {
  //     list.sort(function(a,b){
  //       var num1=parseInt(a[sortKey]), num2=parseInt(b[sortKey])
  //       return num1-num2;
  //     })
  //     return list;
  //   }
    static sortList = function (list, sortOrder, sortKey) {
      // first sort without unicode
      list.sort((a, b) => {
        const firstItem = a[sortKey].replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase(); const
          secondItem = b[sortKey].replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase();
        if (firstItem < secondItem) // sort string ascending
        { return -1; }
        if (firstItem > secondItem) return 1;
        return 0; // default return value (no sorting)
      });
      // Account for unicode characters next
      list.sort((a, b) => a[sortKey].replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase().localeCompare(b[sortKey].replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase()));
      // Reverse the list for descending
      if (sortOrder === constants.SortType.DESC) {
        list = list.reverse();
      }
      return list;
    }
    //   static wrapMultipleLines(elementClass)
    //   {
    //     var elements = document.getElementsByClassName(elementClass);
    //     MultiLineEllipsis.ellipsis(elements);
    //   }
    //   static isFunction(func)
    //   {
    //     if (typeof func === "function") {
    //       return true;
    //     }
    //     return false;
    //   }
    //   static readNodeValue(element)
    //   {
    //     if(element)
    //     {
    //       var nodeName = element.nodeName.toLowerCase().trim();
    //       if(nodeName === 'input' || nodeName ==='textarea')
    //       {
    //         return element.value;
    //       }
    //       else if(nodeName === 'select')
    //       {
    //         return element.options[element.selectedIndex].value;
    //       }
    //       else if(nodeName === 'label') {
    //         return element.textContent;
    //       }
    //       return '';
    //     }
    //   }

    //   static navigateToElement(elementIdentifier,win, isFrame,identifierType) {
    //     var doc;
    //     if(isFrame) {
    //       doc = win.document;
    //     }
    //     else {
    //       doc = document;
    //     }

    //     var element;
    //     if(identifierType === 'class') {
    //       element = doc.getElementsByClassName(elementIdentifier)[0];
    //     }
    //     else {
    //       element = doc.getElementById(elementIdentifier);
    //     }
    //     element.scrollIntoView();
    //     window.scrollTo(0,0);
    //   }

    //   static clearSelection(win,doc) {
    //     if (win && win.getSelection) {
    //         win.getSelection().removeAllRanges();
    //     } else if (doc && doc.selection) {
    //         document.selection.empty();
    //     }
    //   }

    //   static filterLeafItem ( resourceList, filterFunc, returnAsList ){
    //     if(typeof filterFunc === 'function' && resourceList){
    //       let filteredResourceList = [];
    //       if( resourceList instanceof Array && resourceList.length > 0){
    //         for(let i = 0,iLen = resourceList.length; i < iLen; i++){
    //           if( resourceList[i].subtopic && resourceList[i].subtopic.length > 0){
    //           let subTree , subTreeResourceList;
    //           subTreeResourceList = this.filterLeafItem(resourceList[i].subtopic, filterFunc, returnAsList);
    //           if(subTreeResourceList && subTreeResourceList instanceof Array && subTreeResourceList.length > 0){
    //             if(returnAsList){
    //               filteredResourceList = filteredResourceList.concat(subTreeResourceList);
    //             } else {
    //               subTree = resourceList[i];
    //               subTree.subtopic = subTreeResourceList;
    //               filteredResourceList.push(subTree);
    //             }
    //           }
    //           } else if(filterFunc(resourceList[i], i)){
    //             filteredResourceList.push(resourceList[i]);
    //           }
    //         }
    //       } else if(typeof resourceList === 'object') {
    //         if(filterFunc(resourceList, i)){
    //           filteredResourceList.push(resourceList);
    //         }
    //       }
    //       return filteredResourceList;
    //     } else {
    //       return resourceList;
    //     }
    //   }

    //   static hostReachable() {
    //     if(!navigator.onLine) {
    //       return false;
    //     }
    //     // Handle IE and more capable browsers
    //     let xhr = new ( window.ActiveXObject || XMLHttpRequest )( "Microsoft.XMLHTTP" );
    //     let status;
    //     // Open new request as a HEAD to the root hostname with a random param to bust the cache
    //     xhr.open( "HEAD", config.BaseUrl +  "?rand=" + Math.floor((1 + Math.random()) * 0x10000), false );

    //     // Issue request and handle response
    //     try {
    //       xhr.send();
    //       return ( xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304) );
    //     } catch (error) {
    //       console.log('hostReachable error in dataFormatter', error);
    //       return false;
    //     }
    //   }

    //   static getBookIdForProduct(productId, productList, role){
    //     var prodLength = productList.length;
    //     var matchedProduct;
    //     for(var i=0; i<prodLength; i++){
    //       if(productList[i].id == productId){
    //         matchedProduct = productList[i];
    //       }
    //     }
    //     var bookArray = matchedProduct.books;
    //     var bookArrayLength = bookArray;
    //     for(var i=0; i<bookArrayLength; i++){
    //       if(bookArray[i].metadata){
    //         if(role == bookArray[i].metadata.role){
    //          return bookArray[i].id;
    //         }
    //       }
    //     }
    //     if(bookArray[0]){
    //       return bookArray[0].id || false
    //     }else{
    //       return false
    //     }
    //   }
    static msieversion() {
      const ua = window.navigator.userAgent;

      // Test values; Uncomment to check result …

      // IE 10
      // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

      // IE 11
      // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

      // Edge 12 (Spartan)
      // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

      // Edge 13
      // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      const edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
    }


    static isiPad() {
      return (navigator.platform.indexOf('iPad') != -1);
    }

    static checkScormLinks(urlString) {
      let scormcheck = false;
      const urlStringAnalysis = urlString.split('?');
      let urlParamString; let urlParamList; let
        urlParam;
      if (urlStringAnalysis.length == 2) {
        urlParamString = urlStringAnalysis[1];
        urlParamList = urlParamString.split('&');
        if (urlParamList.length > 0) {
          const scormParamCheck = {}; let
            scormParamCheckCount = 0;
          for (const param of urlParamList) {
            urlParam = param.split('=');
            if (urlParam.length > 0 && (urlParam[0] === 'source' || urlParam[0] === 'originId' || urlParam[0] === 'assetId')) {
              scormParamCheckCount++;
              scormParamCheck[urlParam[0]] = urlParam[1];
            }
          }
          if (scormParamCheckCount >= 1 && scormParamCheckCount <= 3) {
            scormcheck = scormParamCheck;
          }
        }
      }
      return scormcheck;
    }

    static formatArryObjToString(obj, type = null) {
      if (type === 'sendbook') {
        return JSON.stringify(obj)
          .replace(/[\"\[\]\{\}]/gi, '')
          .replace(/,/g, ', ')
          .replace(/userId/g, 'uId')
          .replace(/productId/g, 'pId')
          .replace(/assignmentType/g, 'type')
          .replace(/interactiveIncluded/g, 'interactive');
      }
      return JSON.stringify(obj)
        .replace(/[\"\[\]\{\}]/gi, '')
        .replace(/,/g, ', ')
        .replace(/:/g, ': ');
    }

    static splitStringToSubstring(inputStr) {
      return inputStr !== '' ? inputStr.match(/.{1,100}/g) : '';
    }

    static camelCaseTo_Separator(inputStr) {
      return inputStr.split(/(?=[A-Z])/).join('_').toLowerCase();
    }

    static formatCustomFieldsForGA(dataObj, prefix = '') {
      const responseObj = {};
      if (Object.keys(dataObj).length > 0) {
        Object.keys(dataObj).forEach((key) => {
          let keyIndex = 1;
          const baseKeyStr = (prefix !== '') ? `${prefix}_${this.camelCaseTo_Separator(key)}` : this.camelCaseTo_Separator(key);
          const formattedStr = this.formatArryObjToString(dataObj[key]);
          let keyStr = baseKeyStr;
          if (formattedStr.length > 100) {
            const splitString = formattedStr.split(',');
            let checkStr = ''; let
              prevCheckStr = '';
            splitString.map((value, index) => {
              prevCheckStr = checkStr;
              checkStr += (checkStr !== '') ? `, ${value}` : value;
              if (checkStr.length > 100) {
                keyStr = `${baseKeyStr}_${keyIndex}`;
                responseObj[keyStr] = prevCheckStr.trim();
                checkStr = value;
                keyIndex++;
              }
            });
            if (checkStr.length > 0) {
              keyStr = `${baseKeyStr}_${keyIndex}`;
              responseObj[keyStr] = checkStr.trim();
            }
          } else {
            responseObj[keyStr] = formattedStr;
          }
        });
      }
      return responseObj;
    }

    static mergeCustomFields(dataObj, mergeObj) {
      if (dataObj && Object.keys(dataObj).length) {
        if (mergeObj) {
          Object.keys(mergeObj).forEach((key) => {
            dataObj.dataLayer[key] = mergeObj[key];
          });
        }
        return dataObj;
      }
      return null;
    }

    static getAppConfigKey(key) {
      const appConfigStr = localStorage.getItem('appConfig');
      let appConfig = appConfigStr.replace(/\\/g, '');
      appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
      return appConfig.readingui[key];
    }
}

export default DataFormatter;
