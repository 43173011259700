/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class GetFilterDataService extends BaseService {
  constructor() {
    super(constants.FILTER_FETCHED_REQUESTED, 'GetFilterDataServiceStatus');
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    headerParams = {
      headers: {
        url: event.furl
        // appversion: 'app'
      }
    };
    // return this.getClient('hapi').get('/fetch-filterconfig',{},headerParams);
    return this.getClient('hapi').get('/fetch-filterconfig', headerParams);
  }


  handleResponse(response) { // eslint-disable-line class-methods-use-this
    // if (response.data && response.data.data) {
    if (response.data) {
      Framework.getEventManager().publish(constants.FILTER_FETCHED_SUCCESS, response.data);
    } else {
      Framework.getEventManager().publish(constants.FILTER_FETCHED_ERROR, {
        message: 'Unable to fetch filter details '
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.FILTER_FETCHED_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
