class LocalizationHandler {
    static LanguageAvailable;

    static fetchLanguageDefaultFile = function () {
      let obj = navigator.language || navigator.userLanguage;
      if (navigator.languages && navigator.languages.length > 0) {
        if (obj != navigator.languages[0]) {
          obj = navigator.languages[0];
        }
      }
      if (obj) {
        return obj;
      }
      return 'en-US';
    }

    static getLanguage = function () {
      if (!LocalizationHandler.LanguageAvailable) {
        LocalizationHandler.LanguageAvailable = LocalizationHandler.fetchLanguageDefaultFile();
      }
      return LocalizationHandler.LanguageAvailable;
    }
}
export default LocalizationHandler;
