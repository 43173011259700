import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import $ from 'jquery';
import { chunk } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import correctCheckmarkIcon from '../../../assets/images/young_learners/correct-checkmark.png';

const PasswordSelectionDoneButton = withStyles({
  root: {
    width: '100%',
    height: '40px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#00857f',
    borderColor: '#00857f',
    color: '#fff',
    borderRadius: '8px',
    fontFamily: [
      'HeinemannSpecial-Bold'
    ].join(','),
    '&:hover': {
      backgroundColor: '#00857f',
      borderColor: '#00857f',
      color: '#fff',
      boxShadow: 'none',
      opacity: 0.6
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#00857f',
      borderColor: '#00857f',
      color: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#c6c6c6',
      borderColor: '#c6c6c6',
      color: '#fff'
    }
  }
})(Button);


class StudentPassword extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    passwordSelected: null,
    passwordImage: null
  };

  componentDidMount() {
    const popUpSelectPassword = document.getElementsByClassName('password-option-icon');
    if (popUpSelectPassword.length > 0) {
      $('.password-option-icon')[0].focus();
    }
  }

  componentWillUnmount() {
  }

  passwordSelectionOnClick = (passwordSelected, passwordImage) => {
    if (passwordSelected !== '') {
      this.setState({ passwordSelected });
      this.setState({ passwordImage });
    }
  }

  renderSelectionStatus = (password) => {
    if (this.state.passwordSelected !== null && this.state.passwordSelected === password) {
      return (
        <img src={correctCheckmarkIcon} alt="password-selected-icon" className="password-selected" />
      );
    }
  }

  renderPasswordGrid = (passwordListFromAPI) => {
    const passwordListArray = chunk(passwordListFromAPI, 4);
    return passwordListArray.map(passwordList => (
      <div className="row password-list-row">
        {passwordList.map(({ password, logoURL }) => (
          <div className="col password-container">
            <div className="row justify-content-center align-items-center">
              <div className="col password-list">
                <div className="overlay">
                  {this.renderSelectionStatus(password)}
                </div>
                <div tabIndex="0" className="password-border-outer" onKeyDown={(e) => { if (e.key == 'Enter') { this.passwordSelectionOnClick(password, logoURL); } }}>
                  <img
                    src={logoURL}
                    className="password-option-icon show-cursor"
                    onClick={() => this.passwordSelectionOnClick(password, logoURL)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  }

  selectPasswordImage = () => {
    this.props.selectPasswordImage(this.state.passwordSelected, this.state.passwordImage);
  }

  showDoneButton = () => {
    const { language, intl } = this.props;
    if (this.state.passwordSelected !== null) {
      return (
        <PasswordSelectionDoneButton onClick={this.selectPasswordImage.bind(this)}>{intl.formatMessage(language.getText('done_msg'))}</PasswordSelectionDoneButton>
      );
    }

    return (
      <PasswordSelectionDoneButton disabled>{intl.formatMessage(language.getText('done_msg'))}</PasswordSelectionDoneButton>
    );
  }

  render() {
    return (
      <div>
        <div
          className="container-fluid selectpassword-container"
        >

          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 justify-content-center student-password-listing">
              {this.renderPasswordGrid(this.props.passwordList)}
              <div className="row password-list-row">
                <div className="col">{this.showDoneButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StudentPassword.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language'),
  observer
)(injectIntl(StudentPassword));
