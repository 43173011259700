import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import $ from 'jquery';
import injectTapEventPlugin from 'react-tap-event-plugin';
import TagManager from 'react-gtm-module';
import TextbookShelf from './TextBooks';
import Popup from './Popup.js';
import PrimaryRefineSearchConsole from './primaryRefineSearchConsole';
import SecondaryRefineSearchConsole from './SecondaryRefineSearchConsole';
import LoadingOverlay from '../../../common/components/loadingOverlay';
import DataFormatter from '../../../common/utils/DataFormatter';
import CommonUtils from '../../../common/utils/CommonUtils';
import Books from '../model/Books';
import * as constants from '../../../common/constants';
import constflipImageURL from '../../../assets/images/books/flip-icon-secondary.png';
import constflipImageURL1 from '../../../assets/images/books/flip-primary.png';
import constnewImageURL from '../../../assets/images/new.png';
import constaddImageURL from '../../../assets/images/add.png';
import constfavInactiveImageURL from '../../../assets/images/fav.png';
import constfavActiveImageURL from '../../../assets/images/fav1.png';
import AddBookComponent from './AddBookComponent';
import ChipComponent from './chips';
// // import GoogleAnalyticsUtil from '../util/googleAnalyticsUtil';

try {
  injectTapEventPlugin();
} catch (e) {
}

let primaryBookShelfProps = {};

let secondaryBookShelfProps = {};

const styles = {
  primaryHeadingStyles: {
    marginTop: '20px',
    marginBottom: '22px',
    fontSize: '32px',
    fontWeight: '300',
    lineHeight: '1.13',
    textAlign: 'left',
    color: '#393c3d'
  },
  okbuttonStyle: {
    width: '140px',
    height: '36px',
    borderRadius: '2px',
    textAlign: 'center',
    marginLeft: '134px',
    marginTop: '20px'
  }
};

const BOOKS_LIMIT_PER_FETCH = 25;

let previousScrollTop = 0;
// const lang = DataFormatter.getLanguage();
const booksListData = [];
let skipfetchProductBySchool = false;
class SchoolLibrary extends React.Component {
  constructor() {
    super();
    this.updateProductsFetched = this.updateProductsFetched.bind(this);
    this.updateSearchProductsFetched = this.updateSearchProductsFetched.bind(this);
    this.refineServiceProductData = this.refineServiceProductData.bind(this);
    this.handleBookClick = this.handleBookClick.bind(this);
    this.updateProductsFetchUpdate = this.updateProductsFetchUpdate.bind(this);
    this.searchBookshelf = this.searchBookshelf.bind(this);
    this.sendBookCallback = this.sendBookCallback.bind(this);
    this.bookCheckedCallback = this.bookCheckedCallback.bind(this);
    this.viewAllBooks = this.viewAllBooks.bind(this);
    this.viewSelectedBooks = this.viewSelectedBooks.bind(this);
    this.sendBooks = this.sendBooks.bind(this);
    this.resetSelectedBooks = this.resetSelectedBooks.bind(this);
    this.showSnackBar = this.showSnackBar.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.closeAddBookPopUp = this.closeAddBookPopUp.bind(this);
    this.handleStudentsPopupClose = this.handleStudentsPopupClose.bind(this);
    this.loadRelatedProducts = this.loadRelatedProducts.bind(this);
    this.updateProductsListFetchedForAutoComplete = this.updateProductsListFetchedForAutoComplete.bind(this);
    this.getProductsListForAutoComplete = this.getProductsListForAutoComplete.bind(this);
    this.isLazyLoadingApplicable = this.isLazyLoadingApplicable.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.searchBookshelfCall = this.searchBookshelfCall.bind(this);
    this.enableLoading = this.enableLoading.bind(this);
    this.updateAutoCompleteSearchUserProductsFetched = this.updateAutoCompleteSearchUserProductsFetched.bind(this);
    this.updateSearchUserProductsFetched = this.updateSearchUserProductsFetched.bind(this);
    this.handleReturnedFilter = this.handleReturnedFilter.bind(this);
    this.retainBooksSelection = this.retainBooksSelection.bind(this);
    this.resizeBaacWindow = this.resizeBaacWindow.bind(this);
    this.selectAllSearchedBooks = this.selectAllSearchedBooks.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  state = {
    bookSelected: [],
    searchText: null,
    books: null,
    booksLength: 0,
    lazyLoadingApplicable: false,
    bookLimitPerFetch: BOOKS_LIMIT_PER_FETCH,
    bookshelfDataLoaded: false,
    productFetchInProgress: false,
    isLoading: true,
    handleBookClicked: false,
    booksSelected: DataFormatter.getObjectInSessionStorage(
      'booksSelectedByTeacher'
    )
      ? DataFormatter.getObjectInSessionStorage('booksSelectedByTeacher')
      : [],
    openSnackBar: false,
    message: '',
    showAddbookPopup: false,
    bookSelectedToAdd: {},
    searchCount: 0,
    chipData: [],
    flipSend: false,
    resizeBaacWindow: false,
    reloadSchoolLib: false,
    booksViewMode: 'viewAll',
    showKeywordSearch: false,
    showFilterSearch: false,
    selectionLimitReached: false,
    searchOccured: false,
    productListData: '',
    ReturnedCalled: true,
    provisionResponse: true
  };

  handleBookClick = (book, index) => {
    DataFormatter.setObjectInStorage('bookClicked', book);
    if (this.state.searchOccured) {
      DataFormatter.setObjectInStorage('restrictedProductCall', true);
    }
    Framework.redirectTo('/book');
  };

  //   componentDidMount = function () {
  //     AppStore.on(
  //       constants.EventTypes.USER_PRODUCTS_FETCHED_SUCCESS,
  //       this.updateUserProducts
  //     );
  //     AppStore.on(
  //       constants.EventTypes.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS,
  //       this.updateProductsListFetchedForAutoComplete
  //     );
  //     AppStore.on(
  //       constants.EventTypes.PRODUCTS_FETCHED_SUCCESS,
  //       this.updateProductsFetched
  //     );
  //     AppStore.on(
  //       constants.EventTypes.SEARCH_PRODUCTS_FETCHED_SUCCESS,
  //       this.updateSearchProductsFetched
  //     );
  //     AppStore.on(
  //       constants.EventTypes.RELATED_PRODUCTS_FETCHED_SUCCESS,
  //       this.loadRelatedProducts
  //     );
  //     AppStore.on(
  //       constants.EventTypes.PRODUCT_PROVISION_COMPLETED,
  //       this.showSnackBar
  //     );
  //     AppStore.on(
  //       constants.EventTypes.SEARCH_USER_PRODUCTS_FETCHED_SUCCESS,
  //       this.updateSearchUserProductsFetched
  //     );
  //     AppStore.on(
  //       constants.EventTypes.USER_PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS,
  //       this.updateAutoCompleteSearchUserProductsFetched
  //     );
  //     const mainAppContainerElement = document.getElementById('main');
  //     if (mainAppContainerElement) {
  //       mainAppContainerElement.addEventListener('scroll', this.handleScroll);
  //     }
  //     window.addEventListener('resize', this.handleResize);
  //   };

  handleResize() {
    this.setState({ resizeWindow: true });
  }

  updateUserProducts = () => {
    const { dlBooks } = this.props;
    // const userProductListData = AppStore.productListData;
    const userProductListData = this.state.productListData;
    DataFormatter.setObjectInStorage('userProductList', userProductListData);
    if (DataFormatter.getObjectInStorage('restrictedProductCall') === true) {
      skipfetchProductBySchool = true;
    }
    if (skipfetchProductBySchool === false) {
      // console.log("updateUserProducts Start Time: ", new Date(), new Date().getTime());
      Promise.all([
        dlBooks.fetch(
          this.props.schoolId,
          this.state.bookLimitPerFetch,
          !isNaN(this.state.productsLength) ? this.state.productsLength : ''
        )]).then(() => {
        // console.log("updateUserProducts End Time: ", new Date(), new Date().getTime());
        // this.updateProductsFetched();
      });

      // AppActions.fetchProductBySchoolId(
      //   this.props.schoolId,
      //   this.state.bookLimitPerFetch,
      //   !isNaN(this.state.productsLength) ? this.state.productsLength : ''
      // );
      // fetch-product-by-school-id
      // Framework.getEventManager().publish(constants.PRODUCTS_FETCHED_REQUESTED,{schoolId:this.props.schoolId,searchlimit:this.state.bookLimitPerFetch,offset:(!isNaN(this.state.productsLength) ? this.state.productsLength : '')});
    }
    skipfetchProductBySchool = false;
  };

  handleScroll = function () {
    const { dlBooks } = this.props;
    const mainAppContainerElement = document.getElementById('main');
    const scrollTop = mainAppContainerElement.scrollTop;
    const docHeight = mainAppContainerElement.scrollHeight;
    const winHeight = mainAppContainerElement.offsetHeight;
    const scrollPercent = scrollTop / (docHeight - winHeight);
    const scrollPercentRounded = Math.round(scrollPercent * 100);

    if (
      previousScrollTop < scrollTop
      && scrollPercentRounded > 75
      && !this.state.productFetchInProgress
      && this.state.booksViewMode !== 'viewSelected'
    ) {
      if (
        this.state.lazyLoadingApplicable
        && (this.state.books.length !== this.state.booksSelected.length
          || this.state.searchOccured)
      ) {
        const path = window.location.search;
        if (path == '') {
          if (this.props.schoolId) {
            this.setState(
              {
                productFetchInProgress: true,
                isLoading: true
              },
              () => {
                if (this.state.searchOccured) {
                  // AppActions.searchProducts(
                  //   this.props.schoolId,
                  //   this.state.searchObject,
                  //   100,
                  //   !isNaN(this.state.productsLength)
                  //     ? this.state.productsLength
                  //     : ''
                  // );
                  // search-product
                  Framework.getEventManager().publish(constants.SEARCH_PRODUCTS_REQUESTED, {
                    schoolId: this.props.schoolId, searchObj: this.state.searchObject, limit: 100, offset: (!isNaN(this.state.productsLength) ? this.state.productsLength : '')
                  });
                } else {
                  // console.log("handleScroll Start Time: ", new Date(), new Date().getTime());
                  Promise.all([
                    dlBooks.fetch(
                      this.props.schoolId,
                      this.state.bookLimitPerFetch,
                      !isNaN(this.state.productsLength)
                        ? this.state.productsLength
                        : ''
                    )
                  ]).then(() => {
                    // console.log("handleScroll End Time: ", new Date(), new Date().getTime());
                    // this.updateProductsFetched();
                  });
                }
              }
            );
          }
        }
      }
    }
    previousScrollTop = scrollTop;
  };

  intializeSecondaryProps = function () {
    const { language, intl } = this.props;
    const teachingNotes = this.getTeachingNotesText();
    secondaryBookShelfProps = {
      textBookObj: this.state.books,
      bookClickCallback: this.handleBookClick,
      bookAddCallback: this.bookAddCallback,
      bookCheckedCallback: this.bookCheckedCallback,
      favouriteOptionCallback: this.favouriteOptionCallback,
      flipBookCallback: this.flipBookCallback,
      returnBookCallback: this.returnBookCallback,
      sendBookCallback: this.sendBookCallback,
      moreBooksCallback: this.moreBooksCallback,
      buyBookCallback: this.buyBookCallback,
      teachingNotesClickCallback: this.teachingNotesClickCallback,
      resourcesClickCallback: this.resourcesClickCallback,
      lessonPlansClickCallback: this.lessonPlansClickCallback,
      favInactiveImageURL: constfavInactiveImageURL,
      favActiveImageURL: constfavActiveImageURL,
      addImageURL: constaddImageURL,
      flipImageURL: constflipImageURL,
      flipImageURL1: constflipImageURL1,
      newImageURL: constnewImageURL,
      enableBookSelect: true,
      enableFavourite: false,
      enableAdd: false,
      enableFlip: true,
      enableReturnBook: true,
      enableMoreBooksOption: false,
      enableAddBookOption: false,
      theme: 'secondary',
      emptyTextbookText: intl.formatMessage(language.getText('NoBookFound')),
      resizeBaacWindow: this.state.resizeBaacWindow,
      parentComponent: 'digital_lib',
      readingLevelText: intl.formatMessage(language.getText('Reading_Level')),
      activityText: intl.formatMessage(language.getText('activity')),
      teachingNotesText: teachingNotes,
      resourcesText: intl.formatMessage(language.getText('resources_baac')),
      lessonPlansText: intl.formatMessage(
        language.getText('lesson_plans_baac')
      ),
      openBookText: intl.formatMessage(language.getText('open_book')),
      sendBookText: intl.formatMessage(language.getText('send_book')),
      physicalCopyText: intl.formatMessage(language.getText('physical_copy')),
      addtoMyBookboxText: intl.formatMessage(
        language.getText('addto_my_bookbox')
      ),
      returnBookText: intl.formatMessage(language.getText('return_book'))
    };
  };

  intializePrimaryProps = function () {
    const { language, intl } = this.props;
    const teachingNotes = this.getTeachingNotesText();
    primaryBookShelfProps = {
      textBookObj: this.state.books,
      bookClickCallback: this.handleBookClick,
      bookAddCallback: this.bookAddCallback,
      bookCheckedCallback: this.bookCheckedCallback,
      favouriteOptionCallback: this.favouriteOptionCallback,
      flipBookCallback: this.flipBookCallback,
      returnBookCallback: this.returnBookCallback,
      sendBookCallback: this.sendBookCallback,
      moreBooksCallback: this.moreBooksCallback,
      buyBookCallback: this.buyBookCallback,
      teachingNotesClickCallback: this.teachingNotesClickCallback,
      resourcesClickCallback: this.resourcesClickCallback,
      lessonPlansClickCallback: this.lessonPlansClickCallback,
      favInactiveImageURL: constfavInactiveImageURL,
      favActiveImageURL: constfavActiveImageURL,
      addImageURL: constaddImageURL,
      flipImageURL: constflipImageURL,
      flipImageURL1: constflipImageURL1,
      newImageURL: constnewImageURL,
      enableBookSelect: false,
      enableFavourite: false,
      enableAdd: true,
      enableFlip: true,
      enableReturnBook: false,
      enableMoreBooksOption: false,
      enableAddBookOption: true,
      theme: 'primary',
      emptyTextbookText: intl.formatMessage(language.getText('NoBookFound')),
      resizeBaacWindow: this.state.resizeBaacWindow,
      parentComponent: 'digital_lib',
      readingLevelText: intl.formatMessage(language.getText('Reading_Level')),
      activityText: intl.formatMessage(language.getText('activity')),
      teachingNotesText: teachingNotes,
      resourcesText: intl.formatMessage(language.getText('resources_baac')),
      lessonPlansText: intl.formatMessage(language.getText('lesson_plans_baac')),
      openBookText: intl.formatMessage(language.getText('open_book')),
      sendBookText: intl.formatMessage(language.getText('send_book')),
      physicalCopyText: intl.formatMessage(language.getText('physical_copy')),
      addtoMyBookboxText: intl.formatMessage(language.getText('addto_my_bookbox')),
      returnBookText: intl.formatMessage(language.getText('return_book'))
    };
  };

  getTeachingNotesText = function () {
    const { language, intl } = this.props;
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(
      appConfig.substring(
        appConfig.indexOf('{'),
        appConfig.lastIndexOf('}') + 1
      )
    );
    const eltConfig = appConfig.isElt === true;
    let teachingNotes = intl.formatMessage(
      language.getText('teaching_notes_baac')
    );
    if (eltConfig) {
      teachingNotes = intl.formatMessage(language.getText('teachers_notes'));
    }
    return teachingNotes;
  };

  bookAddCallback = (book, index) => {
    const { language, intl } = this.props;
    try {
      $('.mainAppContainer')[0].scrollTop = 0;
    } catch (e) { }

    // Updating the 'alreadyassigned' state of the book selected
    const booksList = this.state.books ? [...this.state.books] : [];
    if (book) {
      if (booksList) {
        if (booksList.length !== 0) {
          booksList.forEach((bookListItem, index) => {
            if (
              bookListItem.bookId === book.bookId
              && bookListItem.alreadyAssigned === true
            ) {
              book.alreadyAssigned = true;
            }
          });
        }
      }
    }

    if (book.hasOwnProperty('alreadyAssigned') && book.alreadyAssigned) {
      this.setState({
        openSnackBar: true,
        snackBarMessage: intl.formatMessage(language.getText('book_already_provisioned'))
      });
    } else {
      this.setState(
        {
          showAddbookPopup: true,
          bookSelectedToAdd: book
        },
        () => {
          const addBookModalPanel = document.getElementById('addBookModalPanel');
          if (addBookModalPanel) {
            addBookModalPanel.focus();
          }
        }
      );
    }
  };

  closeAddBookPopUp = (type = '') => {
    const { language, intl } = this.props;
    this.setState({
      showAddbookPopup: false,
      bookSelectedToAdd: {}
    });
    const eventCategory = `${intl.formatMessage(language.getText('Cancel'))} ${intl.formatMessage(language.getText('add_book'))}`;
    const eventAction = `${intl.formatMessage(language.getText('Cancel'))} ${intl.formatMessage(language.getText('clicked'))}`;

    if (type === 'close') {
      const gtmData = {
        dataLayer: {
          event: 'cancel_add_book',
          event_category: eventCategory,
          event_action: eventAction,
          event_label: null,
          assignment_type: 'self',
          interactive_included: 'true',
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }
  };

  handleAlreadyAssignedStatus = () => {
    // Updating the 'alreadyassigned' state of the book selected
    const booksList = this.state.books ? [...this.state.books] : [];
    const booksSelected = this.state.booksAlreadyAssigned
      ? [...this.state.booksAlreadyAssigned]
      : [];
    booksSelected.push(this.state.bookSelectedToAdd);
    if (booksSelected) {
      if (booksList) {
        if (booksList.length !== 0) {
          self = this;
          booksList.forEach((bookListItem, index1) => {
            booksSelected.forEach((booksSelectedItem, index2) => {
              if (bookListItem.bookId === booksSelectedItem.bookId) {
                booksList[index1].alreadyAssigned = true;
              }
            });
          });
        }
      }
    }
    this.setState({
      books: booksList,
      booksAlreadyAssigned: booksSelected
    });
  };

  handleAddbookPopup = () => {
    if (this.state.showAddbookPopup) {
      return (
        <AddBookComponent
          assignStatus={this.handleAlreadyAssignedStatus}
          book={this.state.bookSelectedToAdd}
          handleClose={(type) => { this.closeAddBookPopUp(type); }}
        />
      );
    }
    return null;
  };

  bookCheckedCallback = (book, index) => {
    const books = DataFormatter.getObjectInStorage('BookshelfData');
    for (let i = 0; i < books.length; i++) {
      if (books[i].bookId == book.bookId) {
        if (book.isChecked) {
          books[i].isChecked = true;
        } else {
          books[i].isChecked = false;
        }
        break;
      }
    }
    DataFormatter.setObjectInStorage('BookshelfData', books);

    const booksSelectedInSession = DataFormatter.getObjectInSessionStorage(
      'booksSelectedByTeacher'
    )
      ? DataFormatter.getObjectInSessionStorage('booksSelectedByTeacher')
      : [];
    if (book.isChecked) {
      if (booksSelectedInSession) {
        let bookAllReadySelected = false;
        for (let i = 0; i < booksSelectedInSession.length; i++) {
          if (booksSelectedInSession[i].bookId == book.bookId) {
            bookAllReadySelected = true;
          }
        }
        if (!bookAllReadySelected) {
          booksSelectedInSession.push(book);
        }
      }
      var sortedBooks = DataFormatter.sortList(
        booksSelectedInSession,
        constants.SortType.ASC,
        'bookName'
      );
      this.setState({
        booksSelected: sortedBooks,
        flipSend: false
      });
    } else {
      for (let i = 0; i < booksSelectedInSession.length; i++) {
        if (booksSelectedInSession[i].bookId === book.bookId) {
          booksSelectedInSession.splice(i, 1);
          break;
        }
      }
      let books = [...this.state.books];
      if (this.state.booksViewMode === 'viewSelected') {
        books = booksSelectedInSession;
      }
      var sortedBooks = DataFormatter.sortList(
        booksSelectedInSession,
        constants.SortType.ASC,
        'bookName'
      );
      this.setState(
        {
          booksSelected: sortedBooks,
          books,
          flipSend: false
        },
        function () {
          if (this.state.booksSelected.length == 0) {
            this.resetSelectedBooks(true);
          }
        }
      );
    }

    if (booksSelectedInSession.length !== 0) {
      DataFormatter.setObjectInSessionStorage(
        'booksSelectedByTeacher',
        booksSelectedInSession
      );
    } else {
      DataFormatter.setObjectInSessionStorage('booksSelectedByTeacher', []);
    }
  };

  selectAllSearchedBooks = () => {
    const searchedBooks = [...this.state.books];
    const selectionLimitReached = searchedBooks.length >= 100;
    if (selectionLimitReached) {
      this.setState({
        selectionLimitReached
      });
    } else {
      this.selectSearchedBooks();
    }
  };

  selectSearchedBooks = () => {
    const searchedBooks = [...this.state.books];
    const booksSelectedInSession = DataFormatter.getObjectInSessionStorage(
      'booksSelectedByTeacher'
    )
      ? DataFormatter.getObjectInSessionStorage('booksSelectedByTeacher')
      : [];
    const booksToSelect = [...booksSelectedInSession];
    const limit = searchedBooks.length > 100 ? 100 : searchedBooks.length;
    if (booksSelectedInSession) {
      for (let i = 0; i < limit; i++) {
        // book selection limit is 100
        if (!searchedBooks[i].isChecked) {
          searchedBooks[i].isChecked = true;
          booksSelectedInSession.push(searchedBooks[i]);
          booksToSelect.push(searchedBooks[i]);
        }
      }
    }
    const sortedBooks = DataFormatter.sortList(
      booksToSelect,
      constants.SortType.ASC,
      'bookName'
    );
    this.setState({
      booksSelected: sortedBooks,
      flipSend: false,
      selectionLimitReached: false
    });

    if (booksSelectedInSession.length !== 0) {
      DataFormatter.setObjectInSessionStorage(
        'booksSelectedByTeacher',
        booksSelectedInSession
      );
    } else {
      DataFormatter.setObjectInSessionStorage('booksSelectedByTeacher', []);
    }
  };

  showSnackBar = (bookProvisioned) => {
    const { language, intl } = this.props;
    this.resetSelectedBooks(true);
    const userMode = DataFormatter.getObjectInStorage('userMode');
    if (userMode === constants.secondaryUserMode) {
      var provisionResponse = bookProvisioned;
      let messageToShow;
      if (provisionResponse === 'success') {
        messageToShow = intl.formatMessage(language.getText('provision_success'));
      } else {
        messageToShow = intl.formatMessage(language.getText('provision_failed'));
      }
      this.setState({
        openSnackBar: true,
        snackBarMessage: messageToShow
      });
    } else {
      var provisionResponse = bookProvisioned;
      if (provisionResponse === 'success') {
        skipfetchProductBySchool = true;
        if (($(document).find('[datavalue="returned"]').hasClass('selected')) && this.state.provisionResponse){
          $(document).find('[datavalue="returned"]').click();
          this.setState({ ReturnedCalled: true, provisionResponse: true });
        } else {
          // AppActions.fetchProductByUserId(this.props.userId, false);
          //  Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
          //   {
          //       userId: this.props.userId,
          //       includeReturned:false,
          //       searchlimit:25,
          //       offset:0
          //   }
          //   );
        }
      }
    }
  };

  handleRequestClose = () => {
    this.setState({
      openSnackBar: false
    });
  };

  favouriteOptionCallback = (book, index) => {
    console.log('favouriteOptionCallback', index);
  };

  flipBookCallback = (book, index) => {
    const { language, intl } = this.props;
    let eventCategory;
    let event = '';
    if (this.props.mode === 'primary') {
      eventCategory = intl.formatMessage(language.getText('flip_book_student'));
      event = 'flip_book_student';
    } else {
      eventCategory = intl.formatMessage(language.getText('flip_book_teacher'));
      event = 'flip_book_teacher';
    }
    const gtmData = {
      dataLayer: {
        event,
        event_category: eventCategory,
        event_action: intl.formatMessage(language.getText('book_turnback')),
        event_label: null,
        book_title: book.bookName,
        book_id: book.bookId,
        activity: 'Turn Backside',
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  };

  buyBookCallback = (book, index) => {
    window.open(book.metadata.eCommLink);
  };

  teachingNotesClickCallback = (book, index) => {
    const { language, intl } = this.props;
    if (this.props.mode === 'secondary') {
      const teachingNotes = this.getTeachingNotesText();
      const gtmData = {
        dataLayer: {
          event: 'teaching_notes',
          event_category: teachingNotes,
          event_action: teachingNotes + intl.formatMessage(language.getText('clicked')),
          event_label: null,
          book_title: book.bookName,
          book_id: book.bookId,
          notes_url: book.metadata.teachingNote,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }

    window.open(book.metadata.teachingNote);
  };

  resourcesClickCallback = (book, index) => {
    const { language, intl } = this.props;
    if (this.props.mode === 'secondary') {
      const resourcesText = intl.formatMessage(language.getText('resources_baac'));
      const gtmData = {
        dataLayer: {
          event: 'resources',
          event_category: resourcesText,
          event_action: resourcesText + intl.formatMessage(language.getText('clicked')),
          event_label: null,
          book_title: book.bookName,
          book_id: book.bookId,
          notes_url: book.metadata.resources,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }

    window.open(book.metadata.resources);
  };

  lessonPlansClickCallback = (book, index) => {
    const { language, intl } = this.props;
    if (this.props.mode === 'secondary') {
      const lessonPlansText = intl.formatMessage(language.getText('lesson_plans_baac'));
      const gtmData = {
        dataLayer: {
          event: 'lesson_plans',
          event_category: lessonPlansText,
          event_action: lessonPlansText + intl.formatMessage(language.getText('clicked')),
          event_label: null,
          book_title: book.bookName,
          book_id: book.bookId,
          notes_url: book.metadata.lessonPlans,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }
    window.open(book.metadata.lessonPlans);
  };

  returnBookCallback = (book, index) => {
    console.log('return clicked', index);
  };

  sendBookCallback = (book, index) => {
    const selectedBooks = [];
    DataFormatter.setObjectInStorage('currentProductId', book.productId);
    selectedBooks.push(book);
    this.setState({
      handleBookClicked: true,
      flipSend: true,
      booksSelected: selectedBooks
    });
  };

  loadRelatedProducts = (loadRelatedProducts) => {
    // const relatedBooks = AppStore.productListData;
    const relatedBooks = loadRelatedProducts;
    let bookitem;
    const booksList = [];
    if (relatedBooks instanceof Array) {
      for (let i = 0; i < relatedBooks.length; i++) {
        bookitem = this.refineServiceProductData(relatedBooks[i], i);
        if (bookitem !== false) {
          booksList.push(bookitem);
        }
      }
    }
    const sortedBooks = DataFormatter.sortList(
      booksList,
      constants.SortType.ASC,
      'bookName'
    );
    if (JSON.stringify(sortedBooks) != JSON.stringify(this.state.books)) {
      this.setState({
        books: sortedBooks,
        bookshelfDataLoaded: true,
        isLoading: false
      });
    }
    DataFormatter.setObjectInStorage('BookshelfData', this.state.books);
  };

  moreBooksCallback = (book, index) => {
    const { language, intl } = this.props;
    const selectedBook = {};
    selectedBook.metadata = {};
    if (book.metadata.topicsOfInterest) {
      selectedBook.metadata.topicsOfInterest = book.metadata.topicsOfInterest;
    }
    if (book.metadata.readingLevelsForFilter) {
      selectedBook.metadata.readingLevels = book.metadata.readingLevelsForFilter;
    }
    const encodedSearchObj = btoa(JSON.stringify(selectedBook));
    this.props.history.push(`/search?params=${encodedSearchObj}`);
    let event = '';
    if (this.props.mode === 'primary') {
      event = 'more_book_student';
    } else {
      event = 'more_book_teacher';
    }

    const gtmData = {
      dataLayer: {
        event,
        event_category: intl.formatMessage(language.getText('more_book')),
        event_action: intl.formatMessage(language.getText('more_book_clicked')),
        event_label: null,
        user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
        filter_option: (book && book.hasOwnProperty('metadata')) ? `Number of filter options: ${Object.keys(book.metadata).length}` : null,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  };

  bookShelfProps = {};

  componentDidMount() {
    const myClass = document.getElementsByClassName('cancelButton');
    if (myClass.addEventListener) {
      myClass.addEventListener('keydown', () => {
        alert('ManiKandanDidMount');
      }, false);
    }
    const { dlBooks } = this.props;
    window.onunload = function () {
      localStorage.removeItem('restrictedProductCall');
      // DataFormatter.removeObjectInStorage('restrictedProductCall');
    };

    Framework.getEventManager().on(constants.DL_BOOKS_FETCHED, (BookList) => {
      //  Booklist has been fetched
      this.updateProductsFetched(BookList);
    });
    Framework.getEventManager().on(constants.DL_BOOKS_ERROR, () => {
      // There is a failiure to Booklist
    });
    Framework.getEventManager().on(constants.DL_BOOKS_EMPTY, () => {
      // Booklist returned in empty
    });

    Framework.getEventManager().on(constants.SEND_BOOK_SUCCESS, (bookProvisioned) => {
      // Sending books to students success
      this.showSnackBar(bookProvisioned);
    });
    Framework.getEventManager().on(constants.SEARCH_PRODUCTS_SUCCESS, (searchData) => {
      this.setState({ productListData: searchData }, function () { this.updateSearchProductsFetched(); });
    });
    // Search Product
    //    Framework.getEventManager().on(constants.SEARCH_PRODUCTS_SUCCESS,(searchData) => {
    //     this.updateSearchProductsFetched(searchData);
    //  });

    // fetch-product-by-school-id
    //    Framework.getEventManager().on(constants.PRODUCTS_FETCHED_SUCCESS,(fetchProductBySchoolId) => {
    //     this.updateProductsFetched();
    //  });
    // GlsGetProductsListForAutoComplete - updateProductsListFetchedForAutoComplete
    Framework.getEventManager().on(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, (ProductsListForAutoComplete) => {
      this.updateProductsListFetchedForAutoComplete(ProductsListForAutoComplete);
    });

    // searchForRelatedProducts
    Framework.getEventManager().on(constants.RELATED_PRODUCTS_FETCHED_SUCCESS, (searchForRelatedProducts) => {
      this.loadRelatedProducts(searchForRelatedProducts);
    });

    // FetchProductByUserId
    Framework.getEventManager().on(constants.USER_PRODUCTS_FETCHED_SUCCESS, (respData) => {
      // this.updateUserProducts(respData);
      this.setState({ productListData: respData }, function () { this.updateUserProducts(); });
    });

    // FetchSearchUserProducts
    Framework.getEventManager().on(constants.FETCH_USER_PRODUCTS_SUCCESS, (respData) => {
      // this.updateAutoCompleteSearchUserProductsFetched(respData);
      this.setState({ productListData: respData }, function () { this.updateAutoCompleteSearchUserProductsFetched(); });
    });
    Framework.getEventManager().on(constants.FETCH_USER_PRODUCTS_SUCCESS, (respData) => {
      this.setState({ productListData: respData }, function () { this.updateSearchUserProductsFetched(false); });
      // this.updateSearchUserProductsFetched(false);
    });

    const mainAppContainerElement = document.getElementById('main');
    if (mainAppContainerElement) {
      mainAppContainerElement.addEventListener('scroll', this.handleScroll);
    }
    window.addEventListener('resize', this.handleResize);

    DataFormatter.setObjectInStorage('currentTab', 'school-library');
    const currentTab = DataFormatter.getObjectInStorage('currentTab');
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(
      appConfig.substring(
        appConfig.indexOf('{'),
        appConfig.lastIndexOf('}') + 1
      )
    );
    const dashboardConfig = appConfig.dashboard;
    let showKeywordSearch = false;
    let showFilterSearch = false;
    if (dashboardConfig && dashboardConfig.sections instanceof Array) {
      for (const sectionItem of dashboardConfig.sections) {
        if (sectionItem.isEnabled && sectionItem.tag === currentTab) {
          if (sectionItem.showKeywordSearch.includes(role)) {
            showKeywordSearch = true;
          }
          if (sectionItem.showFilterSearch.includes(role)) {
            showFilterSearch = true;
          }
        }
      }

      this.setState({
        showKeywordSearch,
        showFilterSearch
      });
    }
    const searchState = DataFormatter.getObjectInSessionStorage('searchState');
    const path = window.location.search;
    if (path != '') {
      const queryParam = CommonUtils.parseQuery(path);
      if (queryParam != undefined) {
        if (queryParam.hasOwnProperty('params')) {
          // AppActions.searchForRelatedProducts(this.props.schoolId,queryParam.params);
          // searchForRelatedProducts
          Framework.getEventManager().publish(constants.RELATED_PRODUCTS_FETCHED_REQUESTED, { schoolId: this.props.schoolId, filterobject: queryParam.params });
        }
      }
    } else if (searchState) {
      // do nothing
    } else if (this.props.schoolId) {
      this.setState(
        {
          productFetchInProgress: true
        },
        () => {
          if (this.props.mode === 'primary') {
            // AppActions.fetchProductByUserId(this.props.userId, false);
            // fetchProductByUserId
            Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
              {
                userId: this.props.userId,
                includeReturned: false,
                searchlimit: 25,
                offset: 0
              });
          } else if (DataFormatter.getObjectInStorage('restrictedProductCall') != true) {
            const productsLength = !isNaN(this.state.productsLength) ? this.state.productsLength : '';
            // console.log("componentDidMount fetch Start Time: ", new Date(), new Date().getTime());
            Promise.all([
              dlBooks.fetch(
                this.props.schoolId,
                this.state.bookLimitPerFetch,
                productsLength
              )
            ]).then(() => {
              // console.log("componentDidMount fetch End Time: ", new Date(), new Date().getTime());
              // this.updateProductsFetched();
            });

            // AppActions.fetchProductBySchoolId(this.props.schoolId, this.state.bookLimitPerFetch, (!isNaN(this.state.productsLength) ? this.state.productsLength : ''));
            //  fetchProductBySchoolId

            // Framework.getEventManager().publish(constants.PRODUCTS_FETCHED_REQUESTED,{schoolId:this.props.schoolId,searchlimit:this.state.bookLimitPerFetch,offset:productsLength});
          }
        }
      );
    } else {
      this.setState({
        books: []
      });
    }

    const gtmData = CommonUtils.getDefaultGAPageView();
    gtmData.dataLayer.page_title = 'Library';
    TagManager.dataLayer(gtmData);
  }

  componentDidUpdate(prevProps, prevState) {
    const path = window.location.search;
    if (path != '') {
      const queryParam = CommonUtils.parseQuery(path);
      if (queryParam != undefined) {
        if (queryParam.hasOwnProperty('params')) {
          // AppActions.searchForRelatedProducts(this.props.schoolId,queryParam.params);
          // searchForRelatedProducts
          Framework.getEventManager().publish(constants.RELATED_PRODUCTS_FETCHED_REQUESTED, { schoolId: this.props.schoolId, filterobject: queryParam.params });
        }
      }
    }
  }

  componentWillUnmount() {
    //     AppStore.removeListener(
    //       constants.EventTypes.USER_PRODUCTS_FETCHED_SUCCESS,
    //       this.updateUserProducts
    //     );
    //     AppStore.removeListener(
    //       constants.EventTypes.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS,
    //       this.updateProductsListFetchedForAutoComplete
    //     );
    //     AppStore.removeListener(
    //       constants.EventTypes.PRODUCTS_FETCHED_SUCCESS,
    //       this.updateProductsFetched
    //     );
    //     AppStore.removeListener(
    //       constants.EventTypes.SEARCH_PRODUCTS_FETCHED_SUCCESS,
    //       this.updateSearchProductsFetched
    //     );
    //     AppStore.removeListener(
    //       constants.EventTypes.RELATED_PRODUCTS_FETCHED_SUCCESS,
    //       this.loadRelatedProducts
    //     );
    //     AppStore.removeListener(
    //       constants.EventTypes.PRODUCT_PROVISION_COMPLETED,
    //       this.showSnackBar
    //     );
    //     AppStore.removeListener(
    //       constants.EventTypes.SEARCH_USER_PRODUCTS_FETCHED_SUCCESS,
    //       this.updateSearchUserProductsFetched
    //     );
    //     AppStore.removeListener(
    //       constants.EventTypes.USER_PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS,
    //       this.updateAutoCompleteSearchUserProductsFetched
    //     );
    const mainAppContainerElement = document.getElementById('main');
    if (mainAppContainerElement) {
      mainAppContainerElement.removeEventListener('scroll', this.handleScroll);
    }
    skipfetchProductBySchool = false;
    window.removeEventListener('resize', this.handleResize);
  }

  getTitle = (book, productItem) => productItem.title;

  refineServiceUserProductData = function (productItemArg, idxArg) {
    return this.refineServiceProductData(productItemArg, idxArg, true);
  };

  refineServiceProductData = function (productItemArg, idx, isUserProduct) {
    const { language, intl } = this.props;
    let productItem;
    if (isUserProduct) {
      productItem = productItemArg.productId;
    } else {
      productItem = productItemArg;
    }
    let refinedData = {};
    const userRole = DataFormatter.getKeyFromObject('userInformation', 'role');
    const bookArray = productItem.books;
    try {
      if (bookArray.length == 0) {
        return false;
      }
      if (bookArray.length == 1) {
        refinedData = {
          bookId: bookArray[0].id,
          assetid: bookArray[0].id,
          coverImageURL: bookArray[0].metadata.coverImageUrl,
          bookName: this.getTitle(bookArray[0].metadata, productItem), // bookArray[0].metadata.title?bookArray[0].metadata.title:productItem.title,
          assetUrl: bookArray[0].metadata.url,
          type: bookArray[0].metadata.type,
          readingLevelText: (bookArray[0].metadata.phonicsFilter) ? intl.formatMessage(language.getText('decodable_set')) : intl.formatMessage(language.getText('Reading_Level'))
        };
      } else if (bookArray.length > 1) {
        const book1Metadata = bookArray[0].metadata || '';
        const book2Metadata = bookArray[1].metadata || '';
        if (book1Metadata != '') {
          var book1Role = book1Metadata.role || '';
        } else {
          var book1Role = '';
        }
        if (book2Metadata != '') {
          var book2Role = book2Metadata.role || '';
        } else {
          var book2Role = '';
        }
        if (String(book1Role) === String(userRole)) {
          refinedData = {
            bookId: bookArray[0].id,
            assetid: bookArray[0].id,
            coverImageURL: book1Metadata.coverImageUrl,
            bookName: this.getTitle(book1Metadata, productItem), // book1Metadata.title?book1Metadata.title:productItem.title,
            assetUrl: book1Metadata.url,
            type: book1Metadata.type
          };
        } else if (String(book2Role) === String(userRole)) {
          refinedData = {
            bookId: bookArray[1].id,
            assetid: bookArray[0].id,
            coverImageURL: book2Metadata.coverImageUrl,
            bookName: this.getTitle(book2Metadata, productItem), // book2Metadata.title?book2Metadata.title:productItem.title,
            assetUrl: book2Metadata.url,
            type: book2Metadata.type
          };
        } else {
          refinedData = {
            bookId: bookArray[0].id,
            assetid: bookArray[0].id,
            // coverImageURL: book1Metadata.coverImageUrl?book1Metadata.title:productItem.title,
            coverImageURL: book1Metadata.coverImageUrl,
            bookName: this.getTitle(book1Metadata, productItem), // book1Metadata.title,
            assetUrl: book1Metadata.url,
            type: book1Metadata.type
          };
        }
      }
      refinedData.author = productItem.author;
      refinedData.productId = productItem.id;
      refinedData.metadata = {};
      if (productItem.metadata) {
        if (productItem.metadata.readingLevels) {
          refinedData.metadata.readingLevelText = (productItem.metadata.phonicsFilter) ? intl.formatMessage(language.getText('decodable_set')) : intl.formatMessage(language.getText('Reading_Level'));
          if (productItem.metadata.readingLevels instanceof Array) {
            refinedData.metadata.readingLevels = (productItem.metadata.phonicsFilter) ? (productItem.metadata.phonicsFilter[0]) : productItem.metadata.readingLevels[0];
          } else {
            refinedData.metadata.readingLevels = (productItem.metadata.phonicsFilter) ? productItem.metadata.phonicsFilter.toString() : productItem.metadata.readingLevels.toString();
          }
          refinedData.metadata.readingLevelsForFilter = productItem.metadata.readingLevels;
        }
        if (productItem.metadata.information) {
          refinedData.metadata.information = productItem.metadata.information.toString();
        }
        if (productItem.metadata.activities) {
          refinedData.metadata.activities = productItem.metadata.activities;
        }
        if (productItem.metadata.eCommLink) {
          refinedData.metadata.eCommLink = productItem.metadata.eCommLink[0];
        }
        if (productItem.metadata.teachingNotes) {
          refinedData.metadata.teachingNote = productItem.metadata.teachingNotes[0];
        }
        if (productItem.metadata.Resources) {
          refinedData.metadata.resources = productItem.metadata.Resources[0];
        }
        if (productItem.metadata.LessonPlan) {
          refinedData.metadata.lessonPlans = productItem.metadata.LessonPlan[0];
        }
        if (productItem.metadata.topicsOfInterest) {
          refinedData.metadata.topicsOfInterest = productItem.metadata.topicsOfInterest;
        }
      }
      if (this.props.schoolId) {
        refinedData.schoolId = this.props.schoolId;
      }
      const userIdTemp = DataFormatter.getKeyFromObject(
        'userInformation',
        'id'
      );
      if (userIdTemp) {
        refinedData.userId = userIdTemp;
      }

      const bookLayoutDetails = DataFormatter.getObjectInStorage(
        'bookLayoutDetails'
      )
        ? DataFormatter.getObjectInStorage('bookLayoutDetails')
        : {};
      const bookLevelSettingIcon = DataFormatter.getObjectInStorage(
        'bookLevelSettingIcon'
      )
        ? DataFormatter.getObjectInStorage('bookLevelSettingIcon')
        : {};
      if (
        productItem.layout.indexOf('portrait_single_page') > -1
        && productItem.layout.indexOf('landscape_single_page') > -1
      ) {
        bookLayoutDetails[productItem.id] = constants.SINGLE_PAGE;
        bookLevelSettingIcon[productItem.id] = false;
        if (
          productItem.layout.indexOf('portrait_double_page') > -1
          || productItem.layout.indexOf('landscape_double_page') > -1
        ) {
          bookLayoutDetails[productItem.id] = constants.DOUBLE_PAGE;
          bookLevelSettingIcon[productItem.id] = true;
        }
        DataFormatter.setObjectInStorage(
          'bookLayoutDetails',
          bookLayoutDetails
        );
        DataFormatter.setObjectInStorage(
          'bookLevelSettingIcon',
          bookLevelSettingIcon
        );
      } else {
        bookLayoutDetails[productItem.id] = constants.DOUBLE_PAGE;
        bookLevelSettingIcon[productItem.id] = true;
        DataFormatter.setObjectInStorage(
          'bookLayoutDetails',
          bookLayoutDetails
        );
        DataFormatter.setObjectInStorage(
          'bookLevelSettingIcon',
          bookLevelSettingIcon
        );
      }
      return refinedData;
    } catch (e) {
      console.log(e);
    }
  };

  updateProductsFetched = function () {
    let bookitem;
    let booksList;
    let productList;
    const productListData = this.props.dlBooks.items;
    if (!(productListData instanceof Array && productListData.length > 0)) {
      productList = null;
      // Explictly assign hard coded productlist
      productList = booksListData;
    } else {
      productList = productListData;
    }

    booksList = [];
    if (productList instanceof Array) {
      for (var i = 0, iLen = productList.length; i < iLen; i++) {
        bookitem = this.refineServiceProductData(productList[i], i);
        if (bookitem !== false) {
          booksList.push(bookitem);
        }
      }
    }

    let updatedBookList = [];
    if (this.state.books instanceof Array && this.state.books.length > 0) {
      if (!this.state.reloadSchoolLib) {
        updatedBookList = updatedBookList.concat(this.state.books);
      }
    }
    try {
      const elements = $('.baacTextBookItem');
      for (var i = 0; i < elements.length; i++) {
        elements[i].children[0].style.border = 'none';
      }
    } catch (e) {}
    updatedBookList = updatedBookList.concat(booksList);
    updatedBookList = this.retainBooksSelection(updatedBookList);
    updatedBookList = this.getAlreadyAssignedBooks(updatedBookList);

    const productsLength = !isNaN(this.state.productsLength) ? this.state.productsLength + productList.length : productList.length;
    const lazyLoadingApplicable = this.isLazyLoadingApplicable(productsLength);

    this.setState(
      {
        books: updatedBookList,
        booksViewMode: 'viewAll',
        productsLength,
        lazyLoadingApplicable,
        bookshelfDataLoaded: true,
        productFetchInProgress: false,
        isLoading: false,
        reloadSchoolLib: false
      },
      function () {
        DataFormatter.setObjectInStorage('BookshelfData', this.state.books);
        DataFormatter.setObjectInStorage(
          'BookshelfProductsLength',
          this.state.productsLength
        );
      }
    );
  };

  isLazyLoadingApplicable = function (newLength, notConsiderBooksListInState) {
    let lazyLoadingApplicable = false;
    if (
      newLength >= this.state.bookLimitPerFetch
      && newLength % this.state.bookLimitPerFetch == 0
    ) {
      lazyLoadingApplicable = true;
    }
    if (
      notConsiderBooksListInState !== true
      && this.state.productsLength >= newLength
    ) {
      lazyLoadingApplicable = false;
    }
    return lazyLoadingApplicable;
  };

  updateSearchProductsFetched = function () {
    this.updateProductsFetchUpdate();
  };

  updateProductsFetchUpdate = function (
    callback,
    bookListDataArg,
    productLengthArg

  ) {
    let bookitem;
    let booksList;
    let productList;
    const dataStoreAutoComplete = [];
    // const productListData = AppStore.productListData;
    const productListData = this.state.productListData;
    let productsLength;
    booksList = [];
    if (bookListDataArg) {
      booksList = bookListDataArg;
      productsLength = productLengthArg;
    } else {
      if (!(productListData instanceof Array && productListData.length > 0)) {
        productList = null;
      } else {
        productList = productListData;
      }

      if (productList instanceof Array) {
        for (var i = 0, iLen = productList.length; i < iLen; i++) {
          bookitem = this.refineServiceProductData(productList[i], i);
          if (bookitem !== false) {
            booksList.push(bookitem);
          }
        }
      }
      var updatedBookList = [];

      if (
        !this.state.searchPerformed
        && this.state.books instanceof Array
        && this.state.books.length > 0
      ) {
        updatedBookList = updatedBookList.concat(this.state.books);
      }
      if (!this.state.searchPerformed) {
        productsLength = !isNaN(this.state.productsLength)
          ? this.state.productsLength + (productList ? productList.length : 0)
          : productList
            ? productList.length
            : 0;
      } else {
        productsLength = productList ? productList.length : 0;
      }
    }
    try {
      const elements = $('.baacTextBookItem');
      for (var i = 0; i < elements.length; i++) {
        elements[i].children[0].style.border = 'none';
      }
    } catch (e) { }

    updatedBookList = updatedBookList.concat(booksList);
    updatedBookList = this.retainBooksSelection(updatedBookList);
    updatedBookList = this.getAlreadyAssignedBooks(updatedBookList);
    if (!this.state.showKeywordSearch) {
      let minHeightValue;
      if (this.props.mode === 'primary') {
        minHeightValue = '700px';
      } else {
        minHeightValue = '600px';
      }
      document.querySelector('.plain_container').style.minHeight = minHeightValue;
      document.querySelector('.plain_container').style.backgroundColor = '#f8f8f8';
    }

    const lazyLoadingApplicable = this.isLazyLoadingApplicable(productsLength);
    const booksSelected = DataFormatter.getObjectInSessionStorage(
      'booksSelectedByTeacher'
    )
      ? DataFormatter.getObjectInSessionStorage('booksSelectedByTeacher')
      : [];
    this.setState(
      {
        books: updatedBookList,
        booksViewMode: 'viewAll',
        productsLength,
        lazyLoadingApplicable,
        searchPerformed: false,
        searchOccured: true,
        bookshelfDataLoaded: true,
        productFetchInProgress: false,
        isLoading: false,
        booksSelected
      },
      function (callback, scrollToTop) {
        this.forceUpdate();
        this.handleRemoveClass();
        if (scrollToTop) {
          const mainAppContainerElement = document.getElementById('main');
          if (mainAppContainerElement) {
            mainAppContainerElement.scrollTop = 0;
            /* var bookshelf_wrapper = mainAppContainerElement.querySelector('.bookshelf_wrapper');
                  if(bookshelf_wrapper){
                    var bound = bookshelf_wrapper.getBoundingClientRect();
                    mainAppContainerElement.scrollTop = mainAppContainerElement.scrollTop+bound.top;
                  } */
          }
          // bookshelf_wrapper
        }
        if (typeof callback === 'function') {
          callback(this.state.productsLength);
        }
      }.bind(
        this,
        callback,
        this.state.searchPerformed || typeof bookListDataArg !== 'undefined'
      )
    );
    // Changes for 357
    DataFormatter.setObjectInStorage('BookshelfData', updatedBookList);
    //  DataFormatter.setObjectInStorage('BookshelfData', this.state.books);
  };

  updateProductsListFetchedForAutoComplete = function (callback, ProductsListForAutoComplete) {
    let bookitem;
    let booksList;
    let productList;
    const dataStoreAutoComplete = [];
    // const productListData = AppStore.productListAutoCompleteData;
    const productListData = ProductsListForAutoComplete;
    if (!(productListData instanceof Array && productListData.length > 0)) {
      productList = null;
    } else {
      productList = productListData;
    }

    booksList = [];
    if (productList instanceof Array) {
      for (let i = 0, iLen = productList.length; i < iLen; i++) {
        bookitem = this.refineServiceProductData(productList[i], i);
        if (bookitem !== false) {
          dataStoreAutoComplete.push(bookitem.bookName);
        }
      }
    }

    this.setState(
      {
        dataStoreAutoComplete
      },
      function (callback) {
        this.forceUpdate();
        if (typeof callback === 'function') {
          callback();
        }
      }.bind(this, callback)
    );
  };

  getProductsListForAutoComplete = function (searchKeyword) {
    if (searchKeyword !== null) {
      this.setState(
        {
          booksSelected: [],
          searchCount: this.state.searchCount + 1
        },
        function (searchKeyword) {
          let searchObjectArray = [];
          if (typeof searchKeyword === 'string') {
            searchObjectArray = {
              title: searchKeyword
            };
          } else {
            searchObjectArray = searchKeyword[0];
          }
          // AppActions.GlsGetProductsListForAutoComplete(this.props.schoolId,searchObjectArray);
          Framework.getEventManager().publish(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_REQUESTED, { schoolId: this.props.schoolId, searchObj: searchObjectArray });
        }.bind(this, searchKeyword)
      );
    } else {
    }
  };

  searchBookshelf = function (searchKeyword, delay, reloadSchoolLib) {
    if (!isNaN(delay)) {
      this.setState(
        {
          isLoading: true
        },
        function (searchKeywordArg1) {
          setTimeout(
            function (searchKeywordArg2) {
              this.searchBookshelfCall(searchKeywordArg2);
            }.bind(this, searchKeywordArg1)
          );
        }.bind(this, searchKeyword)
      );
    } else {
      this.searchBookshelfCall(searchKeyword, reloadSchoolLib);
    }
  };

  handleRemoveClass = () => {
    if (this.state.books != null) {
      setTimeout(() => {
        let filterOpenStatus = document.getElementById('searchConsoleFilter');
        let textBookWrapComponent = document.getElementsByClassName('baacTextBookWrapComponent').length;
        textBookWrapComponent = textBookWrapComponent > 0 ? document.getElementsByClassName('baacTextBookWrapComponent')[0].getBoundingClientRect().height : 0;
        filterOpenStatus = filterOpenStatus !== null ? !!filterOpenStatus.classList.contains('hidden') : false;
        if (this.state.books.length === 0 && filterOpenStatus) {
          if (document.getElementsByClassName('baacTextBookWrapComponent')[0] && document.getElementsByClassName('baacTextBookWrapComponent')[0].classList != undefined) {
            document.getElementsByClassName('baacTextBookWrapComponent')[0].classList.remove('baacTextBookWrapComponentCount');
          }
        } else if (
          this.state.books.length > 0
          || (this.state.books.length === 0 && !filterOpenStatus)
        ) {
          if (document.getElementsByClassName('baacTextBookWrapComponent')[0] && document.getElementsByClassName('baacTextBookWrapComponent')[0].classList != undefined) {
            document.getElementsByClassName('baacTextBookWrapComponent')[0].classList.add('baacTextBookWrapComponentCount');
          }
        } else if (document.getElementsByClassName('baacTextBookWrapComponent')[0] && document.getElementsByClassName('baacTextBookWrapComponent')[0].classList != undefined) {
          document.getElementsByClassName('baacTextBookWrapComponent')[0].classList.remove('baacTextBookWrapComponentCount');
        }
      }, 1000);
    }
  };

  searchBookshelfCall = function (searchKeyword, reloadSchoolLib) {
    const { dlBooks } = this.props;
    const booksSelectedByTeacher = DataFormatter.getObjectInSessionStorage(
      'booksSelectedByTeacher'
    )
      ? DataFormatter.getObjectInSessionStorage('booksSelectedByTeacher')
      : [];
    if (searchKeyword !== null) {
      let searchObjectArray = [];
      if (typeof searchKeyword === 'string') {
        searchObjectArray = {
          title: searchKeyword
        };
      } else {
        searchObjectArray = searchKeyword[0];
      }
      // if(searchObjectArray){
      //   if(searchObjectArray.metadata && searchObjectArray.metadata.readingLevels){
      //     let tmpArray = [], tmpArray1=[], tmpArray2=[];
      //     for(var i=0; i<searchObjectArray.metadata.readingLevels.length;i++){
      //         if(searchObjectArray.metadata.readingLevels[i] == 'A, 1, 2' ){
      //           tmpArray = searchObjectArray.metadata.readingLevels[i].split(',');
      //           tmpArray.shift();
      //           var index = searchObjectArray.metadata.readingLevels.indexOf('A,1,2');
      //           if (index !== -1) searchObjectArray.metadata.readingLevels.splice(index, 1);
      //           searchObjectArray.metadata.readingLevels.push(...tmpArray);
      //           tmpArray.splice(0, tmpArray.length);
      //         }
      //         if(searchObjectArray.metadata.readingLevels[i] == '6-8'){
      //           tmpArray1 = searchObjectArray.metadata.readingLevels[i].split('-');
      //           tmpArray1 = Array(8).fill(0,5).map((e,i)=>i+1);
      //           var index = searchObjectArray.metadata.readingLevels.indexOf('6-8');
      //           if (index !== -1) searchObjectArray.metadata.readingLevels.splice(index, 1);
      //           searchObjectArray.metadata.readingLevels.push(...tmpArray1);
      //           tmpArray1.splice(0, tmpArray1.length);
      //         }
      //         if (searchObjectArray.metadata.readingLevels[i] == '70-80') {
      //           tmpArray2 = searchObjectArray.metadata.readingLevels[i].split('-');
      //           tmpArray2 = Array(80).fill(0,69).map((e,i)=>i+1);
      //           var index = searchObjectArray.metadata.readingLevels.indexOf('70-80');
      //           if (index !== -1) searchObjectArray.metadata.readingLevels.splice(index, 1);
      //           searchObjectArray.metadata.readingLevels.push(...tmpArray2);
      //           tmpArray2.splice(0, tmpArray2.length);
      //         }
      //     }
      //     var filtered = searchObjectArray.metadata.readingLevels.filter(function (el) {
      //       return el;
      //     });
      //     var finalArray = new Set(filtered);
      //     searchObjectArray.metadata.readingLevels = finalArray;
      //     filtered.splice(0, filtered.length);
      //   }
      // }
      this.setState(
        {
          isLoading: true,
          booksSelected: booksSelectedByTeacher,
          searchCount: this.state.searchCount + 1,
          searchObject: searchObjectArray,
          productsLength: 0,
          searchPerformed: true,
          productFetchInProgress: true,
          searchOccured: true,
          bookLimitPerFetch: 100
        },
        function (searchKeyword) {
          // AppActions.searchProducts(this.props.schoolId,searchObjectArray,100,0);
          // search-product
          Framework.getEventManager().publish(constants.SEARCH_PRODUCTS_REQUESTED, {
            schoolId: this.props.schoolId, searchObj: searchObjectArray, limit: 100, offset: 0
          });
        }.bind(this, searchKeyword)
      );
    } else {
      const refreshData = false;
      this.setState(
        {
          isLoading: true,
          searchCount: 0,
          booksSelected: booksSelectedByTeacher
        },
        (refreshData) => {
          if (this.props.schoolId) {
            this.setState(
              {
                productFetchInProgress: true,
                productsLength: 0,
                reloadSchoolLib: true,
                bookLimitPerFetch: BOOKS_LIMIT_PER_FETCH,
                searchOccured: false
              },
              () => {
                // AppActions.fetchProductBySchoolId(
                //   this.props.schoolId,
                //   this.state.bookLimitPerFetch,
                //   ''
                // );
                // fetch-product-by-school-id
                // Framework.getEventManager().publish(constants.PRODUCTS_FETCHED_REQUESTED,{schoolId:this.props.schoolId,searchlimit:this.state.bookLimitPerFetch,offset: ''});
                //
                // console.log("searchBookshelfCall Start Time: ", new Date(), new Date().getTime());
                Promise.all([
                  dlBooks.fetch(
                    this.props.schoolId,
                    this.state.bookLimitPerFetch,
                    ''
                  )
                ]).then(() => {
                  // console.log("searchBookshelfCall End Time: ", new Date(), new Date().getTime());
                  // this.updateProductsFetched();
                });

                this.handleRemoveClass();
              }
            );
          } else {
            this.setState(
              {
                books: []
              },
              () => {
                this.handleRemoveClass();
              }
            );
          }
        }
      );
    }
  };

  viewAllBooks = () => {
    try {
      document
        .getElementsByClassName('sendBookOption2')[0]
        .classList.add('activeClassForSendBookFooter');
      document
        .getElementsByClassName('sendBookOption3')[0]
        .classList.remove('activeClassForSendBookFooter');
    } catch (e) { }
    const books = this.state.books;
    this.setState({
      books: DataFormatter.getObjectInStorage('BookshelfData'),
      booksViewMode: 'viewAll'
    });
  };

  viewSelectedBooks = () => {
    const active_button = document.querySelector('.sendBookOption2');
    if (active_button.classList.contains('activeClassForSendBookFooter')) {
      DataFormatter.setObjectInStorage('BookshelfData', this.state.books);
      const selectedBooks = DataFormatter.getObjectInSessionStorage(
        'booksSelectedByTeacher'
      );
      try {
        document
          .getElementsByClassName('sendBookOption2')[0]
          .classList.remove('activeClassForSendBookFooter');
        document
          .getElementsByClassName('sendBookOption3')[0]
          .classList.add('activeClassForSendBookFooter');
      } catch (e) {}
      this.setState({
        books: selectedBooks,
        booksSelected: selectedBooks,
        booksViewMode: 'viewSelected'
      });
    }
  };

  sendBooks = () => {
    this.setState({
      handleBookClicked: true
    });
  };

  resetSelectedBooks = (resetBooks) => {
    try {
      const elements = $('.baacTextBookItem');
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.border = 'none';
        elements[i].children[0].style.border = 'none';
      }
    } catch (e) { }
    const allBooks = DataFormatter.getObjectInStorage('BookshelfData');
    for (var i = 0; i < allBooks.length; i++) {
      allBooks[i].isChecked = false;
    }
    DataFormatter.setObjectInStorage('BookshelfData', allBooks);

    if (resetBooks) {
      DataFormatter.setObjectInSessionStorage('booksSelectedByTeacher', []);
    }
    this.setState(
      {
        books: allBooks,
        booksSelected: []
      },
      this.viewAllBooks()
    );
  };

  handleCloseIconEnterKey = function (event) {
    const isTabPressed = event.key === 'Enter' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
    if (!isTabPressed) {
      return;
    }
    if (event.key == 'Enter') {
      // document.getElementsByClassName('selectall-checkbox')[0].focus();
      const baacTextBookThumnailImage = document.getElementsByClassName('baacTextBookThumnailImage')[0];
      baacTextBookThumnailImage.tabIndex = '0';
      // baacTextBookThumnailImage.focus();
      if (baacTextBookThumnailImage) {
        baacTextBookThumnailImage.focus();
        document.getElementsByClassName('baacTextBookThumnailImage')[0].nextSibling.focus();
      }
      if (event.key == 'Enter') {
        this.resetSelectedBooks(true);
      }
    }
  }

  handleStudentsPopupClose() {
    this.setState({
      handleBookClicked: false
    });
  }

  renderChips = () => {
    if (this.state.chipData.length > 0) {
      return (
        <ChipComponent
          chipData={this.state.chipData}
          handleChipDelete={this.handleChipDelete}
        />
      );
    }
    return null;
  };

  handleChipDelete = (data) => {
    console.log(data);
  };

  enableLoading = (callback) => {
    this.setState(
      {
        isLoading: true
      },
      typeof callback === 'function' ? callback : () => { }
    );
  };

  updateAutoCompleteSearchUserProductsFetched = function () {
    this.updateSearchUserProductsFetched(true);
  };

  updateSearchUserProductsFetched = function (isAutoComplete, respData) {
    let bookitem;
    let booksList;
    let productList;
    const dataStoreAutoComplete = [];
    // const productListData = AppStore.productListData;respData
    const productListData = this.state.productListData;
    if (!(productListData instanceof Array && productListData.length > 0)) {
      productList = null;
    } else {
      productList = productListData;
    }

    booksList = [];
    if (productList instanceof Array) {
      let productListItem = null;
      for (let i = 0, iLen = productList.length; i < iLen; i++) {
        productListItem = productList[i];
        bookitem = this.refineServiceUserProductData(productListItem, i);
        if (bookitem !== false) {
          booksList.push(bookitem);
          dataStoreAutoComplete.push(bookitem.bookName);
        }
      }
    }

    const updtState = {
      bookshelfDataLoaded: true,
      lazyLoadingApplicable: false
    };
    if (isAutoComplete) {
      updtState.dataStoreAutoComplete = dataStoreAutoComplete;
    } else {
      updtState.books = booksList;
      updtState.isLoading = false;
    }

    this.setState(updtState, () => {
      this.forceUpdate();
      this.handleRemoveClass();
    });
  };

  handleReturnedFilter = function (searchKeyword, autoComplete) {
    if (this.props.userId) {
      if (!autoComplete) {
        this.setState(
          {
            isLoading: true
          },
          function (searchKeyword) {
            //  AppActions.searchUserProducts(this.props.userId,searchKeyword !== null ? searchKeyword : '','returned');
            (this.state.ReturnedCalled && this.state.provisionResponse) &&
            Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_REQUESTED, {
              userId: this.props.userId,
              searchKeyword: searchKeyword !== null ? searchKeyword : '',
              filterByArg: 'returned'
            });
          }.bind(this, searchKeyword)
        );
      } else {
        // AppActions.searchUserProducts(this.props.userId,searchKeyword !== null ? searchKeyword : '','returned',true);
        Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_REQUESTED, {
          userId: this.props.userId,
          searchKeyword: searchKeyword !== null ? searchKeyword : '',
          filterByArg: 'returned'
        });
      }
    }
  };

  getAlreadyAssignedBooks = (booksList) => {
    const userBookList = DataFormatter.getObjectInStorage('userProductList')
      ? DataFormatter.getObjectInStorage('userProductList')
      : [];
    if (booksList) {
      if (userBookList) {
        if (userBookList.length !== 0) {
          booksList.forEach((book, index) => {
            for (let i = 0; i < userBookList.length; i += 1) {
              if (userBookList[i].productId) {
                const books = userBookList[i].productId.books;
                for (let j = 0; j < books.length; j++) {
                  if (book.bookId === books[j].id) {
                    booksList[index].alreadyAssigned = true;
                    break;
                  }
                }
              }
            }
          });
        }
      }
      return booksList;
    }
  };

  retainBooksSelection = function (booksList) {
    const selectedBooks = DataFormatter.getObjectInSessionStorage(
      'booksSelectedByTeacher'
    )
      ? DataFormatter.getObjectInSessionStorage('booksSelectedByTeacher')
      : [];
    if (booksList) {
      if (selectedBooks) {
        if (selectedBooks.length !== 0) {
          booksList.forEach((book, index) => {
            for (let i = 0; i < selectedBooks.length; i += 1) {
              if (book.bookId === selectedBooks[i].bookId) {
                booksList[index].isChecked = true;
                break;
              }
            }
          });
        }
      }
      return booksList;
    }
  };

  resizeBaacWindow = function (resize) {
    this.setState({ resizeBaacWindow: resize });
  };

  getSelectionReachedMessage = () => {
    const { language, intl } = this.props;
    const viewportOffset = document.getElementById('main').getBoundingClientRect();
    const left = (viewportOffset.width - 436) / 2;
    const style = { top: '40%', left: `${left}px` };

    if (this.state.selectionLimitReached) {
      return (
        <div className="fixedBackground">
          <div className="selectionReachedContainer" style={style}>
            <div className="selectionReachedHeader">
              {intl.formatMessage(language.getText('selection_reached_header'))}
            </div>
            <div className="selectionReachedMessage">
              {intl.formatMessage(language.getText('selection_reached_message'))}
            </div>
            <Button
              variant="contained"
              onClick={this.closeSelectionMessage.bind(this)}
              primary
              labelStyle={{ lineHeight: 2.4 }}
              buttonStyle={{ backgroundColor: '#00857f' }}
              style={styles.okbuttonStyle}
            >
              {intl.formatMessage(language.getText('okButtonLabel'))}
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  closeSelectionMessage = () => {
    this.selectSearchedBooks();
    this.setState({
      selectionLimitReached: false
    });
  };

  render() {
    const { language, intl } = this.props;
    const that = this;
    let shelfComponent;
    let searchConsole;
    let headingElement = null;
    const showingSendBookFooter = !!(
      this.state.booksSelected.length > 0 && !this.state.flipSend
    );
    let searchConsoleStyle = {};
    let bookWrapperStyle = {};
    if (this.props.mode === 'primary') {
      this.intializePrimaryProps();
      if (this.state.showKeywordSearch) {
        if (this.props.mode !== 'primary') {
          searchConsoleStyle = { height: '248px' };
        }
      } else {
        bookWrapperStyle = { paddingTop: '30px', paddingBottom: '120px' };
      }
      const primaryFilterOptions = [
        { value: 'all', displayValue: 'All' },
        { value: 'returned', displayValue: 'Returned' }
      ];
      if (this.state.showFilterSearch) {
        primaryFilterOptions.push({
          value: 'filter',
          displayValue: 'Find Books',
          displayType: 'icon'
        });
      }
      shelfComponent = <TextbookShelf {...primaryBookShelfProps} />;
      headingElement = (
        <div style={styles.primaryHeadingStyles}>
          {intl.formatMessage(language.getText('school_library'))}
        </div>
      );
      searchConsole = (
        <PrimaryRefineSearchConsole
          // handleScrollWithRecords={this.handleRemoveClass}
          headingElement={headingElement}
          defaultPrimaryFilter="all"
          primaryFilterOptions={primaryFilterOptions}
          mode={this.props.mode}
          handleSearch={this.searchBookshelf.bind(this)}
          dataStoreAutoComplete={this.state.dataStoreAutoComplete}
          enableLoading={this.enableLoading}
          handleReturnedFilter={this.handleReturnedFilter}
          resizeBaacWindow={this.resizeBaacWindow.bind(this)}
          parentComponent="schoolLibrary"
          showKeywordSearch
          showFilterSearch={this.state.showFilterSearch}
        />
      );
    } else {
      this.intializeSecondaryProps();
      searchConsoleStyle = { height: '108px' };
      shelfComponent = <TextbookShelf {...secondaryBookShelfProps} />;
      headingElement = (
        <FormattedMessage {...language.getText('school_library')} />
      );
      searchConsole = (
        <SecondaryRefineSearchConsole
          headingElement={headingElement}
          defaultPrimaryFilter="all"
          primaryFilterOptions={[
            { value: 'all', displayValue: 'All' },
            { value: 'returned', displayValue: 'Returned' }
          ]}
          mode={this.props.mode}
          handleSearch={this.searchBookshelf.bind(this)}
          showingSendBookFooter={showingSendBookFooter}
          booksViewMode={this.state.booksViewMode}
          books={this.state.books}
          selectAllSearchedBooks={this.selectAllSearchedBooks}
          dataStoreAutoComplete={this.state.dataStoreAutoComplete}
          // enableLoading={this.enableLoading}
          resizeBaacWindow={this.resizeBaacWindow.bind(this)}
          showKeywordSearch
          showFilterSearch={this.state.showFilterSearch}
        />
      );
    }
    let viewAllStyle = 'sendBookOption2';
    let viewSelectedStyle = 'sendBookOption3';
    if (this.state.booksViewMode === 'viewAll') {
      // viewSelectedStyle = viewSelectedStyle + " activeClassForSendBookFooter";
      viewAllStyle += ' activeClassForSendBookFooter';
    } else {
      // viewAllStyle = viewAllStyle + " activeClassForSendBookFooter";
      viewSelectedStyle += ' activeClassForSendBookFooter';
    }

    return (
      <div
        className="plain_container app_body_container school_library_container"
        style={this.props.masterStyle ? this.props.masterStyle : {}}
      >
        <LoadingOverlay isLoading={this.state.isLoading} />
        {this.renderChips()}
        <div
          className={`search_console_wrapper ${this.props.mode === 'primary' ? ' primary' : ' secondary'
          }`}
          style={searchConsoleStyle}
        >
          {searchConsole}
        </div>
        {this.getSelectionReachedMessage()}

        <div
          id="bookshelfWrapper"
          style={bookWrapperStyle}
          className={`bookshelf_wrapper${this.props.mode === 'secondary' ? ' secondaryBookWrapper' : ''
          }`}
        >
          {this.state.bookshelfDataLoaded ? shelfComponent : null}
        </div>

        <div
          className={`footerForSendBook ${this.props.mode != 'primary'
              && this.state.booksSelected.length > 0
              && !this.state.flipSend
            ? 'showSendBooksFooter'
            : ''
          }`}
        >
          <div className="sendBookOptions">
            <div className="sendBookOption1">
              <span style={{ fontWeight: 'bold' }} aria-live="polite">
                {`${this.state.booksSelected.length} `}
                <span style={{ fontWeight: 'normal' }}>
                  <FormattedMessage {...language.getText('items_selected')} />
                </span>
              </span>
            </div>
            <div
              tabIndex="0"
              className={viewAllStyle}
              onClick={this.viewAllBooks.bind(this)}
              onKeyDown={(event) => {
                if (event.key == 'Enter') {
                  that.viewAllBooks.call(that);
                }
              }}
            >
              <FormattedMessage {...language.getText('view_all')} />
            </div>
            <div
              tabIndex="0"
              className={viewSelectedStyle}
              onClick={this.viewSelectedBooks.bind(this)}
              onKeyDown={(event) => {
                if (event.key == 'Enter') {
                  that.viewSelectedBooks.call(that);
                }
              }}
            >
              <FormattedMessage {...language.getText('view_selected')} />
            </div>
          </div>
          <div className="sendBookActions">
            <div className="submitButton">
              <Button
                variant="contained"
                tabIndex="0"
                onClick={this.sendBooks.bind(this)}
                primary
                style={{ color: '#fff', backgroundColor: '#007fa3' }}
                labelStyle={{
                  fontSize: '16px',
                  fontWeight: '600',
                  fontFamily: 'Open Sans'
                }}
              >
                <FormattedMessage
                  {...language.getText('send_books_button_label')}
                />
              </Button>
            </div>
            <div className="cancelButton">
              <Button
                variant="contained"
                tabIndex="0"
                onKeyDown={this.handleCloseIconEnterKey.bind(this)}
                onClick={this.resetSelectedBooks.bind(this, true)}
                primary
                style={{ color: '#007fa3', backgroundColor: '#fff' }}
                labelStyle={{
                  fontWeight: '600',
                  fontSize: '16px',
                  fontFamily: 'Open Sans'
                }}
              >
                <FormattedMessage
                  {...language.getText('cancel_button_label')}
                />
              </Button>
            </div>
          </div>
        </div>
        {this.handleAddbookPopup()}
        {(this.state.handleBookClicked ? <Popup schoolId={this.props.schoolId} bookSelected={this.state.bookSelected} booksSelected={this.state.booksSelected} handleClose={this.handleStudentsPopupClose.bind(this)} /> : '')}
        <Snackbar
          open={this.state.openSnackBar}
          message={this.state.snackBarMessage ? this.state.snackBarMessage : ''}
          autoHideDuration={3000}
          onClose={this.handleRequestClose}
          contentStyle={{ textAlign: 'center', fontFamily: 'Open Sans' }}
        />
      </div>
    );
  }
}

SchoolLibrary.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired,
  dlBooks: shapes.modelOf(Books).isRequired
};

// // SchoolLibrary.contextTypes= {
// //   router: React.PropTypes.object.isRequired
// // }

export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language', 'dlBooks'),
  observer
)(injectIntl(SchoolLibrary));
