import { STUDENT } from '../constants';
import DataFormatter from '../../../common/utils/DataFormatter';
import { QA_CLIENT_ID, STUDENT_CLIENT_ID } from '../../../common/constants';

export default class AuthHandler {
  get() {
    const authHandler = {
      getTokenConfig: () => {
        const courseSelected = DataFormatter.getObjectInStorage('courseSelected');
        const _token = {
          id: '',
          type: 'PI',
          token: localStorage.getItem('usertype') == 'student' ? localStorage.getItem(`${STUDENT_CLIENT_ID}.PiAccessToken`) : localStorage.getItem(`${QA_CLIENT_ID}.PiAccessToken`),
          callApigee: false,
          userRole: courseSelected && courseSelected.roleValue ? courseSelected.roleValue : STUDENT
        };
        return _token;
      },
      getToken: () => new Promise((resolve) => {
        window.piSession.getToken((status, accessToken) => {
          const courseSelected = DataFormatter.getObjectInStorage('courseSelected');
          resolve({
            id: '',
            type: 'PI',
            token: localStorage.getItem('usertype') == 'student' ? localStorage.getItem(`${STUDENT_CLIENT_ID}.PiAccessToken`) : localStorage.getItem(`${QA_CLIENT_ID}.PiAccessToken`),
            callApigee: false,
            userRole: courseSelected && courseSelected.roleValue ? courseSelected.roleValue : STUDENT
          });
        });
      }),
      getUserId: () => localStorage.getItem('userId'),
      getGabUserId: () => localStorage.getItem('userId'),
      onError: () => { },
      getCdnToken: () => { },
      getHawkConfig: () => {
        const hawkConfig = {
          hawkId: '',
          hawkKey: ''
        };
        return hawkConfig;
      }
    };
    return authHandler;
  }
}
