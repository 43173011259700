/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx State Asset Details
 *
 * @author Sarathkumar Venkatachalam
 */

import { types, applySnapshot } from 'mobx-state-tree';

const Assets = types.model('Assets', {
  id: types.optional(types.string, ''),
  skills: types.array(types.string)
});

const AssetDetails = types.model('AssetDetails', {
  assets: types.optional(types.array(Assets), []),
  hasNext: types.optional(types.boolean, false),
  next: types.optional(types.string, ''),
  total: types.optional(types.number, 0)
}).actions(self => ({
  set(data) {
    applySnapshot(self, data);
  },
  clear() {
    applySnapshot(self, {});
  }
}));

export default AssetDetails;
