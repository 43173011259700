import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import DataFormatter from '../../../common/utils/DataFormatter';

// let lang = DataFormatter.getLanguage()

class FooterSnackbar extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    language: PropTypes.object.isRequired
  };
  // userName = DataFormatter.getKeyFromObject('userInformation', 'id');

  // isCWTUser = DataFormatter.getKeyFromObject('cwtUserDetails', 'isCWTUser')

  state = {
    isActive: true
  };

  componentDidMount() {
    localStorage.setItem(
      'userName',
      DataFormatter.getKeyFromObject('userInformation', 'id')
    );
  }

  closeSnackbar = () => {
    this.setState({ isActive: false });
  };

  renderLegalDocsLinks = () => {
    const { language, intl } = this.props;
    const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
    const isCWTUser = !!(
      iesUserDetails
      && iesUserDetails.identity.homeCountryCode.toLowerCase() == 'ca'
    );
    const school = DataFormatter.getKeyFromObject('userInformation', 'schools');
    let termsOfUseURL = '';
    let cookiePolicyURL = '';
    let privacyURL = '';
    let copyright = '';
    try {
      termsOfUseURL = school[0] ? school[0].links.terms : '';
      cookiePolicyURL = school[0] ? school[0].links.cookie : '';
      privacyURL = school[0] ? school[0].links.privacy : '';
      // const termsOfUseURL = 'https://www.pearson.com/ca/en/legal/terms-of-use.html'
      // const privacyURL = 'https://www.pearson.com/ca/en/legal/privacy-statement.html'
      // const cookiePolicyURL = 'https://www.pearson.com/corporate/cookies.html'
      copyright = 'https://www.pearson.com/ca/en/legal/copyright.html';
    } catch (e) {}
    return (
      <p>
        {intl.formatMessage(language.getText('continue_declaration'))}
        &nbsp;
        <a href={termsOfUseURL} target="_blank">
          {intl.formatMessage(language.getText('terms'))}
        </a>
        ,&nbsp;
        <a href={privacyURL} target="_blank">
          {intl.formatMessage(language.getText('privacy'))}
        </a>
        ,&nbsp;
        {isCWTUser ? (
          <a href={copyright} target="_blank">
            {intl.formatMessage(language.getText('copy_right'))}
          </a>
        ) : (
          ''
        )}
        {' '}
        {intl.formatMessage(language.getText('and_use_of'))}
        &nbsp;
        <a href={cookiePolicyURL} target="_blank">
          {intl.formatMessage(language.getText('cookies'))}
        </a>
      </p>
    );
  };

  render() {
    const { language, intl } = this.props;
    const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
    const isCWTUser = !!(
      iesUserDetails
      && iesUserDetails.identity.homeCountryCode.toLowerCase() == 'ca'
    );
    if (this.state.isActive && localStorage.getItem('userName') === null) {
      return (
        <div className="footer-snackbar snackbar-show">
          {isCWTUser ? (
            <p>
              {intl.formatMessage(
                language.getText('terms_privacy_copy_cookies')
              )}
            </p>
          ) : (
            <p>
              {intl.formatMessage(language.getText('terms_privacy_cookies'))}
            </p>
          )}
          {this.renderLegalDocsLinks()}
          <button onClick={this.closeSnackbar}>
            {intl.formatMessage(language.getText('accept_continue'))}
          </button>
        </div>
      );
    }
    return <div className="snackbar snackbar-hide" />;
  }
}
export default compose(
  inject('language'),
  observer
)(injectIntl(FooterSnackbar));
