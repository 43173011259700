/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Common Application utilities
 *
 * @file CommonUtils.js
 * @author Prithviraj K
 */
import React from 'react';
import Framework from '@greenville/framework';
import { EventProvider } from '@aquila/core';
import {
  deviceType, isChrome, isFirefox, isSafari, isOpera, isIE, isEdge, osName
} from 'react-device-detect';
import { address } from 'ip';
import publicIp from 'public-ip';
import ipaddr from 'ipaddr.js';
import env from '../env';
import PathUtils from './PathUtils';
import AppcuesUtils from './AppcuesUtils';
import * as constants from '../constants';
import { version } from '../../../package.json';
import DataFormatter from './DataFormatter';

export default class CommonUtils {
  static loadUuid = this.getUUID();

  static loadDt = new Date().toISOString();

  static userLocalLoadDt = CommonUtils.getLocalIsoTime();

  static loadTime = new Date().getTime();

  /**
   * Function to Initiate Google Analytics
   */
  // static initializeGTM() {
  //   if (!window.google_tag_manager) {
  //     Framework.getEventManager().publish(constants.GTM_REQUESTED);
  //   }
  // }

  /**
   * Function to lazyload and preload component
   *
   * @param {Module} component
   */
  static lazyWithPreload(component) {
    const lazyComponent = React.lazy(component);
    lazyComponent.preload = component;
    return lazyComponent;
  }

  static tocList(toc, pageId) {
    let found;
    const findAsset = (tocData) => {
      tocData.forEach((child) => {
        if (child.id === pageId) {
          found = child;
        }
        if (child.children) { findAsset(child.children); }
      });
      return found;
    };
    findAsset(toc);
    return found;
  }

  /**
   * Method to track Home Appcues events
   *
   */
  static trackAppcuesHomeEvent(user, bookshelf) {
    let entitlementTier = '';
    let gpsSubscription = '';
    let entitlementLevel = '';
    if (user && user.gpsSubscriptions && user.gpsSubscriptions.length > 0) {
      gpsSubscription = user.gpsSubscriptions.find(subscription => (subscription.status === constants.ACTIVE
        && subscription.entitlementTier));
      gpsSubscription && gpsSubscription.entitlementTier ? entitlementTier = gpsSubscription.entitlementTier : '';
      gpsSubscription && gpsSubscription.entitlementLevel ? entitlementLevel = gpsSubscription.entitlementLevel : '';
    }
    const eventData = {
      eventName: constants.APPCUES_TRACK_OPEN_HOME,
      eventProperties: {
        entitlementLevel,
        pearsonSubscriptionTierCode: entitlementTier,
        bookshelfempty: bookshelf.books && !bookshelf.books.length > 0
      }
    };
    AppcuesUtils.handleEvent(constants.APPCUES_TRACK_EVENT, null, eventData);
  }

  static getBrowserName() {
    let browserName = navigator.appName;
    switch (true) {
      case isChrome:
        browserName = 'Chrome';
        break;
      case isFirefox:
        browserName = 'Firefox';
        break;
      case isSafari:
        browserName = 'Safari';
        break;
      case isOpera:
        browserName = 'Opera';
        break;
      case isIE:
        browserName = 'Internet Explorer';
        break;
      case isEdge:
        browserName = 'Edge';
        break;
      default:
        browserName;
        break;
    }
    return browserName;
  }

  static getBrowserVersion() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[2]] : [navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  static getIpAddressV6 = () => {
    const ip4 = ipaddr.parse(address());
    return ip4.toIPv4MappedAddress().toString();
  };

  static getDeviceType = () => {
    if (deviceType === 'browser') return 'PC';
    return deviceType;
  }

  static pushGlobalParams() {
    return {
      dataLayer: {
        app_id: env.PRODUCT && env.PRODUCT.toUpperCase(),
        app_version: version,
        user_timezone_offset: Math.abs(new Date().getTimezoneOffset() / 60),
        web_browser: this.getBrowserName(), // Browser Name
        web_browser_version: this.getBrowserVersion(), // Browser Version
        device_type: this.getDeviceType(), // Device Type, Ex: PC, Tablet, Phone
        device_id: window.piSession ? window.piSession.getDeviceId() : null,
        operating_system_code: osName
      }
    };
  }

  static initTelemetryPageAction() {
    return EventProvider.getEventManager().dispatch({
      event: constants.TELEMTRY_INIT
    });
  }

  static logUserTelemetryEvent(course, userId) {
    const userParams = {
      person_id: userId,
      person_id_type: constants.ID_TYPE,
      person_role_code: course.role || 'Student',
      login_session_id: window.piSession && window.piSession.getContextId(), // PI Session Id of the logged user
      secondary_person_id: null,
      secondary_person_id_type: null,
      secondary_person_role_code: null,
      organization_id: course.organizationId || null,
      organization_id_type: constants.ORGANIZATION_ID_TYPE
    };
    return EventProvider.getEventManager().dispatch(userParams);
  }

  static logTelemetryPageAction(event) {
    const {
      category,
      action,
      label,
      value
    } = event;
    return EventProvider.getEventManager().dispatch({
      event: constants.TELEMETRY_PAGE_ACTION,
      eventCategory: category || 'NA',
      eventAction: action || 'NA',
      eventLabel: label || 'NA',
      eventValue: value || 'NA',
      transactionDt: new Date().toISOString()
    });
  }

  static logTelemetryPageError(error) {
    const {
      errorCode,
      errorUrl,
      errorMessage,
      errorStateCode,
      errorDetail
    } = error;
    return EventProvider.getEventManager().dispatch({
      event: constants.TELEMETRY_PAGE_ERROR,
      errorCode,
      errorUrl,
      errorMessage,
      errorStateCode,
      errorDetail,
      transactionDt: new Date().toISOString()
    });
  }

  static getUUID() {
    const crypto = window.crypto || window.msCrypto;
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line
      (crypto && (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)));
  }

  static getLocalIsoTime() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    return localISOTime;
  }

  /**
   * Function to load the PLA events
   * @param {object} model - Model
   * @param {string} pageId - pageId
   * @param {Boolean} isFromCourse - Is from course
   * @param {string} previousPageId - pageId
   */

  static sendCommonPLAEvents(model, pageId, isFromCourse, previousPageId, loadType, navData) {
    const product = isFromCourse ? model.getProduct() : model;
    const currentTime = new Date().getTime();
    const durationSeconds = ((currentTime - this.loadTime) / 1000).toFixed(0);
    const unloadUuId = this.loadUuid;
    const updatedLoadId = this.getUUID();
    const unloadDt = this.loadDt;
    const updatedLoadDt = new Date().toISOString();
    const unloadUserLocalLoadDt = this.userLocalLoadDt;
    const updatedUserLocalLoadDt = this.getLocalIsoTime();
    product.setPLAEvents(model, pageId, isFromCourse, previousPageId, durationSeconds, updatedLoadId, updatedLoadDt,
      updatedUserLocalLoadDt, unloadUuId, unloadDt, unloadUserLocalLoadDt, loadType, navData);
    this.loadTime = new Date().getTime();
    this.loadUuid = updatedLoadId;
    this.loadDt = updatedLoadDt;
    this.userLocalLoadDt = updatedUserLocalLoadDt;
  }

  /**
   * Function to set audio  url
   * @param {array} audioUri - File Name
   * @param {string} metaDataVersionId - Version ID
   * @param {string} metaDataAssetTypeUri - Asset Type URL
   * @param {string} metaBasePath - Base Path
   */

  static setUrl(audioUri, metaDataVersionId, metaDataAssetTypeUri, metaBasePath) {
    // TODO: Need to fix it from service end by adding flag for human readable audio
    const audioUrl = audioUri.map(val => (val.startsWith('http') ? `${val}` : `${metaBasePath}${metaDataVersionId}${metaDataAssetTypeUri}${val}`));
    return audioUrl;
  }

  /**
   * Function to set pdf audio url
   * @param {array} audioUri - File Name
   * @param {object} asset - Asset values
   */
  static setPdfAudioUrl(audioUri, asset) {
    const metaBasePath = asset.audioMetadata.basePath;
    const metaDataVersionId = asset.audioMetadata.assetTypes[0].versionId;
    const metaDataAssetTypeUri = asset.audioMetadata.assetTypes[0].uri;
    const pdfAudioUrl = this.setUrl(audioUri, metaDataVersionId, metaDataAssetTypeUri, metaBasePath);

    return pdfAudioUrl;
  }

  /**
   * Function to get cookie token
   * @param {string} cname
   */
  static getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  /**
   * Function to trigger GTM
   * when user clicks on disconnect
   * @param {string} deviceId
   */
  static deviceDisconnectEvent(deviceId) {
    const deviceDisconnectEvent = {
      category: constants.DISCONNECT_DEVICE,
      event_category: 'Device Management',
      event_action: 'Disconnect Device',
      event_label: 'deviceId',
      event_value: deviceId,
      event: constants.DISCONNECT_DEVICE,
      disconnected_device_id: deviceId,
      transaction_local_dt: this.getLocalIsoTime(),
      user_local_event_dt: this.getLocalIsoTime(),
      message_id: this.getUUID(),
      event_unix_timestamp: new Date().getTime()
    };

    publicIp.v4().then(
      (ipAddress) => {
        deviceDisconnectEvent.disconnected_device_ip = ipAddress;
        EventProvider.getEventManager().dispatch(deviceDisconnectEvent);
      }
    );
  }

  /**
   * Function to trigger Account setting
   */
  static accountSettingEvent() {
    const deviceDisconnectEvent = {
      event: constants.ACCOUNT_SETTING,
      event_action: '',
      transaction_local_dt: this.getLocalIsoTime(),
      message_id: this.getUUID()
    };
    EventProvider.getEventManager().dispatch(deviceDisconnectEvent);
  }

  /**
   * Function to trigger page view for bookshelf
   * @param {Object} gpsSubscriptions
   */
  static sendPageViewEvent(gpsSubscriptions) {
    const subscriptionData = gpsSubscriptions && gpsSubscriptions.find(subscription => subscription.status === 'ACTIVE');
    const navigationEvent = {
      event: constants.PAGE_VIEW,
      page_name: constants.MOJO_HOME,
      content_id_type: env.PRODUCT && env.PRODUCT.toUpperCase(),
      message_id: this.getUUID(),
      transaction_dt: new Date().toISOString(), // Timestamp in ISO Format: 2020-04-15T12:33:45.237Z
      transaction_local_dt: CommonUtils.getLocalIsoTime() // "2021-05-04T17:38:48:195"
    };
    if (subscriptionData) {
      navigationEvent.entitlement_id = subscriptionData.entitlementLevel;
      navigationEvent.pearson_subscription_id = subscriptionData.subscriptionId;
      navigationEvent.pearson_subscription_tier_code = subscriptionData.entitlementLevel;
    }
    EventProvider.getEventManager().dispatch(navigationEvent);
  }

  /**
   * Function to get cookie expire time
   */

  static getExpireTime() {
    const currentTime = new Date();
    const currentGetTime = currentTime.getTime();
    const expireTime = currentGetTime + 1000 * 36000;
    currentTime.setTime(expireTime);
    return currentTime.toUTCString();
  }

  /**
   * Function to load offer event push
   */
  static onloadOfferEventPush() {
    const loadOfferEvent = {
      event: constants.LOAD_OFFER,
      event_category: 'Partnered Offer',
      event_action: 'Load',
      event_label: 'Load Offer',
      event_value: '40%',
      offer_name: constants.OFFER_NAME,
      transaction_local_dt: this.getLocalIsoTime(),
      user_local_event_dt: this.getLocalIsoTime(),
      message_id: this.getUUID(),
      event_unix_timestamp: new Date().getTime()
    };
    EventProvider.getEventManager().dispatch(loadOfferEvent);
  }

  /**
   * Function to get page name
   * @param {string} pageId
   */
  static getPageName(pageId) {
    const assetInfo = Framework.getStoreRegistry().getStore('asset');
    const slatesInfo = assetInfo && assetInfo.slates && assetInfo.slates.length > 0 && CommonUtils.tocList(assetInfo.slates, pageId);
    const childrenInfo = assetInfo && assetInfo.children && assetInfo.children.length > 0 && CommonUtils.tocList(assetInfo.children, pageId);
    const pageName = (slatesInfo && slatesInfo.title) || (childrenInfo && childrenInfo.title) || this.getAppTitle();
    return pageName;
  }

  /**
   * Function to redirect
   * if user is in etext but should be in plus
   * and vice versa
   * @param {string} businessModelCode
   */
  static redirectUser(businessModelCode) {
    if (!window.location.host.includes(constants.ETEXT_PERF)) {
      const currentUrl = window.location.href;
      this.canadianProxy = PathUtils.getQueryParameterbyName(constants.CP);
      this.tpiValue = PathUtils.getQueryParameterbyName(constants.TPI);
      const isLocalhost = window.location.host.includes(constants.LOCAL);
      const isGlobalEtextLaunch = businessModelCode === constants.INTEGRATED_RUMBA || (this.canadianProxy && this.canadianProxy === '1') || (this.tpiValue && this.tpiValue.toLowerCase() === 'y');

      if (!isLocalhost && isGlobalEtextLaunch && window.location.host.includes(constants.PLUS)) {
        window.location.href = currentUrl.replace(constants.PLUS, constants.ETEXT);
      } else if (!isLocalhost && !isGlobalEtextLaunch && window.location.host.includes(constants.ETEXT)) {
        window.location.href = currentUrl.replace(constants.ETEXT, constants.PLUS);
      }
    }
  }

  /**
    * Beta feedback event for appcues
    */
  static openAppcuesBetaFeedback(data) {
    const eventData = {
      eventName: data.eventName,
      eventProperties: {
        source: data.source,
        productID: data.productID,
        bookId: data.bookId,
        courseId: data.courseId || '',
        bookTitle: data.bookTitle,
        contentFormat: data.contentFormat
      }
    };
    AppcuesUtils.handleEvent(constants.APPCUES_TRACK_EVENT, null, eventData);
  }

  static getAppTitle() {
    this.title = constants.TITLE;
    if (window.location.hostname.includes(constants.PLUS)) {
      this.title = constants.PEARSON_PLUS_TITLE;
    }

    return this.title;
  }

  static getGabProductId() {
    // const config = Framework.getStoreRegistry().getStore('dlConfig');
    const config = DataFormatter.getObjectInStorage('configData');
    let gabProductId = null;
    const gabProductIds = config?.gabProductIds;
    // const gabProductIds = {"digital-library":"5a7832126c743b3100747793",
    // "starkbook":"5a7832126c743b3100747793",
    // "spark":"5a7832126c743b3100747793"};
    const brandName = DataFormatter.getObjectInStorage('brand_name');
    if (brandName) {
      gabProductId = gabProductIds[brandName];
    }
    return gabProductId;
  }

  static getdeviceId() {
    let CookieDate = new Date();
    CookieDate.setFullYear(CookieDate.getFullYear() + 20);
    document.cookie = `myCookie=to_be_deleted; expires=${CookieDate.toGMTString()};`;
    //  var d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (CookieDate + Math.random() * 16) % 16 | 0;
      CookieDate = Math.floor(CookieDate / 16);
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
    return uuid;
  }

  static parseQuery(qstr, url) {
    const query = {};
    const a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
    for (let i = 0; i < a.length; i++) {
      const b = a[i].split('=');
      query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
    }
    return query;
  }

  static getDefaultGAPageView() {
    return {
      dataLayer: {
        event: 'pageview',
        product_id: (localStorage.getItem('setCoursesectionFetchData')) ? JSON.parse(localStorage.getItem('setCoursesectionFetchData')).data[0].products[0].id : null,
        product_platform_code: 'GLS',
        product_model_name: 'Digital Library',
        business_model_code: 'Digital Library',
        course_id: null,
        course_section_id: (localStorage.getItem('setCoursesectionFetchData')) ? JSON.parse(localStorage.getItem('setCoursesectionFetchData')).data[0].id : null,
        content_id: null,
        content_id_type: null, // env.PRODUCT && env.PRODUCT.toUpperCase(),
        isbn13: null,
        offer_id: null,
        book_id: null,
        message_id: CommonUtils.getUUID(),
        transaction_dt: new Date().toISOString(),
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
  }

  static time_diff_seconds(timeStamp) {
    const duration = (new Date().getTime() - timeStamp) / 1000;
    return Math.abs(Math.round(duration));
  }

  static isSessionExpired(timeStamp) {
    const secondsDuration = this.time_diff_seconds(timeStamp);
    return (secondsDuration > constants.SESSION_DURATION); // PI Session Expiry Time 30(1800 ms) mins
  }
}
