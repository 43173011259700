import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import env from './common/env';
import newrelic from './modules/newrelic/newrelic';

ReactDOM.render(<App />, document.getElementById('main'));

if (env.NEWRELIC_ENABLED && window.location.hostname !== 'local-dev.pearson.com') {
  newrelic.init();
}
