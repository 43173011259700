/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import BookReader from './BookReader';
// import ScormService from "./services/ScormService";
import FetchScormAssetDetailsService from './services/FetchScormAssetDetailsService';
import UpdateUserProductStatusService from './services/UpdateUserProductStatusService';
import GetServerTimestampService from './services/GetServerTimestampService';

export default {
  path: '/book',
  exact: true,
  services: [new FetchScormAssetDetailsService(),
    new UpdateUserProductStatusService(),
    new GetServerTimestampService()
  ],
  stores: [],
  // services: [new ScormService()],
  // stores: [
  //   {
  //     name: "dlScormAsset",
  //     spec: ScormAsset,
  //     saveLocally: false,
  //     initialState: '',
  //   },
  // ],
  render: props => (
    <BookReader {...props} />
  )
};
