/* eslint-disable linebreak-style */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  MenuItem,
  Popover,
  MenuList
} from '@material-ui/core';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import pearsonLogo from '../../../assets/images/logoInside.svg';

const ShowCourseSelectionIcon = inject(
  'user',
  'language'
)(
  observer((props) => {
    const { language } = props;
    return (
      <div id="courseList" className="nav-item signout">
        <Button
          variant="contained"
          labelPosition="before"
          id="courseListButton"
          classes={{ root: 'courseButton', label: 'courseListLabel' }}
        >
          {
            <Typography>
              <FormattedMessage
                {...language.getText('course_selection_label')}
              />
            </Typography>
          }
        </Button>
        <Popover
          classes={{ paper: 'navbarPopOver' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <MenuList id="coursePopover" tabIndex={0}>
            <MenuItem
              key={-1}
              tabIndex={0}
              value={null}
              style={{
                minHeight: '0px !important',
                maxHeight: '0px !important'
              }}
            />
          </MenuList>
        </Popover>
      </div>
    );
  })
);

const ShowHelpMenu = inject(
  'user',
  'language'
)(
  observer((props) => {
    const { language } = props;

    try {
      return (
        <div
          className="nav-item notification"
          tabIndex="0"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              // this.helpLinkClicked();
            }
          }}
        >
          <div className="helpIcon" title={String(language.getText('help'))}>
            <i className="material-icons" style={{ color: '#74797b' }}>
              help
            </i>
          </div>
        </div>
      );
      // }
    } catch (e) {
      console.log('showHelpMenu', e);
      return '';
    }
  })
);

function Navbar(props) {
  const {
    language
  } = props;

  return (
    <div className="app-bar">
      <div className="left-section">
        <a href="http://www.pearson.com/" style={{ display: 'inline-block' }}>
          <img
            src={pearsonLogo}
            alt={
              <FormattedMessage {...language.getText('navigate_to_homepage')} />
            }
            style={{ height: '69px' }}
          />
        </a>
      </div>
      <div className="right-nav">
        <ShowHelpMenu />
        <ShowCourseSelectionIcon />
        <div id="signout" tabIndex="0" className="nav-item signout">
          <a href="javascript:void(0)">
            <Typography>
              <FormattedMessage {...language.getText('sign_out_label')} />
            </Typography>
          </a>
        </div>
      </div>
    </div>
  );
}

Navbar.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language', 'user', 'dlUser', 'dlUserSchool', 'cartridgeList'),
  observer
)(injectIntl(Navbar));
