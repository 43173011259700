import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
// import AppActions from '../actions/app.actions';
// import AppStore from '../stores/app.store';
// import AppConstants from '../constants/app.constants';
import Button from '@material-ui/core/Button';
import TagManager from 'react-gtm-module';
import * as constants from '../../../common/constants';
// import DataFormatter from './utilities/dataFormatter';
import DataFormatter from '../../../common/utils/DataFormatter';
// import GoogleAnalyticsUtil from '../util/googleAnalyticsUtil';
import guidePointerIcon from '../../../assets/images/guide-pointer.png';
import bookAddedIcon from '../../../assets/images/bookAdded.png';
// var lang = DataFormatter.getLanguage();
import CommonUtils from '../../../common/utils/CommonUtils';

class ReturnBookComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    bookReturned: true
  }

  componentDidMount = function () {
    // AppStore.on(AppConstants.EventTypes.RETURN_PRODUCT_COMPLETED, this.productReturned);
    Framework.getEventManager().on(constants.RETURN_BOOK_SUCCESS, (removedResp) => {
      this.productReturned();
    });
  }

  componentDidUpdate = function (prevProps, prevState) {

  }

  UNSAFE_componentWillMount = function () {

  }

  componentWillUnmount = function () {
    // AppStore.removeListener(AppConstants.EventTypes.RETURN_PRODUCT_COMPLETED, this.productReturned);
  }

  productReturned = () => {
    this.setState({
      bookReturned: false
    }, function () {
      const self = this;
      setTimeout(self.props.handleClose, 3000);
    });
  }

  scrollParent = () => {
    try {
      $('.mainAppContainer')[0].scrollTop = $('.addBookComponentContainer')[0].scrollTop;
    } catch (e) {}
  }

  returnBook = () => {
    const { language, intl } = this.props;
    const bookToReturn = [{
      productId: this.props.book.productId,
      userId: DataFormatter.getKeyFromObject('userInformation', 'id')
    }];
    // AppActions.ReturnBook(bookToReturn);
    Framework.getEventManager().publish(constants.RETURN_BOOK_REQUESTED,
      bookToReturn);

    const gtmData = {
      dataLayer: {
        event: 'return_book',
        event_category: intl.formatMessage(language.getText('return_book')),
        event_action: intl.formatMessage(language.getText('return_book_clicked')),
        event_label: null,
        product_id: bookToReturn[0].productId,
        user_id: bookToReturn[0].userId,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  }

  handleAddBookPopup = () => {
    const { language, intl } = this.props;
    if (this.state.bookReturned) {
      return (
        <div className="addBookComponentContainer" onScroll={this.scrollParent.bind(this)}>
          <div className="tabBarContainer addBook">
            <div className="tabList">
              <div className="tab" style={{ visibility: 'hidden' }}>{intl.formatMessage(language.getText('my_book_box'))}</div>
              <div className="tab" style={{ color: 'white', paddingLeft: '37px' }}>{intl.formatMessage(language.getText('school_library'))}</div>
            </div>
          </div>
          <div className="arrowIndicatorReturn">
            <img src={guidePointerIcon} />
          </div>
          <div className="modalBox" id="returnBookModalPanel" tabIndex="0" role="dialog" aria-modal="true" onKeyDown={this.handleAccessibility.bind(this)}>
            <div className="addBookContent">
              <div className="addBookThumbnail">
                <img src={this.props.book.coverImageURL} />
              </div>
              <div className="addBookInfo">
                <div className="title">{this.props.book.bookName}</div>
                <div className="actionText">{intl.formatMessage(language.getText('return_to_school_library'))}</div>
              </div>
            </div>
            <div className="addBookActions">
              <Button id="btn_cancel_return_book" onKeyDown={this.handleAccessibility.bind(this)} onClick={this.props.handleClose} primary>{intl.formatMessage(language.getText('cancel_button_label'))}</Button>
              <Button id="btn_return_book" onKeyDown={this.handleAccessibility.bind(this)} onClick={this.returnBook.bind(this)} primary>{intl.formatMessage(language.getText('okButtonLabel'))}</Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="addBookComponentContainer" onScroll={this.scrollParent.bind(this)}>
        <div className="bookAddedIcon">
          <img src={bookAddedIcon} />
        </div>
        <div className="bookAddedText bookReturnedText">
          <div>{intl.formatMessage(language.getText('book_returned'))}</div>
        </div>
      </div>
    );
  }

  handleAccessibility = function (event) {
    const isTabPressed = event.key === 'Escape' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
    if (!isTabPressed) {
      return;
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      this.props.handleClose();
      return false;
    }
    if (event.key === 'Tab' || event.shiftKey) {
      if (event.target.id == 'btn_return_book') {
        document.getElementById('btn_cancel_return_book').focus();
        event.preventDefault();
        return false;
      }
      if (event.target.id == 'btn_cancel_return_book') {
        document.getElementById('btn_return_book').focus();
        event.preventDefault();
        return false;
      }

      document.getElementById('btn_cancel_return_book').focus();
      event.preventDefault();
      return false;
    }
  }

  render = function () {
    return (
      <div>{this.handleAddBookPopup()}</div>
    );
  }
}

ReturnBookComponent.defaultProps = {
};

// ReturnBookComponent.contextTypes= {
//   router: React.PropTypes.object.isRequired
// }

// export default ReturnBookComponent;
ReturnBookComponent.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language'),
  observer
)(injectIntl(ReturnBookComponent));
