import React, { Component } from 'react';

class IframeComponent extends Component {
  iframe = () => ({
    __html: `<iframe id="scorm-frame" src="${this.props.iframeUrl}" style="${this.props.style}"></iframe>`
  })

  render() {
    return (
      <div>
        <div dangerouslySetInnerHTML={this.iframe()} />
      </div>
    );
  }
}

IframeComponent.defaultProps = {
  style: 'width: 100%; height: 100vh'
};

export default IframeComponent;
