/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../constants';
import DataFormatter from '../utils/DataFormatter';

export default class DlServerYearService extends BaseService {
  constructor() {
    super(constants.DL_SERVER_YEAR_REQUESTED, 'DlServerYearServiceStatus');
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    headerParams = {
      // headers: {
      //   userid: event.userId,
      //   appversion: 'app'
      // }
    };
    // console.log("currentYear handleEvent: ", new Date(), new Date().getTime());
    return this.getClient('hapi').get('/currentYear', headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    // console.log("currentYear handleResponse: ", new Date(), new Date().getTime());
    if (response.data && response.data !== '') {
      const yearData = {};
      yearData.copyRightYear = String(response.data);
      Framework.getStoreRegistry().getStore('dlServerYear').set(yearData);
      Framework.getEventManager().publish(
        constants.DL_SERVER_YEAR_FETCHED,
        yearData
      );
    } else {
      Framework.getEventManager().publish(constants.DL_SERVER_YEAR_EMPTY, {
        message: 'Cannot Find Server Year'
      });
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    // Framework.getStoreRegistry().getStore('dlUserSchool').set({});
    Framework.getEventManager().publish(constants.DL_SERVER_YEAR_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
