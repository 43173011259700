import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import injectTapEventPlugin from 'react-tap-event-plugin';
// import GoogleAnalyticsUtil from '../util/googleAnalyticsUtil';
import TagManager from 'react-gtm-module';
import DataFormatter from '../../../common/utils/DataFormatter';
import CommonUtils from '../../../common/utils/CommonUtils';

try {
  injectTapEventPlugin();
} catch (e) {

}

// var lang = DataFormatter.getLanguage();

class FooterBar extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    language: PropTypes.object.isRequired
  };

  onLinkClicked = function (linkUrl, type) {
    const { language, intl } = this.props;
    let eventCategory;
    let eventAction = '';
    switch (type) {
      case 'terms':
        eventCategory = intl.formatMessage(language.getText('terms'));
        eventAction = intl.formatMessage(language.getText('terms_clicked'));
        break;
      case 'cookie':
        eventCategory = intl.formatMessage(language.getText('cookie_policy'));
        eventAction = intl.formatMessage(language.getText('cookie_clicked'));
        break;
      case 'copyright':
        eventCategory = intl.formatMessage(language.getText('copy_right'));
        eventAction = intl.formatMessage(
          language.getText('copy_right_clicked')
        );
        break;
      case 'privacy':
        eventCategory = intl.formatMessage(language.getText('privacy'));
        eventAction = intl.formatMessage(language.getText('policy_clicked'));
        break;
    }
    const gtmData = {
      dataLayer: {
        event: type,
        event_category: eventCategory,
        event_action: eventAction,
        event_label: null,
        user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
        link_url: linkUrl,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  };

  getCopyRight = function () {
    const { language, intl } = this.props;
    const copyRightText = `© ${DataFormatter.getObjectInStorage(
      'copyRightYear'
    )} ${intl.formatMessage(language.getText('copyright'))}`;
    return copyRightText;
  };

  render() {
    const { language, intl } = this.props;
    let schoolList;
    // const isCWTUser = DataFormatter.getKeyFromObject('cwtUserDetails', 'isCWTUser')
    const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
    const isCWTUser = !!(
      iesUserDetails
      && iesUserDetails.identity.homeCountryCode.toLowerCase() == 'ca'
    );
    schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
    let termsLink = '';
    let cookiePolicyLink = '';
    let privacyLink = '';
    let copyrightLink = '';
    try {
      schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
      termsLink = schoolList[0] ? schoolList[0].links.terms : '';
      cookiePolicyLink = schoolList[0] ? schoolList[0].links.cookie : '';
      privacyLink = schoolList[0] ? schoolList[0].links.privacy : '';
      // termsLink = 'https://www.pearson.com/ca/en/legal/terms-of-use.html'
      // privacyLink = 'https://www.pearson.com/ca/en/legal/privacy-statement.html'
      // cookiePolicyLink = 'https://www.pearson.com/corporate/cookies.html'
      copyrightLink = 'https://www.pearson.com/ca/en/legal/copyright.html';
    } catch (e) { }
    const that = this;
    return (
      <div className="footerBarMain" id="footerBarMain" tabIndex="-1">
        <div className="footerBar" tabIndex="-1">
          <div
            onKeyPress={this.onLinkClicked.bind(this, termsLink, 'terms')}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                that.onLinkClicked.call(that, termsLink, 'terms');
              }
            }}
            className={`footerOption ${typeof termsLink === 'undefined' || termsLink === ''
              ? 'hideFooterOption'
              : ''
            }`}
          >
            <div hidden>
              <span id="new-window-0">Opens in a new window</span>
            </div>
            <a
              href={termsLink}
              onClick={this.onLinkClicked.bind(this, termsLink, 'terms')}
              id="terms"
              style={{ color: '#393c3d' }}
              rel="noopener"
              target="_blank"
              aria-describedby="new-window-0"
              tabIndex="-1"
            >
              {intl.formatMessage(language.getText('terms'))}
            </a>
          </div>

          <div
            onKeyPress={this.onLinkClicked.bind(
              this,
              cookiePolicyLink,
              'cookie'
            )}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                that.onLinkClicked.call(that, cookiePolicyLink, 'cookie');
              }
            }}
            className={`footerOption ${typeof cookiePolicyLink === 'undefined' || cookiePolicyLink === ''
              ? 'hideFooterOption'
              : ''
            }`}
          >
            <div hidden>
              <span id="new-window-1">Opens in a new window</span>
            </div>
            <a
              href={cookiePolicyLink}
              onClick={this.onLinkClicked.bind(
                this,
                cookiePolicyLink,
                'cookie'
              )}
              id="cookiePolicy"
              style={{ color: '#393c3d' }}
              rel="noopener"
              target="_blank"
              aria-describedby="new-window-1"
              tabIndex="-1"
            >
              {intl.formatMessage(language.getText('cookie_policy'))}
            </a>
          </div>

          {isCWTUser ? (
            <div
              onKeyPress={this.onLinkClicked.bind(
                this,
                copyrightLink,
                'copyright'
              )}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  that.onLinkClicked.call(that, copyrightLink, 'copyright');
                }
              }}
              className={`footerOption ${typeof copyrightLink === 'undefined' || copyrightLink === ''
                ? 'hideFooterOption'
                : ''
              }`}
            >
              <div hidden>
                <span id="new-window-2">Opens in a new window</span>
              </div>
              <a
                href={copyrightLink}
                onClick={this.onLinkClicked.bind(
                  this,
                  copyrightLink,
                  'copyright'
                )}
                id="privacy"
                style={{ color: '#393c3d' }}
                rel="noopener"
                target="_blank"
                aria-describedby="new-window-2"
                tabIndex="-1"
              >
                {intl.formatMessage(language.getText('copy_right'))}
              </a>
            </div>
          ) : (
            ''
          )}
          <div
            onKeyPress={this.onLinkClicked.bind(this, privacyLink, 'privacy')}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                that.onLinkClicked.call(that, privacyLink, 'privacy');
              }
            }}
            className={`footerOption ${typeof privacyLink === 'undefined' || privacyLink === ''
              ? 'hideFooterOption'
              : ''
            }`}
          >
            <div hidden>
              <span id="new-window-2">Opens in a new window</span>
            </div>
            <a
              href={privacyLink}
              onClick={this.onLinkClicked.bind(this, privacyLink, 'privacy')}
              id="privacy"
              style={{ color: '#393c3d' }}
              rel="noopener"
              target="_blank"
              aria-describedby="new-window-3"
              tabIndex="-1"
            >
              {intl.formatMessage(language.getText('privacy'))}
            </a>
          </div>
        </div>
        <div className="copyright" tabIndex="-1">{this.getCopyRight()}</div>
      </div>
    );
  }
}
export default compose(inject('language'), observer)(injectIntl(FooterBar));
