import env from '../env';

const ko = {
  'bookshelf.TITLE': '내 책장',
  'common.LOGOUT': '로그아웃',
  'common.GO_HOME': '홈으로 이동',
  'common.PURCHASE': '구입',
  'courses.WELCOME': '안녕하세요 {userName}님, {courseName} 코스에 오신 것을 환영합니다!',
  'courses.action.SHOW_ASSIGNMENTS': '과제 표시',
  'courses.toc.PUBLISH_SUCCESS': '성공!',
  'courses.toc.PUBLISH_MESSAGE': '다음에 학생들이 콘텐츠를 열 때 해당 변경 내용을 볼 수 있습니다.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': '구독을 찾을 수 없습니다',
  'errors.SUBSCRIPTION_GENERIC_DESC': '귀하의 계정은 이 타이틀에 대한 액세스 권한이 없습니다. 이 메시지가 잘못 수신되었다고 판단되면 피어슨 기술 지원팀에 문의하세요',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': '구독을 찾을 수 없습니다',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': '귀하의 구독에는 eText에 대한 액세스 권한이 없지만, 신용 카드를 사용해 지금 즉시 구독할 수 있습니다.',
  'foxit.ABOUT': '소개',
  'foxit.COPY_RIGHT': 'Foxit 제공 PDF 리더. 저작권 (C) 2003-2019 by Foxit 소프트웨어(주)',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': '지원팀 문의',
  'menu.ABOUT': '소개',
  'menu.ACCESSIBILITY': '접근성',
  'menu.FOXIT': 'Foxit 제공',
  'menu.TERMS_OF_USE': '이용 약관',
  'menu.PRIVACY_POLICY': '개인 정보 보호 정책',
  'menu.SIGN_OUT': '로그아웃',
  'menu.PRINT_PAGE': '페이지 인쇄',
  'menu.GET_LOOSE_LEAF': '루스 리프 받기',
  'menu.AVAILABLE': '역시 이용 가능',
  'menu.PRINT_VERSION': '인쇄 버전을 원하세요?',
  'menu.COPY_RIGHT': '저작권 © {year} 피어슨 에듀케이션(주) 무단 전재를 금합니다.',
  'menu.tooltip.SCHEDULE': '스케줄',
  'print.WATERMARK': '저작권 © {year} 피어슨 에듀케이션(주)',
  'product.WELCOME': '안녕하세요 {userName}님',
  'device.SWAPPED_ALREADY': '이번 달에 이미 변경했습니다',
  'device.CONTACT_SUPPORT': '다음 달까지 기다리거나 도움이 더 필요한 경우 지원팀에 문의하세요.',
  'device.SWITCH_DEVICE': '현재 기기로 변경하시겠어요?',
  'device.SWITCH_ONCE': '한 달 1회 변경할 수 있으므로 이 기기를 취소할 수 없습니다.',
  'device.SEAT_TAKEN': '해당 자리가 찼습니다',
  'device.DISCONNECT_DEVICE': '3대의 기기에 한해 로그인할 수 있습니다. 여기에서 계속하려면 다른 기기를 해제하세요.',
  'device.ALERT_TEXT': '한 달에 한 번만 기기 변경이 가능하다는 점을 명심하세요.',
  'device.YOUR_MOJO': 'mojo로 이동하고 있습니다!'
};

export default ko;
