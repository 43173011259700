/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';
// import async from 'async';

export default class IESUserDetailsByCourseService extends BaseService {
  constructor() {
    super(constants.IES_USER_REQUESTED, 'IESUserDetailsByCourseServiceStatus');
  }

  handleEvent(eventName, event) {
    const gabProductId = CommonUtils.getGabProductId();
    let headerParams = null;
    headerParams = {
      headers: {
        productid: gabProductId,
        courseid: event.courseId,
        appversion: 'app'
      }
    };
    return this.getClient('hapi').get(
      '/fetch-user-by-course-id',
      headerParams
    );
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    const userIdsDataArray = [];
    const userDataArray = [];
    if (response.data && response.data.length > 0) {
      // const userList = toJS(Framework.getStoreRegistry().getStore('iesUserDetailsByCourse'));
      response.data.forEach((user) => {
        // userList.items.push(user._id?user:'')
        if (user.roleValue.toLowerCase() === 'student') {
          userIdsDataArray.push(user.userId);
          userDataArray.push(user);
        }
      });
      // Framework.getStoreRegistry().getStore('iesUserDetailsByCourse').set(userList);
      Framework.getEventManager().publish(constants.IES_USER_FETCHED, userIdsDataArray);
      Framework.getEventManager().publish(constants.IES_USER_DATA_ARRAY_FETCHED, userDataArray);
    } else {
      Framework.getEventManager().publish(constants.IES_USER_LIST_EMPTY, {
        message: 'Unable to fetch user details for selected course'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    // Framework.getStoreRegistry().getStore('dlUser').set({});
    Framework.getEventManager().publish(constants.IES_USER_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
