import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Framework, { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import injectTapEventPlugin from 'react-tap-event-plugin';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import TagManager from 'react-gtm-module';
import DataFormatter from '../../../common/utils/DataFormatter';
import dlCourseForNavbar from '../model/DlCourseForNavbar';
import * as constants from '../../../common/constants';
import NotificationComponent from './NotificationComponent';
import CommonUtils from '../../../common/utils/CommonUtils';
import brandLogo from '../../../assets/images/logoInside.svg';

try {
  injectTapEventPlugin();
} catch (e) {}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  }
});

class PageNavbar extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    showNotifications: false,
    bookCount: 0,
    courseList: [],
    open: false,
    selectedCourse: '',
    selectedCourseLabel: '',
    books: [],
    newBooks: []
  };

  componentDidMount() {
    Framework.getEventManager().on(constants.GET_NEW_BOOKS_FOR_NOTIFICATION_SUCCESS, (respData) => {
      this.userBooksFetched(respData);
    });
    Framework.getEventManager().on(constants.EDIT_CLASS_NAME_SUCCESS, (editClassNameData) => {
      this.refreshCourseName(editClassNameData);
    });
    DataFormatter.setObjectInStorage('getNewBooksCount', true);
    Framework.getEventManager().on(constants.COURSELIST_FETCHED, (courseList) => {
      this.updateCourseList(courseList);
    });
  }

  UNSAFE_componentWillMount() {
    const { dlCourseForNavbar } = this.props;
    Framework.getEventManager().on(constants.STORE_NEW_BOOK_COUNT, (respData) => {
      this.setState({
        newBooks: respData
      }, () => this.updateCount(respData));
      // this.updateCount(respData);
    });
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    dlCourseForNavbar.fetch(this.props.schoolId, userId);
    // AppActions.fetchMyEnrolledCourses(this.props.schoolId, userId);
    // AppActions.getNewBooksForNotification(userId);
    if (localStorage.getItem('usertype') === 'student') {
      Framework.getEventManager().publish(constants.GET_NEW_BOOKS_FOR_NOTIFICATION_REQUESTED, { userId });
    }
  }

  userBooksFetched = (respData) => {
    this.fetchNewBookCountsToStore(respData);
  }

  updateCount = (respData) => {
    this.setState({
      bookCount: respData.count,
      books: respData.books
    });
  }

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();
    if (typeof localStorage.getItem('editClassNameStatus') !== 'undefined' && localStorage.getItem('editClassNameStatus') === true) {
      return false;
    }
    if (this.state.open) {
      this.setState({ open: false });
      return false;
    }
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    }, function () {
      if (this.state.open) {
        const coursePopover = document.getElementById('coursePopover');
        if (coursePopover) {
          this.state.anchorEl.blur();
          document.getElementById('courseListButton').blur();
          coursePopover.focus();
        }
      }
    });
  };

  handleCourseChange = (course) => {
    DataFormatter.setObjectInStorage('courseSelected', course);
    DataFormatter.setObjectInStorage('schoolId', course.schools[0].id);
    if(document.getElementsByClassName('teacher-expiry-message-snackbar') && document.getElementsByClassName('teacher-expiry-message-snackbar').length > 0 && DataFormatter.getObjectInStorage('schoolIdsArr') && DataFormatter.getObjectInStorage('schoolIdsArr').length > 1){
      const getCloseButton= document.getElementById('teacher_snackbar_message_close_unique');
      getCloseButton.click();
      DataFormatter.removeObjectInStorage('isExpiryMessageClosed');
    }
    //Appcues placeholder
    // const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    // if (userId && window.Appcues) {
    //   window.Appcues.identify(userId, {
    //     role: 'teacher'
    //   });
    // }
    // AppDispatcher.dispatch({
    //   type: ActionTypes.COURSE_SELECTION,
    //   data: course
    // });
    this.setState({
      open: false,
      selectedCourseLabel: course.name
    });
    Framework.getEventManager().publish(constants.COURSE_SELECTED, {});
    this.sendAnalytics('course_change', course.name);
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  updateCourseList = (courseListData) => {
    // let courseListData = AppStore.courseListNavBarData;

    if (localStorage.getItem('editClassNameData') && localStorage.getItem('editClassNameData') != '' && typeof localStorage.getItem('editClassNameData') !== 'undefined') {
      if (localStorage.getItem('editClassNameData').update !== '') {
        courseListData.items.map((course, i) => {
          // console.log("COMP-->",course.id,"===",JSON.parse(localStorage.getItem('editClassNameData')).update.id);
          if (course.id === JSON.parse(localStorage.getItem('editClassNameData')).update.id) {
            DataFormatter.setObjectInStorage('courseSelected', courseListData.items[i]);
            this.setState({
              selectedCourseLabel: courseListData.items[i].name,
              selectedCourse: courseListData.items[i].id,
              courseList: courseListData.items
            });
            // AppDispatcher.dispatch({
            //   type: ActionTypes.COURSE_SELECTION,
            //   data: courseListData[i]
            // });
          }
        });
        localStorage.removeItem('editClassNameData');
      }
    } else {
      DataFormatter.setObjectInStorage('courseSelected', courseListData.items[0]);
      if(courseListData.items[0]) {
        this.setState({
          selectedCourseLabel: courseListData.items[0].name,
          selectedCourse: courseListData.items[0].id,
          courseList: courseListData.items
        });
      }
      // AppDispatcher.dispatch({
      //   type: ActionTypes.COURSE_SELECTION,
      //   data: courseListData[0]
      // });
    }
  }

  refreshCourseName = (editClassNameData) => {
    this.setState({
      open: false,
      selectedCourseLabel: editClassNameData.update.name
    });
  }

  notificationsClicked = () => {
    const { language, intl } = this.props;
    const notificationsFlag = !this.state.showNotifications;
    if (notificationsFlag) {
      // AppActions.sendNewBookCountToStore(0, AppStore.newBooks ? AppStore.newBooks : []);
      const setOfNewBooks = this.state.newBooks ? this.state.newBooks : [];
      Framework.getEventManager().publish(constants.STORE_NEW_BOOK_COUNT, { count: 0, books: setOfNewBooks });
      const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
      const gtmData = {
        dataLayer: {
          event: 'notification',
          event_category: intl.formatMessage(language.getText('notification')),
          event_action: intl.formatMessage(language.getText('notification_clicked')),
          event_label: null,
          user_id: userId,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }
    this.setState({
      showNotifications: notificationsFlag
    }, () => {
      const notificationContainer = document.getElementsByClassName('notificationContainer')[0];
      if (notificationContainer) {
        notificationContainer.focus();
      }
    });
  }

  refineServiceProductData = (userProductItemArg, idx) => {
    const productItem = userProductItemArg.productId;
    let refinedData = {};
    const userRole = DataFormatter.getKeyFromObject('userInformation', 'role');
    const bookArray = productItem.books;
    try {
      if (bookArray.length == 0) {
        return false;
      }
      if (bookArray.length == 1) {
        refinedData = {
          coverImageURL: bookArray[0].metadata.coverImageUrl,
          title: bookArray[0].metadata.title
        };
      } else if (bookArray.length > 1) {
        const book1Metadata = bookArray[0].metadata || '';
        const book2Metadata = bookArray[1].metadata || '';
        if (book1Metadata != '') {
          var book1Role = book1Metadata.role || '';
        } else {
          var book1Role = '';
        }
        if (book2Metadata != '') {
          var book2Role = book2Metadata.role || '';
        } else {
          var book2Role = '';
        }
        if (String(book1Role) === String(userRole)) {
          refinedData = {
            coverImageURL: book1Metadata.coverImageUrl,
            title: book1Metadata.title
          };
        } else if (String(book2Role) === String(userRole)) {
          refinedData = {
            coverImageURL: book2Metadata.coverImageUrl,
            title: book2Metadata.title
          };
        } else {
          refinedData = {
            coverImageURL: book1Metadata.coverImageUrl ? book1Metadata.title : productItem.title,
            title: book1Metadata.title
          };
        }
      }
      const assignedDate = userProductItemArg.assignedDate * 1000;
      const bookStatus = userProductItemArg.status;
      const dateToday = new Date();
      const daysLimit = 5; // go back 5 days!
      const notificationLastVisitedTime = DataFormatter.getObjectInStorage('notificationsLastVisitedTime');
      if (notificationLastVisitedTime) {
        var verifyDate = notificationLastVisitedTime;
      } else {
        var verifyDate = dateToday.setDate(dateToday.getDate() - daysLimit);
      }
      if (assignedDate > verifyDate) {
        refinedData.isNew = true;
      }
      refinedData.bookInfo = {};
      if (userProductItemArg.assignedDate) {
        refinedData.bookInfo.assignedDate = userProductItemArg.assignedDate;
      }
      if (typeof userProductItemArg.status !== 'undefined') {
        refinedData.bookInfo.status = userProductItemArg.status;
      }
      if (userProductItemArg.assignmentType) {
        refinedData.bookInfo.assignmentType = userProductItemArg.assignmentType;
      }
      if (userProductItemArg.title) {
        refinedData.bookInfo.title = userProductItemArg.title;
      }
      return refinedData;
    } catch (e) {}
  };

  fetchNewBookCountsToStore = (productList) => {
    const userProducts = productList;
    const booksList = [];
    if (userProducts instanceof Array) {
      let productListItem = null;
      for (let i = 0, iLen = userProducts.length; i < iLen; i++) {
        productListItem = userProducts[i];
        const bookitem = this.refineServiceProductData(productListItem, i);
        if (bookitem !== false) {
          if (bookitem.isNew) {
            if (bookitem.bookInfo.assignmentType == 'teacher') {
              booksList.push(bookitem);
            }
          }
        }
      }
    }
    // AppActions.sendNewBookCountToStore(booksList.length, booksList);
    Framework.getEventManager().publish(constants.STORE_NEW_BOOK_COUNT, { count: booksList.length, books: booksList });
    if (JSON.stringify(booksList) != JSON.stringify(this.state.books)) {
      this.setState({
        books: booksList
      });
    }
    return booksList;
  }

  handleNotifications = () => {
    if (this.state.showNotifications) {
      return (
        <NotificationComponent bookCount={this.state.bookCount} fetchNewBookCountsToStore={this.fetchNewBookCountsToStore} closeNotifications={this.notificationsClicked.bind(this)} books={this.state.books} />
      );
    }
  }

  logout = () => {
    this.sendAnalytics('logout', '');
    // window.piSession.logout();
    const piSession = window.piSession;
    if (piSession) {
      piSession.setOptions({
        requireLogin: false
      });
    }
    if(window.sessionStorage){
      const booksSelectedByTeacher = DataFormatter.getObjectInSessionStorage('booksSelectedByTeacher');
      if(booksSelectedByTeacher && booksSelectedByTeacher.length) {
        DataFormatter.setObjectInSessionStorage('booksSelectedByTeacher', []);
      }
    }
    if (window.localStorage) {
      // Getting the userName from localStorage before clearing to set it back - fix for RPLUS-4705
      const userName = localStorage.getItem('userName');
      const isStudentAdd = localStorage.getItem('isStudentAdd');
      const getNewBooksCount = localStorage.getItem('getNewBooksCount');
      const isTakeANoteClicked = localStorage.getItem('isTakeANoteClicked');
      const notificationsLastVisitedTime = localStorage.getItem('notificationsLastVisitedTime');
      localStorage.clear();
      localStorage.setItem('userName', userName);
      localStorage.setItem('getNewBooksCount', getNewBooksCount);
      localStorage.setItem('notificationsLastVisitedTime', notificationsLastVisitedTime);
      localStorage.setItem('isTakeANoteClicked', isTakeANoteClicked);
      (isStudentAdd === 'true') ? localStorage.setItem('isStudentAdd', isStudentAdd) : '';
    }
    if (piSession) {
      const redirectURL = `${window.location.protocol}//${window.location.host}`;
      piSession.logout(redirectURL);
      // window.location = window.location.protocol+"//"+window.location.host;
    } else {
      Framework.logout();
    // AppActions.logoutUser();
    }
  }

  // userLogoutComplete = () => {
  //   let resp = AppStore.response
  //   if (resp === 'success') {
  //     //this.context.router.push('/')
  //     window.location = window.location.protocol+"//"+window.location.host;
  //   }
  // }

  handleNotificationCount = () => {
    if (this.state.bookCount > 0) {
      return (
        <div className="notificationCount">{this.state.bookCount}</div>
      );
    }
    return null;
  }

  helpLinkClicked = () => {
    // need to get the help link from school configuration-CAAS service data-At present data is not available
    // const schoolInfo = DataFormatter.getKeyFromObject('userInformation', 'schools')
    // const helpLinkUrl = schoolInfo[0].links.help
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
    let helpLinkUrl = appConfig.help.url;
    if (localStorage.getItem('usertype') == 'student') {
      helpLinkUrl = appConfig.help.student_url;
    }
    this.sendAnalytics('help', '');
    window.open(helpLinkUrl, '_blank');
  }

  showHelpMenu = () => {
    const { language, intl } = this.props;
    try {
      let appConfig;
      if (localStorage.getItem('appConfig')) {
        appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
        appConfig = JSON.parse(
          appConfig.substring(
            appConfig.indexOf('{'),
            appConfig.lastIndexOf('}') + 1
          )
        );
      }
      const helpLink = appConfig.help;
      /*
      TODO: Changing helpLink.url to empty string to hide help icon
      Help page is under development
      Need to update changes when help page is ready
      */
      // helpLink.url = "" // need to delete this line of code when help page is ready
      if (helpLink.url !== '') {
        return (
          <div
            className="nav-item notification"
            tabIndex="0"
            onClick={this.helpLinkClicked}
            onKeyDown={(e) => {
              if (e.key == 'Enter') {
                this.helpLinkClicked();
              }
            }}
          >
            <div
              className="helpIcon"
              title={intl.formatMessage(language.getText('help'))}
            >
              <i className="material-icons" style={{ color: '#74797b' }}>
                help
              </i>
            </div>
          </div>
        );
      }
    } catch (e) {
      console.log('showHelpMenu', e);
    }
  };

  showNotificationsIcon = () => {
    const { language, intl } = this.props;
    if (DataFormatter.getKeyFromObject('userInformation', 'role') != 'teacher') {
      return (
        <div
          className="nav-item notification"
          onClick={this.notificationsClicked.bind(this)}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              this.notificationsClicked();
            }
          }}
        >
          {this.handleNotificationCount()}
          <button className="notificationIcon" title={intl.formatMessage(language.getText('notifications'))}>
            <i className="material-icons" style={this.state.showNotifications ? { color: '#028aae' } : {}}>notifications</i>
          </button>
        </div>
      );
    }
    return null;
  }

  showCourseSelectionIcon = () => {
    const { language } = this.props;
    const that = this;
    const courseCount = this.state.courseList.length;
    if (DataFormatter.getKeyFromObject('userInformation', 'role') != 'student') {
      return (
        <div id="courseList" className="nav-item signout">
          <Button
            variant="contained"
            labelPosition="before"
            id="courseListButton"
            endIcon={this.state.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            // labelStyle={styles.labelStyle}
            classes={{ root: 'courseButton', label: 'courseListLabel' }}
            onClick={this.handleClick}
            onKeyPress={this.handleClick}
            onKeyDown={this.handleClick}
          >
            {this.state.selectedCourseLabel ? this.state.selectedCourseLabel : (
              <FormattedMessage
                {...language.getText('course_selection_label')}
              />
            )}
          </Button>
          <Popover
            open={this.state.open}
            classes={{ paper: 'navbarPopOver' }}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            onClose={this.handleRequestClose}
          >
            <MenuList id="coursePopover" tabIndex={0}>
              <MenuItem
                key={-1}
                tabIndex={0}
                value={null}
                // primaryText=""
                style={{ minHeight: '0px !important', maxHeight: '0px !important' }}
              />
              {this.state.courseList.map((course, index) => (
                <MenuItem
                  key={index}
                  tabIndex={0}
                  onClick={that.handleCourseChange.bind(that, course)}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      that.handleCourseChange.call(that, course);
                    } else if (e.key == 'Tab' && ((index + 1) == courseCount)) {
                      that.handleRequestClose.call(that);
                    }
                  }}
                  onKeyPress={(e) => {
                    that.handleCourseChange.bind(that, course);
                  }}
                  value={course.id}
                  schoolId={course.schools[0].id}
                >
                  {DataFormatter.getObjectInStorage('schoolIdsArr') && DataFormatter.getObjectInStorage('schoolIdsArr').length > 1 ? course.name + "("+(DataFormatter.getObjectInStorage("schoolNamesArr").filter((x)=>x.id==course.schools[0].id).map((x)=>x.name)[0]).substring(0,10)+")":course.name}
                  {/* {DataFormatter.getObjectInStorage('schoolIdsArr') && DataFormatter.getObjectInStorage('schoolIdsArr').length > 1 ? course.name + "("+DataFormatter.getObjectInStorage('iesSchoolDetails').school[0].name.substring(0,10)+")":course.name} */}
                </MenuItem>
              ))
              }
            </MenuList>
          </Popover>
        </div>
      );
    }
    return null;
  }

  sendAnalytics = (type, name) => {
    const { language, intl } = this.props;
    let eventCategory; const
      eventClicked = intl.formatMessage(language.getText('clicked'));
    const userID = DataFormatter.getKeyFromObject('userInformation', 'id');
    switch (type) {
      case 'logout':
        eventCategory = intl.formatMessage(language.getText('Logout'));
        break;
      case 'help':
        eventCategory = intl.formatMessage(language.getText('help'));
        break;
      case 'course_change':
        eventCategory = intl.formatMessage(language.getText('course_selection_label'));
        break;
    }
    const eventAction = eventCategory + eventClicked;
    let gtmData;

    if (type === 'logout') {
      const iesUserSchool = DataFormatter.getObjectInStorage('iesSchoolDetails');
      gtmData = {
        dataLayer: {
          event: type,
          event_category: eventCategory,
          event_action: eventAction,
          event_label: null,
          person_id: null,
          person_id_type: constants.ID_TYPE,
          person_role_code: null,
          school_id: null,
          secondary_person_id: null,
          secondary_person_id_type: null,
          secondary_person_role_code: null,
          organization_id: null, // course.organizationId || null,
          organization_id_type: null,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
    } else {
      gtmData = {
        dataLayer: {
          event: type,
          event_category: eventCategory,
          event_action: eventAction,
          event_label: null,
          user_id: userID,
          selected_course: name,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
    }
    TagManager.dataLayer(gtmData);
  }

  render() {
    const { language, intl } = this.props;
    // var currentBrand = DataFormatter.getObjectInStorage('brand_name');
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className="app-bar">
          {this.handleNotifications()}
          <div className="left-section">
            <a
              href="http://www.pearson.com/"
              style={{ display: 'inline-block' }}
            >
              <img
                src={brandLogo}
                alt={intl.formatMessage(language.getText('navigate_to_homepage'))}
                style={{ height: '69px' }}
              />
            </a>
          </div>
          <div className="right-nav">
            {this.showHelpMenu()}
            {this.showNotificationsIcon()}
            {this.showCourseSelectionIcon()}
            <div
              id="signout"
              tabIndex="0"
              className="nav-item signout"
              onClick={this.logout}
            >
              <a href="javascript:void(0)">
                <FormattedMessage {...language.getText('sign_out_label')} />
              </a>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

// PageNavbar.contextTypes= {
//   router: React.PropTypes.object.isRequired
// }
PageNavbar.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired,
  dlCourseForNavbar: shapes.modelOf(dlCourseForNavbar).isRequired
};

export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language', 'dlCourseForNavbar'),
  observer
)(injectIntl(PageNavbar));
