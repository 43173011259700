/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */


/**
 * Select Role page
 *
 * @file SelectRole.js
 * @author Ratheesh
 * @since 1.0.0
 */


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import Framework from '@greenville/framework';
import TagManager from 'react-gtm-module';
import WelcomeMessage from '../../../common/components/WelcomeMessage';
import LoadingOverlay from '../../../common/components/loadingOverlay';
import DataFormatter from '../../../common/utils/DataFormatter';
import pearsonLogo from '../../../assets/images/pearson_logo.png';
import CommonUtils from '../../../common/utils/CommonUtils';
import * as constants from '../../../common/constants';
import { QA_CLIENT_ID, STUDENT_CLIENT_ID } from '../../../common/constants';

class SelectRole extends Component {
    state = {
      showLoader: true
    }

    sendGAData = () => {
      // Push Global Parameter
      const globalParams = CommonUtils.pushGlobalParams();
      TagManager.dataLayer(globalParams);

      // Push null value as anonymous user
      TagManager.dataLayer({
        dataLayer: {
          person_id: null,
          login_session_id: null,
          person_id_type: constants.ID_TYPE,
          person_role_code: null,
          school_id: null,
          secondary_person_id: null,
          secondary_person_id_type: null,
          secondary_person_role_code: null,
          organization_id: null,
          organization_id_type: null // constants.ORGANIZATION_ID_TYPE,
        }
      });

      // Page view for select role
      const gtmPageData = CommonUtils.getDefaultGAPageView();
      gtmPageData.dataLayer.page_title = 'Select Role';
      TagManager.dataLayer(gtmPageData);
    }

    UNSAFE_componentWillMount() {
      if (window.Appcues) {
        window.Appcues.identify('0f2ebec02efc4e9f8e59cea3b90b7f7b', {
          role: 'teacher'
        });
      }
    }

    componentDidMount() {
      setTimeout(() => { this.sendGAData(); }, 2000);
      const scormUrl = DataFormatter.getObjectInStorage('scormUrl');
      const redirectionInfo = DataFormatter.getObjectInStorage('redirectionInfo');
      if (redirectionInfo == 'true') {
        const productId = redirectionInfo.productId;
        DataFormatter.setObjectInStorage('productIdToOpen', productId);
        // this.props.history.push('/book');
        window.location.assign('/book');
      } else if (scormUrl) {
        // this.props.history.push('/launchScorm')
        window.location.assign('/launchScorm');
      }
      if (window.Appcues) {
        window.Appcues.identify('d8b30b8e84df45f8a6de2c9bf8ae5e01', {
          role: 'teacher'
        });
      }
    }

    redirectToStudent = () => {
      localStorage.setItem('usertype', 'student');
      localStorage.setItem('userMode', 'primary');
      Framework.redirectTo('/student/studentcode');
    }

    redirectToTeacher = () => {
      localStorage.setItem('usertype', 'teacher');
      window.location.assign('/app');
      // Framework.redirectTo('/app');
    }

    onClickLogo = () => {
      // window.logout();
      const piSession = window.piSession;
      if (piSession) {
        piSession.setOptions({
          requireLogin: false
        });
      }
      // Getting the userName from localStorage before clearing to set it back - fix for RPLUS-4705
      const userName = localStorage.getItem('userName');
      const isStudentAdd = localStorage.getItem('isStudentAdd');
      localStorage.clear();
      localStorage.setItem('userName', userName);
      (isStudentAdd === 'true') ? localStorage.setItem('isStudentAdd', isStudentAdd) : '';
      Framework.redirectTo('/');
      if (piSession) {
        const redirectURL = `${window.location.protocol}//${window.location.host}`;
        piSession.logout(redirectURL);
        // window.location = window.location.protocol+"//"+window.location.host;
      }
    }

    renderRoleSelection = () => {
      const { language, intl } = this.props;
      return (
        <div>
          {/* <Header /> */}
          <div>
            <nav className="navbar navbar-expand-sm rectangle-1">
              <img onClick={this.onClickLogo} src={pearsonLogo} alt="Pearson Logo" className="pearson-logo" />
            </nav>
          </div>
          <div className="container-fluid selectrole-container">
            <div className="selectrole-welcome-container">
              <WelcomeMessage />
            </div>
            <div className="select-role">
              <label className="select-role-txt">{intl.formatMessage(language.getText('select_your_role'))}</label>
              <br />
              <br />
              <button id="id_student" className="btn-role" onClick={this.redirectToStudent}>
                <label className="role-names">{intl.formatMessage(language.getText('student_text'))}</label>
              </button>
              <br />
              <br />
              <br />
              <button id="id_teacher" className="btn-role" onClick={this.redirectToTeacher}>
                <label className="role-names">{intl.formatMessage(language.getText('teacher_text'))}</label>
              </button>
            </div>
          </div>
          <div className="copyright" style={{ position: 'absolute', left: '68%', top: '95%' }}>
                    ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            <FormattedMessage {...language.getText('copyright')} />
          </div>
        </div>
      );
    }

    render() {
      const piSessionIdLocal = localStorage.getItem('usertype') == 'student' ? localStorage.getItem(`${STUDENT_CLIENT_ID}.PiSessionId`) : localStorage.getItem(`${QA_CLIENT_ID}.PiSessionId`);
      const activeTime = localStorage.getItem('usertype') == 'student' ? localStorage.getItem(`${STUDENT_CLIENT_ID}.PiLastUserActivityTime`) : localStorage.getItem(`${QA_CLIENT_ID}.PiLastUserActivityTime`);
      if (piSessionIdLocal && activeTime && !CommonUtils.isSessionExpired(activeTime)) { // Fix for DL-341
        window.location.assign('/app');
      }
      if ((localStorage.getItem('usertype') === 'teacher' || localStorage.getItem('usertype') === 'student')
            && !DataFormatter.getObjectInStorage('scormUrl')
            && DataFormatter.getObjectInStorage('redirectionInfo') !== true) {
        var sessionStatCheck = setInterval(() => {
          const piSession = window.piSession;
          if (piSession) {
            const sessionState = piSession.hasValidSession(100);
            if (sessionState === piSession.NoToken || sessionState === piSession.NoSession || sessionState === piSession.Success) {
              piSession.login(window.location.href, 100, (status, accessToken) => {
                if (status === piSession.Success) {
                  clearInterval(sessionStatCheck);
                  localStorage.setItem('userId', piSession.userId());
                  localStorage.setItem('contextId', piSession.getContextId());
                  localStorage.setItem('accessToken', accessToken);
                  window.location.assign('/app');
                } else {
                  clearInterval(sessionStatCheck);
                  piSession.logout();
                }
              });
            } else if (sessionState === piSession.NoSession) {
              this.setState({ showLoader: false });
            } else {
              this.setState({ showLoader: true });
            }
          } else {
            this.setState({ showLoader: false });
          }
        }, 1000);
        if (this.state.showLoader === true) {
          return (
            <div>
              <LoadingOverlay isLoading />
            </div>
          );
        }
        return (
          <div>
            {this.renderRoleSelection()}
          </div>
        );
      }
      return (
        <div>
          {this.renderRoleSelection()}
        </div>
      );
    }
}

SelectRole.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(SelectRole));
