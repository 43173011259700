/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class FetchActivityAttemptsByUserIdService extends BaseService {
  constructor() {
    super(constants.USER_ACTIVITY_ATTEMPT_REQUESTED, 'FetchActivityAttemptsByUserIdServiceStatus');
  }

  handleEvent(eventName, event) {
    const headerParams = {
      headers: {
        userId: event.userId,
        next: event.next
      }
    };
    const allAttempts = event.allAttempts;
    return this.getClient('hapi').post(
      '/fetch-activity-attempts-by-user-id',
      {}, headerParams
    );
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data && response.data !== '') {
      Framework.getEventManager().publish(constants.USER_ACTIVITY_ATTEMPT_SUCCESS, response.data.attempts);
    } else {
      Framework.getEventManager().publish(constants.USER_ACTIVITY_ATTEMPT_ERROR, {
        message: 'Cannot Find Activity Attempt Details'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.USER_ACTIVITY_ATTEMPT_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
