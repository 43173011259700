import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Framework, { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import _ from 'lodash';
// import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TagManager from 'react-gtm-module';
import InteractiveActivity from '../../../assets/images/interactive_activity.svg';
import iesUserDetailsByCourse from '../model/IESUserDetailsByCourse';
import LoadingOverlay from './loadingOverlay';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

let usersSelected = [];
let selectedBooksIndex = [];
const styles = {
  block: {
    maxWidth: 250
  },
  radioButton: {
    marginBottom: 16,
    float: 'left',
    // width: '70px',
    marginBottom: '0px'
    // marginRight: '40px'
  },
  inActiveButton: {
    fontWeight: '600',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.26)'
  },
  activeButton: {
    fontWeight: '600',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    color: '#fff'
  }
};
// let lang = DataFormatter.getLanguage();
const muiTheme = createTheme({
  overrides: {
    MuiFormGroup: {
      root: {
        display: 'inline'
      }
    }
  }
});
class Popup extends React.Component {
  constructor() {
    super();
    this.interactiveActivityClick = this.interactiveActivityClick.bind(this);
  }

  state = {
    userListData: [],
    selectedStudentNumber: usersSelected.length,
    displayMessage: 'students',
    selectedValue: true,
    isLoading: false,
    isIESUserFetchInitiatedPopup: false
  }

  componentDidMount = function () {
    this.setState({
      isLoading: true
    });
    Framework.getEventManager().on(constants.IES_USER_FETCHED, (setIesUserFetchData) => {
      if (setIesUserFetchData.length > 0) {
        if (this.state.isIESUserFetchInitiatedPopup == false) {
          this.setState({ isIESUserFetchInitiatedPopup: true });
          Framework.getEventManager().publish(constants.FETCH_IES_USER_REQUESTED,
            {
              userIdArray: setIesUserFetchData
            });
        }
      } else {
        this.setState({ isLoading: false });
      }
    });
    Framework.getEventManager().on(constants.FETCH_IES_USER_SUCCESS, (IesUserData) => {
      this.updateUserCourseFetched(IesUserData);
    });

    selectedBooksIndex = [];
    try {
      if (DataFormatter.msieversion()) {
        document.getElementsByClassName('mainAppContainer')[0].style.overflowY = 'hidden';
      }
    } catch (error) { }
    const popUpSendBookSelectAll = document.getElementsByClassName('fixedBackground');
    if (popUpSendBookSelectAll.length > 0) {
      document.getElementsByClassName('fixedBackground')[0].focus();
    }
  }

  UNSAFE_componentWillMount = function () {
    const { iesUserDetailsByCourse } = this.props;
    // let courseListData = AppStore.courseListNavBarData;
    const course = DataFormatter.getObjectInStorage('courseSelected');
    if (course.id.length > 0) {
      const courseId = course.id;
      const roleValue = constants.roleValue;
      // AppActions.getIesUserDetailsByCourse(courseId,roleValue);
      iesUserDetailsByCourse.fetch(courseId, roleValue);
    }
  }

  handleChange = (index, uid, event, value) => {
    const { language, intl } = this.props;
    var removeElement = -1;
    if (event.currentTarget.checked) {
      usersSelected.push(uid);
      selectedBooksIndex.push(`book${index}`);
      try {
        event.currentTarget.parentElement.parentElement.parentElement.parentElement.style.backgroundColor = 'rgba(0, 127, 163, 0.05)';
        event.currentTarget.parentElement.parentElement.parentElement.parentElement.style.border = '1px solid rgba(0, 127, 163, 0.5)';
        event.currentTarget.parentElement.parentElement.parentElement.children[2].children[1].style.color = '#007fa3';
      } catch (e) { }
    } else {
      selectedBooksIndex.splice(selectedBooksIndex.indexOf(`book${index}`), 1);
      try {
        event.currentTarget.parentElement.parentElement.parentElement.parentElement.style.backgroundColor = 'white';
        event.currentTarget.parentElement.parentElement.parentElement.parentElement.style.border = '1px solid #d3d3d3';
        event.currentTarget.parentElement.parentElement.parentElement.children[2].children[1].style.color = '#393c3d';
      } catch (e) { }
      for (var i = 0, removeElement = 0; i < usersSelected.length; i++) {
        if (uid == usersSelected[i]) {
          removeElement = i;
          break;
        }
      }
      if (removeElement != -1) {
        usersSelected.splice(removeElement, 1);
      }
    }

    this.setState({
      selectedStudentNumber: usersSelected.length
    });
    if (usersSelected.length > 1) {
      this.setState({
        displayMessage: intl.formatMessage(language.getText('students'))
      });
    } else {
      this.setState({
        displayMessage: intl.formatMessage(language.getText('student'))
      });
    }
  }

  onSelectAllKeydown = (event) => {
    if (event.key == 'Enter') {
      event.currentTarget.checked = !event.currentTarget.checked;
      this.selectAll(event);
    }
  }

  submit = () => {
    const { language, intl } = this.props;
    const submitStudentForm = [];
    const assignmentType = 'teacher';

    for (let i = 0; i < this.props.booksSelected.length; i++) {
      for (let j = 0; j < usersSelected.length; j++) {
        const data = {};
        data.userId = usersSelected[j];
        data.productId = this.props.booksSelected[i].productId;
        data.assignmentType = assignmentType;
        data.interactiveIncluded = this.state.selectedValue;
        submitStudentForm.push(data);
      }
    }
    // AppActions.provisionBooks(submitStudentForm); - This is replaced with below code
    Framework.getEventManager().publish(
      constants.SEND_BOOK_REQUESTED,
      {
        payload: submitStudentForm
      }
    );

    if (submitStudentForm.length > 0) {
      for (let i = 0; i < submitStudentForm.length; i++) {
        const userId = submitStudentForm[i].userId;
        // submitStudentForm[i].userId = sha1(userId);
        submitStudentForm[i].userId = userId;
      }

      if (submitStudentForm.length > 0) {
        const gtmData = {
          dataLayer: {
            event: 'send_book',
            event_category: intl.formatMessage(language.getText('send_book')),
            event_action: intl.formatMessage(language.getText('send_book_clicked')),
            event_label: null,
            user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
            book_detail: `Number of Books: ${this.props.booksSelected.length}, Assigned students: ${usersSelected.length}`,
            transaction_local_dt: CommonUtils.getLocalIsoTime()
          }
        };
        TagManager.dataLayer(gtmData);
      }
    }
    usersSelected.length = 0;
    if (typeof this.props.handleClose === 'function') {
      this.props.handleClose();
    }
    // tab accessbility
    const baacTextBookThumnailImage = document.getElementsByClassName('baacTextBookThumnailImage')[0];
    baacTextBookThumnailImage.tabIndex = '0';
    // baacTextBookThumnailImage.focus();
    if (baacTextBookThumnailImage) {
      baacTextBookThumnailImage.focus();
      document.getElementsByClassName('baacTextBookThumnailImage')[0].nextSibling.focus();
    }
  }

  selectAll = (event) => {
    const { language, intl } = this.props;
    const elememts = document.getElementsByClassName('checkboxesdiv');
    selectedBooksIndex = [];
    if (event.currentTarget.checked) {
      for (let i = 0; i < elememts.length; i++) {
        elememts[i].style.backgroundColor = 'rgba(0, 127, 163, 0.05)';
        elememts[i].style.border = '1px solid rgba(0, 127, 163, 0.5)';
      }
      for (let i = 0; i < this.state.userListData.length; i++) {
        selectedBooksIndex.push(`book${i}`);
        for (var j = 0; j < usersSelected.length; j++) {
          if (this.state.userListData[i].identity.id == usersSelected[j]) break;
        }
        if (j == usersSelected.length) usersSelected.push(this.state.userListData[i].identity.id);
      }
    } else {
      usersSelected = [];
      for (let i = 0; i < elememts.length; i++) {
        elememts[i].style.backgroundColor = 'white';
        elememts[i].style.border = '1px solid #d3d3d3';
      }
    }
    this.setState({
      selectedStudentNumber: usersSelected.length,
      displayMessage: intl.formatMessage(language.getText('students'))
    });
  }

  updateUserCourseFetched = function (setIesUserFetchData) {
    //  let userListDataArray = setIesUserFetchData.data.matches;
    this.setState({
      userListData: setIesUserFetchData.data.matches,
      isLoading: false
    });
  }

  hidePopup = function () {
    usersSelected.length = 0;
    if (typeof this.props.handleClose === 'function') {
      this.props.handleClose();
    }
  }

  interactiveActivityClick = function (event) {
    let selectedOptionValue;
    if (event.target.value === 'on') {
      selectedOptionValue = true;
    } else {
      selectedOptionValue = false;
    }
    this.setState({
      selectedValue: selectedOptionValue
    });
  }

  componentWillUnmount = function () {
    // AppStore.removeListener(AppConstants.EventTypes.FETCH_IES_USER, this.updateUserCourseFetched);
    try {
      if (DataFormatter.msieversion()) {
        document.getElementsByClassName('mainAppContainer')[0].style.overflowY = 'auto';
      }
    } catch (error) { }
  }

  showNameBoxes = function () {
    const that = this;
    return (
      <div>
        {this.state.userListData.map((sample, index) => (
          <div
            className="checkboxesdiv"
            onKeyDown={(event) => {
              if (event.key == 'Enter') {
                document.activeElement.click();
              }
            }}
          >
            <FormControlLabel
              control={(
                <Checkbox
                // label={sample.identityProfile['givenName'] + " " + sample.identityProfile['familyName']}
                  checked={(selectedBooksIndex.indexOf(`book${index}`) > -1)}
                  onChange={(event) => { that.handleChange(index, sample.identity.id, event); }}
                // labelStyle = {(selectedBooksIndex.indexOf('book' +index) > -1) ? {color:'#007fa3'}:{color:'#393c3d'}}
                  checkedIcon={<i className="material-icons" style={{ color: '#007da7' }}>check_box</i>}
                  uncheckedIcon={<i className="material-icons" style={{ color: '#74797b' }}>check_box_outline_blank</i>}
                />
)}
              style={(selectedBooksIndex.indexOf(`book${index}`) > -1) ? { color: '#007fa3' } : { color: '#393c3d' }}
              label={`${sample.identityProfile.givenName} ${sample.identityProfile.familyName}`}
            />
          </div>
        ))
        }
      </div>
    );
  }

  handleCloseIconEnterKey = function (event) {
    const isTabPressed = event.key === 'Enter' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
    if (!isTabPressed) {
      return;
    }
    if (event.key === 'Enter') {
      this.hidePopup();
      // changes For DL-122
      const baacTextBookThumnailImage = document.getElementsByClassName('baacTextBookThumnailImage')[0];
      baacTextBookThumnailImage.tabIndex = '0';
      // baacTextBookThumnailImage.focus();
      if (baacTextBookThumnailImage) {
        baacTextBookThumnailImage.focus();
        document.getElementsByClassName('baacTextBookThumnailImage')[0].nextSibling.focus();
      }

      return;
    }
    if (event.key === 'Tab' || event.key === 'Shift' || event.shiftKey) {
      document.getElementsByClassName('selectall-checkbox')[0].focus();
    }
  }

  handleAccessibility = function (event) {
    const isTabPressed = event.key === 'Escape' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
    if (!isTabPressed) {
      return;
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      this.hidePopup();
      return false;
    }
    if (event.key === 'Tab' || event.shiftKey) {
      if (event.target.id == 'send_books') {
        document.getElementsById('send_books_close')[0].focus();
        event.preventDefault();
        return false;
      }
      if ((event.target.id == 'activity_off' || event.target.id == 'activity_off_label') && usersSelected.length == 0) {
        document.getElementsById('send_books_close')[0].focus();
        event.preventDefault();
        return false;
      }
    }
  }

  render() {
    const { language, intl } = this.props;
    const {
      userListData,
      selectedStudentNumber,
      isLoading,
      displayMessage
    } = this.state;
    const isiPad = navigator.userAgent.match(/iPad/i) != null;
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div tabIndex="0" className="fixedBackground">
          <LoadingOverlay isLoading={isLoading} />
          <div className={(isiPad ? 'ipadcontainer' : 'container')} onKeyDown={this.handleAccessibility.bind(this)}>
            <div className={`clearFix ${usersSelected.length > 0 ? 'headerActive' : 'header'}`}>
              <div className={`checkCircle ${usersSelected.length == 0 ? 'hideHeaderButton' : ''}`}><i className="material-icons">check</i></div>
              <p className="mainHeading">{intl.formatMessage(language.getText('selectStudents'))}</p>
              <i tabIndex="0" className="material-icons close" id="send_books_close" onKeyDown={this.handleCloseIconEnterKey.bind(this)} onClick={this.hidePopup.bind(this)}>clear</i>
            </div>
            <div className="selectall-button">
              <div tabIndex="0" className="selectall-checkbox" onKeyDown={this.onSelectAllKeydown}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={userListData.length && selectedStudentNumber && (selectedStudentNumber === userListData.length)}
                      onChange={(event) => { this.selectAll(event); }}
                  // onClick={this.selectAll.bind(this)}
                      checkedIcon={<i className="material-icons" style={{ color: '#007da7' }}>check_box</i>}
                      uncheckedIcon={<i className="material-icons" style={{ color: '#74797b' }}>check_box_outline_blank</i>}
                    />
)}
                  label={intl.formatMessage(language.getText('select_all'))}
                />
              </div>
              <p className="showStudentNumber">
                <span style={{ fontWeight: '600' }}>
                  {' '}
                  {selectedStudentNumber}
                  {' '}
                </span>
                {' '}
                <span style={{ fontWeight: '300' }}>
                  {' '}
                  {displayMessage}
                  {' '}
                  {intl.formatMessage(language.getText('Allocated')).toLowerCase()}
                  {' '}
                </span>
              </p>
            </div>
            <div className="middleContainer clearFloat">
              <div className="loadUserNames">
                {this.showNameBoxes()}
              </div>
            </div>
            <div className="interactive-activity">
              <div className="radioButtons">
                <p className="middleContainerSubHeading">{intl.formatMessage(language.getText('interactiveActivity'))}</p>
                <span className="activity-icon">
                  <img src={InteractiveActivity} />
                </span>
                <RadioGroup name="shipSpeed" value="on">
                  <FormControlLabel
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key == 'Enter') {
                        this.setState({
                          selectedValue: true
                        });
                      }
                    }}
                    value="on"
                    control={(
                      <Radio
                        value="on"
                    // label={intl.formatMessage(language.getText('On_label'))}
                    // labelStyle={{color:'#393c3d', fontSize: '16px', fontFamily: 'Open Sans'}}
                        style={styles.radioButton}
                        onClick={this.interactiveActivityClick}
                        checked={this.state.selectedValue == true}
                        id="activity_on"
                      />
)}
                    label={intl.formatMessage(language.getText('On_label'))}
                    style={{ color: '#393c3d', fontSize: '16px', fontFamily: 'Open Sans' }}
                  />
                  <FormControlLabel
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key == 'Enter') {
                        this.setState({
                          selectedValue: false
                        });
                      }
                    }}
                    value="off"
                    control={(
                      <Radio
                        value="off"
                    // label={intl.formatMessage(language.getText('Off_label'))}
                    // labelStyle={{color:'#393c3d', fontSize: '16px', fontFamily: 'Open Sans'}}
                        style={styles.radioButton}
                        onClick={this.interactiveActivityClick}
                        checked={this.state.selectedValue == false}
                        id="activity_off"
                      />
)}
                    label={intl.formatMessage(language.getText('Off_label'))}
                    style={{ color: '#393c3d', fontSize: '16px', fontFamily: 'Open Sans' }}
                    id="activity_off_label"
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="sendBooksFooter" style={{ position: 'relative' }}>
              <div className="sendBookActionsPop">
                <div className="submitButtonPop">
                  <Button variant="contained" id="send_books" tabIndex="0" className={usersSelected.length > 0 ? 'buttonActiveState' : 'buttonInActiveState'} onClick={this.submit.bind(this)} primary={usersSelected.length > 0} disabled={usersSelected.length == 0} labelStyle={usersSelected.length == 0 ? styles.inActiveButton : styles.activeButton}>{intl.formatMessage(language.getText('send_books_button_label'))}</Button>
                </div>
                {/* <div className="cancelButtonPop">
              <Button tabIndex="0" onClick={this.hidePopup.bind(this)} primary={true} labelStyle={{'fontWeight':'600','color':'#007fa3',fontSize: '16px','fontFamily': 'Open Sans'}}>{intl.formatMessage(language.getText('cancel_button_label'))}</Button>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
// export default Popup;
Popup.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired,
  iesUserDetailsByCourse: shapes.modelOf(iesUserDetailsByCourse).isRequired
};
export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language', 'iesUserDetailsByCourse'),
  observer
)(injectIntl(Popup));
