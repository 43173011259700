import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework, { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import $ from 'jquery';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import injectTapEventPlugin from 'react-tap-event-plugin';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from '@material-ui/core/SvgIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import HardwareKeyboardArrowBack from '@material-ui/icons/ArrowBack';
import ReactTooltip from 'react-tooltip';
import TagManager from 'react-gtm-module';
import iesUserDetailsByCourse from '../model/IESUserDetailsByCourse';
import StudentPassword from './StudentPassword';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

// var lang = DataFormatter.getLanguage();
const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  }
});

try {
  injectTapEventPlugin();
} catch (e) { }

const styles = {
  backButton: {
    height: '24px',
    width: '24px',
    padding: '0px',
    marginRight: '20px',
    top: '5px'
  },
  backButtonIcon: {
    height: '24px',
    width: '24px'
  }
};

const AddStudentIcon = props => (
  <SvgIcon {...props}>
    <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
  </SvgIcon>
);

const RemoveStudentIcon = props => (
  <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.65 0C10.6717 0 11.5 0.828273 11.5 1.85L11.494 2H16.125C16.6082 2 17 2.39175 17 2.875C17 3.35825 16.6082 3.75 16.125 3.75H16V15.5C16 16.8807 14.8807 18 13.5 18H4.5C3.11929 18 2 16.8807 2 15.5V3.75H1.875C1.39175 3.75 1 3.35825 1 2.875C1 2.39175 1.39175 2 1.875 2H6.50599C6.50202 1.95052 6.5 1.9005 6.5 1.85C6.5 0.828273 7.32827 0 8.35 0H9.65ZM14.25 3.75H3.75V15.5C3.75 15.9142 4.08579 16.25 4.5 16.25H13.5C13.9142 16.25 14.25 15.9142 14.25 15.5V3.75ZM7 6V13.5C7 14.0523 6.55228 14.5 6 14.5C5.44772 14.5 5 14.0523 5 13.5V6H7ZM10 6V13.5C10 14.0523 9.55229 14.5 9 14.5C8.44772 14.5 8 14.0523 8 13.5V6H10ZM13 6V13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5V6H13Z" fill="#6A7070" />
  </SvgIcon>
);

const InfoIcon = props => (
  <SvgIcon {...props}>
    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </SvgIcon>
);

const SelectFieldIcon = props => (
  <SvgIcon {...props}>
    <path fill="#161616" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
  </SvgIcon>
);

class AddStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [{
        firstName: '',
        lastName: '',
        displayName: '',
        studentID: '',
        passwordType: 'Text',
        passwordSelectValue: 0,
        passwordText: '',
        passwordUrl: ''
      }],
      showPasswordDialog: false,
      createStudentDialog: false,
      studentList: [],
      userListFetchStatus: null,
      newUserList: [],
      passwordList: null,
      passwordTypeField: null,
      error: true,
      errorText: '',
      batchItemsFetched: false,
      batchItemMessage: '',
      batchStatus: '',
      studentCount: this.props.studentCount,
      maxStudentDialog: false,
      batchPdfUrl: '',
      validatedRow: '',
      isUserCreationInitiated: false,
      isIESUserFetchInitiated: false
    };
    this.handleStudentCreation = this.handleStudentCreation.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleCloseDialogEnterKey = this.handleCloseDialogEnterKey.bind(this);
    this.handleCloseDialogEscapeKey = this.handleCloseDialogEscapeKey.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
  }

    componentDidMount = function () {
      window.addEventListener('popstate', (event) => {
        this.browserBackHandler(event);
      });
      // AppActions.iesNewUserFetch();
      // var course = DataFormatter.getObjectInStorage('courseSelected');
      // var courseId = course.id;
      // var roleValue = constants.roleValue;
      // AppActions.getIesUserDetailsByCourse(courseId, roleValue);- Replacement for this call is added below
      Framework.getEventManager().on(constants.IES_USER_FETCHED, (setIesUserFetchData) => {
        if (this.state.isIESUserFetchInitiated == false) {
          this.setState({ isIESUserFetchInitiated: true });
          Framework.getEventManager().publish(constants.FETCH_IES_USER_REQUESTED,
            {
              userIdArray: setIesUserFetchData
            });
        }
      });
      // AppActions.iesPasswordFetch();
      // AppStore.on(AppConstants.EventTypes.FETCH_IES_USER,this.callbackFetchIesUser);- Replacement for this call is added below
      Framework.getEventManager().on(constants.FETCH_IES_USER_SUCCESS, (IesUserData) => {
        // if(this.state.isIESUserFetchInitiated == false) {
        // this.setState({isIESUserFetchInitiated: true});
        this.callbackFetchIesUser(IesUserData);
        // }
      });
      // AppStore.on(AppConstants.EventTypes.FETCH_NEW_IES_USER,this.callbackFetchNewIesUser);
      Framework.getEventManager().on(constants.FETCH_NEW_IES_USER_SUCCESS, (setIesNewUserData) => {
        this.callbackFetchNewIesUser(setIesNewUserData);
      });
      // AppStore.on(AppConstants.EventTypes.PASSWORD_LIST_FETCH,this.callbackFetchIesPassword);
      Framework.getEventManager().on(constants.IES_PASSWORD_LIST_FETCH_SUCCESS, (setIesPasswordData) => {
        this.callbackFetchIesPassword(setIesPasswordData);
      });
      // AppStore.on(AppConstants.EventTypes.CREATE_STUDENT,this.callbackCreateStudent);- This call is already commented in original source
      // AppStore.on(AppConstants.EventTypes.BULK_USERS_CREATE,this.callbackBulkUsersCreate);- Replacement for this call is added below
      Framework.getEventManager().on(constants.CREATE_STUDENT_SUCCESS, (setBulkUsersCreated) => {
        if (this.state.isUserCreationInitiated == false) {
          this.setState({ isUserCreationInitiated: true });
          this.callbackBulkUsersCreate(setBulkUsersCreated);
        }
      });
      // AppStore.on(AppConstants.EventTypes.STUDENT_ENROLL_SCHOOL_SECTION_SUCCESS,this.callbackStudentsEnroll);
      Framework.getEventManager().on(constants.STUDENT_ENROLL_SCHOOL_SECTION_SUCCESS, (setBulkStudentsEnrollSchoolSection) => {
        this.callbackStudentsEnroll(setBulkStudentsEnrollSchoolSection);
      });
    };

    browserBackHandler = function (event) {
      if (event) {
        //  this.handleCloseDialog("createStudent",this);
        const urlprefix = window.location.origin;
        window.location.assign(`${urlprefix}/app`);
      }
    }

    UNSAFE_componentWillMount = function () {
      const { iesUserDetailsByCourse } = this.props;
      // AppActions.iesNewUserFetch();
      Framework.getEventManager().publish(constants.FETCH_NEW_IES_USER_REQUESTED, {});
      const course = DataFormatter.getObjectInStorage('courseSelected');
      if (course.id.length > 0) {
        const courseId = course.id;
        const roleValue = constants.roleValue;
        // AppActions.getIesUserDetailsByCourse(courseId, roleValue); - Replacement for this call is added below
        iesUserDetailsByCourse.fetch(courseId, roleValue);
      }
      Framework.getEventManager().publish(constants.IES_PASSWORD_LIST_FETCH_REQUESTED, {});
    };

    createUI() {
      const { language, intl } = this.props;
      return this.state.values.map((inputField, i) => (
        <TableRow key={i}>
          <TableCell component="th" scope="row">
            <TextField id={`firstName${i}`} placeholder={intl.formatMessage(language.getText('first_name'))} className="student-input" type="text" value={inputField.firstName || ''} onChange={this.handleInputChange.bind(this, i)} />
          </TableCell>
          <TableCell>
            <TextField id={`lastName${i}`} placeholder={intl.formatMessage(language.getText('last_name'))} className="student-input" type="text" value={inputField.lastName || ''} onChange={this.handleInputChange.bind(this, i)} />
          </TableCell>
          {/* <TableCell>
                    <TextField id={`displayName${i}`} placeholder="Display Name" className="student-input" type="text" value={inputField.displayName || ''} onChange={this.handleInputChange.bind(this, i)} />
                </TableCell> */}
          <TableCell>
            <TextField id={`studentID${i}`} placeholder={intl.formatMessage(language.getText('student_id'))} className="student-input" type="text" value={inputField.studentID || ''} onChange={this.handleInputChange.bind(this, i)} />
          </TableCell>
          <TableCell>
            <Select id={`passwordType${i}`} value={inputField.passwordSelectValue} className="password-type-dropdown" MenuProps={{ className: 'password-type-dropdown-menu' }} onChange={this.handleSelectChange.bind(this, i)}>
              <MenuItem classes={{ root: 'password-type-dropdown-text' }} value={0}>{intl.formatMessage(language.getText('select_password'))}</MenuItem>
              <MenuItem classes={{ root: 'password-type-dropdown-text' }} value={1}>{intl.formatMessage(language.getText('password_text_option'))}</MenuItem>
              <MenuItem classes={{ root: 'password-type-dropdown-text' }} value={2}>{intl.formatMessage(language.getText('password_image_option'))}</MenuItem>
            </Select>
          </TableCell>
          <TableCell>
            {(inputField.passwordUrl === ''
              ? <TextField id={`passwordText${i}`} placeholder="" className="student-input" type="text" value={inputField.passwordText || ''} onChange={this.handleInputChange.bind(this, i)} onFocus={this.handlePasswordBlur.bind(this, i)} onBlur={this.handlePasswordBlur.bind(this, i)} />
              : <TextField id={`passwordText${i}`} placeholder={intl.formatMessage(language.getText('image_selected'))} className="student-input" type="text" InputProps={{ readOnly: true }} value={intl.formatMessage(language.getText('image_selected'))} />)}
          </TableCell>
          <TableCell>
            {
              <Button
                variant="contained"
                color="primary"
                className="remove-student-button"
                labelPosition="after"
                primary
                startIcon={<RemoveStudentIcon className="remove-student-icon" />}
                onClick={this.removeStudent.bind(this, i)}
              >
                {intl.formatMessage(language.getText('remove_student'))}
              </Button>
                    }
          </TableCell>
        </TableRow>
      ));
    }

    renderTitle = function () {
      const { language, intl } = this.props;
      return (
        <div className="back-to-student-list">
          <IconButton onClick={this.handleCloseDialog.bind(this, 'createStudent')} aria-label="To Go back to student list" style={styles.backButton}>
            <HardwareKeyboardArrowBack style={styles.backButtonIcon} />
          </IconButton>
          <p aria-live="polite">{intl.formatMessage(language.getText('add_new_student'))}</p>
        </div>
      );
    }

    handleInputChange(i, event) {
      const values = [...this.state.values];
      if (event.target.value !== '') {
        this.setState({
          error: false,
          errorText: ''
        });
        $(`#${event.target.id}`).removeClass('inputerror');
      } else {

      }
      if (event.target.id === `firstName${i}`) {
        values[i].firstName = event.target.value;
      } else if (event.target.id === `lastName${i}`) {
        values[i].lastName = event.target.value;
        // } else if (event.target.id === "displayName" + i) {
        //     values[i].displayName = event.target.value;
      } else if (event.target.id === `studentID${i}`) {
        values[i].studentID = event.target.value;
      } else if (event.target.id === `passwordText${i}`) {
        values[i].passwordText = event.target.value;
      }
      this.setState({ values });
      if (values[i].firstName !== '' && values[i].lastName !== '' && values[i].passwordSelectValue !== '' && values[i].passwordText !== '') {
        this.setState({ validatedRow: i });
      } else {
        this.setState({ validatedRow: '' });
      }
    }

    handlePasswordBlur(i, event) {
      const values = [...this.state.values];
      const prevValue = values[i].passwordType;
      if (prevValue == 'Text') {
        if (event.type == 'focus') {
          event.target.type = 'text';
        } else {
          event.target.type = 'password';
        }
      } else {
        event.target.type = 'text';
      }
    }

    handleSelectChange(i, event) {
      const value = event.target.value;
      const values = [...this.state.values];
      const prevValue = values[i].passwordSelectValue;
      values[i].passwordSelectValue = value;
      values[i].passwordType = (value == 1 ? 'Text' : (value == 2 ? 'Image' : ''));
      if (value === '' || value === 0 || prevValue !== value) {
        values[i].passwordText = '';
        values[i].passwordUrl = '';
        this.setState({ validatedRow: '' });
      }
      this.setState({
        error: false,
        errorText: ''
      });
      $(`#passwordType${i}`).parent().removeClass('inputerror');
      this.setState({ values });
      if (value == 2) {
        this.setState({
          showPasswordDialog: true
        });
        this.setState({
          passwordTypeField: i
        });
        $(`#passwordText${i}`).prop('type', 'text');
      }
    }

    selectPasswordImage = (passwordText, passwordImage) => {
      const values = [...this.state.values];
      const currentInput = this.state.passwordTypeField;
      if (currentInput !== null) {
        values[currentInput].passwordText = passwordText;
        values[currentInput].passwordUrl = passwordImage;
        this.setState({ values });
        this.setState({
          showPasswordDialog: false
        });
      }
      if (values[currentInput].firstName !== '' && values[currentInput].lastName !== '' && values[currentInput].passwordSelectValue !== '' && values[currentInput].passwordText !== '') {
        this.setState({ validatedRow: currentInput });
      } else {
        this.setState({ validatedRow: '' });
      }
    }

    addStudent(i, event) {
      const values = [...this.state.values];
      let validationError = '';
      if (values.length > 0) {
        validationError = this.validateInputValues(i);
      }
      if (validationError === '') {
        if (this.state.studentCount + values.length >= 35) {
          this.setState({ maxStudentDialog: true });
        } else {
          values.push({
            firstName: '',
            lastName: '',
            displayName: '',
            studentID: '',
            passwordType: 'Text',
            passwordSelectValue: 1,
            passwordText: '',
            passwordUrl: ''
          });
          this.setState({ values });
        }
      } else {
        this.setState({
          error: true,
          errorText: validationError
        });
      }
    }

    removeStudent(i) {
      // debugger;
      const values = [...this.state.values];
      values.splice(i, 1);
      this.setState({ values });
    }

    validateInputValues(i, focus=true) {
      const { language, intl } = this.props;
      let validationError = '';
      const values = [...this.state.values];
      const regex = new RegExp('^[a-zA-Z0-9]+$');
      if (values[i].firstName === '') {
        validationError = `${intl.formatMessage(language.getText('please_enter'))} ${intl.formatMessage(language.getText('first_name'))}`;
        if(focus) {
          $(`#firstName${i}`).addClass('inputerror');
        }
      } else if (values[i].lastName === '') {
        validationError = `${intl.formatMessage(language.getText('please_enter'))} ${intl.formatMessage(language.getText('last_name'))}`;
        if(focus) {
          $(`#lastName${i}`).addClass('inputerror');
        }
        // } else if (values[i].displayName === "") {
        //     validationError = "Please enter Display Name";
        //     $('#displayName' + i).addClass('inputerror');
      } else if (values[i].passwordSelectValue === '' || values[i].passwordSelectValue === 0) {
        validationError = `${intl.formatMessage(language.getText('please_select'))} ${intl.formatMessage(language.getText('password_type'))}`;
        if(focus) {
          $(`#passwordType${i}`).parent().addClass('inputerror');
        }
      } else if (values[i].passwordType === 'Text') {
        if (values[i].passwordText === '') {
          validationError = `${intl.formatMessage(language.getText('please_select'))} ${intl.formatMessage(language.getText('password_text_validation'))}`;
          if(focus) {
            $(`#passwordText${i}`).addClass('inputerror');
          }
        } else if (values[i].passwordText.length < 5 || regex.test(values[i].passwordText) === false) {
          validationError = intl.formatMessage(language.getText('password_validation'));
          if(focus) {
            $(`#passwordText${i}`).addClass('inputerror');
          }
        }
      } else if (values[i].passwordType === 'Image') {
        if (values[i].passwordUrl === '') {
          validationError = `${intl.formatMessage(language.getText('please_select'))} ${intl.formatMessage(language.getText('password_image_validation'))}`;
          if(focus) {
            $(`#passwordText${i}`).addClass('inputerror');
          }
        }
      }
      return validationError;
    }

    handleStudentCreation(event) {
      const { language, intl } = this.props;
      const userData = this.state.values;
      const self = this;
      let validationError = '';
      userData.map((userdata, i) => {
        if (validationError === '') {
          validationError = this.validateInputValues(i);
          if (validationError) {
            this.setState({
              error: true,
              errorText: validationError
            });
          }
        }
      });
      if (validationError === '') {
        const unusedUserData = this.state.newUserList.filter((newUser) => {
          const existingStudents = self.state.studentList;
          if (existingStudents !== null && existingStudents.length > 0) {
            if (!existingStudents.includes(newUser.imageUrl)) {
              return newUser;
            }
          } else {
            return newUser;
          }
        });
        if (typeof userData !== 'undefined' && userData.length > 0) {
          userData.map((user, index) => {
            user.passType = (user.passwordUrl !== '') ? 'IMG' : '';
            user.userName = unusedUserData[index].userName,
            user.studentIcon = unusedUserData[index].imageUrl;
          });
        }
        const metaData = this.createMetadata(userData);
        // AppActions.bulkUsersCreate({ "items": metaData });
        Framework.getEventManager().publish(constants.CREATE_STUDENT_REQUESTED,
          {
            userId: DataFormatter.getKeyFromObject('userInformation', 'id'),
            payload: { items: metaData }
          });
        localStorage.setItem('isStudentAdd', 'true');
        const gtmData = {
          dataLayer: {
            event: 'adding_student',
            event_category: intl.formatMessage(language.getText('adding_student')),
            event_action: intl.formatMessage(language.getText('add_student_clicked')),
            event_label: null,
            creation_type: 'Individual Student Creation',
            number_of_students: userData.length,
            transaction_local_dt: CommonUtils.getLocalIsoTime()
          }
        };
        TagManager.dataLayer(gtmData);
        event.preventDefault();
      }
    }
    handleDisabled() {
      const userData = this.state.values;
      let validationError = '';
      const totalCount = userData?.length;
      let count = 0;
      userData.forEach((userdata, i) => {
        validationError = this.validateInputValues(i, false);
        if (!validationError) {
          count = count + 1;
        }
      });
      return totalCount !== count;
    }
    createMetadata(studentData) {
      const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
      const countryCode = iesUserDetails.identity.homeCountryCode;
      const metaData = []; let newValues = {}; let userMetaData = {}; let
        passImageData = {};
      studentData.map((student, index) => {
        userMetaData = {
          // "name": student.displayName,
          username: student.userName,
          fName: student.firstName,
          lName: student.lastName,
          roleValue: 'student',
          homeCountryCode: countryCode,
          password: student.passwordText,
          identityScheme: 'ies',
          createOrRegister: 'create',
          studentIcon: student.studentIcon,
          passwordUrl: student.passwordUrl,
          cardNumber: student.studentID
        };
        if (student.passType === 'IMG') {
          passImageData = { passType: student.passType };
          userMetaData = { ...userMetaData, ...passImageData };
        }
        newValues = {
          createdBy: DataFormatter.getKeyFromObject('userInformation', 'id'),
          type: 'user',
          metadata: userMetaData
        };
        metaData.push(newValues);
      });
      if (metaData.length > 0) {
        metaData.push({
          createdBy: DataFormatter.getKeyFromObject('userInformation', 'id'),
          type: 'pdf-generation',
          status: 'submitted',
          metadata: {
            isNotifyTeacher: true,
            isEmailNotification: true,
            isPdfGeneration: true,
            SchoolId: DataFormatter.getObjectInStorage('schoolId')
          }
        });
      }
      return metaData;
    }

    handleCloseDialog(type, event) {
      this.setState({ showPasswordDialog: false });
      this.setState({ createStudentDialog: false });
      this.setState({ maxStudentDialog: false });
      if (type === 'createStudent') {
        this.props.showStudentList();
      }
    }

    handleCloseDialogEnterKey = function (event) {
      const isTabPressed = event.key === 'Escape' || event.key === 'Enter' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
      if (!isTabPressed) {
        return;
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        this.setState({ showPasswordDialog: false });
        return false;
      }
      if (event.key === 'Enter') {
        this.setState({ showPasswordDialog: false });
      }
    }

    handleCloseDialogEscapeKey = function (event) {
      const isTabPressed = event.key === 'Escape' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
      if (!isTabPressed) {
        return;
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        this.setState({ showPasswordDialog: false });
        return false;
      }
    }

    showStudentList = () => {
      this.props.showStudentList();
    }

    renderstats = function () {
      return (
        <div>
          <div tabIndex="0" className="addstudenttitle">
            {this.renderTitle()}
          </div>
        </div>
      );
    };

    callbackFetchIesUser = (setIesUserFetchData) => {
      if (typeof setIesUserFetchData !== 'undefined') {
        if (typeof setIesUserFetchData.data.matches !== 'undefined' && setIesUserFetchData.data.matches.length > 0) {
          const userWithImages = [];
          const teacherUsers = [];
          setIesUserFetchData.data.matches.map((user) => {
            if (typeof user !== 'undefined') {
              if (
                typeof user.credentials !== 'undefined'
                        && user.credentials.length > 0
              ) {
                if (
                  typeof user.identityProfile !== 'undefined' && typeof user.identity !== 'undefined'
                ) {
                  if (
                    typeof user.credentials[0].userName !== 'undefined'
                                && user.credentials[0].userName !== ''
                                && typeof user.identityProfile.givenName !== 'undefined'
                                && user.identityProfile.givenName !== ''
                  ) {
                    // Check if the user is Student
                    if (typeof user.credentials[0].userNameImageUrl !== 'undefined'
                                    && user.credentials[0].userNameImageUrl !== '') {
                      userWithImages.push(user.credentials[0].userNameImageUrl);
                    }
                  }
                }
              }
            }
          });
          this.setState({
            studentList: userWithImages,
            userListFetchStatus: { status: 'complete' }
          });
        }
      }
    };

    callbackFetchNewIesUser = (setIesNewUserData) => {
      if (typeof setIesNewUserData !== 'undefined' && typeof setIesNewUserData.data.images !== 'undefined' && setIesNewUserData.data.images.length > 0) {
        // 1. Check for username & userimage & givenname should be populated
        this.setState({
          newUserList: setIesNewUserData.data.images
        });
      }
    };

    callbackFetchIesPassword = (setIesPasswordList) => {
      const iesPasswordList = [];
      if (typeof setIesPasswordList.data.images !== 'undefined' && setIesPasswordList.data.images.length > 0) {
        setIesPasswordList.data.images.map((image) => {
          if (typeof image.imageUrl !== 'undefined' && image.imageUrl !== '' && typeof image.password !== 'undefined' && image.password !== '') {
            iesPasswordList.push({
              logoURL: image.imageUrl, password: image.password
            });
          }
        });
        this.setState({ passwordList: iesPasswordList, passwordListFetchStatus: { status: 'complete' } });
      }
    }

    callbackCreateStudent = () => {}

    callbackBulkUsersCreate = (setBulkUsersCreated) => {
      const mapUserToSchoolClass = [];
      this.setState({
        createStudentDialog: true
      });
      if (setBulkUsersCreated) {
        const batchId = setBulkUsersCreated.batch.id;
        const course = DataFormatter.getObjectInStorage('courseSelected');
        const originId = course.externalRef[0].originId;
        setBulkUsersCreated.items.map((userdata) => {
          if (userdata.type === 'user') {
            const classData = {
              type: 'class-user',
              schoolId: DataFormatter.getObjectInStorage('schoolId'),
              externalRef: [{
                originId,
                source: 'dl-classcode'
              }],
              userUID: userdata.uid,
              cardNumber: userdata.metadata.cardNumber,
              roleValue: 'student'
            };
            mapUserToSchoolClass.push(classData);
            const schoolData = {
              type: 'school-user',
              schoolId: DataFormatter.getObjectInStorage('schoolId'),
              userUID: userdata.uid,
              roleValue: 'student'
            };
            mapUserToSchoolClass.push(schoolData);
          }
        });
        if (mapUserToSchoolClass.length > 0) {
          const mapUserData = { links: mapUserToSchoolClass };
          // AppActions.bulkEnrollUsersToSchoolClass(batchId, mapUserData);-- This call is replaced with below event triggering
          Framework.getEventManager().publish(constants.STUDENT_ENROLL_SCHOOL_SECTION_REQUESTED,
            {
              batchId,
              payload: mapUserData
            });
        }
      }
    };

    callbackStudentsEnroll = (setBulkStudentsEnrollSchoolSection) => {
      const { language, intl } = this.props;
      if (setBulkStudentsEnrollSchoolSection.length > 0) {
        const batchId = setBulkStudentsEnrollSchoolSection[0].batchId;
        const message = (
          <div>
            {intl.formatMessage(language.getText('student_creation_started'))}
            <br />
            {' '}
            {intl.formatMessage(language.getText('close_popup'))}
            {' '}
            {intl.formatMessage(language.getText('class_list_ready'))}
          </div>
        );
        this.setState({ batchItemsFetched: true, batchItemMessage: message });
      }
    }

    render() {
      const { language, intl } = this.props;
      return (
        <MuiThemeProvider theme={muiTheme}>
          <div id="addStudentContainer" className="addstudentcontainer">
            {this.renderstats()}
            <div className="addStudentContent">
              <Table className="addStudentTable" aria-label="simple table">
                <TableHead>
                  <TableRow className="student-header">
                    <TableCell>
                      {intl.formatMessage(language.getText('first_name'))}
                      {' '}
                      <span className="requiredfield">*</span>
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage(language.getText('last_name'))}
                      {' '}
                      <span className="requiredfield">*</span>
                    </TableCell>
                    {/* <TableCell>Display Name <span className="requiredfield">*</span></TableCell> */}
                    <TableCell>{intl.formatMessage(language.getText('student_id'))}</TableCell>
                    <TableCell className="password-type-label">
                      <span data-tip data-for="passwordToolTip"><InfoIcon className="info-icon" /></span>
                      <ReactTooltip className="password-info" id="passwordToolTip" place="bottom" effect="solid" type="light" opensOn="Click"><span className="tooltipText">{intl.formatMessage(language.getText('password_tooltip'))}</span></ReactTooltip>
                      {intl.formatMessage(language.getText('password_type'))}
                      {' '}
                      <span className="requiredfield">*</span>
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage(language.getText('password_text'))}
                      {' '}
                      <span className="requiredfield">*</span>
                    </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.createUI()}
                </TableBody>
              </Table>
            </div>
            <div>
              {(this.state.error === true
                            && (
                            <div className="errortext">
                              <p>{this.state.errorText}</p>
                            </div>
                            ))}
              <div className="form-submit">
                {/* {(this.state.validatedRow === this.state.values.length - 1 ? <Button variant='contained' color="primary" className="add-student-button"  labelPosition="after" primary={true} icon={<AddStudentIcon className="add-student-icon" />} onClick={this.addStudent.bind(this, this.state.values.length - 1)}>Add Student</Button> : <Button variant='contained' color="primary" className="add-student-disabled" labelPosition="after" primary={true} icon={<AddStudentIcon className="add-student-icon" />}>Add Student</Button>)} */}
                {(<Button variant="contained" color="primary" className="add-student-button" labelPosition="after" primary icon={<AddStudentIcon className="add-student-icon" />} onClick={this.addStudent.bind(this, this.state.values.length - 1)}>
+
                  {intl.formatMessage(language.getText('add_student'))}
                  </Button>)}
                {(this.state.createStudentDialog === false && this.state.values.length > 0) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className="submit-button"
                    label={intl.formatMessage(language.getText('add_student_button'))}
                    primary
                    onClick={this.handleStudentCreation.bind(this)}
                    disabled={this.handleDisabled()}
                  >
                    {intl.formatMessage(language.getText('add_student_button'))}
                  </Button>
                ) : <Button variant="contained" color="primary" className="submit-button-disabled" primary disabled>{intl.formatMessage(language.getText('add_student_button'))}</Button>}
              </div>
            </div>
            <Dialog title="" modal open={this.state.showPasswordDialog} className="popup-outer-container">
              <div className="popup-message-container" onKeyDown={this.handleCloseDialogEscapeKey.bind(this)}>
                <div tabIndex="0" className="popup-close" onClick={this.handleCloseDialog.bind(this, 'passwordImage')} onKeyDown={this.handleCloseDialogEnterKey.bind(this)}>X</div>
                <br />
                <br />
                <br />
                <StudentPassword passwordList={this.state.passwordList} selectPasswordImage={this.selectPasswordImage} />
              </div>
            </Dialog>
            <Dialog title="" modal open={this.state.createStudentDialog} contentClassName="popup-outer-container">
              <div className="popup-message-container">
                {/* <div className="popup-close" onClick={this.handleCloseDialog.bind(this, "createStudent")}>X</div> */}
                <h3 />
                <br />
                <br />
                <div className="create-student-container">
                  <div className="row">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                      {!this.state.batchItemsFetched && (
                      <div>
                        <div className="circularLoader">
                          <CircularProgress mode="indeterminate" />
                          <br />
                        </div>
                        <div>
                          {' '}
                          {intl.formatMessage(language.getText('student_creation_progress'))}
                          {' '}
                          {intl.formatMessage(language.getText('do_not_close'))}
                        </div>
                      </div>
                      )}
                      {/* {this.state.batchItemsFetched && <div className="row password-list-row">
                                            {this.state.batchItemMessage && <div className="col">{this.state.batchItemMessage}<Button variant="contained" className="no-students-button" primary={true}
                                        onClick={this.handleCloseDialog.bind(this, "createStudent")}>{intl.formatMessage(language.getText(`okButtonLabel`))}</Button></div>}
                                            {/* {this.state.batchPdfUrl && <object width="100%" height="500" data={this.state.batchPdfUrl}></object>} */ /* }
                                        </div>} */}
                      {
                                            // if processing is  done lets close the dialog automatically and move to
                                            this.state.batchItemsFetched && this.handleCloseDialog('createStudent')
                                        }
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            <Dialog title="" modal open={this.state.maxStudentDialog} className="listing-outer-container">
              <div className="listing-message-container">
                <div className="listing-close" onClick={this.handleCloseDialog.bind(this, 'maxStudent')}>X</div>
                <h3 />
                <br />
                <div className="student-list-container">
                  <div className="row">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                      <div className="col no-students-text">
                        {intl.formatMessage(language.getText('max_limit_add'))}
                        {' '}
35
                        {' '}
                        {intl.formatMessage(language.getText('students')).toLowerCase()}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Button
                        variant="contained"
                        color="primary"
                        className="no-students-button"
                        primary
                        onClick={this.handleCloseDialog.bind(this, 'maxStudent')}
                      >
                        {intl.formatMessage(language.getText('okButtonLabel'))}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            {/* <pre>
                        {JSON.stringify(this.state, null, 2)}
                    </pre> */}
          </div>
        </MuiThemeProvider>
      );
    }
}

AddStudent.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired,
  iesUserDetailsByCourse: shapes.modelOf(iesUserDetailsByCourse).isRequired
};

// export default AddStudent;
export default compose(
  inject('language', 'iesUserDetailsByCourse'),
  observer
)(injectIntl(AddStudent));
