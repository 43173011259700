import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import $ from 'jquery';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import injectTapEventPlugin from 'react-tap-event-plugin';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from '@material-ui/core/SvgIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import HardwareKeyboardArrowBack from '@material-ui/icons/ArrowBack';
import ReactTooltip from 'react-tooltip';
import TagManager from 'react-gtm-module';
import StudentPassword from './StudentPassword';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

// var lang = DataFormatter.getLanguage();
const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  }
});

try {
  injectTapEventPlugin();
} catch (e) { }

const styles = {
  backButton: {
    height: '24px',
    width: '24px',
    padding: '0px',
    marginRight: '20px',
    top: '5px'
  },
  backButtonIcon: {
    height: '24px',
    width: '24px'
  }
};

const EditStudentIcon = props => (
  <SvgIcon {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z" />
  </SvgIcon>
);

const InfoIcon = props => (
  <SvgIcon {...props}>
    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </SvgIcon>
);

class EditStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        firstName: props.editStudentData.fName,
        lastName: props.editStudentData.lName,
        displayName: `${props.editStudentData.fName} ${props.editStudentData.lName}`,
        studentID: props.editStudentData.cardNumber ? props.editStudentData.cardNumber : '',
        userId: props.editStudentData.userId,
        passwordType: props.editStudentData.passType,
        passwordSelectValue: 0,
        passwordText: '',
        passwordUrl: ''
      },
      showPasswordDialog: false,
      editStudentDialog: false,
      passwordList: null,
      error: true,
      errorText: '',
      editStudentCompleted: false,
      batchItemMessage: '',
      batchStatus: '',
      studentCount: this.props.studentCount
    };
    this.handleEditStudent = this.handleEditStudent.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleCloseDialogEnterKey = this.handleCloseDialogEnterKey.bind(this);
    this.handleCloseDialogEscapeKey = this.handleCloseDialogEscapeKey.bind(this);
  }

    componentDidMount = function () {
      // AppActions.iesPasswordFetch();--This call is replaced with code in componentWillMount

      // AppStore.on(
      //     AppConstants.EventTypes.PASSWORD_LIST_FETCH,
      //     this.callbackFetchIesPassword
      // );--This call is replaced with below code
      Framework.getEventManager().on(constants.IES_PASSWORD_LIST_FETCH_SUCCESS, (setIesPasswordData) => {
        this.callbackFetchIesPassword(setIesPasswordData);
      });
      // AppStore.on(
      //     AppConstants.EventTypes.EDIT_STUDENT,
      //     this.callbackEditStudent
      // );--This call is replaced with below code
      Framework.getEventManager().on(constants.EDIT_STUDENT_SUCCESS, (setEditStudentData) => {
        this.callbackEditStudent(setEditStudentData);
      });
    };

    UNSAFE_componentWillMount = function () {
      Framework.getEventManager().publish(constants.IES_PASSWORD_LIST_FETCH_REQUESTED, {});
    };

    componentDidUpdate = function () {

    }

    componentWillUnmount() {
      // AppStore.removeListener(
      //     AppConstants.EventTypes.PASSWORD_LIST_FETCH,
      //     this.callbackFetchIesPassword
      // );
      // AppStore.removeListener(
      //     AppConstants.EventTypes.EDIT_STUDENT,
      //     this.callbackEditStudent
      // );
    }

    createUI() {
      const { language, intl } = this.props;
      return (
        <TableRow>
          <TableCell component="th" scope="row">
            <TextField id="firstName" placeholder={intl.formatMessage(language.getText('first_name'))} className="student-input" type="text" value={this.state.values.firstName || ''} onChange={this.handleInputChange.bind(this)} disabled={this.props.accessModel} />
          </TableCell>
          <TableCell>
            <TextField id="lastName" placeholder={intl.formatMessage(language.getText('last_name'))} className="student-input" type="text" value={this.state.values.lastName || ''} onChange={this.handleInputChange.bind(this)} disabled={this.props.accessModel} />
          </TableCell>
          {/* <TableCell>
                <TextField id={`displayName`} placeholder="Display Name" className="student-input" type="text" value={this.state.values.displayName || ''} onChange={this.handleInputChange.bind(this)} />
            </TableCell> */}
          <TableCell>
            <TextField id="studentID" placeholder={intl.formatMessage(language.getText('student_id'))} className="student-input" type="text" value={this.state.values.studentID || ''} onChange={this.handleInputChange.bind(this)} disabled={this.props.accessModel} />
          </TableCell>
          <TableCell>
            <Select id="passwordType" value={this.state.values.passwordSelectValue} className="password-type-dropdown" MenuProps={{ className: 'password-type-dropdown-menu' }} onChange={this.handleSelectChange.bind(this)}>
              <MenuItem classes={{ root: 'password-type-dropdown-text' }} value={0}>{intl.formatMessage(language.getText('select_password'))}</MenuItem>
              <MenuItem classes={{ root: 'password-type-dropdown-text' }} value={1}>{intl.formatMessage(language.getText('password_text_option'))}</MenuItem>
              <MenuItem classes={{ root: 'password-type-dropdown-text' }} value={2}>{intl.formatMessage(language.getText('password_image_option'))}</MenuItem>
            </Select>
          </TableCell>
          <TableCell>
            {(this.state.values.passwordUrl === ''
              ? <TextField id="passwordText" placeholder="" className="student-input" type="text" value={this.state.values.passwordText || ''} onChange={this.handleInputChange.bind(this)} />
              : <TextField id="passwordText" placeholder={intl.formatMessage(language.getText('image_selected'))} className="student-input" type="text" InputProps={{ readOnly: true }} value="Image Selected" />)}
          </TableCell>
          <TableCell>
            <Button variant="raised" className="edit-student-button save-print-button" labelPosition="after" primary icon={<EditStudentIcon className="add-student-icon" />} onClick={this.handleEditStudent.bind(this)}>{intl.formatMessage(language.getText('edit_student_button'))}</Button>
          </TableCell>
        </TableRow>
      );
    }

    renderTitle = function () {
      const { language, intl } = this.props;
      return (
        <div className="back-to-student-list">
          <IconButton onClick={this.handleCloseDialog.bind(this, 'editStudent')} aria-label="To Go back to student list" style={styles.backButton}>
            <HardwareKeyboardArrowBack style={styles.backButtonIcon} />
          </IconButton>
          <p aria-live="polite">{intl.formatMessage(language.getText('edit_students'))}</p>
        </div>
      );
    }

    handleInputChange(event) {
      const values = { ...this.state.values };
      if (event.target.value !== '') {
        this.setState({
          error: false,
          errorText: ''
        });
        $(`#${event.target.id}`).removeClass('inputerror');
      } else {

      }
      if (event.target.id === 'firstName') {
        values.firstName = event.target.value;
      } else if (event.target.id === 'lastName') {
        values.lastName = event.target.value;
        // } else if (event.target.id === "displayName") {
        //     values.displayName = event.target.value;
      } else if (event.target.id === 'studentID') {
        values.studentID = event.target.value;
      } else if (event.target.id === 'passwordText') {
        values.passwordText = event.target.value;
      }
      this.setState({ values });
    }

    handleSelectChange(event) {
      const value = event.target.value;
      const values = { ...this.state.values };
      const prevValue = values.passwordSelectValue;
      values.passwordSelectValue = value;
      if (value === '' || value === 0 || prevValue !== value) {
        values.passwordText = '';
        values.passwordUrl = '';
      }
      if (value == 2) {
        values.passwordType = 'Image';
      } else if (value == 1) {
        values.passwordType = 'Text';
      }
      this.setState({
        error: false,
        errorText: ''
      });
      $('#passwordType').parent().removeClass('inputerror');
      this.setState({ values });
      if (value == 2 && values.passwordType === 'Image') {
        this.setState({
          showPasswordDialog: true
        });
      }
    }

    selectPasswordImage = (passwordText, passwordImage) => {
      const values = { ...this.state.values };
      values.passwordText = passwordText;
      values.passwordUrl = passwordImage;
      this.setState({ values });
      this.setState({
        showPasswordDialog: false
      });
    }

    validateInputValues() {
      const { language, intl } = this.props;
      let validationError = '';
      const values = { ...this.state.values };
      const regex = new RegExp('^[a-zA-Z0-9]+$');
      if (values.firstName === '') {
        validationError = `${intl.formatMessage(language.getText('please_enter'))} ${intl.formatMessage(language.getText('first_name'))}`;
        $('#firstName').addClass('inputerror');
      } else if (values.lastName === '') {
        validationError = `${intl.formatMessage(language.getText('please_enter'))} ${intl.formatMessage(language.getText('last_name'))}`;
        $('#lastName').addClass('inputerror');
        // } else if (values.displayName === "") {
        //     validationError = "Please enter Display Name";
        //     $('#displayName').addClass('inputerror');
      } else if (values.passwordSelectValue !== '' && values.passwordSelectValue !== 0) {
        if (values.passwordType === 'Text') {
          if (values.passwordText === '') {
            validationError = `${intl.formatMessage(language.getText('please_select'))} ${intl.formatMessage(language.getText('password_text_validation'))}`;
            $('#passwordText').addClass('inputerror');
          } else if (values.passwordText.length < 5 || regex.test(values.passwordText) === false) {
            validationError = intl.formatMessage(language.getText('password_validation'));
            // $('#passwordText' + i).addClass('inputerror');
            $('#passwordText').addClass('inputerror');
          }
        } else if (values.passwordType === 'Image') {
          if (values.passwordUrl === '') {
            validationError = `${intl.formatMessage(language.getText('please_select'))} ${intl.formatMessage(language.getText('password_image_validation'))}`;
            $('#passwordText').addClass('inputerror');
          }
        }
      } else if ((values.passwordSelectValue == 0 && values.passwordText !== '') || (values.passwordSelectValue == 0 && values.passwordText == '')) {
        validationError = `${intl.formatMessage(language.getText('please_select'))} ${intl.formatMessage(language.getText('password_type'))}`;
        $('#passwordType').parent().addClass('inputerror');
      }
      return validationError;
    }

    handleEditStudent(event) {
      const { language, intl } = this.props;
      const userData = this.state.values;
      const self = this;
      let validationError = '';
      validationError = this.validateInputValues();
      if (validationError) {
        this.setState({
          error: true,
          errorText: validationError
        });
      } else if (validationError === '') {
        const metaData = this.createMetadata(userData);
        // AppActions.editStudent(metaData);
        Framework.getEventManager().publish(constants.EDIT_STUDENT_REQUESTED,
          {
            userId: DataFormatter.getKeyFromObject('userInformation', 'id'),
            payload: metaData
          });
        this.setState({
          editStudentDialog: true
        });

        const gtmData = {
          dataLayer: {
            event: 'editing_student',
            event_category: intl.formatMessage(language.getText('editing_student')),
            event_action: intl.formatMessage(language.getText('edit_student_clicked')),
            event_label: null,
            transaction_local_dt: CommonUtils.getLocalIsoTime(),
            student_user_id: userData.userId
          }
        };
        TagManager.dataLayer(gtmData);
        event.preventDefault();
      }
    }

    createMetadata(studentData) {
      const course = DataFormatter.getObjectInStorage('courseSelected');
      const originId = course.externalRef[0].originId;
      let passwordData = {}; const
        metaDataArray = [];
      if (studentData.passwordSelectValue === 1 || studentData.passwordSelectValue === 2) {
        let passMetaData = {
          password: studentData.passwordText,
          resetPassword: 'true',
          identityScheme: 'ies',
          userId: studentData.userId,
          schoolId: DataFormatter.getObjectInStorage('schoolId'),
          classcode: originId,
          reIngestion: true
        };
        if (studentData.passwordType == 'Image') {
          const passImageData = { passwordUrl: studentData.passwordUrl, passType: 'IMG' };
          passMetaData = { ...passMetaData, ...passImageData };
        }
        passwordData = {
          createdBy: DataFormatter.getKeyFromObject('userInformation', 'id'),
          type: 'user',
          metadata: passMetaData
        };
        metaDataArray.push(passwordData);
      }
      metaDataArray.push({
        createdBy: DataFormatter.getKeyFromObject('userInformation', 'id'),
        type: 'user',
        metadata: {
          fName: studentData.firstName,
          lName: studentData.lastName,
          userId: studentData.userId,
          cardNumber: studentData.studentID,
          identityScheme: 'ies',
          schoolId: DataFormatter.getObjectInStorage('schoolId'),
          classcode: originId,
          reIngestion: true
        }
      });
      // Pdf generation
      metaDataArray.push({
        createdBy: DataFormatter.getKeyFromObject('userInformation', 'id'),
        type: 'pdf-generation',
        status: 'submitted',
        metadata: {
          isNotifyTeacher: true,
          isEmailNotification: true,
          isPdfGeneration: true

        }

      });
      const metaData = {
        items: metaDataArray
      };
      // console.log(metaData);
      return metaData;
    }

    handleCloseDialog(type, event) {
      this.setState({ showPasswordDialog: false });
      this.setState({ editStudentDialog: false });
      if (type === 'editStudent') {
        this.props.showStudentList();
      }
    }

    handleCloseDialogEnterKey = function (event) {
      const isTabPressed = event.key === 'Escape' || event.key === 'Enter' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
      if (!isTabPressed) {
        return;
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        this.setState({ showPasswordDialog: false });
        return false;
      }
      if (event.key === 'Enter') {
        this.setState({ showPasswordDialog: false });
      }
    }

    handleCloseDialogEscapeKey = function (event) {
      const isTabPressed = event.key === 'Escape' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
      if (!isTabPressed) {
        return;
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        this.setState({ showPasswordDialog: false });
        return false;
      }
    }

    showStudentList = () => {
      this.props.showStudentList();
    }

    renderstats = function () {
      return (
        <div>
          <div tabIndex="0" className="addstudenttitle">
            {this.renderTitle()}
          </div>
        </div>
      );
    };

    callbackFetchIesPassword = (setIesPasswordList) => {
      const iesPasswordList = [];
      if (typeof setIesPasswordList.data.images !== 'undefined' && setIesPasswordList.data.images.length > 0) {
        setIesPasswordList.data.images.map((image) => {
          if (typeof image.imageUrl !== 'undefined' && image.imageUrl !== '' && typeof image.password !== 'undefined' && image.password !== '') {
            iesPasswordList.push({
              logoURL: image.imageUrl, password: image.password
            });
          }
        });
        this.setState({ passwordList: iesPasswordList, passwordListFetchStatus: { status: 'complete' } });
      }
    }

    callbackEditStudent = (setEditStudentData) => {
      const { language, intl } = this.props;
      if (setEditStudentData) {
        localStorage.removeItem('isStudentAdd');
        const batchId = setEditStudentData.batch.id;
        const message = (
          <div>
            {intl.formatMessage(language.getText('student_update_started'))}
            <br />
            {' '}
            {intl.formatMessage(language.getText('close_popup'))}
            {' '}
            {intl.formatMessage(language.getText('let_you_know'))}
          </div>
        );
        this.setState({ editStudentCompleted: true, batchItemMessage: message });
      }
    }

    render() {
      const { language, intl } = this.props;
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div id="addStudentContainer" className="addstudentcontainer">
            {this.renderstats()}
            <div className="addStudentContent">
              <Table className="addStudentTable" aria-label="simple table">
                <TableHead>
                  <TableRow className="student-header">
                    <TableCell>
                      {intl.formatMessage(language.getText('first_name'))}
                      {' '}
                      <span className="requiredfield">*</span>
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage(language.getText('last_name'))}
                      {' '}
                      <span className="requiredfield">*</span>
                    </TableCell>
                    {/* <TableCell>Display Name <span className="requiredfield">*</span></TableCell> */}
                    <TableCell>{intl.formatMessage(language.getText('student_id'))}</TableCell>
                    <TableCell className="password-type-label">
                      <span data-tip data-for="passwordToolTip"><InfoIcon className="info-icon" /></span>
                      <ReactTooltip className="password-info" id="passwordToolTip" place="bottom" effect="solid" type="light" opensOn="Click"><span className="tooltipText">{intl.formatMessage(language.getText('password_tooltip'))}</span></ReactTooltip>
                      {intl.formatMessage(language.getText('password_type'))}
                      {' '}
                      <span className="requiredfield">*</span>
                    </TableCell>
                    <TableCell>{intl.formatMessage(language.getText('password_text'))}</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.createUI()}
                </TableBody>
              </Table>
            </div>
            <div>
              {(this.state.error === true
                            && (
                            <div className="errortext">
                              <p>{this.state.errorText}</p>
                            </div>
                            ))}
            </div>
            <Dialog title="" modal open={this.state.showPasswordDialog} className="popup-outer-container">
              <div className="popup-message-container" onKeyDown={this.handleCloseDialogEscapeKey.bind(this)}>
                <div tabIndex="0" className="popup-close" onClick={this.handleCloseDialog.bind(this, 'passwordImage')} onKeyDown={this.handleCloseDialogEnterKey.bind(this)}>X</div>
                {' '}
                <br />
                <br />
                <br />
                <StudentPassword passwordList={this.state.passwordList} selectPasswordImage={this.selectPasswordImage} />
              </div>
            </Dialog>
            <Dialog title="" modal open={this.state.editStudentDialog} contentClassName="popup-outer-container">
              <div className="popup-message-container">
                {/* <div className="popup-close" onClick={this.handleCloseDialog.bind(this, "editStudent")}>X</div> */}
                <h3 />
                <br />
                <br />
                <div className="create-student-container">
                  <div className="row">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                      {!this.state.editStudentCompleted && (
                      <div>
                        <div className="circularLoader">
                          <CircularProgress mode="indeterminate" />
                          <br />
                        </div>
                        <div>
                          {' '}
                          {intl.formatMessage(language.getText('student_update_progress'))}
                          {' '}
                          {intl.formatMessage(language.getText('do_not_close'))}
                        </div>
                      </div>
                      )}
                      {/* {this.state.editStudentCompleted && <div className="row password-list-row">
                                            {this.state.batchItemMessage && <div className="col">{this.state.batchItemMessage}<Button variant="raised" className="no-students-button" primary={true} onClick={this.handleCloseDialog.bind(this, "editStudent")}>{intl.formatMessage(language.getText(`okButtonLabel`))}</Button></div>}
                                        </div>} */}
                      {
                                            // if processing is  done lets close the dialog automatically and move to
                                            this.state.editStudentCompleted && this.handleCloseDialog('editStudent')
                                        }
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            {/* <pre>
                        {JSON.stringify(this.state, null, 2)}
                    </pre> */}
          </div>
        </MuiThemeProvider>
      );
    }
}

// EditStudent.contextTypes = {
//     router: React.PropTypes.object.isRequired
// };
EditStudent.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language'),
  observer
)(injectIntl(EditStudent));
