/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class ReturnBookService extends BaseService {
  constructor() {
    super(constants.RETURN_BOOK_REQUESTED, 'ReturnBookServiceStatus');
  }

  handleEvent(eventName, event) {
    const deviceId = CommonUtils.getdeviceId();
    const headerParams = {
      headers: {
        deviceid: deviceId,
        deleteflag: true
      }
    };

    return this.getClient('hapi').post(
      '/provison-books-by-user-id',
      event, headerParams
    );
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    let userProvisionData;
    const userId = localStorage.getItem('userIDRemoveBooks') ? localStorage.getItem('userIDRemoveBooks') : localStorage.getItem('userId');
    if (response.data) {
      if (response.status === 404 || response.data.statusCode === 400) {
        userProvisionData = 'failed';
      } else {
        userProvisionData = 'success';
        if (localStorage.getItem('usertype') === 'teacher') {
          Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
            {
              userId,
              includeReturned: true
            });
          localStorage.removeItem('userIDRemoveBooks');
        }
        Framework.getEventManager().publish(constants.RETURN_BOOK_SUCCESS, userProvisionData);
      }
    } else {
      Framework.getEventManager().publish(constants.RETURN_BOOK_ERROR, {
        message: 'Unable to Return the book(s)'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.RETURN_BOOK_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
