import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import IframeViewer from './IframeViewer';
import DataFormatter from '../../common/utils/DataFormatter';
// import AppActions from '../actions/app.actions'
// import AppStore from '../stores/app.store'
// import AppConstants, {COMPLETED, INCOMPLETE} from '../constants/app.constants';
import * as constants from '../../common/constants';

const iframeViewerStyle = 'width: 100%; height: 100vh;';
const reviewIframeViewerStyle = 'width: 100%; height: 100vh; padding-top: 10px;';
class LaunchScorm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      gradesPosted: false,
      linkNotReady: false,
      reviewMode: false
    };

    // this.scormUrl = DataFormatter.getObjectInStorage('scormUrl');
    // DataFormatter.removeObjectInStorage('scormUrl')
    localStorage.removeItem('scormUrl');
    let UrlLink = window.location.href;
    const urlArray = UrlLink.split('scormUrl=');
    this.scormUrl = urlArray && urlArray.length && urlArray[1];
    this.assetId = decodeURIComponent(this.scormUrl).split('assetid=').pop().split('&userid=')[0];
    this.prevCompletionStatus = '';
  }

  UNSAFE_componentWillMount() {
    this.getScormGrades();
  }

  componentDidMount() {
    // AppStore.on(AppConstants.EventTypes.FETCH_SCORM_GRADES, this.scormGradesData);
    Framework.getEventManager().on(constants.FETCH_SCORM_GRADES_SUCCESS, (respData) => {
      this.scormGradesData(respData);
    });
    // AppStore.on(AppConstants.EventTypes.POST_SCORM_ACTIVITY_GRADES, this.scormPosted);
    Framework.getEventManager().on(constants.POST_SCORM_ACTIVITY_GRADES_SUCCESS, (respData) => {
      this.scormPosted(respData);
    });
    const btn = document.getElementsByClassName('button blue fadeIn animated');
    try {
      btn.addEventListener('click', () => {
        window.close();
        window.parent.close();
        window.parent.window.parent.close();
      });
    } catch (e) {
      console.log('btn.addEventListener', e);
    }
    // btn.onclick(function() { window.close()})
  }

  componentWillUnmount() {
    // AppStore.removeListener(AppConstants.EventTypes.FETCH_SCORM_GRADES, this.scormGradesData)
    // AppStore.removeListener(AppConstants.EventTypes.POST_SCORM_ACTIVITY_GRADES, this.scormPosted)
    // Framework.getEventManager().unregisterAllCallbacks();
  }

  getScormGrades = () => {
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    // AppActions.fetchScormGrades(userId, this.assetId);
    Framework.getEventManager().publish(constants.FETCH_SCORM_GRADES_REQUESTED, {
      userId,
      assetId: this.assetId
    });
  }

  scormGradesData = (scormGradesData) => {
    const scormGades = scormGradesData; // scorm grades from pulse

    // when link is not ready
    if (scormGades.statusCode === 400) {
      this.setState({ linkNotReady: true });
    } else {
      // polling to fetch scorm grades every 5 sec
      const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
      const payload = {};

      if (scormGades && scormGades.grade.length > 0) {
        const grade = scormGades.grade[0];
        if (grade.hasOwnProperty('completionStatus') && grade.completionStatus === constants.COMPLETED) {
          payload.submittedByUserId = grade.userId;
          payload.type = grade.gradeType;
          payload.maxValue = grade.maxValue.toString();
          payload.value = grade.value.toString();

          if (this.prevCompletionStatus !== '') { // to prevent post when scorm opens in review mode
            this.setState({ isLoading: true });
            // AppActions.postScormActivityGrades(userId, this.assetId, payload)
            Framework.getEventManager().publish(constants.POST_SCORM_ACTIVITY_GRADES_REQUESTED, {
              userId,
              assetId: this.assetId,
              payload
            });
          } else {
            // setTimeout(() => {
            this.setState({ reviewMode: true });
            // }, 3000);
          }
        } else {
          this.prevCompletionStatus = constants.INCOMPLETE;
          setTimeout(() => {
            // AppActions.fetchScormGrades(userId, this.assetId)
            Framework.getEventManager().publish(constants.FETCH_SCORM_GRADES_REQUESTED, {
              userId,
              assetId: this.assetId
            });
          }, 2500);
        }
      } else { // to handle case when grades array is empty from api
        setTimeout(() => {
          // AppActions.fetchScormGrades(userId, this.assetId)
          Framework.getEventManager().publish(constants.FETCH_SCORM_GRADES_REQUESTED, {
            userId,
            assetId: this.assetId
          });
        }, 5000);
      }
    }
  }

  scormPosted = (respData) => {
    this.setState({ gradesPosted: true });
    // this.manageScormComplete(false)
  }

  manageScormLoader = () => {
    if (this.state.isLoading && !this.state.gradesPosted) {
      return (
        <div id="loadingOverlay">
          <div>
            <h1 id="loading-msg">Please do not close the window</h1>
            <div id="loading-spinner" />
          </div>
        </div>
      );
    } if (this.state.isLoading && this.state.gradesPosted) {
      return (
        <div id="loadingOverlay">
          <div>
            <button id="loading-button" onClick={() => window.close()}>Close</button>
          </div>
        </div>
      );
    } if (this.state.linkNotReady) {
      return (
        <div id="loadingOverlay">
          <div>
            <h1 id="loading-msg">Link is not ready</h1>
            <button id="loading-button" onClick={() => window.close()}>Close</button>
          </div>
        </div>
      );
    }

    return (
      <div />
    );
  }

  render() {
    if (this.state.reviewMode == true) {
      return (
        <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
          <div className="scormCloseBtn"><button onClick={() => window.close()}>Close</button></div>
          <div className="launchScorm" style={{ overflow: 'hidden', backgroundColor: '#FFFFFF' }}>
            {this.manageScormLoader()}
            <IframeViewer iframeUrl={this.scormUrl} style={reviewIframeViewerStyle} />
          </div>
        </div>
      );
    }
    return (
      <div
        className="launchScorm"
        style={{
          height: '100%', width: '100%', overflow: 'hidden', backgroundColor: '#C7E6F0'
        }}
      >
        {this.manageScormLoader()}
        <IframeViewer iframeUrl={this.scormUrl} style={iframeViewerStyle} />
      </div>
    );
  }
}
LaunchScorm.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(LaunchScorm));
