import React from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import DataFormatter from '../../../common/utils/DataFormatter';
import TabComponent from './TabComponent';
import SchoolLibrary from './SchoolLibrary';
import MyBookBox from './MyBookBox';

const masterStyle = {
  fontFamily: 'Quicksand, sans-serif'
};

const muiPrimaryTheme = createTheme(masterStyle);

// var lang = DataFormatter.getLanguage();

const books = [];

class PrimaryApplicationContainer extends React.Component {
  constructor() {
    super();
  }

  state = {
    activeTab: 0
  }

  componentDidMount = function () {
  }

  componentDidUpdate = function (prevProps, prevState) {
  }

  tabsList = [];

  UNSAFE_componentWillMount = function () {
    const { intl, language } = this.props;
    const tabConfigs = {
      'my-bookbox': {
        tabLabel: intl.formatMessage(language.getText('my_bookbox')),
        id: 'myBookBoxTab',
        content: (<div>
          <MyBookBox userId={(this.props.userId ? this.props.userId : false)} schoolId={(this.props.schoolId ? this.props.schoolId : false)} />
        </div>),
        mode: 'primary'
      },
      'school-library': {
        tabLabel: intl.formatMessage(language.getText('school_library')),
        id: 'schoolLibraryTab',
        content: (<div>
          <SchoolLibrary userId={(this.props.userId ? this.props.userId : false)} schoolId={(this.props.schoolId ? this.props.schoolId : false)} mode="primary" />
        </div>),
        mode: 'primary'
      }
    };
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    if (localStorage.getItem('appConfig')) {
      let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
      appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
      const { dashboard } = appConfig;
      if (dashboard && dashboard.sections instanceof Array) {
        for (const sectionItem of dashboard.sections) {
          if (sectionItem.isEnabled && sectionItem.roleValues.includes(role) && tabConfigs[sectionItem.tag]) {
            this.tabsList.push(tabConfigs[sectionItem.tag]);
          }
        }
      }
    }
  }

  render = function () {
    let teacherName; let schoolList; let classRoomName = ''; let
      imageFileName;
    try {
      teacherName = DataFormatter.getKeyFromObject('userInformation', 'fName');
      schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
      classRoomName = schoolList[0].name;
      imageFileName = schoolList[0].links.heroImage;
    } catch (e) {
      console.log('PrimaryApplicationContainer_catch', e);
    }
    return (
      <MuiThemeProvider muiTheme={muiPrimaryTheme}>
        <div className="primaryContainer" style={masterStyle}>
          <div id="maincontent">
            <TabComponent tabsList={this.tabsList} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
// export default PrimaryApplicationContainer;
export default compose(
  inject('language'),
  observer
)(injectIntl(PrimaryApplicationContainer));
