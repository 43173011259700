import React from 'react';

class LoadingOverlay extends React.Component {
  constructor() {
    super();
  }

  render = function () {
    if (this.props.isLoading) {
      return (
        <div className="loadingOverlay">
          <div
            className="parentWrap"
            style={{
              height: '62px', position: 'absolute', width: '100%', top: '51%'
            }}
          >
            <div className="loadingOverlayMessage" />
          </div>
        </div>
      );
    }
    return null;
  }
}
export default LoadingOverlay;
