import env from '../env';

const pl = {
  'bookshelf.TITLE': 'Moja półka na książki',
  'common.LOGOUT': 'Wyloguj',
  'common.GO_HOME': 'Idź do Strony głównej',
  'common.PURCHASE': 'Zakup',
  'courses.WELCOME': 'Witaj {userName}, witaj na swoim kursie {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Pokaż zadania',
  'courses.toc.PUBLISH_SUCCESS': 'Sukces!',
  'courses.toc.PUBLISH_MESSAGE': 'Studenci zobaczą Twoje zmiany, gdy następnym razem otworzą treść.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Nie znaleziono subskrypcji',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Twoje konto nie obejmuje dostępu do tego tytułu. Jeśli uważasz, 
    że otrzymałeś ten komunikat przez pomyłkę, skontaktuj się z pomocą techniczną Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Nie znaleziono subskrypcji',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Twoja subskrypcja nie obejmuje dostępu do aplikacji eText, 
    jednak możesz ją teraz zasubskrybować za pomocą karty kredytowej.`,
  'foxit.ABOUT': 'O',
  'foxit.COPY_RIGHT': 'PDF Reader obsługiwany przez Foxit. Prawa autorskie (C) 2003-2019 Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': 'Kontakt z pomocą techniczną',
  'menu.ABOUT': 'O',
  'menu.ACCESSIBILITY': 'Dostępność',
  'menu.FOXIT': 'Obsługiwane przez Foxit',
  'menu.TERMS_OF_USE': 'Warunki korzystania',
  'menu.PRIVACY_POLICY': 'Polityka prywatności',
  'menu.SIGN_OUT': 'Wyloguj',
  'menu.PRINT_PAGE': 'Drukuj stronę',
  'menu.GET_LOOSE_LEAF': 'Zdobądź luźną kartę',
  'menu.AVAILABLE': 'Również dostępne',
  'menu.PRINT_VERSION': 'Potrzebujesz drukowaną wersję?',
  'menu.COPY_RIGHT': 'Prawo autorskie © {year} Pearson Education Inc. Wszystkie prawa zastrzeżone.',
  'menu.tooltip.SCHEDULE': 'Harmonogram',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Witaj {userName}',
  'device.SWAPPED_ALREADY': 'W tym miesiącu już dokonałeś zamiany',
  'device.CONTACT_SUPPORT': 'Poczekaj do następnego miesiąca lub skontaktuj się z działem wsparcia, aby uzyskać pomoc.',
  'device.SWITCH_DEVICE': 'Przełączyć się na bieżące urządzenie?',
  'device.SWITCH_ONCE': 'Ponieważ możesz się przełączyć tylko raz w miesiącu, nie będziesz mógł się cofnąć.',
  'device.SEAT_TAKEN': 'To miejsce jest zajęte',
  'device.DISCONNECT_DEVICE': 'Możesz zalogować się tylko na 3 urządzeniach. Aby kontynuować, odłącz kolejne urządzenie.',
  'device.ALERT_TEXT': 'Pamiętaj, że możesz zmieniać urządzenia tylko raz w miesiącu.',
  'device.YOUR_MOJO': 'Jesteś na drodze do mojo'
};

export default pl;
