/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Perf environment overrides.
 *
 * @author Vignesh Selvam
 */

const sit = {
  ENVIRONMENT: 'sit',
  PARTNER_ENV: 'prod',
  PRODUCT: 'Digital Library',
  CSG_API_BASE_URL: 'https://content-service-perf.stg-prsn.com/csg/api/v3',
  MARIN_API_BASE_URL: 'https://plus.pearson.com/marin/api/1.0',
  PRISM_API_BASE_URL: 'https://prismperf.stg-prsn.com/api',
  PAPER_API_BASE_URL: 'https://paperapiperf.stg-openclass.com/nextext-api/api/platforms/bookshelf',
  STPAPER_API_BASE_URL: 'https://stpaperapiprf.stg-prsn.com/etext/v2',
  SPECTRUM_API_BASE_URL: 'https://spectrum-ppe.pearsoned.com/api/v1',
  SPECTRUM_API_V4_BASE_URL: 'https://spectrum-ppe.pearsoned.com/api/v4',
  HAPI_API_BASE_URL: `//digital-library-sit.pearson.com${process.env.NODE_ENV !== 'production' ? ':3000' : ''}`,
  CLIENT_ID: 'OYG7tQTgGn4qQybwvjxXdmt90DhRxRAM',
  PXE_X_CALLER: '3cc7b6e8-3416-11e8-b467-0ed5f89f718b',
  CITE_X_CALLER: 'b2cee8b6-d75e-11e8-9f8b-f2801f1b9fd1',
  TENANT_ID_ETEXT: 'beeea450-02f6-4f88-842e-cec9364453b7',
  TENANT_KEY_ETEXT: '4f0fbc0b-2a5a-4382-90c4-d8ecbba99158',
  TENANT_KEY_ETEXT_PDF: 'bb0a1d97-78af-44e4-bfe4-810c535a45c0',
  LD_CLIENT_SIDE_ID: '601af2891b23a20a61a4fe74',
  LD_ANONYMOUS_USER_ID: 'ise_anonymous_perf_user',
  LD_ANONYMOUS_HASH: '0e36d47a6e87fff7d7bfded6d7e5488de59a7249f806e5a6c3c7bf78f06580d1',
  PRODUCT_TOKEN: '35094',
  BOOKSHELF_CONFIG: {
    checkout: 'https://pearson-perf.pearson.com/en-us/pearsonplus/pricing.html',
    pmcHeader: 'https://pearson-perf.pearson.com/en-us/pearsonplus.nav-content.standalone.html',
    addon: 'https://pearson-stg3.pearson.com/store/_ui/addons/pmciamaddon/responsive/common/js/pmciamaddon.js',
    chatbot: {
      url: 'https://pearson--servicedev.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseUrl: 'https://pearson--servicedev.my.salesforce.com',
      supportUrl: 'https://servicedev-pearsoncommunity.cs100.force.com/getsupport',
      id: '00D1x0000000Mu5',
      name: 'NAUS_HETS_DTC_CS_Bot',
      baseLiveAgentContentURL: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
      deploymentId: '572b0000000ClKC',
      buttonId: '5731x000000Cbzc',
      baseLiveAgentURL: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1x000000CaofEAC_1785e5167e1',
      isOfflineSupportEnabled: false
    },
    more: {
      MOJO_TERMS: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/terms-of-use.html',
      MOJO_PRIVACY: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/user-privacy-policy.html',
      MOJO_COOKIES: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_INFO: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_ACCESSIBILITY: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/accessibility.html',
      MOJO_NOTICE: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/patent-notice.html',
      MOJO_PEARSON_ACCESSIBILITY: 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
      MOJO_SUPPORT: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/support.html'
    }
  },
  PLUS_URL: 'https://plus-ppe.pearson.com',
  IES_BASE_URL: 'https://login.pearson.com/v1/piapi',
  CAAS_ID: '65cb47d5-5581-4b6e-9f8d-cff3681387cc',

  // Google Tag Manager SIT Configs
  GTM_CONTAINER_ID: 'GTM-N8JS47K',
  GTM_AUTH: 'GIpWCf35kyCaOQoqsBd94Q',
  GTM_PREVIEW: 'env-6'
};

export default sit;
