/* eslint-disable linebreak-style */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React, { Suspense, useEffect } from 'react';

import { injectIntl } from 'react-intl';
import Framework, { shapes } from '@greenville/framework';
import {
  CircularProgress
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import * as constants from '../../common/constants';
import TeacherDashboard from './components/TeacherDashboard';
import SelectYourLibrary from './components/SelectYourLibrary';
import AccountCreated from './components/AccountCreated';
import DlUser from '../../common/model/DlUser';
import DlUserSchool from '../../common/model/DlUserSchool';

const renderSwitch = (param) => {
  switch (param) {
    case 'selectlibrary':
      return <SelectYourLibrary />;
    case 'dashboard':
      return <TeacherDashboard />;
    case 'confirmlib':
      return <AccountCreated />;
    default:
      return undefined;
  }
};


function TeacherContainer(props) {
  const {
    match, dlUser, dlUserSchool
  } = props;
  const { pageName } = match.params;
  const auth = Framework.getAuthService();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    Framework.getEventManager().on('USER_PROFILE_FETCHED', (user) => {
      // IES User done, lets get DL data now
      if (auth && auth.isInitialized === true && user && user.id.length > 0) {
        // Call Function to get DL User & School Data
        dlUser.fetch(user.id);
        dlUserSchool.fetch(user.id);
      }
    });
    Framework.getEventManager().on(constants.DL_USER_SCHOOL_ERROR, () => {
      // There is a failiure to get DL User School
    });
    Framework.getEventManager().on(constants.DL_USER_SCHOOL_UNSET, () => {
      Framework.redirectTo('/teacher/selectlibrary');
    });
    return () => {
      // Framework.getEventManager().unregisterAllCallbacks();
    };
  });

  return (
    <Suspense fallback={<CircularProgress className="circleStyle" />}>
      {
      renderSwitch(pageName)
    }
    </Suspense>
  );
}

TeacherContainer.propTypes = {
  match: shapes.match.isRequired,
  dlUser: shapes.modelOf(DlUser).isRequired,
  dlUserSchool: shapes.modelOf(DlUserSchool).isRequired
};

export default compose(inject('user', 'dlUser', 'dlUserSchool'), observer)(injectIntl(TeacherContainer));
