/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Appcues events
 *
 * @file AppcuesEventsList.js
 * @author Prithviraj K
 */

const appcuesEventsList = [
  {
    eventName: 'Created a Note',
    category: 'Notebook',
    action: 'Create'
  },
  {
    eventName: 'Bookmarked a Page',
    category: 'BookMarks',
    action: 'Add'
  },
  {
    eventName: 'Font size change',
    category: 'Preferences',
    action: 'FontChange'
  },
  {
    eventName: 'Viewed schedule readings calendar',
    category: 'Scheduled',
    action: 'Title Click'
  },
  {
    eventName: 'Searched',
    category: 'Search',
    action: 'Query'
  },
  {
    eventName: 'Student Favorited Terms',
    category: 'StudyTools',
    action: 'TermSaved'
  },
  {
    eventName: 'Opened a Card',
    category: 'Flashcards',
    action: 'Open'
  },
  {
    eventName: 'Started a Practice Session',
    category: 'Prepare',
    action: 'Open'
  },
  {
    eventName: 'Created a Match',
    category: 'MatchingGame',
    action: 'Open'
  },
  {
    eventName: 'Audio Playlist Opened',
    category: 'Audio',
    action: 'Play'
  }
];

export default appcuesEventsList;
