/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

// import bookshelf from './bookshelf';
// import courses from './courses';
import home from './home';
import pages from './pages';
import app from './app';
import student from './student';
import teacher from './teacher';
import bookreader from './bookreader';
import launchScorm from './launchScorm';

export default [
  home,
  pages,
  app,
  student,
  teacher,
  bookreader,
  launchScorm
];
