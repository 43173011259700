import React from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import injectTapEventPlugin from 'react-tap-event-plugin';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DataFormatter from '../utils/DataFormatter';

try {
  injectTapEventPlugin();
} catch (e) {

}
const menuListStyles = theme => ({
  menuPaper: {
    maxHeight: 'calc(100% - 241px) !important'
  }
});
const popover_class = {
  position: 'absolute !important'
};
const styles = {
  formControl: {
    margin: 10,
    minWidth: 120
  },
  filterWithNoCombo: {
    paddingLeft: '20px !important',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 15,
    boxShadow: 'rgb(236, 236, 236) 0px 0.5px 0.5px 0px, rgb(236, 236, 236) 0px 0px 0.5px 0px'
  },
  searchFilterStyle: {
    height: 48,
    width: 264,
    textAlign: 'center',
    display: 'block',
    marginTop: 0,
    maxHeight: '60% !important',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)'
  },
  selectBoxStyle: {
    top: 0, left: 0, width: '100%', height: '100%', zIndex: -1, position: 'relative !important'
  },
  searchFilterWithInfoButtonStyle: {
    height: 48,
    width: 208,
    textAlign: 'center',
    marginTop: 0,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)'
  },
  searchFilterWrapperStyle: {
    marginBottom: 0,
    // display: 'inline-block',
    maxHeight: '58px'
  },
  searchFilterWithPickerWrapperStyle: {
    // display: 'inline-block',
    verticalAlign: 'top',
    height: 61,
    width: 264,
    position: 'relative'
  },
  searchFilterWithCascadePickerWrapperStyle: {
    verticalAlign: 'top',
    height: 61,
    width: 264,
    position: 'relative',
    marginTop: '-22px'
  },
  searchFilterWithInfoButtonWrapperStyle: {
    // display: 'inline-block',
    maxHeight: '58px'
    // marginTop: '-23px'
  },
  searchFilterHintStyle: {
    fontSize: '14px',
    lineHeight: '1.14',
    textAlign: 'left',
    color: '#393c3d',
    bottom: '16px',
    marginLeft: '16px'
  },
  searchFilterHintSelectedStyle: {
    fontSize: '14px',
    lineHeight: '1.14',
    textAlign: 'left',
    color: '#007fa3',
    bottom: '16px',
    marginLeft: '16px'
  },
  filterElementDropDownMenuStyle: {
    width: 264
  },
  pickerMenuWidth: {
    width: 258
  },
  filterElementDropDownMenuInlineStyle: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  filterElementDropDownMenuWithInfoButtonStyle: {
    width: 208
  },
  filterElementDropDownMenuItemStyle: {
    whiteSpace: 'normal',
    boxShadow: '0 0.5px 0.5px 0 #ececec, 0 0 0.5px 0 #ececec'
  },
  filterElementDropDownPickerMenuItemStyle: {
    lineHeight: 1,
    minHeight: '',
    whiteSpace: 'normal',
    height: '18px',
    width: '18px',
    boxShadow: '0 0.5px 0.5px 0 #ececec, 0 0 0.5px 0 #ececec'
  },
  filterElementDropDownPickerMenuItem5thStyle: {
    lineHeight: 1,
    minHeight: '',
    whiteSpace: 'normal',
    height: '18px',
    width: '18px'
  },
  filterElementDropDownMenuItemInnerDivStyle: {
    paddingBottom: 10,
    paddingTop: 10
  },
  filterElementDropDownPickerMenuItemInnerDivStyle: {

  },
  filterElementDropDownMenuWithInfoButtonInlineStyle: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  infoButtonStyle: {
    width: '48px',
    display: 'inline-block',
    marginRight: '0px',
    marginTop: '0px',
    marginLeft: '8px',
    verticalAlign: 'top'
  },
  primaryComboBoxStyle: {
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)'
  }
};

const emptyMenuPicker = (
  <MenuItem
    className="emptyMenuPicker"
    id="emptyMenu"
    key={-1}
    value={null}
    primaryText=""
    style={{
      minHeight: '0px !important', maxHeight: '0px !important', padding: '0', margin: '0', border: 'none !important', display: 'none !important'
    }}
    innerDivStyle={{ width: '0px !important', height: '0px !important', margin: '0px !important' }}
  />
);

const emptyMenu = <MenuItem key={-1} value={null} primaryText="" style={{ minHeight: '0px !important', maxHeight: '0px !important' }} />;

class CustomSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.renderFilterElement = this.renderFilterElement.bind(this);
    this.renderOptionElement = this.renderOptionElement.bind(this);
    this.handleFilterSelectFieldSelect = this.handleFilterSelectFieldSelect.bind(this);
    this.showCorrelationChart = this.showCorrelationChart.bind(this);
    this.handleRequestPickerClose = this.handleRequestPickerClose.bind(this);
    this.handleTouchTap = this.handleTouchTap.bind(this);
    this.handleTouchTapFilterWithPickerWrapper = this.handleTouchTapFilterWithPickerWrapper.bind(this);
  }

  state = {
    valueArray: [],
    selectedSections: [], // selected Guided reading values
    multiple: false,
    pickerOpen: false,
    dropdownChange: false,
    selectOpen: false
  }

  UNSAFE_componentWillMount() {
    const filterState = {}; let
      updated = 0;
    if (this.props.filterConfig) {
      const ifilterConfig = this.props.filterConfig;

      filterState.multiple = (!!ifilterConfig.multiselect);
      updated++;
    }
    if (this.props.filterValue instanceof Array) {
      filterState.valueArray = this.props.filterValue;
      filterState.selectedSections = this.props.sectionData;
      updated++;
    }
    if (updated > 0) {
      this.setState(filterState);
    }
  }

  componentDidMount() {
    // Adding this code block focus on inner element while tab navigation
    const divElements = document.querySelectorAll('.customComboBox');
    for (const divElement of divElements) {
      divElement.firstChild.setAttribute('tabIndex', '-1');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filterState = {}; let
      updated = 0;
    if ((nextProps.filterValue
      && this.props.filterValue
      && JSON.stringify(nextProps.filterValue) == JSON.stringify(this.props.filterValue)
    ) && nextProps.filterValue instanceof Array) {
      filterState.valueArray = nextProps.filterValue;
      filterState.selectedSections = nextProps.sectionData;
      updated++;
    } else if (nextProps.filterValue != this.props.filterValue && typeof nextProps.filterValue === 'undefined') {
      filterState.valueArray = [];
      filterState.selectedSections = nextProps.sectionData;
      updated++;
    }
    if (updated > 0) {
      this.setState(filterState);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showFilter !== prevProps.showFilter && this.state.pickerOpen) {
      this.setState(prevState => ({
        pickerOpen: !prevState.pickerOpen
      }));
    }
  }

  handleCascadeSelectFilter = function (value, subValue, event) {
    let _selectedSections = this.state.selectedSections ? [...this.state.selectedSections] : [];
    if (value.key) {
      subValue = subValue || { ...value };
    }
    if (_selectedSections && _selectedSections.length > 0) {
      let subValueArray = _selectedSections[0].subValue;
      const index = this.checkOptAvailable(subValueArray, subValue);
      if (index > -1) {
        subValueArray.splice(index, 1);
      } else {
        subValueArray = subValueArray.concat(subValue);
      }
      if (subValueArray.length === 0) {
        _selectedSections = [];
      } else {
        _selectedSections[0].subValue = subValueArray;
      }
    } else {
      _selectedSections.push({ value: value.key, label: value.key, subValue: [subValue] });
    }
    this.setState({
      selectedSections: _selectedSections
    }, () => {
      this.props.onFilterValueUpdate(this.state.valueArray, this.state.selectedSections);
    });
  }

  handleFilterSelectFieldSelect = function (value, subValue, event) {
    // this.handleRequestPickerClose();
    let newVal; const valueArray = [...this.state.valueArray]; let chk; let
      objVal;
    if (value.key) {
      subValue = subValue || { ...value };
      objVal = Object.assign({}, { value: value.key, label: value.key });
    } else {
      objVal = Object.assign({}, value);
    }
    chk = this.checkOptAvailable(valueArray, objVal);
    if (chk > -1) {
      if (subValue != null) {
        let subValueArray = valueArray[chk].subValue; let
          chkSub;
        chkSub = this.checkOptAvailable(subValueArray, subValue);
        if (chkSub > -1) {
          subValueArray.splice(chkSub, 1);
          if (subValueArray.length > 0) {
            valueArray[chk].subValue = subValueArray;
          } else {
            valueArray.splice(chk, 1);
          }
          newVal = valueArray;
        } else {
          subValueArray = subValueArray.concat(subValue);
          valueArray[chk].subValue = subValueArray;
          newVal = valueArray;
        }
      } else {
        valueArray.splice(chk, 1);
        newVal = valueArray;
      }
    } else {
      const setval = objVal;
      if (subValue != null) {
        newVal = valueArray.concat({ value: objVal.value, label: objVal.labelValue, subValue: [subValue] });
      } else {
        newVal = valueArray.concat(setval);
      }
    }

    this.setState({
      valueArray: newVal
    }, () => {
      this.props.onFilterValueUpdate(this.state.valueArray, this.state.selectedSections);
    });
  }

  checkOptAvailable = (vArray, opt) => vArray.findIndex(arrayVal => arrayVal.value === opt.value);

  handleInfoIconKeyDown = (event) => {
    if (event.keyCode == 13) {
      this.showCorrelationChart();
    }
  }

  showCorrelationChart = function () {
    window.open(this.props.infoButtonUrl);
  }

  handleTouchTap = (idx, event) => {
    // This prevents ghost click.
    if (this.state.pickerOpen !== false) {
      const lastChild = document.body.lastElementChild;
      lastChild.click();
      return;
    }
    event.preventDefault();
    const ClickElement = event.currentTarget.parentElement;
    DataFormatter.setObjectInStorage('CustomClickSubMenuHeight', ClickElement.offsetHeight);
    DataFormatter.setObjectInStorage('CustomClickSubMenuTop', ClickElement.offsetTop);

    this.setState({
      pickerOpen: ((idx !== null) ? idx : true),
      pickerAnchorEl: event.currentTarget
    }, function () {
      const focEl = document.getElementsByClassName('subMenuPick')[0];
      if (focEl) {
        const laseElement = document.body.lastElementChild;
        laseElement.style.position = 'relative';
        this.state.pickerAnchorEl.blur();
      }
    });
    setTimeout(() => {
      const presentElement = document.querySelectorAll('.subMenuPick');
      if (presentElement.length) {
        presentElement[0].parentElement.style.overflowY = 'scroll';
      }
    }, 500);
  };

  handleTouchTapFilterWithPickerWrapper = (idx, ifilterConfigName, event) => {
    const updateState = {
      pickerOpen: ((idx !== null) ? idx : true)
    };
    let elementWrapper;
    if (ifilterConfigName) {
      elementWrapper = document.querySelector(`.customComboBox.withPicker.${ifilterConfigName}`);
      if (elementWrapper) {
        updateState.pickerAnchorEl = elementWrapper;
      }
    }
    event.preventDefault();
    this.setState(updateState);
  };

  handleRequestPickerClose = function () {
    this.setState({
      pickerOpen: false
    });
  }

  renderDefaultOption = (menuItemsprops, index, optionType, option, innerDivStyle, insetChildren, checkItemSelected, showArrowIcon) => {
    if (typeof option.labelValue === 'string') {
      menuItemsprops.primaryText = option.labelValue;
    }
    const { language } = this.props;
    const CheckBoxIconStyle = { fill: '#007da7', top: '15px' };
    const CheckBoxOutlineIconStyle = { top: '15px' };
    if (typeof option.other !== 'undefined' && typeof option.other.text !== 'undefined') {
      if (typeof option.other.text !== 'undefined' && typeof option.other.subtext !== 'undefined') {
        let colorSuffixEle = null;
        if (typeof option.other.colourCode !== 'undefined') {
          colorSuffixEle = (
            <div style={{
              display: 'inline-block', backgroundColor: option.other.colourCode, borderRadius: '50%', height: '10px', width: '10px', marginRight: '10px'
            }}
            />
          );
        }
        menuItemsprops.primaryText = (
          <div style={{ lineHeight: '1.2' }}>
            <div>
              {colorSuffixEle}
              {
                language.getMessage(option.other.text)
              }
            </div>
            <div>
              {
                language.getMessage(option.other.subtext)
              }
            </div>
          </div>
        );
      } else {
        let colorSuffixEle = null;
        if (typeof option.other.colourCode !== 'undefined') {
          colorSuffixEle = (
            <div style={{
              display: 'inline-block', backgroundColor: option.other.colourCode, borderRadius: '50%', height: '18px', width: '18px', marginRight: '10px'
            }}
            />
          );
          if (option.other.colourCode === '#ffffff') {
            colorSuffixEle = (
              <div style={{
                display: 'inline-block', backgroundColor: option.other.colourCode, borderRadius: '50%', height: '18px', width: '18px', marginRight: '10px', border: '1.6px solid #000', opacity: '0.5'
              }}
              />
            );
          }
          CheckBoxIconStyle.top = '7px';
          CheckBoxOutlineIconStyle.top = '7px';
          menuItemsprops.primaryText = (
            <div style={{ lineHeight: '1.2', display: 'flex' }}>
              <div>{colorSuffixEle}</div>
              <div>{language.getMessage(option.other.text)}</div>
            </div>
          );
        } else {
          menuItemsprops.primaryText = (
          <div>
            {
              language.getMessage(option.other.text)
            }
          </div>);
        }
      }
    }
    if (optionType === 'combo_with_colour') {
      innerDivStyle.boxShadow = '0 0.5px 0.5px 0 #ececec, 0 0 0.5px 0 #ececec';
      innerDivStyle.paddingLeft = '50px';
      CheckBoxIconStyle.top = '5px';
      CheckBoxOutlineIconStyle.top = '5px';
    }
    if (showArrowIcon) {
      return (
        <MenuItem
          key={`submenu_${index}`}
          value={option}
          style={{ display: 'block !important' }}
          onClick={() => this.subMenuSelect(`submenu_${index}`)}
          innerDivStyle={innerDivStyle}
          {...menuItemsprops}
        >
          {menuItemsprops.primaryText}
          {this.state.pickerOpen === index ? <ArrowDropUpIcon className="arrowDropUp" /> : <ArrowDropDownIcon className="arrowDropDown" />}
        </MenuItem>
      );
    }
    return (
      <MenuItem
        key={`submenu_${index}`}
        value={option}
        innerDivStyle={innerDivStyle}
        insetChildren={insetChildren}
        leftIcon={(checkItemSelected ? <CheckBoxIcon style={CheckBoxIconStyle} /> : <CheckBoxOutlineIcon style={CheckBoxOutlineIconStyle} />)}
        {...menuItemsprops}
      >
        <ListItemIcon>{(checkItemSelected ? <CheckBoxIcon style={CheckBoxIconStyle} /> : <CheckBoxOutlineIcon style={CheckBoxOutlineIconStyle} />)}</ListItemIcon>
        {menuItemsprops.primaryText}
      </MenuItem>
    );
  }

  renderNestedOptionElement = function (optionType, checkItemSelectedV, opt, subopt, subidx) {
    let scheckItemSelected = false;
    let optionIndex;
    let selectedItem;
    const _subopt = { ...subopt };
    const { language } = this.props;
    _subopt.labelValue = language.getMessage(subopt.label);
    if (opt && opt.key) {
      selectedItem = opt.key === 'Guided Reading' && this.state.selectedSections && this.state.selectedSections.length > 0 ? this.state.selectedSections[0] : this.state.valueArray.find(option => option.value === opt.key);
      optionIndex = this.checkOptAvailable(selectedItem ? selectedItem.subValue : this.state.valueArray, subopt);
    } else if (opt && checkItemSelectedV > -1) {
      optionIndex = this.checkOptAvailable(this.state.valueArray[checkItemSelectedV].subValue, subopt);
    } else {
      optionIndex = this.checkOptAvailable(this.state.valueArray, subopt);
    }
    scheckItemSelected = optionIndex > -1;
    switch (optionType) {
      case 'picker':
        return (
          <MenuItem
            key={`submenu_${subidx}`}
            value={_subopt}
            className={`${(((subidx + 1) % 5 == 0) ? 'fifth' : '') + (scheckItemSelected ? ' selected' : '')} ${_subopt.style ? _subopt.style : ''}`}
            style={((subidx % 5 == 0) ? styles.filterElementDropDownPickerMenuItemStyle : styles.filterElementDropDownPickerMenuItem5thStyle)}
            innerDivStyle={styles.filterElementDropDownPickerMenuItemInnerDivStyle}
            insetChildren={false}
            onClick={this.handleFilterSelectFieldSelect.bind(this, ((opt != null) ? opt : _subopt), ((opt != null) ? _subopt : null))}
          >
            {_subopt.labelValue}
          </MenuItem>
        );

      case 'cascade_picker':
        return (
          <MenuItem
            key={`submenu_${subidx}`}
            value={_subopt}
            className={`${(((subidx + 1) % 5 == 0) ? 'fifth' : '') + (scheckItemSelected ? ' selected' : '')} ${_subopt.style ? _subopt.style : ''}`}
            style={((subidx % 5 == 0) ? styles.filterElementDropDownPickerMenuItemStyle : styles.filterElementDropDownPickerMenuItem5thStyle)}
            innerDivStyle={styles.filterElementDropDownPickerMenuItemInnerDivStyle}
            insetChildren={false}
            onClick={this.handleFilterSelectFieldSelect.bind(this, ((opt != null) ? opt : _subopt), ((opt != null) ? _subopt : null))}
          >
            {_subopt.labelValue}
          </MenuItem>
        );

      default:
        const menuItemsprops = {};
        menuItemsprops.onClick = this.handleFilterSelectFieldSelect.bind(this, ((opt != null) ? opt : _subopt), ((opt != null) ? _subopt : null));
        const innerDivStyle = styles.filterElementDropDownMenuItemInnerDivStyle;
        return this.renderDefaultOption(menuItemsprops, subidx, optionType, _subopt, innerDivStyle, false, scheckItemSelected);
    }
  }

  renderOptionElement(optObjArg, idx, optionType, filterClass = '') {
    const opt = optObjArg.options[idx];
    const { language } = this.props;
    if (typeof opt.label !== 'undefined' && typeof opt.value !== 'undefined') {
      if (typeof opt.nested !== 'undefined' && opt.nested.options instanceof Array && opt.nested.options.length > 0) {
        let checkItemSelected = false; let
          checkItemSelectedV;
        opt.labelValue = language.getMessage(optObjArg.options[idx].label);
        checkItemSelectedV = this.checkOptAvailable(this.state.valueArray, opt);
        checkItemSelected = checkItemSelectedV > -1;
        let subPickerOption = null;

        let subMenuOption = [];
        subMenuOption = opt.nested.options.map(this.renderNestedOptionElement.bind(this, opt.nested.optionType, checkItemSelectedV, opt));
        let optType = opt.nested.optionType;
        if (opt.nested.type === 'combo_with_colour') {
          optType = 'combo_with_colour';
        }
        switch (optType) {
          case 'picker':
            const subMenuOptionPicker = [emptyMenuPicker, ...subMenuOption];
            subPickerOption = (
              <Popover
                id="picker"
                open={this.state.pickerOpen === idx}
                anchorEl={this.state.pickerAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={this.handleRequestPickerClose.bind(this, idx)}
                useLayerForClickAway
              >
                <MenuList id={`subMenuPick_${idx}`} maxHeight="300" style={{ overflowY: 'auto !important' }} className="pickerMenu subMenuPick" tabIndex={0} autoWidth={false} width={styles.pickerMenuWidth.width}>
                  {subMenuOptionPicker}
                </MenuList>
              </Popover>
            );
          case 'combo_with_colour':
            if (opt.nested.type === 'combo_with_colour') {
              const subMenuOptionColorPicker = [emptyMenu, ...subMenuOption];
              subPickerOption = (
                <Popover
                  id="combo_with_colour"
                  open={this.state.pickerOpen === idx}
                  anchorEl={this.state.pickerAnchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  onClose={this.handleRequestPickerClose.bind(this, idx)}
                  useLayerForClickAway
                >
                  <MenuList id={`subMenuPick_${idx}`} style={{ overflowY: 'auto !important' }} className="subMenuPick" maxHeight="300" tabIndex={0} autoWidth={false} width={180}>
                    {subMenuOptionColorPicker}
                  </MenuList>
                </Popover>
              );
            }
          default:
            var menuItemsprops = {};
            if (subPickerOption === null) {
              menuItemsprops.menuItems = subMenuOption;
            } else {
              menuItemsprops.onClick = this.handleTouchTap.bind(this, idx);
            }
            var menuItemElement = this.renderDefaultOption(menuItemsprops, idx, optType, opt, styles.filterWithNoCombo, false, checkItemSelected, true);
            if (subPickerOption !== null) {
              return { picker: subPickerOption, menuItem: menuItemElement };
            }
            return { menuItem: menuItemElement };
        }
      } else {
        let checkItemSelected = false; let
          checkItemSelectedV;
        checkItemSelectedV = this.checkOptAvailable(this.state.valueArray, opt);
        checkItemSelected = checkItemSelectedV > -1;
        let subPickerOption = null; const
          menuItemsprops = {};
        let subMenuOption = [];
        switch (optionType) {
          case 'picker':
          case 'cascade_picker':
            const id = 'simple-popover';
            subMenuOption = optObjArg.options.map(this.renderNestedOptionElement.bind(this, optionType, checkItemSelectedV, opt.key ? opt : null));
            const subMenuOptionPicker = [emptyMenuPicker, ...subMenuOption];
            subPickerOption = (
              <Popover
                id={id}
                open={this.state.pickerOpen}
                className="popover_class"
                anchorEl={this.state.pickerAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={this.handleRequestPickerClose.bind(this, null)}
              >
                <MenuList className="pickerMenu" autoWidth={false} width={styles.pickerMenuWidth.width}>
                  {subMenuOptionPicker}
                </MenuList>
              </Popover>
            );
            break;
          case 'combo_with_colour':
            subMenuOption = optObjArg.options.map(this.renderNestedOptionElement.bind(this, optionType, checkItemSelectedV, opt.key ? opt : null));
            const subMenuOptionColorPicker = [emptyMenu, ...subMenuOption];
            subPickerOption = (
              <Popover
                id="combo_with_colour"
                open={this.state.pickerOpen}
                anchorEl={this.state.pickerAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={this.handleRequestPickerClose.bind(this, idx)}
                useLayerForClickAway
              >
                <MenuList id={`subMenuPick_${idx}`} style={{ overflowY: 'auto !important' }} className={`cascadeCombo subMenuPick ${this.props.filterConfig.className}`} maxHeight="300" tabIndex={0} autoWidth={false} width={180}>
                  {subMenuOptionColorPicker}
                </MenuList>
              </Popover>
            );
            break;
          case 'normal':
            subMenuOption = optObjArg.options.map(this.renderNestedOptionElement.bind(this, optionType, checkItemSelectedV, opt.key ? opt : null));
            const subMenuOptionItems = [emptyMenu, ...subMenuOption];
            subPickerOption = (
              <Popover
                id={`combo_${this.props.index}`}
                open={this.state.pickerOpen && this.props.showFilter}
                className={`${filterClass} popover_class_normal`}
                anchorEl={this.state.pickerAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={this.handleRequestPickerClose.bind(this, this.props.index)}
                useLayerForClickAway
              >
                <MenuList id={`menuPick_${this.props.index}`} style={{ overflowY: 'auto !important' }} className={`cascadeCombo subMenuPick ${this.props.filterConfig.className}`} maxHeight="300" tabIndex={0} autoWidth={false} width={180}>
                  {subMenuOptionItems}
                </MenuList>
              </Popover>
            );
            break;
          default:
            menuItemsprops.onClick = this.handleFilterSelectFieldSelect.bind(this, opt, null);
        }
        const menuItemElement = this.renderDefaultOption(menuItemsprops, idx, optionType, opt, styles.filterElementDropDownMenuItemInnerDivStyle, true, checkItemSelected);
        if (subPickerOption !== null) {
          return { picker: subPickerOption };
        }
        return { menuItem: menuItemElement };
      }
    }
  }

  dropdownSelect = (event) => {
    // var ClickElement = event.currentTarget.offsetTop;
    // DataFormatter.setObjectInStorage('CustomClickSelectField', ClickElement);
    const currentClickedElement = event.currentTarget.offsetTop - event.currentTarget.parentElement.parentElement.offsetTop;
    DataFormatter.setObjectInStorage('CustomClickSelectField', currentClickedElement);
    DataFormatter.setObjectInStorage('CustomClickSelectFieldHeight', event.currentTarget.offsetHeight);
    // console.log("event",event);
    // console.log('CustomClickSelectField', currentClickedElement);
    // console.log('CustomClickSelectFieldHeight',event.currentTarget.offsetHeight);
    const lastElement = document.body.lastElementChild;
    const previousLastElement = lastElement.previousElementSibling;
    if (lastElement.tagName !== 'SCRIPT') {
      lastElement.style.position = 'relative';
      lastElement.click();
    }
    if (previousLastElement.tagName !== 'SCRIPT') {
      previousLastElement.style.position = 'relative';
      previousLastElement.click();
    }
    this.setState({
      dropdownChange: !this.state.dropdownChange,
      selectOpen: !this.state.selectOpen,
      pickerAnchorEl: event.currentTarget
    }, () => {
      setTimeout(() => {
        const element = document.body.lastElementChild;
        element.style.position = 'relative';
      }, 100);
      setTimeout(() => {
        const presentElement = document.querySelectorAll('.subMenuPick');
        if (presentElement.length) {
          presentElement[0].parentElement.style.overflowY = 'scroll';
        }
      }, 500);
    });
  }

  dropdownClose = () => {
    this.setState({
      dropdownChange: false
    });
  }

  renderFilterElement(classes) {
    let filterComponent = null;
    const { language } = this.props;
    if (this.props.filterConfig) {
      const ifilterConfig = this.props.filterConfig;
      if (ifilterConfig && ifilterConfig.type && ifilterConfig.name) {
        let infoButtonAvailable = false;
        switch (ifilterConfig.type) {
          case 'combo_with_info_button':
            if (ifilterConfig.infoButtonUrl && ifilterConfig.infoButtonUrl !== '') {
              infoButtonAvailable = true;
            }
          case 'combo':
            let optionElement = []; let
              picketElement = [];
            if (ifilterConfig.options && ifilterConfig.options.options instanceof Array && ifilterConfig.options.options.length > 0) {
              if (ifilterConfig.options.optionType === 'picker' || ifilterConfig.options.optionType === 'combo_with_colour') {
                const renderOptionList = this.renderOptionElement(ifilterConfig.options, 0, ifilterConfig.options.optionType, ifilterConfig.className);
                if (renderOptionList.picker) {
                  picketElement = picketElement.concat(renderOptionList.picker);
                }
              } else if (ifilterConfig.options.optionType === 'cascade_picker') {
                const options = JSON.parse(JSON.stringify(ifilterConfig.options));
                const selectedSections = this.state.valueArray.find(optVal => optVal.value === 'Sections');
                if (selectedSections && selectedSections.subValue && selectedSections.subValue.length > 0) {
                  const _options = selectedSections.subValue.reduce((optionSet, section) => {
                    const currentOptions = options.options.filter(opt => section.other.aliasValue.includes(opt.value));
                    return [...optionSet, ...currentOptions];
                  }, []);
                  const mapOptions = _options.map(JSON.stringify);
                  const uniqueOptionSet = new Set(mapOptions);
                  const uniqueOptions = Array.from(uniqueOptionSet).map(JSON.parse);
                  options.options = uniqueOptions.sort((opt1, opt2) => (opt1.value > opt2.value ? 1 : -1));
                }
                const renderOptionList = this.renderOptionElement(options, 0, ifilterConfig.options.optionType, ifilterConfig.className);
                if (renderOptionList.picker) {
                  picketElement = picketElement.concat(renderOptionList.picker);
                }
              } else if (ifilterConfig.options.optionType === 'normal') {
                const renderOptionList = this.renderOptionElement(ifilterConfig.options, 0, ifilterConfig.options.optionType, ifilterConfig.className);
                if (renderOptionList.picker) {
                  picketElement = picketElement.concat(renderOptionList.picker);
                }
                if (renderOptionList.menuItem) {
                  if (optionElement.length == 0) {
                    optionElement[0] = emptyMenu;
                  }
                  optionElement = optionElement.concat(renderOptionList.menuItem);
                }
              } else {
                for (let i = 0, iLen = ifilterConfig.options.options.length; i < iLen; i++) {
                  const renderOptionList = this.renderOptionElement(ifilterConfig.options, i, ifilterConfig.options.optionType, ifilterConfig.className);

                  if (renderOptionList.picker) {
                    picketElement = picketElement.concat(renderOptionList.picker);
                  }
                  if (renderOptionList.menuItem) {
                    if (optionElement.length == 0) {
                      optionElement[0] = emptyMenu;
                    }
                    optionElement = optionElement.concat(renderOptionList.menuItem);
                  }
                }
              }
              const arrowStyle = (this.state.dropdownChange || this.state.pickerOpen) ? 'arrow_drop_up' : 'arrow_drop_down';
              let componentStyle = (infoButtonAvailable ? styles.searchFilterWithInfoButtonStyle : styles.searchFilterStyle);
              componentStyle = (this.props.mode === 'primary' ? { ...componentStyle, ...styles.primaryComboBoxStyle } : componentStyle);


              if (optionElement.length > 0) {
                const TfilterComponent = (
                  <div
                    style={{ display: 'inline-block', height: '48px' }}
                    tabIndex={this.props.index}
                    onKeyDown={(event) => {
                      if (event.key == 'Enter') {
                        this.dropdownSelect(event);
                      }
                    }}
                  >
                    <Select
                      onClick={event => this.dropdownSelect(event)}
                      open={this.state.selectOpen}
                      id={`customComboFilter_${this.props.index}`}
                      labelId={`inputLabel_${this.props.index}`}
                      className="customComboBox"
                  // underlineStyle={{display: 'none'}}
                      maxHeight={300}
                      style={componentStyle}
                  // hack
                      value={[]}
                  // iconStyle={(this.state.valueArray instanceof Array && this.state.valueArray.length>0)?{fill:'#007fa3'}:{fill:'#74797b'}}
                  // hintText={ifilterConfig.hintText}
                  // hintStyle={((this.state.valueArray instanceof Array && this.state.valueArray.length>0)?styles.searchFilterHintSelectedStyle:styles.searchFilterHintStyle)}
                  // menuStyle={(infoButtonAvailable?styles.filterElementDropDownMenuWithInfoButtonInlineStyle:styles.filterElementDropDownMenuInlineStyle)}
                  //  MenuProps={dropDownMenuProps}
                      onClose={this.dropdownClose.bind(this)}
                  // IconComponent={ExpandMoreIcon}
                  // MenuProps={{ className: "custom-select-dropdown-menu" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        classes: {
                          paper: classes.menuPaper
                        },
                        getContentAnchorEl: null,
                        className: `custom-select-dropdown-menu ${ifilterConfig.className}`
                      }}
                  // menuItemStyle={styles.filterElementDropDownMenuItemStyle}
                      multiple={(!!ifilterConfig.multiselect)}
                    >
                      {optionElement}

                    </Select>
                  </div>
                );
                if (infoButtonAvailable) {
                  filterComponent = (
                    <div style={styles.searchFilterWithInfoButtonWrapperStyle} title={(ifilterConfig.infoButtonToolTip ? ifilterConfig.infoButtonToolTip : '')} alt={(ifilterConfig.infoButtonToolTip ? ifilterConfig.infoButtonToolTip : '')}>
                      <InputLabel
                        id={`inputLabel_${this.props.index}`}
                        className="customComboBoxLabel"
                        style={{
                          fontSize: 14, position: 'fixed', backgroundColor: 'transparent', fontFamily: 'Open Sans', transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms', cursor: 'auto', textAlign: 'left', marginTop: 0, marginLeft: '10px', marginRight: '70px', zIndex: 1
                        }}
                      >
                        {language.getMessage(ifilterConfig.hintText)}
                      </InputLabel>
                      {TfilterComponent}
                      <div style={styles.infoButtonStyle}><i tabIndex="0" className="material-icons errorList listDetail" onKeyDown={event => this.handleInfoIconKeyDown(event)} onClick={this.showCorrelationChart.bind(this)}>info_outline</i></div>
                      {picketElement}
                    </div>
                  );
                } else {
                  
                  filterComponent = (
                    <div style={styles.searchFilterWrapperStyle}>
                      <InputLabel
                        id={`inputLabel_${this.props.index}`}
                        className="customComboBoxLabel"
                        style={{
                          fontSize: 14, position: 'fixed', backgroundColor: 'transparent', fontFamily: 'Open Sans', transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms', cursor: 'auto', textAlign: 'left', marginTop: 0, marginLeft: '10px', zIndex: 1
                        }}
                      >
                        {language.getMessage(ifilterConfig.hintText)}
                      </InputLabel>
                      {TfilterComponent}
                      {picketElement}
                    </div>
                  );
                }
              } else if (picketElement.length > 0) {
                const TfilterComponent = (
                  <div
                    style={{ display: 'inline-block', height: '48px' }}
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key == 'Enter') {
                        this.handleTouchTap(this, event);
                      }
                    }}
                  >
                    <Select
                      className="customComboBox"
                  // underlineStyle={{display: 'none'}}
                      style={componentStyle}
                  // iconStyle={(this.state.valueArray instanceof Array && this.state.valueArray.length>0)?{fill:'#007fa3'}:{fill:'#74797b'}}
                  // hintText={ifilterConfig.hintText}
                  // hintStyle={((this.state.valueArray instanceof Array && this.state.valueArray.length>0)?styles.searchFilterHintSelectedStyle:styles.searchFilterHintStyle)}
                  // menuStyle={(infoButtonAvailable?styles.filterElementDropDownMenuWithInfoButtonInlineStyle:styles.filterElementDropDownMenuInlineStyle)}
                  //  dropDownMenuProps={dropDownMenuProps}
                      onClose={this.dropdownClose.bind(this)}
                  // IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        classes: {
                          paper: classes.menuPaper
                        },
                        getContentAnchorEl: null,
                        className: 'custom-select-dropdown-menu'
                      }}
                  // menuItemStyle={styles.filterElementDropDownMenuItemStyle}
                      multiple={(!!ifilterConfig.multiselect)}
                      onClick={this.handleTouchTap.bind(this, null)}
                    />
                  </div>
                );
                if (infoButtonAvailable) {
                  filterComponent = (
                    <div style={styles.searchFilterWithInfoButtonWrapperStyle} title={(ifilterConfig.infoButtonToolTip ? ifilterConfig.infoButtonToolTip : '')} alt={(ifilterConfig.infoButtonToolTip ? ifilterConfig.infoButtonToolTip : '')}>
                      <InputLabel
                        id={`inputLabel_${this.props.index}`}
                        className="customComboBoxLabel"
                        style={{
                          fontSize: 14, position: 'fixed', backgroundColor: 'transparent', fontFamily: 'Open Sans', transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms', cursor: 'auto', textAlign: 'left', marginTop: 0, marginLeft: '10px', marginRight: '70px', zIndex: 1
                        }}
                      >
                        {language.getMessage(ifilterConfig.hintText)}
                      </InputLabel>
                      {TfilterComponent}
                      <div style={styles.infoButtonStyle}><i tabIndex="0" className="material-icons errorList listDetail" onKeyDown={event => this.handleInfoIconKeyDown(event)} onClick={this.showCorrelationChart.bind(this)}>info_outline</i></div>
                      {picketElement}
                    </div>
                  );
                } else {
                  const isCascadeCombo = ifilterConfig.options.optionType === 'combo_with_colour';
                  const isCascadePicker = ifilterConfig.options.optionType === 'cascade_picker';
                  const TfilterComponent = (
                    <div
                      style={{ display: 'inline-block', height: '48px' }}
                      tabIndex={0}
                      onKeyDown={(event) => {
                        if (event.key == 'Enter') {
                          this.handleTouchTapFilterWithPickerWrapper(null, ifilterConfig.name, event);
                        }
                      }}
                    >
                      <TextField
                        className={`customComboBox withPicker ${ifilterConfig.name}`}
                        disabled
                      // underlineStyle={{display: 'none'}}
                        inputStyle={{ cursor: 'pointer' }}
                        style={componentStyle}
                        hintText={language.getMessage(ifilterConfig.hintText)}
                        hintStyle={((this.state.valueArray instanceof Array && this.state.valueArray.length > 0) ? styles.searchFilterHintSelectedStyle : styles.searchFilterHintStyle)}
                        onClick={this.handleTouchTapFilterWithPickerWrapper.bind(this, null, ifilterConfig.name)}
                        width={styles.pickerMenuWidth.width}
                      />
                    </div>
                  );
                  filterComponent = (
                    <div className={`inner-${ifilterConfig.name}`} style={isCascadeCombo ? styles.searchFilterWithCascadePickerWrapperStyle : styles.searchFilterWithPickerWrapperStyle}>
                      <InputLabel
                        id={`inputLabel_${this.props.index}`}
                        className="customComboBoxLabel"
                        style={{
                          fontSize: 14, position: 'fixed', backgroundColor: 'transparent', fontFamily: 'Open Sans', transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms', cursor: 'auto', textAlign: 'left', marginTop: 0, marginLeft: '10px', zIndex: 1
                        }}
                      >
                        {
                          language.getMessage(ifilterConfig.hintText)
                        }
                      </InputLabel>
                      {TfilterComponent}
                      <div className={isCascadeCombo || isCascadePicker ? 'cascadedropicon' : 'dropicon'} onClick={this.handleTouchTapFilterWithPickerWrapper.bind(this, null, ifilterConfig.name)}><i className="material-icons">{arrowStyle}</i></div>
                      {picketElement}
                    </div>
                  );
                }
              }
            }
            break;
        }
      }
    }
    return filterComponent;
  }

  render() {
    const { classes } = this.props;
    return (this.renderFilterElement(classes));
  }
}

CustomSelectBox.propTypes = {
  language: PropTypes.object.isRequired
};

export default withStyles(menuListStyles)(compose(
  inject('language'),
  observer
)(injectIntl(CustomSelectBox)));
