/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Stage environment overrides.
 *
 * @author Hari Gangadharan
 */

const stg = {
  // GTM config to be uncommented once the Mojo changes has been pushed to STG
  // Google Tag Manager STG Configs
  GTM_CONTAINER_ID: 'GTM-N8JS47K',
  GTM_AUTH: '1lIWR88O2K8goVYAppZdLw',
  GTM_PREVIEW: 'env-5',
  NEWRELIC_ENABLED: true,
  HAPI_API_BASE_URL: `//digital-library-stg.pearson.com${process.env.NODE_ENV !== 'production' ? ':3000' : ''}`,
  ACCESS_CODE_BASE_URL: 'https://admin-stg.cac.pulse.pearson.com/#/ext-access-code'
};
export default stg;
