import React from 'react';
// import AppStore from '../stores/app.store';
// import AppConstants from '../constants/app.constants';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
// import GoogleAnalyticsUtil from '../util/googleAnalyticsUtil';
// import PrepareSearchObjectUtil from '../util/prepareSearchObjectUtil';
import TagManager from 'react-gtm-module';
import PrepareSearchObjectUtil from '../../../common/utils/prepareSearchObjectUtil';
import FilterComponent from './FilterComponent';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

const lang = DataFormatter.getLanguage();
const styles = {
  filterCloseIconStyle: {
    float: 'right', marginTop: '20px', marginRight: '20px', width: 21, height: 21, cursor: 'pointer', zIndex: '1000'
  },
  primarySearchfloatingLabelStyle: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '1',
    textAlign: 'left',
    color: '#3d3d3d',
    bottom: '15px',
    textIndent: 24,
    paddingRight: '40px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
    width: '95%',
    paddingBottom: '1px'
  },
  primarySearchConsoleStyle: {

  },
  primarySearchConsoleOuterStyle: {
    marginTop: '8px',
    marginBottom: '4px'
  },
  primarySearchTextBoxStyle: { paddingLeft: '21px', height: '48px', width: '90%' },
  primarySearchIconStyle: {
    position: 'absolute', left: '21px', bottom: 13, width: 18, height: 17, cursor: 'pointer', zIndex: '1000'
  },
  primaryCloseIconStyle: {
    position: 'absolute', right: '21px', bottom: 13, width: 18, height: 17, cursor: 'pointer', zIndex: '1000'
  },

  primarySearchIconColor: '#74797b',
  primarySearchTextBoxInputStyle: { textIndent: 24, fontSize: '20px', fontWeight: '300' },
  primaryHeaderWrapperStyle: {
    backgroundColor: 'rgb(20, 129, 163)'
  },
  flatButtonLabelStyles: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '1.25',
    textAlign: 'center',
    color: '#ffffff'
  },
  filterButtonWithCountLabelStyles: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '1.25',
    textAlign: 'center',
    color: '#00857f'
  },
  flatButtonStyles: {
    marginTop: '14px',
    marginLeft: '21px',
    fontFamily: 'Quicksand, sans-serif !important',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.11',
    letterSpacing: 'normal',
    color: '#ffffff'
  },
  floatingLabelStyle: {
    fontFamily: 'HeinemannRomanRm !important',
    color: '#636769 !important'
  }
};

class PrimaryRefineSearchConsole extends React.Component {
  constructor() {
    super();
    this.searchTextField = React.createRef();
    this.searchSubmit = this.searchSubmit.bind(this);
    this.handleEnterKeyPress = this.handleEnterKeyPress.bind(this);
    this.renderFilterOptions = this.renderFilterOptions.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderAutoComplete = this.renderAutoComplete.bind(this);
    this.renderRecentSearch = this.renderRecentSearch.bind(this);
    this.handleSearchInFocus = this.handleSearchInFocus.bind(this);
    this.handleSearchFocusOut = this.handleSearchFocusOut.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.setDropdownLayout = this.setDropdownLayout.bind(this);
    this.renderPrimarySearchConsole = this.renderPrimarySearchConsole.bind(this);
    this.renderPrimaryHeader = this.renderPrimaryHeader.bind(this);
    this.handleSearchKeywordKeyPress = this.handleSearchKeywordKeyPress.bind(this);
    this.handleAutoCompleteClick = this.handleAutoCompleteClick.bind(this);
    this.handleAutoCompleteHide = this.handleAutoCompleteHide.bind(this);
    this.handleSearchkeywordChange = this.handleSearchkeywordChange.bind(this);
    this.handleSearchCall = this.handleSearchCall.bind(this);
    this.handleAutoCompleteCall = this.handleAutoCompleteCall.bind(this);
    this.handleAutoCompleteFetchComplete = this.handleAutoCompleteFetchComplete.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleFilterSelect = this.handleFilterSelect.bind(this);
    this.renderSearchBar = this.renderSearchBar.bind(this);
    this.doSearchCall = this.doSearchCall.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.handleFilterDoneClick = this.handleFilterDoneClick.bind(this);
    this.hideKeyboard = this.hideKeyboard.bind(this);
    this.hiddenCountOfChip = 0;
    this.reload = true;
    this.UpdatehiddenCountOfChip = this.UpdatehiddenCountOfChip.bind(this);
    this.hideKeyboard = this.hideKeyboard.bind(this);
    this.hiddenCountOfChip = 0;
    this.reload = true;
    this.UpdatehiddenCountOfChip = this.UpdatehiddenCountOfChip.bind(this);
  }

  state = {
    searchText: null,
    showAutoComplete: false,
    showRecentSearch: false,
    performSearchPending: false,
    autoCompleteLoading: 0,
    showFilter: false,
    hideFilter: false,
    filterQueue: {},
    chipData: [],
    sectionData: {},
    hiddenCountOfChip: 0,
    filterBy: null
  }

  calculateWindowWidth = function (sendRemainingWidth) {
    const windowWidth = window.innerWidth;
    const booksCanAccomodate = windowWidth / 285;
    const remainingWidth = windowWidth - (parseInt(booksCanAccomodate) * 285);
    if (sendRemainingWidth) {
      return remainingWidth;
    }
    return (windowWidth - remainingWidth - 24);
  }

  handleScroll(event) {
    const viewportOffset = this.searchComponentWrapper.parentNode.getBoundingClientRect();
    const bookShelfWrapperElement = document.querySelector('.bookshelf_wrapper'); let bookShelfWrapperElementOffset; let
      bookShelfWrapperElementOffsetHeight;
    let prtWrpHeight; let
      primarySearchConsoleBCR;
    const primarySearchHeaderWrapper = this.searchComponentWrapper.querySelector('.primarySearchHeaderWrapper');
    if (primarySearchHeaderWrapper) {
      const primarySearchHeaderWrapperBCR = primarySearchHeaderWrapper.getBoundingClientRect();
      prtWrpHeight = primarySearchHeaderWrapperBCR.height;
    }
    let suppressSticky = false;
    if (bookShelfWrapperElement) {
      bookShelfWrapperElementOffset = bookShelfWrapperElement.getBoundingClientRect();
      if (bookShelfWrapperElementOffset.height) {
        bookShelfWrapperElementOffsetHeight = bookShelfWrapperElementOffset.height;
        if (bookShelfWrapperElementOffsetHeight <= 405) {
          suppressSticky = true;
        }
      }
    }
    const currentSelectFieldTop = DataFormatter.getObjectInStorage('CustomClickSelectField');
    const top = viewportOffset.top;
    const searchConsoleFilter = document.getElementsByClassName('searchConsoleFilter')[0];
    const filterResetButton = document.getElementById('filterResetButton');
    const dividerOffset = document.getElementsByClassName('divider')[0].offsetTop;
    let updateScrollValue = 0;
    const filterButtonWrapper = document.getElementById('filterButtonWrapper');
    if (filterButtonWrapper) {
      var buttonWrapper = document.getElementById('filterButtonWrapper').offsetHeight;
    }
    if (top <= 0 && !suppressSticky) {
      if (filterButtonWrapper && document.body.lastElementChild.tagName !== 'STYLE') {
        updateScrollValue = buttonWrapper + currentSelectFieldTop + dividerOffset + 40;
        try { document.body.lastElementChild.firstChild.style.top = `${updateScrollValue}px`; } catch (e) {}
      }
      this.searchComponentWrapper.classList.add('sticky');
      this.searchComponentWrapper.style.width = `${Math.round(viewportOffset.width)}px`;

      if (this.searchHeaderComponentWrapper) {
        /* this.searchHeaderComponentWrapper.style.width = Math.round(viewportOffset.width)
        - this.calculateWindowWidth(true) / 2+ 'px'; */
        this.searchHeaderComponentWrapper.style.width = `${Math.round(viewportOffset.width)}px`;
        this.searchComponentWrapper.parentElement.style.height = `${Math.round(prtWrpHeight)}px`;
        this.searchHeaderComponentWrapper.style.top = '63px';
        this.searchHeaderComponentWrapper.classList.add('stickyScroll');
        this.searchHeaderComponentWrapper.classList.add('sticky');
      }
      if (searchConsoleFilter) {
        searchConsoleFilter.style.top = '64px';
      }
      if (filterButtonWrapper) {
        filterButtonWrapper.style.top = '0px';
      }
      if (filterResetButton) {
        filterResetButton.classList.remove('hidden');
      }
    } else {
      if (filterButtonWrapper && document.body.lastElementChild.tagName !== 'STYLE') {
        updateScrollValue = buttonWrapper + currentSelectFieldTop + dividerOffset + top + 40;
        try { document.body.lastElementChild.firstChild.style.top = `${updateScrollValue}px`; } catch (e) {}
      }
      this.searchComponentWrapper.classList.remove('sticky');
      if (this.searchHeaderComponentWrapper) {
        this.searchHeaderComponentWrapper.classList.remove('sticky');
      }
      this.searchComponentWrapper.style.width = '';

      this.searchComponentWrapper.parentElement.style.height = '';
      const filterTop = `${top + 64}px`;
      if (this.searchHeaderComponentWrapper) {
        this.searchHeaderComponentWrapper.style.top = filterTop;
      }
      if (searchConsoleFilter) {
        searchConsoleFilter.style.top = filterTop;
      }
      if (filterButtonWrapper) {
        filterButtonWrapper.style.top = `${top}px`;
      }
      if (filterResetButton) {
        if (top > 180) {
          filterResetButton.classList.add('hidden');
        } else {
          filterResetButton.classList.remove('hidden');
        }
      }
    }
  }

  componentDidMount() {
    // AppStore.on(AppConstants.EventTypes.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, this.handleAutoCompleteFetchComplete);
    Framework.getEventManager().on(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, () => {
      this.handleAutoCompleteFetchComplete();
    });

    // AppStore.on(AppConstants.EventTypes.USER_PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, this.handleAutoCompleteFetchComplete);
    Framework.getEventManager().on(constants.USER_PRODUCTS_FETCHED_SUCCESS, () => {
      this.handleAutoCompleteFetchComplete();
    });


    if (this.searchTextField && this.searchTextField.current) {
      this.searchTextField.current.addEventListener('keypress', this.handleSearchKeywordKeyPress);
    }

    const primarySearchConsoleBackDrop = this.primarySearchConsoleWrapper && this.primarySearchConsoleWrapper.querySelector('.primarySearchConsoleBackDrop');
    if (primarySearchConsoleBackDrop) {
      primarySearchConsoleBackDrop.addEventListener('click', this.handleSearchFocusOut.bind(this, false));
    }
    setTimeout(this.setDropdownLayout, 2000);
    window.addEventListener('resize', this.handleResize);
    document.getElementById('main').addEventListener('scroll', this.handleScroll);
    const searchState = DataFormatter.getObjectInSessionStorage('searchState');
    DataFormatter.setObjectInSessionStorage('searchState', null);
    if (searchState) {
      this.setState({ ...searchState }, function () {
        DataFormatter.setObjectInSessionStorage('searchState', null);
        this.handleFilterDoneClick(true, searchState.showFilter);
        if (searchState.showFilter) {
          const viewportOffset = this.searchComponentWrapper.parentNode.getBoundingClientRect();
          const top = viewportOffset.top; const
            scrollTopValue = document.getElementById('main').scrollTop;
          if (top > 0) {
            document.getElementById('main').scrollTop = (top + scrollTopValue + viewportOffset.height);
          }
        }
      });
    }
  }

  handleFilterDoneClick(returnedFromPlayer, toShowFilter) {
    let showFilter = false;
    if (returnedFromPlayer) {
      showFilter = toShowFilter;
    }
    if (showFilter) {
      if (typeof this.props.resizeBaacWindow === 'function') {
        this.props.resizeBaacWindow(true);
      }
    }
    this.setState({
      showFilter
    }, () => {
      this.handleSearchCall(true);
    });
  }

  handleResize() {
    // this.setDropdownLayout();
    this.onWindowResize();
  }


  handleAutoCompleteFetchComplete() {
    const updtState = {
      autoCompleteLoading: ((this.state.autoCompleteLoading > 0) ? (this.state.autoCompleteLoading - 1) : 0)
    };

    if (this.state.autoCompleteLoading == 1 && this.state.performSearchPending) {
      updtState.performSearchPending = false;
      this.handleSearchCall();
    }
    this.setState(updtState);
  }

  setDropdownLayout() {
    const primarySearchConsoleDropdown = this.primarySearchConsoleWrapper && this.primarySearchConsoleWrapper.querySelector('.primarySearchDropdown');
    const primarySearchConsoleInput = this.primarySearchConsoleWrapper && this.primarySearchConsoleWrapper.querySelector('.primarySearchFieldWrapper');
    if (primarySearchConsoleDropdown && primarySearchConsoleInput) {
      primarySearchConsoleDropdown.style.width = `${primarySearchConsoleInput.offsetWidth}px`;
    }
  }

  handleSearchInFocus(event) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({
      showAutoComplete: true
    }, (function () {
      document.addEventListener('click', this.handleSearchFocusOut.bind(this, false));
    }));
  }

  handleSearchFocusOut(force, event) {
    let $element;
    if (event) {
      event.stopPropagation();
      $element = $(event.target);
    }
    if (force || ($element && $element.parents('.primarySearchConsole').length > 0)) {
      this.setState({
        showAutoComplete: false
      }, (function () {
        document.removeEventListener('click', this.handleSearchFocusOut);
      }));
    }
    this.searchSubmit();
  }

  UNSAFE_componentWillMount = function () {
  }

  componentWillUnmount() {
    // AppStore.removeListener(AppConstants.EventTypes.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, this.handleAutoCompleteFetchComplete);
    // AppStore.removeListener(AppConstants.EventTypes.USER_PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, this.handleAutoCompleteFetchComplete);

    // AppStore.removeListener(AppConstants.EventTypes.SEARCH_PRODUCTS_FETCHED_SUCCESS, this.handleAutoCompleteHide);

    if (this.searchTextField && this.searchTextField.current) {
      this.searchTextField.current.removeEventListener('focus', this.handleSearchInFocus);
    }
    window.removeEventListener('resize', this.handleResize);
    document.getElementById('main').removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate = function () {
    const isSchoolLibTabClicked = DataFormatter.getObjectInSessionStorage('schoolLibTabClicked');
    DataFormatter.setObjectInSessionStorage('schoolLibTabClicked', false);
    if (isSchoolLibTabClicked) {
      this.handleCancelClick();
    }
    this.onWindowResize();
    this.setFilterPosition();
  }

  setFilterPosition = () => {
    const viewportOffset = this.searchComponentWrapper.parentNode.getBoundingClientRect();
    const top = viewportOffset.top;
    if (this.state.showFilter && top > 0) {
      if ($('div .baacBookItemContainer')) {
        if ($('div .baacBookItemContainer:nth-child(2)')[0]) {
          if ($('div .baacBookItemContainer:nth-child(2)')[0].getBoundingClientRect().x > 0) {
            var viewPortTop = $('div .baacBookItemContainer:nth-child(2)')[0].getBoundingClientRect().x - $('div .baacBookItemContainer:nth-child(1)')[0].getBoundingClientRect().x;
          } else { var viewPortTop = 250; }
        } else { var viewPortTop = 250; }
      } else {
        var viewPortTop = 250;
      }
      document.getElementById('main').scrollTop = viewPortTop;
    }
  }

  onWindowResize = function () {
    const filterButtonWrapper = document.getElementById('filterButtonWrapper');
    const mainContainer = document.getElementById('main');
    if (filterButtonWrapper) {
      let top = this.searchHeaderComponentWrapper.style.top;
      top = top.substring(0, top.length - 2);
      filterButtonWrapper.style.top = `${top - 63}px`;
    }
    const headerViewPortOffset = this.searchComponentWrapper.parentNode.getBoundingClientRect();
    if (this.searchHeaderComponentWrapper) {
      this.searchHeaderComponentWrapper.style.width = `${Math.round(headerViewPortOffset.width)}px`;
      this.searchHeaderComponentWrapper.style.marginRight = `${mainContainer.offsetWidth - mainContainer.clientWidth}px`;
    }
    if (this.searchBarWrapper) {
      const primarySearchFieldwrappr = document.getElementsByClassName('primarySearchFieldWrapper ');
      if (primarySearchFieldwrappr) {
        primarySearchFieldwrappr[0].style.marginLeft = `${this.calculateWindowWidth(true) / 2}px`;
      }
      const primarySearchHeaderWrapper = this.searchComponentWrapper.querySelector('.primarySearchHeaderWrapper');
      if (primarySearchHeaderWrapper) {
        primarySearchHeaderWrapper.style.marginLeft = `${this.calculateWindowWidth(true) / 2}px`;
      }
      const primaryBookBoxFilterBarWrapperChd = this.searchComponentWrapper.querySelector('.primaryBookBoxFilterBarWrapper');
      if (primaryBookBoxFilterBarWrapperChd) {
        primaryBookBoxFilterBarWrapperChd.style.marginRight = `${(this.calculateWindowWidth(true) / 2) - 24}px`;
      }
      const teacherAssignedBookShelfComponent = document.getElementById('teacherAssignedBookShelfComponent');
      const selfAssignedBookShelfComponent = document.getElementById('selfAssignedBookShelfComponent');
      if (teacherAssignedBookShelfComponent) {
        teacherAssignedBookShelfComponent.style.marginLeft = `${this.calculateWindowWidth(true) / 2}px`;
      }
      if (selfAssignedBookShelfComponent) {
        selfAssignedBookShelfComponent.style.marginLeft = `${this.calculateWindowWidth(true) / 2}px`;
      }
    } else {
      const changedMargin = `${this.calculateWindowWidth(true) / 2}px`;
      const selfAssignedBookShelfComponent = document.getElementById('selfAssignedBookShelfComponent');
      const studentDashboard = document.getElementsByClassName('student-dashboard-container');
      const teacherAssignedBookShelfComponent = document.getElementById('teacherAssignedBookShelfComponent');
      if (studentDashboard) {
        studentDashboard[0].style.marginLeft = changedMargin;
      }
      if (teacherAssignedBookShelfComponent) {
        teacherAssignedBookShelfComponent.style.marginLeft = changedMargin;
      }

      if (selfAssignedBookShelfComponent) {
        selfAssignedBookShelfComponent.style.marginLeft = changedMargin;
      }
    }
  }

  handleEnterKeyPress = function (event) {
    if (event.which == 13) {
      event.preventDefault();
      try {
        if (this.searchTextField && this.searchTextField.current) {
          this.handleSearchFocusOut(true);
        }
      } catch (err) {
      }
    }
  }

 handleSearchKeywordKeyPress = function (event) {
   if (event.which == 13) {
     event.preventDefault();
     this.setState({
       performSearchPending: true
     }, () => {
       this.handleAutoCompleteHide(() => {
         if (!this.state.autoCompleteLoading) {
           this.handleSearchCall(true);
         } else if (typeof this.props.enableLoading === 'function') {
           this.props.enableLoading();
         }
       });
     });
   }
 }

  handleSearchkeywordChange = (event) => {
    let searchText = null;
    if (event.target.value !== '') {
      searchText = event.target.value;
      const updateState = {
        searchText
      };
      if (typeof this.props.handleAutoComplete === 'function' && searchText.length >= 3) {
        updateState.showAutoComplete = true;
        updateState.autoCompleteLoading = this.state.autoCompleteLoading + 1;
      } else {
        updateState.showAutoComplete = false;
      }
      this.setState(updateState, () => {
        if (this.state.showAutoComplete && this.state.searchText.length >= 3) {
          this.props.handleAutoComplete(this.state.searchText);
        }
      });
    } else {
      this.setState({
        searchText
      });
    }
  };

handleAutoCompleteClick = (event) => {
  let searchText = null;
  if (event.target.textContent !== '') {
    searchText = event.target.textContent;
    this.setState({
      searchText
    }, () => {
      this.handleAutoCompleteHide(() => {
        if (!this.state.autoCompleteLoading) {
          this.handleSearchCall(true);
        } else if (typeof this.props.enableLoading === 'function') {
          this.props.enableLoading();
        }
      });
    });
  }
}

handleSearchCall(checkForSearchObject, isCancelClicked, isFilterOpened) {
  const { language, intl } = this.props;
  let arg = null;
  const searchText = this.state.searchText;
  let filterBy = this.state.filterBy;
  if (isFilterOpened) {
    filterBy = 'all';
  } else if (checkForSearchObject) {
    arg = PrepareSearchObjectUtil.prepareSearchObject(searchText, this.state.filterQueue);
  }
  DataFormatter.setObjectInSessionStorage('tempSearchState', this.state);
  const eventLabel = { userId: DataFormatter.getKeyFromObject('userInformation', 'id') };
  let eventCategory; let eventAction; let
    event = '';
  if (searchText) {
    eventCategory = intl.formatMessage(language.getText('keyword_search'));
    eventAction = intl.formatMessage(language.getText('search_executed'));
    eventLabel.keyword = searchText;
    event = 'keyword_search_student';
  }
  if (filterBy) {
    eventCategory = intl.formatMessage(language.getText('filter_option'));
    eventAction = intl.formatMessage(language.getText('filter_executed'));
    eventLabel.filterOption = filterBy;
    if (arg && arg[0].metadata) {
      eventLabel.filterValue = arg[0].metadata;
    }
    event = 'filter_option_student';
  }
  if (eventCategory) {
    const gtmData = {
      dataLayer: {
        event,
        event_category: eventCategory,
        event_action: eventAction,
        event_label: null,
        user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
        keyword: (searchText && searchText !== '') ? searchText : null,
        filter_by: (filterBy && filterBy !== '') ? filterBy : null,
        filter_option: (arg && arg[0].hasOwnProperty('metadata')) ? `Number of filter options: ${Object.keys(arg[0].metadata).length}` : null,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  }
  if (filterBy === 'filter') {
    this.props.handleSearch(arg);
  } else if (filterBy === 'returned') {
    if (typeof this.props.handleReturnedFilter === 'function') {
      this.props.handleReturnedFilter(searchText);
    }
  } else if (typeof this.props.handleSearch === 'function') {
    DataFormatter.setObjectInStorage('restrictedProductCall', true);
    this.props.handleSearch(searchText, filterBy);
  }
  this.hideKeyboard();
}

handleAutoCompleteCall(searchText) {
  if (this.state.filterBy === 'returned') {
    if (typeof this.props.handleReturnedFilter === 'function') {
      this.props.handleReturnedFilter(searchText, true);
    }
  } else if (typeof this.props.handleAutoComplete === 'function') {
    this.props.handleAutoComplete(searchText, this.state.filterBy);
  }
}

  handleAutoCompleteHide = (callback) => {
    this.setState({
      showAutoComplete: false
    }, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  hideKeyboard = function () {
    document.activeElement.blur();
    $('input').blur();
  }

  searchSubmit = function (event) {
    this.handleAutoCompleteHide(() => {
      if (!this.state.autoCompleteLoading) {
        this.handleSearchCall(true);
      } else if (typeof this.props.enableLoading === 'function') {
        this.props.enableLoading();
      }
    });
  }

  searchClear = function (event) {
    const { language, intl } = this.props;
    DataFormatter.setObjectInSessionStorage('searchState', null);
    DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    let eventCategory; let
      eventAction = '';
    if (searchText) {
      eventCategory = intl.formatMessage(language.getText('search_cancel'));
      eventAction = intl.formatMessage(language.getText('search_cancel_executed'));
    }
    if (eventCategory) {
      const gtmData = {
        dataLayer: {
          event: 'search_cancel_student',
          event_category: eventCategory,
          event_action: eventAction,
          event_label: null,
          user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
          keyword: (this.state.searchText !== '') ? this.state.searchText : null,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }
    this.setState({
      searchText: null
    }, function () {
      this.handleAutoCompleteHide(() => {
        this.handleSearchCall(true);
      });
    });
    // this.setState({
    //   searchText: null
    // }, function () {
    //   this.handleAutoCompleteHide((function () {
    //     if (Object.keys(this.state.filterQueue).length > 0){
    //       this.handleSearchCall(true);
    //      } else {
    //       this.handleSearchCall(null);
    //      }
    //   }).bind(this))
    // });
  }

  handleFilterSelect = function (filterName) {
    DataFormatter.setObjectInStorage('restrictedProductCall', true);
    let updtState = {};
    updtState.filterBy = filterName;
    if (filterName === 'filter') {
      if (typeof this.props.resizeBaacWindow === 'function') {
        this.props.resizeBaacWindow(!this.state.showFilter);
      }
      updtState.showFilter = true;
      this.setState(updtState, () => {
        this.setFilterPosition();
        this.handleSearchCall(null, null, true);
      });
    } else {
      if (this.state.showFilter && !this.state.hideFilter) {
        if (typeof this.props.resizeBaacWindow === 'function') {
          this.props.resizeBaacWindow(false);
        }
      }
      updtState = {
        ...updtState,
        showFilter: false,
        hideFilter: false,
        filterQueue: {},
        chipData: [],
        sectionData: {},
        hiddenCountOfChip: 0
      };

      this.setState(updtState, () => {
        this.handleSearchCall();
      });
    }
    // this.props.handleScrollWithRecords();
    // this.props.handleScrollWithRecords();
  }

  renderFilterOptions() {
    const { language, intl } = this.props;
    const primaryFilterOptions = this.props.primaryFilterOptions;
    const defaultPrimaryFilter = this.props.defaultPrimaryFilter;
    if (primaryFilterOptions instanceof Array && primaryFilterOptions.length > 0) {
      return (
        <ul className="primaryFilter" role="menubar">
          {
        primaryFilterOptions.map((v, i, ary) => {
          let mapValue;
          switch (v.displayValue) {
            case 'All':
              mapValue = intl.formatMessage(language.getText('filter_all'));
              break;
            case 'New':
              mapValue = intl.formatMessage(language.getText('filter_new'));
              break;
            case 'I&apos;m reading':
              mapValue = intl.formatMessage(language.getText('filter_reading'));
              break;
            case 'Finished':
              mapValue = intl.formatMessage(language.getText('filter_finished'));
              break;
            case 'Liked':
              mapValue = intl.formatMessage(language.getText('filter_liked'));
              break;
            case 'Returned':
              mapValue = intl.formatMessage(language.getText('filter_returned'));
              break;
            case 'Find Books':
              mapValue = intl.formatMessage(language.getText('findBooks'));
              break;
          }
          let displayValue = mapValue;
          const idenityValue = (v.value ? v.value : v);
          let isSelected = false;
          let isLast = false;
          if (!this.state.filterBy) {
            if (defaultPrimaryFilter == idenityValue) {
              isSelected = true;
            }
          } else if (this.state.filterBy == idenityValue) {
            isSelected = true;
          }
          if (ary.length === (i + 1)) {
            isLast = true;
          }
          let labelDisplayValue;
          if (v.displayType === 'icon') {
            if (idenityValue === 'filter') {
              labelDisplayValue = `${displayValue}`;
              displayValue = `<div>${displayValue}<div class='filter'></div> </div>`;
            } else {
              displayValue = '';
            }
          }
          let labelValue = displayValue;
          const that = this;
          if (idenityValue === 'liked') {
            labelValue = `${intl.formatMessage(language.getText('favourite'))}`;
          }
          return (
            <li
              key={i}
              role="none"
            >
              <button
                tabIndex="0"
                className={`${(isSelected ? ' selected' : '') + (isLast ? ' last' : '')} ${idenityValue}`}
                role="button"
                dataValue={idenityValue}
                onKeyPress={this.handleFilterSelect.bind(this, idenityValue)}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    that.handleFilterSelect.call(that, idenityValue);
                  }
                }}
                onClick={this.handleFilterSelect.bind(this, idenityValue)}
                dangerouslySetInnerHTML={{ __html: displayValue }}
                aria-label={labelDisplayValue}
                value={labelDisplayValue}
              />
            </li>
          );
        })
      }
        </ul>
      );
    }
    return null;
  }

  renderDropdown() {
    return (<div className="primarySearchDropdown">{(this.state.searchText == null) ? (this.state.showRecentSearch ? this.renderRecentSearch() : null) : this.renderAutoComplete()}</div>);
  }

  renderRecentSearch() {
    const { language, intl } = this.props;
    return (
      <ul className="primarySearchRecentHistory">
        <li className="heading"><h3>{intl.formatMessage(language.getText('recentSearches'))}</h3></li>
      </ul>
    );
  }

  highlightTextInData = function (highlightText, data) {
    let st = -1; let ed; let
      wrappedBookTitleData = [];
    if (data) {
      if (typeof highlightText === 'string' && highlightText.length > 1) {
        st = (highlightText) ? (data.toLowerCase()).indexOf(highlightText.toLowerCase()) : -1;
        wrappedBookTitleData = data;
        if (st !== -1) {
          wrappedBookTitleData = wrappedBookTitleData.replace(new RegExp(`(${highlightText})`, 'ig'), "<span class='highlightSearchText'>$1</span>");
        }
      } else if (highlightText instanceof Array) {
        if (highlightText.indexOf(data) !== -1) {
          wrappedBookTitleData.push(`<span class='highlightSearchText'>${data}</span>`);
        } else {
          let stArray = [];
          wrappedBookTitleData = data;
          for (let i = 0, ilen = highlightText.length; i < ilen; i++) {
            let tempSt; const stArrayItem = {}; const
              tempHighlightText = highlightText[i];
            if (tempHighlightText && tempHighlightText.indexOf(data) !== -1) {
              wrappedBookTitleData = [];
              wrappedBookTitleData.push(`<span class='highlightSearchText'>${data}</span>`);
              stArray = [];
              break;
            } else {
              let tempHighlightTextReg;
              if (typeof tempHighlightText === 'string' && highlightText.length > 1) {
                wrappedBookTitleData = wrappedBookTitleData.replace(new RegExp(`(${tempHighlightText})`, 'ig'), "<span class='highlightSearchText'>$1</span>");
              }
            }
          }

          if (wrappedBookTitleData.length == 0) {
            wrappedBookTitleData = data;
          }
        }
      } else {
        wrappedBookTitleData = data;
      }
    } else {
      wrappedBookTitleData = data;
    }
    if (wrappedBookTitleData instanceof Array && wrappedBookTitleData.length > 0) {
      wrappedBookTitleData = wrappedBookTitleData.join('');
    }

    return { __html: wrappedBookTitleData };
  }

  renderAutoComplete() {
    let autoCompleteListElement = null; let
      autoCompleteList;
    if (this.state.showAutoComplete && this.props.dataStoreAutoComplete && this.props.dataStoreAutoComplete instanceof Array) {
      autoCompleteList = [...this.props.dataStoreAutoComplete];

      autoCompleteListElement = autoCompleteList.map((v, i) => (<li onClick={this.handleAutoCompleteClick} dangerouslySetInnerHTML={this.highlightTextInData(this.state.searchText, v)} />));
    }
    return (
      <ul className="primarySearchAutoComplete">
        {autoCompleteListElement}
      </ul>
    );
  }

  resetChipCount = () => {
    this.setState({ hiddenCountOfChip: 0 });
  }

  UpdatehiddenCountOfChip = function (count) {
    this.hiddenCountOfChip = count;
    this.reload = false;
  }

  doSearchCall = (filterData, chips, selectedSections, checkForSearchObject, isCancelClicked) => {
    this.setState({
      filterQueue: filterData,
      chipData: chips,
      sectionData: selectedSections
    }, () => {
      this.handleSearchCall(checkForSearchObject, isCancelClicked);
    });
  }

  handleFilterButtonClick() {
    if (typeof this.props.resizeBaacWindow === 'function') {
      this.props.resizeBaacWindow(this.state.hideFilter);
    }
    let hiddenCountOfChip = 0;
    if (document.getElementById('hiddenCountOfChip')) {
      hiddenCountOfChip = document.getElementById('hiddenCountOfChip').getAttribute('value');
      if (parseInt(hiddenCountOfChip)) {
        hiddenCountOfChip = parseInt(hiddenCountOfChip);
      } else {
        hiddenCountOfChip = 0;
      }
    }
    this.setState({ hideFilter: !this.state.hideFilter, hiddenCountOfChip });
  }

  handleCancelClick(isFilterCancel) {
    DataFormatter.setObjectInStorage('restrictedProductCall', false);
    DataFormatter.setObjectInSessionStorage('searchState', null);
    DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    if (document.getElementById('hiddenCountOfChip')) {
      document.getElementById('hiddenCountOfChip').setAttribute('value', 0);
    }
    if (typeof this.props.resizeBaacWindow === 'function') {
      this.props.resizeBaacWindow(false);
    }

    let updateState = {
      searchText: null,
      showFilter: false,
      filterQueue: {},
      chipData: [],
      sectionData: {},
      selectedAll: false,
      hideFilter: false
    };
    if (isFilterCancel) {
      updateState = {
        showFilter: false,
        filterQueue: {},
        chipData: [],
        sectionData: {},
        hideFilter: false,
        filterBy: 'all',
        hiddenCountOfChip: 0
      };
    }

    this.setState(updateState, () => {
      if (isFilterCancel) {
        if ((typeof this.state.searchText === 'string' && this.state.searchText.trim() != '')) {
          this.handleSearchCall(true);
        } else {
          this.handleSearchCall(null, true);
        }
      } else {
        this.handleSearchCall(null, true);
      }
    });
  }

  renderPrimaryHeader() {
    return this.props.headingElement != null ? (
      <div style={{ backgroundColor: '#f8f8f8' }} ref={(searchHeaderComponentWrapper) => { this.searchHeaderComponentWrapper = searchHeaderComponentWrapper; }}>
        <div className="primarySearchHeaderWrapper searchHeaderWrapper">
          <div style={{
            float: 'left',
            width: '100%',
            backgroundColor: '#f8f8f8'
          }}
          >
            {this.props.headingElement}
          </div>
        </div>
      </div>
    ) : null;
  }

  renderSearchBar() {
    const { language, intl } = this.props;
    const searchText = this.state.searchText ? this.state.searchText : '';
    if (this.props.showKeywordSearch) {
      return (
        <div className="primarySearchConsoleBarWrapper" ref={(searchBarWrapper) => { this.searchBarWrapper = searchBarWrapper; }}>
          <div className="primarySearchConsoleBackDrop " />
          <div className="primarySearchConsole" style={(styles.primarySearchConsoleOuterStyle)}>
            <div className="primarySearchFieldWrapper " style={(styles.primarySearchConsoleStyle)}>
              <SearchIcon style={styles.primarySearchIconStyle} color={styles.primarySearchIconColor} onClick={this.searchSubmit.bind(this)} />
              <TextField
                inputRef={this.searchTextField}
                id="searchText"
                fullWidth
                onChange={this.handleSearchkeywordChange}
                // inputStyle={styles.primarySearchTextBoxInputStyle}
                placeholder={intl.formatMessage(language.getText('keywordSearch'))}
                aria-label={intl.formatMessage(language.getText('keywordSearch'))}
                autoComplete="off"
                value={searchText}
                type="text"
                // hintStyle={styles.primarySearchfloatingLabelStyle}
                underlineShow={false}
                // floatingLabelStyle={styles.floatingLabelStyle}
                style={styles.primarySearchTextBoxStyle}
                classes={{ root: 'primarySearchTextBoxInputStyle' }}
              />
              <CloseIcon style={styles.primaryCloseIconStyle} color={styles.primarySearchIconColor} onClick={this.searchClear.bind(this)} className={(this.state.searchText == null ? ' hidden' : ' ')} />
            </div>
            {(this.state.showAutoComplete) ? this.renderDropdown() : null}
          </div>

        </div>
      );
    }
    return null;
  }

  renderPrimarySearchConsole() {
    const { language, intl } = this.props;
    let filterButton = null;
    const filterButtonWrapperStyle = {};
    const isiPad = navigator.userAgent.match(/iPad/i) != null;
    if (isiPad || navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC' || navigator.platform === 'Mac68K') {
      filterButtonWrapperStyle.marginRight = 0;
    }
    if (this.state.showFilter) {
      filterButton = (
        <div
          id="filterButtonWrapper"
          className="filterButton"
          style={filterButtonWrapperStyle}
        >
          <Button
            id="filterButton"
            labelPosition="before"
            style={{ ...styles.flatButtonStyles }}
            labelStyle={styles.flatButtonLabelStyles}
            onClick={this.handleFilterButtonClick.bind(this, true)}
            backgroundColor="transparent"
          >
            {intl.formatMessage(language.getText('findBooks'))}
          </Button>
          <CloseIcon
            id="filterCloseIcon"
            tabIndex="0"
            aria-label="Close filter button"
            style={{ ...styles.filterCloseIconStyle, color: '#fff' }}
            color="white"
            onClick={this.handleCancelClick.bind(this, true)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                this.handleCancelClick(true);
              }
            }}
          />
        </div>
      );
    }
    if (this.state.hideFilter && this.state.chipData.length > 0) {
      const buttonLabel = `${intl.formatMessage(language.getText('filterButtonLabel'))}(${this.state.chipData.length})`;
      filterButton = (
        <div
          id="filterButtonWrapper"
          className="filterButton"
        >
          <Button
            variant="flat"
            id="filterButton"
            label={buttonLabel}
            labelPosition="before"
            style={{ ...styles.flatButtonStyles }}
            labelStyle={styles.filterButtonWithCountLabelStyles}
            onClick={this.handleFilterButtonClick.bind(this, true)}
            backgroundColor="#ffffff"
            borderRadius="2px"
          >
            {buttonLabel}
          </Button>
        </div>
      );
    }
    return (
      <div ref={(primarySearchConsoleWrapper) => { this.primarySearchConsoleWrapper = primarySearchConsoleWrapper; }}>
        <div className="primarySearchConsoleWrapper searchConsoleWrapper" style={styles.primaryHeaderWrapperStyle}>
          {this.props.showKeywordSearch ? this.renderSearchBar() : null}
          <div className="primaryFilterWrapper">
            <div className={this.props.showKeywordSearch ? 'primaryBookBoxFilterBarWrapper' : 'primaryFilterBarWithNoSearchBarWrapper'}>
              {this.renderFilterOptions()}
            </div>
            {filterButton}
          </div>

        </div>
        <FilterComponent
          mode="primary"
          hiddenCountOfChip={this.hiddenCountOfChip}
          UpdatehiddenCountOfChip={count => this.UpdatehiddenCountOfChip(count)}
          reload={this.reload}
          resetChipCount={this.resetChipCount}
          showFilter={this.state.showFilter}
          hideFilter={this.state.hideFilter}
          doSearchCall={this.doSearchCall}
          filterQueue={this.state.filterQueue}
          chipData={this.state.chipData}
          searchText={this.state.searchText}
          sectionData={this.state.sectionData}
        />
      </div>
    );
  }


  render() {
    let isPrimary = false; let
      headerElement;
    if (this.props.mode == 'primary') {
      isPrimary = true;
    }
    return (
      <div id="primarySearchComponent">
        <div ref={(searchComponentWrapper) => { this.searchComponentWrapper = searchComponentWrapper; }}>
          {this.renderPrimarySearchConsole()}
          {this.renderPrimaryHeader()}
        </div>


      </div>
    );
  }
}

// PrimaryRefineSearchConsole.contextTypes = {
//   router: React.PropTypes.object.isRequired
// }
// export default PrimaryRefineSearchConsole;
export default compose(
  inject('language'),
  observer
)(injectIntl(PrimaryRefineSearchConsole));
