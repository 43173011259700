import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import injectTapEventPlugin from 'react-tap-event-plugin';
import TagManager from 'react-gtm-module';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
import StudentImportProgressbar from './studentImportProgressbar';
import CommonUtils from '../../../common/utils/CommonUtils';

const lang = DataFormatter.getLanguage();
let batchStatInterval;
try {
  injectTapEventPlugin();
} catch (e) { }

class TabComponent extends React.Component {
  constructor() {
    super();
    this.setActiveTab = this.setActiveTab.bind(this);
    // this.setupActiveTabWidth = this.setupActiveTabWidth.bind(this);
    this.getTabContentProxy = this.getTabContentProxy.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.renderTabsContent = this.renderTabsContent.bind(this);
    this.sendGA = this.sendGA.bind(this);
    clearTimeout(batchStatInterval);
  }

  state = {
    activeTab: (window.location.pathname === '/search' ? 1 : 0),
    isSecondarySchoolLibTab: false,
    batchStatus: '',
    progressBarMessage: '',
    showProgressbarBlue: false,
    showProgressbarGreen: false,
    showProgressbarYellow: false,
    showProgressbarRed: false,
    latestBatchId: '',
    // Reports
    progressReportBarMessage: '',
    showReportProgressbarRed: false
  }

  UNSAFE_componentWillMount = function () {
    const isBackFromPlayer = DataFormatter.getObjectInStorage('fromPlayerBackButtonClick');
    let tab = 0;
    if (isBackFromPlayer) {
      if (DataFormatter.getObjectInStorage('userMode') === 'secondary') {
        tab = 0;
        DataFormatter.setObjectInStorage('fromPlayerBackButtonClick', false);
      } else {
        tab = (DataFormatter.getObjectInStorage('currentTab') === 'my-bookbox' ? 0 : 1);
      }

      this.setState({ activeTab: tab });
    }
    if (localStorage.getItem('usertype') == 'teacher') {
      // AppActions.getUserBatch();
      Framework.getEventManager().publish(constants.GET_USER_BATCH_REQUESTED, { userId: DataFormatter.getKeyFromObject('userInformation', 'id') });
    }
  }

  componentDidMount = function () {
    // AppStore.on(AppConstants.EventTypes.PRODUCT_PROVISION_COMPLETED, this.productProvisioned);
    Framework.getEventManager().on(constants.SEND_BOOK_SUCCESS, (respData) => {
      this.productProvisioned();
    });
    // AppStore.on(AppConstants.ActionTypes.SWITCH_TO_BOOKBOX, this.switchToMyBookbox);
    Framework.getEventManager().on(constants.SWITCH_TO_BOOKBOX, (respData) => {
      this.switchToMyBookbox();
    });
    // AppStore.on(AppConstants.EventTypes.SWITCH_TO_LIBRARY, this.switchToLibrary);
    // AppStore.on(AppConstants.EventTypes.GET_USER_BATCH,this.callbackGetUserBatch);
    Framework.getEventManager().on(constants.GET_USER_BATCH_SUCCESS, (setUserBatch) => {
      this.callbackGetUserBatch(setUserBatch);
    });
    // AppStore.on(AppConstants.EventTypes.INGESTION_GET_BATCH_STATUS,this.callbackGetBatchStatus);
    Framework.getEventManager().on(constants.INGESTION_GET_BATCH_STATUS_SUCCESS, (setIngestionBatchStatus) => {
      // batchStatInterval = setTimeout(function () {
      //   Framework.getEventManager().publish(constants.INGESTION_GET_BATCH_STATUS_REQUESTED,{batchId:setIngestionBatchStatus.id});
      // }, 5000);
      this.callbackGetBatchStatus(setIngestionBatchStatus);
    });
    // AppStore.on(AppConstants.EventTypes.INGESTION_GET_BATCH_ITEMS,this.callbackGetBatchItems);
    Framework.getEventManager().on(constants.INGESTION_GET_BATCH_ITEMS_SUCCESS, (setIngestionBatchItems) => {
      this.callbackGetBatchItems(setIngestionBatchItems);
    });
    Framework.getEventManager().on(constants.DOWNLOAD_REPORT_ERROR, (respData) => {
      this.setState({ ReportRedflag: respData.fileFormat });
      this.showReportRedBar();
    });
  }

  showReportRedBar = function () {
    this.setState({ showReportProgressbarRed: true });
  }

  handleReportProgressbarCloseRed = () => {
    this.setState({ showReportProgressbarRed: false });
  }

  reportBanner = function () {
    const { language } = this.props;
    return (
      <StudentImportProgressbar
        show={localStorage.getItem('usertype') == 'teacher' && this.state.showReportProgressbarRed}
        message={`${language.getMessage('report_error_message')} ${this.state.ReportRedflag} ${language.getMessage('report_error_message_m1')}`}
        bgColor="red"
        disableClose={false}
        handleClose={this.handleReportProgressbarCloseRed}
      />
    );
  }


  switchToLibrary = () => {
    this.setActiveTab(1);
  }


  productProvisioned = () => {
    const { language, intl } = this.props;
    const mode = DataFormatter.getObjectInStorage('userMode');
    let tabName = '';
    if (mode === 'primary') {
      tabName = intl.formatMessage(language.getText('school_library'));
    } else {
      tabName = intl.formatMessage(language.getText('school_library'));
    }
  }

  productReturned = () => {
    const { language, intl } = this.props;
    const self = this;
    const mode = DataFormatter.getObjectInStorage('userMode');
    let tabName = '';
    if (mode === 'primary') {
      tabName = intl.formatMessage(language.getText('school_library'));
    } else {
      tabName = intl.formatMessage(language.getText('students'));
    }
    setTimeout(() => {
      self.setActiveTab(1, tabName, mode);
    }, 3001);
  }

  sendGA = function (tabIndex, tabName) {
    const { language, intl } = this.props;
    let gtmData;
    if (!tabName) {
      tabName = (tabIndex === 1) ? intl.formatMessage(language.getText('school_library')) : intl.formatMessage(language.getText('my_bookbox'));
    }
    if (tabName === intl.formatMessage(language.getText('my_bookbox'))) {
      gtmData = {
        dataLayer: {
          event: 'my_bookbox_tab_clicked',
          event_category: intl.formatMessage(language.getText('my_bookbox_tab')),
          event_action: intl.formatMessage(language.getText('my_bookbox_tab_clicked')),
          event_label: null,
          user_id: DataFormatter.getKeyFromObject('userInformation', 'id')
        }
      };
    } else if (tabName === intl.formatMessage(language.getText('school_library'))) {
      gtmData = {
        dataLayer: {
          event: 'school_library_tab_clicked',
          event_category: intl.formatMessage(language.getText('school_library_tab')),
          event_action: intl.formatMessage(language.getText('school_library_tab_clicked')),
          event_label: null,
          user_id: DataFormatter.getKeyFromObject('userInformation', 'id')
        }
      };
    } else if (tabName === intl.formatMessage(language.getText('students'))) {
      //Appcues placeholder
      // const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
      // if (userId && window.Appcues) {
      //   window.Appcues.identify(userId, {
      //     role: 'teacher'
      //   });
      // }
      gtmData = {
        dataLayer: {
          event: 'student_tab_clicked',
          event_category: intl.formatMessage(language.getText('student_tab')),
          event_action: intl.formatMessage(language.getText('student_tab_clicked')),
          event_label: null,
          user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
    }
    TagManager.dataLayer(gtmData);
  }

  setActiveTab = function (tabIndex, tabLabel, mode) {
    const { language, intl } = this.props;
    this.sendGA(tabIndex, tabLabel);
    let isSecondarySchoolLibTab = false;
    if (tabLabel === intl.formatMessage(language.getText('students'))
      || tabLabel === intl.formatMessage(language.getText('my_bookbox'))) {
      DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    }
    const searchPerformed = DataFormatter.getObjectInSessionStorage('tempSearchState');
    if (searchPerformed !== null) {
      DataFormatter.setObjectInSessionStorage('schoolLibTabClicked', true);
      isSecondarySchoolLibTab = true;
    }
    DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    this.setState({
      activeTab: tabIndex,
      isSecondarySchoolLibTab
    }, function () {
      if (this.state.activeTab == 0) {
        Framework.redirectTo('/app');
      }
    });
    if (tabLabel === intl.formatMessage(language.getText('students'))) {
      document.getElementsByClassName('mainAppContainer')[0].style.overflowX = 'unset';
    } else {
      document.getElementsByClassName('mainAppContainer')[0].style.overflowX = 'hidden';
    }
  }

  switchToMyBookbox = () => {
    if (this.state.activeTab != 0) {
      this.setActiveTab(0);
    }
  }

  getTabContentProxy = function (activeTabArg) {
    if (this.props.tabsList instanceof Array && !isNaN(activeTabArg)
      && this.props.tabsList[parseInt(activeTabArg)] && typeof this.props.tabsList[parseInt(activeTabArg)].content !== 'undefined') {
      return this.props.tabsList[parseInt(activeTabArg)].content;
    }
    return null;
  }

  renderTabs = function () {
    if (this.props.tabsList instanceof Array) {
      return this.props.tabsList.map(function (tabData, index) {
        const mode = tabData.hasOwnProperty('mode') ? tabData.mode : '';
        return (
          <button
            role="tab"
            key={index}
            className={`tab${this.state.activeTab == index ? ' active' : ''}`}
            onClick={this.setActiveTab.bind(this, index, tabData.tabLabel, mode)}
            aria-controls={tabData.id}
            tabIndex="0"
            style={(tabData.tabStyles ? tabData.tabStyles : {})}
            aria-label={tabData.tabLabel}
          >
            {tabData.tabLabel}
          </button>
        );
      }, this);
    }
  }

  renderTabsContent = function () {
    if (this.props.tabsList instanceof Array) {
      return this.props.tabsList.map(function (tabData, index) {
        return (
          <div
            role={`${tabData.id}panel`}
            aria-labelledby={tabData.id}
            key={index}
            className={`tab-content ${this.state.activeTab == index ? ' ' : ' hidden'}`}
            aria-controls={tabData.id}
            id={tabData.id}
          >
            {(this.state.activeTab == index ? tabData.content : null)}
          </div>
        );
      }, this);
    }
  }

  handleProgressbarCloseBlue = () => {
    this.setState({ showProgressbarBlue: false });
  }

  handleProgressbarCloseGreen = () => {
    localStorage.setItem('latestBatchId', this.state.latestBatchId);
    this.setState({ showProgressbarGreen: false });
  }

  handleProgressbarCloseYellow = () => {
    localStorage.setItem('latestBatchId', this.state.latestBatchId);
    this.setState({ showProgressbarYellow: false });
  }

  handleProgressbarCloseRed = () => {
    localStorage.setItem('latestBatchId', this.state.latestBatchId);
    this.setState({ showProgressbarRed: false });
  }

  callbackGetUserBatch = (setUserBatch) => {
    if (setUserBatch.length == 0 || setUserBatch[0] == 'undefined' || setUserBatch[0].id == 'undefined') {
      return false;
    }
    // If the latest batch is already shown in progress bar we have to block it showing again
    const latestBatchId = setUserBatch[0].id;
    if (localStorage.getItem('latestBatchId')) {
      if (localStorage.getItem('latestBatchId') === latestBatchId) { // Batch already shown"
        return false;
      }
    }
    // sort the batch by latest and get batch id in response - Filter with last 1hour data. If there is any batch within last 1hour show that
    const currentDate = new Date();
    const latestBatchCreatedAt = new Date(setUserBatch[0].createdAt);
    const timeDiff = ((currentDate.getTime() - latestBatchCreatedAt.getTime()) / (1000 * 3600));
    if (timeDiff > 1) { // There is no batches created within last 1 hour
      return false;
    }

    // AppActions.ingestionGetBatchStatus(latestBatchId);
    Framework.getEventManager().publish(constants.INGESTION_GET_BATCH_STATUS_REQUESTED, { batchId: latestBatchId });
  }

  showBlueBar = () => {
    this.setState({
      showProgressbarBlue: AppStore.setshowUploadProgress.status, progressBarMessage: AppStore.setshowUploadProgress.message, showProgressbarGreen: false, showProgressbarYellow: false, showProgressbarRed: false
    });
  }

  callbackGetBatchStatus = (setIngestionBatchStatus) => {
    const { language, intl } = this.props;
    if (setIngestionBatchStatus) {
      const totalCount = setIngestionBatchStatus.itemStat.total || 0;
      const failedCount = setIngestionBatchStatus.itemStat.failed || 0;
      const completedCount = setIngestionBatchStatus.itemStat.completed || 0;
      if (setIngestionBatchStatus.status.toLowerCase() !== 'completed') {
        // Show blue bar
        let messageToBeDisplayed = intl.formatMessage(language.getText('edit_student_update_message'));
        try {
          if (localStorage.getItem('isStudentAdd') === 'true') {
            messageToBeDisplayed = intl.formatMessage(language.getText('add_student_created_message'));
          }
        } catch (e) { }
        this.setState({
          showProgressbarBlue: true, progressBarMessage: messageToBeDisplayed, showProgressbarGreen: false, showProgressbarYellow: false, showProgressbarRed: false
        });
        batchStatInterval = setTimeout(() => {
          Framework.getEventManager().publish(constants.INGESTION_GET_BATCH_STATUS_REQUESTED, { batchId: setIngestionBatchStatus.id });
        }, 5000);
      } else {
        clearTimeout(batchStatInterval);
        if (totalCount === completedCount) {
          this.setState({ batchStatus: 'completed' });
        } else if (totalCount === failedCount) {
          this.setState({ batchStatus: 'failed' });
        } else if (totalCount > completedCount) {
          this.setState({ batchStatus: 'partiallycompleted' });
        }
        Framework.getEventManager().publish(constants.INGESTION_GET_BATCH_ITEMS_REQUESTED, { batchId: setIngestionBatchStatus.id });
      }
    }
  }

  updateTable = function () {
    const { iesUserDetailsByCourse } = this.props;
    const course = DataFormatter.getObjectInStorage('courseSelected');
    const fromDateFormatted = Math.floor(new Date(course.startDate).getTime() / 1000);
    const toDateFormatted = Math.floor(new Date(course.endDate).getTime() / 1000);
    if (course.id.length > 0) {
      const courseId = course.id;
      const roleValue = constants.roleValue;
      iesUserDetailsByCourse.fetch(courseId, roleValue);
    }
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    Framework.getEventManager().publish(constants.GET_USER_PERMISSIONS_REQUESTED, { userid: userId });
  };

  callbackGetBatchItems = (setIngestionBatchItems) => {
    const { language, intl } = this.props;
    if (setIngestionBatchItems.length > 0) {
      let successCount = 0; let failedCount = 0; let message = ''; const batchUsers = []; const failedUsers = []; let failedUserMessage = ''; let pdfUrl = ''; let
        pdfLink = '';
      setIngestionBatchItems.map((batchItem) => {
        this.setState({ latestBatchId: batchItem.batchId });
        if (batchItem.type === 'user') {
          batchUsers[batchItem.uid] = Object.assign({}, batchUsers[batchItem.uid], { iesuser: batchItem.status, uid: batchItem.uid, username: `${batchItem.metadata.fName} ${batchItem.metadata.lName}` });
        } else if (batchItem.type === 'school-user') {
          batchUsers[batchItem.metadata.userUID] = Object.assign({}, batchUsers[batchItem.metadata.userUID], { schooluser: batchItem.status });
        } else if (batchItem.type === 'class-user') {
          batchUsers[batchItem.metadata.userUID] = Object.assign({}, batchUsers[batchItem.metadata.userUID], { classuser: batchItem.status });
        } else if (batchItem.type === 'pdf-generation' && batchItem.status === 'completed') {
          if (batchItem.progression.email && batchItem.progression.email.length > 0) {
            pdfUrl = batchItem.progression.email[0].pdf;
          } else if (batchItem.attempts[0].response && batchItem.attempts[0].response.length > 0) {
            pdfUrl = batchItem.attempts[0].response[0].pdf;
          }
          // PDF link being customized based on Add or Edit Screen
          pdfLink = `<a href="${pdfUrl}" target="_blank">${intl.formatMessage(language.getText('add_student_click_here'))}</a>`;
        }
      });
      for (const user in batchUsers) {
        if (batchUsers[user].iesuser == 'failed' || batchUsers[user].schooluser == 'failed' || batchUsers[user].classuser == 'failed') {
          // failedUserMessage += `<div>${batchUsers[user].username}</div>`;
          failedUsers.push(batchUsers[user].username);
          failedCount++;
        } else {
          successCount++;
        }
      }
      if (failedUsers.length > 0) {
        failedUserMessage = failedUsers.join(', ');
      }
      const totalCount = successCount + failedCount;
      if (this.state.batchStatus === 'completed') { // Show green bar
        // If in Student tab let us refresh the student list
        try {
          if (this.state.activeTab === 1) {
            this.updateTable();
          }
        } catch (e) { }

        // Display message being customized based on Add or Edit Screen
        let message_string; let
          message_link;
        message_string = intl.formatMessage(language.getText('edit_student_successful_p2'));
        message_link = ReactHtmlParser(this.replaceText(message_string, [pdfLink]));
        message = (
          <div>
            <div>
              {intl.formatMessage(language.getText('edit_student_successful_p1'))}
              <br />
              {message_link}
            </div>
          </div>
        );
        try {
          if (localStorage.getItem('isStudentAdd') === 'true') {
            message_string = intl.formatMessage(language.getText('add_student_successful_p2'));
            message_link = ReactHtmlParser(this.replaceText(message_string, [pdfLink]));
            message = (
              <div>
                <div>
                  {intl.formatMessage(language.getText('add_student_successful_p1'))}
                  <br />
                  {message_link}
                </div>
              </div>
            );
          }
        } catch (e) { }
        this.setState({
          showProgressbarGreen: true, showProgressbarBlue: false, showProgressbarYellow: false, showProgressbarRed: false, progressBarMessage: message
        });
      } else if (this.state.batchStatus === 'partiallycompleted') { // Show yellow bar
        message = (
          <div>
            <div>
              {intl.formatMessage(language.getText('upload_partial_successful'))}
              {' '}
              {successCount}
/
              {totalCount}
              {' '}
              {intl.formatMessage(language.getText('upload_partial_successful_email'))}
              <br />
              {' '}
              {pdfLink}
            </div>
          </div>
        );
        this.setState({
          showProgressbarYellow: true, showProgressbarGreen: false, showProgressbarBlue: false, showProgressbarRed: false, progressBarMessage: message
        });
      } else if (this.state.batchStatus === 'failed') { // Show red bar
        message = (<div>{intl.formatMessage(language.getText('upload_unsuccessful'))}</div>);
        this.setState({
          showProgressbarRed: true, showProgressbarGreen: false, showProgressbarBlue: false, showProgressbarYellow: false, progressBarMessage: message
        });
      }
      Framework.getEventManager().publish(constants.ADD_STUDENT_SUCCESS, {});
      // this.setState({ batchItemsFetched: true, batchItemMessage: message })
    }
  }

  replaceText = (licenceMsg, licenceValues) => {
    licenceValues.forEach((value, index) => {
      licenceMsg = licenceMsg.replace(new RegExp(`\\{${index}\\}`, 'g'), value);
    });
    return licenceMsg;
  }

  render() {
    const { language } = this.props;
    const tabBarContainerStyle = {};
    if (this.state.isSecondarySchoolLibTab || ((DataFormatter.getObjectInStorage('userMode') === 'secondary') && (this.state.activeTab === 0))) {
      tabBarContainerStyle.boxShadow = 'none';
    }

    return (
      <div className={`tabComponentWrapper ${DataFormatter.msieversion() ? ' ie' : ''}`}>
        <div className="tabBarContainer" style={tabBarContainerStyle}>
          {
            (this.props.tabsList.length > 0 ? (
              <div role="tabList" className="tabList">
                {this.renderTabs()}
                {/* <hr className="tabBarIndicator" style={tabBarIndicatorStyle}/> */}
              </div>
            ) : <span><FormattedMessage {...language.getText('set_config')} /></span>)
          }
        </div>
        {this.reportBanner()}
        <StudentImportProgressbar show={localStorage.getItem('usertype') == 'teacher' && this.state.showProgressbarBlue} message={this.state.progressBarMessage} bgColor="blue" disableClose handleClose={this.handleProgressbarCloseBlue} />
        <StudentImportProgressbar show={localStorage.getItem('usertype') == 'teacher' && this.state.showProgressbarGreen} message={this.state.progressBarMessage} bgColor="green" disableClose={false} handleClose={this.handleProgressbarCloseGreen} />
        <StudentImportProgressbar show={localStorage.getItem('usertype') == 'teacher' && this.state.showProgressbarYellow} message={this.state.progressBarMessage} bgColor="yellow" disableClose={false} handleClose={this.handleProgressbarCloseYellow} />
        <StudentImportProgressbar show={localStorage.getItem('usertype') == 'teacher' && this.state.showProgressbarRed} message={this.state.progressBarMessage} bgColor="red" disableClose={false} handleClose={this.handleProgressbarCloseRed} />
        <div className="plain_container">{this.renderTabsContent()}</div>
      </div>
    );
  }
}

// TabComponent.contextTypes= {
//   router: React.PropTypes.object.isRequired
// }
TabComponent.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language', 'iesUserDetailsByCourse'),
  observer
)(injectIntl(TabComponent));
