import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// import _ from 'lodash';
import Book from './Book';
// import styleSheet from '../theme.css';
const textBooktheme = createTheme({
  palette: {
  }
});

class Textbooks extends React.Component {
  constructor(props) {
    super(props);
    this.renderBookshelfItemList = this.renderBookshelfItemList.bind(this);
    this.renderBookshelfItem = this.renderBookshelfItem.bind(this);
    this.resize = this.resize.bind(this);
  }

    state = {
      textBookObj: this.props.textBookObj ? this.props.textBookObj : [],
      emptyTextbookText: this.props.emptyTextbookText ? this.props.emptyTextbookText : 'No Books To Show'
    }

    componentDidMount() {
      this.resize();
      window.addEventListener('resize', this.resize);
    }

    componentDidUpdate() {
      this.resize();
    }

    handleClick = (book, index, event) => {
      this.props.bookClickCallback(book, index);
    }

    bookAddCallback = (book, index, event) => {
      this.props.bookAddCallback(book, index);
    }

    bookCheckedCallback = (book, index, event) => {
      this.props.bookCheckedCallback(book, index);
    }

    favouriteOptionCallback = (book, index, event) => {
      this.props.favouriteOptionCallback(book, index);
    }

    returnBookCallback = (book, index) => {
      this.props.returnBookCallback(book, index);
    }

    sendBookCallback = (book, index) => {
      this.props.sendBookCallback(book, index);
    }

    moreBooksCallback = (book, index) => {
      this.props.moreBooksCallback(book, index);
    }

    buyBookCallback = (book, index) => {
      this.props.buyBookCallback(book, index);
    }

    teachingNotesClickCallback = (book, index) => {
      this.props.teachingNotesClickCallback(book, index);
    }

    resourcesClickCallback = (book, index) => {
      this.props.resourcesClickCallback(book, index);
    }

    lessonPlansClickCallback = (book, index) => {
      this.props.lessonPlansClickCallback(book, index);
    }

    activityClickCallback = (book) => {
      this.props.activityClickCallback(book);
    }

    flipBookCallback = (book, index, event) => {
      this.state.textBookObj[index] = book;
      this.setState({
        textBookObj: this.state.textBookObj
      });
      this.props.flipBookCallback(book, index);
    }

    handleCheckboxAction = (event, value) => {
      try {
        if (value) {
          var childElements = event.currentTarget.parentElement.parentElement.children;
          for (let i = 0; i < childElements.length; i++) {
            if (event.currentTarget.parentElement.parentElement.children[i].classList.contains('baacTriangleShape')) {
              event.currentTarget.parentElement.parentElement.children[i].style.backgroundColor = '#22bcb4';
            }
          }
          event.currentTarget.parentElement.parentElement.parentElement.style.border = '3px solid #22bcb4';
        } else {
          var childElements = event.currentTarget.parentElement.parentElement.children;
          for (let i = 0; i < childElements.length; i++) {
            if (event.currentTarget.parentElement.parentElement.children[i].classList.contains('baacTriangleShape')) {
              event.currentTarget.parentElement.parentElement.children[i].style.backgroundColor = '#fff';
            }
          }
          event.currentTarget.parentElement.parentElement.parentElement.style.border = 'none';
        }
      } catch (e) {}
    }

    resize = () => {
      let windowWidth; let booksCanAccomodate; let remainingWidth; let
        elements;
      if (this.props.relatedBook == true) {
        elements = document.querySelectorAll('.baacRelatedBook');
      } else {
        elements = document.querySelectorAll('.baacTextBookWrapComponent');
      }

      if (document.querySelectorAll('.baacRelatedBook').length > 0) {
        windowWidth = window.innerWidth - 350;
        booksCanAccomodate = windowWidth / 285;
        remainingWidth = windowWidth - (parseInt(booksCanAccomodate) * 285);
      } else {
        windowWidth = window.innerWidth;
        if (this.props.parentComponent === 'digital_lib' && this.props.resizeBaacWindow) {
          windowWidth -= 300;
        }
        booksCanAccomodate = windowWidth / 285;
        remainingWidth = windowWidth - (parseInt(booksCanAccomodate) * 285);
      }

      try {
        for (let i = 0; i < elements.length; i++) {
          if (this.props.relatedBook === true) {
            elements[i].style.marginLeft = '40px';
            elements[i].style.width = '285px';
          } else {
            elements[i].style.marginLeft = `${remainingWidth / 2}px`;
            elements[i].style.marginRight = `${remainingWidth / 2}px`;
            elements[i].style.width = `${windowWidth - remainingWidth}px`;
          }
        }
      } catch (e) {}
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      this.setState({
        textBookObj: newProps.textBookObj,
        emptyTextbookText: newProps.emptyTextbookText,
        enableBookSelect: newProps.enableBookSelect,
        enableFavourite: newProps.enableFavourite,
        enableAdd: newProps.enableAdd,
        enableFlip: newProps.enableFlip
      });
    }

    renderBookshelfItemList = function (textbook, index) {
      if (this.state.textBookObj && this.state.textBookObj.length > 0) {
        return this.state.textBookObj.map(this.renderBookshelfItem, this);
      }
      let noBookStyle = {};
      if (this.props.parentComponent === 'digital_lib' && this.props.resizeBaacWindow) {
        noBookStyle = { height: '500px' };
      }
      return (
        <div className="baacNoTextBooksAvailableWrap" tabIndex={0} style={noBookStyle}>
          <div tabIndex={0} className="baacEmptyTextbookImage" />
          <div tabIndex={0} className="baacEmptyTextbook">{this.state.emptyTextbookText}</div>
        </div>
      );
    }

    renderBookshelfItem = function (textbook, index) {
      return (
        <Book
          book={textbook}
          key={index}
          index={index}
          bookClickCallback={this.handleClick}
          bookAddCallback={this.bookAddCallback}
          bookCheckedCallback={this.bookCheckedCallback}
          favouriteOptionCallback={this.favouriteOptionCallback}
          flipBookCallback={this.flipBookCallback}
          returnBookCallback={this.returnBookCallback}
          activityClickCallback={this.activityClickCallback}
          sendBookCallback={this.sendBookCallback}
          moreBooksCallback={this.moreBooksCallback}
          buyBookCallback={this.buyBookCallback}
          teachingNotesClickCallback={this.teachingNotesClickCallback}
          resourcesClickCallback={this.resourcesClickCallback}
          lessonPlansClickCallback={this.lessonPlansClickCallback}
          handleCheckboxAction={this.handleCheckboxAction}
          favInactiveImageURL={this.props.favInactiveImageURL}
          favActiveImageURL={this.props.favActiveImageURL}
          addImageURL={this.props.addImageURL}
          flipImageURL={this.props.flipImageURL}
          flipImageURL1={this.props.flipImageURL1}
          enableBookSelect={this.props.enableBookSelect}
          enableFavourite={this.props.enableFavourite}
          enableAdd={this.props.enableAdd}
          enableFlip={this.props.enableFlip}
          theme={this.props.theme}
          enableReturnBook={this.props.enableReturnBook}
          enableMoreBooksOption={this.props.enableMoreBooksOption}
          enableAddBookOption={this.props.enableAddBookOption}
          newImageURL={this.props.newImageURL}
          readingLevelText={this.props.readingLevelText}
          activityText={this.props.activityText}
          teachingNotesText={this.props.teachingNotesText}
          resourcesText={this.props.resourcesText}
          lessonPlansText={this.props.lessonPlansText}
          openBookText={this.props.openBookText}
          sendBookText={this.props.sendBookText}
          physicalCopyText={this.props.physicalCopyText}
          addtoMyBookboxText={this.props.addtoMyBookboxText}
          returnBookText={this.props.returnBookText}
        />
      );
    }

    render() {
      return (
        <MuiThemeProvider theme={textBooktheme}>
          <div className={(this.props.relatedBook ? 'baacRelatedBook' : 'baacTextBookWrapComponent')}>
            {this.renderBookshelfItemList()}
          </div>
        </MuiThemeProvider>
      );
    }
}

export default Textbooks;
