/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Local environment settings.
 *
 * @author Hari Gangadharan
 */

const local = {
  ENVIRONMENT: 'local',
  // CONTENT_HOST: 'https://epspqa.stg-openclass.com',
  PXE_BASE_PATH: 'pearson-reader/api/item',
  MARIN_API_BASE_URL: 'https://marin-api.dev-prsn.com/api/1.0',
  HAPI_API_BASE_URL: `//digital-library-dev.pearson.com${process.env.NODE_ENV !== 'production' ? ':3000' : ''}`,

  // Google Tag Manager Configs - Local
  GTM_CONTAINER_ID: 'GTM-N8JS47K',
  GTM_AUTH: '34vutGcP7_nco7Xgx1OspQ',
  GTM_PREVIEW: 'env-3'
};

export default local;
