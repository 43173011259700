import React from 'react';

const StudentImportProgressbar = ({
  show, message, bgColor = 'blue', handleClose, disableClose = true
}) => {
  if (show === true) {
    let closeButton = (
      <div tabIndex="0" className="student-import-progressbar-close" onClick={handleClose} onKeyPress={(e) => { if (e.key == 'Enter') { handleClose(); } }}>
          X
      </div>
    );
    if (disableClose === true) {
      closeButton = (<div />);
    }
    return (
      <div className={`student-import-progressbar-inner-${bgColor}`}>
        <div className="student-import-progressbar-message-block">
          {message}
        </div>
        {closeButton}
      </div>
    );
  }
  return false;
};
export default StudentImportProgressbar;
