import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TagManager from 'react-gtm-module';
import * as constants from '../../../common/constants';
import DataFormatter from '../../../common/utils/DataFormatter';
import guidePointerIcon from '../../../assets/images/guide-pointer.png';
import bookAddedIcon from '../../../assets/images/bookAdded.png';
import CommonUtils from '../../../common/utils/CommonUtils';

class AddBookComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    bookAdded: true
  }

  componentDidMount = function () {
    // AppStore.on(AppConstants.EventTypes.PRODUCT_PROVISION_COMPLETED, this.productProvisioned);
    Framework.getEventManager().on(constants.SEND_BOOK_SUCCESS, (respData) => {
      this.productProvisioned();
    });
  }

  productProvisioned = () => {
    this.setState({
      bookAdded: false
    }, function () {
      this.props.assignStatus();
      const self = this;
      setTimeout(self.props.handleClose, 3000);
    });
  }

  scrollParent = () => {
    try {
      $('.mainAppContainer')[0].scrollTop = $('.addBookComponentContainer')[0].scrollTop;
    } catch (e) { }
  }

  addBook = () => {
    const { language, intl } = this.props;
    const assignmentType = 'self';
    const bookToProvision = [{
      productId: this.props.book.productId,
      userId: DataFormatter.getKeyFromObject('userInformation', 'id'),
      assignmentType,
      interactiveIncluded: true
    }];
    // AppActions.provisionBooks(bookToProvision);
    Framework.getEventManager().publish(constants.SEND_BOOK_REQUESTED,
      {
        payload: bookToProvision
      });
    const gtmData = {
      dataLayer: {
        event: 'add_book',
        event_category: intl.formatMessage(language.getText('add_book')),
        event_action: intl.formatMessage(language.getText('add_book_clicked')),
        event_label: null,
        assignment_type: bookToProvision[0].assignmentType,
        interactive_included: 'true',
        product_id: bookToProvision[0].productId,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
    // tab accessbility
    const baacTextBookThumnailImage = document.getElementsByClassName('baacTextBookThumnailImage')[0];
    baacTextBookThumnailImage.tabIndex = '0';
    // baacTextBookThumnailImage.focus();
    if (baacTextBookThumnailImage) {
      baacTextBookThumnailImage.focus();
      document.getElementsByClassName('baacTextBookThumnailImage')[0].nextSibling.focus();
    }
  }

  handleAddBookPopup = () => {
    const { language, intl } = this.props;
    if (this.state.bookAdded) {
      return (
        <div className="addBookComponentContainer" onScroll={this.scrollParent.bind(this)}>
          <div className="tabBarContainer addBook">
            <div className="tabList">
              <div className="tab" style={{ color: 'white' }}>{intl.formatMessage(language.getText('my_book_box'))}</div>
              <div className="tab" style={{ visibility: 'hidden' }}>{intl.formatMessage(language.getText('school_library'))}</div>
            </div>
          </div>
          <div className="arrowIndicator">
            <img
              alt={intl.formatMessage(language.getText('moving_to_bookbox'))}
              src={guidePointerIcon}
            />
          </div>
          <div className="modalBox" id="addBookModalPanel" tabIndex="0" role="dialog" aria-modal="true" onKeyDown={this.handleAccessibility.bind(this)}>
            <div className="addBookContent">
              <div className="addBookThumbnail">
                <img src={this.props.book.coverImageURL} />
              </div>
              <div className="addBookInfo">
                <div className="title">{this.props.book.bookName}</div>
                <div className="actionText">{intl.formatMessage(language.getText('add_to_mybookbox'))}</div>
              </div>
            </div>

            <div className="addBookActions">
              <Button
                variant="flat"
                id="btn_cancel_add_book"
                onKeyDown={this.handleAccessibility.bind(this)}
                onClick={() => {
                  this.props.handleClose('close');
                  const baacTextBookThumnailImage = document.getElementsByClassName('baacTextBookThumnailImage')[0];
                  baacTextBookThumnailImage.tabIndex = '0';
                  // baacTextBookThumnailImage.focus();
                  if (baacTextBookThumnailImage) {
                    baacTextBookThumnailImage.focus();
                    document.getElementsByClassName('baacTextBookThumnailImage')[0].nextSibling.focus();
                  }
                }}
                primary
              >
                {intl.formatMessage(language.getText('cancel_button_label'))}
              </Button>
              <Button variant="flat" id="btn_add_book" onKeyDown={this.handleAccessibility.bind(this)} onClick={this.addBook.bind(this)} primary>{intl.formatMessage(language.getText('okButtonLabel'))}</Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="addBookComponentContainer" onScroll={this.scrollParent.bind(this)}>
        <div className="bookAddedIcon">
          <img alt={intl.formatMessage(language.getText('book_added_moving_to_bookbox'))} src={bookAddedIcon} />
        </div>
        <div tabIndex="0" className="bookAddedText">
          <div aria-live="polite" aria-label={intl.formatMessage(language.getText('book_added_moving_to_bookbox'))}>{intl.formatMessage(language.getText('book_added'))}</div>
        </div>
      </div>
    );
  }

  handleAccessibility = function (event) {
    const isTabPressed = event.key === 'Escape' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
    if (!isTabPressed) {
      return;
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      this.props.handleClose('close');
      return false;
    }
    if (event.key === 'Tab' || event.shiftKey) {
      if (event.target.id == 'btn_add_book') {
        document.getElementById('btn_cancel_add_book').focus();
        event.preventDefault();
        return false;
      }
      if (event.target.id == 'btn_cancel_add_book') {
        document.getElementById('btn_add_book').focus();
        event.preventDefault();
        return false;
      }

      document.getElementById('btn_cancel_add_book').focus();
      event.preventDefault();
      return false;
    }
  }

  render = function () {
    return (
      <div>{this.handleAddBookPopup()}</div>
    );
  }
}

AddBookComponent.defaultProps = {
};

// export default AddBookComponent;
AddBookComponent.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language'),
  observer
)(injectIntl(AddBookComponent));
