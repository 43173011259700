import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const TeacherSnackbar = ({ show, message, handleClose }) => {
  if (show === true) {
    return (
      <Snackbar
        className="teacher-message-snackbar pad0-marg0"
        open={show}
        // onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <div className="teacher-message-snackbar-inner">
          <div className="teacher-snackbar-info">
            <i className="material-icons">info_outline</i>
          </div>
          <div className="teacher-snackbar-message-block">
            <div>{message}</div>
          </div>
          <div className="teacher-snackbar-close" onClick={handleClose}>
          X
          </div>
        </div>
      </Snackbar>
    );
  }
  return false;
};
export default TeacherSnackbar;
