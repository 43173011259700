/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class CreateTeacherSchoolClassService extends BaseService {
  constructor() {
    super(constants.CREATE_TEACHER_SCHOOL_CLASS_REQUESTED, 'CreateTeacherSchoolClassServiceStatus');
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    headerParams = {
      headers: {
        userid: event.payload.userId
      }
    };
    return this.getClient('hapi').post(
      '/create-teacher-school-class',
      event.payload, headerParams
    ); // courseboot/user/${event.userId}/referral`);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data && response.data.Status === 'success' && response.data.Enrollment === 'success') {
      Framework.getEventManager().publish(constants.CREATE_TEACHER_SCHOOL_CLASS_CREATED, response.data);
    } else {
      Framework.getEventManager().publish(constants.CREATE_TEACHER_SCHOOL_CLASS_FAIL, {
        message: 'Unable to Create School and Class'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.CREATE_TEACHER_SCHOOL_CLASS_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
