/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * QA environment overrides.
 *
 * @author Hari Gangadharan
 */

const qa = {
  ENVIRONMENT: 'qa',
  PARTNER_ENV: 'qa',
  CSG_API_BASE_URL: 'https://content-service-qa.stg-prsn.com/csg/api/v3',
  MARIN_API_BASE_URL: 'https://plus-stg.pearson.com/marin/api/1.0',
  PRISM_API_BASE_URL: 'https://prism-qa.pearsoned.com/api',
  PAPER_API_BASE_URL: 'https://paperapi-qa.stg-openclass.com/nextext-api/api/platforms/bookshelf',
  STPAPER_API_BASE_URL: 'https://stpaperapiqa.stg-prsn.com/etext/v2',
  SPECTRUM_API_BASE_URL: 'https://spectrum-qa.pearsoned.com/api/v1',
  SPECTRUM_API_V4_BASE_URL: 'https://spectrum-stg.pearsoned.com/api/v4', // TODO: Need to check with Spectrum team for notescopy support
  IDC_API_BASE_URL: 'https://etext-instructor-qa.pearson.com/idc-api/',
  ETEXT_API_BASE_URL: 'https://etext-qa-stg.pearson.com/api/nextext-api/v1/api',
  HAPI_API_BASE_URL: `//digital-library-qa.pearson.com${process.env.NODE_ENV !== 'production' ? ':3000' : ''}`,
  PXE_X_CALLER: '3cc7b6e8-3416-11e8-b467-0ed5f89f718b',
  CITE_X_CALLER: 'b2cee8b6-d75e-11e8-9f8b-f2801f1b9fd1',
  LD_CLIENT_SIDE_ID: '5eda7256a9d7760a721a39b4',
  LD_ANONYMOUS_USER_ID: 'ise_anonymous_qa_user',
  LD_ANONYMOUS_HASH: '0e36d47a6e87fff7d7bfded6d7e5488de59a7249f806e5a6c3c7bf78f06580d1',

  // Google Tag Manager QA Configs
  GTM_CONTAINER_ID: 'GTM-N8JS47K',
  GTM_AUTH: 'lDDnpM0TyvIVygT7iM0G2w',
  GTM_PREVIEW: 'env-4'
};

export default qa;
