/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class UpdateTimeSpentOnEachBookService extends BaseService {
  constructor() {
    super(constants.UPDATE_TIME_SPENT_ON_BOOK_REQUESTED, 'UpdateTimeSpentOnEachBookServiceStatus');
  }

  handleEvent(eventName, event) {
    const deviceId = CommonUtils.getdeviceId();
    const headerParams = {
      headers: {
        deviceid: deviceId,
        userid: event.userId,
        productid: event.productId
      }
    };
    // let totalTime = {
    //   "totalTimeSpentInBook": event.totalTimeSpentInBook
    // }
    const timeSpentObj = { totalTimeSpentInBook: event.totalTimeSpentInBook };
    return this.getClient('hapi').put('/put-time-spent', timeSpentObj, headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data) {
      let timeSpentOnBook;
      if (response.statusCode === 404) {
        timeSpentOnBook = 'failed';
      } else {
        timeSpentOnBook = 'success';
      }
      Framework.getEventManager().publish(constants.UPDATE_TIME_SPENT_ON_BOOK_SUCCESS, timeSpentOnBook);
    } else {
      Framework.getEventManager().publish(constants.UPDATE_TIME_SPENT_ON_BOOK_ERROR, {
        message: 'Unable to Update time spent on book details'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.UPDATE_TIME_SPENT_ON_BOOK_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
