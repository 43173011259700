import React, { Component } from 'react';
import Framework from '@greenville/framework';
import { ACCESS_CODE_BASE_URL } from '../../../common/constants';
import HmacService from '../../../common/utils/dlCryptoHandler';
import arrowLeftActive from '../../../assets/images/young_learners/arrow-left-active.png';

class AccessCode extends Component {
  constructor(props) {
    super(props);
  }

  redirectComponent = () => {
    const setIesUserSelectedFromRosterData = JSON.parse(localStorage.getItem('setIesUserSelectedFromRosterData'));
    switch (setIesUserSelectedFromRosterData.passMethod) {
      case 'image':
        Framework.redirectTo('/student/selectpassword/');
        break;
      case 'text':
        Framework.redirectTo('/student/password/');
        break;
      default:
        break;
    }
  }

  handleMessage = (e) => {
    if (JSON.parse(e.data).status) {
      this.redirectComponent();
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessage, false);
  }

  handleBackArrowOnClick = () => {
    Framework.redirectTo('/student/selectuser/');
  };

  render() {
    const domainSpecificClass = localStorage.getItem('isDomainSpark') !== 'true'
      ? 'select-password-left-arrow-active select-password-left-arrow-active-global'
      : 'select-password-left-arrow-active';
    return (
      <div className="access-code">
        <div className="welcome-container">
          <div className="back-arrow-position-password col-md-12  col-lg-2 col-xl-2">
            <div className="row">
              <div className="col student-id-icon">
                <div
                  tabIndex="0"
                  className="back-arrow-btn-outer"
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      this.handleBackArrowOnClick();
                    }
                  }}
                >
                  <img
                    src={arrowLeftActive}
                    alt="arrow Left Active"
                    className={domainSpecificClass}
                    onClick={() => { this.handleBackArrowOnClick(); }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <iframe
          id="container-accesscode"
          name="container-accesscode"
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          referrerPolicy="no-referrer"
          src={
            `${ACCESS_CODE_BASE_URL
            }?uid=${
              encodeURIComponent(
                HmacService.encryptJS(localStorage.getItem('userId'))
              )}`
          }
        />
      </div>
    );
  }
}

export default AccessCode;
