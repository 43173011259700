/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class FetchSearchUserProductsService extends BaseService {
  constructor() {
    super(constants.FETCH_USER_PRODUCTS_REQUESTED, 'FetchSearchUserProductsService');
  }

  handleEvent(eventName, event) {
    const deviceId = CommonUtils.getdeviceId();
    let filterBy = '';
    if (event.filterByArg && event.filterByArg != 'all') {
      filterBy = event.filterByArg;
    }
    const date = event.date ? event.date : '';
    const headerParams = {
      headers: {
        userid: event.userId,
        deviceid: deviceId,
        keyword: event.searchKeyword,
        filterby: filterBy,
        searchlimit: encodeURIComponent(isNaN(event.limit) ? '' : event.limit),
        offset: encodeURIComponent(isNaN(event.offset) ? '' : event.offset),
        sort: '-assignmentType',
        sortbydate: date
      }
    };
    return this.getClient('hapi').get('/fetch-product-by-user-id', headerParams);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data) {
      // Framework.getStoreRegistry().getStore('dlBooks').set({items:response.data});
      Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_SUCCESS, response.data);
    } else {
      Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_ERROR, {
        message: 'Cannot Find Books'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.FETCH_USER_PRODUCTS_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
