import env from '../env';

const ru = {
  'bookshelf.TITLE': 'Моя книжная полка',
  'common.LOGOUT': 'Выход из системы',
  'common.GO_HOME': 'На главную страницу',
  'common.PURCHASE': 'Покупка',
  'courses.WELCOME': 'Здравствуйте, {userName}! Добро пожаловать на курс {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Показать задания',
  'courses.toc.PUBLISH_SUCCESS': 'Успешно!',
  'courses.toc.PUBLISH_MESSAGE': 'Студенты увидят изменения в результате следующего открытия ими содержимого.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Подписка не найдена',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Ваша учетная запись не предполагает доступа к этому заголовку. Если вы полагаете, 
    что получили это сообщение по ошибке, обратитесь в Службу технической поддержки Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Подписка не найдена',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Ваша подписка не предусматривает доступа к eText; тем не менее, 
    вы можете оформить подписку, используя кредитную карту.`,
  'foxit.ABOUT': 'О продукте',
  'foxit.COPY_RIGHT': 'PDF Reader на платформе Foxit. Copyright (C) 2003-2019 гг., Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': 'Обратиться в Службы поддержки',
  'menu.ABOUT': 'О продукте',
  'menu.ACCESSIBILITY': 'Доступ',
  'menu.FOXIT': 'На платформе Foxit',
  'menu.TERMS_OF_USE': 'Условия использования',
  'menu.PRIVACY_POLICY': 'Политика конфиденциальности',
  'menu.SIGN_OUT': 'Выйти',
  'menu.PRINT_PAGE': 'Печать страницы',
  'menu.GET_LOOSE_LEAF': 'Получить отдельный лист',
  'menu.AVAILABLE': 'Также доступно',
  'menu.PRINT_VERSION': 'Нужна печатная версия?',
  'menu.COPY_RIGHT': 'Copyright © {year} Pearson Education Inc. Все права защищены.',
  'menu.tooltip.SCHEDULE': 'График',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Здравствуйте {userName}',
  'device.SWAPPED_ALREADY': 'Вы уже заменили этот месяц',
  'device.CONTACT_SUPPORT': 'Дождитесь конца месяца или обратитесь за помощью в службу поддержки.',
  'device.SWITCH_DEVICE': 'Переключиться на текущее устройство?',
  'device.SWITCH_ONCE': 'Поскольку переключение возможно только один раз в месяц, вы не сможете отменить действие.',
  'device.SEAT_TAKEN': 'Это место занято',
  'device.DISCONNECT_DEVICE': 'Регистрация возможна только на трех устройствах. Чтобы продолжить, отключите одно их устройств.',
  'device.ALERT_TEXT': 'Помните: вы можете менять устройства один раз в месяц.',
  'device.YOUR_MOJO': 'Вы в процессе перехода к Mojo'
};

export default ru;
