import env from '../env';

const nl = {
  'bookshelf.TITLE': 'Mijn boekenplank',
  'common.GO_HOME': 'Naar de startpagina',
  'common.LOGOUT': 'Uitloggen',
  'common.PURCHASE': 'Kopen',
  'courses.action.SHOW_ASSIGNMENTS': 'Opdrachten tonen',
  'courses.toc.PUBLISH_MESSAGE': 'Deelnemers zien uw wijzigingen de volgende keer als ze de inhoud bekijken.',
  'courses.toc.PUBLISH_SUCCESS': 'Veel succes!',
  'courses.WELCOME': 'Hallo {userName}, welkom bij uw {courseName} cursus!',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Uw account bevat geen toegang tot deze titel. Als u denkt dat u dit bericht niet had moeten ontvangen, neem dan contact op met Pearson Technical Support',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Abonnement niet gevonden',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Uw abonnement bevat geen toegang tot de eText, maar u kunt zich hiervoor wel aanmelden met een creditcard',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Abonnement niet gevonden',
  'foxit.ABOUT': 'Over',
  'foxit.COPY_RIGHT': 'PDF Reader ondersteund door Foxit. Auteursrecht (C) 2003-2019 van Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.ABOUT': 'Over',
  'menu.ACCESSIBILITY': 'Toegankelijkheid',
  'menu.AVAILABLE': 'Ook beschikbaar',
  'menu.CONTACT_SUPPORT': 'Contact opnemen met Ondersteuning',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education, Inc. Alle rechten voorbehouden.',
  'menu.FOXIT': 'Ondersteund door Foxit',
  'menu.GET_LOOSE_LEAF': 'Neem een los blad',
  'menu.PRINT_PAGE': 'Pagina afdrukken',
  'menu.PRINT_VERSION': 'Wilt u de afgedrukte versie?',
  'menu.PRIVACY_POLICY': 'Privacybeleid',
  'menu.SIGN_OUT': 'Uitloggen',
  'menu.TERMS_OF_USE': 'Gebruiksvoorwaarden',
  'menu.tooltip.SCHEDULE': 'Schema',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hallo {userName}',
  'device.SWAPPED_ALREADY': 'U heeft deze maand al geruild',
  'device.CONTACT_SUPPORT': 'Wacht tot volgende maand of neem contact op met de ondersteuning voor meer hulp.',
  'device.SWITCH_DEVICE': 'Overschakelen naar huidig apparaat?',
  'device.SWITCH_ONCE': 'Aangezien u maar één keer per maand kunt overstappen, kunt u dit niet ongedaan maken.',
  'device.SEAT_TAKEN': 'Die plek is bezet',
  'device.DISCONNECT_DEVICE': 'U kunt maar op 3 apparaten inloggen. Koppel een ander apparaat los om hier verder te gaan.',
  'device.ALERT_TEXT': 'Onthoud, u kunt slechts één keer per maand tussen apparaten switchen.',
  'device.YOUR_MOJO': 'U bent op weg naar mojo'
};

export default nl;
