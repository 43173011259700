import env from '../env';

const trTR = {
  continue: 'devam et',
  username: 'Kullanıcı Adı',
  password: 'Şifre girin',
  back: 'GERİ',
  sign_in: 'Oturum aç',
  invalid_user: 'Geçersiz bir kullanıcı adı girdiniz.',
  invalid_password: 'Geçersiz bir şifre girdiniz.',
  username_empty: 'Lütfen kullanıcı adınızı giriniz',
  password_empty: 'Lütfen şifrenizi giriniz',
  brand: 'Pearson',
  skip_to_main_content: 'Ana İçeriğe Geç',
  school_library: 'Kütüphane',
  students: 'Öğrenciler',
  my_bookbox: "Bookbox'ım",
  sign_out_label: 'OTURUMU KAPAT',
  course_selection_label: 'Dersler',
  keywordSearch: 'Bir kitabı başlığa, yazara veya anahtar kelimeye göre arayın',
  recentSearches: 'Son Aramalar',
  findBooks: 'Kitap Bul',
  filterButtonLabel: 'FİLTRELER',
  cancelButtonLabel: 'İPTAL ET',
  resetFilterButtonLabel: 'FİLTRELERİ SIFIRLA',
  resetButtonLabel: 'SIFIRLA',
  doneButtonLabel: 'Bul',
  refineByFilterHeader: 'Şuna göre iyileştir',
  chooseFilterHeader: 'Seç',
  NoOfbooksSuffix: 'kitaplar',
  roleValue: 'öğrenci',
  select_all_button_label: 'TÜMÜNÜ SEÇ',
  cancel_button_label: 'İPTAL ET',
  send_books_button_label: 'KİTAPLARI GÖNDER',
  student: 'öğrenci',
  provision_success: 'Kitaplar gönderildi.',
  provision_failed: 'Hata - Kitapların temini başarısız oldu',
  primary: 'birincil',
  secondary: 'ikincil',
  selfassigned: 'kendi',
  teacherassigned: 'öğretmen',
  interactiveActivity: 'İnteraktif Etkinlikler',
  selectStudents: 'Öğrencileri Seç',
  NoBookFound: 'Üzgünüm, hiç kitap bulunmadı',
  NoBookFoundSuffix1: 'Lütfen tekrar deneyin.',
  terms: 'Kullanım Şartları',
  Cookies: 'Çerezler',
  privacy: 'Gizlilik Politikası',
  copyright: 'Pearson Eğitim',
  copy_right: 'Telif Hakkı',
  copy_right_clicked: 'Telif hakkı tıklandı',
  help: 'Yardım',
  notificationMessagePrefix: 'Öğretmenin sana şunu gönderdi',
  noNotificationMessagePrefix: 'Hiç bildirim alınmadı.',
  Total: 'Toplam',
  AverageStats: 'Öğrenci başına okunan ortalama kitap sayısı',
  Overall: 'Genel',
  This_Month: 'BU AY',
  This_Year: 'BU YIL',
  Books_Read: 'OKUNMUŞ KİTAPLAR',
  BookBox: 'BOOKBOX',
  Book_Title: 'Kitap Başlığı',
  Date_Sent: 'Gönderildiği Tarih',
  Date_text: 'Tarih',
  Complete: 'Tamamla',
  Pages: 'Sayfalar',
  Read: 'Oku',
  Audio: 'Audio',
  Used: 'Kullanıldı',
  Favorite: 'Favori',
  Self_text: 'Kendi',
  Allocated: 'Seçildi',
  In_bookbox: "Bookbox'ta",
  Unread_books: 'Okunmamış Kitaplar',
  Books_Read_dashboard: 'Okunmuş Kitaplar',
  Number_of: 'Sayısı',
  Student_name: 'Öğrencinin adı',
  unread_in: 'ŞURADAKİ OKUNMAMIŞ',
  remove_books: 'KİTAPLARI KALDIR',
  notifications: 'Bildirimler',
  set_config: 'Uygulama yapılandırması ayarlanmadı',
  book_selected: 'seçilmiş kitap',
  books_selected: 'seçilmiş kitaplar',
  books_removed_pural: 'kitaplar bu öğrenciden kaldırıldı',
  book_removed_singular: 'bu öğrenciden 1 kitap kaldırıldı',
  manage_bookbox: "BOOKBOX'I YÖNET",
  view_all: 'HEPSİNİ GÖR',
  view_selected: 'SEÇİLENLERİ GÖRÜNTÜLE',
  login_clicked: 'Giriş tıklandı',
  logout_clicked: 'Çıkış tıklandı',
  terms_clicked: 'Şartlar tıklandı',
  cookie_clicked: 'Çerez tıklandı',
  policy_clicked: 'Gizlilik tıklandı',
  open_book: 'Kitabı aç',
  book_clicked: 'Kitap tıklandı',
  flip_book_teacher: 'Flip book-Öğretmen',
  book_turnback: 'Kitap (arka tarafını çevirin)',
  send_book: 'Kitabı Gönder',
  send_book_clicked: 'Kitabı gönder tıklandı',
  teaching_notes: 'Eğitim Notları',
  teaching_notes_clicked: 'Eğitim notları tıklandı',
  keyword_search: 'Anahtar kelime araması',
  search_executed: 'Arama yapıldı',
  filter_option: 'Filtre Seçeneği',
  filter_executed: 'Arama filtresi uygulandı',
  my_bookbox_tab: 'Bookbox sekmem',
  my_bookbox_tab_clicked: 'Bookbox sekmem tıklandı',
  favourite: 'Favori',
  favourite_clicked: 'Favori tıklandı',
  flip_book_student: 'Flip book-Öğrenci',
  return_book: 'Kitabı iade et',
  return_book_clicked: 'Kitabı iade et tıklandı',
  more_book: 'Daha Fazla Kitap',
  more_book_clicked: 'Daha fazla kitap tıklandı',
  add_book: 'Kitap ekle',
  add_book_clicked: 'Kitap ekle tıklandı',
  adding_student: 'Öğrenci Ekle',
  add_student_clicked: 'Addstudent tıklandı',
  deleting_student: 'Sil öğrenci',
  delete_student_clicked: 'Deletestudent tıklandı',
  editing_student: 'Öğrenciyi Düzenle',
  edit_student_clicked: 'Editstudent tıklandı',
  editing_classname: 'Sınıf Adı’nı düzenle',
  edit_classname_clicked: 'Editclassname tıklandı',
  adding_notes: 'Not ekle',
  add_notes_clicked: 'Addnotes tıklandı',
  notification: 'Bildirim',
  notification_clicked: 'Bildirim tıklandı',
  school_library_tab: 'Kütüphane Sekmesi',
  school_library_tab_clicked: 'Kütüphane sekmesi tıklandı',
  search_cancel: 'Arama iptal',
  search_cancel_executed: 'Arama iptal uygulandı',
  filter_cancel: 'Filtre İptal',
  filter_cancel_executed: 'Filtre İptal uygulandı',
  student_tab: 'Öğrenci Sekmesi',
  student_tab_clicked: 'Öğrenci sekmesi tıklandı',
  student_list: 'Öğrenci Listesi',
  student_name_clicked: 'Öğrenci Adı tıklandı',
  remove_book: 'Kitabı Kaldır',
  remove_book_clicked: 'Kitabı kaldır tıklandı',
  selection_reached_header: 'Seçim sınırına ulaşıldı',
  selection_reached_message: "Bu sonuç kümesi 100'den fazla kitap içeriyor. Sizin için ilk 100'ü seçtik.",
  Guided: 'Kılavuzlu',
  Reading_Level: 'Okuma Seviyesi',
  books_i_choose: 'Seçtiğim Kitaplar',
  from_my_teacher: 'Öğretmenimden',
  my_book_box: "Bookbox'ım",
  SessionExpired: 'Oturumunuz sona erdi. Devam etmek için lütfen tekrar giriş yapın.',
  network_not_available: 'Ağ kullanılamıyor.',
  null_check_username_password: 'Lütfen kullanıcı adınızı ve şifrenizi girin',
  check_network: 'Lütfen ağı kontrol edin ve tekrar deneyin',
  NoSchoolsAssignedLoggedOut: 'Okul atanmamış.',
  invalid_server_response: 'Geçersiz sunucu yanıtı:',
  book_already_provisioned: "Kitap zaten Bookbox'ınızda.",
  Off_label: 'Kapalı',
  On_label: 'Açık',
  okButtonLabel: 'OK',
  take_a_look: 'BİR GÖZ AT',
  select_all: 'Tümünü Seç',
  of: 'nın',
  EmptyBookShelfHeader: 'İçerik Yok',
  EmptyBookShelfHint: 'Kitaplığınızı yenilemek için buraya tıklayın',
  Logout: 'Çıkış Yap',
  Save: 'Kaydet',
  Cancel: 'İptal Et',
  app_logo: 'R+',
  reader_plus: 'Reader+',
  search: 'Ara',
  search_bookshelf: 'Kitaplıkta ara',
  unknown: 'Bilinmeyen',
  version: 'Versiyon',
  close: 'Kapat',
  Delete: 'Sil',
  logout: 'Oturumu kapat',
  my_bookshelf: 'Kitaplığım',
  page: 'Sayfa',
  resources_title: 'Kaynaklar',
  ok: 'Ok',
  empty_bookshelf_message: 'Kitaplığınızı yenilemek için buraya tıklayın',
  bookmarks_toolbar_select_exit_btn: 'Tamamladım',
  bookmarks_toolbar_edit_bar_cancel: 'İPTAL ET',
  all_resource_filter_panel_search_hint_txt: 'Bir anahtar kelime veya kelime dizini yazın',
  download_manager_modal_action_button_label: 'Uygula',
  ReadBook: 'Kitap Oku',
  Bookshelf: 'Kitaplığım',
  add_to_mybookbox: "Bu kitap Bookbox'ıma eklensin mi?",
  book_added: 'Kitap eklendi!',
  book_returned: 'Kitap iade edildi!',
  return_to_school_library: 'Bu kitap Kütüphaneye iade edilsin mi?',
  items_selected: 'Öğeler Seçildi',
  welcome: 'Hoş geldiniz',
  filter_all: 'Hepsi',
  filter_returned: 'İade edildi',
  filter_new: 'Yeni',
  filter_reading: 'Okuyorum',
  filter_finished: 'Bitti',
  filter_liked: 'Beğendi',
  teaching_notes_baac: 'Eğitim Notları',
  teachers_notes: 'Öğretmenin Notları',
  resources_baac: 'Kaynaklar',
  lesson_plans_baac: 'Ders Planları',
  activity: 'Aktivite',
  physical_copy: 'Fiziksel Bir Kopya Satın Alın',
  addto_my_bookbox: "Bookbox'ıma Ekle",
  see_more: 'Daha fazla gör',
  show_less: 'Daha az göster',
  book: 'kitap',
  filter_sections: 'Bölümler',
  filter_color_bands: 'Renk Bantları',
  'filter_lessthan-A1-GSE:15-22': '<A1 (GSE:15-22)',
  'filter_equals-A1-GSE:19-28': 'A1 (GSE:19-28)',
  'filter_A1-A2-GSE:25-36': 'A1-A2 (GSE:25-36)',
  'filter_A2-A2+GSE:33-41': 'A2-A2+ (GSE:33-41)',
  'filter_A2+-B1GSE:38-46': 'A2+-B1 (GSE:38-46)',
  'filter_greaterthanB1-GSE:Above 47': '>B1 (GSE: Above 47)',
  'filter_elt_GSE_16-27': 'GSE [16-27]/CEFR [<A1 - A1]',
  'filter_elt_GSE_22-32': 'GSE [22-32]/CEFR [A1 - A2]',
  'filter_elt_GSE_25-35': 'GSE [25-35]/CEFR [A1 - A2]',
  'filter_elt_GSE_27-38': 'GSE [27-38]/CEFR [A1 - A2+]',
  'filter_elt_GSE_30-40': 'GSE [30-40]/CEFR [A2 - A2+]',
  'filter_elt_GSE_36-48': 'GSE [36-48]/CEFR [A2+ - B1]',
  filter_pink: 'Pembe',
  filter_red: 'Kırmızı',
  filter_orange: 'Turuncu',
  filter_yellow: 'Sarı',
  filter_green: 'Yeşil',
  filter_blue: 'Mavi',
  filter_aqua: 'Aqua',
  filter_purple: 'Mor',
  filter_brown: 'Kahverengi',
  filter_white: 'Beyaz',
  filter_levels: 'Seviyeler',
  filter_level1: 'Seviye 1',
  filter_level2: 'Seviye 2',
  filter_level3: 'Seviye 3',
  filter_level4: 'Seviye 4',
  filter_level5: 'Seviye 5',
  filter_level6: 'Seviye 6',
  'filter_(levels A–B): Initial': '(A – B seviyeleri): Başlangıç',
  'filter_(levels C–D): Early Emergent': '(C – D seviyeleri): Erken Acil',
  'filter_(levels D–E): Emergent': '(D – E seviyeleri): Acil',
  'filter_(levels F–H): Early': '(F – H seviyeleri): Erken',
  'filter_(levels I–K): Developing': '(I–K seviyeleri): Gelişme',
  'filter_(levels L–N): Transitional': '(L–N seviyeleri): Geçişli',
  'filter_(levels O–Q): Fluent': '(O – Q seviyeleri): Akıcı',
  'filter_(levels R–U): Independent': '(R – U seviyeleri): Bağımsız',
  'filter_(levels V–Z): Proficient': '(V – Z seviyeleri): Yeterli',
  filter_guided_reading: 'Kılavuzlu Okuma',
  filter_a: 'A',
  filter_b: 'B',
  filter_c: 'C',
  filter_d: 'D',
  filter_e: 'E',
  filter_f: 'F',
  filter_g: 'G',
  filter_h: 'H',
  filter_i: 'I',
  filter_j: 'J',
  filter_k: 'K',
  filter_l: 'L',
  filter_m: 'M',
  filter_n: 'N',
  filter_O: 'O',
  filter_p: 'P',
  filter_q: 'Q',
  filter_r: 'R',
  filter_s: 'S',
  filter_t: 'T',
  filter_u: 'U',
  filter_v: 'V',
  filter_w: 'W',
  filter_x: 'X',
  filter_y: 'Y',
  filter_z: 'Z',
  filter_lexile: 'Lexile®',
  'filter_100-150': '100-150',
  'filter_150-200': '150-200',
  'filter_200-250': '200-250',
  'filter_250-300': '250-300',
  'filter_300-350': '300-350',
  'filter_350-400': '350-400',
  'filter_400-450': '400-450',
  'filter_450-500': '450-500',
  'filter_500-550': '500-550',
  'filter_550-600': '550-600',
  'filter_600+': '600+',
  filter_dra: 'DRA',
  filter_a12: 'A, 1, 2',
  filter_3: '3',
  filter_4: '4',
  'filter_6-8': '6-8',
  filter_10: '10',
  filter_12: '12',
  filter_14: '14',
  filter_16: '16',
  filter_18: '18',
  filter_20: '20',
  filter_24: '24',
  filter_28: '28',
  filter_30: '30',
  filter_34: '34',
  filter_38: '38',
  filter_40: '40',
  filter_50: '50',
  filter_60: '60',
  'filter_70-80': '70-80',
  'filter_topic of Interest/Inquiry': 'İlgi / Sorgu Konusu',
  filter_topics: 'Konular',
  filter_adventure: 'Macera',
  filter_animals: 'Hayvanlar',
  'filter_animals/Insects': 'Hayvanlar / Böcekler',
  'filter_animals/Bugs': 'Hayvanlar / Böcekler',
  'filter_buildings-and-places': 'Buildings and Places',
  'filter_celebrations-and-festivals': 'Celebrations and Festivals',
  filter_emotions: 'Emotions',
  'filter_families-and-friends': 'Families and Friends',
  filter_bullying: 'Zorbalık',
  filter_courage: 'Cesaret',
  filter_environment: 'Çevre',
  filter_families: 'Aileler',
  filter_food: 'Yiyecek',
  filter_friendship: 'Arkadaşlık',
  filter_culture: 'Kültür',
  filter_indigenous_peoples: 'Yerli Halk',
  filter_media: 'Medya',
  filter_numbers: 'Sayılar',
  filter_space: 'Uzay',
  filter_sports: 'Spor',
  filter_technology: 'Teknoloji',
  filter_the_human_body: 'İnsan Vücudu',
  filter_transportation: 'Ulaşım',
  filter_communities: 'Toplumlar',
  filter_plants: 'Bitkiler',
  filter_subject_area: 'Konu Alanı',
  'filter_design-and-technology': 'Design and Technology',
  filter_science: 'Bilim',
  'filter_health_and_well-being': 'Sağlık ve Sıhhat',
  'filter_health-and-wellbeing': 'Sağlık ve Sıhhat',
  filter_values: 'Values',
  filter_health: 'Health',
  'filter_human-body': 'Human Body',
  filter_jobs: 'Jobs',
  filter_magic: 'Magic',
  filter_music: 'Music',
  filter_nature: 'Nature',
  'filter_literature-and-language': 'Literature and Language',
  'filter_performance-art': 'Performance art',
  filter_transport: 'Transport',
  filter_travel: 'Travel',
  'filter_the-past': 'The Past',
  filter_weather: 'Weather',
  filter_compassion: 'Compassion',
  filter_confidence: 'Confidence',
  filter_creativity: 'Creativity',
  filter_curiosity: 'Curiosity',
  filter_determination: 'Determination',
  filter_empathy: 'Empathy',
  filter_equality: 'Equality',
  filter_fairness: 'Fairness',
  filter_friendship: 'Friendship',
  filter_generosity: 'Generosity',
  filter_kindness: 'Kindness',
  'filter_hard-working': 'Hard working',
  'filter_helping-others': 'Helping others',
  filter_honesty: 'Honesty',
  filter_humility: 'Humility',
  filter_independance: 'Independance',
  filter_integrity: 'Integrity',
  filter_justice: 'Justice',
  filter_loyalty: 'Loyalty',
  'filter_open-mindedness': 'Open-mindedness',
  filter_patience: 'Patience',
  filter_perseverance: 'Perseverance',
  filter_positivity: 'Positivity',
  'filter_problem-solving': 'Problem solving',
  filter_resilience: 'Resilience',
  filter_respect: 'Respect',
  filter_responsibility: 'Responsibility',
  'filter_self-belief': 'Self-belief',
  'filter_self-control': 'Self-control',
  filter_sincerity: 'Sincerity',
  filter_teamwork: 'Teamwork',
  filter_tolerance: 'Tolerance',
  filter_books: 'Books',
  filter_mathematics: 'Matematik',
  filter_social_studies: 'Sosyal Bilimler',
  filter_the_arts: 'Sanat',
  filter_art: 'Art',
  'filter_art-and-design': 'Art and Design',
  'filter_genre/Text_type': 'Tür/Metin Türü',
  filter_genre: 'Tür',
  filter_classic: 'Classic',
  filter_disney: 'Disney',
  'filter_fairy-tale': 'Fairy Tale',
  'filter_non-fiction': 'Kurgu Dışı',
  filter_Fiction: 'Kurgu',
  filter_biography: 'Biyografi',
  filter_description: 'Tanım',
  filter_explanation: 'Açıklama',
  filter_procedure: 'Prosedür',
  filter_report: 'Rapor',
  filter_retell: 'Tekrar',
  filter_graphic_text: 'Grafik Metin',
  filter_traditional_story: 'Geleneksel Hikaye',
  'filter_fantasy/sci-fi': 'Fantastik / Bilim Kurgu',
  filter_comprehension_strategies: 'Anlama Stratejileri',
  filter_comprehension_fluency: 'Comprehension/Fluency',
  filter_activating_prior_knowledge: 'Ön Bilgiyi Etkinleştirme',
  filter_analyzing_and_evaluating: 'Analiz ve Değerlendirme',
  filter_inferring: 'Sonuç Çıkarma',
  filter_predicting: 'Öngörme',
  filter_summarizing: 'Özetleme',
  'filter_listen-to-myself-reading': 'Listen to Myself Reading',
  'filter_reading-skills': 'Reading Skills',
  filter_vocabulary: 'Vocabulary',
  'filter_extracting-information': 'Extracting Information',
  'filter_understanding-the-story': 'Understanding the Story',
  filter_grammar: 'Grammar',
  filter_using_text_features: 'Metin Özelliklerini Kullanma',
  'filter_language-of-books': 'Language of Books',
  'filter_english-only': 'English Only',
  'filter_french-only': 'French Only',
  filter_phonics: 'Decodable Books',
  filter_all_decodable_books: 'All Decodable Books',
  filter_set00_wordless_books: 'Set 00: Wordless Books',
  filter_set1_2_satpinmd: 'Set 1-2: s a t p i n m d',
  filter_set3_gock: 'Set 3: g o c k',
  filter_set4_ckeur: 'Set 4: ck e u r',
  filter_set5_hbffflllss: 'Set 5: h b f ff l ll ss',
  filter_set6_jvwx: 'Set 6: j v w x',
  filter_set7_yzzzqu: 'Set 7: y z zz qu',
  filter_set8_chshthng: 'Set 8: ch sh th ng',
  filter_set9_aieeighoaoo: 'Set 9: ai ee igh oa oo',
  filter_set10_arorurowoi: 'Set 10: ar or ur ow oi',
  filter_set11_earairureer: 'Set 11: ear air ure er',
  filter_set12_Consolidation: 'Set 12: Consolidation Sets 1-11',
  filter_set13_whph: 'Set 13: wh ph',
  filter_set14_ayaeeigh: 'Set 14: /ai/ ay a-e eigh ey ei',
  filter_set15_eaeeie: 'Set 15: /ee/ ea e-e ie ey y',
  filter_set16_ieieyI: 'Set 16: /igh/ ie i-e y i eigh',
  filter_set17_owoeo: 'Set 17: /oa/ ow o-e o oe',
  filter_set18_ewueue: 'Set 18: /oo/ ew ue u-e u; /(y)oo/ ew ue u-e; /oo/ u oul',
  filter_set19_awaual: 'Set 19: /ŏ/ aw au al',
  filter_set20_irerear: 'Set 20: /ur/ ir er ear',
  filter_set21_ouoy: 'Set 21: /ow/ ou; /oi/ oy',
  filter_set22_ereeerareear: 'Set 22: /ear/ ere eer; /air/ are ear',
  filter_set23_ckckch: 'Set 23: k ck ch',
  filter_set24_cecicyscstlse: 'Set 24: /s/ ce c sc st se',
  filter_set25_gegigydge: 'Set 25: /j/ g(e) g(i) g(y) dge',
  filter_set26_lembkngnwr: 'Set 26: /l/ le; /m/ mb; /r/ wr; /n/ kn gn',
  filter_set27_tchsheazh: 'Set 27: tch t(ion) ss(ion) c(ial) c(ian); /e/ ea; /o/ (w)a; /u/ o',
  definition_of_text_types: 'Metin türlerinin tanımı',
  reading_level_correlation_chart: 'Okuma Seviyesi Korelasyon Şeması',
  moving_to_bookbox: "Bu kitabı book box'ıma taşımak için",
  book_added_moving_to_bookbox: "Kitap eklendi, Book Box'ıma Taşınıyor",
  not_favourite: 'Favori Değil',
  navigate_to_homepage: 'Pearson ana sayfasına gitmek için tıklayın',
  student_dashboard: 'Öğrenci Analiz Kontrol Paneli',
  Reading_Level_Short: 'Okuma Seviyesi',
  Level_Short: 'Seviye',
  signing_declaration: 'Giriş yaparak Şartlarımızı,',
  terms_privacy_cookies: 'gizliliği ve çerezlerimizi kabul etmiş olursunuz',
  terms_privacy_copy_cookies: 'Şartlar, gizlilik, telif hakkı ve çerezler',
  continue_declaration: 'Bu sitede gezinmeye devam ederek, şunları kabul etmiş olursunuz',
  accept_continue: 'KABUL ET VE DEVAM ET',
  and_use_of: 've',
  assessed_strategies: 'Değerlendirilen Stratejilerin (Etkileşimli Aktiviteler) kullanımı',
  assessed_skills: 'Değerlendirilen Becerilerin (Etkileşimli Aktiviteler) kullanımı',
  prior_knowledge: 'Ön Bilgiyi etkinleştirme',
  predicting: 'Öngörme',
  inferring: 'Sonuç Çıkarma',
  summarizing: 'Özetleme',
  analyzing: 'Analiz ve Değerlendirme',
  text_features: 'Metin özelliklerini kullanma',
  first_att: 'Doğru 1. deneme',
  second_att: 'Doğru 2. deneme',
  third_att: 'Doğru 3. deneme',
  fourth_att: 'Yanlış 3. deneme',
  num_activities: 'Etkinlik sayısı',
  completed: 'tamamlandı',
  no_activity: 'Bu öğrenci hiçbir etkinliği tamamlamadı',

  welcome_text: 'Hoş geldiniz',
  select_your_role: 'Rolünüzü seçin:',
  teacher_text: 'Öğretmen',
  student_text: 'Öğrenci',
  what_is_your_code: 'Kodunuz nedir?',
  your_code: 'KODUNUZ',
  your_code_will_be_provided: 'Kodunuz öğretmeniniz tarafından sağlanacak',
  error_msg_1: '<p>Tekrar deneyin<br/>veya<br/>öğretmeninize sorun.</p>',
  who_are_you: 'Sen kimsin?',
  your_account_locked: 'Hesabınız kilitlendi.',
  your_account_locked_1: '15 dakika sonra tekrar deneyin.',
  close_msg: 'Kapat',
  you_are: 'Sen:',
  what_is_your_password: 'Şifreniz Nedir?',
  done_msg: 'Tamamlandı',
  forgot_password: 'Şifreni mi Unuttun?',
  validation_error_msg: '<p>Kod sayılar, harfler içerebilir<br/> ve sadece 6 karakter olmalıdır. </p>',
  license_expiry_msg_ca: "<p>Hoş geldiniz!<br/>Deneme süreniz sona erdi. Yeniden etkinleştirmek için, satın almak üzere <a href={0} target='_blank'>buraya tıklayın</a>.</p>",
  license_expiry_msg_student_ca: "<p>Hoş geldiniz!<br/>Artık Spark Reading'e erişiminiz yok. Lütfen daha fazla bilgi için öğretmeninize danışın.</p>",
  license_purchase_msg_ca: "Hoş geldiniz!<br/>Deneme sürenizin {0} günü kaldı. Spark Reading Canada'nın keyfini çıkarmaya devam etmek için <a href={1} target='_blank'>buraya tıklayın</a> ve satın alın.",
  license_expiry_msg_tr: "<p>Hoş geldiniz!<br/>Deneme süreniz sona erdi. Yeniden etkinleştirmek için, satın almak üzere <a href={0} target='_blank'>buraya tıklayın</a>.</p>",
  license_expiry_msg_student_tr: "<p>Hoş geldiniz!<br/>Artık 'e erişiminiz yok. Lütfen daha fazla bilgi için öğretmeninize danışın.</p>",
  license_purchase_msg_tr: "Hoş geldiniz!<br/>Deneme sürenizin {0} günü kaldı. Devam etmek için <a href={1} target='_blank'>buraya tıklayın</a> ve satın alın.",
  teacher_license_expiry_msg_last_day: 'Your account expires today - please contact your administrator or Spark Reading Help {0} for assistance.',
  teacher_license_expiry_msg_ca: "Your account will expire in {0} day(s) - please contact your administrator or <a href={1} target='_blank'>Spark Reading Help</a> for assistance.",
  teacher_license_expiry_msg_tr: "Your account will expire in {0} day(s) - please contact your administrator or <a href={1} target='_blank'>Spark Reading Help</a> for assistance.",
  teacher_license_expiry_msg_gb: "Your account will expire in {0} day(s) - please contact your administrator or <a href={1} target='_blank'>Spark Reading Help</a> for assistance.",
  teacher_license_expired_msg_ca: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  teacher_license_expired_msg_tr: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  teacher_license_expired_msg_gb: "Your account is expired. Please contact <a href={0} target='_blank'>your sales rep</a> or your administrator to renew your license. For any other inquiries contact <a href={1} target='_blank'>Spark Reading Help</a>",
  class_text: 'Sınıf:',
  password_text: 'Şifre',
  show_password: 'Şifreyi Göster',
  ask_teacher: '<p>Öğretmeninizden yardım isteyin.</p>',
  ask_teacher_try_again: '<p>Bir şeyler ters gitti! Lütfen tekrar deneyin! <br/>Ya da Lütfen öğretmeninizden yardım isteyin.</p>',
  download_template: 'İçe Aktarma Şablonunu İndirin',
  upload_template: 'İçe Aktarma Şablonunu Yükleyin',
  no_students_msg1: 'Sınıfınızda hiç öğrenci yok.',
  no_students_msg2: "Bir öğrenci eklemek için ÖĞRENCİ EKLE'ye veya İçe Aktarma Şablonunu İndir'e tıklayın.",
  no_students_msg3: "Ardından, şablonu doldurun ve öğrencilerinizi içe aktarmak için İçe Aktarma Şablonunu Yükle'ye tıklayın.",
  add_new_student: 'Yeni bir öğrenci ekleyin',
  first_name: 'Adı',
  last_name: 'Soyadı',
  student_id: 'Öğrenci ID',
  password_type: 'Şifre türü',
  password_tooltip: 'Şifreler sadece sayılar, harfler içerebilir ve minimum 5 karakter olmalıdır.',
  select_password: 'Tür seçin',
  password_text_option: 'Metin',
  password_image_option: 'Resim',
  image_selected: 'Resim Seçildi',
  add_student: 'Bir öğrenci ekleyin',
  add_student_button: 'Tamamlandı / yazdır',
  max_limit_add: 'Maksimum limitinize ulaştınız ',
  remove_student: 'Kaldır',
  please_enter: 'Lütfen girin ',
  please_select: 'Lütfen seçin ',
  password_validation: 'Şifreler sadece sayılar, harfler içerebilir ve minimum 5 karakter olmalıdır.',
  password_text_validation: 'Şifre Metni',
  password_image_validation: 'Şifre Resmi',
  student_creation_started: 'Öğrenci oluşturma başladı',
  close_popup: 'Açılır pencereyi kapatabilir ve uygulama içinde özgürce gezinebilirsiniz.',
  class_list_ready: 'Sınıf listeniz hazır olur olmaz size haber vereceğiz.',
  student_creation_progress: 'Öğrenci oluşturma işlemi devam ediyor.',
  do_not_close: 'Lütfen pencereyi kapatmayın veya geri düğmesine basmayın',
  delete_student: 'Bu öğrenciyi kalıcı olarak silmek istediğinizden emin misiniz?',
  yes: 'EVET',
  no: 'HAYIR',
  upload_successful: 'Yükleme başarılı oldu. Sınıf listenizi içeren bir e-posta gönderildi.',
  upload_partial_successful: 'Yükleme kısmen başarılı oldu.',
  upload_partial_successful_email: 'kayıtlar yüklendi. Sınıf listenizi içeren bir e-posta gönderildi.',
  upload_unsuccessful: 'Yükleme başarısız oldu.',
  view_students: 'Öğrencileri görmek için tıklayın.',
  edit_students: 'Öğrenciyi Düzenle',
  edit_student_button: 'Kaydet/Yazdır',
  student_update_progress: 'Öğrenci güncellemesi devam ediyor.',
  student_update_started: 'Öğrenci güncellemesi başladı.',
  let_you_know: 'Öğrenci ayrıntıları güncellenir güncellenmez size haber vereceğiz.',
  students_updated: 'Öğrencileriniz güncelleniyor...',
  password_not_found: 'Şifre bulunamadı!',
  upload_failed_contents: 'Öğrenci Yükleme Başarısız!<br> Lütfen dosya içeriğini kontrol edin.',
  failed_to_import: 'Maksimum {1} öğrenci limitinizi aşacağınız için {0} yeni öğrenci alınamadı',
  csv_file_error: 'Csv dosyası okunamıyor',
  csv_format_error: 'Yüklenen dosya CSV formatında değil. <br/>Lütfen önce <br/>şablonu indirdiğinizden emin olun ve tekrar içe aktarmayı deneyin.',
  upload_failed: 'Öğrenci Yüklemesi Başarısız!<br> Lütfen bir süre sonra kontrol edin.',

  add_student_successful_p1: 'ÖNEMLİ! En son eklediğiniz öğrenci(ler) için hesap(lar) oluşturuldu.',
  add_student_successful_p2: ' ​​​​​​​​{0} öğrenci mektubunu (mektuplarını) içeren dosyayı indirmek için.',
  add_student_click_here: 'buraya tıklayın',
  add_student_created_message: 'Öğrenci hesabınız (hesaplarınız) oluşturuluyor. Bu birkaç dakika sürebilir.',
  class_code: 'Sınıf Kodu:',
  cookie_policy: 'Çerez Politikası',
  edit_student_successful_p1: 'ÖNEMLİ! Öğrenci(leri)niz için hesaplar güncellendi.',
  edit_student_successful_p2: ' {0} öğrenci mektubunu (mektuplarını) içeren dosyayı indirmek için.',
  edit_student_update_message: 'Öğrenci hesabınız (hesaplarınız) güncelleniyor. Bu birkaç dakika sürebilir.',
  select_library: 'Kitaplığınızı seçin',
  make_selection: 'Bir seçim yapın',
  create_account: 'Hesap oluşturun',
  select_library_error: 'İsteğiniz yerine getirilirken bir hata oluştu. lütfen destek ekibiyle iletişime geçin.',
  account_created: 'Hesap oluşturuldu',
  loading_data: 'Veri Yükleniyor...',
  import_students_help: 'Birden Fazla Öğrenci Ekle: Yardım',
  'welcome_!': 'Hoş geldiniz!',
  logged_in_count: 'Oturum Açma<br>Sayısı',
  last_login_time: 'En Son<br>Giriş Yapıldı',
  leave_as_is: 'Olduğu gibi bırak',
  clicked: ' tıklandı',
  student_letter: 'Öğrenci Mektubu',
  password_letter: 'Şifre Harfi',
  student_letters: 'Öğrenci Mektubu (Mektupları)',
  password_letter_unavailable: 'Öğrenci mektubu mevcut değil, mektubu oluşturmak için lütfen öğrenci bilgilerini düzenleyin/öğrenci şifresini sıfırlayın.',
  options: 'Seçeneği',
  loading_activity_graph: 'Etkinlik grafiği yükleniyor...',
  available_access: 'Mevcut Erişim',
  reset_password: 'Şifreyi sıfırla',
  country_not_supported_msg: 'Sorry, we do not yet support self registration in the country that you selected.',
  student_license_expiry_title: 'Sınıfınızın süresi dolmuş veya mevcut değil.',
  student_license_expiry_description: 'Lütfen Spark Reading Yardım ile iletişime geçin: <sparkreadinghelp@pearson.com>',
  teacher_license_expiry_title:"Your account has expired.",
  teacher_license_expiry_contact:"Please contact your <a href={0} target='_blank'>sales representative </a>or your administrator to renew your license.",
  teacher_license_expiry_description:"For any other inquiries contact Spark Reading Help &lt;sparkreadinghelp@pearson.com&gt;",
  // Reports
  notes: 'Notlar',
  time_spent: 'Harcanan süre',
  download_report: 'Raporu Farklı İndir',
  report_error_message: 'Something went wrong on our end. Please try again later.',
  csv: 'CSV',
  excel: 'Excel',
  pdf: 'PDF',
  report_generation_progress: 'Report generation is in progress.',
  activities_completed: 'Activities Completed',
  // Reportbanner message
  report_error_message: 'Something went wrong on our end when generating your',
  report_error_message_m1: 'file.Please try again later',
  report_inProgress_message: 'Generation of your report as a',
  report_inProgress_message_m1: 'file is in progress.',
  report_complete_message: 'Generation of your report as a',
  report_complete_message_m1: 'has been completed successfully.',
  Keep_reading: 'Keep Reading',
  'are_you_still_reading?': 'Are You Still Reading?',
  log_out_label: 'Log out now'
};

export default trTR;
