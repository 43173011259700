import React from 'react';
import { VegaReader } from '@vega/vega-reader';
import { injectIntl, intlShape } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import IdleTimer from 'react-idle-timer';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import {
  Link
} from '@material-ui/core';
import AuthHandler from './util/AuthHandler';
import SpdfAndEpubMapper from './util/SpdfAndEpubMapper';
import * as ReaderConstants from './constants';
import * as constants from '../../common/constants';
import DataFormatter from '../../common/utils/DataFormatter';
import CommonUtils from '../../common/utils/CommonUtils';
import ExpiryMessage from '../../common/components/ExpiryMessage';
class BookReader extends React.Component {
  static childContextTypes = {
    preferenceContext: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      bookData: {},
      bookDetails: {},
      pageId: '',
      drawerState: ReaderConstants.DRAWER_STATE_CLOSED,
      selectedPanel: '',
      pagesRead: [],
      totalPages: 0,
      bookPageData: [],
      pagesNavigated: [],
      lastPageVisited: 0,
      isAudioUsed: null,
      showIdleTimer: false,
      showPopUp: false,
      noBookSelected: false
    };
    this.currentTab = DataFormatter.getObjectInStorage('currentTab');
    this.configData = DataFormatter.getObjectInStorage('configData');
    this.authHandler = new AuthHandler();
    // TimeSpent On Each BOOK
    this.idleTimer = null;
    // this.handleOnAction = this.handleOnAction.bind(this);
    // this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.logout = this.logout.bind(this);
    this.convertMillisToSeconds = this.convertMillisToSeconds.bind(this);
  }

  getChildContext() {
    return {
      preferenceContext: {
        showColorMode: false,
        showPagelayout: DataFormatter.getAppConfigKey('showPageLayout'),
        showPageProgression: false,
        showAnnotationSwitch: false,
        showFontOptions: false,
        showTextTransform: false,
        showLineSpacing: false,
        showHotSpots: false
      }
    };
  }

  UNSAFE_componentWillMount = function () {
    const currentTab = DataFormatter.getObjectInStorage('currentTab');
    if (currentTab && currentTab === 'my-bookbox') {
      this.setState({ showIdleTimer: true });
    }

    // Fetching server time
    Framework.getEventManager().publish(
      constants.FETCH_SERVER_TIME_REQUESTED,
      {}
    );
  };

  componentDidMount() {
    const { language, intl } = this.props;
    const bookInfo = DataFormatter.getObjectInStorage('bookClicked');
    if(!bookInfo?.bookId) {
      this.setState({
        noBookSelected: true
      });
    }
    const param = {
      baseUrl: `${this.configData.BaseUrl}v1/books/${bookInfo?.bookId}`,
      bookId: bookInfo?.bookId,
      cloudFrontUrl: ReaderConstants.CLOUDFRONT_URL,
      hawkId: this.configData.readerHawkId,
      hawkKey: this.configData.readerHawkKey,
      algorithm: this.configData.readerHawkAlgorithm
    };
    // AppActions.getServerTimestamp((err, res) => {
    // });

    Framework.getEventManager().on(
      constants.FETCH_SERVER_TIME_SUCCESS,
      (timeData) => {
        param.timeStamp = parseInt(timeData);
        SpdfAndEpubMapper.getBookData(param).then((responseData) => {
          bookInfo.type = bookInfo.type === ReaderConstants.PDF
            ? ReaderConstants.SPDF
            : bookInfo.type;
          let _totalPages = responseData.pagePlaylist.length;
          if (responseData.pagePlaylist) {
            const pageIndexWithBodyMatter = [];
            const pagePlaylist = responseData.pagePlaylist.filter(
              page => page.pageType === 'bodymatter'
            );
            pagePlaylist.map((page) => {
              pageIndexWithBodyMatter.push(page.index);
            });
            _totalPages = pageIndexWithBodyMatter.length;
          }
          if (
            this.currentTab === ReaderConstants.MY_BOOKBOX
            && bookInfo?.bookInfo
            && bookInfo?.bookInfo?.lastVisitedPage
          ) {
            const pageIndex = parseInt(bookInfo?.bookInfo?.lastVisitedPage);
            this.setState({
              bookData: responseData,
              bookDetails: bookInfo,
              pageId:
                pageIndex < responseData.pagePlaylist.length
                  ? responseData.pagePlaylist[pageIndex].id
                  : responseData.pagePlaylist[pageIndex - 1].id,
              pagesRead: bookInfo?.bookInfo?.pagesRead,
              isAudioUsed: bookInfo?.bookInfo?.isAudioUsed,
              totalPages: _totalPages
            });
          } else {
            this.setState({
              bookData: responseData,
              bookDetails: bookInfo,
              totalPages: _totalPages
            });
          }
        });
      }
    );

    let gaEvent = '';
    if (DataFormatter.getObjectInStorage('userMode') === 'primary') {
      gaEvent = 'open_book_student';
    } else {
      gaEvent = 'open_book_teacher';
    }
    const searchState = DataFormatter.getObjectInSessionStorage('tempSearchState');
    const lang = this.getLanguage(DataFormatter.getLanguage());
    DataFormatter.setObjectInSessionStorage('searchState', searchState);
    DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    DataFormatter.setObjectInStorage('fromPlayerBackButtonClick', true);
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');

    const gtmData = {
      dataLayer: {
        event: gaEvent,
        event_category: intl.formatMessage(language.getText('open_book')),
        event_action: intl.formatMessage(language.getText('book_clicked')),
        event_label: null,
        book_title: bookInfo?.bookName,
        book_id: bookInfo?.bookId,
        user_id: userId,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);

    this.interval = setInterval(() => {
      if (this.currentTab === ReaderConstants.MY_BOOKBOX) {
        this.updateBookReadStatus();
      }
    }, 60000);

    // AppStore.on(AppConstants.default.EventTypes.FETCH_SCROM_URL_SUCCESS, this.onScormClickCallback);
    Framework.getEventManager().on(
      constants.FETCH_SCROM_URL_SUCCESS,
      (scormData) => {
        this.onScormClickCallback(scormData);
      }
    );

    window.onpopstate = () => {
      if (this.currentTab === ReaderConstants.MY_BOOKBOX) {
        this.updateBookReadStatus();
      }
    };
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    // AppStore.removeListener(AppConstants.default.EventTypes.FETCH_SCROM_URL_SUCCESS, this.onScormClickCallback);
    // Framework.getEventManager().unregisterAllCallbacks();
  }

  mediaOverlayCallback = () => {
    if (!this.state.isAudioUsed) {
      this.setState({ isAudioUsed: true });
    }
  };

  getBookInfo = () => {
    const { bookData, bookDetails } = this.state;
    if (bookDetails) {
      return {
        author: bookDetails.author,
        // isDoublePage: bookDetails.type === ReaderConstants.SPDF ? true : bookData.isFixedLayout,
        isDoublePage: DataFormatter.getAppConfigKey('isDoublePage') || false,
        isFixedLayout:
          bookDetails.type === ReaderConstants.SPDF
            ? true
            : bookData.isFixedLayout,
        layoutDimensions: bookData.layoutDimensions
          ? bookData.layoutDimensions
          : {},
        preferenceObject:
          bookDetails.type === ReaderConstants.SPDF
            ? {
              sliderVal: '0'
            }
            : bookData.isFixedLayout
              ? {
                sliderVal: '100'
              }
              : {},
        thumbnailUrl: bookDetails.coverImageURL.replace('http:', 'https:'),
        title: bookDetails.bookName
      };
    }
  };

  getLanguage = (lang) => {
    switch (lang) {
      case 'en-us':
      case 'en-US':
        return 'en';
      case 'fr-ca':
      case 'fr-CA':
        return 'fr';
      case 'it-it':
        return 'it';
      case 'tr':
      case 'TR':
        return 'tr';
      default:
        return 'en';
    }
  };

  handleNavigationChange = (pageId) => {
    const { bookData, bookDetails } = this.state;
    let _bookPageData = [...this.state.bookPageData];
    let _pagesRead = [...this.state.pagesRead];
    let _totalPages = this.state.totalPages;
    if (this.currentTab === ReaderConstants.MY_BOOKBOX) {
      const pageNumber = bookData.pagePlaylist.findIndex(
        page => page.id === pageId
      );
      if (bookData.pagePlaylist) {
        const pageIndexWidthBodyMatter = [];
        const pagePlaylist = bookData.pagePlaylist.filter(
          page => page.pageType === 'bodymatter'
        );
        pagePlaylist.map((page) => {
          pageIndexWidthBodyMatter.push(page.index);
        });
        _bookPageData = pageIndexWidthBodyMatter;
        if (pageIndexWidthBodyMatter && pageIndexWidthBodyMatter.length > 0) {
          _pagesRead = _pagesRead.filter(page => pageIndexWidthBodyMatter.includes(page));
          _totalPages = pageIndexWidthBodyMatter.length;
          if (
            !_pagesRead.includes(pageNumber)
            && _bookPageData.includes(pageNumber)
          ) {
            this.setNavigationState(
              pageId,
              _bookPageData,
              _pagesRead,
              _totalPages,
              pageNumber
            );
          } else {
            this.setState({ lastPageVisited: pageNumber });
          }
        } else {
          _totalPages = bookData.pagePlaylist.length;
          if (!_pagesRead.includes(pageNumber)) {
            this.setNavigationState(
              pageId,
              _bookPageData,
              _pagesRead,
              _totalPages,
              pageNumber
            );
          } else {
            this.setState({ lastPageVisited: pageNumber });
          }
        }
      } else {
        _totalPages = bookData.pagePlaylist.length;
        if (!_pagesRead.includes(pageNumber)) {
          this.setNavigationState(
            pageId,
            _bookPageData,
            _pagesRead,
            _totalPages,
            pageNumber
          );
        } else {
          this.setState({ lastPageVisited: pageNumber });
        }
      }
    }
    const iFrameLoaded = setInterval(() => {
      const courseSelected = DataFormatter.getObjectInStorage('courseSelected');
      const iFrame = document.getElementById('bookFrame');
      if (
        iFrame
        && courseSelected
        && courseSelected.roleValue === ReaderConstants.STUDENT
        && ((bookDetails.bookInfo && !bookDetails.bookInfo.interactiveIncluded)
          || this.currentTab !== ReaderConstants.MY_BOOKBOX)
      ) {
        clearInterval(iFrameLoaded);
        const activitiesElements = iFrame.contentWindow.document.querySelectorAll(
          'div[class*=prsn-rplus-interactive]'
        );
        for (const activityElement of activitiesElements) {
          activityElement.style.display = 'none';
        }
      }
    }, 100);
    this.setState({ pageId });
  };

  setNavigationState = (
    pageId,
    _bookPageData,
    _pagesRead,
    _totalPages,
    _lastPageVisited
  ) => {
    if (_lastPageVisited >= 0) {
      _pagesRead.push(_lastPageVisited);
      this.setState({
        pageId,
        bookPageData: _bookPageData,
        pagesRead: _pagesRead,
        totalPages: _totalPages,
        lastPageVisited: _lastPageVisited
      });
    }
  };

  updateBookReadStatus = () => {
    localStorage.removeItem('ReadingStatus');
    const {
      bookData,
      bookPageData,
      pagesRead,
      totalPages,
      lastPageVisited,
      isAudioUsed
    } = this.state;
    let _pagesRead = [];
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    const productId = DataFormatter.getKeyFromObject('bookClicked', 'productId');
    const pagesReadData = pagesRead.filter(
      (value, index, current) => current.indexOf(value) === index
    );
    if (bookPageData && bookPageData.length > 0) {
      const pagesNavigated = bookPageData.filter(element => pagesReadData.includes(element));
      if (pagesNavigated.length > 0) {
        _pagesRead = pagesNavigated.sort();
      }
    } else {
      _pagesRead = pagesReadData.sort();
    }
    const userStatusObj = {
      lastVisitedPage: lastPageVisited.toString(),
      pagesRead: _pagesRead,
      totalPages,
      isAudioUsed
    };
    if (
      bookData.tocData.mediaOverlays
      && Object.values(bookData.tocData.mediaOverlays).length > 0
      && !isAudioUsed
    ) {
      userStatusObj.isAudioUsed = false;
    }
    if (typeof userStatusObj.isAudioUsed !== 'boolean') {
      delete userStatusObj.isAudioUsed;
    }

    localStorage.setItem(
      'ReadingStatus',
      JSON.stringify({
        userId,
        productId,
        userStatusObj
      })
    );

    Framework.getEventManager().publish(
      constants.UPDATE_USER_PRODUCT_REQUESTED,
      {
        userId,
        productId,
        userStatusObj
      }
    );
  };

  onBackNavClick = () => {
    if (
      DataFormatter.getObjectInStorage('userMode') === 'primary'
      && this.state.showIdleTimer
    ) {
      const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
      const productId = DataFormatter.getKeyFromObject(
        'bookClicked',
        'productId'
      );
      const totalActiveTime = this.idleTimer.getTotalActiveTime();
      const totalTime = this.convertMillisToSeconds(totalActiveTime);
      // AppActions.putTimeSpentonEachBook(userId, productId, totalTime);
      Framework.getEventManager().publish(
        constants.UPDATE_TIME_SPENT_ON_BOOK_REQUESTED,
        {
          userId,
          productId,
          totalTimeSpentInBook: totalTime
        }
      );
    }

    DataFormatter.setObjectInStorage('fromPlayerBackButtonClick', true);
    if (this.currentTab === ReaderConstants.MY_BOOKBOX) {
      this.updateBookReadStatus();
    }
    // const urlprefix = window.location.origin;
    // window.location = urlprefix +'/login';
    DataFormatter.setObjectInStorage('BackButtonClick', true);
    Framework.redirectTo('/app');
  };

  onScormLinkClick = (data) => {
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    const scormObj = DataFormatter.checkScormLinks(data);
    Framework.getEventManager().publish(constants.FETCH_SCROM_URL_REQUESTED, {
      userId,
      externalRef: [scormObj]
    });
  };

  onScormClickCallback = (scormData) => {
    const data = scormData; // AppStore.scormData
    if (DataFormatter.getObjectInStorage('scormUrl') === data.scormUrl) {
      // console.log("Opening Scorm");
      return false;
    }
    DataFormatter.setObjectInStorage('scormUrl', data.scormUrl);
    const location = window.location.origin;
    window.open(`${location}/launchScorm?scormUrl=${data.scormUrl}`);
  };

  handleDrawerChange = (drawer) => {
    this.setState({ drawerState: drawer });
  };

  getDrawerView = () => {
    const _drawerView = {
      drawerState: this.state.drawerState,
      selectedPanel: this.state.selectedPanel,
      onDrawerStateChange: this.handleDrawerChange
    };
    return _drawerView;
  };

  convertMillisToSeconds = (millis) => {
    const seconds = millis / 1000;
    return seconds;
  };

  //   handleOnActive = (event) => {
  //     console.log("user is active", event);
  //     console.log("time remaining", this.idleTimer.resume());
  //   };
  //   handleOnAction = (event) => {
  //     //  this.setState({showPopUp: false});
  //     console.log("time remaining", this.idleTimer.resume());
  //   };
  handleCloseDialog = (event) => {
    this.idleTimer.resume();
    this.idleTimer.reset();
    this.setState({ showPopUp: false });
  };

  handleOnIdle = () => {
    this.setState({ showPopUp: true });
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    const productId = DataFormatter.getKeyFromObject('bookClicked', 'productId');
    const totalActiveTime = this.idleTimer.getTotalActiveTime() + 300000;
    const totalTime = this.convertMillisToSeconds(totalActiveTime);
    //  AppActions.putTimeSpentonEachBook(userId, productId, totalTime);
    Framework.getEventManager().publish(
      constants.UPDATE_TIME_SPENT_ON_BOOK_REQUESTED,
      {
        userId,
        productId,
        totalTimeSpentInBook: totalTime
      }
    );
    this.idleTimer.pause();
  };

  logout = () => {
    const piSession = window.piSession;
    if (piSession) {
      piSession.setOptions({
        requireLogin: false
      });
    }
    if (piSession) {
      const redirectURL = `${window.location.protocol}//${window.location.host}`;
      piSession.logout(redirectURL);
      // window.location = window.location.protocol+"//"+window.location.host;
    } else {
      Framework.logout();
      // AppActions.logoutUser();
    }
  };

  render() {
    const { bookData, bookDetails } = this.state;
    const { language } = this.props;
    const courseSelected = DataFormatter.getObjectInStorage('courseSelected');
    const vegaConfig = Object.keys(bookData).length
      ? {
        allowNotesCopy: false,
        allowTocEdit: false,
        applicationId: 'ise',
        showHeader: true,
        showDrawer: true,
        showPref: true,
        showSearch: false,
        showLastVisitedPage: false,
        showBookmark: DataFormatter.getAppConfigKey('bookmarks') || false,
        showHighlights: DataFormatter.getAppConfigKey('highlights') || false,
        showBackNav: true,
        locale: this.getLanguage(DataFormatter.getLanguage()),
        clientApp: ReaderConstants.CLIENT_APP,
        tenantId: this.configData.tenantId,
        tenantKey: this.configData.tenantKey,
        enableQuickView: false,
        mathmlOn: false,
        showAudio: false,
        showStudy: false,
        showFoc: true,
        showResource: false,
        skin:
            courseSelected
            && courseSelected.roleValue === ReaderConstants.STUDENT
              ? ReaderConstants.YL
              : '',
        isDoublePage: DataFormatter.getAppConfigKey('isDoublePage') || false,
        showPageLayout:
            DataFormatter.getAppConfigKey('showPageLayout') || false
      }
      : {};
    let vrProps = {};
    if (Object.keys(bookData).length > 0) {
      vrProps = {
        authHandler: this.authHandler.get(),
        bookId: bookDetails.productId,
        bookInfo: this.getBookInfo(),
        config: vegaConfig,
        contentType: bookDetails.type.toUpperCase(),
        env: this.configData.env,
        courseId: '',
        pageId: this.state.pageId,
        onBackNavClick: this.onBackNavClick,
        onNavigationChange: this.handleNavigationChange,
        onScormLinkClick: this.onScormLinkClick,
        mediaOverlayCallback: this.mediaOverlayCallback,
        tocData: bookData.tocData,
        pagePlaylist: bookData.pagePlaylist,
        drawerView: this.getDrawerView()
      };
    }
    return (
      <div>
        {DataFormatter.getObjectInStorage('userMode') === 'primary'
          && this.state.showIdleTimer && (
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              timeout={5 * 60 * 1000}
              onIdle={this.handleOnIdle}
            />
        )}
        {this.state.showPopUp ? (
          <Dialog
            title=""
            modal
            open={this.state.showPopUp}
            className="listing-outer-container deleteStudentContainer"
          >
            <div className="listing-message-container">
              <br />
              <div className="student-list-container">
                <div className="row">
                  <div
                    className="col-md-11 col-lg-11 col-xl-9"
                    style={{ paddingLeft: '50px' }}
                  >
                    <div className="" style={{ paddingLeft: '69px' }}>
                      { language.getMessage('are_you_still_reading?') }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ paddingleft: '43px' }}>
                    <Button
                      variant="contained"
                      className="no-students-button"
                      primary
                      onClick={this.logout.bind(this)}
                    >
                      {language.getMessage('log_out_label')}
                    </Button>
                    <Button
                      variant="contained"
                      className="no-students-button"
                      primary
                      onClick={this.handleCloseDialog.bind(this)}
                    >
                      {language.getMessage('Keep_reading')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        ) : (
          ''
        )}
        {
          this.state.noBookSelected && (
          <div className="noBookWrapper">
            <ExpiryMessage
              title="Sorry, there was no book selected"
              description={(
                <>Please <a href='/app'>click here</a> to go back to the home page</>
              )}
            />
          </div>)
        }
        <div
          className={`vr-container ${
            bookData.isFixedLayout || bookDetails.type === ReaderConstants.SPDF
              ? 'vr-container-fixed'
              : 'vr-container-flowable'
          }`}
        >
          {Object.keys(bookData).length > 0 ? <VegaReader {...vrProps} /> : ''}
        </div>
      </div>
    );
  }
}
// export default BookReader;
BookReader.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(inject('language'), observer)(injectIntl(BookReader));
