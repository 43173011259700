import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import injectTapEventPlugin from 'react-tap-event-plugin';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
// import DataTables from "material-ui-datatables";
import SvgIcon from '@material-ui/core/SvgIcon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { trim } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from 'mui-datatables';
import ReactHtmlParser from 'react-html-parser';
import TagManager from 'react-gtm-module';
import FileReader from './FileReader';
import * as constants from '../../../common/constants';
import { MAX_BULK_IMPORT_SIZE } from '../../../common/constants';
import StudentImportTemplate from '../../../assets/images/templates/Student_Import_Template.csv';
import EditStudent from './EditStudent';
import AddStudent from './AddStudent';
import DataFormatter from '../../../common/utils/DataFormatter';
import CommonUtils from '../../../common/utils/CommonUtils';
import LoadingOverlay from '../../../common/components/loadingOverlay';

const lang = DataFormatter.getLanguage();
// var lang = DataFormatter.getLanguage();
let TABLE_COLUMNS_NEW = new Array(); let TABLE_COLUMNS = new Array(); let ACTIONS = new Array(); let AVAILABLE_ACCESS = new Array(); let
  NOSTUDENT_COLUMNS = new Array();
const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  }
});

try {
  injectTapEventPlugin();
} catch (e) { }

const styles = {
  tableWrapperStyle: {
    margin: 4,
    boxShadow:
      '0 2px 10px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)'
  },
  infostudent: {
    height: '4px'
  },
  tableusername: {
    width: '23.3%',
    height: ' 18px',
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#007fa3'
  },
  tableusernameWithPointer: {
    width: '23.3%',
    height: ' 18px',
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#007fa3',
    cursor: 'pointer'
  },
  dot: {
    height: '25px',
    width: '25px',
    backgroundColor: ' #bbb',
    borderRadius: '50%',
    display: 'inline-block'
  },
  tablerowstyle: {
    textAlign: 'left'
  },
  searchfloatingLabelStyle: {
    fontSize: '18px',
    lineHeight: '1.22',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.42)',
    bottom: '13px',
    textIndent: 64,
    paddingRight: '40px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '95%'
  },
  searchConsoleStyle: {
    width: '50%',
    position: 'relative',
    display: 'inline-block',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: '8px',
    marginBottom: '8px'
  },
  searchTextBoxStyle: { height: '48px' },
  searchIconStyle: {
    position: 'absolute', left: '19px', bottom: 15.5, width: 17.5, height: 17.5, cursor: 'pointer', zIndex: '1000'
  },
  closeIconStyle: {
    position: 'absolute', right: '19px', bottom: 15.5, width: 17.5, height: 17.5, cursor: 'pointer', zIndex: '1000'
  },
  filterCloseIconStyle: {
    position: 'relative', right: '-140px', bottom: -10, width: 21, height: 21, cursor: 'pointer', zIndex: '1000'
  },
  searchIconColor: '#74797b',
  searchTextBoxInputStyle: { textIndent: 64, width: '90%' },
  month: {
    width: '24px',
    height: '12px',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.2',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase'

  },
  date: {
    width: '20px',
    height: '16px',
    fontSize: '16px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.1',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)'
  }
};


const EditStudentIcon = props => (
  <SvgIcon {...props}>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </SvgIcon>
);

const DeleteStudentIcon = props => (
  <SvgIcon {...props}>
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
  </SvgIcon>
);

const EditClassNameIcon = props => (
  <SvgIcon {...props}>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </SvgIcon>
);

class StudentAnalytics extends React.Component {
  constructor() {
    super();
    this.prepareUserData = this.prepareUserData.bind(this);
    this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
    this.sortValues = this.sortValues.bind(this);
    this.showAddStudent = this.showAddStudent.bind(this);
    this.showStudentList = this.showStudentList.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    // Notes
    this.handleNoteChangeEvent = this.handleNoteChangeEvent.bind(this);
  }

  state = {
    userListTableData: [],
    searchText: null,
    studentListActive: true,
    userPermissions: [],
    addStudentEnabled: false,
    noStudentPassLetterPopup: false,
    noStudentPassLetterPopupMsg: '',
    deleteStudentDialog: false,
    deleteStudentData: {},
    maxStudentDialog: false,
    classNameEditable: true,
    editStudentActive: false,
    editStudentData: {},
    showImportDialog: false,
    showImportDialogMessage: '',
    createStudentDialog: false,
    batchItemMessage: '',
    batchItemsFetched: false,
    studentCount: 0,
    isStudentListLoading: false,
    collectiveUserDataArray: '',
    userIdsDataArray: [],
    analyticsCollectiveData: [],
    newColSummaryUserData: null,
    isDataLoading: true,
    isAccessModel: false,
    isAnalyticsDataRequested: false,
    isIESUserFetchInitiated: false,
    isCourseChanging: false,
    showLoader: false,
    disableClassReport: 0
  };

  componentDidMount = function () {
    DataFormatter.setObjectInStorage('restrictedProductCall', false);
    // AppStore.on(AppConstants.EventTypes.COURSE_SELECTED, this.updateTable);
    Framework.getEventManager().on(constants.COURSE_SELECTED, () => {
      this.setState({ isCourseChanging: true }, () => {
        this.setState({ isIESUserFetchInitiated: false, isAnalyticsDataRequested: false });
        this.prepareUserData();
        this.updateTable();
      });
    });
    // AppStore.on(AppConstants.EventTypes.ANALYTICS_COLLECTIVE_DATA_FETCHED_SUCCESS, this.collectiveUserData); - This is replaced with below code
    // const course = DataFormatter.getObjectInStorage('courseSelected');
    // const fromDateFormatted = Math.floor(new Date(course.startDate).getTime() / 1000);
    // const toDateFormatted = Math.floor(new Date(course.endDate).getTime() / 1000);

    // const date = new Date();
    // const y = date.getFullYear();
    // const m = date.getMonth();
    // const firstDay = Math.floor(new Date(y, m, 1).getTime() / 1000);
    // const lastDay = Math.floor(new Date(y, m + 1, 0).setHours(23, 59, 59, 999) / 1000);
    // if (m < 8) {
    //   var firstDayofYear = Math.floor(new Date(y - 1, 8, 1).getTime() / 1000);
    //   var lastDayofYear = Math.floor(new Date(y, 7, 31).setHours(23,59,59,999) / 1000);
    // }
    // else {
    //   var firstDayofYear = Math.floor(new Date(y, 8, 1).getTime() / 1000);
    //   var lastDayofYear = Math.floor(new Date(y + 1, 7, 31).setHours(23,59,59,999) / 1000);
    // }

    Framework.getEventManager().on(constants.IES_USER_FETCHED, (setIesUserFetchData) => {
      this.setState({ userIdsDataArray: setIesUserFetchData }, () => {
        if (this.state.isAnalyticsDataRequested === false) {
          this.setState({ isAnalyticsDataRequested: true });
          Framework.getEventManager().publish(constants.ANALYTICS_COLLECTIVE_DATA_REQUESTED,
            {
              format: 'summary',
              userIds: setIesUserFetchData
            });
          Framework.getEventManager().publish(constants.ANALYTICS_COLLECTIVE_DATA_LIST_REQUESTED,
            {
              format: 'list',
              userIds: setIesUserFetchData
            });
        }
      });
    });
    Framework.getEventManager().on(constants.IES_USER_DATA_ARRAY_FETCHED, (iesUserDetailsArray) => {
      this.setState({ collectiveUserDataArray: iesUserDetailsArray });
    });
    Framework.getEventManager().on(constants.ANALYTICS_COLLECTIVE_DATA_FETCHED_SUCCESS, (colSummaryUserData) => {
      this.setState({ newColSummaryUserData: colSummaryUserData }, () => {
        Framework.getEventManager().on(constants.ANALYTICS_COLLECTIVE_DATA_LIST_FETCHED_SUCCESS, (colListUserData) => {
          // this.collectiveUserData(colUserData);-Up on getting above results we need to merge it to form dataToSend array as in app.actions.js line #2135
          if (colListUserData.user_book_analytics && this.state.collectiveUserDataArray && this.state.collectiveUserDataArray.length > 0) {
            const dataToSend = {};
            const userDataArray = this.state.collectiveUserDataArray;
            let overallBooksReadCount = 0;

            const mergedList = userDataArray.map(obj => Object.assign(obj, colListUserData.user_book_analytics.find(summary => summary.userId === obj.userId)));

            if (colListUserData !== undefined && colListUserData.statusCode != 400 && this.state.newColSummaryUserData !== undefined && this.state.newColSummaryUserData.statusCode != 400) {
              const studentsList = colListUserData.user_book_analytics.map((userdata, index) => {
                overallBooksReadCount += (userdata.booksReadCount ? userdata.booksReadCount : 0);
              });
              dataToSend.averageMonth = Math.round(this.state.newColSummaryUserData);
              const average = overallBooksReadCount / colListUserData.user_book_analytics.length;
              dataToSend.averageOverall = (isNaN(average) ? 0 : Math.round(average));
              dataToSend.studentsCount = this.state.userIdsDataArray.length;
              dataToSend.studentsList = mergedList;
            }
            this.setState({ analyticsCollectiveData: dataToSend }, () => this.collectiveUserData());
            // this.collectiveUserData();
          }
        });
      });
    });
    // AppStore.on(AppConstants.EventTypes.FETCH_IES_USER, this.prepareUserData); - This is replaced with below code
    Framework.getEventManager().on(constants.FETCH_IES_USER_SUCCESS, (IesUserData) => {
      this.setState({ userIdsDataArray: IesUserData }, () => {
        this.prepareUserData();
        this.setState({ isCourseChanging: false });// For stopping the loader during course switching
      });
    });
    Framework.getEventManager().on(constants.IES_USER_LIST_EMPTY, (response) => {
      this.setState({
        userListTableData: [],
        isDataLoading: false,
        studentCount: 0,
        disableClassReport: 1,
        isCourseChanging: false// For stopping the loader during course switching - Fix for DL-399
      });
    });
    // AppStore.on(AppConstants.EventTypes.GET_USER_PERMISSIONS, this.getUserPermissions);
    Framework.getEventManager().on(constants.GET_USER_PERMISSIONS_SUCCESS, (setUserPermissions) => {
      this.getUserPermissions(setUserPermissions);
    });
    // AppStore.on(AppConstants.EventTypes.DELETE_STUDENT, this.callbackDeleteStudent);--This is replaced with below code
    Framework.getEventManager().on(constants.DELETE_STUDENT_SUCCESS, (deleteStudentData) => {
      this.callbackDeleteStudent(deleteStudentData);
    });
    // AppStore.on(AppConstants.EventTypes.EDIT_CLASS_NAME, this.callbackEditClassName);--This is replaced with below code
    Framework.getEventManager().on(constants.EDIT_CLASS_NAME_SUCCESS, (editClassNameData) => {
      DataFormatter.setObjectInStorage('editClassNameData', editClassNameData);
      this.callbackEditClassName(editClassNameData);
    });
    // AppStore.on(AppConstants.EventTypes.FETCH_STUDENT_PASS_LETTER, this.callbackFetchPasswordLetters);
    Framework.getEventManager().on(constants.FETCH_STUDENT_PASS_LETTER_SUCCESS, (studentData) => {
      this.callbackFetchPasswordLetters(studentData);
    });
    // AppActions.getUserBatch(); - This is replaced with below code
    Framework.getEventManager().publish(constants.GET_USER_BATCH_REQUESTED, { userId: DataFormatter.getKeyFromObject('userInformation', 'id') });
    Framework.getEventManager().on(constants.ADD_STUDENT_SUCCESS, () => {
      this.setState({
        isIESUserFetchInitiated: false,
        isAnalyticsDataRequested: false
      });
    });
    Framework.getEventManager().on(constants.FETCH_CLASS_REPORT_SUCCESS, () => {
      this.setState({ showLoader: false });
    });
    const gtmData = CommonUtils.getDefaultGAPageView();
    gtmData.dataLayer.page_title = 'Students';
    TagManager.dataLayer(gtmData);
  };

  UNSAFE_componentWillMount = function () {
    const { language, intl, iesUserDetailsByCourse } = this.props;

    const course = DataFormatter.getObjectInStorage('courseSelected');
    if (course.id.length > 0) {
      const courseId = course.id;
      const roleValue = constants.roleValue;
      iesUserDetailsByCourse.fetch(courseId, roleValue);
    }

    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    Framework.getEventManager().publish(constants.GET_USER_PERMISSIONS_REQUESTED,
      {
        userid: userId
      });
    // this.updateTable();
    const student_name_label = intl.formatMessage(language.getText('Student_name'));
    const number_of_label = intl.formatMessage(language.getText('Number_of'));
    const books_read_label = intl.formatMessage(language.getText('Books_Read_dashboard'));
    const unread_books_label = intl.formatMessage(language.getText('Unread_books'));
    const in_bookbox_label = intl.formatMessage(language.getText('In_bookbox'));
    const last_login_time_label = ReactHtmlParser(intl.formatMessage(language.getText('last_login_time')));
    const options_label = intl.formatMessage(language.getText('options'));
    const available_access_label = intl.formatMessage(language.getText('available_access'));
    TABLE_COLUMNS_NEW = [
      {
        name: 'name',
        label: <div><p>{student_name_label}</p></div>,
        options: {
          sort: true
        }
        // {
        //   key: "level",
        //   style: styles.tableusername,
        //   label: (
        //     <div>
        //       <p>Level of</p>
        //       <p>Last Books Read</p>
        //     </div>
        //   )
      },
      {
        name: 'booksReadCount',
        // style: styles.tableusername,
        label: (
          <div>
            <p>
              {number_of_label}
&nbsp;
              {books_read_label}
            </p>
          </div>
        ),
        options: {
          sort: true
        }
        // sortable: true
      },
      {
        name: 'activitiesCompleted',

        label: (
          <div>
            <p>{language.getMessage('Number_of')}</p>
            <p>{language.getMessage('activities_completed')}</p>
          </div>
        ),
        options: {
          sort: true
        }
      },
      {
        name: 'booksUnReadCount',
        // style: styles.tableusername,
        label: (
          <div>
            <p>
              {unread_books_label}
&nbsp;
              {in_bookbox_label}
            </p>
          </div>
        ),
        options: {
          sort: true
        }
        // sortable: true
      },
      {
        name: 'lastTimeLoggedIn',
        // style: styles.tableusername,
        label: (
          <div>
            <p>{last_login_time_label}</p>
          </div>
        ),
        options: {
          sort: true
        }
        // sortable: true
      },
      {
        name: 'Notes',
        // style: styles.tableusername,
        label: (
          <div style={{ marginLeft: '11px' }}>
            <p>{language.getMessage('notes')}</p>
          </div>
        ),
        options: {
          sort: false
        }

      }
    ];

    ACTIONS = [{
      name: 'actions',
      // style: styles.tableusername,
      label: (
        <div><p>{options_label}</p></div>
      ),
      options: {
        sort: false
      }
      // sortable: false
    }];

    AVAILABLE_ACCESS = [{
      name: 'availableAccess',
      label: (
        <div>
          <p>{available_access_label}</p>
        </div>
      ),
      options: {
        sort: true
      }
    }];

    NOSTUDENT_COLUMNS = [
      {
        name: 'name',
        label: <div tabIndex="0" />,
        options: {
          sort: false
        }
      }
    ];
  }

  componentWillUnmount() {
    // AppStore.removeListener(AppConstants.EventTypes.ANALYTICS_COLLECTIVE_DATA_FETCHED_SUCCESS, this.prepareUserData);
    // AppStore.removeListener(AppConstants.EventTypes.FETCH_IES_USER, this.prepareUserData);
    // AppStore.removeListener(AppConstants.EventTypes.GET_USER_PERMISSIONS, this.getUserPermissions);
    // AppStore.removeListener(AppConstants.EventTypes.DELETE_STUDENT, this.callbackDeleteStudent);
    // AppStore.removeListener(AppConstants.EventTypes.EDIT_CLASS_NAME, this.callbackEditClassName);
    // AppStore.removeListener(AppConstants.EventTypes.FETCH_STUDENT_PASS_LETTER, this.callbackFetchPasswordLetters);
  }

  updateTable = () => {
    const { iesUserDetailsByCourse } = this.props;
    const course = DataFormatter.getObjectInStorage('courseSelected');
    // let roleValue = AppConstants.roleValue;
    const fromDateFormatted = Math.floor(new Date(course.startDate).getTime() / 1000);
    const toDateFormatted = Math.floor(new Date(course.endDate).getTime() / 1000);
    if (course.id.length > 0) {
      const courseId = course.id;
      const roleValue = constants.roleValue;
      iesUserDetailsByCourse.fetch(courseId, roleValue);
    }
    // AppActions.getCollectiveData(courseId, roleValue, fromDateFormatted, toDateFormatted);
    // Framework.getEventManager().publish(constants.ANALYTICS_COLLECTIVE_DATA_REQUESTED,
    //   {
    //     firstDay:fromDateFormatted,
    //     lastDay:toDateFormatted,
    //     format:'summary',
    //     userIds: setIesUserFetchData
    //   }
    // );
    // AppActions.getUserPermissions();
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    Framework.getEventManager().publish(constants.GET_USER_PERMISSIONS_REQUESTED, { userid: userId });
  };

  getUserPermissions = (setUserPermissions) => {
    const userPermissions = [];
    if (typeof setUserPermissions !== 'undefined' && setUserPermissions.length > 0) {
      setUserPermissions.map((userpermission) => {
        userPermissions.push(userpermission.capability);
      });
      this.setState({ userPermissions });
      if (userPermissions.includes('create_user') && userPermissions.includes('enroll_course_student') && userPermissions.includes('enroll_school_student')) {
        this.setState({ addStudentEnabled: true });
      }
    }
  };

  collectiveUserData = () => {
    const userIds = [];
    const userListDataArray = this.state.analyticsCollectiveData.studentsList;
    if (userListDataArray !== undefined && userListDataArray.length > 0) {
      userListDataArray.map(user => userIds.push(user.userId));
      // AppActions.iesUserFetch(JSON.stringify(userIds))
      if (this.state.isIESUserFetchInitiated == false) {
        this.setState({ isIESUserFetchInitiated: true });
        Framework.getEventManager().publish(constants.FETCH_IES_USER_REQUESTED,
          {
            userIdArray: userIds
          });
      }
    } else {
      this.prepareUserData();
    }
  }

  capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

  // Notes
  handleNoteChangeEvent = (id, event) => {
    const { language, intl } = this.props;
    if(event.target.defaultValue === event.target.value) {
      return false;
    }
    const classCode = DataFormatter.getObjectInStorage('courseSelected').externalRef[0].originId;
    const userId = id;
    const notesValue = event.target.value;
    let notepayload;
    if (notesValue && typeof notesValue !== undefined) {
      notepayload = {
        userId,
        classCode,
        notes: notesValue
      };
    } else {
      notepayload = {
        userId,
        classCode
      };
    }
    // AppActions.postNotesOnEachStudent(userId, classCode, notepayload)
    Framework.getEventManager().publish(constants.POST_NOTES_ON_STUDENT_REQUESTED,
      {
        userId,
        classCode,
        notes: notepayload
      });

    const gtmData = {
      dataLayer: {
        event: 'adding_notes',
        event_category: intl.formatMessage(language.getText('adding_notes')),
        event_action: intl.formatMessage(language.getText('add_notes_clicked')),
        event_label: null,
        transaction_local_dt: CommonUtils.getLocalIsoTime(),
        student_user_id: userId,
        notes_value: notesValue
      }
    };
    TagManager.dataLayer(gtmData);
  }

  prepareUserData = function () {
    const { language, intl } = this.props;
    const userListTableData = [];
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const userListDataArray = this.state.analyticsCollectiveData.studentsList;
    const isAccessModel = DataFormatter.getObjectInStorage('courseSelected').accessModel;

    if (userListDataArray !== undefined && userListDataArray.length > 0 && this.state.userIdsDataArray.data !== undefined && this.state.userIdsDataArray.data.matches !== undefined) {
      const userDetails = this.state.userIdsDataArray.data.matches;// AppStore.setIesUserFetchData.data.matches
      const that = this;
      let userListDataArray = this.state.analyticsCollectiveData.studentsList;
      for (let i = 0; i < userDetails.length; i++) {
        for (let j = 0; j < userListDataArray.length; j++) {
          if (userDetails[i].identity.id === userListDataArray[j].userId) {
            userListDataArray[j].fName = (userDetails[i].identityProfile.givenName !== undefined) ? userDetails[i].identityProfile.givenName : '';
            userListDataArray[j].lName = (userDetails[i].identityProfile.familyName !== undefined) ? userDetails[i].identityProfile.familyName : '';
            userListDataArray[j].passType = (userDetails[i].credentials[0].passType === 'IMG') ? 'Image' : 'Text';

            // to capitalize first letter of name
            userListDataArray[j].fName = this.capitalizeFirstLetter(userListDataArray[j].fName);
            userListDataArray[j].lName = this.capitalizeFirstLetter(userListDataArray[j].lName);
            userListDataArray[j].lastTimeLoggedIn = userDetails[i].credentials[0].lastLoginSuccess;
          }
        }
      }
      // To delete an object in an array if fName and lName is empty
      // This is work around to prevent showing undefined during the demo
      // Need to follow up with services team to fix the bug
      /* TODO: DELETE THE CONDITION WHERE I AM REMOVING AN OBJ IN THE ARRAY */
      userListDataArray = userListDataArray.filter(user => user.hasOwnProperty('fName'));

      userListDataArray.map((userdata, index) => {
        const userObject = {};
        userObject.name = (
          <a
            onClick={that.props.handleRedirect.bind(that, userdata)}
            href="javascript:void(0);"
            onKeyPress={that.props.handleRedirect.bind(that, userdata)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                that.handleRedirect.call(that, userdata);
              }
            }}
          >
            {`${userdata.fName} ${userdata.lName}`}
          </a>
        );
        userObject.fullname = userdata.fName;// + " " + userdata.lName;
        userObject.booksReadCount = (userdata.booksReadCount ? userdata.booksReadCount : 0);
        userObject.activitiesCompleted = (userdata.numberOfActivitiesCompleted ? userdata.numberOfActivitiesCompleted : 0);
        userObject.booksUnReadCount = (userdata.booksUnReadCount ? userdata.booksUnReadCount : 0);
        if (userdata.lastTimeLoggedIn) {
          const loginDate = new Date(userdata.lastTimeLoggedIn);
          const loginMonth = loginDate.getMonth();
          const loginDateFormatted = (`0${loginDate.getUTCDate()}`).slice(-2);
          userObject.lastTimeLoggedInforSort = Date.parse(loginDate) / 1000;
          userObject.lastTimeLoggedIn = (
            <div>
              <span style={styles.month}><p>{monthNames[loginMonth]}</p></span>
              <span style={styles.date}><p>{loginDateFormatted}</p></span>
            </div>
          );
        } else {
          userObject.lastTimeLoggedIn = '';
          userObject.lastTimeLoggedInforSort = 0;
        }
        // Notes
        let notesValue = '';
        if (userdata.notes.length > 0) {
          notesValue = userdata.notes[0].notes;
        } else {
          notesValue = '';
        }
        userObject.Notes = (
          <textarea type="text" maxLength={100} id="notes" onBlur={that.handleNoteChangeEvent.bind(that, userdata.userId)} defaultValue={notesValue} key={`${Math.floor((Math.random() * 1000))}`} />
        );
        const student_letter_label = intl.formatMessage(language.getText('student_letter'));
        const edit_students_label = intl.formatMessage(language.getText('edit_students'));
        const delete_student_label = `${intl.formatMessage(language.getText('Delete'))} ${intl.formatMessage(language.getText('student_text'))}`;
        const reset_password_label = intl.formatMessage(language.getText('reset_password'));
        // userObject.actions = <div><span className="editIcon" tabIndex="0" onKeyPress={that.handleEditStudentEnterKey.bind(that,userdata)}><EditStudentIcon color="#017fa3" onClick={that.showEditStudent.bind(that, userdata)} /></span><span className="deleteIcon" tabIndex="0" onKeyPress={that.handleDeleteStudentEnterKey.bind(that, userdata)}><DeleteStudentIcon color="#017fa3" onClick={that.showDeleteStudent.bind(that, userdata)} /></span></div>;
        userObject.actions = (
          <div className="student-more-options-container">
            <Select value={0} className="student-more-options-dropdown" MenuProps={{ className: 'student-more-options-dropdown-menu' }} onChange={that.showMoreOptionsForStudent.bind(that, userdata)}>
              <MenuItem classes={{ root: 'student-more-options-zero' }} value={0}><MoreHorizIcon color="#007fa3" className="student-more-options-zero" /></MenuItem>
              <MenuItem classes={{ root: 'student-more-options-text' }} value={2}>
                <MailOutlineIcon color="#017fa3" />
&nbsp;&nbsp;
                {student_letter_label}
              </MenuItem>

              {isAccessModel
                ? (
                  <MenuItem classes={{ root: 'student-more-options-text' }} value={4}>
                    <LockOutlinedIcon color="#017fa3" />
&nbsp;&nbsp;
                    {reset_password_label}
                  </MenuItem>
                ) : ''}
              {!isAccessModel
                ? (
                  <MenuItem classes={{ root: 'student-more-options-text' }} value={1}>
                    <EditStudentIcon color="#017fa3" />
&nbsp;&nbsp;
                    {edit_students_label}
                  </MenuItem>
                ) : ''}
              {!isAccessModel
                ? (
                  <MenuItem classes={{ root: 'student-more-options-last' }} value={3}>
                    <DeleteStudentIcon color="#017fa3" />
&nbsp;&nbsp;
                    {delete_student_label}
                  </MenuItem>
                ) : ''}
            </Select>
          </div>
        );

        if (isAccessModel) {
          userObject.availableAccess = (userdata.isLocked) ? <LockOutlinedIcon color="#017fa3" /> : <DoneOutlinedIcon color="#017fa3" />;
        }
        userListTableData.push(userObject);
      });
      this.setState({
        userListTableData,
        isDataLoading: false
      });
      this.setState({ studentCount: userListTableData.length });
    } else {
      // regarding this change for DL-216
      // else {
      if (userListDataArray === undefined && this.state.userIdsDataArray.length === 0) {
        setTimeout(() => {
          const userObject = {};
          const no_students_msg1_label = intl.formatMessage(language.getText('no_students_msg1'));
          const no_students_msg2_label = intl.formatMessage(language.getText('no_students_msg2'));
          const no_students_msg3_label = intl.formatMessage(language.getText('no_students_msg3'));
          // //userObject.id = 1;
          userObject.name = (
            <div className="no-students">
              {no_students_msg1_label}
              {' '}
              <br />
              {no_students_msg2_label}
              {' '}
              <br />
              {no_students_msg3_label}
            </div>
          );
          // userObject.booksUnReadCount = "";
          // userObject.actions = "";
          userListTableData.push(userObject);
          this.setState({
            userListTableData,
            isDataLoading: false
          });
          this.setState({ studentCount: 0 });
        });
      }
    }
  };

  handleCloseDialog(type, event) {
    this.setState({ noStudentPassLetterPopup: false });
    this.setState({ deleteStudentDialog: false });
    this.setState({ deleteStudentData: {} });
    this.setState({ maxStudentDialog: false });
    this.setState({ showImportDialog: false });
    this.setState({ showImportDialogMessage: '' });
    this.setState({ createStudentDialog: false, batchItemMessage: '', batchItemsFetched: false });
  }

  handleSortOrderChange(key, order) {
    if (key == 'name') {
      this.setState({ userListTableData: [].concat(this.state.userListTableData.sort(this.sortValues('fullname', order))) });
    } else if (key == 'lastTimeLoggedIn') {
      this.setState({ userListTableData: [].concat(this.state.userListTableData.sort(this.sortValues('lastTimeLoggedInforSort', order))) });
    } else {
      this.setState({ userListTableData: [].concat(this.state.userListTableData.sort(this.sortValues(key, order))) });
    }
  }

  sortValues = function (key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ? (comparison * -1) : comparison
      );
    };
  };

  showAddStudent = function () {
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    if (userId && window.Appcues) {
      window.Appcues.identify(userId, {
        role: 'teacher'
      });
    }
    if (this.state.userListTableData.length >= 35) {
      this.setState({ maxStudentDialog: true });
    } else {
      this.setState({ studentListActive: false });
    }
  }

  showEditStudent(userdata, event) {
    this.setState({ studentListActive: false });
    this.setState({ editStudentActive: true });
    this.setState({ editStudentData: userdata });
    if (event.target.value === 4) {
      this.setState({ isAccessModel: true });
    }
  }

  showDeleteStudent(userdata, event) {
    this.setState({ deleteStudentDialog: true });
    this.setState({ deleteStudentData: userdata });
  }

  showStudentList = function () {
    this.setState({ isAnalyticsDataRequested: false });
    this.setState({ studentListActive: true });
    this.setState({ editStudentActive: false });
    this.setState({ noStudentPassLetterPopup: false });
    // AppActions.getUserBatch();
    Framework.getEventManager().publish(constants.GET_USER_BATCH_REQUESTED, { userId: DataFormatter.getKeyFromObject('userInformation', 'id') });
    this.updateTable();
  }

  deleteStudent(event) {
    const { language, intl } = this.props;
    if (this.state.deleteStudentData) {
      // AppActions.deleteStudent(DataFormatter.getObjectInStorage('schoolId'),this.state.deleteStudentData.userId);--This is replaced with below code
      Framework.getEventManager().publish(constants.DELETE_STUDENT_REQUESTED,
        {
          schoolId: DataFormatter.getObjectInStorage('schoolId'),
          studentuserId: this.state.deleteStudentData.userId,
          userId: DataFormatter.getKeyFromObject('userInformation', 'id')
        });

      const gtmData = {
        dataLayer: {
          event: 'deleting_student',
          event_category: intl.formatMessage(language.getText('deleting_student')),
          event_action: intl.formatMessage(language.getText('delete_student_clicked')),
          event_label: null,
          transaction_local_dt: CommonUtils.getLocalIsoTime(),
          student_user_id: this.state.deleteStudentData.userId
        }
      };
      TagManager.dataLayer(gtmData);
    }
  }

  callbackDeleteStudent = (deleteStudentData) => {
    const userPermissions = [];
    if (typeof deleteStudentData !== 'undefined') {
      this.setState({ deleteStudentDialog: false });
      this.setState({ deleteStudentData: {} });
      this.setState({ isIESUserFetchInitiated: false });
      this.setState({ isAnalyticsDataRequested: false }, () => {
        this.prepareUserData();
        this.updateTable();
      });
    }
  };

  handleClassNameChange = (event) => {
    const { language, intl } = this.props;
    this.setState({ classNameEditable: true });
    const oldClassName = DataFormatter.getObjectInStorage('courseSelected').name;
    if (trim(event.target.value) == DataFormatter.getObjectInStorage('courseSelected').name) {
      return false;
    }
    const newClassName = trim(event.target.value);
    this.setState({ isStudentListLoading: true });
    // AppStore.editClassNameStatus = true;--This code is replaced with below logic
    localStorage.setItem('editClassNameStatus', 'true');
    // AppStore.on(
    //   AppConstants.EventTypes.EDIT_CLASS_NAME,
    //   this.callbackEditClassName
    // );
    Framework.getEventManager().on(constants.EDIT_CLASS_NAME_SUCCESS, (editClassNameData) => {
      DataFormatter.setObjectInStorage('editClassNameData', editClassNameData);
      this.callbackEditClassName(editClassNameData);
    });
    // AppActions.editDefaultClassName(DataFormatter.getObjectInStorage('courseSelected').id, newClassName);
    Framework.getEventManager().publish(constants.EDIT_CLASS_NAME_REQUESTED,
      {
        className: newClassName,
        classSecId: DataFormatter.getObjectInStorage('courseSelected').id
      });
    const gtmData = {
      dataLayer: {
        event: 'editing_classname',
        event_category: intl.formatMessage(language.getText('editing_classname')),
        event_action: intl.formatMessage(language.getText('edit_classname_clicked')),
        event_label: null,
        transaction_local_dt: CommonUtils.getLocalIsoTime(),
        old_classname: oldClassName,
        updated_classname: newClassName
      }
    };
    TagManager.dataLayer(gtmData);
  }

  handleClassNameEnterKey = (event) => {
    if (event.key !== 'Enter') {
      return false;
    }
    this.handleClassNameChange(event);
  }

  callbackEditClassName = (editClassNameData) => {
    // Call back method for edit class name
    if (typeof editClassNameData !== 'undefined' && localStorage.getItem('editClassNameStatus') === 'true') {
      if (editClassNameData.update !== '') {
        const jsonCourse = DataFormatter.getObjectInStorage('courseSelected');
        jsonCourse.name = editClassNameData.update.name;

        DataFormatter.setObjectInStorage('courseSelected', jsonCourse);
        const schoolId = DataFormatter.getObjectInStorage('schoolId');
        const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
        // AppActions.fetchMyEnrolledCourses(schoolId, userId);
        Framework.getEventManager().publish(constants.COURSELIST_REQUESTED, { schoolId, userId });
      }
    }
    // AppStore.editClassNameStatus = false;--This code is replaced with below logic
    localStorage.removeItem('editClassNameStatus');
    this.setState({ isStudentListLoading: false });
  }

  makeClassNameEditable = () => {
    this.setState({ classNameEditable: false });
  }

  importHelpClicked = () => {
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
    const helpLinkUrl = appConfig.help.multiple_student_url;
    window.open(helpLinkUrl, '_blank');
  }

  // Reports:
  fetchClassLevelReport = (event) => {
    const selectedFormat = event.target.value;
    const classCode = DataFormatter.getObjectInStorage('courseSelected').externalRef[0].originId;
    // Fetching UserID
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    // AppActions.downloadClassLevelReport(userId, classCode, selectedFormat, fileFormat);
    Framework.getEventManager().publish(constants.FETCH_CLASS_REPORT_REQUESTED, {
      userId,
      classCode,
      selectedFormat
    });
    this.setState({
      showLoader: true
    });
  }

  renderstats = function () {
    const { language, intl } = this.props;
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
    const helpLinkUrl = appConfig.help.url;
    const student_dashboard_label = intl.formatMessage(language.getText('student_dashboard'));
    const students_label = intl.formatMessage(language.getText('students'));
    const class_code_label = intl.formatMessage(language.getText('class_code'));
    const student_letters_label = intl.formatMessage(language.getText('student_letters'));
    const add_student_label = intl.formatMessage(language.getText('add_student'));
    const import_students_help_label = intl.formatMessage(language.getText('import_students_help'));
    const download_template_label = intl.formatMessage(language.getText('download_template'));
    return (
      <div>
        <div className="studentinfo">
          <p aria-live="polite">
            {students_label}
            <span aria-label={student_dashboard_label} />
          </p>
        </div>
        <div className="class-code-container">
          {class_code_label}
          <span id="class_code_value" tabIndex="0" onKeyDown={this.copyClassCodeValue}>{DataFormatter.getObjectInStorage('courseSelected').externalRef[0].originId.toUpperCase()}</span>
        </div>
        {/* {(typeof AppStore.analyticsCollectiveData !=="undefined" && typeof AppStore.analyticsCollectiveData.studentsList !=="undefined")&& */}
        <div className="class-code-container">
          <span tabIndex="0" className="download-class-password-letters" onClick={this.downloadClassPasswordLetter} onKeyDown={(e) => { if (e.key == 'Enter') { this.downloadClassPasswordLetter(); } }}>{student_letters_label}</span>
        </div>
        {/* } */}
        <div className="manage-student-container">
          <div className="update-classname">
            <div>
              <input id="default_classname" readOnly={this.state.classNameEditable} className={this.state.classNameEditable ? 'disable-default-classname disable' : 'edit-default-classname'} ref="defaultClassName" onBlur={this.handleClassNameChange} onKeyPress={this.handleClassNameEnterKey} defaultValue={DataFormatter.getObjectInStorage('courseSelected').name} key={`${Math.floor((Math.random() * 1000))}`} autoComplete="off" />
              <span tabIndex="0" className="editClassNameIcon" onKeyPress={this.handleEditClasIconEnterKey}><EditClassNameIcon color="#017fa3" onClick={this.makeClassNameEditable} /></span>
              {(this.state.isStudentListLoading === true)
                && <div className="circularLoaderEditClass"><CircularProgress mode="indeterminate" /></div>
              }
            </div>
          </div>
          {(this.state.addStudentEnabled === true) && (
          <div className="manage-student">
            <Button variant="contained" color="primary" className="studentButton  add-student" onClick={this.showAddStudent}>{add_student_label}</Button>
            {(typeof helpLinkUrl !== 'undefined' && helpLinkUrl !== '')
              && <Button variant="contained" color="primary" className="studentButton  import-student-help" onClick={this.importHelpClicked}>{import_students_help_label}</Button>
            }
            <Button variant="contained" color="primary" onClick={() => { window.open(StudentImportTemplate, '_blank'); }} primary className="studentButton download-template">{download_template_label}</Button>
            <FileReader showDialog={this.handleImportDialog.bind(this)} showCreateDialog={this.handleCreateDialog.bind(this)} />
            <Select id="DownloadReport" color="primary" value="Download Report As" className="studentButton  import-student-help download_classreport .MuiSelect-selectMenu-966" MenuProps={{ className: '.MuiSelect-select-896.MuiSelect-select-896 ' }} onChange={this.fetchClassLevelReport} disabled={this.state.disableClassReport === 1 ? true:false}>
              <MenuItem classes={{ root: 'student-more-options-text' }} value="Download Report As">{language.getMessage('download_report')}</MenuItem>
              <MenuItem classes={{ root: 'student-more-options-text' }} value="csv">{language.getMessage('csv')}</MenuItem>
              <MenuItem classes={{ root: 'student-more-options-text' }} value="xlsx">{language.getMessage('excel')}</MenuItem>
              <MenuItem classes={{ root: 'student-more-options-text' }} value="pdf">{language.getMessage('pdf')}</MenuItem>
            </Select>
          </div>
          )}
        </div>
      </div>
    );
  };

  handleImportDialog = (status, message) => {
    this.setState({
      showImportDialog: status,
      showImportDialogMessage: message
    });
  }

  handleCreateDialog = (status, loadComplete, message) => {
    this.setState({
      createStudentDialog: status,
      batchItemMessage: message,
      batchItemsFetched: loadComplete,
      isAnalyticsDataRequested: false
    });
  }

  handleEditStudentEnterKey = (userdata, event) => {
    if (event.key !== 'Enter') {
      return false;
    }
    this.setState({ studentListActive: false });
    this.setState({ editStudentActive: true });
    this.setState({ editStudentData: userdata });
  }

  handleDeleteStudentEnterKey = (userdata, event) => {
    if (event.key !== 'Enter') {
      return false;
    }
    this.setState({ deleteStudentDialog: true });
    this.setState({ deleteStudentData: userdata });
  }

  downloadClassPasswordLetter = () => {
    const classCode = DataFormatter.getObjectInStorage('courseSelected').externalRef[0].originId;
    let userIds = [];
    const userListDataArray = this.state.analyticsCollectiveData.studentsList;
    if (userListDataArray !== undefined && userListDataArray.length > 0) {
      userListDataArray.map(user => userIds.push(user.userId));
    }
    userIds = { userIds };
    DataFormatter.removeObjectInStorage('studentPasswordLetterData');
    // AppActions.downloadClassListPdf(classCode,userIds);
    Framework.getEventManager().publish(constants.FETCH_STUDENT_PASS_LETTER_REQUESTED,
      {
        classCode,
        payload: userIds
      });
  }

  downloadStudentPasswordLetter = (userdata, event) => {
    const userId = userdata.userId;
    let userIds = [];
    userIds.push(userId);
    const classCode = DataFormatter.getObjectInStorage('courseSelected').externalRef[0].originId;
    userIds = { userIds };
    // AppActions.downloadClassListPdf(classCode,userIds);
    DataFormatter.removeObjectInStorage('studentPasswordLetterData');
    Framework.getEventManager().publish(constants.FETCH_STUDENT_PASS_LETTER_REQUESTED,
      {
        classCode,
        payload: userIds
      });
  }

  callbackFetchPasswordLetters = (setStudentPasswordLetterData) => {
    const { language, intl } = this.props;
    if (typeof setStudentPasswordLetterData !== 'undefined' && typeof setStudentPasswordLetterData.pdfUrl !== 'undefined') {
      // pdf available for the students
      const studentPasswordLetterData = setStudentPasswordLetterData.pdfUrl;
      if (DataFormatter.getObjectInStorage('studentPasswordLetterData') === studentPasswordLetterData) {
        return false;
      }
      DataFormatter.setObjectInStorage('studentPasswordLetterData', studentPasswordLetterData);
      window.open(setStudentPasswordLetterData.pdfUrl, '_blank');
    } else if (typeof setStudentPasswordLetterData.message !== '') { // pdf not available for the students
      this.setState({ noStudentPassLetterPopupMsg: intl.formatMessage(language.getText('password_letter_unavailable')), noStudentPassLetterPopup: true });
    }
  }

  showMoreOptionsForStudent = (userdata, event) => {
    const value = event.target.value;
    switch (value) {
      case 1:
      case 4:
        this.showEditStudent(userdata, event);
        break;
      case 2:
        this.downloadStudentPasswordLetter(userdata, event);
        break;
      case 3:
        this.showDeleteStudent(userdata, event);
        break;
      default:
        return false;
        break;
    }
  }

  handleEditClasIconEnterKey = (event) => {
    if (event.key !== 'Enter') {
      return false;
    }
    this.setState({ classNameEditable: false });
    document.getElementById('default_classname').focus();
  }

  copyClassCodeValue = (event) => {
    const isKeyPressed = event.key === 'Tab' || event.keyCode === 9 || event.shiftKey || event.ctrlKey || event.keyCode === 67;
    if (!isKeyPressed) {
      return;
    }
    if (event.keyCode === 67 && event.ctrlKey) {
      const r = document.createRange();
      r.selectNode(document.getElementById('class_code_value'));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(r);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    }
  }

  render() {
    const { language, intl } = this.props;
    const { isCourseChanging, showLoader } = this.state;
    const isAccessModel = DataFormatter.getObjectInStorage('courseSelected').accessModel;
    TABLE_COLUMNS = isAccessModel ? TABLE_COLUMNS_NEW.concat(ACTIONS, AVAILABLE_ACCESS) : TABLE_COLUMNS_NEW.concat(ACTIONS);
    const options = {
      filterType: 'checkbox',
      download: false,
      expandableRowsHeader: false,
      filter: false,
      responsive: 'standard',
      rowHover: false,
      rowsPerPage: 100,
      selectableRowsHeader: false,
      selectableRows: 'none',
      rowsPerPageOptions: {},
      paging: false,
      search: false,
      print: false,
      viewColumns: false,
      pagination: false,
      onColumnSortChange: this.handleSortOrderChange
    };
    const okbutton_label = intl.formatMessage(language.getText('okButtonLabel'));
    const delete_student_label = intl.formatMessage(language.getText('delete_student'));
    const yes_label = intl.formatMessage(language.getText('yes'));
    const no_label = intl.formatMessage(language.getText('no'));
    const max_limit_add_label = intl.formatMessage(language.getText('max_limit_add'));
    const students_label = intl.formatMessage(language.getText('students'));
    const do_not_close_label = `${intl.formatMessage(language.getText('student_creation_progress'))} ${intl.formatMessage(language.getText('do_not_close'))}`;
    return (
      <MuiThemeProvider theme={muiTheme}>
        <LoadingOverlay isLoading={isCourseChanging || showLoader} />
        {(this.state.studentListActive === true ? (
          <div className={`analyticcontainer ${isAccessModel ? 'access-model' : ''}`}>
            {this.renderstats()}
            <MUIDataTable
              className="studentlist"
            // height={"auto"}
            // selectable={true}
            // showRowHover={true}
              columns={(this.state.studentCount === 0) ? NOSTUDENT_COLUMNS : (this.state.addStudentEnabled) ? TABLE_COLUMNS : TABLE_COLUMNS_NEW}
              data={
              (this.state.isDataLoading)
                ? [[intl.formatMessage(language.getText('loading_data'))]]
                : (this.state.userListTableData.length > 0
                  ? this.state.userListTableData
                  : [[
                    <div className="no-students">
                      {intl.formatMessage(language.getText('no_students_msg1'))}
                      {' '}
                      <br />
                      {intl.formatMessage(language.getText('no_students_msg2'))}
                      {' '}
                      <br />
                      {intl.formatMessage(language.getText('no_students_msg3'))}
                    </div>
                  ]]
                )
            }
              options={options}
            // enableSelectAll={true}
            // showFooterToolbar={false}
            // showCheckboxes={false}
            // tableWrapperStyle={styles.tableWrapperStyle}
            // multiSelectable={true}
            // tablerowstyle={styles.tablerowstyle}
            // onCellClick={this.handleCellClick}
            // onCellDoubleClick={this.handleCellDoubleClick}
            // onFilterValueChange={this.handleFilterValueChange}
              onSortOrderChange={this.handleSortOrderChange}
            />
            <Dialog title="" modal open={this.state.noStudentPassLetterPopup} onKeyDown={(e) => { if (e.key == 'Escape') { this.handleCloseDialog.bind(this, 'noStudents'); } }} className="listing-outer-container deleteStudentContainer">
              <div className="listing-message-container">
                <div className="listing-close" onClick={this.handleCloseDialog.bind(this, 'noStudents')}>X</div>
                <h3 />
                <br />
                <div className="student-list-container">
                  <div className="row">
                    <div className="col-md-10 col-lg-11 col-xl-11 justify-content-center">
                      {/* <div className="col no-students-text">{...language.getText('password_letter_unavailable')}</div> */}
                      <div className="col no-students-text">{this.state.noStudentPassLetterPopupMsg}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Button
                        variant="raised"
                        className="no-students-button-pswd"
                        primary
                        onClick={this.handleCloseDialog.bind(this, 'noStudents')}
                      >
                        {okbutton_label}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            <Dialog title="" modal open={this.state.deleteStudentDialog} className="listing-outer-container deleteStudentContainer">
              <div className="listing-message-container">
                <div
                  className="listing-close"
                  tabIndex="0"
                  onClick={this.handleCloseDialog.bind(this, 'deleteStudent')}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      this.handleCloseDialog(this, 'deleteStudent');
                    }
                  }}
                >
X
                </div>
                <h3 />
                <br />
                <div className="student-list-container">
                  <div className="row">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                      <div className="col no-students-text">{delete_student_label}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Button
                        variant="contained"
                        className="no-students-button"
                        primary
                        onClick={this.deleteStudent.bind(this)}
                      >
                        {yes_label}
                      </Button>
                      <Button
                        variant="contained"
                        className="no-students-button"
                        primary
                        onClick={this.handleCloseDialog.bind(this, 'deleteStudent')}
                      >
                        {no_label}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            <Dialog title="" modal open={this.state.maxStudentDialog} className="listing-outer-container">
              <div className="listing-message-container">
                <div className="listing-close" onClick={this.handleCloseDialog.bind(this, 'maxStudent')}>X</div>
                <h3 />
                <br />
                <div className="student-list-container">
                  <div className="row">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                      <div className="col no-students-text">
                        {max_limit_add_label}
                        {' '}
                        {MAX_BULK_IMPORT_SIZE}
                        {' '}
                        {students_label}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Button
                        variant="raised"
                        className="no-students-button"
                        primary
                        onClick={this.handleCloseDialog.bind(this, 'maxStudent')}
                      >
                        {okbutton_label}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            <Dialog title="" modal open={this.state.showImportDialog} className="listing-outer-container">
              <div className="listing-message-container">
                <div className="listing-close" onClick={this.handleCloseDialog.bind(this, 'maxStudent')}>X</div>
                <h3 />
                <br />
                <div className="student-list-container">
                  <div className="row">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                      <div className="col no-students-text">{this.state.showImportDialogMessage}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Button
                        variant="raised"
                        className="no-students-button"
                        primary
                        onClick={this.handleCloseDialog.bind(this, 'maxStudent')}
                      >
                        {okbutton_label}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
            <Dialog title="" modal open={this.state.createStudentDialog} contentClassName="popup-outer-container">
              <div className="popup-message-container">
                {/* <div className="popup-close" onClick={this.handleCloseDialog.bind(this, "createStudent")}>X</div> */}
                <h3 />
                <br />
                <br />
                <div className="create-student-container">
                  <div className="row">
                    <div className="col-md-11 col-lg-11 col-xl-11">
                      {!this.state.batchItemsFetched && (
                      <div>
                        <div className="circularLoader">
                          <CircularProgress mode="indeterminate" />
                          <br />
                        </div>
                        <div>{do_not_close_label}</div>
                      </div>
                      )}
                      {/* {this.state.batchItemsFetched && <div className="row password-list-row"> */}
                      {/* {this.state.batchItemMessage && <div className="col">{this.state.batchItemMessage}<Button variant="contained" className="no-students-button" primary={true} onClick={this.handleCloseDialog.bind(this, "createStudent")}>{...language.getText('okButtonLabel')}</Button></div>} */}
                      {/* {this.state.batchPdfUrl && <object width="100%" height="500" data={this.state.batchPdfUrl}></object>} */}
                      {/* </div>} */}
                      {
                      // if processing is  done lets close the dialog automatically and move to
                      this.state.batchItemsFetched && this.handleCloseDialog('createStudent')
                    }
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        ) : (this.state.editStudentActive === true ? <EditStudent showStudentList={this.showStudentList.bind(this)} studentCount={this.state.userListTableData.length} editStudentData={this.state.editStudentData} accessModel={this.state.isAccessModel} /> : <AddStudent showStudentList={this.showStudentList.bind(this)} studentCount={this.state.studentCount} />))}
      </MuiThemeProvider>
    );
  }
}

// StudentAnalytics.contextTypes = {
//   router: React.PropTypes.object.isRequired
// };
StudentAnalytics.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
// export default StudentAnalytics;
export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language', 'iesUserDetailsByCourse'),
  observer
)(injectIntl(StudentAnalytics));
