/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../constants';

export default class DlUserSchoolService extends BaseService {
  constructor() {
    super(constants.DL_USER_SCHOOL_REQUESTED, 'DlUserSchoolServiceStatus');
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    headerParams = {
      headers: {
        userid: event.userId,
        appversion: 'app'
      }
    };
    // console.log("check-user-school handleEvent: ", new Date(), new Date().getTime());
    return this.getClient('hapi').get('/check-user-school', headerParams);// courseboot/user/${event.userId}/referral`);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    // console.log("check-user-school handleResponse: ", new Date(), new Date().getTime());
    if (response.data && response.data.length > 0) {
      Framework.getStoreRegistry().getStore('dlUserSchool').set(response.data[0]);
      Framework.getEventManager().publish(
        constants.DL_USER_SCHOOL_FETCHED,
        response.data
      );
    } else {
      Framework.getEventManager().publish(constants.DL_USER_SCHOOL_UNSET, {
        message: 'Cannot Find School Mapped to the User'
      });
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    // Framework.getStoreRegistry().getStore('dlUserSchool').set({});
    Framework.getEventManager().publish(constants.DL_USER_SCHOOL_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
