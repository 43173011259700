import React from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import $ from 'jquery';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
class ChipComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
  }

  componentDidMount = function () {

  }

  hiddenCount = this.props.hiddenCountOfChip;

  showingMoreLess = false;

  chipLength = 0;

  componentDidUpdate = function (prevProps, prevState) {
    if (this.props.reload && this.props.chipData.length > 3) {
      var childLength = $('.chipContainer')[0].children.length;
      for (let i = 0; i < childLength; i++) {
        if ($('.chipContainer').height() > 80 && !this.state.showMore) {
          $('.moreLess')[0].style.display = 'block';
          $('.chipContainer')[0].style.height = '80px';
        }
        var lastChild = $('.chipContainer')[0].children[i];
        if ($('.chipContainer')[0].offsetTop + 80 > (lastChild.offsetTop + lastChild.offsetHeight)) {
          this.showingMoreLess = false;
          this.hiddenCount = 0;

          $('.moreLess')[0].style.display = 'none';
          if (this.state.showMore) {
            this.setState({
              showMore: false
            });
          }
        } else {
          this.hiddenCount += 1;
          $('.moreLess')[0].style.display = 'block';
        }
      }
      this.showingMoreLess = true;
      this.chipLength = childLength;
      if (this.hiddenCount > 0) {
        this.props.UpdatehiddenCountOfChip(this.hiddenCount);
      }
    } else {
      if ($('.chipContainer').height() > 80 && !this.state.showMore) {
        $('.moreLess')[0].style.display = 'block';
        $('.chipContainer')[0].style.height = '80px';
      }
      var childLength = $('.chipContainer')[0].children.length;
      var lastChild = $('.chipContainer')[0].children[childLength - 1];
      if ($('.chipContainer')[0]?.offsetTop + 80 > (lastChild?.offsetTop + lastChild?.offsetHeight)) {
        this.showingMoreLess = false;
        // this.hiddenCount = 0;
        // this.props.UpdatehiddenCountOfChip(this.hiddenCount)

        $('.moreLess')[0].style.display = 'none';
        if (this.state.showMore) {
          this.setState({
            showMore: false
          });
        }
      } else {
        if (!this.showingMoreLess) {
          this.showingMoreLess = true;
          this.chipLength = childLength;
          // if (this.props.hiddenCountOfChip > 0) {
          //   this.hiddenCount = this.props.hiddenCountOfChip;
          // } else {
          //   this.hiddenCount = this.hiddenCount + 1;
          // }
        }
        // if (this.chipLength < childLength) {
        //   this.hiddenCount = this.hiddenCount + 1;
        // } else if (this.chipLength > childLength) {
        //   for (let i = 0; i < childLength; i++) {
        //     var lastChild = $('.chipContainer')[0].children[i];
        //     if ($('.chipContainer')[0].offsetTop + 80 > (lastChild.offsetTop + lastChild.offsetHeight)) {
        //       this.hiddenCount = 0;
        //     } else {
        //       this.hiddenCount = this.hiddenCount + 1;
        //     }
        //   }
        // }
        for (let i = 0; i < childLength; i++) {
          var lastChild = $('.chipContainer')[0].children[i];
          if ($('.chipContainer')[0].offsetTop + 80 > (lastChild.offsetTop + lastChild.offsetHeight)) {
            this.hiddenCount = 0;
          } else {
            this.hiddenCount += 1;
          }
        }
        this.chipLength = childLength;
        $('.moreLess')[0].style.display = 'block';
      }
    }
  }

  UNSAFE_componentWillMount = function () {

  }

  delete = (label) => {
    this.props.handleChipDelete(label);
  }

  hideOrShow = () => {
    const flag = !this.state.showMore;
    this.setState({
      showMore: flag
    }, function () {
      if (this.state.showMore) {
        $('.chipContainer')[0].style.height = 'auto';
      } else {
        $('.chipContainer')[0].style.height = '80px';
      }
      this.props.renderFilterElements();
    });
  }

  getNameForChip = (data) => {
    const { language } = this.props;
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    if (data.filterName == 'readingLevels' && role !== constants.roleValue) {
      return `${language.getMessage('Reading_Level')} ${data.filterLabel}`;
    } if (data.filterName == 'defaultGrades') {
      return `Grade ${data.filterLabel}`;
    }
    return data.filterLabel;
  }

  renderChip(data, index) {
    return (
      <Chip
        onDelete={this.delete.bind(this, data)}
        style={{
          float: 'left', marginRight: '8px', marginBottom: '8px', backgroundColor: '#007fa3'
        }}// index > 0 ? {float:'left', margin: '0px 8px', backgroundColor: '#007fa3'} :
        label={this.getNameForChip(data)}
        classes={{ root: 'chipButton', label: 'chipLabel' }}
      />
    );
  }

  render = function () {
    let hiddenCount = '';
    if ((this.props.hiddenCountOfChip > 0) && (this.hiddenCount === 0)) {
      hiddenCount = this.props.hiddenCountOfChip;
      this.hiddenCount = this.props.hiddenCountOfChip;
    } else if (this.hiddenCount > 0) {
      hiddenCount = this.hiddenCount;
    }
    const { language } = this.props;
    const seeMore = `${language.getMessage('see_more')}(${hiddenCount})`;
    const showLess = language.getMessage('show_less');
    return (
      <div style={{ display: 'table-row' }}>
        <div id="chipContainer" className="chipContainer">
          {this.props.chipData.map(this.renderChip, this)}
        </div>
        <div className="moreLess" onClick={this.hideOrShow.bind(this)}>{this.state.showMore ? showLess : seeMore}</div>
        <span className="hidden" id="hiddenCountOfChip" value={hiddenCount}>{hiddenCount}</span>
      </div>
    );
  }
}

ChipComponent.propTypes = {
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language'),
  observer
)(injectIntl(ChipComponent));
