import env from '../env';

const zhhk = {
  'bookshelf.TITLE': '我的書架',
  'common.LOGOUT': '登出',
  'common.GO_HOME': '前往首頁',
  'common.PURCHASE': '購買',
  'courses.WELCOME': '{userName} 您好，歡迎來到您的 {courseName} 課程！',
  'courses.action.SHOW_ASSIGNMENTS': '顯示作業',
  'courses.toc.PUBLISH_SUCCESS': '成功！',
  'courses.toc.PUBLISH_MESSAGE': '學生將在下次開啟內容時看到您的變更。',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': '訂閱未找到',
  'errors.SUBSCRIPTION_GENERIC_DESC': '您的帳戶沒有存取該章目的許可權。如果您認為您錯誤接收了這條訊息，請聯絡培生技術支援團隊',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': '訂閱未找到',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': '您的訂閱不包括存取 eText，然而您可以選擇使用信用卡立即訂閱。',
  'foxit.ABOUT': '關於',
  'foxit.COPY_RIGHT': 'PDF 閱讀器由 Foxit 提供支援。Copyright (C) 2003-2019 by Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': '聯絡客服團隊',
  'menu.ABOUT': '關於',
  'menu.ACCESSIBILITY': '可及性',
  'menu.FOXIT': 'Foxit 提供支援',
  'menu.TERMS_OF_USE': '使用條款',
  'menu.PRIVACY_POLICY': '隱私政策。',
  'menu.SIGN_OUT': '登出',
  'menu.PRINT_PAGE': '列印頁面',
  'menu.GET_LOOSE_LEAF': '獲得活頁',
  'menu.AVAILABLE': '同樣可用',
  'menu.PRINT_VERSION': '想獲得紙本版本嗎？',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. 保留所有權利。',
  'menu.tooltip.SCHEDULE': '計劃安排',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': '{userName} 您好',
  'device.SWAPPED_ALREADY': '您本月已經切換過了',
  'device.CONTACT_SUPPORT': '請等到下個月，或者聯絡客服團隊獲得更多幫助。',
  'device.SWITCH_DEVICE': '切換到目前裝置？',
  'device.SWITCH_ONCE': '由於您每月只能進行一次切換，因此您無法撤銷此操作。',
  'device.SEAT_TAKEN': '該名額已被佔用',
  'device.DISCONNECT_DEVICE': '您只能在3台裝置上登入。要從此處繼續，請斷開另一台裝置。',
  'device.ALERT_TEXT': '請記住，您每月只能進行一次裝置切換。',
  'device.YOUR_MOJO': '您正在前往Mojo'
};

export default zhhk;
