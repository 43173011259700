/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Spark PROD environment overrides.
 */

const sparkProd = {
  ENVIRONMENT: 'prod',
  PARTNER_ENV: 'prod',
  PRODUCT: 'Spark',
  CSG_API_BASE_URL: 'https://content-service.prd-prsn.com/csg/api/v3',
  PAPER_API_BASE_URL: 'https://paperapi.openclass.com/nextext-api/api/platforms/bookshelf',
  PRISM_API_BASE_URL: 'https://prism.pearsoned.com/api',
  SPECTRUM_API_BASE_URL: 'https://spectrum.pearsoned.com/api/v1',
  SPECTRUM_API_V4_BASE_URL: 'https://spectrum.pearsoned.com/api/v4',
  STPAPER_API_BASE_URL: 'https://stpaperapi.prd-prsn.com/etext/v2',
  MARIN_API_BASE_URL: 'https://plus.pearson.com/marin/api/1.0',
  ETEXT_API_BASE_URL: 'https://etext.pearson.com/api/nextext-api/v1/api',
  IDC_API_BASE_URL: 'https://etext-instructor.pearson.com/idc-api/',
  HAPI_API_BASE_URL: `//spark.pearson.com${process.env.NODE_ENV !== 'production' ? ':3000' : ''}`,
  PSEUDO_LOCALE_CODE: null,
  CLIENT_ID: 'OYG7tQTgGn4qQybwvjxXdmt90DhRxRAM',
  ZEPPELIN_URL: 'https://product-transaction.openclass.com/products',
  CONSOLE_URL: 'https://console.pearson.com',
  TENANT_ID_ETEXT: 'beeea450-02f6-4f88-842e-cec9364453b7',
  TENANT_KEY_ETEXT: 'fbc0226b-3587-4760-9503-17ded7e2e3d8',
  TENANT_KEY_ETEXT_PDF: 'bb0a1d97-78af-44e4-bfe4-810c535a45c0',
  TERMS_OF_USE_URL: 'https://login.pearson.com/v1/piapi/policies/static/html/PearsonEULA.html',
  PRIVACY_URL: 'https://login.pearson.com/v1/piapi/policies/static/html/PearsonPrivacyPolicy.html',
  PROMO_CODE_API_BASE_URL: 'https://readerplus-resources.s3.amazonaws.com/etext/prd/promo.json',
  PXE_X_CALLER: 'a66ec434-c1d5-4ce6-864c-70121c11524c',
  CITE_X_CALLER: 'b45676f0-d77b-11e8-9f8b-f2801f1b9fd1',
  NEWRELIC_CONFIG_NAME: '/newrelic/newrelic.js',
  NEWRELIC_ENABLED: false,
  NEWRELIC_CONFIG: {
    accountId: 1322480,
    agentId: 274529857,
    applicationId: 91909111,
    licenseKey: 'd527718953',
    trustKey: 691807
  },
  SMARTTHINKING_CONFIG: {
    baseUrl: 'https://services.smarthinking.com/api/payment-mojo',
    secret: 'UK0DQCH1dUiZjwRH1r5GLpo7VPao2h9L'
  },
  LD_CLIENT_SIDE_ID: '5eda7256a9d7760a721a39b5',
  LD_ANONYMOUS_USER_ID: 'ise_anonymous_prod_user',
  LD_ANONYMOUS_HASH: '0e36d47a6e87fff7d7bfded6d7e5488de59a7249f806e5a6c3c7bf78f06580d1',
  BOOKSHELF_CONFIG: {
    checkout: 'https://www.pearson.com/en-us/pearsonplus/pricing.html',
    pmcHeader: 'https://www.pearson.com/en-us/pearsonplus.nav-content.standalone.html',
    purchase: 'https://www.pearson.com/en-us/pearsonplus/search.html',
    addon: 'https://pearson.pearson.com/store/_ui/addons/pmciamaddon/responsive/common/js/pmciamaddon.js',
    chatbot: {
      url: 'https://pearson.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseUrl: 'https://pearson.my.salesforce.com',
      supportUrl: 'https://support.pearson.com/getsupport',
      id: '00Db0000000e0e2',
      name: 'NAUS_HETS_DTC_CS_Bot',
      baseLiveAgentContentURL: 'https://c.la3-c1-fra.salesforceliveagent.com/content',
      deploymentId: '572b0000000ClKC',
      buttonId: '5735p000000sXvm',
      baseLiveAgentURL: 'https://d.la3-c1-fra.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5p000000sXuZEAU_17a41f20db5',
      isOfflineSupportEnabled: false
    },
    more: {
      MOJO_TERMS: 'https://www.pearson.com/en-us/pearsonplus/legal/terms-of-use.html',
      MOJO_PRIVACY: 'https://www.pearson.com/en-us/pearsonplus/legal/user-privacy-policy.html',
      MOJO_COOKIES: 'https://www.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_INFO: 'https://www.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_ACCESSIBILITY: 'https://www.pearson.com/en-us/pearsonplus/legal/accessibility.html',
      MOJO_NOTICE: 'https://www.pearson.com/en-us/pearsonplus/legal/patent-notice.html',
      MOJO_PEARSON_ACCESSIBILITY: 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
      MOJO_SUPPORT: 'https://www.pearson.com/en-us/pearsonplus/support.html'
    }
  },
  PRODUCT_TOKEN: '80153',
  PLUS_URL: 'https://plus.pearson.com',
  IES_BASE_URL: 'https://login.pearson.com/v1/piapi',
  CAAS_ID: '65cb47d5-5581-4b6e-9f8d-cff3681387cc',

  // Google Tag Manager Prod Configs
  GTM_CONTAINER_ID: 'GTM-N8JS47K',
  GTM_AUTH: '4JvvJLr21DMKWiH2XUsQ-A',
  GTM_PREVIEW: 'env-1'
};

export default sparkProd;
