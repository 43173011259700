/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class FetchAssetDetailsService extends BaseService {
  constructor() {
    super(constants.FETCH_ASSET_DETAILS_REQUESTED, 'FetchAssetDetailsServiceStatus');
  }

  handleEvent(eventName, event) {
    if (event.isClear) {
      Framework.getStoreRegistry().getStore('assetDetails').clear();
    }
    const headerParams = {
      headers: {
        next: event.next ? event.next : ''
      }
    };
    const assetIds = event.assetIds;
    return this.getClient('hapi').post(
      '/fetch-asset-details',
      { assetIds }, headerParams
    );
  }

  handleResponse(response, event) { // eslint-disable-line class-methods-use-this
    if (response?.data) {
      const {
        hasNext,
        next
      } = response.data;
      const previousAssetDetails = JSON.parse(JSON.stringify(Framework.getStoreRegistry().getStore('assetDetails')));
      let finalAssetDetails = response.data;
      if (previousAssetDetails?.assets?.length) {
        const totalAssets = previousAssetDetails.assets.concat(response.data?.assets);
        finalAssetDetails = {
          assets: totalAssets,
          hasNext,
          next,
          total: previousAssetDetails?.total
        };
      }
      Framework.getStoreRegistry().getStore('assetDetails').set(finalAssetDetails);
      if (hasNext) {
        Framework.getEventManager().publish(constants.FETCH_ASSET_DETAILS_REQUESTED, {
          assetIds: event?.assetIds,
          next
        });
        return;
      }
      Framework.getEventManager().publish(constants.FETCH_ASSET_DETAILS_SUCCESS, finalAssetDetails);
    } else {
      Framework.getEventManager().publish(constants.FETCH_ASSET_DETAILS_ERROR, {
        message: 'Cannot Find Asset Details'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.FETCH_ASSET_DETAILS_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
