import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const CustomMessage = ({
  show, message, timeout = 5000, handleClose, color = '#fc5b43'
}) => {
  if (show === true) {
    return (
      <Snackbar
        className="rectangle-message-snackbar pad0-marg0"
        open={show}
        autoHideDuration={timeout}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{ backgroundColor: color }}
      >
        <div className="rectangle-message" style={{ backgroundColor: color }}>
          <div className="container-fluid">
            <div className="row message-pad">
              <div className="col">
                <div>{message}</div>
              </div>
            </div>
          </div>
        </div>
      </Snackbar>
    );
  }
  return false;
};
export default CustomMessage;
