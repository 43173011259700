/* eslint-disable linebreak-style */
/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { injectIntl, FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

const onLinkClicked = () => {
  // Google Analytics code
};

const ShowFooterPage = inject('user', 'language', 'dlUser', 'dlUserSchool')(
  observer((props) => {
    const {
      language, dlUserSchool
    } = props;
    const isCWTUser = true;
    let termsLink = '';
    let cookiePolicyLink = '';
    let privacyLink = '';
    let copyrightLink = '';

    try {
      termsLink = dlUserSchool.links.terms;
      cookiePolicyLink = dlUserSchool.links.cookie;
      privacyLink = dlUserSchool.links.privacy;
      copyrightLink = 'https://www.pearson.com/ca/en/legal/copyright.html';
    } catch (e) {
      console.log(e);
    }
    const that = this;
    return (
      <div className="footerBarMain" id="footerBarMain" tabIndex="-1">
        <div className="footerBar" tabIndex="-1">
          <div
            onKeyPress={onLinkClicked.bind(this, termsLink, 'terms')}
            onKeyDown={(e) => { if (e.keyCode === 13) { onLinkClicked.call(that, termsLink, 'terms'); } }}
            className={`footerOption ${typeof termsLink === 'undefined' || termsLink === '' ? 'hideFooterOption' : ''}`}
          >
            <div hidden>
              <span id="new-window-0">Opens in a new window</span>
            </div>
            <a
              href={termsLink}
              onClick={onLinkClicked.bind(this, termsLink, 'terms')}
              id="terms"
              style={{ color: '#393c3d' }}
              rel="noopener noreferrer"
              target="_blank"
              aria-describedby="new-window-0"
              tabIndex="-1"
            >
              <FormattedMessage {...language.getText('terms')} />
            </a>
          </div>

          <div
            onKeyPress={onLinkClicked.bind(this, cookiePolicyLink, 'cookie')}
            onKeyDown={(e) => { if (e.keyCode === 13) { onLinkClicked.call(that, cookiePolicyLink, 'cookie'); } }}
            className={`footerOption ${typeof cookiePolicyLink === 'undefined' || cookiePolicyLink === '' ? 'hideFooterOption' : ''}`}
          >
            <div hidden>
              <span id="new-window-1">Opens in a new window</span>
            </div>
            <a
              href={cookiePolicyLink}
              onClick={onLinkClicked.bind(this, cookiePolicyLink, 'cookie')}
              id="cookiePolicy"
              style={{ color: '#393c3d' }}
              rel="noopener noreferrer"
              target="_blank"
              aria-describedby="new-window-1"
              tabIndex="-1"
            >
              <FormattedMessage {...language.getText('cookie_policy')} />
            </a>
          </div>

          {isCWTUser
            ? (
              <div
                onKeyPress={onLinkClicked.bind(this, copyrightLink, 'copyright')}
                onKeyDown={(e) => { if (e.keyCode === 13) { onLinkClicked.call(that, copyrightLink, 'copyright'); } }}
                className={`footerOption ${typeof copyrightLink === 'undefined' || copyrightLink === '' ? 'hideFooterOption' : ''}`}
              >
                <div hidden>
                  <span id="new-window-2">Opens in a new window</span>
                </div>
                <a
                  href={copyrightLink}
                  onClick={onLinkClicked.bind(this, copyrightLink, 'copyright')}
                  id="privacy"
                  style={{ color: '#393c3d' }}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-describedby="new-window-2"
                  tabIndex="-1"
                >
                  <FormattedMessage {...language.getText('copy_right')} />
                </a>
              </div>
            )
            : ''
          }
          <div
            onKeyPress={onLinkClicked.bind(this, privacyLink, 'privacy')}
            onKeyDown={(e) => { if (e.keyCode === 13) { onLinkClicked.call(that, privacyLink, 'privacy'); } }}
            className={`footerOption ${typeof privacyLink === 'undefined' || privacyLink === '' ? 'hideFooterOption' : ''}`}
          >
            <div hidden>
              <span id="new-window-2">Opens in a new window</span>
            </div>
            <a
              href={privacyLink}
              onClick={onLinkClicked.bind(this, privacyLink, 'privacy')}
              id="privacy"
              style={{ color: '#393c3d' }}
              rel="noopener noreferrer"
              target="_blank"
              aria-describedby="new-window-3"
              tabIndex="-1"
            >
              <FormattedMessage {...language.getText('privacy')} />
            </a>
          </div>
        </div>
        <div className="copyright" tabIndex="-1">
          © 2021
          {' '}
          <FormattedMessage {...language.getText('copyright')} />
        </div>
      </div>
    );
  })
);

function Footer(props) {
  const theme = useTheme();
  const matchesdownXL = useMediaQuery(theme.breakpoints.down('xl'));
  const matchesdownLG = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <ShowFooterPage
      theme={theme}
      matchesdownXL={matchesdownXL}
      matchesdownLG={matchesdownLG}
      pageName={props.pageName}
    />
  );
}

Footer.propTypes = {
  pageName: PropTypes.string.isRequired
};

export default injectIntl(Footer);
