import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import injectTapEventPlugin from 'react-tap-event-plugin';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MUIDataTable from 'mui-datatables';

import ReplyIcon from '@material-ui/icons/Reply';
import TagManager from 'react-gtm-module';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import StudentActivityAttemptsChart from './StudentActivityAttemptsChart';
import addIcon from '../../../assets/images/add.png';
import favIcon from '../../../assets/images/fav.png';
import favOneIcon from '../../../assets/images/fav1.png';
import CommonUtils from '../../../common/utils/CommonUtils';
import DataFormatter from '../../../common/utils/DataFormatter';
import { FILE_NOT_FOUND, DEFAULT_LANG } from '../../../common/constants';
import * as constants from '../../../common/constants';
import LoadingOverlay from '../../../common/components/loadingOverlay';

const lang = DataFormatter.getLanguage();
const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  },
  overrides: {
    MUIDataTable: {
      responsiveBase: {
        width: '100%'
      }
    }
  }
});

try {
  injectTapEventPlugin();
} catch (e) { }
const styles = {
  getIconStyle: {
    marginLeft: '16px',
    marginRight: '8px',
    color: '#5D5D5D'
  },
  tableWrapperStyle: {
    margin: 4,
    boxShadow:
      '0 2px 10px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)'
  },
  infostudent: {
    height: '4px'
  },
  strategyoverviewButton: {
    width: '262px',
    height: '50px',
    backgroundColor: '#00a69f',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)'
  },
  labelStyle: {
    width: '158px',
    height: '16px',
    fontSize: '18px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.89',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff'
  },

  tableusername: {
    height: ' 18px',
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 0.17,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#007fa3'
  },
  tableWidth: {
    width: '20%'
  },
  readingLevelWidth: {
    width: '15%'
  },
  dateSentWidth: {
    width: '13%'
  },
  dateCompletedWidth: {
    width: '13%'
  },
  pageReadWidth: {
    width: '10%'
  },
  audioUsedWidth: {
    width: '8%'
  },
  favouriteWidth: {
    width: '10%'
  },
  selfallocatedWidth: {
    width: '11%'
  },
  dot: {
    height: '25px',
    width: '25px',
    backgroundColor: ' #bbb',
    borderRadius: '50%',
    display: 'inline-block'
  },
  tablerowstyle: {
    textAlign: 'left'
  },
  tableSelfAllocationIcon: {
    width: '28px',
    height: '28px',
    backgroundColor: '#ed8000',
    borderRadius: '50%'
  },
  tableSelfAllocationIconInactive: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    backgroundColor: '#d3d3d3'

  },
  pagesRead: {
    fontSize: '13px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.23',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  audioUsedStyle: {
    fontSize: '13px',
    lineHeight: '1.23',
    fontFamily: 'Open Sans',
    color: 'rgba(0, 0, 0, .87)',
    fontWeight: '600',
    marginLeft: '5px'
  },
  month: {
    width: '24px',
    height: '12px',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.2',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase'

  },
  date: {
    width: '20px',
    height: '16px',
    fontSize: '16px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '0.1',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)'
  }
};

let TABLE_COLUMNS = new Array();


class StudentAnalyticsOverView extends React.Component {
  constructor() {
    super();
    this.bus = {};
    this.prepareUserData = this.prepareUserData.bind(this);
    this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
    this.viewAllBooks = this.viewAllBooks.bind(this);
    this.viewAvailableBooks = this.viewAvailableBooks.bind(this);
    this.handleCellClick = this.handleCellClick.bind(this);
    this.removeSelectedBooks = this.removeSelectedBooks.bind(this);
    this.getTableValues = this.getTableValues.bind(this);
    this.booksToBeRemoved = [];
    this.attemptActivityData = this.attemptActivityData.bind(this);
    this.assetDetails = this.assetDetails.bind(this);
    this.getGraphData = this.getGraphData.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.count = {};
    let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
    appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
    let activityUrl = appConfig.activities.skillurl.replace('.json', '');
    if (lang && lang.split('-')[0]) {
      activityUrl = `${activityUrl}-${lang.split('-')[0]}.json`;
    }
    // AppActions.getChartYaxis(activityUrl);
    Framework.getEventManager().publish(constants.CHART_AXIS_FETCH_REQUESTED,
      {
        Url: activityUrl
      });
  }

  state = {
    userListTableData: [],
    booksUnread: 0,
    booksReadMonth: 0,
    booksReadyear: 0,
    viewAll: true,
    viewAvailable: false,
    isRowsSelected: false,
    removedBooksDetail: {
      removedBooksCount: 0,
      showMessage: false
    },
    data: {},
    uniqueSkills: [],
    chartYAxis: {},
    noActivityAttempted: false,
    productListData: [],
    loadingChart: true,
    isAttemptDataRequested: false,
    showLoader: false
  };


  componentDidMount = function () {
    // AppStore.on(AppConstants.EventTypes.USER_ACTIVITY_ATTEMPT, this.attemptActivityData)
    Framework.getEventManager().on(constants.USER_ACTIVITY_ATTEMPT_SUCCESS, (attemptData) => {
      this.setState({ isAttemptDataRequested: false });
      this.attemptActivityData(attemptData);
    });
    // AppStore.on(AppConstants.EventTypes.USER_PRODUCTS_FETCHED_SUCCESS,this.prepareUserData);
    Framework.getEventManager().on(constants.USER_PRODUCTS_FETCHED_SUCCESS, (respData) => {
      this.setState({
        productListData: respData
      }, () => this.prepareUserData());
      // TODO - Here we have to set the result in state variable and then we have to make func call
    });
    // AppStore.on(AppConstants.EventTypes.RETURN_PRODUCT_COMPLETED, this.booksRemoved)
    Framework.getEventManager().on(constants.RETURN_BOOK_SUCCESS, (data) => {
      this.booksRemoved();
    });
    // AppStore.on(AppConstants.EventTypes.ASSET_DETAILS, this.assetDetails)
    Framework.getEventManager().on(constants.FETCH_ASSET_DETAILS_SUCCESS, (assetDet) => {
      this.assetDetails(assetDet);
    });
    // AppStore.on(AppConstants.EventTypes.CHART_FETCHED_SUCCESS, this.getChartYLabel);
    Framework.getEventManager().on(constants.CHART_AXIS_FETCH_SUCCESS, (chartYLabel) => {
      this.getChartYLabel(chartYLabel);
    });
    Framework.getEventManager().on(constants.FETCH_STUDENT_REPORT_SUCCESS, () => {
      this.setState({ showLoader: false });
    });
    const gtmData = CommonUtils.getDefaultGAPageView();
    gtmData.dataLayer.page_title = 'Student Analytics';
    TagManager.dataLayer(gtmData);
  };

  UNSAFE_componentWillMount = function () {
    const { language, intl } = this.props;
    TABLE_COLUMNS = [
      {
        name: 'title',
        label: intl.formatMessage(language.getText('Book_Title')),
        options: {
          sort: true
        }
      },
      {
        name: 'readingLevels',
        label: (
          <div>
            <p>{intl.formatMessage(language.getText('Guided'))}</p>
            <p>{intl.formatMessage(language.getText('Reading_Level_Short'))}</p>
          </div>
        ),
        options: {
          sort: true
        }
        // style: styles.readingLevelWidth
      },
      {
        name: 'datesent',
        // style: styles.dateSentWidth,
        label: (
          <div>
            <p>{intl.formatMessage(language.getText('Date_Sent'))}</p>
          </div>
        ),
        options: {
          sort: true
        }
      },
      {
        name: 'datecompleted',
        // style: styles.dateCompletedWidth,
        label: (
          <div>
            <p>{intl.formatMessage(language.getText('Date_text'))}</p>
            <p>{intl.formatMessage(language.getText('Complete'))}</p>
          </div>
        ),
        options: {
          sort: true
        }
      },
      {
        name: 'pageread',
        // style: styles.pageReadWidth,
        label: (
          <div>
            <p>{intl.formatMessage(language.getText('Pages'))}</p>
            <p>{intl.formatMessage(language.getText('Read'))}</p>
          </div>
        )
      },
      {
        name: 'TimeSpent',
        label: (
          <div>
            {language.getMessage('time_spent')}
          </div>
        )
      },
      {
        name: 'audioused',
        // style: styles.audioUsedWidth,
        label: (
          <div>
            <p>{intl.formatMessage(language.getText('Audio'))}</p>
            <p>{intl.formatMessage(language.getText('Used'))}</p>
          </div>
        )
      },
      {
        name: 'favourite',
        // style: styles.favouriteWidth,
        label: (
          <div>
            <p>{intl.formatMessage(language.getText('Favorite'))}</p>
          </div>
        )
      },
      {
        name: 'selfallocated',
        // style: styles.selfallocatedWidth,
        label: (
          <div>
            <p>{intl.formatMessage(language.getText('Self_text'))}</p>
            <p>{intl.formatMessage(language.getText('Allocated'))}</p>
          </div>
        )
      }
    ];
    this.updateTable();
    this.getGraphData();
  };

  componentDidUpdate = function () {
    if (this.state.viewAvailable) {
      if (this.state.booksCount === this.state.availableBooksTableData.length) {
        const selectallcb = document.getElementsByName('selectallcb')[0];
        try {
          if (selectallcb.checked === false) {
            selectallcb.click();
          }
        } catch (e) { }
      }
    }
  }

  componentWillUnmount() {
    delete this.bus[constants.RETURN_BOOK_SUCCESS, constants.RETURN_BOOK_SUCCESS];
  }

  viewAllBooks = function () {
    this.setState({
      viewAll: true,
      viewAvailable: false
    });
  };

  viewAvailableBooks = function () {
    this.setState({
      viewAll: false,
      viewAvailable: true
    });
  };

  booksRemoved = (removedResp) => {
    this.booksRemovedFromStudent = true;
    // this.updateTable();
    this.prepareUserData();
  }

  getChartYLabel = (chartYLabel) => {
    const chartLabel = chartYLabel;
    if (chartLabel === FILE_NOT_FOUND) {
      let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
      appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
      let activityUrl = appConfig.activities.skillurl.replace('.json', '');
      if (lang && lang.split('-')[0]) {
        activityUrl = `${activityUrl}-${DEFAULT_LANG}.json`;
      }
      // AppActions.getChartYaxis(activityUrl);
      Framework.getEventManager().publish(constants.CHART_AXIS_FETCH_REQUESTED,
        {
          Url: activityUrl
        });
    } else {
      this.setState({ chartYAxis: chartLabel });
    }
  }

  removeSelectedBooks = function () {
    const { language, intl } = this.props;
    localStorage.setItem('userIDRemoveBooks', this.props.userId);
    const booksToRemove = this.booksToBeRemoved.map(function (id) {
      return {
        productId: id,
        userId: this.props.userId
      };
    }, this);


    Framework.getEventManager().publish(constants.RETURN_BOOK_REQUESTED,
      booksToRemove);

    const gtmData = {
      dataLayer: {
        event: 'remove_book',
        event_category: intl.formatMessage(language.getText('remove_book')),
        event_action: intl.formatMessage(language.getText('remove_book_clicked')),
        event_label: null,
        book_detail: `Number of books removed: ${booksToRemove.length}`,
        product_id: DataFormatter.getObjectInStorage('courseSelected') ? DataFormatter.getObjectInStorage('courseSelected').products[0].id : null,
        transaction_local_dt: CommonUtils.getLocalIsoTime()
      }
    };
    TagManager.dataLayer(gtmData);
  }

  updateTable = function () {
    // AppActions.fetchProductByUserId(this.props.userId,true);
    Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
      {
        userId: this.props.userId,
        includeReturned: true
      });
  };

  getGraphData = function () {
    const allAttempts = [];
    // AppActions.fetchActivityAttemptsByUserId(this.props.userId, null, allAttempts)
    Framework.getEventManager().publish(constants.USER_ACTIVITY_ATTEMPT_REQUESTED,
      {
        userId: this.props.userId,
        next: '',
        allAttempts
      });
  }

  attemptActivityData = function (attemptData) {
    const activityAttemptsData = attemptData;// AppStore.activityAttemptsData

    if (typeof activityAttemptsData === 'undefined' || (activityAttemptsData && activityAttemptsData.statusCode === 400)) {
      this.setState({ noActivityAttempted: true });
      return;
    }
    const activityAttemptsAssetIds = [];
    activityAttemptsData.map(attempt => activityAttemptsAssetIds.push(attempt.assetId));

    // each asset count
    const assetCount = {};
    activityAttemptsAssetIds.forEach((attempt) => {
      assetCount[attempt] = (assetCount[attempt] || 0) + 1;
    });

    // asset's max value
    const assetMaxValue = {};
    for (let i = 0; i < activityAttemptsData.length; i++) {
      const asset = activityAttemptsData[i].assetId;
      if (assetMaxValue.hasOwnProperty(asset)) {
        if (assetMaxValue[asset][0] < activityAttemptsData[i].value) {
          assetMaxValue[asset][0] = activityAttemptsData[i].value;
          assetMaxValue[asset][1] = activityAttemptsData[i].updatedAt;
        }
      } else {
        assetMaxValue[asset] = [];
        assetMaxValue[asset].push(activityAttemptsData[i].value, activityAttemptsData[i].updatedAt);
      }
    }

    // this.count based on value(score) - refer jira CGSER-2840
    // this logic is written for max three attempt as per jira
    for (const key in assetMaxValue) {
      if (parseInt(assetMaxValue[key][0]) == 100) {
        this.count[key] = [];
        this.count[key].push(1, assetMaxValue[key][1]);
      } else if (parseInt(assetMaxValue[key]) >= 50 && parseInt(assetMaxValue[key]) < 100) {
        this.count[key] = [];
        this.count[key].push(2, assetMaxValue[key][1]);
      } else if (parseInt(assetMaxValue[key]) >= 33 && parseInt(assetMaxValue[key]) < 50) {
        this.count[key] = [];
        this.count[key].push(3, assetMaxValue[key][1]);
      } else {
        this.count[key] = [];
        this.count[key].push('', assetMaxValue[key][1]);
      }
    }

    // TODO: Need to update this logic when completionStatus is introduced
    const completedActivityAssetIds = Object.keys(assetCount);

    if (this.state.isAttemptDataRequested === false) {
      this.setState({ isAttemptDataRequested: true });
      // AppActions.fetchAssetDetails(completedActivityAssetIds)
      Framework.getEventManager().publish(constants.FETCH_ASSET_DETAILS_REQUESTED, {
        assetIds: completedActivityAssetIds,
        isClear: true
      });
    }
  }

  assetDetails = function (assetDet) {
    // let assetDetailsData = AppStore.assetDetailsData
    const assetDetailsData = assetDet;
    // to create all the skills available in assetDetailsData obj
    const allSkills = [];
    const assets = [];
    for (let i = 0; i < assetDetailsData.assets.length; i++) {
      if (assetDetailsData.assets[i].skills) {
        assets.push(assetDetailsData.assets[i]);
        allSkills.push(assetDetailsData.assets[i].skills);
      }
    }

    if (allSkills.length === 0) {
      this.setState({ noActivityAttempted: true });
    }

    // to create unique skills array and delete duplicate element
    let uniqueSkills = [...new Set(allSkills)];

    // asset details required to plot graph - array of obj
    const requiredAssetDetails = [];
    for (let i = 0; i < assets.length; i++) {
      requiredAssetDetails[i] = {};
      requiredAssetDetails[i].assetId = assets[i].id;
      requiredAssetDetails[i].skills = assets[i].skills;
      requiredAssetDetails[i].length = 1;
    }

    // injecting count from this.count to requiredAssetDetails
    requiredAssetDetails.map((asset) => {
      Object.keys(this.count).forEach((key) => {
        if (key == asset.assetId) {
          asset.count = this.count[key][0];
          asset.date = this.count[key][1];
          asset.color = asset.count == 1 ? '#84bd00' : asset.count == 2 ? '#ffb81c' : asset.count == 3 ? '#ea7600' : 'lightgray';
        }
      });
    });

    // sort requiredAssetDetails based on date
    requiredAssetDetails.sort((a, b) => new Date(a.date) - new Date(b.date));

    // to create an array of objects with elements in uniqueSkills as keys
    // and that skill's assetid and count as values from requiredAssetDetails
    const dataObj = {};
    let skillKeys = [];
    requiredAssetDetails.forEach((asset) => {
      skillKeys = [...new Set(skillKeys.concat(asset.skills))];
    });

    let temp = [];
    skillKeys.forEach((sKey) => {
      temp = [];
      requiredAssetDetails.forEach((a) => {
        if (a.skills.includes(sKey)) {
          temp.push({
            count: a.count,
            length: 1,
            skill: sKey,
            color: a.count == 1 ? '#84bd00' : a.count == 2 ? '#ffb81c' : a.count == 3 ? '#ea7600' : 'lightgray'
          });
        }
      });
      dataObj[sKey] = temp;
    });

    let tempLen = 0;
    Object.keys(dataObj).forEach((key) => {
      if (tempLen > dataObj[key].length) {
        tempLen = tempLen;
      } else {
        tempLen = dataObj[key].length;
      }
    });

    const data = {};
    for (let i = 0; i < tempLen; i++) {
      data[`data${i}`] = [];
      Object.keys(dataObj).forEach((key) => {
        if (dataObj[key][i]) data[`data${i}`].push(dataObj[key][i]);
      });
    }
    const { chartYAxis } = this.state;
    uniqueSkills = chartYAxis.skills.map(skill => skill.value);
    Object.keys(data).forEach((key) => {
      data[key] = uniqueSkills.map(skill => data[key].find(e => e.skill === skill) || { skill });
    });

    this.setState({ data });
    this.setState({ uniqueSkills });
  }

  prepareUserData = function () {
    const { language, intl } = this.props;
    const userListTableData = [];
    const availableBooksTableData = [];
    const booksRead = [];
    const booksReadyear = [];
    const booksReadMonth = [];
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const userListDataArray = this.state.productListData;
    userListDataArray.map((userdata, index) => {
      const userObject = {};
      if (userdata.productId) {
        userObject.title = (<div className="analyticsBookTitle">{userdata.productId.title}</div>);
        userObject.titleforSort = userdata.productId.title;
        userObject.productId = userdata.productId.id;
        if (userdata.productId && userdata.productId.metadata) {
          if (typeof userdata.productId.metadata.readingLevels !== 'undefined') {
            const readingLevel = userdata.productId.metadata.readingLevels[0];
            const readingLevelColor = constants.readingLevelsColorCodes.find(colorCode => colorCode.key == readingLevel);
            let readingLevelColorCode = '#A9A9A9';
            if (typeof (readingLevelColor) !== 'undefined' && readingLevelColor !== null) {
              readingLevelColorCode = readingLevelColor.value;
            }
            userObject.readingLevelsforSort = readingLevel;
            userObject.readingLevels = (
              <div style={{
                display: 'inline-block',
                backgroundColor: readingLevelColorCode,
                textAlign: 'center',
                borderRadius: '50%',
                height: '25px',
                width: '25px',
                marginRight: '10px',
                lineHeight: '2.0',
                color: '#ffff'
              }}
              >
                {readingLevel}
              </div>
            );
          }
        }
        if (userdata.readCompletionDate) {
          userObject.datecompletedforSort = userdata.readCompletionDate;
          booksRead.push(userdata);
          const date = new Date();
          const y = date.getFullYear();
          const m = date.getMonth();
          const firstDayofMonth = Math.floor(new Date(y, m, 1).getTime() / 1000);
          const lastDayofMonth = Math.floor(new Date(y, m + 1, 0).setHours(23, 59, 59, 999) / 1000);
          if (m < 8) {
            var firstDayofYear = Math.floor(new Date(y - 1, 8, 1).getTime() / 1000);
            var lastDayofYear = Math.floor(new Date(y, 7, 31).setHours(23, 59, 59, 999) / 1000);
          } else {
            var firstDayofYear = Math.floor(new Date(y, 8, 1).getTime() / 1000);
            var lastDayofYear = Math.floor(new Date(y + 1, 7, 31).setHours(23, 59, 59, 999) / 1000);
          }

          if (userdata.readCompletionDate >= firstDayofMonth && userdata.readCompletionDate <= lastDayofMonth) {
            booksReadMonth.push(userdata.readCompletionDate);
          }

          if (userdata.readCompletionDate >= firstDayofYear && userdata.readCompletionDate <= lastDayofYear) {
            booksReadyear.push(userdata.readCompletionDate);
          }
        } else {
          userObject.datecompletedforSort = 0;
        }

        const dateAssigned = new Date(userdata.assignedDate * 1000);
        const monthAssigned = dateAssigned.getMonth();
        const dateAssignedFormatted = (`0${dateAssigned.getDate()}`).slice(-2);
        userObject.datesentforSort = userdata.assignedDate;
        userObject.datesent = (
          <div>
            <span style={styles.month}><p>{monthNames[monthAssigned]}</p></span>
            <span style={styles.date}><p>{dateAssignedFormatted}</p></span>
          </div>
        );
        var readCompletionDate;
        if (userdata.readCompletionDate) {
          const dateParsingreadCompletion = new Date(userdata.readCompletionDate * 1000);
          var monthCompletion = dateParsingreadCompletion.getMonth();
          var readCompletionDate = (`0${dateParsingreadCompletion.getDate()}`).slice(-2);
        }

        userObject.datecompleted = (
          <div>
            <span style={styles.month}><p>{monthNames[monthCompletion]}</p></span>
            <span style={styles.date}><p>{readCompletionDate}</p></span>
          </div>
        );
        const pagesReadData = `${userdata.pagesRead.length}/${userdata.totalPages ? userdata.totalPages : 0}`;
        userObject.pagereadforSort = userdata.pagesRead.length;
        userObject.pageread = (
          <span style={styles.pagesRead}><p>{pagesReadData}</p></span>
        );
        // getTimeSpent On EachBook
        userObject.TimeSpentforSort = userdata.totalTimeSpentInBook;
        userObject.TimeSpent = (
          <span style={styles.pagesRead}><p>{userdata.totalTimeSpentInBook}</p></span>
        );
        let audioUsed;
        if (userdata && userdata.productId && userdata.productId.books.length > 0
          && userdata.productId.books[0].metadata && userdata.productId.books[0].metadata.type === 'epub') {
          if (userdata.hasOwnProperty('isAudioUsed')) {
            if (userdata.isAudioUsed) {
              audioUsed = intl.formatMessage(language.getText('yes'));
            } else if (userdata.productId.books && userdata.productId.books[0].metadata.hasOwnProperty('mediaOverlayActiveClass')) {
              audioUsed = intl.formatMessage(language.getText('no'));
            } else {
              audioUsed = '-';
            }
          } else {
            audioUsed = '-';
          }
        } else {
          audioUsed = '-';
        }
        userObject.audiousedforSort = audioUsed;
        userObject.audioused = (
          <span style={styles.audioUsedStyle}><p>{audioUsed}</p></span>
        );
        userObject.favouriteforSort = userdata.isLiked === true;
        userObject.favourite = (
          <div>
            <img width="40px" height="40px" alt={(userdata.isLiked === true ? intl.formatMessage(language.getText('favourite')) : intl.formatMessage(language.getText('not_favourite')))} src={(userdata.isLiked === true ? favOneIcon : favIcon)} />


          </div>
        );
        userObject.selfallocatedforSort = userdata.assignmentType == 'self';
        userObject.selfallocated = (
          <div>
            <img style={(userdata.assignmentType == 'self' ? styles.tableSelfAllocationIcon : styles.tableSelfAllocationIconInactive)} src={addIcon} />

          </div>
        );
        userObject.unread = 20;
        userListTableData.push(userObject);
        if (!userdata.isReturned) {
          availableBooksTableData.push(userObject);
        }
      }
    });
    const unReturnBooks = booksRead.filter(books => !books.isReturned);
    const booksUnread = Number(availableBooksTableData.length - unReturnBooks.length);
    let removedBooksDetail = { removedBooksCount: 0, showMessage: false };
    let removedBooksFlow = false;
    if (this.booksRemovedFromStudent) {
      const removedBooksCount = this.booksToBeRemoved.length;
      this.booksToBeRemoved = [];
      this.booksRemovedFromStudent = false;
      removedBooksFlow = true;
      removedBooksDetail = {
        removedBooksCount,
        showMessage: true
      };
    }
    this.setState({
      userListTableData,
      availableBooksTableData,
      booksReadMonth: booksReadMonth.length,
      booksReadyear: booksReadyear.length,
      booksUnread,
      removedBooksDetail,
      removedBooksFlow,
      isRowsSelected: false,
      selectedRowIndex: []
    });
  };

  handleSortOrderChange(key, order) {
    if (this.state.viewAvailable) {
      this.setState({
        availableBooksTableData: [].concat(this.state.availableBooksTableData.sort(this.sortValues(`${key}forSort`, order)))
      });
    } else {
      this.setState({
        userListTableData: [].concat(this.state.userListTableData.sort(this.sortValues(`${key}forSort`, order)))
      });
    }
  }

  sortValues = function (key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ? (comparison * -1) : comparison
      );
    };
  };

  renderChart() {
    const configData = DataFormatter.getObjectInStorage('configData');
    const showGraph = configData.studentAnalytics;
    const dataObjKeys = Object.keys(this.state.data).length;
    if (!this.state.noActivityAttempted) {
      if (dataObjKeys > 0 && this.state.uniqueSkills.length > 0 && showGraph) {
        return <StudentActivityAttemptsChart data={this.state.data} uniqueSkills={this.state.uniqueSkills} yLabels={this.state.chartYAxis} toggleChartLoader={this.toggleChartLoader} />;
      }
    } else {
      return <StudentActivityAttemptsChart noActivityAttempted={this.state.noActivityAttempted} toggleChartLoader={this.toggleChartLoader} />;
    }
  }

  renderstats = function () {
    const { language, intl } = this.props;
    const configData = DataFormatter.getObjectInStorage('configData');
    const showGraph = configData.studentAnalytics;
    return (
      <div>
        <div className="student-info-container">
          <div><p className="studentName">{this.props.fullname}</p></div>
          <div />
        </div>
        <div className="info-box-overview-container">
          <div className="info-box-overview">
            <div className="info-box-overview-content">
              <span className="info-box-overview-text"><p>{intl.formatMessage(language.getText('unread_in'))}</p></span>
              <span className="info-box-overview-text-thin">
                <p>
                  {intl.formatMessage(language.getText('BookBox'))}
                  {' '}
                </p>
              </span>
            </div>
            <div className="info-box-overview-content-number">
              <span className="info-box-overview-number">{this.state.booksUnread}</span>
            </div>
          </div>

          <div className="info-box-overview">
            <div className="info-box-overview-content">
              <span className="info-box-overview-text">
                <p>
                  {intl.formatMessage(language.getText('Books_Read'))}
                  {' '}
                </p>
              </span>
              <span className="info-box-overview-text-thin">
                <p>{intl.formatMessage(language.getText('This_Month'))}</p>
              </span>
            </div>
            <div className="info-box-overview-content-number">
              <span className="info-box-overview-number">{this.state.booksReadMonth}</span>
            </div>
          </div>
          <div className="info-box-overview">
            <div className="info-box-overview-content">
              <span className="info-box-overview-text">
                <p>
                  {intl.formatMessage(language.getText('Books_Read'))}
                  {' '}
                  {' '}
                </p>
              </span>
              <span className="info-box-overview-text-thin">
                <p>{intl.formatMessage(language.getText('This_Year'))}</p>
              </span>
            </div>
            <div className="info-box-overview-content-number">
              <span className="info-box-overview-number">{this.state.booksReadyear}</span>
            </div>
          </div>
        </div>
        {this.state.loadingChart ? <div className="loading-chart">{intl.formatMessage(language.getText('loading_activity_graph'))}</div> : ''}
        {this.renderChart()}
      </div>
    );
  };

  handleCellClick = function (currentRowsSelected, selectedRows, rowsSelected) {
    let isRowsSelected = false;
    let booksCount = '';
    let selectedRowIndex = [];
    this.booksToBeRemoved = [];
    if (selectedRows === 'all' || selectedRows === 'All') {
      isRowsSelected = true;
      booksCount = this.state.availableBooksTableData.length;
      this.state.availableBooksTableData.map(function (data, index) {
        this.booksToBeRemoved.push(data.productId);
        selectedRowIndex.push(index);
      }, this);
    } else if (selectedRows === 'none' || selectedRows === 'None') {
      booksCount = 0;
      selectedRowIndex = undefined;
    } else {
      if (selectedRows.length > 0) {
        isRowsSelected = true;
        booksCount = selectedRows.length;
      }
      selectedRows.map(function ({ index }) {
        try { this.booksToBeRemoved.push(this.state.availableBooksTableData[index].productId); } catch (e) { }
      }, this);
      selectedRowIndex = [...selectedRows];
    }
    this.setState({
      isRowsSelected,
      booksCount,
      selectedRowIndex
    });
  }

  handleRequestClose = () => {
    this.setState({
      removedBooksDetail: {
        removedBooksCount: 0,
        showMessage: false
      },
      removedBooksFlow: false
    });
  };

  getTableValues = function () {
    const { language, intl } = this.props;
    let viewAvailableStyle = 'getBookOption3 inActiveTab';
    let viewAllStyle = 'getBookOption2 activeTab';
    let iconBGColor = '#E5E5E5';
    let labelColor = '#5D5D5D';
    let removeButtonBg = 'removeButtonBg';
    let booksCount = '';
    const iconStyle = styles.getIconStyle;
    let removeBookButton = '';
    let tableData = this.state.userListTableData;
    let removebuttonDisable = true;
    let removedMessage = '';
    let selectedRow = [];
    if (this.state.viewAvailable) {
      viewAvailableStyle = 'getBookOption3 activeTab';
      viewAllStyle = 'getBookOption2 inActiveTab';

      if (this.state.availableBooksTableData.length > 0) {
        selectedRow = this.state.selectedRowIndex;
      }

      tableData = this.state.availableBooksTableData;
      if (this.state.isRowsSelected) {
        iconBGColor = '#00a69f';
        labelColor = '#fff';
        removeButtonBg = 'removeButtonSelectedBg';
        iconStyle.color = 'white';
        removebuttonDisable = false;
        const bookSelection = this.state.booksCount > 1 ? intl.formatMessage(language.getText('books_selected')) : intl.formatMessage(language.getText('book_selected'));
        booksCount = (
          <div className="getBookOption1">
            <span style={{ fontWeight: 'bold' }} id="bookCount">{this.state.booksCount}</span>
            <span>
              {' '}
              {bookSelection}
            </span>
          </div>
        );
      }
      if (this.state.removedBooksFlow) {
        if (this.state.removedBooksDetail.showMessage) {
          removedMessage = this.state.removedBooksDetail.removedBooksCount > 1 ? `${this.state.removedBooksDetail.removedBooksCount} ${intl.formatMessage(language.getText('books_removed_pural'))} ` : intl.formatMessage(language.getText('book_removed_singular'));
        }
      }
      removeBookButton = (
        <div className="getBookActions">
          <div className="cancelButton">
            <Button
              variant="contained"
              color="primary"
              labelPosition="after"
              onClick={this.removeSelectedBooks.bind(this)}
              disabled={removebuttonDisable}
              startIcon={<ReplyIcon className="reply-icon" />}
              backgroundColor={iconBGColor}
              primary
              classes={{ root: removeButtonBg }}
            >
              {intl.formatMessage(language.getText('remove_books'))}
            </Button>
          </div>
        </div>
      );
    }
    return {
      viewAvailableStyle,
      viewAllStyle,
      selectedRow,
      booksCount,
      removeBookButton,
      tableData,
      removedMessage
    };
  }

  toggleChartLoader = () => {
    this.setState({
      loadingChart: false
    });
  }

  fetchStudentLevelReport = (event) => {
    const selectedFormat = event.target.value;
    const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
    const schoolList = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school');
    const saCode = schoolList && schoolList.length && schoolList[0]?.saId;
    const studentId = this.props.userId;
    Framework.getEventManager().publish(constants.FETCH_STUDENT_REPORT_REQUESTED,
      {
        reportFormat: selectedFormat,
        SACode: saCode,
        userId: studentId,
        createdBy: userId
      });
    this.setState({
      showLoader: true
    });
  }

  render() {
    const { language, intl } = this.props;
    const { showLoader, selectedRowIndex } = this.state;
    const rowsSelected = [];
    const tableValue = this.getTableValues();
    if(selectedRowIndex?.length) {
      selectedRowIndex.forEach((element) => {
        rowsSelected.push(element?.index);
      });
    }
    let props = {
      columns: TABLE_COLUMNS,
      data: (tableValue.tableData.length > 0) ? tableValue.tableData : [['']],
      options: {
        filterType: 'checkbox',
        download: false,
        expandableRowsHeader: false,
        filter: false,
        responsive: 'standard',
        rowHover: false,
        rowsPerPage: 100,
        rowsPerPageOptions: {},
        paging: false,
        search: false,
        print: false,
        viewColumns: false,
        pagination: false,
        selectToolbarPlacement: 'none',
        rowsSelected,
        onColumnSortChange: this.handleSortOrderChange
      }
    };
    if (this.state.viewAll) {
      props = {
        ...props,
        options: {
          ...props.options,
          selectableRowsHeader: false,
          selectableRows: 'none',
          selectableRowsOnClick: false
        }
      };
    }
    if (this.state.viewAvailable) {
      const emptyListProps = {
        selectableRowsHeader: false,
        selectableRows: 'none',
        selectableRowsOnClick: false
      };
      const listProps = {
        selectableRowsHeader: true,
        selectableRows: 'multiple',
        selectableRowsOnClick: true
      };
      const optionProps = (tableValue.tableData.length > 0) ? listProps : emptyListProps;
      props = {
        ...props,
        options: {
          ...props.options,
          onRowSelectionChange: this.handleCellClick,
          ...optionProps
        }
      };
    }


    return (
      <MuiThemeProvider theme={muiTheme}>
        <LoadingOverlay isLoading={showLoader} />
        <div id="studentAnalyticContainer" className="studentAnalyticcontainer">
          <div className="statisticsContainer">{this.renderstats()}</div>
          <div className="dataTableContainer">
            <div className="dataTableHeader">
              <div className="getBookOptions">
                {tableValue.booksCount}
                <div id="viewOptions" style={{ display: 'block' }}>
                  <div
                    className={tableValue.viewAllStyle}
                    tabIndex="0"
                    onClick={this.viewAllBooks}
                    onKeyDown={(e) => {
                      if (e.key == 'Enter') {
                        this.viewAllBooks();
                      }
                    }}
                  >
                    {intl.formatMessage(language.getText('view_all'))}
                  </div>
                  <div
                    className={tableValue.viewAvailableStyle}
                    tabIndex="0"
                    onClick={this.viewAvailableBooks}
                    onKeyDown={(e) => {
                      if (e.key == 'Enter') {
                        this.viewAvailableBooks();
                      }
                    }}
                  >
                    {intl.formatMessage(language.getText('manage_bookbox'))}
                  </div>
                </div>
              </div>
              {(this.state.viewAll ? (
                <Select id="DownloadReport" color="primary" value="Download Report As" className="studentButton download_report" onChange={this.fetchStudentLevelReport} disabled={tableValue.tableData.length === 0 ? true:false}>
                  <MenuItem classes={{ root: 'student-more-options-text' }} value="Download Report As">{language.getMessage('download_report')}</MenuItem>
                  <MenuItem classes={{ root: 'student-more-options-text' }} value="csv">{language.getMessage('csv')}</MenuItem>
                  <MenuItem classes={{ root: 'student-more-options-text' }} value="xlsx">{language.getMessage('excel')}</MenuItem>
                  <MenuItem classes={{ root: 'student-more-options-text' }} value="pdf">{language.getMessage('pdf')}</MenuItem>
                </Select>
              ) : '')}
              {tableValue.removeBookButton}
            </div>
            <div
              className="viewall-manage"
              onKeyDown={(event) => {
                if (event.key == 'Enter' && event.target.type == 'checkbox') {
                  document.activeElement.click();
                }
              }}
            >
              <MUIDataTable {...props} />
            </div>
          </div>
          <Snackbar
            open={this.state.removedBooksDetail.showMessage}
            message={tableValue.removedMessage}
            autoHideDuration={2000}
            onClose={this.handleRequestClose}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

StudentAnalyticsOverView.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(
  inject('language'),
  observer
)(injectIntl(StudentAnalyticsOverView));
