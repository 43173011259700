/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../constants';

const DlAppConfigMode = types.model('DlAppConfigReadingUiMode', {
  teacher: types.maybeNull(types.string),
  student: types.maybeNull(types.string)
});
const DlAppConfigReadingUi = types.model('DlAppConfigReadingUi', {
  highlights: types.maybeNull(types.boolean),
  bookmarks: types.maybeNull(types.boolean),
  related_assets: types.maybeNull(types.boolean),
  assignments: types.maybeNull(types.boolean),
  assessments: types.maybeNull(types.boolean),
  ui_mode: types.maybeNull(DlAppConfigMode)
});

const DlAppConfigBookshelfFilters = types.model('DlAppConfigBookshelfFilters', {
  url: types.maybeNull(types.string),
  primaryurl: types.maybeNull(types.string),
  isEnabled: types.maybeNull(types.boolean),
  roleValues: types.maybeNull(types.array(types.maybeNull(types.string)))
});

const DlAppConfigDashboardSections = types.model('DlAppConfigDashboardSections', {
  tag: types.maybeNull(types.string),
  isEnabled: types.maybeNull(types.boolean),
  roleValues: types.maybeNull(types.array(types.maybeNull(types.string))),
  showKeywordSearch: types.maybeNull(types.array(types.maybeNull(types.string))),
  showFilterSearch: types.maybeNull(types.array(types.maybeNull(types.string)))
});

const DlAppConfigDashboard = types.model('DlAppConfigDashboard', {
  sections: types.maybeNull(types.array(types.maybeNull(DlAppConfigDashboardSections)))
});

const DlAppConfigActivities = types.model('DlAppConfigActivities', {
  skillurl: types.maybeNull(types.string)
});

const DlAppConfigHelp = types.model('DlAppConfigHelp', {
  url: types.maybeNull(types.string),
  student_url: types.maybeNull(types.string)
});

const DlAppConfig = types
  .model('DlAppConfig', {
    config_version: types.maybeNull(types.string),
    readingui: types.maybeNull(DlAppConfigReadingUi),
    bookshelf_filters: types.maybeNull(DlAppConfigBookshelfFilters),
    appui_mode: types.maybeNull(DlAppConfigMode),
    dashboard: types.maybeNull(DlAppConfigDashboard),
    activities: types.maybeNull(DlAppConfigActivities),
    help: types.maybeNull(DlAppConfigHelp)
  })
  .actions(self => ({
    fetch() {
      Framework.getEventManager().publish(constants.DL_APP_CONFIG_REQUESTED, {});
    },
    set(state) {
      applySnapshot(self, state);
    }
  }));

export default DlAppConfig;
