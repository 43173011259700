/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class GetProductsListForAutoCompleteService extends BaseService {
  constructor() {
    super(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_REQUESTED, 'GetProductsListForAutoCompleteService');
  }

  handleEvent(eventName, event) {
    const deviceId = CommonUtils.getdeviceId();
    let searchKeyword;
    let encodedfilterObj;
    let searchLimit;
    let headerParams = null;
    if (event.searchObj && event.searchObj.hasOwnProperty('title')) {
      searchKeyword = encodeURIComponent(event.searchObj.title);
    } else {
      searchKeyword = '';
    }
    if (event.searchObj.hasOwnProperty('metadata')) {
      const filterObject = { metadata: event.searchObj.metadata };
      encodedfilterObj = encodeURIComponent(btoa(JSON.stringify(filterObject)));
    } else {
      encodedfilterObj = '';
    }
    searchLimit = '5';
    headerParams = {
      headers: {
        schoolid: encodeURIComponent(event.schoolId),
        keyword: searchKeyword,
        filterobject: encodedfilterObj,
        searchlimit: searchLimit,
        deviceid: deviceId
      }

    };
    return this.getClient('hapi').get('/search-product', headerParams);
  }


  handleResponse(response) { // eslint-disable-line class-methods-use-this
    // if (response.data && response.data.data) {
    if (response.data) {
      Framework.getEventManager().publish(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, response.data);
    } else {
      Framework.getEventManager().publish(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_ERROR, {
        message: 'Unable to fetch the  Search Products'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
