import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Framework, { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import Papa from 'papaparse';
// import AppActions from "../actions/app.actions";
import ReactHtmlParser from 'react-html-parser';
import TagManager from 'react-gtm-module';
import DataFormatter from '../../../common/utils/DataFormatter';
// import AppConstants, {MAX_BULK_IMPORT_SIZE} from "../constants/app.constants";
import * as constants from '../../../common/constants';
import { MAX_BULK_IMPORT_SIZE } from '../../../common/constants';
// import AppStore from "../stores/app.store";
// var lang = DataFormatter.getLanguage();
import iesUserDetailsByCourse from '../model/IESUserDetailsByCourse';
import CommonUtils from '../../../common/utils/CommonUtils';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  }
});

class FileReader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      csvfile: undefined,
      csv: null,
      values: [{
        firstName: '',
        lastName: '',
        displayName: '',
        studentID: '',
        passwordType: '',
        passwordSelectValue: '',
        passwordText: '',
        passwordUrl: ''
      }],
      showPasswordDialog: false,
      createStudentDialog: false,
      studentList: [],
      userListFetchStatus: null,
      newUserList: [],
      passwordList: null,
      passwordTypeField: null,
      error: true,
      errorText: '',
      batchItemsFetched: false,
      batchItemMessage: '',
      batchStatus: '',
      studentCount: 0,
      maxStudentDialog: false,
      batchPdfUrl: '',
      isBulkUserCreationInitiated: false

    };
  }

    getPasswordObject = (passwordObjectId, passwordObjectList) => {
      const { language, intl } = this.props;

      let defaultReturn = {
        status: 'error',
        message: intl.formatMessage(language.getText('password_not_found')),
        data: {}
      };
      passwordObjectList.map((passwordObj) => {
        if (passwordObj.password !== '' && passwordObj.logoURL !== '' && passwordObj.logoURL.indexOf(passwordObjectId.toLowerCase()) !== -1) {
          const word = passwordObjectId.toLowerCase();
          const regex = new RegExp(`\\b${word}\\b`);
          const result = passwordObj.logoURL.search(regex);
          if (result !== -1) {
            defaultReturn = {
              status: 'success',
              message: intl.formatMessage(language.getText('password_not_found')),
              data: passwordObj
            };
          }
        }
      });

      return defaultReturn;
    }

    componentDidMount() {
      // var course = DataFormatter.getObjectInStorage('courseSelected');
      // var courseId = course.id;
      // var roleValue = constants.roleValue;
      // Fetching Username List
      // AppActions.iesNewUserFetch();
      // AppActions.getIesUserDetailsByCourse(courseId, roleValue);
      Framework.getEventManager().on(constants.IES_USER_FETCHED, (setIesUserFetchData) => {
        this.setState({
          studentCount: setIesUserFetchData.length
        });
        // Framework.getEventManager().publish(constants.FETCH_IES_USER_REQUESTED,
        //   {
        //     userIdArray: setIesUserFetchData
        //   }
        // );
      });
      // AppActions.iesPasswordFetch();

      // AppStore.on(AppConstants.EventTypes.FETCH_IES_USER,this.callbackFetchIesUser);
      Framework.getEventManager().on(constants.FETCH_IES_USER_SUCCESS, (IesUserData) => {
        this.callbackFetchIesUser(IesUserData);
      });
      // AppStore.on(AppConstants.EventTypes.FETCH_NEW_IES_USER,this.callbackFetchNewIesUser);
      Framework.getEventManager().on(constants.FETCH_NEW_IES_USER_SUCCESS, (setIesNewUserData) => {
        this.callbackFetchNewIesUser(setIesNewUserData);
      });
      // AppStore.on(AppConstants.EventTypes.PASSWORD_LIST_FETCH,this.callbackFetchIesPassword);
      Framework.getEventManager().on(constants.IES_PASSWORD_LIST_FETCH_SUCCESS, (setIesPasswordData) => {
        this.callbackFetchIesPassword(setIesPasswordData);
      });
      // AppStore.on(AppConstants.EventTypes.CREATE_STUDENT,this.callbackCreateStudent);
      // AppStore.on(AppConstants.EventTypes.BULK_USERS_CREATE,this.callbackBulkUsersCreate);
      Framework.getEventManager().on(constants.CREATE_BULK_STUDENT_SUCCESS, (setBulkUsersCreated) => {
        if (this.state.isBulkUserCreationInitiated == false) {
          this.setState({ isBulkUserCreationInitiated: true });
          this.callbackBulkUsersCreate(setBulkUsersCreated);
        }
      });
      // AppStore.on(AppConstants.EventTypes.BULK_STUDENTS_ENROLL_SCHOOL_SECTION,this.callbackStudentsEnroll);
      Framework.getEventManager().on(constants.BULK_STUDENTS_ENROLL_SCHOOL_SECTION_SUCCESS, (setBulkStudentsEnrollSchoolSection) => {
        this.callbackStudentsEnroll(setBulkStudentsEnrollSchoolSection);
      });
    }

    UNSAFE_componentWillMount = function () {
      const { iesUserDetailsByCourse } = this.props;
      Framework.getEventManager().publish(constants.FETCH_NEW_IES_USER_REQUESTED, {});
      const course = DataFormatter.getObjectInStorage('courseSelected');
      if (course.id.length > 0) {
        const courseId = course.id;
        const roleValue = constants.roleValue;
        // AppActions.getIesUserDetailsByCourse(courseId, roleValue); - Replacement for this call is added below
        iesUserDetailsByCourse.fetch(courseId, roleValue);
      }
      Framework.getEventManager().publish(constants.IES_PASSWORD_LIST_FETCH_REQUESTED, {});
    }

    componentWillUnmount() {
      // AppStore.removeListener(AppConstants.EventTypes.FETCH_IES_USER,this.callbackFetchIesUser);
      // AppStore.removeListener(AppConstants.EventTypes.FETCH_NEW_IES_USER,this.callbackFetchNewIesUser);
      // AppStore.removeListener(AppConstants.EventTypes.PASSWORD_LIST_FETCH,this.callbackFetchIesPassword);
      // AppStore.removeListener(AppConstants.EventTypes.CREATE_STUDENT,this.callbackCreateStudent);
      // AppStore.removeListener(AppConstants.EventTypes.BULK_USERS_CREATE,this.callbackBulkUsersCreate);
      // AppStore.removeListener(AppConstants.EventTypes.BULK_STUDENTS_ENROLL_SCHOOL_SECTION,this.callbackStudentsEnroll);
    }

    validateAndPrepareData = (readCSV) => {
      const { language, intl } = this.props;
      const regexForPassword = new RegExp('^[a-zA-Z0-9]+$');

      let errorObj = null;
      const csvArray = [];
      readCSV.filter((csv) => {
        if (csv.first_name === ''
                && typeof csv.last_name === 'undefined'
                && typeof csv.password_type === 'undefined'
                && typeof csv.password === 'undefined'
        ) {
          return false;
        }
        if (csv.first_name === ''
                || csv.last_name === ''
                || (csv.password_type !== 'Image' && csv.password_type !== 'Text')
                || csv.password === ''
        ) {
          errorObj = {
            status: 'error',
            message: ReactHtmlParser(intl.formatMessage(language.getText('upload_failed_contents'))),
            data: []
          };
          return false;
        }
        if (csv.password_type === 'Text') {
          if (csv.password.length < 5 || regexForPassword.test(csv.password) === false) {
            errorObj = {
              status: 'error',
              message: ReactHtmlParser(intl.formatMessage(language.getText('upload_failed_contents'))),
              data: []
            };
            return false;
          }
        }
        return true;
      }).map((csv) => {
        let reqPasswordObj = '';
        let passUrl = '';
        let passTxt = '';
        if (csv.password_type === 'Image') {
          reqPasswordObj = this.getPasswordObject(csv.password, this.state.passwordList);
          if (reqPasswordObj.status === 'success') {
            passUrl = reqPasswordObj.data.logoURL;
            passTxt = reqPasswordObj.data.password;
          }
        } else if (csv.password_type === 'Text') {
          passUrl = '';
          passTxt = csv.password;
        }
        csvArray.push({
          firstName: csv.first_name,
          lastName: csv.last_name,
          displayName: csv.first_name + csv.last_name.substr(0, 1).toUpperCase(),
          studentID: csv.student_id,
          passwordType: csv.password_type,
          passwordSelectValue: '',
          passwordText: (passTxt !== '') ? passTxt : '',
          passwordUrl: passUrl
        });
      });

      if (errorObj !== null) {
        return errorObj;
      }
      return ({
        status: 'success',
        message: '',
        data: csvArray
      });
    }

    handleStudentCreation(preparedStudentData) {
      const { language, intl } = this.props;
      const userData = preparedStudentData;
      const self = this;
      const studentCount = this.state.studentCount;
      const existingStudents = studentCount === 0 ? null : self.state.studentList;// In case of deleting all students and immediately trying to upload bulk students studentCount will be zero but studentList is not empty
      if (studentCount >= MAX_BULK_IMPORT_SIZE) {
        // AppActions.showUploadProgress({status:false, message:""});
        this.setState({ status: 'error' });
        this.props.showDialog(true, `${intl.formatMessage(language.getText('max_limit_add')) + MAX_BULK_IMPORT_SIZE} ${intl.formatMessage(language.getText('students')).toLowerCase()}`);
      } else if ((userData.length + studentCount) > MAX_BULK_IMPORT_SIZE) {
        // AppActions.showUploadProgress({status:false, message:""});
        this.setState({ status: 'error' });
        this.props.showCreateDialog(false, false, '');
        this.props.showDialog(true, this.replaceText(intl.formatMessage(language.getText('failed_to_import')), [userData.length, MAX_BULK_IMPORT_SIZE]));
      } else {
        const unusedUserData = this.state.newUserList.filter((newUser) => {
          if (existingStudents !== null && existingStudents.length > 0) {
            if (!existingStudents.includes(newUser.imageUrl)) {
              return newUser;
            }
          } else {
            return newUser;
          }
        });
        if (typeof userData !== 'undefined' && userData.length > 0) {
          userData.map((user, index) => {
            user.passType = (user.passwordUrl !== '') ? 'IMG' : '';
            user.userName = unusedUserData[index].userName,
            user.studentIcon = unusedUserData[index].imageUrl;
          });
        }
        const metaData = this.createMetadata(userData);
        // AppActions.createStudent(metaData);
        this.setState({
          status: 'started'
        });
        // AppActions.bulkUsersCreate({ "items": metaData });
        Framework.getEventManager().publish(constants.CREATE_BULK_STUDENT_REQUESTED,
          {
            userId: DataFormatter.getKeyFromObject('userInformation', 'id'),
            payload: { items: metaData }
          });
        const gtmData = {
          dataLayer: {
            event: 'adding_student',
            event_category: intl.formatMessage(language.getText('adding_student')),
            event_action: intl.formatMessage(language.getText('add_student_clicked')),
            event_label: null,
            creation_type: 'Bulk file Student creation',
            number_of_students: userData.length,
            transaction_local_dt: CommonUtils.getLocalIsoTime()
          }
        };
        TagManager.dataLayer(gtmData);
      }
    }

    replaceText = (studentMsg, csvValues) => {
      csvValues.forEach((value, index) => {
        studentMsg = studentMsg.replace(new RegExp(`\\{${index}\\}`, 'g'), value);
      });
      return studentMsg;
    }

    createMetadata(studentData) {
      const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
      const countryCode = iesUserDetails.identity.homeCountryCode;
      const metaData = []; let newValues = {}; let userMetaData = {}; let
        passImageData = {};
      studentData.map((student, index) => {
        userMetaData = {
          // "name": student.displayName,
          username: student.userName,
          fName: student.firstName,
          lName: student.lastName,
          roleValue: 'student',
          homeCountryCode: countryCode,
          password: student.passwordText,
          identityScheme: 'ies',
          createOrRegister: 'create',
          studentIcon: student.studentIcon,
          passwordUrl: student.passwordUrl,
          cardNumber: student.studentID
        };
        if (student.passType === 'IMG') {
          passImageData = { passType: student.passType };
          userMetaData = { ...userMetaData, ...passImageData };
        }
        newValues = {
          createdBy: DataFormatter.getKeyFromObject('userInformation', 'id'),
          type: 'user',
          metadata: userMetaData
        };
        metaData.push(newValues);
      });
      if (metaData.length > 0) {
        metaData.push({
          createdBy: DataFormatter.getKeyFromObject('userInformation', 'id'),
          type: 'pdf-generation',
          status: 'submitted',
          metadata: {
            isNotifyTeacher: true,
            isEmailNotification: true,
            isPdfGeneration: true,
            SchoolId: DataFormatter.getObjectInStorage('schoolId')
          }
        });
      }
      return metaData;
    }


    handleChange = (file) => {
      const { language, intl } = this.props;
      this.setState({ isBulkUserCreationInitiated: false });
      if (this.state.studentCount >= MAX_BULK_IMPORT_SIZE) {
        this.props.showDialog(true, `${intl.formatMessage(language.getText('max_limit_add')) + MAX_BULK_IMPORT_SIZE} ${intl.formatMessage(language.getText('students')).toLowerCase()}`);
      } else {
        const sCurExtension = '.csv';
        // let sFileName = event.target.files[0];
        const sFileName = file;
        if (sFileName.name.substr(sFileName.name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
          Papa.parse(sFileName, {
            complete: (result) => {
              if (result.data === []) {
                this.props.showDialog(true, intl.formatMessage(language.getText('csv_file_error')));
                this.setState({
                  status: 'error',
                  csvfile: sFileName
                });
                return;
              }
              this.setState({
                csv: result.data
              });
              const validateStat = this.validateAndPrepareData(result.data);
              if (validateStat.status === 'error') {
                this.setState({
                  status: 'error',
                  csvfile: sFileName
                }, () => {
                  this.props.showDialog(true, validateStat.message);
                });
              } else if (validateStat.status === 'success') {
                if (validateStat.data.length > 0) {
                  // AppActions.showUploadProgress({status:true, message:"Uploading Import Template"});
                  // Starting User Creation
                  this.props.showCreateDialog(true, false, '');
                  this.handleStudentCreation(validateStat.data);
                } else {
                  this.setState({
                    status: 'error',
                    csvfile: sFileName
                  });
                  this.props.showDialog(true, ReactHtmlParser(intl.formatMessage(language.getText('upload_failed_contents'))));
                }
              }
            },
            header: true
          });
        } else {
          this.props.showDialog(true, <div>{ReactHtmlParser(intl.formatMessage(language.getText('csv_format_error')))}</div>);
        }
      }
    };

    callbackFetchNewIesUser = (setIesNewUserData) => {
      if (
        typeof setIesNewUserData !== 'undefined'
            && typeof setIesNewUserData.data.images !== 'undefined'
            && setIesNewUserData.data.images.length > 0
      ) {
        // 1. Check for username & userimage & givenname should be populated
        this.setState({
          newUserList: setIesNewUserData.data.images
        });
      }
    };

    callbackFetchIesPassword = (setIesPasswordList) => {
      const iesPasswordList = [];
      if (typeof setIesPasswordList.data.images !== 'undefined' && setIesPasswordList.data.images.length > 0) {
        setIesPasswordList.data.images.map((image) => {
          if (typeof image.imageUrl !== 'undefined' && image.imageUrl !== '' && typeof image.password !== 'undefined' && image.password !== '') {
            iesPasswordList.push({
              logoURL: image.imageUrl, password: image.password
            });
          }
        });
        this.setState({ passwordList: iesPasswordList, passwordListFetchStatus: { status: 'complete' } });
      }
    }

    callbackFetchIesUser = (setIesUserFetchData) => {
      if (
        typeof setIesUserFetchData !== 'undefined'
            && typeof setIesUserFetchData.data.matches !== 'undefined'
            && setIesUserFetchData.data.matches.length > 0
      ) {
        const userWithImages = [];
        const teacherUsers = [];
        setIesUserFetchData.data.matches.map((user) => {
          if (typeof user !== 'undefined') {
            if (
              typeof user.credentials !== 'undefined'
                        && user.credentials.length > 0
            ) {
              if (
                typeof user.identityProfile !== 'undefined' && typeof user.identity !== 'undefined'
              ) {
                if (
                  typeof user.credentials[0].userName !== 'undefined'
                                && user.credentials[0].userName !== ''
                                && typeof user.identityProfile.givenName !== 'undefined'
                                && user.identityProfile.givenName !== ''
                ) {
                  // Check if the user is Student
                  if (typeof user.credentials[0].userNameImageUrl !== 'undefined'
                                    && user.credentials[0].userNameImageUrl !== '') {
                    userWithImages.push(user.credentials[0].userNameImageUrl);
                  }
                }
              }
            }
          }
        });
        this.setState({
          studentList: userWithImages,
          studentCount: setIesUserFetchData.data.matches.length,
          userListFetchStatus: { status: 'complete' }
        });
      }
    };

    callbackBulkUsersCreate = (setBulkUsersCreated) => {
      const { language, intl } = this.props;
      const mapUserToSchoolClass = [];
      this.setState({
        createStudentDialog: true
      });
      this.props.showCreateDialog(true, false, '');
      // this.props.showCreateDialog(true,true,<div>Student creation has started.<br></br> You can close the pop up and navigate freely around the app. We will let you know as soon as your class list is ready.</div>);
      if (setBulkUsersCreated) {
        const batchId = setBulkUsersCreated.batch.id;
        const course = DataFormatter.getObjectInStorage('courseSelected');
        const originId = course.externalRef[0].originId;
        setBulkUsersCreated.items.map((userdata) => {
          if (userdata.type === 'user') {
            const classData = {
              type: 'class-user',
              schoolId: DataFormatter.getObjectInStorage('schoolId'),
              externalRef: [{
                originId,
                source: 'dl-classcode'
              }],
              userUID: userdata.uid,
              cardNumber: userdata.metadata.cardNumber,
              roleValue: 'student'
            };
            mapUserToSchoolClass.push(classData);
            const schoolData = {
              type: 'school-user',
              schoolId: DataFormatter.getObjectInStorage('schoolId'),
              userUID: userdata.uid,
              roleValue: 'student'
            };
            mapUserToSchoolClass.push(schoolData);
          }
        });
        if (mapUserToSchoolClass.length > 0) {
          const mapUserData = { links: mapUserToSchoolClass };
          // AppActions.bulkEnrollUsersToSchoolClass(batchId, mapUserData);
          Framework.getEventManager().publish(constants.BULK_STUDENTS_ENROLL_SCHOOL_SECTION_REQUESTED,
            {
              batchId,
              payload: mapUserData
            });
        } else {
          // AppActions.showUploadProgress({status:false, message:""});
          this.props.showCreateDialog(false, false, '');
          this.props.showDialog(true, intl.formatMessage(language.getText('upload_failed')));
          this.setState({
            status: 'error'
          });
        }
      } else {
        this.props.showCreateDialog(false, false, '');
        // AppActions.showUploadProgress({status:false, message:""});
        this.props.showDialog(true, intl.formatMessage(language.getText('upload_failed')));
        this.setState({
          status: 'error'
        });
      }
    };

    callbackStudentsEnroll = (setBulkStudentsEnrollSchoolSection) => {
      if (setBulkStudentsEnrollSchoolSection.length > 0) {
        const batchId = setBulkStudentsEnrollSchoolSection[0].batchId;
        // AppActions.ingestionGetBatchStatus(batchId);
        this.setState({
          status: 'complete'
        });
        // AppActions.showUploadProgress({status:false, message:""});
        // AppActions.getUserBatch();
        Framework.getEventManager().publish(constants.GET_USER_BATCH_REQUESTED, { userId: DataFormatter.getKeyFromObject('userInformation', 'id') });
        localStorage.setItem('isStudentAdd', 'true');
        this.props.showCreateDialog(true, true, <div>
Student creation has started.
          <br />
          {' '}
You can close the pop up and navigate freely around the app. We will let you know as soon as your class list is ready.
                                                </div>);
      } else {
        this.setState({
          status: 'error'
        });
        this.props.showCreateDialog(false, false, '');
        // AppActions.showUploadProgress({status:false, message:""});
        this.props.showDialog(true, 'Student Upload Failed! Please check after sometime.');
      }
    }

    render() {
      const { language, intl } = this.props;
      return (
        <MuiThemeProvider theme={muiTheme}>
          <div>
            <input
              className="csv-input"
              type="file"
              ref={(input) => {
                this.filesInput = input;
              }}
              name="file"
              id="file"
              placeholder={null}
                    // onChange={(e)=> {
                    //     this.handleChange(e)
                    // }}
              style={{ display: 'none' }}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={(this.state.status === 'started')}
              onClick={(e) => {
                this.filesInput.click();
                this.fileCheck = setInterval((e) => {
                  try {
                    if (typeof this.filesInput.files[0] !== 'undefined' && this.filesInput.files[0] !== null) {
                      clearInterval(this.fileCheck);
                      this.handleChange(this.filesInput.files[0]);
                      document.getElementById('file').value = '';
                    }
                  } catch (e) {}
                }, 1000);
              }}
              primary
              className="studentButton  upload-template"
            >
              {intl.formatMessage(language.getText('upload_template'))}
            </Button>
          </div>
        </MuiThemeProvider>
      );
    }
}

// FileReader.contextTypes = {
//     router: React.PropTypes.object.isRequired,
// };
FileReader.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired,
  iesUserDetailsByCourse: shapes.modelOf(iesUserDetailsByCourse).isRequired
};

// export default FileReader;
export default compose(
  inject('language', 'iesUserDetailsByCourse'),
  observer
)(injectIntl(FileReader));
