import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from "@material-ui/core/styles";
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import largerDesktopBgImage from '../../../assets/images/newdesign_v5_1920_1080.png';
import desktopBgImage from '../../../assets/images/newdesign_v5_1280_800.png';
import iPadBgImage from '../../../assets/images/newdesign_v5_768_1024.png';

const Styles = (theme) => ({
  confirmLibraryWrapper: {
    backgroundImage: `url(${largerDesktopBgImage})`,
    '@media (max-width: 1280px) and (min-width: 821px)': {
      backgroundImage: `url(${desktopBgImage})`
    },
    '@media (max-width: 820px)': {
      backgroundImage: `url(${iPadBgImage})`
    }
  }
});
class AccountCreated extends Component {
    redirectToNextPage = () => {
      if (localStorage.getItem('userid') !== 'null' && localStorage.getItem('userid') !== undefined) {
        if (window.localStorage) {
          const isStudentAdd = localStorage.getItem('isStudentAdd');
          localStorage.clear();
          (isStudentAdd === 'true') ? localStorage.setItem('isStudentAdd', isStudentAdd) : '';
        }
        window.piSession.logout();
        localStorage.setItem('usertype', 'teacher');
        Framework.redirectTo('/app');
      }
    }


    render() {
      const { language, intl, classes } = this.props;
      return (
        <div className={`teacher-confirm-library-outer-container ${classes.confirmLibraryWrapper}`}>
          <div className="teacher-confirm-library-container">
            <label className="teacher-confirm-library-txt">{intl.formatMessage(language.getText('account_created'))}</label>
            <br />
            <button id="id_confirm_library" className="teacher-btn-confirm-library" onClick={this.redirectToNextPage}><label className="teacher-btn-confirm-library-text">{intl.formatMessage(language.getText('continue')).toUpperCase()}</label></button>
          </div>
        </div>
      );
    }
}

AccountCreated.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default withStyles(Styles)(compose(
  inject('language'),
  observer
)(injectIntl(AccountCreated)));
