/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class UpdateUserProductStatusService extends BaseService {
  constructor() {
    super(constants.UPDATE_USER_PRODUCT_REQUESTED, 'UpdateUserProductStatusServiceStatus');
  }

  handleEvent(eventName, event) {
    const deviceId = CommonUtils.getdeviceId();
    const headerParams = {
      headers: {
        deviceid: deviceId,
        userid: event.userId,
        productid: event.productId
      }
    };
    const userStatusObj = event.userStatusObj;
    return this.getClient('hapi').put(
      '/update-user-product',
      userStatusObj, headerParams
    );
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data) {
      let userProductData;
      if (response.statusCode === 404) {
        userProductData = 'failed';
      } else {
        userProductData = 'success';
      }
      Framework.getEventManager().publish(constants.UPDATE_USER_PRODUCT_SUCCESS, userProductData);
    } else {
      Framework.getEventManager().publish(constants.UPDATE_USER_PRODUCT_ERROR, {
        message: 'Unable to Update the user product details'
      });
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.UPDATE_USER_PRODUCT_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
