import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import $ from 'jquery';
import TagManager from 'react-gtm-module';
import DataFormatter from '../../../common/utils/DataFormatter';
import CommonUtils from '../../../common/utils/CommonUtils';
import * as constants from '../../../common/constants';

// import GoogleAnalyticsUtil from '../util/googleAnalyticsUtil';
// import PrepareSearchObjectUtil from '../../bookreader/util/prepareSearchObjectUtil';
import PrepareSearchObjectUtil from '../../../common/utils/prepareSearchObjectUtil';


import FilterComponent from './FilterComponent';

const lang = DataFormatter.getLanguage();
const styles = {
  searchfloatingLabelStyle: {
    fontSize: '18px',
    lineHeight: '1.22',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.42)',
    bottom: '13px',
    textIndent: 64,
    paddingRight: '40px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '95%'
  },
  searchConsoleStyle: {
    width: '50%',
    position: 'relative',
    display: 'inline-block',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: '8px',
    marginBottom: '8px'
  },
  searchTextBoxStyle: { height: '48px', paddingLeft: '60px', paddingTop: '8px' },
  searchIconStyle: {
    position: 'absolute', left: '19px', bottom: 15.5, width: 17.5, height: 17.5, cursor: 'pointer', zIndex: '1000'
  },
  closeIconStyle: {
    position: 'absolute', right: '19px', bottom: 15.5, width: 17.5, height: 17.5, cursor: 'pointer', zIndex: '1000'
  },
  filterCloseIconStyle: {
    position: 'relative', right: '-140px', bottom: -10, width: 21, height: 21, cursor: 'pointer', zIndex: '1000'
  },
  searchIconColor: '#74797b',
  searchTextBoxInputStyle: { textIndent: 64, width: '90%' },

  flatButtonLabelStyles: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '1.25',
    textAlign: 'center',
    color: '#ffffff'
  },
  filterButtonWithCountLabelStyles: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '1.25',
    textAlign: 'center',
    color: '#00857f'
  },
  flatButtonStyles: {
    marginTop: '14px',
    marginLeft: '12px',
    fontWeight: 'bold'
  }

};

class SecondaryRefineSearchConsole extends React.Component {
  constructor(props) {
    super(props);
    this.searchTextField = React.createRef();
    this.primarySearchConsoleWrapper = React.createRef();
    this.selectAllButton = React.createRef();
    this.secondarySearchHeaderWrapper = React.createRef();
    this.searchComponentWrapper = React.createRef();
    this.searchSubmit = this.searchSubmit.bind(this);
    this.handleEnterKeyPress = this.handleEnterKeyPress.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.renderAutoComplete = this.renderAutoComplete.bind(this);
    this.renderRecentSearch = this.renderRecentSearch.bind(this);
    this.handleSearchInFocus = this.handleSearchInFocus.bind(this);
    this.handleSearchFocusOut = this.handleSearchFocusOut.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.setDropdownLayout = this.setDropdownLayout.bind(this);
    this.renderSecondarySearchConsole = this.renderSecondarySearchConsole.bind(this);
    this.renderSecondaryHeader = this.renderSecondaryHeader.bind(this);
    this.handleFindBookClick = this.handleFindBookClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleFilterDoneClick = this.handleFilterDoneClick.bind(this);
    this.handleSearchKeywordKeyPress = this.handleSearchKeywordKeyPress.bind(this);
    this.handleAutoCompleteClick = this.handleAutoCompleteClick.bind(this);
    this.handleAutoCompleteHide = this.handleAutoCompleteHide.bind(this);
    this.handleSearchkeywordChange = this.handleSearchkeywordChange.bind(this);
    this.handleSearchCall = this.handleSearchCall.bind(this);
    this.handleAutoCompleteCall = this.handleAutoCompleteCall.bind(this);
    this.handleAutoCompleteFetchComplete = this.handleAutoCompleteFetchComplete.bind(this);
    this.selectAllSearchedBooks = this.selectAllSearchedBooks.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
    this.doSearchCall = this.doSearchCall.bind(this);
    this.hideKeyboard = this.hideKeyboard.bind(this);
    this.hiddenCountOfChip = 0;
    this.reload = true;
    this.UpdatehiddenCountOfChip = this.UpdatehiddenCountOfChip.bind(this);
    this.hiddenCountOfChip = 0;
    this.reload = true;
    this.UpdatehiddenCountOfChip = this.UpdatehiddenCountOfChip.bind(this);
  }

  state = {
    searchText: null,
    showAutoComplete: false,
    showRecentSearch: false,
    showFilter: false,
    autoCompleteLoading: 0,
    searchProductsLoaded: false,
    filterQueue: {},
    chipData: [],
    sectionData: {},
    selectedAll: false,
    showSelectAllButton: false,
    keywordSearchOccured: false,
    hideFilter: false,
    hiddenCountOfChip: 0
  }

  calculateWindowWidth = function (sendRemainingWidth) {
    const windowWidth = window.innerWidth;
    const booksCanAccomodate = windowWidth / 285;
    const remainingWidth = windowWidth - (parseInt(booksCanAccomodate) * 285);
    if (sendRemainingWidth) {
      return remainingWidth;
    }
    return (windowWidth - remainingWidth - 24);
  }

  componentDidUpdate = function () {
    const isSchoolLibTabClicked = DataFormatter.getObjectInSessionStorage('schoolLibTabClicked');
    DataFormatter.setObjectInSessionStorage('schoolLibTabClicked', false);
    if (isSchoolLibTabClicked) {
      this.handleCancelClick();
    }
    this.setFilterPosition();
    this.onWindowResize();
  }

  onWindowResize = function () {
    const searchConsole = document.getElementById('searchConsole');
    const searchFieldWrapper = document.getElementById('searchFieldWrapper');
    const filterButtonWrapper = document.getElementById('filterButtonWrapper');

    if (searchConsole) {
      if (searchFieldWrapper) {
        const size = (searchConsole.clientWidth > 800) ? 425 : 348;
        searchFieldWrapper.style.width = `${searchConsole.clientWidth - size}px`;
      }
      if (filterButtonWrapper) {
        filterButtonWrapper.style.width = '290px';
      }
      const headerViewPortOffset = this.searchComponentWrapper.current.parentNode.getBoundingClientRect();
      this.secondarySearchHeaderWrapper.current.style.width = `${Math.round(headerViewPortOffset.width)}px`;
    }
    // hack
    if (this.selectAllButton.current) {
      if (this.state.showFilter && !this.state.hideFilter) {
        this.selectAllButton.current.style.marginRight = '367px';
      } else {
        this.selectAllButton.current.style.marginRight = '1.4%';
      }
    }
    const size = (searchConsole.clientWidth > 800) ? 425 : 268;
    const baacTextBookWrapComponentPadding = this.calculateWindowWidth(true) / 2;
    const changedwidth = searchConsole.clientWidth - size + filterButtonWrapper.clientWidth + baacTextBookWrapComponentPadding;
    if (searchConsole.clientWidth < changedwidth) {
      searchFieldWrapper.style.width = `${searchConsole.clientWidth - size - baacTextBookWrapComponentPadding / 2}px`;
    }
    if (searchConsole.clientWidth > 800) searchConsole.style.paddingLeft = `${baacTextBookWrapComponentPadding}px`;
    else { searchConsole.style.paddingLeft = '10px'; }
    const secondarySearchsubwrappermrgn = document.getElementsByClassName('secondarySearchsubwrapper');
    if (secondarySearchsubwrappermrgn) {
      secondarySearchsubwrappermrgn[0].style.paddingLeft = `${baacTextBookWrapComponentPadding}px`;
    }
  }

  componentDidMount = function () {
    Framework.getEventManager().on(constants.PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS, () => {
      this.handleAutoCompleteFetchComplete();
    });

    if (this.props.showKeywordSearch && this.searchTextField.current) {
      this.searchTextField.current.addEventListener('focus', this.handleSearchInFocus);
      this.searchTextField.current.addEventListener('blur', this.handleSearchFocusOut.bind(this, true));
      this.searchTextField.current.addEventListener('keypress', this.handleSearchKeywordKeyPress);

      const primarySearchConsoleBackDrop = this.primarySearchConsoleWrapper.current.querySelector('.primarySearchConsoleBackDrop');
      if (primarySearchConsoleBackDrop) {
        primarySearchConsoleBackDrop.addEventListener('click', this.handleSearchFocusOut.bind(this, false));
      }
    }

    if (this.props.showKeywordSearch || this.props.showFilterSearch) {
      document.getElementById('main').addEventListener('scroll', this.handleScroll);
    }

    setTimeout(this.setDropdownLayout, 2000);
    window.addEventListener('resize', this.handleResize);

    const searchState = DataFormatter.getObjectInSessionStorage('searchState');
    DataFormatter.setObjectInSessionStorage('searchState', null);
    if (searchState) {
      this.setState({ ...searchState }, () => {
        this.setFilterPosition();
        DataFormatter.setObjectInSessionStorage('searchState', null);
        this.handleFilterDoneClick(true, searchState.showFilter);
      });
    }
  }

  handleAutoCompleteFetchComplete() {
    const updtState = {
      autoCompleteLoading: ((this.state.autoCompleteLoading > 0) ? (this.state.autoCompleteLoading - 1) : 0)
    };
    if (this.state.autoCompleteLoading == 1 && this.state.performSearchPending) {
      updtState.performSearchPending = false;
      this.handleSearchCall(true);
    }
    this.setState(updtState);
  }

  handleResize() {
    const viewportOffset = this.searchComponentWrapper.current.parentNode.getBoundingClientRect();
    const top = viewportOffset.top;
    if (top <= 0) {
      this.searchComponentWrapper.current.style.width = `${Math.round(viewportOffset.width)}px`;
      this.secondarySearchHeaderWrapper.current.style.width = `${Math.round(viewportOffset.width)}px`;
    }
    this.setDropdownLayout();
    this.onWindowResize();
  }

  handleScroll(event) {
    const viewportOffset = this.searchComponentWrapper.current.parentNode.getBoundingClientRect();
    const bookShelfWrapperElement = document.querySelector('.bookshelf_wrapper'); let bookShelfWrapperElementOffset; let
      bookShelfWrapperElementOffsetHeight;
    const primarySearchConsole = this.searchComponentWrapper.current.querySelector('.primarySearchConsoleWrapper');
    let prtWrpHeight; let
      primarySearchConsoleBCR;
    if (this.state.searchEnabled) {
      if (primarySearchConsole) {
        primarySearchConsoleBCR = primarySearchConsole.getBoundingClientRect();
        prtWrpHeight = primarySearchConsoleBCR.height;
      }
    } else {
      const primarySearchHeaderWrapper = this.searchComponentWrapper.current.querySelector('.secondarySearchHeaderWrapper');
      if (primarySearchHeaderWrapper) {
        const primarySearchHeaderWrapperBCR = primarySearchHeaderWrapper.getBoundingClientRect();
        prtWrpHeight = primarySearchHeaderWrapperBCR.height;
      }
    }
    let suppressSticky = false;
    if (bookShelfWrapperElement) {
      bookShelfWrapperElementOffset = bookShelfWrapperElement.getBoundingClientRect();
      if (bookShelfWrapperElementOffset.height) {
        bookShelfWrapperElementOffsetHeight = bookShelfWrapperElementOffset.height;
        if (bookShelfWrapperElementOffsetHeight <= 400) {
          suppressSticky = true;
        }
      }
    }
    const currentSelectFieldTop = DataFormatter.getObjectInStorage('CustomClickSelectField');
    const currentSubMenuHeight = DataFormatter.getObjectInStorage('CustomClickSubMenuHeight');
    const currentSubMenuTop = DataFormatter.getObjectInStorage('CustomClickSubMenuTop');

    const top = viewportOffset.top;
    const searchConsoleFilter = document.getElementsByClassName('searchConsoleFilter')[0];
    const filterResetButton = document.getElementById('filterResetButton');
    const buttonWrapper = document.getElementById('filterButtonWrapper').offsetHeight;
    const dividerOffset = document.getElementsByClassName('divider')[0].offsetTop;
    let updateScrollValue = 0;
    let updateSubmenuScrollValue = 0;
    if (top <= 0 && !suppressSticky) {
      if (document.body.lastElementChild.tagName !== 'STYLE') {
        updateScrollValue = buttonWrapper + currentSelectFieldTop + dividerOffset + 50;
        if (document.body.lastElementChild.previousElementSibling.tagName === 'STYLE') {
          document.body.lastElementChild.firstChild.style.top = `${updateScrollValue}px`;
        } else {
          updateSubmenuScrollValue = updateScrollValue + currentSubMenuHeight + currentSubMenuTop;
          try {
            document.body.lastElementChild.previousElementSibling.firstChild.style.top = `${updateScrollValue}px`;
            document.body.lastElementChild.firstChild.style.top = `${updateSubmenuScrollValue}px`;
          } catch (e) {
            // console.log("secondaryRefineSearchConsole #2",e);
          }
        }
      }
      this.searchComponentWrapper.current.classList.add('sticky');
      this.searchComponentWrapper.current.style.width = `${Math.round(viewportOffset.width)}px`;
      this.secondarySearchHeaderWrapper.current.style.width = `${Math.round(viewportOffset.width)}px`;
      this.searchComponentWrapper.current.parentNode.style.height = `${Math.round(prtWrpHeight)}px`;
      this.secondarySearchHeaderWrapper.current.style.top = '63px';
      this.secondarySearchHeaderWrapper.current.classList.add('sticky');
      if (searchConsoleFilter) {
        searchConsoleFilter.style.top = '63px';
      }
      if (filterResetButton) {
        filterResetButton.classList.remove('hidden');
      }
    } else {
      if (document.body.lastElementChild.tagName !== 'STYLE') {
        updateScrollValue = buttonWrapper + currentSelectFieldTop + dividerOffset + top + 50;
        if (document.body.lastElementChild.previousElementSibling.tagName === 'STYLE') {
          document.body.lastElementChild.firstChild.style.top = `${updateScrollValue}px`;
        } else {
          updateSubmenuScrollValue = updateScrollValue + currentSubMenuTop + currentSubMenuHeight;
          try {
            document.body.lastElementChild.previousElementSibling.firstChild.style.top = `${updateScrollValue}px`;
            document.body.lastElementChild.firstChild.style.top = `${updateSubmenuScrollValue}px`;
          } catch (e) {
            // console.log("secondaryRefineSearchConsole #1",e);
          }
        }
      }
      this.searchComponentWrapper.current.classList.remove('sticky');
      this.secondarySearchHeaderWrapper.current.classList.remove('sticky');
      this.searchComponentWrapper.current.style.width = '';
      this.secondarySearchHeaderWrapper.current.style.width = '';
      this.searchComponentWrapper.current.parentNode.style.height = '';
      const filterTop = `${top + 63}px`;
      this.secondarySearchHeaderWrapper.current.style.top = filterTop;
      if (searchConsoleFilter) {
        searchConsoleFilter.style.top = filterTop;
      }
      if (filterResetButton) {
        if (top > 180) {
          filterResetButton.classList.add('hidden');
        } else {
          filterResetButton.classList.remove('hidden');
        }
      }
    }
    // hack
    if (this.selectAllButton.current) {
      if (this.state.showFilter && !this.state.hideFilter) {
        this.selectAllButton.current.style.marginRight = '367px';
      } else {
        this.selectAllButton.current.style.marginRight = '1.4%';
      }
    }
  }

  setDropdownLayout() {
    let primarySearchConsoleDropdown; let
      primarySearchConsoleInput;
    // hack
    if (this.primarySearchConsoleWrapper.current) {
      primarySearchConsoleDropdown = this.primarySearchConsoleWrapper.current.querySelector('.primarySearchDropdown');
      primarySearchConsoleInput = this.primarySearchConsoleWrapper.current.querySelector('.primarySearchFieldWrapper');
    }
    if (primarySearchConsoleDropdown && primarySearchConsoleInput) {
      primarySearchConsoleDropdown.style.width = `${primarySearchConsoleInput.offsetWidth}px`;
    }
  }

  handleSearchInFocus(event) {
    if (event) {
      event.stopPropagation();
    }
  }

  handleSearchFocusOut(force, event) {
    let $element;
    if (event) {
      event.stopPropagation();
      $element = $(event.target);
    }
  }

  componentWillUnmount() {
    if (this.props.showKeywordSearch && this.searchTextField.current) {
      this.searchTextField.current.removeEventListener('focus', this.handleSearchInFocus);
      document.getElementById('main').removeEventListener('scroll', this.handleScroll);
    }

    if (this.props.showKeywordSearch || this.props.showFilterSearch) {
      document.getElementById('main').removeEventListener('scroll', this.handleScroll);
    }

    window.removeEventListener('resize', this.handleResize);
  }

  handleEnterKeyPress = function (event) {
    if (event.which == 13) {
      event.preventDefault();
      try {
        if (this.searchTextField.current) {
          this.handleSearchFocusOut(true);
        }
      } catch (err) {
      }
    }
  }

  handleSearchCall(checkForSearchObject, isCancelClicked) {
    const { intl, language } = this.props;
    let arg = null;
    if (checkForSearchObject) {
      arg = PrepareSearchObjectUtil.prepareSearchObject(this.state.searchText, Object.keys(this.state.sectionData).length > 0 ? this.state.sectionData : this.state.filterQueue);
    }
    DataFormatter.setObjectInSessionStorage('tempSearchState', this.state);
    if (arg && arg.length) {
      const searchObj = arg[0];
      if (searchObj) {
        let eventCategory; let eventAction; let removeEventListener; let
          event = '';
        const eventLabel = {
          userId: DataFormatter.getKeyFromObject('userInformation', 'id')
        };
        if (searchObj && searchObj.hasOwnProperty('title')) {
          const searchKeyword = searchObj.title;
          eventCategory = intl.formatMessage(language.getText('keyword_search'));
          eventAction = intl.formatMessage(language.getText('search_executed'));
          eventLabel.keyword = searchKeyword;
          event = 'keyword_search_teacher';
        }
        if (searchObj.hasOwnProperty('metadata')) {
          if (Object.keys(searchObj.metadata).length !== 0) {
            eventCategory = intl.formatMessage(language.getText('filter_option'));
            eventAction = intl.formatMessage(language.getText('filter_executed'));
            eventLabel.filterOption = searchObj.metadata;
            event = 'filter_option_teacher';
          }
        }
        if (eventCategory) {
          const gtmData = {
            dataLayer: {
              event,
              event_category: eventCategory,
              event_action: eventAction,
              event_label: null,
              user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
              keyword: (searchObj && searchObj.hasOwnProperty('title')) ? searchObj.title : null,
              filter_option: (searchObj && searchObj.hasOwnProperty('metadata')) ? `Number of filter options: ${Object.keys(searchObj.metadata).length}` : null,
              transaction_local_dt: CommonUtils.getLocalIsoTime()
            }
          };
          TagManager.dataLayer(gtmData);
        }
      }
    }
    this.setState({
      showSelectAllButton: true
    });
    if (typeof this.props.handleSearch === 'function') {
      if (isCancelClicked) {
        this.props.handleSearch(arg, 'NaN', isCancelClicked);
      } else {
        this.props.handleSearch(arg);
      }
    }
    this.hideKeyboard();
  }

  handleAutoCompleteCall(arg) {
    if (typeof this.props.handleAutoComplete === 'function') {
      this.props.handleAutoComplete(arg);
    }
  }

  handleSearchKeywordKeyPress = function (event) {
    if (event.which == 13) {
      event.preventDefault();
      if (!this.state.showFilter) {
        this.setState({
          performSearchPending: true,
          keywordSearchOccured: true
        }, () => {
          this.handleAutoCompleteHide(() => {
            if (!this.state.autoCompleteLoading) {
              this.handleSearchCall(true);
            } else if (typeof this.props.enableLoading === 'function') {
              this.props.enableLoading();
            }
          });
        });
      } else {
        this.setState({
          performSearchPending: true,
          keywordSearchOccured: true
        }, () => {
          this.handleAutoCompleteHide(() => {
            if (!this.state.autoCompleteLoading) {
              this.handleSearchCall(true);
            } else if (typeof this.props.enableLoading === 'function') {
              this.props.enableLoading();
            }
          });
        });
      }
    }
  }

  handleSearchkeywordChange = (event) => {
    let searchText = null;
    if (event.target.value !== '') {
      searchText = event.target.value;
      const updateState = {
        searchText,
        keywordSearchOccured: false
      };
      if (typeof this.props.handleAutoComplete === 'function' && searchText.length >= 3) {
        updateState.showAutoComplete = true;
        updateState.autoCompleteLoading = this.state.autoCompleteLoading + 1;
      }
      this.setState(updateState, () => {
        if (this.state.showAutoComplete && this.state.searchText.length >= 3) {
          this.handleAutoCompleteCall(true);
        }
      });
    } else {
      this.setState({
        searchText
      });
    }
  };

  renderDropdown() {
    return (<div className="primarySearchDropdown">{(this.state.searchText == null) ? (this.state.showRecentSearch ? this.renderRecentSearch() : null) : this.renderAutoComplete()}</div>);
  }

  renderRecentSearch() {
    const { language } = this.props;
    return (
      <ul className="primarySearchRecentHistory">
        <li className="heading"><h3><FormattedMessage {...language.getText('recentSearches')} /></h3></li>
      </ul>
    );
  }

  highlightTextInData = function (highlightText, data) {
    let st = -1; let ed; let
      wrappedBookTitleData = [];
    if (data) {
      if (typeof highlightText === 'string' && highlightText.length > 1) {
        st = (highlightText) ? (data.toLowerCase()).indexOf(highlightText.toLowerCase()) : -1;
        wrappedBookTitleData = data;
        if (st !== -1) {
          wrappedBookTitleData = wrappedBookTitleData.replace(new RegExp(`(${highlightText})`, 'ig'), "<span class='highlightSearchText'>$1</span>");
        }
      } else if (highlightText instanceof Array) {
        if (highlightText.indexOf(data) !== -1) {
          wrappedBookTitleData.push(`<span class='highlightSearchText'>${data}</span>`);
        } else {
          let stArray = [];
          wrappedBookTitleData = data;
          for (let i = 0, ilen = highlightText.length; i < ilen; i++) {
            let tempSt; const stArrayItem = {}; const
              tempHighlightText = highlightText[i];
            if (tempHighlightText && tempHighlightText.indexOf(data) !== -1) {
              wrappedBookTitleData = [];
              wrappedBookTitleData.push(`<span class='highlightSearchText'>${data}</span>`);
              stArray = [];
              break;
            } else {
              let tempHighlightTextReg;
              if (typeof tempHighlightText === 'string' && highlightText.length > 1) {
                wrappedBookTitleData = wrappedBookTitleData.replace(new RegExp(`(${tempHighlightText})`, 'ig'), "<span class='highlightSearchText'>$1</span>");
              }
            }
          }
          if (wrappedBookTitleData.length == 0) {
            wrappedBookTitleData = data;
          }
        }
      } else {
        wrappedBookTitleData = data;
      }
    } else {
      wrappedBookTitleData = data;
    }
    if (wrappedBookTitleData instanceof Array && wrappedBookTitleData.length > 0) {
      wrappedBookTitleData = wrappedBookTitleData.join('');
    }
    return { __html: wrappedBookTitleData };
  }

  renderAutoComplete() {
    let autoCompleteListElement = null; let
      autoCompleteList;
    if (this.props.dataStoreAutoComplete && this.props.dataStoreAutoComplete instanceof Array) {
      autoCompleteList = [...this.props.dataStoreAutoComplete];
      autoCompleteListElement = autoCompleteList.map((v, i) => (<li tabIndex="0" onClick={this.handleAutoCompleteClick} dangerouslySetInnerHTML={this.highlightTextInData(this.state.searchText, v)} />));
    }
    return (
      <ul className="primarySearchAutoComplete">
        {autoCompleteListElement}
      </ul>
    );
  }

  handleFindBookClick() {
    this.setState({
      searchEnabled: true,
      showFilter: true
    }, () => {
      this.setFilterPosition();
    });
  }

  hideKeyboard = function () {
    document.activeElement.blur();
    $('input').blur();
  }

  searchSubmit = function (event) {
    if (!this.state.showFilter) {
      this.handleAutoCompleteHide(() => {
        this.handleSearchCall(true);
      });
    }
  }

  searchClear = function (event) {
    const { intl, language } = this.props;
    DataFormatter.setObjectInSessionStorage('searchState', null);
    DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    const eventLabel = { userId: DataFormatter.getKeyFromObject('userInformation', 'id') };
    let eventCategory; let
      eventAction = '';
    if (this.state.searchText) {
      eventCategory = intl.formatMessage(language.getText('search_cancel'));
      eventAction = intl.formatMessage(language.getText('search_cancel_executed'));
      eventLabel.keyword = this.state.searchText;
    }
    if (eventCategory) {
      const gtmData = {
        dataLayer: {
          event: 'search_cancel_teacher',
          event_category: eventCategory,
          event_action: eventAction,
          event_label: null,
          user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
          keyword: (this.state.searchText) !== '' ? this.state.searchText : null,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }
    this.setState({
      searchText: '',
      keywordSearchOccured: false
    }, () => {
      if (Object.keys(this.state.filterQueue).length > 0) {
        this.handleSearchCall(true);
      } else {
        this.handleSearchCall(null);
      }
    });
  }

  handleCancelClick(isFilterCancel) {
    const { intl, language } = this.props;
    DataFormatter.setObjectInStorage('restrictedProductCall', false);
    DataFormatter.setObjectInSessionStorage('searchState', null);
    DataFormatter.setObjectInSessionStorage('tempSearchState', null);
    if (document.getElementById('hiddenCountOfChip')) {
      document.getElementById('hiddenCountOfChip').setAttribute('value', 0);
    }
    if (typeof this.props.resizeBaacWindow === 'function') {
      this.props.resizeBaacWindow(false);
    }
    const eventLabel = { userId: DataFormatter.getKeyFromObject('userInformation', 'id') };
    let eventCategory; let eventAction; let
      event = '';
    const filterObj = PrepareSearchObjectUtil.prepareSearchObject(this.state.searchText, this.state.filterQueue);
    if (this.state.searchText) {
      eventCategory = intl.formatMessage(language.getText('search_cancel'));
      eventAction = intl.formatMessage(language.getText('search_cancel_executed'));
      eventLabel.keyword = this.state.searchText;
      event = 'search_cancel_teacher';
    }
    if (filterObj) {
      if (filterObj.hasOwnProperty('metadata')) {
        if (Object.keys(filterObj.metadata).length !== 0) {
          eventCategory = intl.formatMessage(language.getText('filter_cancel'));
          eventAction = intl.formatMessage(language.getText('filter_cancel_executed'));
          eventLabel.filterOption = filterObj.metadata;
          event = 'filter_cancel_teacher';
        }
      }
    }

    if (eventCategory) {
      const gtmData = {
        dataLayer: {
          event,
          event_category: eventCategory,
          event_action: eventAction,
          event_label: null,
          user_id: DataFormatter.getKeyFromObject('userInformation', 'id'),
          keyword: (this.state.searchText) !== '' ? this.state.searchText : null,
          filter_option: `Number of filters cancelled: ${(filterObj && filterObj.hasOwnProperty('metadata')) ? Object.keys(filterObj.metadata).length : null}`,
          transaction_local_dt: CommonUtils.getLocalIsoTime()
        }
      };
      TagManager.dataLayer(gtmData);
    }

    let updateState = {
      searchText: null,
      showFilter: false,
      filterQueue: {},
      chipData: [],
      sectionData: {},
      selectedAll: false,
      showSelectAllButton: false,
      keywordSearchOccured: false,
      hideFilter: false
    };
    if (isFilterCancel) {
      updateState = {
        showFilter: false,
        filterQueue: {},
        chipData: [],
        sectionData: {},
        hideFilter: false,
        hiddenCountOfChip: 0
      };
    }

    this.setState(updateState, () => {
      this.handleAutoCompleteHide(() => {
        if (isFilterCancel) {
          if ((typeof this.state.searchText === 'string' && this.state.searchText.trim() != '')) {
            this.handleSearchCall(true);
          } else {
            this.handleSearchCall(null, true);
          }
        } else {
          this.handleSearchCall(null, true);
        }
      });
    });
  }

  handleFilterButtonClick() {
    if (typeof this.props.resizeBaacWindow === 'function') {
      this.props.resizeBaacWindow(this.state.hideFilter);
    }
    let hiddenCountOfChip = 0;
    if (document.getElementById('hiddenCountOfChip')) {
      hiddenCountOfChip = document.getElementById('hiddenCountOfChip').getAttribute('value');
      if (parseInt(hiddenCountOfChip)) {
        hiddenCountOfChip = parseInt(hiddenCountOfChip);
      } else {
        hiddenCountOfChip = 0;
      }
    }
    this.setState({ hideFilter: !this.state.hideFilter, hiddenCountOfChip });
  }

  handleFilterClick() {
    if (typeof this.props.resizeBaacWindow === 'function') {
      this.props.resizeBaacWindow(!this.state.showFilter);
    }

    const updateState = {
      showFilter: !this.state.showFilter,
      highlightFilterButton: false
    };
    if (this.state.showFilter) {
      updateState.filterQueue = {};
      updateState.chipData = [];
      updateState.sectionData = {};
      updateState.hiddenCountOfChip = 0;
    }
    this.setState(updateState, () => {
      this.setFilterPosition();
    });
  }

  setFilterPosition = () => {
    if (this.state.showFilter) {
      const primarySearchConsoleEle = this.searchComponentWrapper.current.querySelector('.primarySearchConsole');
      let primarySearchConsoleEleBCR;
      if (primarySearchConsoleEle) {
        primarySearchConsoleEleBCR = primarySearchConsoleEle.getBoundingClientRect();
      }
      const viewportOffset = this.searchComponentWrapper.current.parentNode.getBoundingClientRect();
      const top = viewportOffset.top; const
        scrollTopValue = document.getElementById('main').scrollTop;
      if (top > 0) {
        document.getElementById('main').scrollTop = (top + scrollTopValue);
      }
    }
  }

  handleFilterDoneClick(returnedFromPlayer, toShowFilter) {
    let showFilter = false;
    if (returnedFromPlayer) {
      showFilter = toShowFilter;
    }
    if (showFilter) {
      if (typeof this.props.resizeBaacWindow === 'function') {
        this.props.resizeBaacWindow(true);
      }
    }
    this.setState({
      showFilter
    }, () => {
      this.handleSearchCall(true);
    });
  }

  handleAutoCompleteClick = (event) => {
    let searchText = null;
    if (event.target.textContent !== '') {
      searchText = event.target.textContent;
      this.setState({
        searchText,
        showFilter: false,
        performSearchPending: true
      }, () => {
        this.handleAutoCompleteHide(() => {
          if (!this.state.autoCompleteLoading) {
            this.handleSearchCall(true, 1000);
          } else if (typeof this.props.enableLoading === 'function') {
            this.props.enableLoading();
          }
        });
      });
    }
  }

  handleAutoCompleteHide = (callback) => {
    this.setState({
      showAutoComplete: false
    }, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  selectAllSearchedBooks = function () {
    let allBooksSelectedEarlier = true;

    if (this.props.books && this.props.books.length > 0) {
      const booksShowing = [...this.props.books];
      for (let i = 0; i < booksShowing.length; i++) {
        if (!booksShowing[i].hasOwnProperty('isChecked')) {
          allBooksSelectedEarlier = false;
          break;
        } else if (!booksShowing[i].isChecked) {
          allBooksSelectedEarlier = false;
          break;
        }
      }
    }
    if (!allBooksSelectedEarlier) {
      this.props.selectAllSearchedBooks();
    }
  }

  resetChipCount = () => {
    this.setState({ hiddenCountOfChip: 0 });
  }

  UpdatehiddenCountOfChip = function (count) {
    this.hiddenCountOfChip = count;
    this.reload = false;
  }

  doSearchCall = (filterData, chips, selectedSections, checkForSearchObject, isCancelClicked) => {
    this.setState({
      filterQueue: filterData,
      chipData: chips,
      sectionData: selectedSections
    }, () => {
      this.handleSearchCall(checkForSearchObject, isCancelClicked);
    });
  }


  renderSecondaryHeader() {
    const { intl, language } = this.props;
    let headingElement;
    const isiPad = navigator.userAgent.match(/iPad/i) != null;
    if (isiPad) {
      headingElement = (<div style={styles.secondaryHeadingStyles} className="headingWrapperiPad">{this.props.headingElement}</div>);
    } else {
      headingElement = (<div style={styles.secondaryHeadingStyles} className="headingWrapper">{this.props.headingElement}</div>);
    }
    let selectAllButton = null;
    const buttonStyle = { float: 'right', marginTop: '14px', marginRight: '1.4%' };
    const backgroundColor = 'rgb(0, 133, 127)';
    const buttonLabel = intl.formatMessage(language.getText('select_all'));
    if (this.state.showFilter && !this.state.hideFilter) {
      buttonStyle.marginRight = '320px';
    }

    if (this.props.books && this.props.books.length > 0 && this.props.booksViewMode !== 'viewSelected') {
      if ((this.state.keywordSearchOccured && this.state.searchText) || this.state.chipData.length > 0) {
        selectAllButton = (
          <div
            ref={this.selectAllButton}
            style={buttonStyle}
          >
            <Button
              variant="contained"
              className="select-all-search"
              onClick={this.selectAllSearchedBooks}
              labelStyle={styles.flatButtonLabelStyles}
              backgroundColor={backgroundColor}
            >
              {buttonLabel}
            </Button>
          </div>
        );
      }
    }

    return (
      <div
        ref={this.secondarySearchHeaderWrapper}
        className="secondarySearchHeaderWrapper searchHeaderWrapper"
        style={{ height: '80px' }}
      >
        <div style={{ float: 'left', display: 'flex' }} className="secondarySearchsubwrapper">
          {headingElement}
        </div>
        {selectAllButton}
      </div>
    );
  }

  renderSecondarySearchConsole() {
    const { intl, language } = this.props;
    const searchFilterStyle = {};
    const isiPad = navigator.userAgent.match(/iPad/i) != null;
    const that = this;
    if (isiPad) {
      searchFilterStyle.marginRight = 0;
    }
    if (this.props.showingSendBookFooter) {
      searchFilterStyle.height = '84%';
    }

    const searchConsoleStyle = styles.searchConsoleStyle;
    const filterButtonLabel = intl.formatMessage(language.getText('filterButtonLabel'));
    let filterButton = (
      <Button
        variant="contained"
      // labelPosition="before"
      // style={styles.flatButtonStyles}
      // labelStyle={styles.flatButtonLabelStyles}
        classes={{ root: 'flatButtonStyles', label: 'flatButtonLabelStyles' }}
        onClick={this.handleFilterClick}
        backgroundColor="transparent"
      >
        {filterButtonLabel}
      </Button>
    );

    if (this.state.showFilter) {
      filterButton = (
        <div>
          <Button
            variant="contained"
            labelPosition="before"
        // style={styles.flatButtonStyles}
            classes={{ root: 'flatButtonStyles', label: 'flatButtonLabelStyles' }}
            labelStyle={styles.flatButtonLabelStyles}
            onClick={this.handleFilterButtonClick.bind(this, true)}
            backgroundColor="transparent"
          >
            {filterButtonLabel}
          </Button>
          <CloseIcon
            id="filterCloseIcon"
            tabIndex="0"
            style={{ ...styles.filterCloseIconStyle, color: '#fff' }}
            onClick={this.handleCancelClick.bind(this, true)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                this.handleCancelClick(true);
              }
            }}
          />
        </div>
      );
    }
    if (this.state.hideFilter && this.state.chipData.length > 0) {
      const buttonLabel = `${intl.formatMessage(language.getText('filterButtonLabel'))}(${this.state.chipData.length})`;
      filterButton = (
        <div>
          <Button
            variant="contained"
            id="filterButton"
            labelPosition="before"
            style={{ ...styles.flatButtonStyles }}
            labelStyle={styles.filterButtonWithCountLabelStyles}
            onClick={this.handleFilterButtonClick.bind(this, true)}
            backgroundColor="#ffffff"
            borderRadius="2px"
          >
            {buttonLabel}
          </Button>
        </div>
      );
    }

    const keywordSearch = (
      <div>
        <SearchIcon style={styles.searchIconStyle} color={styles.searchIconColor} onClick={this.searchSubmit.bind(this)} />
        <TextField
          inputRef={this.searchTextField}
          id="searchText"
          fullWidth
          onChange={this.handleSearchkeywordChange}
      // inputStyle={styles.searchTextBoxInputStyle}
          placeholder={intl.formatMessage(language.getText('keywordSearch'))}
          aria-label={intl.formatMessage(language.getText('keywordSearch'))}
          autoComplete="off"
          value={this.state.searchText ? this.state.searchText : ''}
          type="text"
        // hintStyle={styles.searchfloatingLabelStyle}
          underlineShow={false}
          style={styles.searchTextBoxStyle}
          classes={{ root: 'searchTextBoxInputStyle' }}
        />
        <CloseIcon style={styles.closeIconStyle} color={styles.searchIconColor} onClick={this.searchClear.bind(this)} className={(this.state.autoCompleteLoading > 0 ? ' hidden' : ' ')} />
      </div>
    );
    return (
      <div ref={this.primarySearchConsoleWrapper} className={`secondarySearchConsoleWrapper${this.state.showAutoComplete ? ' showAutoComplete' : ''}`}>
        <div className="primarySearchConsoleBackDrop " />
        <div id="searchConsole" className="primarySearchConsole " style={{ padding: '0 0 0 4%', backgroundColor: '#00857f' }}>
          <div id="searchFieldWrapper" className="primarySearchFieldWrapper " style={searchConsoleStyle}>
            {this.props.showKeywordSearch ? keywordSearch : ''}
          </div>
          {this.state.showAutoComplete ? this.renderDropdown() : null}
          <div id="filterButtonWrapper" className={`activeFilterButtonWrapper${this.state.showFilter ? ' activeFilterButton' : ''}`}>
            {this.props.showFilterSearch ? filterButton : ''}
          </div>
        </div>
        <FilterComponent
          showingSendBookFooter={this.props.showingSendBookFooter}
          mode="secondary"
          hiddenCountOfChip={this.hiddenCountOfChip}
          UpdatehiddenCountOfChip={count => this.UpdatehiddenCountOfChip(count)}
          reload={this.reload}
          resetChipCount={this.resetChipCount}
          showFilter={this.state.showFilter}
          hideFilter={this.state.hideFilter}
          doSearchCall={this.doSearchCall}
          filterQueue={this.state.filterQueue}
          chipData={this.state.chipData}
          searchText={this.state.searchText}
          sectionData={this.state.sectionData}
        />

      </div>
    );
  }

  render() {
    return (
      <div className="mainSearchControlWrapper">
        <div ref={this.searchComponentWrapper}>
          {this.renderSecondarySearchConsole()}
        </div>
        {this.renderSecondaryHeader()}
      </div>
    );
  }
}

// SecondaryRefineSearchConsole.contextTypes = {
//   router: React.PropTypes.object.isRequired
// }

SecondaryRefineSearchConsole.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language'),
  observer
)(injectIntl(SecondaryRefineSearchConsole));
