import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const TeacherLicenseExpiryMsg = ({
  show, message, handleClose, onMouseEnter, onMouseLeave, animateStatus, color
}) => {
  if (show === true) {
    return (
      <Snackbar
        className={animateStatus}
        open={show}
        // onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onMouseOver={onMouseEnter}
        onMouseOut={onMouseLeave}

      >
        <div className="teacher-message-snackbar-inner">
          <div className="teacher-snackbar-info">
            <i className={color == 'amber' ? 'material-icons material-icons-black' : 'material-icons'}>info_outline</i>
          </div>
          <div className={color == 'amber' ? 'teacher-snackbar-message-block teacher-snackbar-message-block-black' : 'teacher-snackbar-message-block teacher-snackbar-message-block-white'}>
            <div>{message}</div>
          </div>
          <div id="teacher_snackbar_message_close_unique" className={color == 'amber' ? 'teacher-snackbar-close teacher-snackbar-close-black' : 'teacher-snackbar-close teacher-snackbar-close-white'} onClick={handleClose}>
          X
          </div>
        </div>
      </Snackbar>
    );
  }
  return false;
};
export default TeacherLicenseExpiryMsg;
