import env from '../env';

const esmx = {
  'bookshelf.TITLE': 'Mi estantería',
  'common.GO_HOME': 'Ir al inicio',
  'common.LOGOUT': 'Cerrar sesión',
  'common.PURCHASE': 'Comprar',
  'courses.action.SHOW_ASSIGNMENTS': 'Mostrar tareas',
  'courses.toc.PUBLISH_MESSAGE': 'Los estudiantes verán sus cambios la próxima vez que abran el contenido.',
  'courses.toc.PUBLISH_SUCCESS': '¡Éxito!',
  'courses.WELCOME': 'Hola, {userName}. ¡Bienvenido al curso {courseName}!',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Su cuenta no incluye acceso a este título. Si cree que recibió este mensaje por error, comuníquese con el soporte técnico de Pearson.',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'No se encontró la suscripción.',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Su suscripción no incluye acceso al texto electrónico; sin embargo, puede suscribirse ahora mismo con una tarjeta de crédito.',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'No se encontró la suscripción.',
  'foxit.ABOUT': 'Información',
  'foxit.COPY_RIGHT': 'PDF Reader activado por Foxit. Copyright (C) 2003-2019 por Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.ABOUT': 'Información',
  'menu.ACCESSIBILITY': 'Accesibilidad',
  'menu.AVAILABLE': 'También disponible',
  'menu.CONTACT_SUPPORT': 'Comunicarse con soporte',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Todos los derechos reservados.',
  'menu.FOXIT': 'Activada por Foxit',
  'menu.GET_LOOSE_LEAF': 'Obtener hoja suelta',
  'menu.PRINT_PAGE': 'Imprimir página',
  'menu.PRINT_VERSION': '¿Desea obtener la versión impresa?',
  'menu.PRIVACY_POLICY': 'Política de privacidad',
  'menu.SIGN_OUT': 'Cerrar sesión',
  'menu.TERMS_OF_USE': 'Condiciones de uso',
  'menu.tooltip.SCHEDULE': 'Programa',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hola, {userName}',
  'device.SWAPPED_ALREADY': 'Ya ha hecho un intercambio este mes',
  'device.CONTACT_SUPPORT': 'Espere al próximo mes o contacte al soporte para obtener más ayuda.',
  'device.SWITCH_DEVICE': '¿Cambiar al dispositivo actual?',
  'device.SWITCH_ONCE': 'Dado que solo puede cambiar una vez al mes, no podrá deshacer esta acción.',
  'device.SEAT_TAKEN': 'Ese asiento está ocupado',
  'device.DISCONNECT_DEVICE': 'Solo puede iniciar sesión en 3 dispositivos. Para continuar aquí, desconecte otro dispositivo.',
  'device.ALERT_TEXT': 'Recuerde que solo puede cambiar los dispositivos una vez al mes.',
  'device.YOUR_MOJO': 'Está en camino hacia Mojo'
};

export default esmx;
