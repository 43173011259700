import env from '../env';

const ptBr = {
  'bookshelf.TITLE': 'Minha estante de livros',
  'common.LOGOUT': 'Sair',
  'common.GO_HOME': 'Ir para página inicial',
  'common.PURCHASE': 'Comprar',
  'courses.WELCOME': 'Olá {userName}, boas vindas ao seu curso {courseName} !',
  'courses.action.SHOW_ASSIGNMENTS': 'Mostrar Tarefas',
  'courses.toc.PUBLISH_SUCCESS': 'Sucesso!',
  'courses.toc.PUBLISH_MESSAGE': 'Os alunos verão as alterações na próxima vez que abrirem o conteúdo.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Assinatura não encontrada',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Sua conta não inclui acesso para este título. Se você acredita que receber esta mensagem tenha
      sido errado, entre em contato com o Suporte Técnico da Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Assinatura não encontrada',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Sua assinatura não inclui acesso ao eText, porém você pode assinar agora mesmo utilizando 
    um cartão de crédito.`,
  'foxit.ABOUT': 'Sobre',
  'foxit.COPY_RIGHT': 'Leitor de PDF distribuído pela Foxit. Direito autoral (C) 2003-2019 Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': 'Entrar em contato com Suporte',
  'menu.ABOUT': 'Sobre',
  'menu.ACCESSIBILITY': 'Acessibilidade',
  'menu.FOXIT': 'Distribuído pela Foxit',
  'menu.TERMS_OF_USE': 'Termos de Uso',
  'menu.PRIVACY_POLICY': 'Política de Privacidade',
  'menu.SIGN_OUT': 'Desconectar',
  'menu.PRINT_PAGE': 'Imprimir página',
  'menu.GET_LOOSE_LEAF': 'Obter versão de folhas soltas',
  'menu.AVAILABLE': 'Também disponível',
  'menu.PRINT_VERSION': 'Deseja a versão impressa??',
  'menu.COPY_RIGHT': 'Direito autoral \u00A9 {year} Pearson Education Inc. Todos os direitos reservados.',
  'menu.tooltip.SCHEDULE': 'Agenda',
  'print.WATERMARK': 'Direito autoral © {year} Pearson Education Inc.',
  'product.WELCOME': 'Olá {userName}',
  'device.SWAPPED_ALREADY': 'Você já fez a troca este mês',
  'device.CONTACT_SUPPORT': 'Aguarde até o próximo mês ou entre em contato com o suporte para obter ajuda adicional.',
  'device.SWITCH_DEVICE': 'Mudar para o atual dispositivo?',
  'device.SWITCH_ONCE': 'Como só é possível fazer uma troca por mês, você não poderá desfazer esta ação.',
  'device.SEAT_TAKEN': 'Essa vaga está ocupada',
  'device.DISCONNECT_DEVICE': 'Você só pode fazer login em 3 dispositivos. Para continuar aqui, desconecte outro dispositivo.',
  'device.ALERT_TEXT': 'Lembre-se de que você pode trocar de dispositivos uma vez por mês.',
  'device.YOUR_MOJO': 'Você está prestes a acessar o mojo'
};

export default ptBr;
