import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import {
  createTheme, withStyles, makeStyles, ThemeProvider
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import lockIcon from '../../assets/images/young_learners/message-lock.png';

const BootstrapButton = withStyles({
  root: {
    width: '675px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 24,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#fc5b43',
    borderColor: '#fff',
    color: '#fff',
    fontFamily: [
      'HeinemannSpecial-Bold'
    ].join(','),
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#fff',
      color: '#017fa3',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#fc5b43',
      borderColor: '#fff'
    },
    '&:onClick': {
      backgroundColor: '#fc5b43',
      borderColor: '#fff',
      color: '#fff'
    }
  }
})(Button);


const LockMessage = ({ showLockMessage, handleLockClose, props }) => {
  const { language, intl } = props;
  if (showLockMessage.show === true) {
    return (
      <Snackbar
        className="rectangle-message-snackbar pad0-marg0"
        open={showLockMessage.show}
        onClose={handleLockClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <div className="rectangle-message lock">
          <div className="container-fluid">
            <div className="row lock-message-pad">
              <div className="col">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        {intl.formatMessage(language.getText('your_account_locked'))}
                        <img
                          src={lockIcon}
                          className="lock-message-icon"
                        />
                        <br />
                        {intl.formatMessage(language.getText('your_account_locked_1'))}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <BootstrapButton onClick={handleLockClose} variant="contained" color="primary" disableRipple className="lock-message-close-btn">
                    {intl.formatMessage(language.getText('close_msg'))}
                  </BootstrapButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Snackbar>
    );
  }
  return <div />;
};
export default LockMessage;
