const it = {
  'bookshelf.title': 'Il mio libretto',
  'common.LOGOUT': 'Disconnessione',
  'common.GO_HOME': 'Vai alla pagina iniziale',
  'common.PURCHASE': 'Acquista',
  'courses.WELCOME': 'Buongiorno {userName}, benvenuto al tuo corso {courseName}!',
  'courses.toc.PUBLISH_SUCCESS': 'Successo!',
  'courses.toc.PUBLISH_MESSAGE': 'Gli studenti vedranno le tue modifiche la prossima volta che apriranno i contenuti.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Sottoscrizione non trovata',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Il tuo account non può accedere a questo titolo. Se pensi di aver ricevuto questo
    messaggio per sbaglio, contatta l’Assistenza tecnica Pearson.`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Sottoscrizione non trovata',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `La tua sottoscrizione non include l’accesso al testo. Tuttavia, puoi registrarti
    adesso usando una carta di credito.`,
  'foxit.ABOUT': 'Info',
  'foxit.COPY_RIGHT': 'Lettore PDF alimentato da Foxit. Copyright (C) 2003-2019 di Foxit Software Incorporated',
  'link.ACCESSIBILITY': 'https://it.pearson.com/accessibilita.html',
  'link.PRIVACY': 'https://it.pearson.com/privacy-policy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://it.pearson.com/supporto.html',
  'link.TOSURL': 'https://it.pearson.com/termini-e-condizioni-uso.html',
  'menu.CONTACT_SUPPORT': 'Contatta l’Assistenza',
  'menu.ABOUT': 'Info',
  'menu.ACCESSIBILITY': 'Accessibilità',
  'menu.FOXIT': 'Offerto da Foxit',
  'menu.TERMS_OF_USE': 'Termini di utilizzo',
  'menu.PRIVACY_POLICY': 'Informativa sulla privacy',
  'menu.SIGN_OUT': 'Disconnettiti',
  'menu.PRINT_PAGE': 'Stampa pagina',
  'menu.GET_LOOSE_LEAF': 'Ottieni versione con pagine sfuse',
  'menu.AVAILABLE': 'Anche disponibile',
  'menu.PRINT_VERSION': 'Vuoi la versione stampata?',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Tutti i diritti riservati',
  'menu.tooltip.SCHEDULE': 'Programmazione',
  'print.FOOTER_MESSAGE': 'Stampato da {fullName}({email}) il {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Buongiorno {userName}',
  'device.SWAPPED_ALREADY': 'Per questo mese hai già effettuato lo scambio',
  'device.CONTACT_SUPPORT': 'Tunggu hingga bulan berikutnya, atau hubungi dukungan untuk bantuan lebih lanjut.',
  'device.SWITCH_DEVICE': 'Beralih ke perangkat saat ini?',
  'device.SWITCH_ONCE': 'Poiché puoi scambiare solo una volta al mese, non ti sarà possibile revocare questa azione.',
  'device.SEAT_TAKEN': 'Quel posto è occupato',
  'device.DISCONNECT_DEVICE': 'Puoi eseguire il login solo su 3 dispositivi. Per proseguire qui, scollega un altro dispositivo.',
  'device.ALERT_TEXT': 'Ricorda che puoi scambiare dispositivi solo una volta al mese.',
  'device.YOUR_MOJO': 'Stai raggiungendo mojo'
};

export default it;
