import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007da7'
    }
  }
});


class NotificationComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    books: this.props.books,
    length: this.props.books.length
  }

  componentDidMount = function () {
    // AppStore.on(AppConstants.EventTypes.USER_PRODUCTS_FETCHED_SUCCESS, this.userProductsFetched);
    Framework.getEventManager().on(constants.USER_PRODUCTS_FETCHED_SUCCESS, (respData) => {
      this.userProductsFetched(respData);
    });
    // AppStore.on(AppConstants.EventTypes.STORED_NEW_BOOK_COUNT, this.updateCount);
    Framework.getEventManager().on(constants.STORE_NEW_BOOK_COUNT, (respData) => {
      this.updateCount(respData);
    });
    try {
      $('.notificationsList')[0].style.height = `${window.innerHeight - 164 - 24}px`;
      $('.mainAppContainer')[0].style.overflowY = 'hidden';
    } catch (error) {}
  }

  updateCount = (respData) => {
    const newBooks = respData;// AppStore.newBooks;
    let length = 0;
    for (let i = 0; i < newBooks.length; i++) {
      if (newBooks[i].coverImageURL) {
        length++;
      }
    }

    if (JSON.stringify(newBooks) != JSON.stringify(this.state.books)) {
      this.setState({
        books: newBooks,
        length
      });
    }
  }


  userProductsFetched = (respData) => {
    // this.fetchNewBookCountsToStore(AppStore.productListData);
    this.fetchNewBookCountsToStore(respData);
  }

  userBooksFetched = () => {
    this.fetchNewBookCountsToStore(AppStore.userBookList);
  }


fetchNewBookCountsToStore = (productList) => {
  const booksList = this.props.fetchNewBookCountsToStore(productList);
  const length = booksList.length;
  if (JSON.stringify(booksList) != JSON.stringify(this.state.books)) {
    this.setState({
      books: booksList,
      length
    });
  }
}

  componentWillUnmount = function () {
    // AppStore.removeListener(AppConstants.EventTypes.USER_PRODUCTS_FETCHED_SUCCESS, this.userProductsFetched);
    try {
      $('.mainAppContainer')[0].style.overflowY = 'scroll';
    } catch (error) {}
  }

  renderImage = (book) => {
    if (book.coverImageURL != '' && book.coverImageURL != undefined) {
      return (<img alt={book.title} src={book.coverImageURL} />);
    }
    return null;
  }

  renderThumbnail = (book, index) => (
    <div className="bookThumbanil" tabIndex={0}>
      {this.renderImage(book)}
    </div>
  )

  stopClosingNotifications = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  handleAccessibilityNotification = (event) => {
    const isTabPressed = event.key === 'Escape' || event.key === 'Enter' || event.key === 'Tab' || event.keyCode === 9 || event.shiftKey;
    if (!isTabPressed) {
      return;
    }
    if (event.key === 'Tab') {
      if (event.target.id == 'notificationsFooter') {
        if (document.getElementById('take_a_look_btn') && this.state.length > 0) {
          document.getElementById('take_a_look_btn').focus();
          event.preventDefault();
        } else {
          document.getElementById('notificationsList').focus();
          event.preventDefault();
        }
      } else if (event.target.id == 'notificationsList') {
        document.getElementById('notificationsFooter').focus();
        event.preventDefault();
      } else if (event.target.id == 'take_a_look_btn') {
        document.getElementById('notificationsList').focus();
        event.preventDefault();
      }
      return false;
    }
    if (event.key == 'Escape' && (event.target.id == '' || event.target.id == 'notificationContainer' || event.target.id == 'notificationsList' || event.target.id == 'notificationsFooter')) {
      this.props.closeNotifications();
      return false;
    }
  }

  showCount = () => {
    const { language, intl } = this.props;
    if (this.state.length > 0) {
      const text = this.state.length > 1 ? intl.formatMessage(language.getText('NoOfbooksSuffix')) : intl.formatMessage(language.getText('book'));
      return (
        <div className="notificationsMessage">{`${intl.formatMessage(language.getText('notificationMessagePrefix')) + this.state.length} ${text}.`}</div>
      );
    }
    return (
      <div className="notificationsMessage">{intl.formatMessage(language.getText('noNotificationMessagePrefix'))}</div>
    );
  }

  gotoBookbox = () => {
    this.setState({
      length: 0,
      books: []
    }, (function () {
      const currentTime = new Date().getTime();
      DataFormatter.setObjectInStorage('getNewBooksCount', true);
      DataFormatter.setObjectInStorage('isTakeANoteClicked', true);
      DataFormatter.setObjectInStorage('notificationsLastVisitedTime', currentTime);
      const userId = DataFormatter.getKeyFromObject('userInformation', 'id');
      // AppActions.switchToBookBox(userId);
      Framework.getEventManager().publish(constants.USER_PRODUCTS_FETCH_REQUESTED,
        {
          userId,
          includeReturned: false,
          searchlimit: 25,
          offset: 0,
          date: '-assignedDate'
        });
      Framework.getEventManager().publish(constants.SWITCH_TO_BOOKBOX, '');
      this.props.closeNotifications();
      if (DataFormatter.getObjectInStorage('userMode') === 'primary' && DataFormatter.getObjectInStorage('currentTab') === 'my-bookbox') {
        $('.primaryFilterBarWithNoSearchBarWrapper ul li button').removeClass('selected');
        $('.primaryFilterBarWithNoSearchBarWrapper .all').addClass('selected');
      }
    }));
  }

  render = function () {
    const { language, intl } = this.props;
    const that = this;
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className="notificationContainer" tabIndex="0" onClick={this.props.closeNotifications} onKeyDown={this.handleAccessibilityNotification.bind(this)}>
          <div id="notificationsList" className="notificationsList" tabIndex="0" onClick={this.stopClosingNotifications.bind(this)} onKeyDown={this.handleAccessibilityNotification.bind(this)}>
            {this.showCount()}
            { this.state.length > 0
              ? this.state.books.map(this.renderThumbnail, this) : '' }
            <div
              id="notificationsFooter"
              className="notificationsFooter"
              tabIndex="1"
              onKeyPress={this.stopClosingNotifications.bind(this)}
              onKeyDown={(e) => {
                if (e.key == 'Enter') { // e.keyCode == 13
                  that.stopClosingNotifications.call(that);
                }
                if (e.key == 'Tab' || e.key == 'Escape') {
                  this.handleAccessibilityNotification(e);
                }
              }}
              onClick={this.stopClosingNotifications.bind(this)}
            >
              <Button
                id="take_a_look_btn"
                variant="contained"
                className={this.state.length > 0 ? '' : 'hideTakeaLook'}
                onKeyPress={this.gotoBookbox.bind(this)}
                onKeyDown={(e) => {
                  if (e.key == 'Enter') { // e.keyCode == 13
                    that.gotoBookbox.call(that);
                  }
                  if (e.key == 'Escape') {
                    this.props.closeNotifications();
                  }
                }}
                onClick={this.gotoBookbox.bind(this)}
                classes={{ root: 'buttonStyle', label: 'buttonLabelStyle' }}
              >
                {intl.formatMessage(language.getText('take_a_look'))}
              </Button>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

NotificationComponent.defaultProps = {
};

NotificationComponent.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(NotificationComponent));
