/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Appcues utilities
 *
 * @file AppcuesUtils.js
 * @author Prithviraj K
 */

import Framework from '@greenville/framework';
import appcuesEventsList from './AppcuesEventsList';
import {
  APPCUES_IDENTIFY_USER,
  APPCUES_PAGE_CHANGE,
  APPCUES_SCRIPT_URL,
  APPCUES_TRACK_EVENT,
  APPCUES_USER_IDENTIFIED
} from '../constants';

export default class AppcuesUtils {
  /**
   * Function to handle Appcues events
   *
   * @param {string} event
   * @param {Object} userData
   * @param {Object} eventData
   */
  static handleEvent(event, userData = {}, eventData = {}) {
    if (window.Appcues) {
      switch (event) {
        case APPCUES_IDENTIFY_USER:
          window.Appcues.identify(userData.userId);
          Framework.getEventManager().publish(APPCUES_USER_IDENTIFIED, {});
          this.initializeEventListener();
          break;
        case APPCUES_PAGE_CHANGE:
          window.Appcues.page();
          break;
        case APPCUES_TRACK_EVENT:
          window.Appcues.track(eventData.eventName, eventData.eventProperties);
          break;
        default:
      }
    } else {
      this.injectScript().then(() => {
        this.handleEvent(event, userData, eventData);
      });
    }
  }

  /**
   * Function to initialize Appcues event listener globally
   *
   */
  static initializeEventListener() {
    /**
     * Function to pass events for Appcues goals
     *
     * @param {Object} data
     */
    window.passEventToAppcues = (data) => {
      const eventCategory = data.category && data.category.split('-')[0].trim();
      const eventAction = data.action && data.action;
      const eventData = appcuesEventsList.find(item => item.category === eventCategory && item.action === eventAction);

      if (eventData) {
        window.Appcues.track(eventData.eventName);
      }
    };
  }

  /**
   * Function to inject Appcues script file
   *
   */
  static injectScript() {
    return new Promise(((resolve) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = APPCUES_SCRIPT_URL;
      script.async = true;
      script.defer = true;
      script.addEventListener('load', () => {
        resolve();
      });
      document.getElementsByTagName('head')[0].appendChild(script);
    }));
  }
}
