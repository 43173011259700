/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import Framework from '@greenville/framework';

import ApplicationContainer from './ApplicationContainer';
import Books from './model/Books';
import BookService from './services/BookService';
import DlCourseForNavbar from './model/DlCourseForNavbar';
import DlCourseService from './services/CourseService';
import IESUserDetailsByCourse from './model/IESUserDetailsByCourse';
import IESUserDetailsByCourseService from './services/IESUserDetailsByCourseService';
import IESUserFetchService from './services/IESUserFetchService';
import SendBookService from './services/SendBookService';
import GetUserPermissionsService from './services/GetUserPermissionsService';
import IESNewUserFetchService from './services/IESNewUserFetchService';
import IESPasswordListFetchService from './services/IESPasswordListFetchService';
import CreateStudentService from './services/CreateStudentService';
import CreateBulkStudentService from './services/CreateBulkStudentService';
import BulkEnrollUsersToSchoolClassService from './services/BulkEnrollUsersToSchoolClassService';
import EnrollUserToSchoolClassService from './services/EnrollUserToSchoolClassService';
import AnalyticsCollectiveDataFetchService from './services/AnalyticsCollectiveDataFetchService';
import AnalyticsCollectiveDataListService from './services/AnalyticsCollectiveDataListService';
import GetUserBatchServiceService from './services/GetUserBatchService';
import IngestionGetBatchStatusService from './services/IngestionGetBatchStatusService';
import IngestionGetBatchItemsService from './services/IngestionGetBatchItemsService';
import EditStudentService from './services/EditStudentService';
import EditClassNameService from './services/EditClassNameService';
import DeleteStudentService from './services/DeleteStudentService';
import DownloadStudentPasswordLetterService from './services/DownloadStudentPasswordLetterService';
import FetchProductByUserIdService from './services/FetchProductByUserIdService';
import GetChartYaxisService from './services/GetChartYaxisService';
import ReturnBookService from './services/ReturnBookService';
import GetFilterDataService from './services/GetFilterDataService';
import GetSearchProductsService from './services/GetSearchProductsService';
import GetProductBySchoolIdService from './services/GetProductBySchoolIdService';
import GetProductsListForAutoCompleteService from './services/GetProductsListForAutoCompleteService';
import GetSearchForRelatedProductsService from './services/GetSearchForRelatedProductsService';
import FetchActivityAttemptsByUserIdService from './services/FetchActivityAttemptsByUserIdService';
import AssetDetails from './model/AssetDetails';
import FetchAssetDetailsService from './services/FetchAssetDetailsService';
import FetchSearchUserProductsService from './services/FetchSearchUserProductsService';
import FetchBookReacdCountByUserIdService from './services/FetchBookReacdCountByUserIdService';
import GetNewBooksForNotificationService from './services/GetNewBooksForNotificationService';
import DlUserService from '../../common/services/DlUserService';
import DlUser from '../../common/model/DlUser';
import DlUserSchoolService from '../../common/services/DlUserSchoolService';
import DlUserSchool from '../../common/model/DlUserSchool';
import DlConfigService from '../../common/services/DlConfigService';
import DlConfig from '../../common/model/DlConfig';
import DlServerYearService from '../../common/services/DlServerYearService';
import DlServerYear from '../../common/model/DlServerYear';
import DlAppConfigService from '../../common/services/DlAppConfigService';
import DlAppConfig from '../../common/model/DlAppConfig';
import env from '../../common/env';
import * as constants from '../../common/constants';

Framework.getClientManager().add({
  name: 'hapi',
  authHeader: constants.X_AUTHORIZATION,
  timeout: env.HAPI_API_TIMEOUT,
  baseUrl: env.HAPI_API_BASE_URL,
  tokenPrefix: 'Bearer '
});

export default {
  path: '/app',
  exact: true,
  services: [
    new DlUserService(),
    new DlUserSchoolService(),
    new DlConfigService(),
    new DlServerYearService(),
    new DlAppConfigService(),
    new BookService(),
    new DlCourseService(),
    new IESUserDetailsByCourseService(),
    new IESUserFetchService(),
    new SendBookService(),
    new GetUserPermissionsService(),
    new IESNewUserFetchService(),
    new IESPasswordListFetchService(),
    new CreateStudentService(),
    new CreateBulkStudentService(),
    new BulkEnrollUsersToSchoolClassService(),
    new EnrollUserToSchoolClassService(),
    new AnalyticsCollectiveDataFetchService(),
    new AnalyticsCollectiveDataListService(),
    new GetUserBatchServiceService(),
    new IngestionGetBatchStatusService(),
    new IngestionGetBatchItemsService(),
    new EditStudentService(),
    new EditClassNameService(),
    new DeleteStudentService(),
    new DownloadStudentPasswordLetterService(),
    new FetchProductByUserIdService(),
    new GetChartYaxisService(),
    new ReturnBookService(),
    new GetFilterDataService(),
    new GetSearchProductsService(),
    new GetProductBySchoolIdService(),
    new GetProductsListForAutoCompleteService(),
    new GetSearchForRelatedProductsService(),
    new FetchActivityAttemptsByUserIdService(),
    new FetchAssetDetailsService(),
    new FetchSearchUserProductsService(),
    new FetchBookReacdCountByUserIdService(),
    new GetNewBooksForNotificationService()
  ],
  stores: [
    {
      name: 'dlBooks',
      spec: Books,
      saveLocally: false,
      initialState: { items: [] }
    },
    {
      name: 'dlCourseForNavbar',
      spec: DlCourseForNavbar,
      saveLocally: false,
      initialState: {
        items: []
      }
    },
    {
      name: 'iesUserDetailsByCourse',
      spec: IESUserDetailsByCourse,
      saveLocally: false,
      initialState: {
        items: []
      }
    },
    {
      name: 'dlUser',
      spec: DlUser,
      saveLocally: false,
      initialState: {
        id: '',
        createdAt: '',
        updatedAt: '',
        accountStatus: '',
        lifecycleStatus: '',
        homeCountryCode: '',
        givenName: '',
        familyName: '',
        emailAddress: '',
        userName: '',
        resetPassword: false,
        lastLoginSuccess: ''
      }
    },
    {
      name: 'dlUserSchool',
      spec: DlUserSchool,
      saveLocally: false,
      initialState: {
        id: '',
        name: '',
        saId: '',
        countryCode: '',
        geoId: '',
        geoName: '',
        saName: '',
        roleValue: '',
        createdBy: '',
        isSelfEnrolled: false,
        products: [
          {
            productId: '',
            startDate: '',
            endDate: ''
          }
        ],
        links: {
          heroImage: '',
          terms: '',
          privacy: '',
          cookie: '',
          appConfig: '',
          appConfigAPI: '',
          services: '',
          website: '',
          textbooksThumbnail: '',
          languagePackAPI: ''
        },
        serviceDirectory: {
          coursesectionApi: {
            tenants: [
              {
                tag: '',
                hawkId: '',
                auth: ''
              }
            ],
            url: ''
          },
          aapi: {
            tenants: [
              {
                hawkId: '',
                auth: '',
                tag: ''
              }
            ],
            url: ''
          },
          bookshelfApiV0: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          bookshelfApiV2: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          annotationsApi: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          parentServiceApi: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          notificationsApi: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          preferencesApi: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          parkinglot: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          caas: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          },
          discussionsApi: {
            tenants: [
              {
                tag: '',
                auth: '',
                hawkId: ''
              }
            ],
            url: ''
          }
        },
        metadata: {
          licenses: [
            {
              numberOfSeats: 0,
              subscriptionLength: 0
            }
          ],
          SACode: '',
          graceBanner: '',
          gracePeriod: '',
          provision_in_GLS: false,
          send_email_notification: false,
          trialBanner: '',
          trialPeriod: '',
          graceBannerLink: '',
          trialBannerLink: '',
          isTrial: false
        }
      }
    },
    {
      name: 'dlConfig',
      spec: DlConfig,
      saveLocally: false,
      initialState: {
        AppID: '',
        BaseUrl: '',
        glsHawkId: '',
        glsHawkKey: '',
        glsHawkAlgorithm: '',
        pulseHawkId: '',
        pulseHawkKey: '',
        pulseHawkAlgorithm: '',
        cwtHawkKey: '',
        readerHawkId: '',
        readerHawkKey: '',
        readerHawkAlgorithm: '',
        pulseauthGABUrl: '',
        pulseapiGABUrl: '',
        pulseapiId: '',
        pulseapiKey: '',
        pulseapiAlgorithm: '',
        v3HawkId: '',
        v3HawkKey: '',
        arsHawkId: '',
        arsHawkKey: '',
        arsHawkAlgorithm: '',
        arsTenantTag: '',
        gaId: '',
        gaEnabled: false,
        gabProductIds: {},
        caas: {},
        arsUrl: '',
        aapiUrl: '',
        aapiHawkId: '',
        aapiHawkKey: '',
        aapiHawkAlgorithm: '',
        studentAnalytics: '',
        iesProxyHawkUrl: '',
        iesProxyHawkId: '',
        iesProxyHawkKey: '',
        iesProxyHawkAlgorithm: '',
        courseSectionUrl: '',
        younglearnersiesUrl: '',
        younglearnersproductId: '',
        gabHawkUrl: '',
        gabHawkId: '',
        gabHawkSecret: '',
        gabHawkAlgorithm: '',
        ingestionHawkUrl: '',
        ingestionHawkId: '',
        ingestionHawkKey: '',
        ingestionHawkAlgorithm: '',
        tenantId: '',
        tenantKey: '',
        env: ''
      }
    },
    {
      name: 'dlServerYear',
      spec: DlServerYear,
      saveLocally: false,
      initialState: {
        copyRightYear: ''
      }
    },
    {
      name: 'dlAppConfig',
      spec: DlAppConfig,
      saveLocally: false,
      initialState: {
        config_version: '',
        readingui: {},
        bookshelf_filters: {},
        appui_mode: {},
        dashboard: {},
        activities: {},
        help: {}
      }
    },
    {
      name: 'assetDetails',
      spec: AssetDetails,
      saveLocally: false,
      initialState: {
        assets: [],
        hasNext: false,
        next: '',
        total: 0
      }
    }
  ],
  render: props => (<ApplicationContainer {...props} />)
};
