import env from '../env';

const zhcn = {
  'bookshelf.TITLE': '我的书架',
  'common.LOGOUT': '注销',
  'common.GO_HOME': '前往主页',
  'common.PURCHASE': '购买',
  'courses.WELCOME': '{userName} 您好，欢迎来到您的 {courseName} 课程！',
  'courses.action.SHOW_ASSIGNMENTS': '显示作业',
  'courses.toc.PUBLISH_SUCCESS': '成功！',
  'courses.toc.PUBLISH_MESSAGE': '学生将在下次打开内容时看到您的更改。',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': '订阅未找到',
  'errors.SUBSCRIPTION_GENERIC_DESC': '您的账户没有访问该章目的权限。如果您认为您错误接收了这条消息，请联系培生技术支持团队',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': '订阅未找到',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': '您的订阅不包括访问 eText，然而您可以选择使用信用卡立即订阅。',
  'foxit.ABOUT': '关于',
  'foxit.COPY_RIGHT': 'PDF 阅读器由 Foxit 提供支持。Copyright (C) 2003-2019 by Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': '联系客服团队',
  'menu.ABOUT': '关于',
  'menu.ACCESSIBILITY': '可及性',
  'menu.FOXIT': 'Foxit 提供支持',
  'menu.TERMS_OF_USE': '使用条款',
  'menu.PRIVACY_POLICY': '隐私政策。',
  'menu.SIGN_OUT': '注销',
  'menu.PRINT_PAGE': '打印页面',
  'menu.GET_LOOSE_LEAF': '获得活页',
  'menu.AVAILABLE': '獲得活頁',
  'menu.PRINT_VERSION': '想获得纸质版本吗？',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. 保留所有权利。',
  'menu.tooltip.SCHEDULE': '计划安排',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': '{userName} 您好',
  'device.SWAPPED_ALREADY': '您本月已经切换过了',
  'device.CONTACT_SUPPORT': '请等到下个月，或者联系客服团队获得更多帮助。',
  'device.SWITCH_DEVICE': '切换到当前设备？',
  'device.SWITCH_ONCE': '由于您每月只能进行一次切换，因此您无法撤销此操作。',
  'device.SEAT_TAKEN': '该名额已被占用',
  'device.DISCONNECT_DEVICE': '您只能在3台设备上登录。要从此处继续，请断开另一台设备。',
  'device.ALERT_TEXT': '请记住，您每月只能进行一次设备切换。',
  'device.YOUR_MOJO': '您正在前往Mojo'
};

export default zhcn;
