/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class IESUserFetchService extends BaseService {
  constructor() {
    super(constants.FETCH_IES_USER_REQUESTED, 'IESUserFetchServiceStatus', true);
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    headerParams = {
      headers: {
        usersarray: JSON.stringify(event.userIdArray).replace(/\\"/g, '"')
      }
    };
    return this.getClient('hapi').post(
      '/ies-user-fetch',
      {}, headerParams
    );
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data && response.data.data) {
      Framework.getEventManager().publish(constants.FETCH_IES_USER_SUCCESS, response.data);
    } else {
      Framework.getEventManager().publish(constants.IES_USER_LIST_EMPTY, {
        message: 'Unable to fetch user details for selected course'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.IES_USER_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
