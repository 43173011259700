/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

// Event definitions
import keyMirror from 'fbjs/lib/keyMirror';

export const APPCUES_APP_DOWNLOAD_FLOW_ID = 'ec557e96-93d9-4b18-86f2-6ccd99948bfd';
export const APPCUES_FLOW_STARTED = 'flow_started';
export const APPCUES_FLOW_COMPLETED = 'flow_completed';
export const APPCUES_IDENTIFY_USER = 'identify';
export const APPCUES_PAGE_CHANGE = 'page';
export const APPCUES_SCRIPT_URL = '//fast.appcues.com/64470.js';
export const APPCUES_TRACK_EVENT = 'track';
export const APPCUES_TRACK_BOOK_OPENED = 'Book opened';
export const APPCUES_TRACK_OPEN_HOME = 'Open Home';
export const APPCUES_USER_IDENTIFIED = 'APPCUES_USER_IDENTIFIED';
export const ASSESSMENT = 'assessment';
export const ASSET = 'asset';
export const ASSET_FETCHED = 'ASSET_FETCHED';
export const ASSET_REQUESTED = 'ASSET_REQUESTED';
export const ASSIGNMENT_FETCHED = 'ASSIGNMENT_FETCHED';
export const ASSIGNMENT_REQUESTED = 'ASSIGNMENT_REQUESTED';
export const AUDIO_BOOK_REQUESTED = 'AUDIO_BOOK_REQUESTED';
export const AUDIO_FLAG_ON = 'On';
export const AUDIO_FLAG_ON_BETA = 'On/Beta';
export const AUTH_VERIFIED = 'AUTH_VERIFIED';
export const AUTOBAHN_TIMEONTASK = 'AUTOBAHN_TIMEONTASK';
export const BACK_LINK_REQUESTED = 'BACK_LINK_REQUESTED';
export const BOOK = 'Book';
export const BOOKSHELF_DATA_FETCHED = 'BOOKSHELF_DATA_FETCHED';
export const BOOKSHELF_DATA_REQUESTED = 'BOOKSHELF_DATA_REQUESTED';
export const BUNDLED_PLATFORM = 'ETEXT2';
export const CG_RENEWALS = 'CG_renewals';
export const CHAPTER = 'Chapter';
export const COLLECTIONS = 'Collections';
export const COLLECTION_CLIENT_APP = 'ETEXT2_WEB';
export const CONTACT_SUPPORT = 'CONTACT SUPPORT';
export const CONTENT_TYPE_CITE = 'CITE';
export const CONTENT_TYPE_EPUB = 'EPUB';
export const CONTENT_TYPE_EPUB3 = 'EPUB3';
export const CONTENT_TYPE_PDF = 'PDF';
export const CONTENT_TYPE_PXE = 'PXE';
export const CONTENT_TYPE_SPDF = 'SPDF';
export const COURSE = 'Course';
export const COURSE_COLLECTION_REQUESTED = 'COURSE_COLLECTION_REQUESTED';
export const COURSE_DATA_FETCHED = 'COURSE_DATA_FETCHED';
export const COURSE_SYNC_REQUESTED = 'COURSE_SYNC_REQUESTED';
export const COURSE_WARE = 'courseWare';
export const CSG_APP_ID_PDF = 'etext1';
export const CUSTOM = 'custom';
export const CUSTOM_COLLECTIONS = 'CUSTOM_COLLECTIONS';
export const CUSTOM_COURSE = 'CUSTOM_COURSE';
export const CUSTOM_TOC_PUBLISH_REQUESTED = 'CUSTOM_TOC_PUBLISH_REQUESTED';
export const D2L_INTEGRATED_RENEWAL = 'D2L Integrated Renewal';
export const D2L_STANDALONE = 'D2L Standalone';
export const D2L_STANDALONE_RENEWAL = 'D2L Standalone Renewal';
export const DEFAULT_BOOK_COVER = '/default-book-cover.png';
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_LOCALE_US = 'en-us';
export const DIRECT_TO_LEARNER = 'directToLearner';
export const DIRECT_TO_LEARNER_RENEWAL = 'directToLearnerRenewal';
export const DIRECT_TO_CONSUMER = 'directToConsumerSubscription';
export const INTEGRATED_SMS = 'IntegratedSMS';
export const INTEGRATED_RUMBA = 'IntegratedRumba';
export const INTEGRATED_SMS_ETEXT = 'Integrated SMS eText';
export const INTEGRATED_RUMBA_ETEXT = 'Integrated Rumba eText';
export const PEARSON_PLUS_SINGLE = 'Pearson+ Single';
export const PEARSON_PLUS_MULTI = 'Pearson+ Multi';
export const INTEGRATED_ETEXT_3L = 'Integrated eText 3PL';
export const MOJO_US_SINGLE = 'BASIC';
export const CONTENT_LEARNING_MODE = 'Learn';
export const DND_TYPE = 'TableOfContents';
export const DRAWER_PANEL_CONTENT = 'Contents';
export const DRAWER_STATE_CLOSED = 'closed';
export const DRAWER_STATE_OPEN = 'open';
export const DROP_LEVEL_TYPE = 'WITH_IN_SAME_LEVEL';
export const EPUB_BRONTE = 'ETEXT_EPUB_BRONTE';
export const EPUB_BRONTE_STANDARD = 'ETEXT_EPUB_STANDARD';
export const ETEXT_PDF = 'ETEXT_PDF';
export const FOXIT = 'FOXIT';
export const FOXIT_LOGO = 'https://d38l3k3yaet8r2.cloudfront.net/etextpdfresources/foxitassets/hotspot_icons/foxit-logo.png';
export const FR_FR = 'fr-fr';
export const FR_CA = 'fr-ca';
export const GET_LOOSE_LEAF = 'GET LOOSE LEAF';
export const GLOSSARY = 'glossary';
export const GLOSSARY_HOTSPOT_FETCHED = 'GLOSSARY_HOTSPOT_FETCHED';
export const GLOSSARY_HOTSPOT_REQUESTED = 'GLOSSARY_HOTSPOT_REQUESTED';
export const GTM_REQUESTED = 'GTM_REQUESTED';
export const INTEGRATED_ETEXT = 'Integrated eText';
export const JA = 'ja';
export const JA_JP = 'ja_jp';
export const KO = 'ko';
export const KO_KR = 'ko_kr';
export const MANIFEST_REQUESTED = 'MANIFEST_REQUESTED';
export const MARIN = 'marin';
export const MS_IN_AN_HOUR = 60 * 60 * 1000;
export const MS_IN_A_DAY = 24 * MS_IN_AN_HOUR;
export const NOTES_COPY_REQUESTED = 'NOTES_COPY_REQUESTED';
export const NOTIFICATION = 'notification';
export const ODD = 'O';
export const PAGE_SEQUENCE_ASSET_REQUESTED = 'PAGE_SEQUENCE_ASSET_REQUESTED';
export const PDF_CLIENT_APP = 'ETEXT1_WEB';
export const IES_DEVICE_LIST_REQUESTED = 'IES_DEVICE_LIST_REQUESTED';
export const DEVICE_LIST_REQUEST_SUCCESS = 'DEVICE_LIST_REQUEST_SUCCESS';
export const DEVICE_SWAP_SUCCESS = 'DEVICE_SWAP_SUCCESS';
export const GPC_PREFERENCE_CREATE = 'PREFERENCE_CREATE';
export const GPC_PREFERENCE_CREATE_SUCCESS = 'GPC_PREFERENCE_CREATE_SUCCESS';
export const GPC_PREFERENCE_CREATE_FAILURE = 'GPC_PREFERENCE_CREATE_FAILURE';
export const GET_CURRENT_TIME = 'GET_CURRENT_TIME';
export const SCROM_ASSET_DETAILS_REQUESTED = 'SCROM_ASSET_DETAILS_REQUESTED';
export const SCROM_ASSET_DETAILS_FETCHED = 'SCROM_ASSET_DETAILS_FETCHED';
export const PDF_CONSTANTS = {
  FILE_FORMAT: {
    FLV_FORMAT: 'flv',
    SWF_FORMAT: 'swf'
  },
  LINK_TYPE: {
    AUDIO_TEXT_SYNCH: 10,
    CHROMELESS_URL: 15,
    EMAIL: 8,
    FACELESSAUDIO: 12,
    FLV: 2,
    GLOSSARY_TERM: 3,
    H264: 13,
    IMAGE: 1,
    IPADAPP: 14,
    JAZZASSET: 16,
    LTILINK: 11,
    MP3: 4,
    PAGE_NUMBER: 5,
    SWF: 6,
    URL: 7,
    VIRTUAL_LEARNING_ASSET: 9
  },
  REGION_TYPE: {
    AUDIO: 1,
    CROSS_REFERENCE: 2,
    EMAIL: 3,
    EXCEL: 13,
    FLASH: 4,
    GLOSSARY_TERM: 5,
    IMAGE: 6,
    INDEX_LINK: 7,
    IPADAPP: 17,
    JAZZASSET: 18,
    LTILINK: 16,
    MEDIA: 8,
    PDF: 14,
    POWERPOINT: 9,
    TOC_LINK: 10,
    URL: 11,
    VIDEO: 12,
    WORD_DOC: 15
  },
  USER_ROLE: {
    DEFAULT_ROLE: 'all',
    DEFAULT_ROLE_ID: 1,
    INSTRUCTOR: 'instructor',
    INSTRUCTOR_ID: 3,
    STUDENT: 'student',
    STUDENT_ID: 2
  }
};
export const PDF_COVER_PAGE = 'Cover';
export const PDF_HOTSPOT_FETCHED = 'PDF_HOTSPOT_FETCHED';
export const PDF_HOTSPOT_REQUESTED = 'PDF_HOTSPOT_REQUESTED';
export const PLAYLIST_CHAPTER = 'chapter';
export const PLAYLIST_SLATE = 'slate';
export const POP = 'POP';
export const PRIVACY_POLICY = 'PRIVACY POLICY';
export const PRODUCT_DATA_FETCHED = 'PRODUCT_DATA_FETCHED';
export const PRODUCT_SYNC_REQUESTED = 'PRODUCT_SYNC_REQUESTED';
export const PRODUCT_TOKEN_REQUESTED = 'PRODUCT_TOKEN_REQUESTED';
export const PROMO_CODE_FETCHED = 'PROMO_CODE_FETCHED';
export const PROMO_CODE_REQUESTED = 'PROMO_CODE_REQUESTED';
export const PROMO_LINK = 'https://www.pearson.com';
export const RESET_TOC_REQUESTED = 'RESET_TOC_REQUESTED;';
export const STANDALONE_ETEXT = 'IL Standalone eText';
export const STANDARDS = 'standards';
export const SLATE = 'slate';
export const USER_ROLE_STUDENT = 'student';
export const USER_LOADS_CONTENT = 'UserLoadsContent';
export const USER_UNLOADS_CONTENT = 'UserUnloadsContent';
export const X_AUTHORIZATION = 'x-authorization';
export const ERROR_CODE_MARIN = 'ETEXT001';
export const ERROR_CODE_PRISM = 'ETEXT002';
export const ERROR_CODE_ETEXT = 'ETEXT003';
export const ERROR_CODE_IDC = 'ETEXT004';
export const ERROR_CODE_STPAPER = 'ETEXT005';
export const ERROR_SUPPORT = 'ERROR_SUPPORT';
export const ERROR_SUPPORT_CLICK = 'ERROR_SUPPORT_CLICK';
export const TITLE = 'Pearson eText';
export const TELEMTRY_INIT = 'inittelemetry';
export const TELEMETRY_USER = 'usertelemetry';
export const PRODUCT_PLATFORM = 'ILP';
export const PRODUCT_MODEL = 'Etext';
export const TELEMETRY_PAGE_ACTION = 'telemetrypageaction';
export const TELEMETRY_PAGE_ERROR = 'telemetrypageerror';
export const TELEMETRY_PAGE_LOAD = 'loadcontent';
export const TELEMETRY_PAGE_UNLOAD = 'unloadcontent';
export const ID_TYPE = 'PI';
export const USER_TYPE_TEACHER = 'Instructor';
export const USER_TYPE_STUDENT = 'Student';
export const PRODUCT_ROLE = 'Learner';
export const ORGANIZATION_ID_TYPE = 'Organization';
export const PEARSON_SUBSCRIPTION_TIER_CODE = 'Basic';
export const NOT_APPLICABLE = 'NA';
export const CONTENT_TYPE = 'Read';
export const PLAYER_MODE_CODE = 'READING'; // TOTO Need to remove and use it dynamically from spectrum
export const NAVIGATION = 'navigation';
export const EVENT_ACTION = 'Next'; // TOTO - Need to remove and use it from vega reader
export const LASTLOCATION_DATA_REQUESTED = 'LASTLOCATION_DATA_REQUESTED';
export const LASTLOCATION_DATA_FETCHED = 'LASTLOCATION_DATA_FETCHED';
export const DISCONNECT_DEVICE = 'disconnectdevice';
export const ETEXT_BOOK_SOURCE = 'sms,rumba';
export const MOJO_BOOK_SOURCE = 'passport';
export const ACCOUNT_SETTING = 'accountsettings';
export const PAGE_VIEW = 'pageview';
export const READ = 'read';
export const AUDIO = 'audio';
export const STUDY = 'study';
export const DRAWER_PANEL_AUDIO = 'Audio';
export const DRAWER_PANEL_STUDY = 'Study';
export const LOAD_OFFER = 'loadoffer';
export const OFFER_NAME = 'PROMO';
export const STANDARD = 'STANDARD';
export const ACTIVE = 'ACTIVE';
export const MOJO_US_MULTI = 'MOJO_US_MULTI';
export const MOJO_HOME = 'Mojo Home';
export const ACCESSIBILITY = 'accessibility';
export const ACCESSIBILITY_BTN_ID = 'accessibility-expand-collapse';
export const ACCESSIBILITY_ARIA_LABEL = 'Accessibility Expand Collapse';
export const ACCESSIBILITY_SUPPORT = 'Accessibility support';
export const CHAT_WITH_SUPPORT = 'chatwithsupport';
export const QUICK_WALKTHROUGH = 'quickwalkthrough';
export const DEVICES = 'devices';
export const DEVICE_BTN_ID = 'help-expand-collapse';
export const DEVICE_ARIA_LABEL = 'Help Expand Collapse';
export const FAQS = 'faqs';
export const USER_DETAILS = 'userdetails';
export const HELP_SUPPORT = 'helpsupport';
export const HELP_SUPPORT_BTN_ID = 'help-expand-collapse';
export const HELP_SUPPORT_ARIA_LABEL = 'Help Expand Collapse';
export const LEGAL = 'legal';
export const LEGAL_BTN_ID = 'legal-expand-collapse';
export const LEGAL_ARIA_LABEL = 'Legal Expand Collapse';
export const PEARSON_ACCESSIBILITY = 'Pearson accessibility';
export const PRINT_PAGE = 'printpage';
export const SIGN_OUT = 'signout';
export const SUPPORT = 'support';
export const TERMS_OF_USE = 'termsofuse';
export const PRIVACY_NOTICE = 'privacynotice';
export const APP_INFO = 'appinfo';
export const TITLE_INFO = 'titleinfo';
export const MOJO = 'MOJO';
export const NONMOJO = 'NONMOJO';
export const SIGN_OUT_EVENT = 'Sign Out';
export const PRINT_PAGE_EVENT = 'Print Page';
export const GET_THE_APP_EVENT = 'Click Get The App';
export const HELP_SUPPORT_EVENT = 'Click Help And Support';
export const FAQS_EVENT = 'Click FAQs';
export const SUPPORT_EVENT = 'Click Support';
export const CHAT_WITH_SUPPORT_EVENT = 'Click Chat With Us';
export const DEVICES_EVENT = 'Click Devices';
export const LEGAL_EVENT = 'Click Legal';
export const TERMS_OF_USE_EVENT = 'Click Terms of Use';
export const PRIVACY_NOTICE_EVENT = 'Click Privacy Policy';
export const ACCESSIBILITY_EVENT = 'Click Accessibility';
export const PEARSON_ACCESSIBILITY_EVENT = 'Click Pearson Accessibility';
export const ACCESSIBILITY_SUPPORT_EVENT = 'Click Accessibility Support';
export const TITLE_INFO_EVENT = 'Click Title Info';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const COURSE_TYPE_SMS = 'SMS';
export const COURSE_TYPE_REGISTRAR = 'Registrar';
export const LOAD = 'load';
export const UNLOAD = 'unload';
export const PLUS = 'plus';
export const ETEXT = 'etext-ise';
export const ETEXT_PERF = 'etext-ise-perf';
export const CP = 'cp';
export const TPI = 'isTpi';
export const LOCAL = 'local-dev';
export const PEARSONED_DOMAIN = '.pearson.com';
export const GPC_PREFERENCE_RETRIEVE_SUCCESS = 'GPC_PREFERENCE_RETRIEVE_SUCCESS';
export const PEARSON_PLUS_META = 'Reimagined learning, designed for you';
export const PEARSON_ETEXT_META = 'Let the learning begin!';
export const PEARSON_PLUS_TITLE = 'Pearson+';
export const PEARSON_ETEXT_TITLE = 'Pearson eText';
export const PEARSON_DL_TITLE = 'Pearson Digital Library';
export const PEARSON_DL_SPARK_TITLE = 'Pearson Spark';
export const SPARK = 'spark';
export const DL_USER_PROFILE_REQUESTED = 'DL_USER_PROFILE_REQUESTED';
export const DL_USER_PROFILE_FETCHED = 'DL_USER_PROFILE_FETCHED';
export const DL_USER_PROFILE_ERROR = 'DL_USER_PROFILE_ERROR';
export const DL_USER_SCHOOL_REQUESTED = 'DL_USER_SCHOOL_REQUESTED';
export const DL_USER_SCHOOL_FETCHED = 'DL_USER_SCHOOL_FETCHED';
export const DL_USER_SCHOOL_ERROR = 'DL_USER_SCHOOL_ERROR';
export const DL_USER_SCHOOL_UNSET = 'DL_USER_SCHOOL_UNSET';
export const CARTRIDGELIST_REQUESTED = 'CARTRIDGELIST_REQUESTED';
export const CARTRIDGELIST_FETCHED = 'CARTRIDGELIST_FETCHED';
export const CARTRIDGELIST_ERROR = 'CARTRIDGELIST_ERROR';
export const CARTRIDGELIST_EMPTY = 'CARTRIDGELIST_EMPTY';
export const CREATE_TEACHER_SCHOOL_CLASS_REQUESTED = 'CREATE_TEACHER_SCHOOL_CLASS_REQUESTED';
export const CREATE_TEACHER_SCHOOL_CLASS_CREATED = 'CREATE_TEACHER_SCHOOL_CLASS_CREATED';
export const CREATE_TEACHER_SCHOOL_CLASS_ERROR = 'CREATE_TEACHER_SCHOOL_CLASS_ERROR';
export const CREATE_TEACHER_SCHOOL_CLASS_FAIL = 'CREATE_TEACHER_SCHOOL_CLASS_FAIL';
export const CREATE_TEACHER_PERMISSIONS_REQUESTED = 'CREATE_TEACHER_PERMISSIONS_REQUESTED';
export const CREATE_TEACHER_PERMISSIONS_CREATED = 'CREATE_TEACHER_PERMISSIONS_CREATED';
export const CREATE_TEACHER_PERMISSIONS_ERROR = 'CREATE_TEACHER_PERMISSIONS_ERROR';
export const CREATE_TEACHER_PERMISSIONS_FAIL = 'CREATE_TEACHER_PERMISSIONS_FAIL';
export const PROVISION_CARTRIDGE_REQUESTED = 'PROVISION_CARTRIDGE_REQUESTED';
export const PROVISION_CARTRIDGE_CREATED = 'PROVISION_CARTRIDGE_CREATED';
export const PROVISION_CARTRIDGE_ERROR = 'PROVISION_CARTRIDGE_ERROR';
export const PROVISION_CARTRIDGE_FAIL = 'PROVISION_CARTRIDGE_FAIL';
export const DL_CONFIG_REQUESTED = 'DL_CONFIG_REQUESTED';
export const DL_CONFIG_FETCHED = 'DL_CONFIG_FETCHED';
export const DL_CONFIG_EMPTY = 'DL_CONFIG_EMPTY';
export const DL_CONFIG_ERROR = 'DL_CONFIG_ERROR';
export const DL_SERVER_YEAR_REQUESTED = 'DL_SERVER_YEAR_REQUESTED';
export const DL_SERVER_YEAR_FETCHED = 'DL_SERVER_YEAR_FETCHED';
export const DL_SERVER_YEAR_EMPTY = 'DL_SERVER_YEAR_EMPTY';
export const DL_SERVER_YEAR_ERROR = 'DL_SERVER_YEAR_ERROR';
export const DL_APP_CONFIG_REQUESTED = 'DL_APP_CONFIG_REQUESTED';
export const DL_APP_CONFIG_FETCHED = 'DL_APP_CONFIG_FETCHED';
export const DL_APP_CONFIG_EMPTY = 'DL_APP_CONFIG_EMPTY';
export const DL_APP_CONFIG_ERROR = 'DL_APP_CONFIG_ERROR';
export const DL_BOOKS_REQUESTED = 'DL_BOOKS_REQUESTED';
export const DL_BOOKS_FETCHED = 'DL_BOOKS_FETCHED';
export const DL_BOOKS_EMPTY = 'DL_BOOKS_EMPTY';
export const DL_BOOKS_ERROR = 'DL_BOOKS_ERROR';
export const COURSELIST_REQUESTED = 'COURSELIST_REQUESTED';
export const COURSELIST_FETCHED = 'COURSELIST_FETCHED';
export const COURSELIST_EMPTY = 'COURSELIST_EMPTY';
export const COURSELIST_ERROR = 'COURSELIST_ERROR';
export const IES_USER_REQUESTED = 'IES_USER_REQUESTED';
export const IES_USER_FETCHED = 'IES_USER_FETCHED';
export const IES_USER_DATA_ARRAY_FETCHED = 'IES_USER_DATA_ARRAY_FETCHED';
export const IES_USER_LIST_EMPTY = 'IES_USER_LIST_EMPTY';
export const IES_USER_ERROR = 'IES_USER_ERROR';
export const FETCH_IES_USER_REQUESTED = 'FETCH_IES_USER_REQUESTED';
export const FETCH_IES_USER_SUCCESS = 'FETCH_IES_USER_SUCCESS';
export const SEND_BOOK_REQUESTED = 'SEND_BOOK_REQUESTED';
export const SEND_BOOK_SUCCESS = 'SEND_BOOK_SUCCESS';
export const SEND_BOOK_ERROR = 'SEND_BOOK_ERROR';
export const GET_USER_PERMISSIONS_REQUESTED = 'GET_USER_PERMISSIONS_REQUESTED';
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_ERROR = 'GET_USER_PERMISSIONS_ERROR';
export const FETCH_NEW_IES_USER_REQUESTED = 'FETCH_NEW_IES_USER_REQUESTED';
export const FETCH_NEW_IES_USER_SUCCESS = 'FETCH_NEW_IES_USER_SUCCESS';
export const IES_PASSWORD_LIST_FETCH_REQUESTED = 'IES_PASSWORD_LIST_FETCH_REQUESTED';
export const IES_PASSWORD_LIST_FETCH_SUCCESS = 'IES_PASSWORD_LIST_FETCH_SUCCESS';
export const IES_PASSWORD_LIST_EMPTY = 'IES_PASSWORD_LIST_EMPTY';
export const IES_PASSWORD_LIST_ERROR = 'IES_PASSWORD_LIST_ERROR';
export const CREATE_STUDENT_REQUESTED = 'CREATE_STUDENT_REQUESTED';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_ERROR = 'CREATE_STUDENT_ERROR';
export const CREATE_BULK_STUDENT_REQUESTED = 'CREATE_BULK_STUDENT_REQUESTED';
export const CREATE_BULK_STUDENT_SUCCESS = 'CREATE_BULK_STUDENT_SUCCESS';
export const CREATE_BULK_STUDENT_ERROR = 'CREATE_BULK_STUDENT_ERROR';
export const BULK_STUDENTS_ENROLL_SCHOOL_SECTION_REQUESTED = 'BULK_STUDENTS_ENROLL_SCHOOL_SECTION_REQUESTED';
export const BULK_STUDENTS_ENROLL_SCHOOL_SECTION_SUCCESS = 'BULK_STUDENTS_ENROLL_SCHOOL_SECTION_SUCCESS';
export const BULK_STUDENTS_ENROLL_SCHOOL_SECTION_ERROR = 'BULK_STUDENTS_ENROLL_SCHOOL_SECTION_ERROR';
export const STUDENT_ENROLL_SCHOOL_SECTION_REQUESTED = 'STUDENT_ENROLL_SCHOOL_SECTION_REQUESTED';
export const STUDENT_ENROLL_SCHOOL_SECTION_SUCCESS = 'STUDENT_ENROLL_SCHOOL_SECTION_SUCCESS';
export const STUDENT_ENROLL_SCHOOL_SECTION_ERROR = 'STUDENT_ENROLL_SCHOOL_SECTION_ERROR';
export const ANALYTICS_COLLECTIVE_DATA_REQUESTED = 'ANALYTICS_COLLECTIVE_DATA_REQUESTED';
export const ANALYTICS_COLLECTIVE_DATA_FETCHED_SUCCESS = 'ANALYTICS_COLLECTIVE_DATA_FETCHED_SUCCESS';
export const ANALYTICS_COLLECTIVE_DATA_LIST_REQUESTED = 'ANALYTICS_COLLECTIVE_DATA_LIST_REQUESTED';
export const ANALYTICS_COLLECTIVE_DATA_LIST_FETCHED_SUCCESS = 'ANALYTICS_COLLECTIVE_DATA_LIST_FETCHED_SUCCESS';
export const ANALYTICS_COLLECTIVE_DATA_ERROR = 'ANALYTICS_COLLECTIVE_DATA_ERROR';
export const GET_USER_BATCH_REQUESTED = 'GET_USER_BATCH_REQUESTED';
export const GET_USER_BATCH_SUCCESS = 'GET_USER_BATCH_SUCCESS';
export const GET_USER_BATCH_ERROR = 'GET_USER_BATCH_ERROR';
export const INGESTION_GET_BATCH_STATUS_REQUESTED = 'INGESTION_GET_BATCH_STATUS_REQUESTED';
export const INGESTION_GET_BATCH_STATUS_SUCCESS = 'INGESTION_GET_BATCH_STATUS_SUCCESS';
export const INGESTION_GET_BATCH_STATUS_ERROR = 'INGESTION_GET_BATCH_STATUS_ERROR';
export const INGESTION_GET_BATCH_ITEMS_REQUESTED = 'INGESTION_GET_BATCH_ITEMS_REQUESTED';
export const INGESTION_GET_BATCH_ITEMS_SUCCESS = 'INGESTION_GET_BATCH_ITEMS_SUCCESS';
export const INGESTION_GET_BATCH_ITEMS_ERROR = 'INGESTION_GET_BATCH_ITEMS_ERROR';
export const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS';
export const EDIT_STUDENT_REQUESTED = 'EDIT_STUDENT_REQUESTED';
export const EDIT_STUDENT_SUCCESS = 'EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_ERROR = 'EDIT_STUDENT_ERROR';
export const EDIT_CLASS_NAME_REQUESTED = 'EDIT_CLASS_NAME_REQUESTED';
export const EDIT_CLASS_NAME_SUCCESS = 'EDIT_CLASS_NAME_SUCCESS';
export const EDIT_CLASS_NAME_ERROR = 'EDIT_CLASS_NAME_ERROR';
export const DELETE_STUDENT_REQUESTED = 'DELETE_STUDENT_REQUESTED';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_ERROR = 'DELETE_STUDENT_ERROR';
export const FETCH_COURSE_SECTION_REQUESTED = 'FETCH_COURSE_SECTION_REQUESTED';
export const FETCH_COURSE_SECTION_SUCCESS = 'FETCH_COURSE_SECTION_SUCCESS';
export const FETCH_COURSE_SECTION_ERROR = 'FETCH_COURSE_SECTION_ERROR';
export const FETCH_STUDENT_PASS_LETTER_REQUESTED = 'FETCH_STUDENT_PASS_LETTER_REQUESTED';
export const FETCH_STUDENT_PASS_LETTER_SUCCESS = 'FETCH_STUDENT_PASS_LETTER_SUCCESS';
export const FETCH_STUDENT_PASS_LETTER_ERROR = 'FETCH_STUDENT_PASS_LETTER_ERROR';
export const FETCH_COURSE_ROSTER_REQUESTED = 'FETCH_COURSE_ROSTER_REQUESTED';
export const FETCH_COURSE_ROSTER_SUCCESS = 'FETCH_COURSE_ROSTER_SUCCESS';
export const FETCH_COURSE_ROSTER_ERROR = 'FETCH_COURSE_ROSTER_ERROR';
export const USER_PRODUCTS_FETCH_REQUESTED = 'USER_PRODUCTS_FETCH_REQUESTED';
export const USER_PRODUCTS_FETCHED_SUCCESS = 'USER_PRODUCTS_FETCHED_SUCCESS';
export const USER_PRODUCTS_FETCH_ERROR = 'USER_PRODUCTS_FETCH_ERROR';
export const CHART_AXIS_FETCH_REQUESTED = 'CHART_AXIS_FETCH_REQUESTED';
export const CHART_AXIS_FETCH_SUCCESS = 'CHART_AXIS_FETCH_SUCCESS';
export const CHART_AXIS_FETCH_ERROR = 'CHART_AXIS_FETCH_ERROR';
export const RETURN_BOOK_REQUESTED = 'RETURN_BOOK_REQUESTED';
export const RETURN_BOOK_SUCCESS = 'RETURN_BOOK_SUCCESS';
export const RETURN_BOOK_ERROR = 'RETURN_BOOK_ERROR';
export const USER_ACTIVITY_ATTEMPT_REQUESTED = 'USER_ACTIVITY_ATTEMPT_REQUESTED';
export const USER_ACTIVITY_ATTEMPT_SUCCESS = 'USER_ACTIVITY_ATTEMPT_SUCCESS';
export const USER_ACTIVITY_ATTEMPT_ERROR = 'USER_ACTIVITY_ATTEMPT_ERROR';
export const FETCH_ASSET_DETAILS_REQUESTED = 'FETCH_ASSET_DETAILS_REQUESTED';
export const FETCH_ASSET_DETAILS_SUCCESS = 'FETCH_ASSET_DETAILS_SUCCESS';
export const FETCH_ASSET_DETAILS_ERROR = 'FETCH_ASSET_DETAILS_ERROR';
export const FETCH_SCROM_URL_REQUESTED = 'FETCH_SCROM_URL_REQUESTED';
export const FETCH_SCROM_URL_SUCCESS = 'FETCH_SCROM_URL_SUCCESS';
export const FETCH_SCROM_URL_ERROR = 'FETCH_SCROM_URL_ERROR';
export const UPDATE_USER_PRODUCT_REQUESTED = 'UPDATE_USER_PRODUCT_REQUESTED';
export const UPDATE_USER_PRODUCT_SUCCESS = 'UPDATE_USER_PRODUCT_SUCCESS';
export const UPDATE_USER_PRODUCT_ERROR = 'UPDATE_USER_PRODUCT_ERROR';
export const FETCH_SCORM_GRADES_REQUESTED = 'FETCH_SCORM_GRADES_REQUESTED';
export const FETCH_SCORM_GRADES_SUCCESS = 'FETCH_SCORM_GRADES_SUCCESS';
export const FETCH_SCORM_GRADES_ERROR = 'FETCH_SCORM_GRADES_ERROR';
export const POST_SCORM_ACTIVITY_GRADES_REQUESTED = 'POST_SCORM_ACTIVITY_GRADES_REQUESTED';
export const POST_SCORM_ACTIVITY_GRADES_SUCCESS = 'POST_SCORM_ACTIVITY_GRADES_SUCCESS';
export const POST_SCORM_ACTIVITY_GRADES_ERROR = 'POST_SCORM_ACTIVITY_GRADES_ERROR';

// Filter Search
export const FILTER_FETCHED_REQUESTED = 'FILTER_FETCHED_REQUESTED';
export const FILTER_FETCHED_SUCCESS = 'FILTER_FETCHED_SUCCESS';
export const FILTER_FETCHED_ERROR = 'FILTER_FETCHED_ERROR';

// Search Product
export const SEARCH_PRODUCTS_REQUESTED = 'SEARCH_PRODUCTS_REQUESTED';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_ERROR = 'SEARCH_PRODUCTS_ERROR';

// fetch-product-by-school-id
export const PRODUCTS_FETCHED_REQUESTED = 'PRODUCTS_FETCHED_REQUESTED';
export const PRODUCTS_FETCHED_SUCCESS = 'PRODUCTS_FETCHED_SUCCESS';
export const PRODUCTS_FETCHED_ERROR = 'PRODUCTS_FETCHED_ERROR';

// fetch-product-by-User-id
// export const USER_PRODUCTS_FETCHED_REQUESTED ='USER_PRODUCTS_FETCHED_REQUESTED';
// export const USER_PRODUCTS_FETCHED_SUCCESS = 'USER_PRODUCTS_FETCHED_SUCCESS';
// export const USER_PRODUCTS_FETCHED_ERROR = 'USER_PRODUCTS_FETCHED_ERROR';

// GlsGetProductsListForAutoComplete
export const PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_REQUESTED = 'PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_REQUESTED';
export const PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS = 'PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_SUCCESS';
export const PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_ERROR = 'PRODUCTS_LIST_FETCHED_AUTOCOMPLETE_ERROR';


// RELATED_PRODUCTS_FETCHED_REQUESTED
export const RELATED_PRODUCTS_FETCHED_REQUESTED = 'RELATED_PRODUCTS_FETCHED_REQUESTED';
export const RELATED_PRODUCTS_FETCHED_SUCCESS = 'RELATED_PRODUCTS_FETCHED_SUCCESS';
export const RELATED_PRODUCTS_FETCHED__ERROR = 'RELATED_PRODUCTS_FETCHED__ERROR';

// searchUserProducts:
export const FETCH_USER_PRODUCTS_REQUESTED = 'FETCH_USER_PRODUCTS_REQUESTED';
export const FETCH_USER_PRODUCTS_SUCCESS = 'FETCH_USER_PRODUCTS_SUCCESS';
export const FETCH_USER_PRODUCTS_ERROR = 'FETCH_USER_PRODUCTS_ERROR';

// FetchBookReacdCountByUserId
export const FETCH_BOOK_READ_COUNT_REQUESTED = 'FETCH_BOOK_READ_COUNT_REQUESTED';
export const FETCH_BOOK_READ_COUNT_SUCCESS = 'FETCH_BOOK_READ_COUNT_SUCCESS';
export const FETCH_BOOK_READ_COUNT_ERROR = 'FETCH_BOOK_READ_COUNT_ERROR';

// SwitchToBookBox
export const SWITCH_TO_BOOKBOX = 'SWITCH_TO_BOOKBOX';
export const STORE_NEW_BOOK_COUNT = 'STORE_NEW_BOOK_COUNT';
export const COURSE_SELECTED = 'COURSE_SELECTED';

// FetchBookReacdCountByUserId
export const GET_NEW_BOOKS_FOR_NOTIFICATION_REQUESTED = 'GET_NEW_BOOKS_FOR_NOTIFICATION_REQUESTED';
export const GET_NEW_BOOKS_FOR_NOTIFICATION_SUCCESS = 'GET_NEW_BOOKS_FOR_NOTIFICATION_SUCCESS';
export const GET_NEW_BOOKS_FOR_NOTIFICATION_ERROR = 'GET_NEW_BOOKS_FOR_NOTIFICATION_ERROR';

// Get Server Time
export const FETCH_SERVER_TIME_REQUESTED = 'FETCH_SERVER_TIME_REQUESTED';
export const FETCH_SERVER_TIME_SUCCESS = 'FETCH_SERVER_TIME_SUCCESS';
export const FETCH_SERVER_TIME_ERROR = 'FETCH_SERVER_TIME_ERROR';

// Update Time Spent On Each Book
export const UPDATE_TIME_SPENT_ON_BOOK_REQUESTED = 'UPDATE_TIME_SPENT_ON_BOOK_REQUESTED';
export const UPDATE_TIME_SPENT_ON_BOOK_SUCCESS = 'UPDATE_TIME_SPENT_ON_BOOK_SUCCESS';
export const UPDATE_TIME_SPENT_ON_BOOK_ERROR = 'UPDATE_TIME_SPENT_ON_BOOK_ERROR';

// Post Notes For Each Student
export const POST_NOTES_ON_STUDENT_REQUESTED = 'POST_NOTES_ON_STUDENT_REQUESTED';
export const POST_NOTES_ON_STUDENT_SUCCESS = 'POST_NOTES_ON_STUDENT_SUCCESS';
export const POST_NOTES_ON_STUDENT_ERROR = 'POST_NOTES_ON_STUDENT_ERROR';

// Fetch Class Level Report with Batch Id
export const FETCH_CLASS_REPORT_REQUESTED = 'FETCH_CLASS_REPORT_REQUESTED';
export const FETCH_CLASS_REPORT_SUCCESS = 'FETCH_CLASS_REPORT_SUCCESS';
export const FETCH_CLASS_REPORT_ERROR = 'FETCH_CLASS_REPORT_ERROR';

// Fetch Student Level Report with Batch Id
export const FETCH_STUDENT_REPORT_REQUESTED = 'FETCH_STUDENT_REPORT_REQUESTED';
export const FETCH_STUDENT_REPORT_SUCCESS = 'FETCH_STUDENT_REPORT_SUCCESS';

// BlueFlag
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';

export const SortType = { ASC: null, DESC: null };
export const FoxitServerURL = `${window.location.protocol}//${window.location.host}/foxit-webpdf-web/pc/`;
export const ElementsPerPage = 10;
export const UserMessages = { UserLoggedOut: 'You have been logged out successfully.' };
export const PageTitles = {
  Login: 'Login',
  Bookshelf: 'My Bookshelf',
  ReadBook: 'Read Book'
};

// Highlights related constants.
export const HighlightEnginePDF = 'foxit_v2';
export const HighlightEngine = 'rangy_v1';
export const DefaultHighlightColor = 'yellow';
export const roleValue = 'student';
export const secondaryUserMode = 'secondary';


export const InfiniteScrollTarget = keyMirror({
  Body: null,
  Element: null
});

export const RequestTypes = keyMirror({
  get: null,
  post: null,
  put: null,
  head: null,
  delete: null
});

export const BookHeaderOptions = keyMirror({
  COVER: null,
  CHAPTER: null,
  TOC: null,
  HIGHLIGHTS: null,
  RESOURCES: null,
  SETTINGS: null,
  BOOKMARK: null,
  MARK: null,
  ACESSIBILITY: null,
  ZOOM100: null,
  ZOOM125: null,
  ZOOM150: null,
  ZOOM175: null,
  ZOOM200: null,
  AUDIOICON: null
});

export const SlidingMenuTypes = keyMirror({
  TOC: null,
  HIGHLIGHTS: null,
  RESOURCES: null,
  BOOKMARK: null,
  MARK: null,
  ACESSIBILITY: null,
  ZOOM100: null,
  ZOOM125: null,
  ZOOM150: null,
  ZOOM175: null,
  ZOOM200: null
});

export const ModalTypes = keyMirror({
  CHAPTER: null,
  NOTES: null
});

export const primaryMyBookBoxFilter = [
  { value: 'all', displayValue: 'All' },
  { value: 'new', displayValue: 'New' },
  { value: 'reading', displayValue: 'I&apos;m reading' },
  { value: 'finished', displayValue: 'Finished' },
  { value: 'liked', displayValue: 'Liked', displayType: 'icon' }
];

export const primarySchoolLibraryFilter = [
  { value: 'all', displayValue: 'All' },
  { value: 'returned', displayValue: 'Returned' }
];

export const readingLevelsColorCodes = [
  { key: 'A', value: '#c82371' }, { key: 'B', value: '#c82371' }, { key: 'C', value: '#db1f26' }, { key: 'D', value: '#db1f26' }, { key: 'E', value: '#e87724' },
  { key: 'F', value: '#fdb71c' }, { key: 'G', value: '#fdb71c' }, { key: 'H', value: '#fdb71c' }, { key: 'I', value: '#82bd41' }, { key: 'J', value: '#82bd41' },
  { key: 'K', value: '#82bd41' }, { key: 'L', value: '#0580a3' }, { key: 'M', value: '#0580a3' }, { key: 'N', value: '#0580a3' }, { key: 'O', value: '#0bb2a7' },
  { key: 'P', value: '#0bb2a7' }, { key: 'Q', value: '#0bb2a7' }, { key: 'R', value: '#772d8f' }, { key: 'S', value: '#772d8f' }, { key: 'T', value: '#772d8f' },
  { key: 'U', value: '#772d8f' }, { key: 'V', value: '#8b5e3c' }, { key: 'W', value: '#8b5e3c' }, { key: 'X', value: '#8b5e3c' }, { key: 'Y', value: '#8b5e3c' }, { key: 'Z', value: '#8b5e3c' }
];

export const defaultAppConfigData = {
  config_version: 'v7',
  readingui: {
    highlights: true,
    bookmarks: true,
    related_assets: true,
    assignments: false,
    assessments: true,
    showPageLayout: false,
    isDoublePage: false,
    ui_mode: {
      teacher: 'adult_ui',
      student: 'primary_ui'
    }
  },
  bookshelf_filters: {
    url: 'https://s3.amazonaws.com/r-digital-library/dev/config/bookshelf-filters-secondary-elt.json',
    primaryurl: 'https://s3.amazonaws.com/r-digital-library/dev/config/bookshelf-filters-primary-elt.json',
    isEnabled: true,
    roleValues: ['teacher', 'student']
  },
  appui_mode: {
    teacher: 'adult_ui',
    student: 'primary_ui'
  },
  dashboard: {
    sections: [
      {
        tag: 'my-bookbox',
        isEnabled: true,
        roleValues: ['student'],
        showKeywordSearch: ['student'],
        showFilterSearch: []
      },
      {
        tag: 'school-library',
        isEnabled: true,
        roleValues: ['teacher', 'student'],
        showKeywordSearch: ['teacher', 'student'],
        showFilterSearch: ['teacher', 'student']
      },
      {
        tag: 'student',
        isEnabled: true,
        roleValues: ['teacher'],
        showKeywordSearch: [],
        showFilterSearch: []
      }]
  },
  activities: {
    skillurl: 'https://s3.amazonaws.com/r-digital-library/dev/chart/activity-chart-elt.json'
  },
  help: {
    url: '',
    student_url: ''
  }
};

export const COMPLETED = 'completed';
export const INCOMPLETE = 'incomplete';
export const MAX_BULK_IMPORT_SIZE = 35;
// export const DRAWER_STATE_CLOSED = 'closed';
export const SPDF = 'spdf';
export const EPUB = 'epub';
export const PDF = 'pdf';
export const CLIENT_APP = 'READER_PLUS';
export const CLOUDFRONT_URL = 'https://d38l3k3yaet8r2.cloudfront.net/';
export const STUDENT = 'student';
export const YL = 'YL';
export const MY_BOOKBOX = 'my-bookbox';
export const SINGLE_PAGE = 'single_page';
export const DOUBLE_PAGE = 'double_page';
export const HOST_URL = `https://${window.location.host}`;
export const DEFAULT_LANG = 'en';
export const FILE_NOT_FOUND = 'File not found. Please initiate with default language';
export const Access_Denied = 'Access Denied';
export const student_code_max_length = 6;
export const student_code_pattern = /^[A-Za-z0-9]+$/i;
export const password_min_length = 5;
export const password_pattern = /^[A-Za-z0-9]+$/i;
export const MAX_EXPIRY_MSG_VALUE = 15;
export const MIN_EXPIRY_MSG_VALUE = 1;
export const SPARK_HELP_LINK = '&lt;sparkreadinghelp@pearson.com&gt;';
export const SPARK_SALES_LINK = 'https://www.pearsoncanadaschool.com/index.cfm?locator=PSZnOv';

// QA
export const DEV_SUCCESS_URL = 'http://digital-library-stg.pearson.com:3000';
export const QA_BASE_URL = 'https://login-stg.pearson.com/v1/piapi-int/piui';
export const QA_CLIENT_ID = 'Wf2aVSJuuP88GZChhzEk29WH1ifGIPQe';
export const STUDENT_CLIENT_ID = 'I15wQHlA92Qt5l66H3BWYQLKZGokKoCA';
export const QA_SUCCESS_URL = 'http://digital-library-stg.pearson.com';
export const ACCESS_CODE_BASE_URL = 'https://admin-dev.cac.pulse.pearson.com/#/ext-access-code';

// SIT/PROD
// export const DEV_SUCCESS_URL = 'http://digital-library.pearson.com';
// export const QA_BASE_URL = 'https://login.pearson.com/v1/piapi/piui';
// export const QA_CLIENT_ID = 'OYG7tQTgGn4qQybwvjxXdmt90DhRxRAM';
// export const STUDENT_CLIENT_ID = 'fbkk85HAiAabSGgru7Elj8ntnyFlGQry';
// export const QA_SUCCESS_URL = 'http://digital-library.pearson.com';
// export const ACCESS_CODE_BASE_URL = 'https://admin.cac.pulse.pearson.com/#/ext-access-code';
export const LOGIN_URL = `${window.location.protocol}//${window.location.host}/pages/selectrole`;
export const NO_AUTH_ROUTES = [
  '/',
  '/pages/selectrole',
  '/pages/selectrole/',
  '/student/studentcode',
  '/student/studentcode/',
  '/student/selectuser',
  '/student/selectuser/',
  '/student/password',
  '/student/password/',
  '/student/selectpassword',
  '/student/selectpassword/'
];
export const ROUTE_LIST = [
  '/',
  '/app',
  '/app/',
  '/book',
  '/book/',
  '/launchScorm',
  '/launchScorm/',
  '/pages/selectrole',
  '/pages/selectrole/',
  '/student/studentcode',
  '/student/studentcode/',
  '/student/selectuser',
  '/student/selectuser/',
  '/student/password',
  '/student/password/',
  '/student/selectpassword',
  '/student/selectpassword/',
  '/teacher',
  '/teacher/'

];
export const SESSION_DURATION = 1800;
