/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import axios from 'axios';
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import fileDownload from 'js-file-download';
export default class FetchClassReportService extends BaseService {
  constructor() {
    super(constants.FETCH_CLASS_REPORT_REQUESTED, 'FetchClassReportService');
  }

  handleEvent(eventName, event) {
    const headerParams = {
      headers: {
        createdby: event.userId,
        classcode: event.classCode,
        reportformat: event.selectedFormat.toLowerCase()
      }
    };
    return this.getClient('hapi').get('/fetch-class-level-report', headerParams);
  }

  handleResponse(response, event) { // eslint-disable-line class-methods-use-this
    const reportType = event.selectedFormat?.toLowerCase();
    if (response?.data?.url) {
      const reportPath = response.data.url;
      const reportPathArray = reportPath.split('report/');
      const fileName = (reportPathArray.length && reportPathArray[1]) || `ClassLevelReport.${reportType}`;
      axios.get(response.data.url, {
        responseType: 'blob',
      })
      .then((res) => {
        Framework.getEventManager().publish(constants.FETCH_CLASS_REPORT_SUCCESS);
        fileDownload(res.data, fileName);
      });
    } else {
      Framework.getEventManager().publish(constants.DOWNLOAD_REPORT_ERROR, {
        fileFormat: event.selectedFormat
      });
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.FETCH_CLASS_REPORT_SUCCESS);
    Framework.getEventManager().publish(constants.DOWNLOAD_REPORT_ERROR, {
      fileFormat: event.selectedFormat
    });
  }
}
