/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Dev environment overrides.
 *
 * @author Hari Gangadharan
 */

const dev = {
  ENVIRONMENT: 'dev',
  MARIN_API_BASE_URL: 'https://marin-api.dev-prsn.com/api/1.0',
  HAPI_API_BASE_URL: `//digital-library-dev.pearson.com${process.env.NODE_ENV !== 'production' ? ':3000' : ''}`,
  SMARTTHINKING_CONFIG: {
    baseUrl: 'https://dev.services.smarthinking.com/api/payment-mojo'
  },
  BOOKSHELF_CONFIG: {
    checkout: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/pricing.html',
    pmcHeader: 'https://pearson-stg3.pearson.com/en-us/pearsonplus.nav-content.standalone.html',
    purchase: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/search.html',
    addon: 'https://pearson-stg3.pearson.com/store/_ui/addons/pmciamaddon/responsive/common/js/pmciamaddon.js',
    chatbot: {
      url: 'https://pearson--servicedev.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseUrl: 'https://pearson--servicedev.my.salesforce.com',
      supportUrl: 'https://servicedev-pearsoncommunity.cs100.force.com/getsupport',
      id: '00D1x0000000Mu5',
      name: 'NAUS_HETS_DTC_CS_Bot',
      baseLiveAgentContentURL: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
      deploymentId: '572b0000000ClKC',
      buttonId: '5731x000000Cbzc',
      baseLiveAgentURL: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1x000000CaofEAC_1785e5167e1',
      isOfflineSupportEnabled: false
    },
    more: {
      MOJO_TERMS: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/terms-of-use.html',
      MOJO_PRIVACY: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/user-privacy-policy.html',
      MOJO_COOKIES: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_INFO: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_ACCESSIBILITY: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/accessibility.html',
      MOJO_NOTICE: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/legal/patent-notice.html',
      MOJO_PEARSON_ACCESSIBILITY: 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
      MOJO_SUPPORT: 'https://pearson-stg3.pearson.com/en-us/pearsonplus/support.html'
    }
  },

  // Google Tag Manager Configs - Dev
  GTM_CONTAINER_ID: 'GTM-N8JS47K',
  GTM_AUTH: '34vutGcP7_nco7Xgx1OspQ',
  GTM_PREVIEW: 'env-3'
};

export default dev;
