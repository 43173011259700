/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const BookItemBookMetadataCustom = types.model('BookItemBookMetadataCustom', {
  audioSync: types.maybeNull(types.integer),
  versoStart: types.maybeNull(types.integer),
  height: types.maybeNull(types.integer),
  width: types.maybeNull(types.integer),
  numberingOffset: types.maybeNull(types.integer)
});

const BookItemBookMetadata = types.model('BookItemBookMetadata', {
  layout: types.maybeNull(types.array(types.maybeNull(types.string))),
  contentPath: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  allowedPageNavigation: types.maybeNull(types.string),
  encpwd: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  opsRoot: types.maybeNull(types.string),
  mediaOverlayActiveClass: types.maybeNull(types.string),
  custom: types.maybeNull(BookItemBookMetadataCustom),
  coverImageUrl: types.maybeNull(types.string),
  url: types.maybeNull(types.string)
});

const BookItemBook = types.model('BookItemBook', {
  metadata: types.maybeNull(BookItemBookMetadata),
  pagesCount: types.maybeNull(types.integer),
  id: types.maybeNull(types.string)
});

const BookItemMetadata = types.model('BookItemMetadata', {
  ISBN: types.maybeNull(types.array(types.maybeNull(types.string))),
  information: types.maybeNull(types.array(types.maybeNull(types.string))),
  readingLevels: types.maybeNull(types.array(types.maybeNull(types.string))),
  subjectAreas: types.maybeNull(types.array(types.maybeNull(types.string))),
  areasForInquiry: types.maybeNull(types.array(types.maybeNull(types.string))),
  grades: types.maybeNull(types.array(types.maybeNull(types.string))),
  defaultGrades: types.maybeNull(types.array(types.maybeNull(types.string))),
  genres: types.maybeNull(types.array(types.maybeNull(types.string))),
  comprehensiveStrategies: types.maybeNull(
    types.array(types.maybeNull(types.string))
  ),
  topicsOfInterest: types.maybeNull(types.array(types.maybeNull(types.string))),
  teachingNotes: types.maybeNull(types.array(types.maybeNull(types.string))),
  activities: types.maybeNull(types.array(types.maybeNull(types.string))),
  eCommLink: types.maybeNull(types.array(types.maybeNull(types.string))),
  provinces: types.maybeNull(types.array(types.maybeNull(types.string))),
  seriesTitles: types.maybeNull(types.array(types.maybeNull(types.string))),
  keywords: types.maybeNull(types.array(types.maybeNull(types.string))),
  language: types.maybeNull(types.array(types.maybeNull(types.string)))
});

const BookItem = types.model('BookItem', {
  books: types.maybeNull(types.array(types.maybeNull(BookItemBook))),
  layout: types.maybeNull(types.array(types.maybeNull(types.string))),
  title: types.maybeNull(types.string),
  author: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  coverImageURL: types.maybeNull(types.string),
  metadata: types.maybeNull(BookItemMetadata),
  allowedPageNavigation: types.maybeNull(types.string),
  encpwd: types.maybeNull(types.string),
  epubURL: types.maybeNull(types.string),
  id: types.maybeNull(types.string)
});

const Books = types.model('Books', { items: types.optional(types.array(BookItem), []) })
  .actions(self => ({
    fetch(schoolid, searchlimit, offset) {
      Framework.getEventManager().publish(constants.DL_BOOKS_REQUESTED, {
        schoolid,
        searchlimit,
        offset
      });
    },
    set(state) {
      applySnapshot(self, state);
    }
  }));

export default Books;
