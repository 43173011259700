/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import { toJS } from 'mobx';
import * as constants from '../constants';

export default class DlUserService extends BaseService {
  constructor() {
    super(constants.DL_USER_PROFILE_REQUESTED, 'DlUserServiceStatus');
  }

  handleEvent(eventName, event) {
    let headerParams = null;
    headerParams = {
      headers: {
        userid: event.userId,
        appversion: 'app'
      }
    };
    // console.log("get-ies-user-details handleEvent: ", new Date(), new Date().getTime());
    return this.getClient('hapi').get(
      '/get-ies-user-details',
      headerParams
    ); // courseboot/user/${event.userId}/referral`);
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    // console.log("get-ies-user-details handleResponse: ", new Date(), new Date().getTime());
    if (response.data && response.data.status === 'success') {
      const dlUserProfile = toJS(
        Framework.getStoreRegistry().getStore('dlUser')
      );
      dlUserProfile.lastLoginSuccess = response.data.data.credentials[0].lastLoginSuccess;
      dlUserProfile.resetPassword = response.data.data.credentials[0].resetPassword;
      dlUserProfile.userName = response.data.data.credentials[0].userName;
      dlUserProfile.accountStatus = response.data.data.identity.accountStatus;
      dlUserProfile.createdAt = response.data.data.identity.createdAt;
      dlUserProfile.homeCountryCode = response.data.data.identity.homeCountryCode;
      dlUserProfile.id = response.data.data.identity.id;
      dlUserProfile.lifecycleStatus = response.data.data.identity.lifecycleStatus;
      dlUserProfile.updatedAt = response.data.data.identity.updatedAt;
      dlUserProfile.familyName = response.data.data.identityProfile.familyName;
      dlUserProfile.givenName = response.data.data.identityProfile.givenName;
      dlUserProfile.emailAddress = response.data.data.identityProfile.emails ? response.data.data.identityProfile.emails[0].emailAddress : '';
      Framework.getStoreRegistry().getStore('dlUser').set(dlUserProfile);
      Framework.getEventManager().publish(
        constants.DL_USER_PROFILE_FETCHED,
        response.data.data
      );
    } else {
      Framework.getEventManager().publish(constants.DL_USER_PROFILE_ERROR, {
        message: 'Unable to fetch User Profile'
      });
    }
  }

  handleError(error, event) { // eslint-disable-line class-methods-use-this
    // Framework.getStoreRegistry().getStore('dlUser').set({});
    Framework.getEventManager().publish(constants.DL_USER_PROFILE_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
