import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import * as constants from '../constants';
// import DataFormatter from "../../../utilities/dataFormatter";
import DataFormatter from '../utils/DataFormatter';
// var lang = DataFormatter.getLanguage();
import sparkLogo from '../../assets/images/spark/sparkreading_logo.png';

class WelcomeMessage extends Component {
  state={
    isDomainSpark: false
  };

  componentDidMount() {
    this.setState({ isDomainSpark: location.href.includes('spark') });
    localStorage.setItem('isDomainSpark', location.href.includes('spark'));
  }

  render() {
    const { language, intl } = this.props;
    // const sparkLogo = "/assets/images/young_learners/sparkreading_logo.png";
    if (this.state.isDomainSpark === true) {
      return (
        <div className="row welcome">
          <div className="col-sm-6 pad0 align-items-end">
            <div className="row pad0 welcome-message-row">
              <div className="col">
                <div className="welcome-to-text">{intl.formatMessage(language.getText('welcome_text'))}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="row spark-logo-welcome-row  justify-content-center">
              <div className="col">
                <img src={sparkLogo} alt="Spark Logo" className="spark-logo-welcome" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row justify-content-center">
        <div className="col">
          <div className="welcome-global-text">{intl.formatMessage(language.getText('welcome_!'))}</div>
        </div>
      </div>
    );
  }
}

WelcomeMessage.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};
export default compose(
  inject('language'),
  observer
)(injectIntl(WelcomeMessage));
