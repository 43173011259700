/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../constants';

const DlConfigCaas = types.model('DlConfigCaas', {
  id: types.maybeNull(types.string)
});
const DlConfigGabProductIds = types.model('DlConfigGabProductIds', {
  'digital-library': types.maybeNull(types.string),
  spark: types.maybeNull(types.string)
});

const DlConfig = types
  .model('DlConfig', {
    AppID: types.maybeNull(types.string),
    BaseUrl: types.maybeNull(types.string),
    glsHawkId: types.maybeNull(types.string),
    glsHawkKey: types.maybeNull(types.string),
    glsHawkAlgorithm: types.maybeNull(types.string),
    pulseHawkId: types.maybeNull(types.string),
    pulseHawkKey: types.maybeNull(types.string),
    pulseHawkAlgorithm: types.maybeNull(types.string),
    cwtHawkKey: types.maybeNull(types.string),
    readerHawkId: types.maybeNull(types.string),
    readerHawkKey: types.maybeNull(types.string),
    readerHawkAlgorithm: types.maybeNull(types.string),
    pulseauthGABUrl: types.maybeNull(types.string),
    pulseapiGABUrl: types.maybeNull(types.string),
    pulseapiId: types.maybeNull(types.string),
    pulseapiKey: types.maybeNull(types.string),
    pulseapiAlgorithm: types.maybeNull(types.string),
    v3HawkId: types.maybeNull(types.string),
    v3HawkKey: types.maybeNull(types.string),
    arsHawkId: types.maybeNull(types.string),
    arsHawkKey: types.maybeNull(types.string),
    arsHawkAlgorithm: types.maybeNull(types.string),
    arsTenantTag: types.maybeNull(types.string),
    gaId: types.maybeNull(types.string),
    gaEnabled: types.maybeNull(types.boolean),
    gabProductIds: types.maybeNull(DlConfigGabProductIds),
    caas: types.maybeNull(DlConfigCaas),
    arsUrl: types.maybeNull(types.string),
    aapiUrl: types.maybeNull(types.string),
    aapiHawkId: types.maybeNull(types.string),
    aapiHawkKey: types.maybeNull(types.string),
    aapiHawkAlgorithm: types.maybeNull(types.string),
    studentAnalytics: types.maybeNull(types.string),
    iesProxyHawkUrl: types.maybeNull(types.string),
    iesProxyHawkId: types.maybeNull(types.string),
    iesProxyHawkKey: types.maybeNull(types.string),
    iesProxyHawkAlgorithm: types.maybeNull(types.string),
    courseSectionUrl: types.maybeNull(types.string),
    younglearnersiesUrl: types.maybeNull(types.string),
    younglearnersproductId: types.maybeNull(types.string),
    gabHawkUrl: types.maybeNull(types.string),
    gabHawkId: types.maybeNull(types.string),
    gabHawkSecret: types.maybeNull(types.string),
    gabHawkAlgorithm: types.maybeNull(types.string),
    ingestionHawkUrl: types.maybeNull(types.string),
    ingestionHawkId: types.maybeNull(types.string),
    ingestionHawkKey: types.maybeNull(types.string),
    ingestionHawkAlgorithm: types.maybeNull(types.string),
    tenantId: types.maybeNull(types.string),
    tenantKey: types.maybeNull(types.string),
    env: types.maybeNull(types.string)
  })
  .actions(self => ({
    fetch() {
      Framework.getEventManager().publish(constants.DL_CONFIG_REQUESTED, {});
    },
    set(state) {
      applySnapshot(self, state);
    }
  }));

export default DlConfig;
