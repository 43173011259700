import env from '../env';

const ja = {
  'bookshelf.TITLE': '私の本棚',
  'common.LOGOUT': 'ログアウト',
  'common.GO_HOME': 'ホームに移動',
  'common.PURCHASE': '購入',
  'courses.WELCOME': 'こんにちは、{userName}さん。{courseName}コースへようこそ。',
  'courses.action.SHOW_ASSIGNMENTS': 'アサインを表示',
  'courses.toc.PUBLISH_SUCCESS': '成功！',
  'courses.toc.PUBLISH_MESSAGE': '生徒が次にコンテンツを開いたときに、変更が表示されます。',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'サブスクリプションが見つかりません',
  'errors.SUBSCRIPTION_GENERIC_DESC': `アカウントには、このタイトルへのアクセスが含まれていません。このメッセージを誤って受け取ったと思われる場合は、
      Pearsonテクニカルサポートにお問い合わせください。`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'サブスクリプションが見つかりません',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'サブスクリプションにはeTextへのアクセスは含まれていませんが、クレジットカードを使用して今すぐサブスクライブできます。',
  'foxit.ABOUT': '詳細',
  'foxit.COPY_RIGHT': 'PDF Reader Powered by Foxit. Copyright (C) 2003-2019 by Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': 'サポートに連絡',
  'menu.ABOUT': '詳細',
  'menu.ACCESSIBILITY': 'アクセシビリティ',
  'menu.FOXIT': 'Powered by Foxit',
  'menu.TERMS_OF_USE': '利用規約',
  'menu.PRIVACY_POLICY': 'プライバシー方針',
  'menu.SIGN_OUT': 'サインアウト',
  'menu.PRINT_PAGE': 'ページを印刷',
  'menu.GET_LOOSE_LEAF': 'ルーズリーフを取得',
  'menu.AVAILABLE': 'こちらも利用可能',
  'menu.PRINT_VERSION': '印刷版をご希望ですか？',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. All Rights Reserved.',
  'menu.tooltip.SCHEDULE': 'スケジュール',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'こんにちは、{userName}さん。',
  'device.SWAPPED_ALREADY': '今月はすでに交換しています',
  'device.CONTACT_SUPPORT': '来月まで待つか、サポートにお問い合わせください。',
  'device.SWITCH_DEVICE': '現在のデバイスに切り替えますか？',
  'device.SWITCH_ONCE': '切り替えができるのは月に1回のみのため、元に戻すことはできません。',
  'device.SEAT_TAKEN': '席がいっぱいです',
  'device.DISCONNECT_DEVICE': 'ログインできるデバイスの台数は3台のみです。続行するには、他のデバイスの接続を解除してください。',
  'device.ALERT_TEXT': 'なお、デバイスの交換は1ヶ月に1回しかできません。',
  'device.YOUR_MOJO': 'Mojoにアクセスしています'
};

export default ja;
