import env from '../env';

const id = {
  'common.LOGOUT': 'Keluar',
  'common.GO_HOME': 'Buka Beranda',
  'common.PURCHASE': 'Pembelian',
  'courses.WELCOME': 'Halo {userName}, selamat datang di kursus {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Tampilkan Tugas',
  'courses.toc.PUBLISH_SUCCESS': 'Berhasil!',
  'courses.toc.PUBLISH_MESSAGE': 'Siswa hanya akan melihat perubahan Anda saat mereka membuka konten nanti.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Langganan Tidak Ditemukan',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Akun Anda tidak mencakup akses ke judul ini. Jika Anda pikir Anda menerima pesan ini karena kesalahan,
      silakan hubungi Dukungan Teknis Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Langganan Tidak Ditemukan',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Langganan Anda tidak mencakup akses ke eText, akan tetapi Anda dapat berlangganan eText sekarang 
    menggunakan kartu kredit.`,
  'foxit.ABOUT': 'Tentang',
  'foxit.COPY_RIGHT': 'PDF Reader Didukung oleh Foxit. Hak Cipta (C) 2003-2019 oleh Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.CONTACT_SUPPORT': 'Hubungi Dukungan',
  'menu.ABOUT': 'Tentang',
  'menu.ACCESSIBILITY': 'Aksesibilitas',
  'menu.FOXIT': 'Didukung oleh Foxit',
  'menu.TERMS_OF_USE': 'Ketentuan Penggunaan',
  'menu.PRIVACY_POLICY': 'Kebijakan Privasi',
  'menu.SIGN_OUT': 'Keluar',
  'menu.PRINT_PAGE': 'Cetak halaman',
  'menu.GET_LOOSE_LEAF': 'Dapatkan halaman lepas',
  'menu.AVAILABLE': 'Juga Tersedia',
  'menu.PRINT_VERSION': 'Ingin versi cetak?',
  'menu.COPY_RIGHT': 'Hak Cipta © {year} Pearson Education Inc. Hak Cipta Dilindungi Undang-Undang.',
  'menu.tooltip.SCHEDULE': 'Jadwal',
  'print.WATERMARK': 'Hak Cipta © {year} Pearson Education',
  'product.WELCOME': 'Halo {userName}',
  'device.SWAPPED_ALREADY': 'Anda sudah mengganti bulan ini',
  'device.CONTACT_SUPPORT': 'Tunggu hingga bulan berikutnya, atau hubungi dukungan untuk bantuan lebih lanjut.',
  'device.SWITCH_DEVICE': 'Beralih ke perangkat saat ini?',
  'device.SWITCH_ONCE': 'Karena Anda hanya dapat berganti sekali dalam sebulan, Anda tidak akan dapat mengurungkan ini.',
  'device.SEAT_TAKEN': 'Kursi tersebut sudah terisi',
  'device.DISCONNECT_DEVICE': 'Anda hanya dapat masuk di 3 perangkat. Untuk melanjutkan di sini, putuskan perangkat lainnya.',
  'device.ALERT_TEXT': 'Ingat, Anda hanya dapat berganti perangkat sekali dalam sebulan.',
  'device.YOUR_MOJO': 'Sedikit lagi Anda sampai ke Mojo'
};

export default id;
