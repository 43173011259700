/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';

export default class CartridgeService extends BaseService {
  constructor() {
    super(constants.CARTRIDGELIST_REQUESTED, 'CartridgeServiceStatus');
  }

  handleEvent(eventName, event) {
    let payload = `{"externalRef": [{"originId": "${event.countrycode}", "source": "dl-library"}]}`;
    payload = JSON.parse(payload);
    // let payload = {"externalRef":[{"originId":"CA","source":"dl-library"}]};
    return this.getClient('hapi').post(
      '/cartridge-list-fetch',
      payload
    );
  }

  handleResponse(response) { // eslint-disable-line class-methods-use-this
    if (response.data && response.data.length > 0) {
      Framework.getEventManager().publish(constants.CARTRIDGELIST_FETCHED, response.data);
    } else {
      Framework.getEventManager().publish(constants.CARTRIDGELIST_EMPTY, {
        message: 'Unable to fetch Cartridge Data'
      });
    }
  }

  handleError(error) { // eslint-disable-line class-methods-use-this
    Framework.getEventManager().publish(constants.CARTRIDGELIST_ERROR, {
      message:
        (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
    });
  }
}
