import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Framework from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataFormatter from '../../../common/utils/DataFormatter';
import * as constants from '../../../common/constants';
import largerDesktopBgImage from '../../../assets/images/newdesign_v5_1920_1080.png';
import desktopBgImage from '../../../assets/images/newdesign_v5_1280_800.png';
import iPadBgImage from '../../../assets/images/newdesign_v5_768_1024.png';

const Styles = (theme) => ({
  selectLibraryWrapper: {
    backgroundImage: `url(${largerDesktopBgImage})`,
    '@media (max-width: 1280px) and (min-width: 821px)': {
      backgroundImage: `url(${desktopBgImage})`
    },
    '@media (max-width: 820px)': {
      backgroundImage: `url(${iPadBgImage})`
    }
  }
});
class SelectYourLibrary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isButtonDisabled: false,
      value: 0,
      libraryListObject: '',
      showLibraryError: false,
      showCountrySupportError: false
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

    redirectToNextPage = () => {
      // Here we will read the selected Library and call the APIs to Create school,class-section and adding permissions to user
      // console.log(this.state.value);
      if (this.state.value === 0) {
        return;
      }
      if (this.state.isButtonDisabled) {
        return;
      }
      this.setState({ isButtonDisabled: true });
      // Selected library catridge id = this.state.value , userId = localStorage value of piSessionUserId
      const userId = localStorage.getItem('userId');
      const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
      const name = `${iesUserDetails.identityProfile.givenName} ${iesUserDetails.identityProfile.familyName} ${localStorage.getItem('userId')}`;
      const countryCode = iesUserDetails.identity.homeCountryCode;

      const roleValue = 'teacher';
      const isCreateClass = 'true';
      const cartridgeId = this.state.value;

      const payLoad = {};
      payLoad.userId = userId;
      payLoad.name = name;
      payLoad.countryCode = countryCode;
      payLoad.roleValue = roleValue;
      payLoad.isCreateClass = isCreateClass;
      payLoad.cartridgeId = cartridgeId;

      // AppStore.on(AppConstants.EventTypes.TEACHER_CREATE_SCHOOL,this.callbackTeacherCreateSchool);

      // AppActions.teacherCreateSchool(userId, name, countryCode, roleValue, isCreateClass, cartridgeId);
      Framework.getEventManager().publish(constants.CREATE_TEACHER_SCHOOL_CLASS_REQUESTED,
        {
          payload: payLoad
        });
    }

    addPermissionsForUser = (schoolId) => {
      if (this.state.value === 0 || schoolId === '') {
        this.setState({ showLibraryError: true });
        return false;
      }
      // Selected library catridge id = this.state.value , userId = localStorage value of piSessionUserId
      const userId = localStorage.getItem('userId');
      Framework.getEventManager().publish(constants.CREATE_TEACHER_PERMISSIONS_REQUESTED,
        {
          userId,
          schoolId
        });
    }

    addCartridgeToSchool = (schoolId) => {
      if (this.state.value === 0 || schoolId === '') {
        return false;
      }
      Framework.getEventManager().publish(constants.PROVISION_CARTRIDGE_REQUESTED,
        {
          cartridgeId: this.state.value,
          schoolId
        });
      return false;
    }

    componentDidMount() {
      // AppStore.on(AppConstants.EventTypes.FETCH_LIBRARY_LIST,this.callbackFetchLibraryList);
      Framework.getEventManager().on(constants.CARTRIDGELIST_FETCHED, (libraryListDetails) => {
        this.callbackFetchLibraryList(libraryListDetails);
      });
      Framework.getEventManager().on(constants.CARTRIDGELIST_EMPTY, () => {
        this.setState({ showCountrySupportError: true });
      });
      Framework.getEventManager().on(constants.CREATE_TEACHER_SCHOOL_CLASS_CREATED, (setTeacherSchoolData) => {
        this.callbackTeacherCreateSchool(setTeacherSchoolData);
      });
      Framework.getEventManager().on(constants.CREATE_TEACHER_PERMISSIONS_CREATED, (setTeacherPermissionData) => {
        this.callbackTeacherCreatePermissions(setTeacherPermissionData);
      });
      Framework.getEventManager().on(constants.PROVISION_CARTRIDGE_CREATED, (setCartridgeToSchoolData) => {
        this.callbackAddCartridgeToSchool(setCartridgeToSchoolData);
      });
      Framework.getEventManager().on(constants.CARTRIDGELIST_ERROR, () => {
        // There is a failiure to get Cartridge List
        this.setState({ showLibraryError: true });
      });
      Framework.getEventManager().on(constants.CREATE_TEACHER_SCHOOL_CLASS_ERROR, () => {
        // There is a failiure to create school-class
        this.setState({ showLibraryError: true });
      });
      Framework.getEventManager().on(constants.CREATE_TEACHER_SCHOOL_CLASS_FAIL, () => {
        // There is a failiure to create school-class
        this.setState({ showLibraryError: true });
      });
      Framework.getEventManager().on(constants.CREATE_TEACHER_PERMISSIONS_ERROR, () => {
        // There is a failiure to create school-class
        this.setState({ showLibraryError: true });
      });
      Framework.getEventManager().on(constants.CREATE_TEACHER_PERMISSIONS_FAIL, () => {
        // There is a failiure to create school-class
        this.setState({ showLibraryError: true });
      });
      Framework.getEventManager().on(constants.PROVISION_CARTRIDGE_ERROR, () => {
        // There is a failiure to create school-class
        this.setState({ showLibraryError: true });
      });
      Framework.getEventManager().on(constants.PROVISION_CARTRIDGE_FAIL, () => {
        // There is a failiure to create school-class
        this.setState({ showLibraryError: true });
      });

      const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
      const countryCode = iesUserDetails.identity.homeCountryCode;
      // AppActions.getLibraryListData(countryCode);
      // var payLoad = `{"externalRef": [{"originId": "${countryCode}", "source": "dl-library"}]}`;
      Framework.getEventManager().publish(constants.CARTRIDGELIST_REQUESTED,
        {
          countrycode: countryCode
        });
    }

    handleChange = event => this.setState({ value: event.target.value });

    callbackFetchLibraryList = (libraryListDetails) => {
      // If we have a valid list of Library details from API, let us create the dropdown list
      if (typeof libraryListDetails !== 'undefined') {
        const libraryList = libraryListDetails.map(library => (
          <MenuItem
            key={library.id}
            value={library.id}
            className="teacher-select-library-dropdown-text"
          >
            {library.name}
          </MenuItem>
        ));
        this.setState({ libraryListObject: libraryList });
      }
    }

    callbackTeacherCreateSchool = (setTeacherSchoolData) => {
      // Call back method for create school API call for Teacher Signup
      if (typeof setTeacherSchoolData !== 'undefined') {
        if (typeof setTeacherSchoolData.schoolId !== 'undefined' && setTeacherSchoolData.schoolId !== '') {
          // If previous request is successful we have to call the next API to add permissions for the user.
          this.addPermissionsForUser(setTeacherSchoolData.schoolId);
        } else {
          this.setState({ showLibraryError: true });
        }
      } else {
        this.setState({ showLibraryError: true });
      }
    }

    callbackTeacherCreatePermissions = (setTeacherPermissionData) => {
      // Call back method for create school API call for Teacher Signup
      if (typeof setTeacherPermissionData !== 'undefined') {
        if (typeof setTeacherPermissionData.created !== 'undefined' && setTeacherPermissionData.created !== '') {
          // If request is successful we have to call next API for provisioning cartridge to school.
          const schoolId = setTeacherPermissionData.created[0].scope.schoolIds[0];
          this.addCartridgeToSchool(schoolId);
        } else {
          this.setState({ showLibraryError: true });
        }
      } else {
        this.setState({ showLibraryError: true });
      }
    }

    callbackAddCartridgeToSchool = (setCartridgeToSchoolData) => {
      // Call back method for create school API call for Teacher Signup
      if (typeof setCartridgeToSchoolData !== 'undefined') {
        if (typeof setCartridgeToSchoolData[0].id !== 'undefined' && setCartridgeToSchoolData[0].id !== '') {
          // If request is successful we have to redirect to next page(AccountCreated).
          // this.props.history.push("/confirmlib");
          Framework.redirectTo('/teacher/confirmlib');
        } else {
          this.setState({ showLibraryError: true });
        }
      } else {
        this.setState({ showLibraryError: true });
      }
    }

    logoutUser = () => {
      const piSession = window.piSession;
      if (piSession) {
        piSession.setOptions({
          requireLogin: false
        });
      }
      if (piSession) {
        const redirectURL = `${window.location.protocol}//${window.location.host}`;
        piSession.logout(redirectURL);
      } else {
        Framework.logout();
      }
    }

    handleCloseDialog() {
      this.setState({ showCountrySupportError: false });
    }

    render() {
      const { language, intl, classes } = this.props;
      const countryNotSupportedMsg = intl.formatMessage(language.getText('country_not_supported_msg'));
      const signOutLabel = intl.formatMessage(language.getText('close'));
      return (
        <MuiThemeProvider>
          <Dialog title="" modal open={this.state.showCountrySupportError} className="listing-outer-container deleteStudentContainer">
            <div className="listing-message-container">
              {/* <div
                className="listing-close"
                tabIndex="0"
                onClick={this.handleCloseDialog.bind(this, 'deleteStudent')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleCloseDialog(this, 'deleteStudent');
                  }
                }}
              >
X
              </div> */}
              <br />
              <div className="country-not-supported">
                <div className="row">
                  <div className="col-md-11 col-lg-11 col-xl-12">
                    <div className="col no-students-text">{countryNotSupportedMsg}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Button
                      variant="contained"
                      className="no-students-button"
                      primary
                      onClick={this.logoutUser}
                    >
                      {signOutLabel}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <div className={`teacher-select-library-outer-container ${classes.selectLibraryWrapper}`}>
            <div className="teacher-select-library-container">
              <label className="teacher-select-library-txt">{intl.formatMessage(language.getText('select_library'))}</label>
              <br />
              <Select
                IconComponent={ExpandMoreIcon}
                className="teacher-select-library-dropdown"
                value={this.state.value}
                onChange={this.handleChange}
                MenuProps={{ className: 'teacher-select-library-dropdown-menu' }}
              >
                <MenuItem key={0} value={0} className="teacher-select-library-dropdown-text">{intl.formatMessage(language.getText('make_selection'))}</MenuItem>
                {
                            (this.state.libraryListObject)
                              ? this.state.libraryListObject : []
                        }
              </Select>
              <br />
              <button id="id_select_library" className="teacher-btn-select-library" onClick={this.redirectToNextPage}><label className="teacher-btn-select-library-text">{intl.formatMessage(language.getText('create_account'))}</label></button>
              {this.state.showLibraryError
                ? <div className="libraryErrorBlock">{intl.formatMessage(language.getText('select_library_error'))}</div>
                : ''}
            </div>
            {this.state.isButtonDisabled
              ? (
                <div className="circularLoader">
                  <CircularProgress mode="indeterminate" />
                  <br />
                </div>
              )
              : ''}
            <div id="signout" tabIndex="1" className="select-library-signout" onClick={this.logoutUser}>
              <a href="javascript:void(0)">{intl.formatMessage(language.getText('sign_out_label'))}</a>
            </div>
          </div>
        </MuiThemeProvider>
      );
    }
}

SelectYourLibrary.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired
};

export default withStyles(Styles)(compose(
  inject('language'),
  observer
)(injectIntl(SelectYourLibrary)));
