import React from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import DataFormatter from '../../../common/utils/DataFormatter';
import TabComponent from './TabComponent';
import SchoolLibrary from './SchoolLibrary';
import StudentAnalyticsContainer from './StudentAnalyticsContainer';

// var lang = DataFormatter.getLanguage();


const masterStyle = {
  fontFamily: 'Open Sans'
};

const muiSecondaryTheme = createTheme(masterStyle);


const books = [];

class SecondaryApplicationContainer extends React.Component {
  // constructor(){
  //   super();
  // }
  state = {
    activeTab: 0
  }

  // componentDidMount = function(){
  // }
  // componentDidUpdate = function(prevProps, prevState){
  // }
  tabsList = [];

  UNSAFE_componentWillMount = function () {
    const { intl, language } = this.props;
    const tabConfigs = {
      'school-library': {
        tabLabel: intl.formatMessage(language.getText('school_library')),
        id: 'schoolLibraryTab',
        content: (<div>
          <SchoolLibrary schoolId={(this.props.schoolId ? this.props.schoolId : false)} mode="secondary" />
        </div>),
        mode: 'secondary'
      },
      student: {
        tabLabel: intl.formatMessage(language.getText('students')),
        id: 'studentTab',
        content: (
          <div>
            <StudentAnalyticsContainer />
          </div>
        ),
        mode: 'secondary'
      }
    };
    const role = DataFormatter.getKeyFromObject('userInformation', 'role');
    if (localStorage.getItem('appConfig')) {
      let appConfig = localStorage.getItem('appConfig').replace(/\\/g, '');
      appConfig = JSON.parse(appConfig.substring(appConfig.indexOf('{'), appConfig.lastIndexOf('}') + 1));
      const { dashboard } = appConfig;
      if (dashboard && dashboard.sections instanceof Array) {
        for (const sectionItem of dashboard.sections) {
          if (sectionItem.isEnabled && sectionItem.roleValues.includes(role) && tabConfigs[sectionItem.tag]) {
            this.tabsList.push(tabConfigs[sectionItem.tag]);
            // console.log(tabConfigs[sectionItem.tag]);
          }
        }
      }
    }
  }

  render = function () {
    let teacherName; let schoolList; let classRoomName = ''; let imageFileName;
    try {
      teacherName = DataFormatter.getKeyFromObject('userInformation', 'fName');
      schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
      classRoomName = schoolList[0].name;
      imageFileName = schoolList[0].links.heroImage;
    } catch (e) {
      console.log(e);
    }
    return (
      <MuiThemeProvider theme={muiSecondaryTheme}>
        <div className="secondaryContainer">
          <div id="maincontent">
            <TabComponent tabsList={this.tabsList} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'language'),
  observer
)(injectIntl(SecondaryApplicationContainer));
