import env from '../env';

const de = {
  'bookshelf.TITLE': 'Mein Bücherregal',
  'common.GO_HOME': 'Gehen Sie zur Startseite',
  'common.LOGOUT': 'Abmelden',
  'common.PURCHASE': 'Kaufen',
  'courses.action.SHOW_ASSIGNMENTS': 'Zuordnungen anzeigen',
  'courses.toc.PUBLISH_MESSAGE': 'Die Studenten sehen Ihre Änderungen beim nächsten Öffnen von Inhalten.',
  'courses.toc.PUBLISH_SUCCESS': 'Erfolg!',
  'courses.WELCOME': 'Hallo {userName}, willkommen zu Ihrem Kurs {courseName}!',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Ihr Konto enthält keinen Zugriff auf diesen Titel. Wenn Sie der Meinung sind, dass Sie diese Nachricht irrtümlich erhalten haben, wenden Sie sich bitte an den technischen Support von Pearson',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Abonnement konnte nicht gefunden werden',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Ihr Abonnement beinhaltet keinen Zugriff auf den eText. Sie können ihn jedoch jetzt mit einer Kreditkarte abonnieren.',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Abonnement konnte nicht gefunden werden',
  'foxit.ABOUT': 'Über',
  'foxit.COPY_RIGHT': 'PDF Reader unterstützt von Foxit. Urheberrecht 2003-2019 von Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': `${env.CONSOLE_URL}/account/manage/privacy`,
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': `${env.CONSOLE_URL}/account/manage/tos`,
  'menu.ABOUT': 'Über',
  'menu.ACCESSIBILITY': 'Zugänglichkeit',
  'menu.AVAILABLE': 'Auch verfügbar',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Alle Rechte vorbehalten.',
  'menu.CONTACT_SUPPORT': 'Support kontaktieren',
  'menu.FOXIT': 'Unterstützt von Foxit',
  'menu.GET_LOOSE_LEAF': 'Lose Blätter erhalten',
  'menu.PRINT_PAGE': 'Seite drucken',
  'menu.PRINT_VERSION': 'Möchten Sie die gedruckte Version erhalten?',
  'menu.PRIVACY_POLICY': 'Datenschutzbestimmungen',
  'menu.SIGN_OUT': 'Abmelden',
  'menu.TERMS_OF_USE': 'Nutzungsbedingungen',
  'menu.tooltip.SCHEDULE': 'Zeitplan',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hallo {userName}',
  'device.SWAPPED_ALREADY': 'Sie haben diesen Monat bereits getauscht',
  'device.CONTACT_SUPPORT': 'Warten Sie bis zum nächsten Monat, oder wenden Sie sich an den Support, um weitere Hilfe zu erhalten.',
  'device.SWITCH_DEVICE': 'Zum aktuellen Gerät wechseln?',
  'device.SWITCH_ONCE': 'Da Sie nur einmal im Monat wechseln können, können Sie dies nicht mehr rückgängig machen.',
  'device.SEAT_TAKEN': 'Dieser Platz ist besetzt',
  'device.DISCONNECT_DEVICE': 'Sie können sich nur an 3 Geräten anmelden. Um hier fortzufahren, trennen Sie die Verbindung zu einem weiteren Gerät.',
  'device.ALERT_TEXT': 'Denken Sie daran, dass Sie die Geräte nur einmal im Monat tauschen können.',
  'device.YOUR_MOJO': 'Sie sind auf dem Weg zum Mojo'
};

export default de;
