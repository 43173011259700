import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import {
  CircularProgress
} from '@material-ui/core';

import ReactHtmlParser from 'react-html-parser';
import TagManager from 'react-gtm-module';
import CommonUtils from '../../common/utils/CommonUtils';
import * as constants from '../../common/constants';
import DlUser from '../../common/model/DlUser';
import DlUserSchool from '../../common/model/DlUserSchool';
import DlConfig from '../../common/model/DlConfig';
import DlServerYear from '../../common/model/DlServerYear';
import DlAppConfig from '../../common/model/DlAppConfig';
import getCurrentBrand from '../../common/utils/BrandHandler';
import DataFormatter from '../../common/utils/DataFormatter';
import ExpiryMessage from '../../common/components/ExpiryMessage';
import Navbar from './components/Navbar';
import ImageBar from './components/ImageBar';
import PrimaryApplicationContainer from './components/PrimaryApplicationContainer';
import SecondaryApplicationContainer from './components/SecondaryApplicationContainer';
import FooterBar from './components/FooterBar';
import FooterSnackbar from './components/FooterSnackBar';
import TeacherSnackbar from './components/TeacherSnackbar';

const USER_ROLE = {
  TEACHER: 'teacher'
};

const APP_MODE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

class ApplicationContainer extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    language: PropTypes.object.isRequired
  };

  state = {
    appui_mode: 'primary',
    readingui_mode: 'primary',
    role: null,
    schoolId: null,
    userId: null,
    displayExpiryMsg: false,
    licenseExpiryLogout: false,
    licenseExpiryMsg: '',
    appReloading: true,
    displayTeacherLicenseExpiryMsg: true,
    isHovered: true
  };

  validateLicenseExpiry = () => {
    const { language, intl } = this.props;
    let subscriptionEndDate = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school')[0].products[0].endDate;
    let subscriptionStartDate = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school')[0].products[0].startDate;

    subscriptionEndDate = new Date(subscriptionEndDate);
    subscriptionStartDate = new Date(subscriptionStartDate);

    // Finding the License Period - (difference between start date and end date)/2
    const subscriptionLength = ((subscriptionEndDate.getTime() - subscriptionStartDate.getTime()) / (1000 * 3600 * 24)) / 2;

    // Add subscriptionLength to subscriptionStartDate to find the actual license end date
    const licenseEndDate = new Date(subscriptionStartDate);
    licenseEndDate.setDate(subscriptionStartDate.getDate() + subscriptionLength);
    const currentDate = new Date();
    // var licenseRemainingDays = Math.round((licenseEndDate.getTime() - currentDate.getTime())/ (1000 * 3600 * 24));

    // RPLUS-5066 New Implementation of Trial/Grace period starts

    // Get Trial Period(trialPeriod) from API and check whether subscriptionStartDate+trialPeriod crossed currentDate
    const trialPeriod = parseInt(DataFormatter.getKeyFromObject('iesSchoolDetails', 'school')[0].metadata.trialPeriod);
    const gracePeriod = parseInt(DataFormatter.getKeyFromObject('iesSchoolDetails', 'school')[0].metadata.gracePeriod);
    const trialBannerLink = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school')[0].metadata.trialBannerLink;
    const graceBannerLink = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school')[0].metadata.graceBannerLink;
    const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
    const countryCode = iesUserDetails.identity.homeCountryCode.toLowerCase();

    const newTrialEndDate = new Date(subscriptionStartDate);
    newTrialEndDate.setDate(subscriptionStartDate.getDate() + trialPeriod);

    if (newTrialEndDate > currentDate) { // If user is in Trial period
      if (localStorage.getItem('trialNotified') === 'true') {
        return false;
      }
      let trialRemainingDays = Math.round((newTrialEndDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));
      if (trialRemainingDays == 0) {
        trialRemainingDays = 1;
      }

      const dispMsg = (
        <p>
          {ReactHtmlParser(this.replaceText(intl.formatMessage(language.getText(`license_purchase_msg_${countryCode}`)), [trialRemainingDays, trialBannerLink]))}
        </p>
      );
      if (localStorage.getItem('usertype') == 'teacher') {
        this.setState({ licenseExpiryMsg: dispMsg, displayExpiryMsg: true });
      }
    } else { // Trial Period over, need to check whether grace period is over or not
      const newGraceEndDate = new Date(subscriptionStartDate);
      newGraceEndDate.setDate(subscriptionStartDate.getDate() + (trialPeriod + gracePeriod));

      if (newGraceEndDate > currentDate) { // || licenseEndDate > currentDate // newGraceEndDate > subscriptionEndDate
        this.setState({
          licenseExpiryMsg: ReactHtmlParser(this.replaceText(intl.formatMessage(language.getText(`license_expiry_msg_${countryCode}`)), [trialBannerLink])),
          displayExpiryMsg: true
        });
        if (localStorage.getItem('usertype') == 'student') {
          this.setState({
            licenseExpiryMsg: ReactHtmlParser(intl.formatMessage(language.getText(`license_expiry_msg_student_${countryCode}`)))
          });
        }
        this.setState({ licenseExpiryLogout: true });// If Trial period is over user needs to be logged out
      }
      else {
        this.setState({
          licenseExpiryMsg: ReactHtmlParser(this.replaceText(intl.formatMessage(language.getText(`license_expiry_msg_${countryCode}`)), [trialBannerLink])),
          displayExpiryMsg: true,
          licenseExpiryLogout: true
        });
      }
    }
  }

  // RPLUS-5066 New Implementation of Trial/Grace period ends

  // }
  handleClose = () => {
    localStorage.setItem('trialNotified', 'true');
    this.setState({ displayExpiryMsg: false });
    if (this.state.licenseExpiryLogout) { // Logging out the user and redirecting to signin page
      if (localStorage.getItem('userId') !== 'null' && localStorage.getItem('userId') !== undefined) {
        const piSession = window.piSession;
        if (piSession) {
          piSession.setOptions({
            requireLogin: false
          });
        }
        // window.piSession.logout(() => {
          if (window.localStorage) {
            // Getting the userName from localStorage before clearing to set it back - fix for RPLUS-4705
            const userName = localStorage.getItem('userName');
            const isStudentAdd = localStorage.getItem('isStudentAdd');
            localStorage.clear();
            localStorage.setItem('userName', userName);
            (isStudentAdd === 'true') ? localStorage.setItem('isStudentAdd', isStudentAdd) : '';
          }
          // localStorage.setItem('usertype', 'teacher');
          // window.piSession.login(window.location.href);
          if (piSession) {
            const redirectURL = `${window.location.protocol}//${window.location.host}`;
            piSession.logout(redirectURL);
          } else {
            Framework.logout();
          }
        // });
        // AppActions.logoutUser();
      }
    }
  }

  handleExpiryMsgClose = () => {
    this.setState({ displayTeacherLicenseExpiryMsg: false });
    DataFormatter.setObjectInStorage('isExpiryMessageClosed', 'true');
  }

  replaceText = (licenceMsg, licenceValues) => {
    licenceValues.forEach((value, index) => {
      licenceMsg = licenceMsg.replace(new RegExp(`\\{${index}\\}`, 'g'), value);
    });
    return licenceMsg;
  };

  handleMouseEnter() {
    this.setState({ isHovered: false });
  }

  handleMouseLeave() {
    this.setState({ isHovered: true });
  }

  componentDidMount() {
    const {
      dlUser, dlUserSchool, dlConfig, dlServerYear
    } = this.props;
    Promise.all([getCurrentBrand(), dlConfig.fetch(), dlServerYear.fetch()]);
    DataFormatter.setObjectInStorage('isReaderplus', true);
    const schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
    if (dlUser?.id && schoolList.length) {
      const appMode = (schoolList[0]?.roleValue === USER_ROLE.TEACHER) ? APP_MODE.SECONDARY : APP_MODE.PRIMARY;
      let appConfig = localStorage.getItem('appConfig') && localStorage.getItem('appConfig').replace(/\\/g, '');
      if (appConfig) {
        appConfig = JSON.parse(
          appConfig.substring(
            appConfig.indexOf('{'),
            appConfig.lastIndexOf('}') + 1
          )
        );
      }
      const readingUIConfig = appConfig?.readingui;
      const readinguiMode = (readingUIConfig && readingUIConfig.ui_mode && readingUIConfig.ui_mode.teacher === 'primary_ui'
      ) ? APP_MODE.PRIMARY : APP_MODE.SECONDARY;
      this.setState({
        schoolId: schoolList[0]?.id,
        userId: dlUser?.id,
        appui_mode: appMode,
        readingui_mode: readinguiMode,
        appReloading: false
      });
      // Promise.all([dlUser.fetch(dlUser?.id)]);
    }
    // Getting Userdetails for the IES logged in user
    if (localStorage.getItem('iesUserDetails') === null) {
      localStorage.setItem('initializeGA', true);
    }
    // --- AppActions.getIesUserDetails(localStorage.getItem('userId'));

    Framework.getEventManager().on('USER_PROFILE_FETCHED', (user) => {
      const auth = Framework.getAuthService();
      // IES User done, lets get DL data now
      if (auth && auth.isInitialized === true && user && user.id.length > 0) {
      // Call Function to get DL User & School Data
        localStorage.setItem('userId', user.id);
        // dlUser.fetch(user.id);
        Promise.all([dlUser.fetch(user.id)]);
      }
    });

    Framework.getEventManager().on(
      constants.DL_USER_PROFILE_FETCHED,
      (user) => {
      // Let us try and get the user school information
        DataFormatter.setObjectInStorage('iesUserDetails', user);
        // dlUserSchool.fetch(user.identity.id);
        Promise.all([dlUserSchool.fetch(user.identity.id)]);
      }
    );
    Framework.getEventManager().on(constants.DL_USER_SCHOOL_FETCHED, (data) => {
      if(data && data.length > 1) { // DL-556,DL-561 Checking whether teacher enrolled to multiple school or not
        let activeSchoolIdsArr = [];
        let activeSchoolNamesArr = [];
        let activeSchoolFound = false;
        for(var i=0;i < data.length;i++) {
          let subscriptionEndDate = data[i].products[0].endDate;
          subscriptionEndDate = new Date(subscriptionEndDate).setHours(0,0,0,0);;
          const currentDate = new Date().setHours(0,0,0,0);;
          let licenceExpiryDaysRemaining = subscriptionEndDate - currentDate;
          if (licenceExpiryDaysRemaining >= 0 && !activeSchoolFound) {
            activeSchoolFound = true;
            activeSchoolIdsArr.unshift(data[i].id);
            DataFormatter.setObjectInStorage('iesSchoolDetails', { school: new Array(data[i]) });
          } else if (licenceExpiryDaysRemaining >= 0) {
            activeSchoolIdsArr.push(data[i].id);
          }
          activeSchoolNamesArr.push({id:data[i].id,name:data[i].name});
        }
        if(activeSchoolIdsArr.length > 0){
          DataFormatter.setObjectInStorage('schoolIdsArr', activeSchoolIdsArr);
          DataFormatter.setObjectInStorage('schoolNamesArr', activeSchoolNamesArr);
        } else {
          DataFormatter.setObjectInStorage('iesSchoolDetails', { school: data });
        }
      } else {
        // Let us try and get the user school information
        DataFormatter.setObjectInStorage('iesSchoolDetails', { school: data });
      }
      this.iesUserSchoolFetched();
    });
    Framework.getEventManager().on(constants.DL_USER_SCHOOL_UNSET, () => {
    // Unable to find the school set to the User
    // cartridgeList.fetch({ externalRef: [{ originId: 'CA', source: 'dl-library' }] });
    // --- Call Redirect to /login/selectlib
      Framework.redirectTo('/teacher/selectlibrary');
    });
    Framework.getEventManager().on(constants.DL_CONFIG_FETCHED, (config) => {
    // Config has been fetched
      DataFormatter.setObjectInStorage('appid', config.AppID);
      DataFormatter.setObjectInStorage('configData', config);
    });
    Framework.getEventManager().on(constants.DL_SERVER_YEAR_FETCHED, (serverYear) => {
    //  Server Year has been fetched
      DataFormatter.setObjectInStorage('copyRightYear', serverYear.copyRightYear);
    });
    Framework.getEventManager().on(constants.DL_APP_CONFIG_FETCHED, (appConfig) => {
    //  App Configurations has been fetched
      DataFormatter.setObjectInStorage('appConfig', appConfig);
      this.getAppConfigCompleted();
    });
  }

  componentWillUnmount() {
    DataFormatter.setObjectInStorage('redirectionInfo', false);
  }

    getUserCompleted = () => {
      const {
        dlAppConfig
      } = this.props;
      Promise.all([
        dlAppConfig.fetch()
      ]).then(() => {
      });
    }

    getAppConfigCompleted = () => {
      const scormUrl = DataFormatter.getObjectInStorage('scormUrl');
      const redirectionInfo = DataFormatter.getObjectInStorage('redirectionInfo');
      if (redirectionInfo == 'true') {
        const productId = redirectionInfo.productId;
        DataFormatter.setObjectInStorage('productIdToOpen', productId);
        Framework.redirectTo('/book');
      } else if (scormUrl) {
        Framework.redirectTo('/launchScorm');
      } else {
        const updtState = {};
        let teacherName;
        let schoolList;
        try {
          teacherName = DataFormatter.getKeyFromObject('userInformation', 'fName');
          schoolList = DataFormatter.getKeyFromObject('userInformation', 'schools');
        } catch (e) {
          console.log(e);
        }
        if (schoolList instanceof Array && schoolList.length > 0 && schoolList[0]) {
          const userInfo = DataFormatter.getObjectInStorage('userInformation');
          userInfo.role = schoolList[0].roleValue;
          DataFormatter.setObjectInStorage('userInformation', userInfo);
          let appConfig = localStorage.getItem('appConfig') && localStorage.getItem('appConfig').replace(/\\/g, '');
          if (appConfig) {
            appConfig = JSON.parse(
              appConfig.substring(
                appConfig.indexOf('{'),
                appConfig.lastIndexOf('}') + 1
              )
            );
          }

          const readingUIConfig = appConfig?.readingui;
          updtState.appReloading = false;
          if (!updtState.appReloading) {
            DataFormatter.setObjectInStorage('reloadForAppconfig', '');
            if (schoolList[0].roleValue === USER_ROLE.TEACHER) {
              updtState.appui_mode = APP_MODE.SECONDARY;
              if (
                readingUIConfig
                && readingUIConfig.ui_mode
                && readingUIConfig.ui_mode.teacher === 'primary_ui'
              ) {
                updtState.readingui_mode = APP_MODE.PRIMARY;
              } else {
                updtState.readingui_mode = APP_MODE.SECONDARY;
              }
            } else {
              updtState.userId = DataFormatter.getKeyFromObject(
                'userInformation',
                'id'
              );
              updtState.appui_mode = APP_MODE.PRIMARY;
              if (
                readingUIConfig
                && readingUIConfig.ui_mode
                && readingUIConfig.ui_mode.student === 'adult_ui'
              ) {
                updtState.readingui_mode = APP_MODE.SECONDARY;
              } else {
                updtState.readingui_mode = APP_MODE.PRIMARY;
              }
            }
          }
          if (schoolList[0].id) {
            updtState.schoolId = schoolList[0].id;
          }
        } else {
          updtState.isLoading = false;
        }
        if (!updtState.appReloading) {
          DataFormatter.setObjectInStorage('userMode', updtState.readingui_mode);
          DataFormatter.setObjectInStorage('schoolId', updtState.schoolId);
        } else {
          updtState.isLoading = true;
        }
        this.setState(updtState);
        let isTrialFlag = false;
        const schoolArray = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school');
        if (schoolArray && typeof schoolArray[0].metadata !== 'undefined') {
          isTrialFlag = DataFormatter.getKeyFromObject(
            'iesSchoolDetails',
            'school'
          )[0].metadata.isTrial;
        }
        if (isTrialFlag === true) {
          this.validateLicenseExpiry();
        }
      }
    }

  iesUserSchoolFetched = () => {
    const { language, intl } = this.props;
    const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
    const iesUserSchool = DataFormatter.getObjectInStorage('iesSchoolDetails');
    if (iesUserDetails && iesUserSchool && iesUserSchool.school && iesUserSchool.school.length > 0) {
      // // massage data to localstorage and call appconfig
      const piSession = window.piSession;
      localStorage.setItem('contextId', piSession.getContextId());

      // Push Global Parameter
      const globalParams = CommonUtils.pushGlobalParams();
      TagManager.dataLayer(globalParams);

      TagManager.dataLayer({
        dataLayer: {
          person_id: localStorage.getItem('userId'),
          login_session_id: localStorage.getItem('contextId'),
          person_id_type: constants.ID_TYPE,
          person_role_code: localStorage.getItem('usertype') === 'teacher' ? constants.USER_TYPE_TEACHER : constants.USER_TYPE_STUDENT,
          school_id: iesUserSchool.school[0].id,
          secondary_person_id: null,
          secondary_person_id_type: null,
          secondary_person_role_code: null,
          organization_id: null, // course.organizationId || null,
          organization_id_type: null // constants.ORGANIZATION_ID_TYPE,
        }
      });

      if ((localStorage.getItem('initializeGA'))) {
        localStorage.removeItem('initializeGA');

        // Push user session env
        TagManager.dataLayer({
          dataLayer: {
            event: 'usersessionenvironment',
            transaction_dt: new Date().toISOString(),
            transaction_local_dt: CommonUtils.getLocalIsoTime()
          }
        });

        // Page view for login
        const gtmPageData = CommonUtils.getDefaultGAPageView();
        gtmPageData.dataLayer.page_title = 'Login';
        TagManager.dataLayer(gtmPageData);

        // Push login success event
        const gtmData = {
          dataLayer: {
            event: 'login',
            event_category: intl.formatMessage(language.getText('login')),
            event_action: intl.formatMessage(language.getText('login_clicked')),
            event_label: null,
            status: 'Success',
            school_id: iesUserSchool ? iesUserSchool.school[0].id : null,
            transaction_local_dt: CommonUtils.getLocalIsoTime()
          }
        };
        TagManager.dataLayer(gtmData);
      }

      const userInfoObj = {};
      userInfoObj.userToken = '';
      userInfoObj.fName = iesUserDetails.identityProfile.givenName;
      userInfoObj.lName = iesUserDetails.identityProfile.familyName;
      userInfoObj.name = `${iesUserDetails.identityProfile.givenName} ${iesUserDetails.identityProfile.familyName}`;
      userInfoObj.email = '';
      userInfoObj.phoneNumber = '';
      userInfoObj.thumbnail = '';
      userInfoObj.isCWTUser = false;
      userInfoObj.id = localStorage.getItem('userId');
      userInfoObj.uid = iesUserDetails.credentials[0].userName;
      userInfoObj.username = iesUserDetails.credentials[0].userName;
      userInfoObj.createdBy = '';
      userInfoObj.externalRef = [];
      userInfoObj.identityScheme = '';
      userInfoObj.noOfTimesLoggedIn = null;
      userInfoObj.schools = iesUserSchool.school;
      userInfoObj.loggedIn = iesUserDetails.credentials[0].lastLoginSuccess;
      userInfoObj.lastLoggedInTime = iesUserDetails.credentials[0].lastLoginSuccess;
      userInfoObj.role = localStorage.getItem('usertype');

      localStorage.setItem('userInformation', JSON.stringify(userInfoObj));
      // Calling next : App Config
      this.getUserCompleted();
    }
  }

  render() {
    const { language, intl } = this.props;
    const courseSectionData = DataFormatter.getObjectInStorage('setCoursesectionFetchData');
    const courseSectionEndDate = courseSectionData && courseSectionData.data && courseSectionData.data.length && courseSectionData.data[0]?.endDate;
    const current_Date = new Date().setHours(0,0,0,0);
    const endDate = new Date(courseSectionEndDate).setHours(0,0,0,0);
    const isClassRoomExpired = (current_Date > endDate);
    const userMode = DataFormatter.getObjectInStorage('userMode');
    let appContainer = <div style={{ height: '500px', width: '100%' }} />;
    if (!this.state.appReloading) {
      appContainer = (
        this.state.appui_mode === APP_MODE.PRIMARY ? (
          <PrimaryApplicationContainer
            schoolId={this.state.schoolId}
            userId={this.state.userId}
          />
        )
          : this.state.schoolId !== null ? (<SecondaryApplicationContainer schoolId={this.state.schoolId} />) : (<CircularProgress />)
      );
      if (isClassRoomExpired && userMode === APP_MODE.PRIMARY) {
        appContainer = (
          <div className="applicationWrapperStyle">
            <ExpiryMessage
              title={language.getMessage('student_license_expiry_title')}
              description={language.getMessage('student_license_expiry_description')}
            />
          </div>
        );
      }
    }
    // DL-443 - License expiry message to be displayed
    const schoolData = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school');
    let subscriptionEndDate = schoolData && schoolData[0].products[0].endDate;
    let subscriptionEndDateForDisplay = new Date(subscriptionEndDate);
    const currentDateForDisplay = new Date();
    subscriptionEndDate = new Date(subscriptionEndDate).setHours(0,0,0,0);
    const currentDate = new Date().setHours(0,0,0,0);
    let licenceExpiryDaysRemaining = subscriptionEndDate - currentDate;
    licenceExpiryDaysRemaining = licenceExpiryDaysRemaining > 0 ? (Math.floor(licenceExpiryDaysRemaining)) + 1 : licenceExpiryDaysRemaining;
    if (licenceExpiryDaysRemaining === 0) {
      licenceExpiryDaysRemaining = 1;
    }
    let isLicenseLastDay = false;
    let licenceExpiryDaysRemainingForDisplay = (subscriptionEndDateForDisplay.getTime() - currentDateForDisplay.getTime()) / (1000 * 3600 * 24);
    licenceExpiryDaysRemainingForDisplay = licenceExpiryDaysRemainingForDisplay > 0 ? (Math.floor(licenceExpiryDaysRemainingForDisplay)) + 1 : licenceExpiryDaysRemainingForDisplay;
    if (licenceExpiryDaysRemainingForDisplay <= 0 && licenceExpiryDaysRemaining == 1) {
      isLicenseLastDay = true;
      licenceExpiryDaysRemainingForDisplay = 1;
    }
    // DL-444 - License expired message to be displayed for teacher
    if (licenceExpiryDaysRemaining < 0 && userMode === APP_MODE.SECONDARY) {
      let license_expired_message = ReactHtmlParser(this.replaceText(intl.formatMessage(language.getText(`teacher_license_expiry_contact`)), [constants.SPARK_SALES_LINK])+'<br />'+intl.formatMessage(language.getText(`teacher_license_expiry_description`)));
      appContainer = (
        <div className='applicationWrapperStyle'>
          <ExpiryMessage
            title={language.getMessage('teacher_license_expiry_title')}
            description={license_expired_message}
          />
        </div>
      );
    }
    // DL-444 Ends here
    const iesUserDetails = DataFormatter.getObjectInStorage('iesUserDetails');
    const countryCode = iesUserDetails && iesUserDetails.identity.homeCountryCode.toLowerCase();
    const isExpiryMessageClosed = DataFormatter.getObjectInStorage('isExpiryMessageClosed');
    let isTrialPeriod = false;
    const schooldetailArray = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school');
    if (schooldetailArray && typeof schooldetailArray[0].metadata !== 'undefined') {
      isTrialPeriod = DataFormatter.getKeyFromObject('iesSchoolDetails', 'school')[0].metadata.isTrial;
    }
    return (
      <LoadingHandler
        loading={this.state.appReloading}
        isError={false}
        error={false}
        content={(
          <div className={DataFormatter.isiPad() ? ' ipad' : ''}>
            <a href="#maincontent" className="skip">
              <FormattedMessage
                {...language.getText('skip_to_main_content')}
              />

            </a>
            <Navbar schoolId={this.state.schoolId} />
            <ImageBar
              classNamePrefix={userMode}
              role={this.state.readingui_mode}
              color={licenceExpiryDaysRemainingForDisplay > constants.MIN_EXPIRY_MSG_VALUE ? 'amber' : 'red'}
              show={
                this.state.displayTeacherLicenseExpiryMsg
                && (licenceExpiryDaysRemainingForDisplay <= constants.MAX_EXPIRY_MSG_VALUE)
                && (licenceExpiryDaysRemainingForDisplay >= 0) && isExpiryMessageClosed !== 'true' && !isTrialPeriod
              }
              message={isLicenseLastDay ? ReactHtmlParser(this.replaceText(intl.formatMessage(language.getText(`teacher_license_expiry_msg_last_day`)), [constants.SPARK_HELP_LINK])):ReactHtmlParser(this.replaceText(intl.formatMessage(language.getText(`teacher_license_expiry_msg_${countryCode}`)), [licenceExpiryDaysRemainingForDisplay, constants.SPARK_HELP_LINK]))}
              handleClose={this.handleExpiryMsgClose}
              animateStatus={this.state.isHovered ? licenceExpiryDaysRemainingForDisplay <= constants.MIN_EXPIRY_MSG_VALUE ? 'teacher-expiry-message-snackbar pad0-marg0 animate-flicker teach-expiry-red' : 'teacher-expiry-message-snackbar pad0-marg0 animate-flicker teach-expiry-amber' : licenceExpiryDaysRemainingForDisplay <= constants.MIN_EXPIRY_MSG_VALUE ? 'teacher-expiry-message-snackbar pad0-marg0 teach-expiry-red' : 'teacher-expiry-message-snackbar pad0-marg0 teach-expiry-amber'}
              onMouseEnter={this.handleMouseEnter.bind(this)}
              onMouseLeave={this.handleMouseLeave.bind(this)}
            />
            {appContainer}
            <FooterSnackbar />
            <FooterBar />
            <TeacherSnackbar show={this.state.displayExpiryMsg} message={this.state.licenseExpiryMsg} handleClose={this.handleClose} />
            {this.state.licenseExpiryLogout ? <div className="overlay-cover" /> : ''}
          </div>
        )}
        loadingContent={(<div className="progressWrapper"><CircularProgress /></div>)}
      />
    );
  }
}

// ApplicationContainer.defaultProps = {};

// ApplicationContainer.contextTypes= {
//   router: React.PropTypes.object.isRequired
// }

ApplicationContainer.propTypes = {
  intl: intlShape.isRequired,
  language: PropTypes.object.isRequired,
  dlUser: shapes.modelOf(DlUser).isRequired,
  dlUserSchool: shapes.modelOf(DlUserSchool).isRequired,
  dlConfig: shapes.modelOf(DlConfig).isRequired,
  dlServerYear: shapes.modelOf(DlServerYear).isRequired,
  dlAppConfig: shapes.modelOf(DlAppConfig).isRequired

};

export default compose(
  inject('user', 'dlUser', 'dlUserSchool', 'dlConfig', 'dlServerYear', 'dlAppConfig', 'language'),
  observer
)(injectIntl(ApplicationContainer));
